import React from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {Row,Col,Button, Input, Form, Modal, DatePicker, Divider, Radio, Tag} from "antd";
import {addTodoWork,updateTodoWork,deleteTodoWork, batchDeleteTodoWork} from "../../actions/todoDBTool";
import moment from "moment";
import {connect} from "react-redux";
import {todoWorkStatusList} from "../../constants/constant";
import ExtendSelect from "./ExtendSelect";
import ExtendTable from "./ExtendTable";
import StaffChoose from "./StaffChoose";
import CustomChooseSelect from "./CustomChooseSelect";
import {name2Account, account2Name, customName2Uuid} from "../../utils/accountUtils";
import { StringCompareSort} from '../../utils/common';

const {confirm} = Modal;
const todoWorkType = [{text:"客户跟进任务",value:"客户跟进任务"},{text:"普通待办任务",value:"普通待办任务"}];
const TodoWorkForm = Form.create({name:'todoWork_modal'})(
    class extends React.Component{
    constructor(props) {
        super(props);
        this.state = {type:"客户跟进任务"};
    }
    /*shouldComponentUpdate(nextProps, nextState, nextContext){
        /*if(this.props.visible !== nextProps.visible){
            this.setState({type:nextProps.values.type});
        }
        return true;
    }*/
    renderFollowCustomTodoWork(values,mode){
        const { form } = this.props;
        let { getFieldDecorator } = form;
        return (<Row>
            <Row style={{background:'#F0F0F0'}}>客户跟进任务</Row>
            <Form.Item label={"客户名称"}>
                {getFieldDecorator('custom',{initialValue:mode?values.custom:""})
                    (<CustomChooseSelect style={{maxWidth:'400px'}} disabled={2 === mode?true:false}/>)}
            </Form.Item>
            <Form.Item label={"任务内容"}>
                {getFieldDecorator('content', {initialValue: mode?values.content:''})
                    (<ReactQuill placeholder={"请输入任务内容..."} readOnly={2 === mode?true:false}/>)}
            </Form.Item>
        </Row>);
    }
    renderNormalTodoWork(values,mode){
        const { form } = this.props;
        let { getFieldDecorator } = form;
        return (<Row>
            <Row style={{background:'#F0F0F0'}}>普通代办任务</Row>
            <br/>
            <Form.Item label={"任务内容"}>
                {getFieldDecorator('content', {initialValue: mode?values.content:''})
                    (<ReactQuill placeholder={"请输入任务内容..."} style={{width:'80%'}} readOnly={2 === mode?true:false}/>)}
            </Form.Item>
        </Row>);
    }
    render(){
        let { visible, onCancel, onOk, form, values,mode,title } = this.props;
        let { getFieldDecorator } = form;
        let formItemLayout = {labelCol: {span:6}, wrapperCol: {span:16}};
        let currentTime = new Date();
        return(<Modal title={title} visible={visible} maskClosable={false}
                onOk={onOk} okText={2===mode?"关闭":"确定"} onCancel={onCancel} cancelText={"取消"} width={1200}>
                <Form {...formItemLayout}>
                    <Form.Item label={"任务类型"}>
                        {getFieldDecorator('type', {initialValue:mode?values.type:'客户跟进任务'})
                        (<Radio.Group style={{width:'90%'}} disabled={mode?true:false} onChange={(e)=>{this.setState({type:e.target.value})}}>
                            <Radio value={'客户跟进任务'}>客户跟进任务</Radio>
                            <Radio value={'普通待办任务'}>普通待办任务</Radio>
                        </Radio.Group>)}
                    </Form.Item>
                    <Form.Item label={"任务标题"}>
                        {getFieldDecorator('todoWorkTitle', {rules: [{required: true, message: '标题不能为空!'}],
                            initialValue: mode ? values.todoWorkTitle: ''})
                            (<Input placeholder={"例如:处理XX任务"} style={{maxWidth:'400px'}} disabled={2 === mode?true:false}/>)}
                    </Form.Item>
                    <Form.Item label={"任务状态"}>
                        {getFieldDecorator('status', {rules: [{required: true,message:'状态不能为空!'}],
                            initialValue: mode ? values.status : todoWorkStatusList[0]})
                            (<ExtendSelect items={todoWorkStatusList} title={"任务状态"} placeholder={"请输入自定义状态"} disabled={2 === mode?true:false}/>)}
                    </Form.Item>
                    <Form.Item label={"设置提醒"}>
                        {getFieldDecorator('remindTime', {rules: [{ required: true,message:'提醒时间不能为空!' }],
                            initialValue:mode? moment(new Date(values.remind_time), 'YYYY-MM-DD'):moment(currentTime,'YYYY-MM-DD')})
                        (<DatePicker placeholder={"选择提醒的时间"} showTime disabled={2 === mode?true:false}/>)}
                    </Form.Item>
                    {this.state.type==='客户跟进任务'?this.renderFollowCustomTodoWork(values,mode):null}
                    {this.state.type==='普通待办任务'?this.renderNormalTodoWork(values,mode):null}
                    <Row style={{background:'#F0F0F0'}}>基础信息</Row>
                    <Form.Item label={"负责人"}>
                        {getFieldDecorator('manager', {rules: [{ required: true,message:'负责人不能为空!'}],
                            initialValue:mode?values.manager:""})
                            (<StaffChoose mode={1} disabled={2 === mode?true:false}/>)}
                    </Form.Item>
                    <Form.Item label={"抄送给"}>
                        {getFieldDecorator('assigner', {initialValue: mode ? values.assigner : ''})
                            (<StaffChoose mode={1} disabled={2 === mode?true:false}/>)}
                    </Form.Item>
                </Form>
            </Modal>)
    }
}
)
function mapStateToProps(state) {
    return {...state.todoWork}
}
function mapDispatchToProps(dispatch) {
    return {onAddTodoWork:(postData)=>dispatch(addTodoWork(postData)),
            onUpdateTodoWork:(todoID)=>dispatch(updateTodoWork(todoID)),
            onDeleteTodoWork:(todoID)=>dispatch(deleteTodoWork(todoID)),
            onBatchDelete:(uuid_list)=>dispatch(batchDeleteTodoWork(uuid_list))
        };
}
class TodoWorkTable extends React.Component{
    constructor(props) {
        super(props);
        this.state = {showTodoWorkModal:false,modalMode:0,modalTitle:'新增任务',modalValues:{}}
        this.showContent = this.showContent.bind(this);
    }
    saveFormRef = formRef => {this.formRef = formRef;}
    updateModalValues(values){
        let detail = values.detail;
        console.log(detail)
        this.setState({modalValues:{
            uuid:values.uuid,
            todoWorkTitle:values.title,
            status:values.status,
            type:values.type,
            custom:detail.custom_name,
            content:detail.content?detail.content:'',
            remind_time:detail.remind_time,
            assigner:account2Name(detail.assigner_account),
            manager:account2Name(detail.manager_account),
            creator:account2Name(values.creator_account),
            createTime: values.create_time
        }})
    }
    handleEmptySelect=(uuid_list)=>{
        this.props.onBatchDelete(uuid_list);
    }
    handleAddOnClick=()=>{
        this.setState({showTodoWorkModal:true,modalMode:0,modalTitle:'新增任务',modalValues:{}});
    }
    handleDelete=(uuid)=>{
        console.log(uuid)
        confirm({
            content: '您确定要删除该待办事务?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk:()=> {this.props.onDeleteTodoWork(uuid)},
            onCancel() {console.log('Cancel');},
        })
    }
    handleOnEdit=(record)=>{
        for (let i = 0;i < this.props.get_todo_result.length;i++){
            let item = this.props.get_todo_result[i];
            if (item.uuid === record.uuid){
                this.updateModalValues(item);
                this.setState({showTodoWorkModal:true,modalMode:1,modalTitle:"更新任务",});
                return ;
            }
        }
    }
    showContent = (record) =>{
        for (let i = 0;i < this.props.get_todo_result.length;i++){
            let item = this.props.get_todo_result[i];
            if (item.uuid === record.uuid){
                this.updateModalValues(item);
                this.setState({showTodoWorkModal:true,modalMode:2,modalTitle:"查看任务",});
                return ;
            }
        }
    }
    handleCancel=()=>{
        const { form } = this.formRef.props;
        form.resetFields();
        this.setState({showTodoWorkModal:false})
    }
    handleSubmit=()=>{
        if(2 === this.state.modalMode){
            this.setState({ showTodoWorkModal: false });
            return;
        }
        const { form } = this.formRef.props;
        form.validateFields((err, fromValues) => {
            if(err){return;}
            let manager_account = name2Account(fromValues.manager);
            let assigner_account = name2Account(fromValues.assigner);
            let detail = {  custom_uuid:customName2Uuid(fromValues.custom),
                            custom_name:fromValues.custom,
                            remind_time:fromValues.remindTime,
                            assigner_account:assigner_account,
                            manager_account:manager_account,
                            content:fromValues.content
                        };
            let current = new Date();
            let postData = {
                enterprise_uuid:localStorage.getItem('enterprise_uuid'),
                title:fromValues.todoWorkTitle,
                status:fromValues.status,
                type:fromValues.type,
                detail:JSON.stringify(detail),
                create_time:Date.parse(current)
            }
            if(this.state.modalMode){
                postData['uuid'] = this.state.modalValues.uuid;
                postData['creator_account'] = this.state.modalValues.creator;
                console.log(postData)
                this.props.onUpdateTodoWork(postData);
            }
            else{
                postData['creator_account'] = localStorage.getItem("account");
                let uuid = Date.parse(new Date())/1000+(Math.round(Math.random() * (9000))+999);
                postData['uuid'] = uuid;
                this.props.onAddTodoWork(postData);
            }
            let modalValues = {
                uuid:postData['uuid'],
                title:fromValues.todoWorkTitle,
                status:fromValues.status,
                type:fromValues.type,
                detail:detail,
                create_time:Date.parse(current),
                creator_account:postData['creator_account']
            }

            this.updateModalValues(modalValues);;
            this.setState({ showTodoWorkModal: false });
            form.resetFields();
        });
    }
    renderStatusStyleText(text){
        const statusColor = ["#fa0","#2db7f5","",];
        return (<Tag color={statusColor[todoWorkStatusList.indexOf(text)]}>{text}</Tag>)
    }
    renderTypeStyleText(text){
        const typeColor = ["#f50","#2db7f5"];
        return (<Tag color={typeColor[todoWorkType.indexOf(text)]}>{text}</Tag>)
    }
    renderSetting(){
        return(<TodoWorkForm wrappedComponentRef={this.saveFormRef} visible={this.state.showTodoWorkModal} 
                values={this.state.modalValues} mode={this.state.modalMode} title={this.state.modalTitle}
                onCancel={this.handleCancel} onOk={this.handleSubmit}/>);
    }
    renderTable(){
        const todoWorkStatus = [];
        todoWorkStatusList.forEach(item=>{todoWorkStatus.push({text:item,value:item})})
        const columns = [
            {title:'标题',dataIndex:'title',key:'title',width:120,ellipsis:true,isSearch:true},
            {title:'类型',dataIndex:'type',key:'type',width:120,ellipsis:true, filters:todoWorkType,isSearch:true,sorter:(a,b)=>a.type.localeCompare(b.type),
                onFilter: (value, record) => record.type.indexOf(value) === 0,render:(text)=>this.renderTypeStyleText(text)},
            {title:'状态',dataIndex:'status',key:'status', width:120,ellipsis:true,filters:todoWorkStatus,isSearch:true,sorter:(a,b)=>a.status.localeCompare(b.status),
                onFilter: (value, record) => record.status.indexOf(value) === 0,render:(text,record)=>this.renderStatusStyleText(text)},
            {title:'抄送',dataIndex: 'assigner',key:'assigner',width:120,ellipsis:true,isSearch:true,sorter:(a,b)=>StringCompareSort(a.assigner,b.assigner),
                render:(text, record) =><Row>
                    {record.assigner instanceof Array?record.assigner.map((tag,index)=>{
                        const tagElem = (<Tag key={tag} color={"geekblue"}>{tag}</Tag>);
                        return (tagElem);
                    }):record.assigner?(<Tag key={record.assigner} color={"geekblue"}>{record.assigner}</Tag>):null}
                </Row>},
            {title:'创建时间',dataIndex:'createTime',key:'createTime',width:160,ellipsis:true,sortDirections: ['ascend','descend'],sorter: (a, b) => Date.parse(a.createTime) - Date.parse(b.createTime)},
            {title:'负责人',dataIndex:'manager',key:'manager',width:120,ellipsis:true,isSearch:true,sorter:(a,b)=>StringCompareSort(a.manager,b.manager),
                render:(text, record) =><Row>
                        {record.manager instanceof Array?record.manager.map((tag,index)=>{
                            const tagElem = (<Tag key={tag} color={"geekblue"}>{tag}</Tag>);
                            return (tagElem);
                        }):record.manager?(<Tag key={record.manager} color={"geekblue"}>{record.manager}</Tag>):null}
                    </Row>},
            {title:'操作',key:'actions', width:150,fixed: 'right',render: (text, record) =><Row>
                    <Col span={8}><Button size='small' type="link" onClick={()=>this.showContent(record)}>查看</Button></Col>
                    <Col span={8}><Button size='small' type="link" onClick={()=>this.handleOnEdit(record)}>编辑</Button></Col>
                    <Col span={8}><Button size='small' type="link" style={{color:'red'}} onClick={()=>this.handleDelete(record.uuid)}>删除</Button></Col>
                </Row>}
        ];
        let dataSource = [];
        for (let i = 0;i < this.props.dataSource.length;i++){
            let record = this.props.dataSource[i];
            let detail = record.detail;
            let assigner = account2Name(detail.assigner_account);
            let manager = account2Name(detail.manager_account);
            dataSource.push({index:i+1,
                            uuid:record.uuid,
                            title:record.title,
                            status:record.status,
                            type: record.type,
                            content:detail.content,
                            assigner:assigner,
                            manager:manager,
                            createTime:moment(new Date(record.create_time)).format('YYYY-MM-DD HH:mm:ss'),
                            creator:record.creator_account
                        });
        }
        return(<ExtendTable handleAddOnClick={this.handleAddOnClick} onBatchDelete={this.handleEmptySelect}
                columns={columns} customColumnsEnable={this.props.customColumnsEnable} tableKey={this.props.tableKey}
                dataSource={dataSource} rowKey={record => record.uuid}/>);
    }
    render() {
        return (<Row>
                    <Row>{this.renderTable()}</Row>
                    <Divider/>
                    <Row>{this.renderSetting()}</Row>
                </Row>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(TodoWorkTable);