import {ActionType} from "../constants/actionTypes"
export default (state={},action)=>{
    switch (action.type) {
        case ActionType.ADD_BUSINESS_OPPORTUNITY_START:
            return {...state,add_business_opportunity:false}
        case ActionType.ADD_BUSINESS_OPPORTUNITY_SUCCESS:
            return {...state,add_business_opportunity: true,add_business_opportunity_result:action.payload}
        case ActionType.ADD_BUSINESS_OPPORTUNITY_FAILED:
            return {...state,add_business_opportunity: false,add_business_opportunity_error:action.errors?action.errors:null}

        case ActionType.GET_BUSINESS_OPPORTUNITY_START:
            return {...state,get_business_opportunity:false}
        case ActionType.GET_BUSINESS_OPPORTUNITY_FAILED:
            return {...state,get_business_opportunity:false,get_business_opportunity_error:action.errors?action.errors:null}
        case ActionType.GET_BUSINESS_OPPORTUNITY_SUCCESS:
            return {...state,get_business_opportunity:true,get_business_opportunity_result:action.payload}

        case ActionType.GET_BUSINESS_OPPORTUNITY_BY_CUSTOM_START:
            return {...state,get_business_opportunity_by_custom: false}
        case ActionType.GET_BUSINESS_OPPORTUNITY_BY_CUSTOM_SUCCESS:
            return {...state,get_business_opportunity_by_custom:true,get_business_opportunity_by_custom_result: action.payload}
        case ActionType.GET_BUSINESS_OPPORTUNITY_BY_CUSTOM_FAILED:
            return {...state,get_business_opportunity_by_custom:false,get_business_opportunity_by_custom_error:action.errors?action.payload.errors:null}

        case ActionType.GET_BUSINESS_OPPORTUNITY_STATUS_COUNT_START:
            return {...state,get_business_opportunity_status_count:false}
        case ActionType.GET_BUSINESS_OPPORTUNITY_STATUS_COUNT_SUCCESS:
            return {...state,get_business_opportunity_status_count:true,get_business_opportunity_status_count_result:action.payload}
        case ActionType.GET_BUSINESS_OPPORTUNITY_STATUS_COUNT_FAILED:
            return {...state,get_business_opportunity_status_count:false,get_business_opportunity_status_count_error:action.errors?action.payload.errors:null}

        case ActionType.GET_BUSINESS_OPPORTUNITY_STATUS_AMOUNT_START:
            return {...state,get_business_opportunity_status_amount:false};
        case ActionType.GET_BUSINESS_OPPORTUNITY_STATUS_AMOUNT_SUCCESS:
            return {...state,get_business_opportunity_status_amount:true,get_business_opportunity_status_amount_result:action.payload};
        case ActionType.GET_BUSINESS_OPPORTUNITY_STATUS_AMOUNT_FAILED:
            return {...state,get_business_opportunity_status_amount:false,get_business_opportunity_status_amount_error:action.errors?action.payload:null};


        case ActionType.UPDATE_BUSINESS_OPPORTUNITY_START:
            return {...state,update_business_opportunity:false}
        case ActionType.UPDATE_BUSINESS_OPPORTUNITY_SUCCESS:
            return {...state,update_business_opportunity:true,update_business_opportunity_result:action.payload}
        case ActionType.UPDATE_BUSINESS_OPPORTUNITY_FAILED:
            return {...state,update_business_opportunity:false,update_business_opportunity_error:action.errors?action.payload.errors:null}

        case ActionType.DELETE_BUSINESS_OPPORTUNITY_START:
            return {...state,delete_business_opportunity:false}
        case ActionType.DELETE_BUSINESS_OPPORTUNITY_SUCCESS:
            return {...state,delete_business_opportunity:true,delete_business_opportunity_result:action.payload}
        case ActionType.DELETE_BUSINESS_OPPORTUNITY_FAILED:
            return {...state,delete_business_opportunity:false,delete_business_opportunity_error:action.errors?action.payload.errors:null}

        case ActionType.ADD_BUSINESS_FLOW_START:
            return {...state,add_business_flow:false}
        case ActionType.ADD_BUSINESS_FLOW_SUCCESS:
            return {...state,add_business_flow:true,add_business_flow_result:action.payload}
        case ActionType.ADD_BUSINESS_FLOW_FAILED:
            return {...state,add_business_flow:false,add_business_flow_error:action.errors?action.payload:null}
        
        case ActionType.GET_BUSINESS_FLOW_START:
            return {...state,get_business_flow:false}
        case ActionType.GET_BUSINESS_FLOW_SUCCESS:
            return {...state,get_business_flow:true,get_business_flow_result:action.payload}
        case ActionType.GET_BUSINESS_FLOW_FAILED:
            return {...state,get_business_flow:false,get_business_flow_error:action.payload}

        case ActionType.UPDATE_BUSINESS_FLOW_START:
            return {...state,update_business_flow:false}
        case ActionType.UPDATE_BUSINESS_FLOW_SUCCESS:
            return {...state,update_business_flow:true,update_business_flow_result:action.payload}
        case ActionType.UPDATE_BUSINESS_FLOW_FAILED:
            return {...state,update_business_flow:false,update_business_flow_error:action.payload}

        case ActionType.DELETE_BUSINESS_FLOW_START:
            return {...state,delete_business_flow:false}
        case ActionType.DELETE_BUSINESS_FLOW_SUCCESS:
            return {...state,delete_business_flow:true,delete_business_flow_result:action.payload}
        case ActionType.DELETE_BUSINESS_FLOW_FAILED:
            return {...state,delete_business_flow:false,delete_business_flow_error:action.payload}

        default:
            return {...state};
    }
}