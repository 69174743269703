import React from "react";
import {Row, Col, Tag, Input, Form, Modal, DatePicker, Divider, Select, Button,Radio,Checkbox} from "antd";
import {connect} from "react-redux";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {addExpense, deleteExpense, updateExpense, batchDeleteExpense} from "../../actions/expenseDBTool";
import moment from "moment";
import vCardsJS from 'vcards-js';
import FileSaver from 'file-saver';
import {exportExcel} from './ExcelUtil';
import {expenseStatusList} from "../../constants/constant";
import ExtendSelect from "./ExtendSelect";
import StaffChoose from "./StaffChoose";
import ExtendTable from "./ExtendTable";
import {account2Name, name2Account} from "../../utils/accountUtils";
import { StringCompareSort } from "../../utils/common";
import FileManage from './FileManage';
const {Option} = Select;
const {confirm} = Modal;
const exportOptions = ['序号','标题','报销事由', '报销金额', '消费时间', '状态', '有无发票','审核人','抄送人','创建人','创建时间'];
const ExpenseForm = Form.create({name:'expense_modal'})(
    class extends React.Component{
        constructor(props) {
            super(props);
            this.state = {  };
        }
        render(){
            let { visible, onCancel, onOk, form, values,mode,title } = this.props;
            let { getFieldDecorator } = form;
            let formItemLayout = {labelCol: {span:6}, wrapperCol: {span:16}};
            let currentTime = new Date();
            return(<Modal title={title} visible={visible} maskClosable={false} onOk={onOk} onCancel={onCancel} okText={2===mode?"关闭":"确定"} cancelText={"取消"}>
                <Form {...formItemLayout}>
                    <Form.Item label={"标题"}>
                        {getFieldDecorator('reimburseTitle', {rules: [{required: true, message: '原因不能为空!'}],initialValue: mode ? values.reimburseTitle: ''})
                        (<Input placeholder={"例如:出差到XX客户公司"} disabled={2 === mode?true:false}/>)}
                    </Form.Item>
                    <Form.Item label={"报销事由"}>
                        {getFieldDecorator('note', {rules: [{ required: false}],initialValue:mode?values.note:""})
                        (<ReactQuill placeholder={"例如:XXX"} readOnly={2 === mode?true:false}/>)}
                    </Form.Item>
                    <Form.Item label={"报销状态"}>
                        {getFieldDecorator('status', {rules: [{required: true,message:'状态不能为空!'}],initialValue: mode ? values.status : ''})
                        (<ExtendSelect items={expenseStatusList} title={"报销状态"} placeholder={"请输入自定义的报销状态"} disabled={2 === mode?true:false}/>)}
                    </Form.Item>
                    <Form.Item label={"报销金额(元)"}>
                        {getFieldDecorator('amount', {rules: [{required: true, message: '金额不能为空!'}],initialValue: mode ? values.amount: ''})
                        (<Input placeholder={"例如:XX元"} disabled={2 === mode?true:false}/>)}
                    </Form.Item>
                    <Form.Item label={"有无发票"}>
                        {getFieldDecorator('isInvoice', {rules: [{required: true, message: '必填项!'}],initialValue: mode?values.isInvoice: 0})
                        (<Select disabled={2 === mode?true:false}>
                            <Option key={0} value={0}>{"无"}</Option>
                            <Option key={1} value={1}>{"有"}</Option>
                            </Select>)}
                    </Form.Item>
                    <Form.Item label={"消费时间"}>
                        {getFieldDecorator('spendTime', {rules: [{ required: true,message:'消费的时间必填!' }],
                            initialValue:mode? moment(new Date(values.spendTime), 'YYYY-MM-DD'):moment(currentTime, 'YYYY-MM-DD')})
                        (<DatePicker placeholder={"选择消费的时间"} showTime disabled={2 === mode?true:false}/>)}
                    </Form.Item>
                    <Form.Item label={"审核人"}>
                        {getFieldDecorator('manager', {rules:[{required:true,message:"审核人不能为空！"}],initialValue:mode?values.manager:''})
                        (<StaffChoose mode={1} disabled={2 === mode?true:false}/>)}
                    </Form.Item>
                    <Form.Item label={"抄送给"} >
                        {getFieldDecorator('assigner', {rules:[],initialValue:mode?values.assigner:''})
                        (<StaffChoose mode={1} disabled={2 === mode?true:false}/>)}
                    </Form.Item>
                    <Form.Item label={"相关文档"}>
                        {getFieldDecorator('fileUrl', {initialValue:mode?values.file_url:""})
                            (<FileManage filePrefix={"expense/" + values.uuid+"/"} fileList={values.file_url} disabled={1 < mode?true:false}/>)}
                    </Form.Item>
                </Form>
            </Modal>);
        }
    }
)
function mapStateToProps(state) {
    return {...state.expense};
}
function mapDispatchToProps(dispatch){
    return {onAddReimburse:(postData)=>dispatch(addExpense(postData)),
            onEditReimburse:(postData)=>dispatch(updateExpense(postData)),
            onDeleteReimburse:(uuid)=>dispatch(deleteExpense(uuid)),
            onBatchDelete:(uuid_list)=>dispatch(batchDeleteExpense(uuid_list))
    };
}
class ExpeseTable extends React.Component{
    constructor(props) {
        super(props);
        this.state = {showModal:false,modalMode:0,modalTitle:'新增报销申请',
                        exportColumns:exportOptions,
                        exportType:'xlsx',
                        vcfSize:'0',
                        modalValues:{uuid:Date.parse(new Date())/1000+(Math.round(Math.random() * (9000))+999)}}
        this.showContent = this.showContent.bind(this);
        this.handleOnEdit = this.handleOnEdit.bind(this);
    }
    componentDidMount(){}
    saveFormRef = formRef => {this.formRef = formRef;}
    updateModalValues(values){
        let detail = values.detail;
        this.setState({modalValues: {
            uuid:values.uuid,
            reimburseTitle:values.title,
            note:values.detail.note,
            status:values.status,
            amount:values.amount,
            isInvoice:detail.is_invoice,
            file_url:detail.file_url?detail.file_url:[],
            spendTime:moment(values.spend_time).format('YYYY-MM-DD HH:mm:ss'),
            manager:account2Name(values.detail.manager_account),
            assigner:account2Name(values.detail.assigner_account),
            creator:account2Name(values.creator_account),
            createTime:moment(values.create_time).format('YYYY-MM-DD HH:mm:ss')
        }});
    }
    handleAddOnClick=()=>{
        this.setState({showModal:true,modalMode:0,modalTitle:'新增报销申请',modalValues:{uuid:Date.parse(new Date())/1000+(Math.round(Math.random() * (9000))+999)}})
    }
    showContent(record){
        for (let i = 0;i < this.props.get_expense_result.length;i++){
            let item = this.props.get_expense_result[i];
            if(item.uuid === record.uuid){
                this.updateModalValues(item);
                this.setState({showModal:true, modalMode:2,modalTitle:'查看报销'});
                return;
            }
        }
    }
    handleEmptySelect=(uuid_list)=>{
        this.props.onBatchDelete(uuid_list);
    }
    handleOnEdit(record){
        for (let i = 0;i < this.props.get_expense_result.length;i++){
            let item = this.props.get_expense_result[i];
            if(item.uuid === record.uuid){
                this.updateModalValues(item);
                this.setState({showModal:true, modalMode:1,modalTitle:'更新报销'});
                return;
            }
        }
    }
    handleDelete=(uuid)=>{
        confirm({
            content: '您确定要删除该报销申请?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk:()=> {
                this.props.onDeleteReimburse(uuid)
            },
            onCancel() {
                console.log('Cancel');
            },
        })
    }
    handleCancel=()=>{
        const { form } = this.formRef.props;
        form.resetFields();
        this.setState({showModal:false})
    }
    handleSubmit=()=>{
        if(2 === this.state.modalMode){
            this.setState({ showModal: false });
            return;
        }
        const { form } = this.formRef.props;
        form.validateFields((err, fromValues) => {
            if(err){return;}
            let manager_account = name2Account(fromValues.manager);
            let assigner_account = name2Account(fromValues.assigner);
            let detail = {
                is_invoice:fromValues.isInvoice,
                file_url:fromValues.fileUrl,
                note:fromValues.note,
                manager_account:manager_account,
                assigner_account:assigner_account
            };
            let postData = {
                uuid:this.state.modalValues.uuid,
                enterprise_uuid:localStorage.getItem('enterprise_uuid'),
                title:fromValues.reimburseTitle,
                status:fromValues.status,
                type:1,
                amount:fromValues.amount,
                spend_time:Date.parse(fromValues.spendTime),
                detail:JSON.stringify(detail),
                creator_account:localStorage.getItem('account'),
                create_time:Date.parse(new Date())
            };
            if(this.state.modalMode){
                this.props.onEditReimburse(postData);
            }
            else{
                this.props.onAddReimburse(postData);
            }
            let modalValues = {
                uuid:postData['uuid'],
                title:fromValues.reimburseTitle,
                status:fromValues.status,
                type:1,
                amount:fromValues.amount,
                spend_time:Date.parse(fromValues.spendTime),
                detail:detail,
                creator_account:localStorage.getItem('account'),
                create_time:Date.parse(new Date())
            }
            this.updateModalValues(modalValues);
            this.setState({ showModal: false });
            form.resetFields();
        });
    }
    OnChangeColumn=(checkedValues)=>{
        console.log(checkedValues);
        this.setState({exportColumns:checkedValues});
    }
    OnChangeExportFormat=(e)=>{
        this.setState({exportType:e.target.value})
    }
    OnChangeVcfSize=(e)=>{
        this.setState({vcfSize:e.target.value});
    }
    ExportVcf=(dataSource)=>{
        console.log(dataSource);
        let vCard = vCardsJS();
        let contactStr = "";
        let fileIndex = 1;
        for (let i = 0;i < dataSource.length;i++){
            vCard.firstName = dataSource[i].name;
            vCard.workPhone = dataSource[i].tel;
            vCard.cellPhone = dataSource[i].phone;
            vCard.workAddress = dataSource[i].address;
            contactStr+=(vCard.getFormattedString());
            if((0 !== this.state.vcfSize && 0 === i%this.state.vcfSize && 0 < i)||dataSource.length - 1 === i){
                let blob = new Blob([contactStr], {type: "text/plain;charset=utf-8"});
                FileSaver.saveAs(blob,"记录表"+fileIndex+".vcf");
                contactStr = "";
                fileIndex++;
            }
        }
        if(0 === this.state.vcfSize){
            let blob = new Blob([contactStr], {type: "text/plain;charset=utf-8"});
            FileSaver.saveAs(blob,"记录表.vcf");
        }
    }
    OnClickExport(dataSource){
        for (let i = 0;i < dataSource.length;i++){
            for (let key in dataSource[i]){
                if (-1 === this.state.exportColumns.indexOf(key)){
                    delete dataSource[i][key];
                    delete dataSource[i].key;
                }
            }
        }
        exportExcel(dataSource,this.state.exportType,'报销审核表');
    }
    renderExportModal=(dataSource,exportDataSource)=>{
        return (<Modal title="导出结果选项" visible={this.state.showExportModal} width={1000}
                        cancelText={"取消"} onCancel={()=>{this.setState({showExportModal:false})}}
                        okText={"导出"} onOk={()=>{this.state.exportType === "vcf"?this.ExportVcf(dataSource):this.OnClickExport(exportDataSource)}} >
                <Checkbox.Group disabled={this.state.exportType === "vcf"} options={exportOptions}
                                defaultValue={exportOptions} onChange={this.OnChangeColumn}/>
                <Divider/>
                <Radio.Group onChange={this.OnChangeExportFormat} defaultValue={'xlsx'}>
                    <Radio value={'xlsx'}>Excel(.xlsx)</Radio>
                    <Radio value={'xls'}>Excel(.xls)</Radio>
                    <Radio value={'csv'}>CSV</Radio>
                    <Radio value={'txt'}>文本(TXT)</Radio>
                </Radio.Group>
        </Modal>);
    }
    renderStatusStyleText(text){
        const statusColor = ["#f80","#2db7f5","#87d068",""];
        return (<Tag color={statusColor[expenseStatusList.indexOf(text)]}>{text}</Tag>)
    }
    renderSetting() {
        return(<ExpenseForm wrappedComponentRef={this.saveFormRef} mode={this.state.modalMode}
                visible={this.state.showModal} values={this.state.modalValues} title={this.state.modalTitle}
                onCancel={this.handleCancel} onOk={this.handleSubmit}/>);
    }
    renderTable(){
        const expenseStatus = [];
        expenseStatusList.forEach((item)=>{expenseStatus.push({text:item,value:item})});
        let showColumns =  [{title: "序号",dataIndex: 'index',key:'index',width:90,ellipsis:true},
        {title: "标题",dataIndex:'title',key:'title',width:120,ellipsis:true,isSearch:true,sorter: (a, b) => StringCompareSort(a.reason,b.reason)},
        {title: "报销事由",dataIndex:'reason',key:'reason',width:120,ellipsis:true,isSearch:true,sorter: (a, b) => StringCompareSort(a.reason,b.reason)},
        {title: "金额(元)",dataIndex:'money',key:'money',width:120,ellipsis:true,isSearch:true,sorter: (a, b) => a.money - b.money},
        {title: "消费时间",dataIndex:'time',key:'time',width:150,ellipsis:true,sorter: (a, b) => Date.parse(a.time) - Date.parse(b.time),
            render:(text, record) =><span>{moment(text).format('yyyy-MM-DD HH:mm:ss')}</span>},
        {title: "状态",dataIndex:"status",key:'status',width:100,ellipsis:true,filters:expenseStatus,onFilter: (value, record) => record.status.indexOf(value) === 0,
            sorter: (a, b) => StringCompareSort(a.status,b.status),render:(text)=>this.renderStatusStyleText(text)},
        {title: "有无发票",dataIndex: "isInvoice",key:'isInvoice',width:100,ellipsis:true,filters:[{text:"是",value:"是"},{text:"否",value:"否"}],
            onFilter: (value, record) => record.isInvoice.indexOf(value) === 0},
        {title: "创建人",dataIndex:"creator",key:'creator',width:120,ellipsis:true,isSearch:true,sorter: (a, b) => StringCompareSort(a.creator,b.creator),
            render:(text) => <Row>
                    {text instanceof Array?text.map((tag,index)=>{
                        const tagElem = (<Tag key={tag} color={"geekblue"}>{tag}</Tag>);
                        return (tagElem);
                    }):text?(<Tag key={text} color={"geekblue"}>{text}</Tag>):null}
                </Row>},
        {title: "创建时间",dataIndex:"createTime",key:'createTime',width:150,ellipsis:true,sorter: (a, b) => Date.parse(a.createTime) - Date.parse(b.createTime),
            render:(text, record) =><span>{moment(text).format('yyyy-MM-DD HH:mm:ss')}</span>},
        {title: "审核人",dataIndex:"manager",key:'manager',width:120,ellipsis:true,isSearch:true,sorter: (a, b) =>StringCompareSort(a.manager,b.manager),
            render:(text) => <Row>
                {text instanceof Array?text.map((tag,index)=>{
                    const tagElem = (<Tag key={tag} color={"geekblue"}>{tag}</Tag>);
                    return (tagElem);
                }):text?(<Tag key={text} color={"geekblue"}>{text}</Tag>):null}
            </Row>},
        {title:"抄送给",dataIndex:"assigner",key:'assigner',width:120,ellipsis:true,isSearch:true,sorter:(a,b)=>StringCompareSort(a.assigner,b.assigner),
            render:(text, record) =><Row>
                {text instanceof Array?text.map((tag,index)=>{
                    const tagElem = (<Tag key={tag} color={"geekblue"}>{tag}</Tag>);
                    return (tagElem);
                }):text?(<Tag key={text} color={"geekblue"}>{text}</Tag>):null}
        </Row>},
        {title:'操作',key:'actions',width:150,fixed: 'right',render: (text, record) =>false === this.props.editEnable?
        <span><Button type="link" onClick={()=>this.showContent(record)}>查看</Button></span>:
        <Row>
            <Col span={8}><Button size='small' type="link" onClick={()=>this.showContent(record)}>查看</Button></Col>
            <Col span={8}><Button size='small' type="link" onClick={()=>this.handleOnEdit(record)}>编辑</Button></Col>
            <Col span={8}><Button size='small' type="link" style={{color:'red'}} onClick={()=>this.handleDelete(record.uuid)}>删除</Button></Col>
        </Row>}];
        let dataSource = [];
        let exportDataSource = [];
        for (let i = 0;i < this.props.dataSource.length;i++){
            let record = this.props.dataSource[i];
            let detail = record.detail;
            let manager = account2Name(detail.manager_account) 
            let assigner = account2Name(detail.assigner_account)
            let creator = account2Name(record.creator_account);
            let isInvoice = "";
            if(record.detail.is_invoice){
                isInvoice = "有";
            }
            else{
                isInvoice = "无"
            }
            dataSource.push({index:i+1,
                uuid:record.uuid,
                title:record.title,
                reason:detail.note,
                time:record.spend_time,
                status:record.status,
                money:record.amount,
                isInvoice:isInvoice,
                manager:manager,
                assigner:assigner,
                creator:creator,
                createTime:record.create_time
            });
            exportDataSource.push({'序号':i+1,
                                    '标题':record.title,
                                    '报销事由':detail.note, 
                                    '报销金额':record.amount, 
                                    '消费时间':moment(record.spend_time).format('yyyy-MM-DD HH:mm:ss'), 
                                    '状态':record.status, 
                                    '有无发票':isInvoice,
                                    '审核人':manager,
                                    '抄送人':assigner,
                                    '创建人':creator,
                                    '创建时间':moment(record.create_time).format('yyyy-MM-DD HH:mm:ss')})
        }
        return(<Row>
                {this.renderExportModal(dataSource,exportDataSource)}
                <ExtendTable handleAddOnClick={this.handleAddOnClick} onBatchDelete={this.handleEmptySelect} dataSource={dataSource}
                    customColumnsEnable={this.props.customColumnsEnable} tableKey={this.props.tableKey} columns={showColumns} 
                    rowKey={record => record.uuid} exportEnable={this.props.exportEnable} exportTitle={"导出报销审核表"} exportClick={()=>this.setState({showExportModal:true})}/>
            </Row>);
    }
    render() {
        return (<Row>
            <Row>{this.renderTable()}</Row>
            <Divider/>
            <Row>{this.renderSetting()}</Row>
        </Row>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(ExpeseTable)