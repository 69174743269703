import React from "react";
import ReactQuill from 'react-quill'; // 
import 'react-quill/dist/quill.snow.css';
import {Row,Col, Input, Form, Modal, DatePicker, Divider, Tag, Select, Button,Radio,Checkbox} from "antd";
import {connect} from "react-redux";
import moment from "moment";
import vCardsJS from 'vcards-js';
import FileSaver from 'file-saver';
import {exportExcel} from './ExcelUtil';
import {getCollection,addCollection,deleteCollection,updateCollection, batchDeleteCollection} from "../../actions/collectionDBTool";
import {invoiceList} from "../../constants/constant";
import ExtendTable from "./ExtendTable";
import CustomChooseSelect from "./CustomChooseSelect";
import StaffChoose from "./StaffChoose";
import OrderChoose from "./OrderChoose";
import {account2Name, name2Account, customName2Uuid} from "../../utils/accountUtils"
import { StringCompareSort } from "../../utils/common";
import FileManage from './FileManage';

const {Option} = Select;
const {confirm} = Modal;
const exportOptions = ['序号','客户', '收款名称', '金额', '收款时间','相关订单','是否有发票','审核人','创建时间','创建人'];
let invoiceOptions = invoiceList.map(item=><Option key={item.value} value={item.value}>{item.text}</Option>);
const CollectionForm = Form.create({name:'collection_modal'})(
    class extends React.Component {
        constructor(props) {
            super(props);
            this.state = {};
        }
        render(){
            let { visible, onCancel, onOk, form, values,mode,title } = this.props;
            let { getFieldDecorator } = form;
            let formItemLayout = {labelCol: {span:6}, wrapperCol: {span:16}};
            let currentTime = new Date();
            return(<Modal title={title} visible={visible} maskClosable={false} onOk={onOk} onCancel={onCancel} okText={2===mode?"关闭":"确定"} cancelText={"取消"}>
                    <Form {...formItemLayout}>
                        <Form.Item label={"标题"}>
                            {getFieldDecorator('collectionTitle', {rules: [{required: true, message: '标题不能为空!'}],
                                initialValue: values.collectionTitle ? values.collectionTitle: ''})
                            (<Input placeholder={"例如:XX客户回款"} disabled={2 === mode?true:false}/>)}
                        </Form.Item>
                        <Form.Item label={"客户名称"}>
                            {getFieldDecorator('customName', {initialValue:values.customName?values.customName:""})(
                                <CustomChooseSelect disabled={2 === mode?true:false}/>)}
                        </Form.Item>
                        <Form.Item label={"订单ID"}>
                            {getFieldDecorator('orderID', {initialValue:values.orderID?values.orderID:""})
                                (<OrderChoose placeholder="请输入相关订单" disabled={2 === mode?true:false}/>)}
                        </Form.Item>
                        <Form.Item label={"是否开具发票"}>
                            {getFieldDecorator('isInvoice', {rules: [{required: true, message: '必填项!'}],initialValue: values.isInvoice?values.isInvoice: 0})
                                (<Select disabled={2 === mode?true:false}>{invoiceOptions}</Select>)}
                        </Form.Item>
                        <Form.Item label={"收款金额(元)"}>
                            {getFieldDecorator('amount', {rules: [{required: true, message: '金额不能为空!'}],initialValue: values.amount ? values.amount: ''})
                            (<Input placeholder={"例如:XX元"} disabled={2 === mode?true:false}/>)}
                        </Form.Item>
                        <Form.Item label={"收款时间"}>
                            {getFieldDecorator('collectionTime',{rules: [{ required: true,message:'收款时间必填!' }],
                                initialValue:values.collectionTime? moment(new Date(values.collectionTime), 'YYYY-MM-DD'):moment(currentTime, 'YYYY-MM-DD')})
                            (<DatePicker placeholder={"选择收款的时间"} showTime disabled={2 === mode?true:false}/>)}
                        </Form.Item>
                        <Form.Item label={"备注"}>
                            {getFieldDecorator('note', {initialValue:values.note?values.note:''})
                            (<ReactQuill placeholder={"请输入备注内容"} readOnly={2 === mode?true:false}/>)}
                        </Form.Item>
                        <Form.Item label={"审核人"}>
                            {getFieldDecorator('manager', {rules: [{ required: true,message:'负责人不能为空！'}],initialValue:values.manager?values.manager:""})
                                (<StaffChoose mode={1} disabled={2 === mode?true:false}/>)}
                        </Form.Item>
                        <Form.Item label={"抄送给"}>
                            {getFieldDecorator('assigner', {rules: [{ required: true,message:'负责人不能为空！'}],initialValue:values.assigner?values.assigner:""})
                                (<StaffChoose mode={1} disabled={2 === mode?true:false}/>)}
                        </Form.Item>
                        <Form.Item label={"相关文档"}>
                            {getFieldDecorator('fileUrl', {initialValue:mode?values.file_url:""})
                                (<FileManage filePrefix={"collection/" + values.uuid+"/"} fileList={values.file_url} disabled={1 < mode?true:false}/>)}
                        </Form.Item>
                    </Form>
                </Modal>);
        }
    }
);
function mapStateToProps(status) {
    return {...status.collection}
}
function mapDispatchToProps(dispatch){
    return {onGetCollection:()=>dispatch(getCollection()),
            onAddCollection:(postData)=>dispatch(addCollection(postData)),
            onUpdateCollection:(postData)=>dispatch(updateCollection(postData)),
            onDeleteCollection:(postData)=>dispatch(deleteCollection(postData)),
            onBatchDelete:(uuid_list)=>dispatch(batchDeleteCollection(uuid_list))
    };
}
class CollectionTable extends React.Component{
    constructor(props) {
        super(props);
        let current = new Date();
        this.state = {showModal:false, modalMode:0,modalTitle:'添加收款记录',
                        exportColumns:exportOptions,
                        exportType:'xlsx',
                        vcfSize:'0',
                        modalValues:{uuid:current.getFullYear().toString() + current.getTime().toString().substr(4,current.getTime().toString().length-4) + (Math.round(Math.random() * (9000))+999),}}
    }
    componentDidMount(){
        this.props.onGetCollection();
    }
    saveFormRef = formRef => {this.formRef = formRef;}
    updateModalValues(collectionData){
        let detail = collectionData.detail;
        let values = {
            uuid:collectionData.uuid,
            collectionTitle:collectionData.title,
            amount:collectionData.amount,
            isInvoice:detail.is_invoice,
            customName:detail.custom_name,
            orderID:detail.order_id,
            collectionTime :collectionData.collection_time,
            note :detail.note,
            file_url:detail.file_url?detail.file_url:[],
            assigner:account2Name(detail.assigner_account),
            manager:account2Name(detail.manager_account),
            creator:account2Name(collectionData.creator_account),
            createTime:collectionData.create_time
        };
        this.setState({modalValues:values});
    }
    handleAddOnClick=()=>{
        let current = new Date();
        if(this.props.selectCustom){
            let selectCustom = this.props.selectCustom;
            this.setState({showModal:true,modalMode:0,modalTitle:'添加收款记录',
                            modalValues:{
                                uuid:current.getFullYear().toString() + current.getTime().toString().substr(4,current.getTime().toString().length-4) + (Math.round(Math.random() * (9000))+999),
                                isInvoice:1,
                                customName:selectCustom.name,
                                manager:account2Name(selectCustom.detail.follower_account),
                                //creator:account2Name(selectCustom.creator_account)
                            }});
        }
        else{
            this.setState({showModal:true,modalMode:0,modalTitle:'添加收款记录',modalValues:{
                            uuid:current.getFullYear().toString() + current.getTime().toString().substr(4,current.getTime().toString().length-4) + (Math.round(Math.random() * (9000))+999)}});
        }
    }
    handleOnEdit=(record)=>{
        for (let i = 0;i < this.props.get_collection_result.length;i++){
            let item = this.props.get_collection_result[i];
            if(item.uuid === record.uuid){
                this.updateModalValues(item);
                this.setState({showModal:true, modalMode:1,modalTitle:'更新收款记录'});
                return;
            }
        }
    }
    showCollection(record){
        for (let i = 0;i < this.props.get_collection_result.length;i++){
            let item = this.props.get_collection_result[i];
            if(item.uuid === record.uuid){
                this.updateModalValues(item);
                this.setState({showModal:true, modalMode:2,modalTitle:'查看收款记录'});
                return;
            }
        }
    }
    handleDelete=(uuid)=>{
        confirm({
            content: '您确定要删除该报销申请?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk:()=> {
                for (let i = 0;i < this.props.dataSource.length;i++){
                    let item = this.props.dataSource[i];
                    if(item.uuid === uuid){
                        return this.props.onDeleteCollection(item)
                    }
                }
            },
            onCancel() {
                console.log('Cancel');
            },
        })
    }
    handleEmptySelect=(uuid_list)=>{
        this.props.onBatchDelete(uuid_list);
    }
    handleCancel=()=>{
        const { form } = this.formRef.props;
        form.resetFields();
        this.setState({showModal:false})
    }
    handleSubmit=()=>{
        if(2 === this.state.modalMode){
            this.setState({ showModal: false });
            return;
        }
        const { form } = this.formRef.props;
        form.validateFields((err, fromValues) => {
            if(err){return;}
            let creator_account = localStorage.getItem('account');
            let manager_account = name2Account(fromValues.manager);
            if (0 >= fromValues.manager.length){
                manager_account = localStorage.getItem('account');
            }
            let detail = {
                custom_uuid:customName2Uuid(fromValues.customName),
                custom_name:fromValues.customName,
                custom_type :0,
                order_id :fromValues.orderID,
                is_invoice:fromValues.isInvoice,
                note:fromValues.note,
                file_url:fromValues.fileUrl,
                assigner_account:fromValues.assigner,
                manager_account:manager_account,
            }
            let postData = {
                uuid:this.state.modalValues.uuid,
                enterprise_uuid:localStorage.getItem('enterprise_uuid'),
                title:fromValues.collectionTitle,
                status :0,
                type :0,
                amount :fromValues.amount,
                collection_time:Date.parse(fromValues.collectionTime),
                detail:JSON.stringify(detail),
                creator_account:creator_account,
                create_time:Date.parse(new Date())
            };
            if(this.state.modalMode){
                this.props.onUpdateCollection(postData);
            }else{
                this.props.onAddCollection(postData);
            }
            let modalValues = {
                uuid:postData['uuid'],
                title:fromValues.collectionTitle,
                status :0,
                type :0,
                amount :fromValues.amount,
                collection_time:Date.parse(fromValues.collectionTime),
                detail:detail,
                creator_account:creator_account,
                create_time:Date.parse(new Date())}
            form.resetFields();
            this.updateModalValues(modalValues);
            this.setState({ showModal: false});
        });
    }
    OnChangeColumn=(checkedValues)=>{
        console.log(checkedValues);
        this.setState({exportColumns:checkedValues});
    }
    OnChangeExportFormat=(e)=>{
        this.setState({exportType:e.target.value})
    }
    OnChangeVcfSize=(e)=>{
        this.setState({vcfSize:e.target.value});
    }
    ExportVcf=(dataSource)=>{
        console.log(dataSource);
        let vCard = vCardsJS();
        let contactStr = "";
        let fileIndex = 1;
        for (let i = 0;i < dataSource.length;i++){
            vCard.firstName = dataSource[i].name;
            vCard.workPhone = dataSource[i].tel;
            vCard.cellPhone = dataSource[i].phone;
            vCard.workAddress = dataSource[i].address;
            contactStr+=(vCard.getFormattedString());
            if((0 !== this.state.vcfSize && 0 === i%this.state.vcfSize && 0 < i)||dataSource.length - 1 === i){
                let blob = new Blob([contactStr], {type: "text/plain;charset=utf-8"});
                FileSaver.saveAs(blob,"记录表"+fileIndex+".vcf");
                contactStr = "";
                fileIndex++;
            }
        }
        if(0 === this.state.vcfSize){
            let blob = new Blob([contactStr], {type: "text/plain;charset=utf-8"});
            FileSaver.saveAs(blob,"记录表.vcf");
        }
    }
    OnClickExport(dataSource){
        for (let i = 0;i < dataSource.length;i++){
            for (let key in dataSource[i]){
                if (-1 === this.state.exportColumns.indexOf(key)){
                    delete dataSource[i][key];
                    delete dataSource[i].key;
                }
            }
        }
        exportExcel(dataSource,this.state.exportType,'收款记录表');
    }
    renderExportModal=(dataSource,exportDataSource)=>{
        return (<Modal title="导出结果选项" visible={this.state.showExportModal} width={1000}
                        cancelText={"取消"} onCancel={()=>{this.setState({showExportModal:false})}}
                        okText={"导出"} onOk={()=>{this.state.exportType === "vcf"?this.ExportVcf(dataSource):this.OnClickExport(exportDataSource)}} >
                <Checkbox.Group disabled={this.state.exportType === "vcf"} options={exportOptions}
                                defaultValue={exportOptions} onChange={this.OnChangeColumn}/>
                <Divider/>
                <Radio.Group onChange={this.OnChangeExportFormat} defaultValue={'xlsx'}>
                    <Radio value={'xlsx'}>Excel(.xlsx)</Radio>
                    <Radio value={'xls'}>Excel(.xls)</Radio>
                    <Radio value={'csv'}>CSV</Radio>
                    <Radio value={'txt'}>文本(TXT)</Radio>
                </Radio.Group>
        </Modal>);
    }
    renderSetting() {
        return(<CollectionForm wrappedComponentRef={this.saveFormRef} mode={this.state.modalMode}
                title={this.state.modalTitle} visible={this.state.showModal} values={this.state.modalValues}
                onCancel={this.handleCancel} onOk={this.handleSubmit}/>);
    }
    renderTable(){
        let showColumns =  [{title:"序号",dataIndex:'index',key:'index',width:90,ellipsis:true},
                            {title:"客户",dataIndex:'custom',key:'custom',width:120,ellipsis:true,isSearch:true,sorter: (a, b) =>StringCompareSort(a.title,b.title)},
                            {title:"收款名称",dataIndex:'title',key:'title',width:120,ellipsis:true,isSearch:true,sorter: (a, b) =>StringCompareSort(a.title,b.title)},
                            {title:"收款金额(元)",dataIndex:'amount',key:'amount',width:120,ellipsis:true,isSearch:true,sorter: (a, b) => a.amount - b.amount},
                            {title:"收款时间",dataIndex:'collectionTime',key:'collectionTime',width:150,ellipsis:true,sorter: (a, b) => Date.parse(a.collectionTime) - Date.parse(b.collectionTime)},
                            {title:"开具发票",dataIndex: "isInvoice",key:'isInvoice',width:100,ellipsis:true,filters:[{text:"是",value:"是"},{text:"否",value:"否"}],
                                onFilter: (value, record) => record.isInvoice.indexOf(value) === 0},
                            {title:"审核人",dataIndex:"manager",key:'manager',width:120,ellipsis:true,isSearch:true,sorter: (a, b) =>StringCompareSort(a.manager,b.manager),
                                render:(text, record) => <Row>
                                    {text instanceof Array?text.map((tag,index)=>{
                                        const tagElem = (<Tag key={tag} color={"geekblue"}>{tag}</Tag>);
                                        return (tagElem);
                                        }):text?(<Tag key={text} color={"geekblue"}>{text}</Tag>):null}
                                    </Row>},
                            {title:"创建时间",dataIndex:"createTime",key:'createTime',width:150,ellipsis:true,sorter: (a, b) => Date.parse(a.createTime) - Date.parse(b.createTime)},
                            {title:"创建人",dataIndex:"creator",width:120,ellipsis:true,key:'creator',isSearch:true,sorter: (a, b) =>StringCompareSort(a.creator,b.creator),
                                render:(text, record) => <Row>
                                    {text instanceof Array?text.map((tag,index)=>{
                                        const tagElem = (<Tag key={tag} color={"geekblue"}>{tag}</Tag>);
                                        return (tagElem);
                                        }):text?(<Tag key={text} color={"geekblue"}>{text}</Tag>):null}
                                </Row>},
                            {title:'操作',key:'actions',width:150,fixed: 'right', render: (text, record) =>false === this.props.editEnable?
                            <span><Button type="link" onClick={()=>this.showCollection(record)}>查看</Button></span>:
                            <Row>
                                <Col span={8}><Button size='small' type="link" onClick={()=>this.showCollection(record)}>查看</Button></Col>
                                <Col span={8}><Button size='small' type="link" onClick={()=>this.handleOnEdit(record)}>编辑</Button></Col>
                                <Col span={8}><Button size='small' type="link" style={{color:"red"}} onClick={()=>this.handleDelete(record.uuid)}>删除</Button></Col>
                            </Row>}]
        let dataSource = [];
        let exportDataSource = [];
        for (let i = 0;i < this.props.dataSource.length;i++){
            let record = this.props.dataSource[i];
            let detail = record.detail;
            let isInvoice = "";
            let manager = account2Name(detail.manager_account); 
            let creator = account2Name(record.creator_account);
            invoiceList.forEach((it)=>{
                if(it.value === detail.is_invoice){
                    isInvoice = it.text;
                }
            });
            dataSource.push({index:i+1,
                            uuid:record.uuid,
                            title:record.title,
                            custom:detail.custom_name,
                            amount:record.amount,
                            collectionTime:moment(record.collection_time).format('YYYY-MM-DD HH:mm:ss'),
                            isInvoice:isInvoice,
                            creator:creator,
                            manager:manager,
                            createTime:moment(record.create_time).format('YYYY-MM-DD HH:mm:ss')});
            exportDataSource.push({ '序号':i+1,
                                    '客户':record.custom_name, 
                                    '收款名称':record.title, 
                                    '金额':record.amount, 
                                    '收款时间':moment(record.collection_time).format('YYYY-MM-DD HH:mm:ss'),
                                    '相关订单':detail.order_id,
                                    '是否有发票':isInvoice,
                                    '审核人':manager,
                                    '创建时间':moment(record.create_time).format('YYYY-MM-DD HH:mm:ss'),
                                    '创建人':creator})                
        }
        return(<Row>
                {this.renderExportModal(dataSource,exportDataSource)}
                <ExtendTable dataSource={dataSource} handleAddOnClick={this.handleAddOnClick} onBatchDelete={this.handleEmptySelect} 
                    customColumnsEnable={this.props.customColumnsEnable} tableKey={this.props.tableKey} columns={showColumns}
                    rowKey={record => record.uuid} exportEnable={this.props.exportEnable} exportTitle={"导出收款记录"} exportClick={()=>this.setState({showExportModal:true})}/>
                </Row>);
    }
    render() {
        return (<Row>
            <Row>{this.renderTable()}</Row>
            <Divider/>
            <Row>{this.renderSetting()}</Row>
        </Row>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(CollectionTable);