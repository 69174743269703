import React from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {Row,Col, Input, Form, Modal, DatePicker, Divider, Button,Tag,Radio,InputNumber} from "antd";
import {PlusCircleOutlined,MinusCircleOutlined} from '@ant-design/icons';
import {addShip, deleteShip, updateShip, batchDeleteShip} from "../../actions/shipDBTool";
import { getAllProduct } from '../../actions/productDBTool';
import {connect} from "react-redux";
import XLSX from 'xlsx';
import moment from "moment";
import {invoiceList, SHIP_STATUS_UN_START, shipStatusList} from "../../constants/constant";
import ExtendSelect from "./ExtendSelect";
import ExtendTable from "./ExtendTable";
import StaffChoose from "./StaffChoose";
import CustomChooseSelect from "./CustomChooseSelect";
import ProductChooseSelect from "./ProductChooseSelect";
import {account2Name, name2Account, Uuid2ProductName, productName2Uuid, customName2Uuid} from "../../utils/accountUtils";
import { StringCompareSort } from "../../utils/common";
import FileManage from './FileManage';

const {confirm} = Modal;
//const exportOptions = ['序号','客户名称', '发货时间', '发货产品', '订单号', '金额','状态','是否开具发票','抄送给','负责人','创建时间','备注'];
const ShipForm = Form.create({name:'ship_modal'})(
    class extends React.Component{
        constructor(props) {
            super(props);
            this.state = {};
        }
        add = ()=>{
            if(2 === this.props.mode){return}
            const { form } = this.props;
            const keys = form.getFieldValue("product_detail_keys");
            if(keys.length >= 100){return}
            const nextKeys = keys.concat(keys.length);
            form.setFieldsValue({product_detail_keys: nextKeys});
            console.log(keys)
        }
        remove = k => {
            if(2 === this.props.mode){return}
            const { form } = this.props;
            const keys = form.getFieldValue("product_detail_keys");
            if (keys.length === 1) {return;}
            form.setFieldsValue({
                product_detail_keys: keys.filter(key => key !== k)
            });
        }
        renderDynamicProduct(values,mode){
            const { form } = this.props;
            let defaultValue = [];
            let { getFieldDecorator } = form;
            if(values.productDetail){
                for (let i = 0;i < values.productDetail.length;i++){
                    defaultValue.push(i);
                }
            }
            else{
                defaultValue.push(0);
            }
            getFieldDecorator("product_detail_keys", {initialValue: mode?defaultValue:[0]});
            const keys = form.getFieldValue("product_detail_keys");
            return(keys.map((key, index) =>
                <Row key={index} gutter={[0,0]}>
                    <Col span={3} offset={1}>
                        <Form.Item>
                            {getFieldDecorator(`productNumber[${index}]`, {initialValue:mode&&values.productDetail&&values.productDetail[index]?Uuid2ProductName(values.productDetail[index].product_number):""})
                                (<Input title={"商品编号"} disabled={2 === mode?true:false} style={{width:'140px'}} size={"small"}/>)}
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item>
                            {getFieldDecorator(`productName[${index}]`, {rules: [{ required: true, message: '商品名称不能为空'}],initialValue:mode&&values.productDetail&&values.productDetail[index]?Uuid2ProductName(values.productDetail[index].product_uuid):""})
                                (<ProductChooseSelect title={"商品名称"} disabled={2 === mode?true:false} style={{width:'140px'}} size={"small"}/>)}
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item>
                            {getFieldDecorator(`standards[${index}]`, {initialValue:mode&&values.productDetail&&values.productDetail[index]?values.productDetail[index].standards:""})
                                (<Input disabled={2 === mode?true:false} style={{width:'130px'}} size={"small"}/>)}
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item>
                            {getFieldDecorator(`unit[${index}]`, {initialValue:mode&&values.productDetail&&values.productDetail[index]?values.productDetail[index].unit:"个"})
                                (<Input disabled={2 === mode?true:false} style={{width:'90px'}} size={"small"}/>)}
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item>
                            {getFieldDecorator(`count[${index}]`, {rules:[{ required: true, message: '数量不能为空' }],initialValue:mode&&values.productDetail&&values.productDetail[index]?values.productDetail[index].count:1})(
                                <InputNumber min={1} disabled={2 === mode?true:false} style={{width:'90px'}} size={"small"} onChange={(value)=>{
                                    this.props.form.setFieldsValue({[`amount[${index}]`]:value*this.props.form.getFieldValue(`price[${index}]`)})
                                }}/>)}
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item>
                            {getFieldDecorator(`netweight[${index}]`, {initialValue:mode&&values.productDetail&&values.productDetail[index]?values.productDetail[index].netweight:0})
                                (<InputNumber min={0} step={0.1} disabled={2 === mode?true:false} style={{width:'90px'}} size={"small"}/>)}
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item>
                            {getFieldDecorator(`grossweight[${index}]`, {initialValue:mode&&values.productDetail&&values.productDetail[index]?values.productDetail[index].grossweight:0})
                                (<InputNumber min={0} step={0.1} disabled={2 === mode?true:false} style={{width:'90px'}} size={"small"}/>)}
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item>
                            {getFieldDecorator(`price[${index}]`, {initialValue:mode&&values.productDetail&&values.productDetail[index]?values.productDetail[index].price:0})
                                (<InputNumber min={0} step={0.1} disabled={2 === mode?true:false} style={{width:'90px'}} size={"small"} onChange={(value)=>{
                                    this.props.form.setFieldsValue({[`amount[${index}]`]:value*this.props.form.getFieldValue(`count[${index}]`)})
                                }}/>)}
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item>
                            {getFieldDecorator(`amount[${index}]`, {initialValue:mode&&values.productDetail&&values.productDetail[index]?values.productDetail[index].amount:0})
                                (<InputNumber min={0} step={0.1} disabled={2 === mode?true:false} style={{width:'100px'}} size={"small"}/>)}&nbsp;
                                {index === 0?(<PlusCircleOutlined style={{color:'blue'}} onClick={() => this.add()}/>):(<MinusCircleOutlined style={{color:'red'}} onClick={() => this.remove(key)}/>)}
                        </Form.Item>
                    </Col>
                </Row>))
        }
        renderDynamicProductTitle(){
            return(<Row gutter={[0,0]}>
                    <Col span={3} offset={1}>
                        <span>商品编号</span>
                    </Col>
                    <Col span={3}>
                        <span>商品名称</span>
                    </Col>
                    <Col span={3}>
                        <span>商品规格</span>
                    </Col>
                    <Col span={2}>
                        <span>单位</span>
                    </Col>
                    <Col span={2}>
                        <span>数量</span>
                    </Col>
                    <Col span={2}>
                        <span>净重(千克)</span>
                    </Col>
                    <Col span={2}>
                        <span>毛重(千克)</span>
                    </Col>
                    <Col span={2}>
                        <span>单价</span>
                    </Col>
                    <Col span={4}>
                        <span>金额</span>
                    </Col>
                </Row>)
        }
        render(){
            let { visible, onCancel, onOk, form, values,mode,title } = this.props;
            let { getFieldDecorator } = form;
            let formItemLayout = {labelCol: {span:6}, wrapperCol: {span:16}};
            let currentTime = new Date();
            return(<Modal title={title} visible={visible} width={1400} maskClosable={false}
                        onOk={onOk} onCancel={onCancel} okText={2===mode?"关闭":"确定"} cancelText={"取消"}>
                <Form {...formItemLayout}>
                    <Row>
                        <Col span={11}>
                            <Form.Item label={"标题"}>
                            {getFieldDecorator('shipTitle', {rules: [{required: true, message: '标题不能为空!'}],initialValue: mode ? values.shipTitle: ''})
                                (<Input placeholder={"例如:XX商品发货单"} disabled={2 === mode?true:false}/>)}
                            </Form.Item>
                            <Form.Item label={"客户名称"}>
                                {getFieldDecorator('customName', {initialValue: mode ? values.customName: ''})
                                    (<CustomChooseSelect disabled={2 === mode?true:false}/>)}
                            </Form.Item>
                            <Form.Item label={"收货地址"}>
                                {getFieldDecorator('receiveAddress', { rules:[{required:true,message:"收货地址不能为空"}],initialValue:mode?values.receiveAddress:""})
                                    (<Input placeholder="请输入收货地址" disabled={2 === mode?true:false}/>)}
                            </Form.Item>
                            <Form.Item label={"发货状态"}>
                                {getFieldDecorator('status', {initialValue: mode?values.status:SHIP_STATUS_UN_START})
                                    (<ExtendSelect items={shipStatusList} title={"状态"} placeholder={"请输入自定义的状态"} disabled={2 === mode?true:false}/>)}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={"相关订单"}>
                                {getFieldDecorator('orderID', {initialValue: mode ? values.orderID: ''})
                                    (<Input placeholder="请输入相关订单,多个订单用逗号隔开" disabled={2 === mode?true:false}/>)}
                            </Form.Item>
                            <Form.Item label={"收货人"} >
                                {getFieldDecorator('receiverName', {rules:[{ required: true,message:'收货人不能为空!' }],initialValue:mode?values.receiverName:''})
                                (<Input placeholder="输入收货人的名称" disabled={2 === mode?true:false}/>)}
                            </Form.Item>
                            <Form.Item label={"联系电话"} >
                                {getFieldDecorator('receiverContact', {rules:[{ required: true,message:'联系电话不能为空!' }],initialValue:mode?values.receiverContact:''})
                                (<Input placeholder="输入收货人的联系电话" disabled={2 === mode?true:false}/>)}
                            </Form.Item>
                            <Form.Item label={"发货时间"}>
                            {getFieldDecorator('sendTime',{rules: [{ required: true,message:'创建时间不能为空!' }],
                                initialValue:mode? moment(new Date(values.sendTime), 'YYYY-MM-DD'):moment(currentTime, 'YYYY-MM-DD')})
                                (<DatePicker placeholder={"选择发货时间"} showTime disabled={2 === mode?true:false}/>)}
                            </Form.Item>
                        </Col>
                    </Row>
                    {this.renderDynamicProductTitle()}
                    <div style={{maxHeight:260,overflow:'auto'}}>{this.renderDynamicProduct(values,mode)}</div>
                    <Row>
                        <Col span={12}>
                            <Form.Item label={"特别备注"}>
                                {getFieldDecorator('note', {initialValue:mode?values.note:""})
                                    (<ReactQuill placeholder={"XXX"} readOnly={2 === mode?true:false}/>)}
                            </Form.Item>
                            <Form.Item label={"相关文档"}>
                                {getFieldDecorator('fileUrl', {initialValue:mode?values.file_url:""})
                                    (<FileManage filePrefix={"ship/"+values.uuid+"/"} fileList={values.file_url} disabled={1 < mode?true:false}/>)}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                                <Form.Item label={"负责人"} >
                                    {getFieldDecorator('manager', {rules:[{ required: true,message:'负责人不能为空!' }],initialValue:mode?values.manager:''})
                                    (<StaffChoose mode={0} disabled={2 === mode?true:false}/>)}
                                </Form.Item>
                                <Form.Item label={"抄送给"} >
                                    {getFieldDecorator('assigner', {rules:[],initialValue:mode?values.assigner:''})
                                    (<StaffChoose mode={1} disabled={2 === mode?true:false}/>)}
                                </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>);
        }
    }
)
function mapStateToProps(state) {
    return {...state.ship,...state.product}
}
function mapDispatchToProps(dispatch) {
    return {onGetAllProduct:()=>dispatch(getAllProduct()),
            onAddShip:(postData)=>dispatch(addShip(postData)),
            onEditShip:(postData)=>dispatch(updateShip(postData)),
            onDeleteShip:(uuid)=>dispatch(deleteShip(uuid)),
            onBatchDelete:(uuid_list)=>dispatch(batchDeleteShip(uuid_list))
        }
}
class ShipTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {showModal:false,modalMode:0,modalTitle:'新增发货单',
                        exportType:'xlsx',
                        vcfSize:'0',
                        //exportColumns:exportOptions,
                        modalValues:{uuid:Date.parse(new Date())/1000+(Math.round(Math.random() * (9000))+999)}}
        this.showContent = this.showContent.bind(this);
        this.updateModalValues = this.updateModalValues.bind(this);
        this.OnClickExport = this.OnClickExport.bind(this);
    }
    componentDidMount(){
        this.props.onGetAllProduct();
    }
    saveFormRef = formRef => {this.formRef = formRef;}
    handleEmptySelect=(uuid_list)=>{
        this.props.onBatchDelete(uuid_list);
    }
   /* OnChangeColumn=(checkedValues)=>{
        this.setState({exportColumns:checkedValues});
    }*/
    OnChangeExportFormat=(e)=>{
        this.setState({exportType:e.target.value})
    }
    OnClickExport(){
        for (let i = 0;i < this.props.dataSource.length;i++){
            let exportDataSource = [];
            let item = this.props.dataSource[i]
            let itemDetail = item.detail;
            let productDetail = itemDetail.product_detail?itemDetail.product_detail:[]
            let productArray = []
            for(let j = 0;j < productDetail.length;j++){
                productArray.push({ '商品编号':productDetail[j].product_number,
                                    '商品名称':Uuid2ProductName(productDetail[j].product_uuid),
                                    '商品规格':productDetail[j].standards,
                                    '计量单位':productDetail[j].unit,
                                    '商品数量':productDetail[j].count,
                                    '净重':productDetail[j].price,
                                    '毛重':productDetail[j].amount,
                                    '单价':productDetail[j].rate,
                                    '金额':productDetail[j].rate_amount
                                })
            }
            let assigner = account2Name(itemDetail.assigner_account);
            let manager = account2Name(itemDetail.manager_account)
            let creator = account2Name(item.creator_account);
            let isInvoice = "";
            invoiceList.forEach((it)=>{
                if(itemDetail.is_invoice === it.value){
                    isInvoice = it.text;
                }
            });
            exportDataSource.push({
            '标题':item.title,
            '客户名称':itemDetail.custom_name,
            '发货时间':moment(new Date(itemDetail.send_time)).format('YYYY-MM-DD HH:mm:ss'),
            '订单号':itemDetail.order_id, 
            '商品总数':itemDetail.count, 
            '总金额(元)':item.amount,
            '状态':item.status,
            '开具发票':isInvoice,
            '抄送给':assigner,
            '负责人':manager,
            '创建时间':moment(new Date(item.create_time)).format('YYYY-MM-DD HH:mm:ss'),
            '创建人':creator,
            '备注':itemDetail.note
        })
            let wb = XLSX.utils.book_new();
            let fileName = itemDetail.custom_name+"发货单.";
            fileName += this.state.exportType;
            let jsonWs = XLSX.utils.json_to_sheet(exportDataSource,{defval:true});
            /*jsonWs['!merges'] = [
                {
                    e: { c: 5, r: 0 },
                    s: { c: 0, r: 0 }  // 表示A1到F1单元格合并
                }]*/
            XLSX.utils.sheet_add_json(jsonWs, productArray, {skipHeader: false, origin: { r: 3, c: 0 }, header: [ "商品编号","商品名称","商品规格","计量单位","商品数量","商品单价","商品金额","商品税率","金额(含税)"]});
            XLSX.utils.book_append_sheet(wb, jsonWs, '发货单内容');
            XLSX.writeFile(wb, fileName);
        }
    }
    handleAddOnClick=()=>{
        this.setState({showModal:true,modalMode:0,modalTitle:'新增发货单',modalValues:{uuid:Date.parse(new Date())/1000+(Math.round(Math.random() * (9000))+999)}})
    }
    showContent(record){
        for (let i = 0;i < this.props.get_ship_result.length;i++){
            let item = this.props.get_ship_result[i];
            if(item.uuid === record.uuid){
                this.updateModalValues(item);
                this.setState({showModal:true,modalMode:2,modalTitle:'查看发货单'})
                return;
            }
        }
    }
    updateModalValues(values){
        let detail = values.detail;
        console.log(detail)
        this.setState({modalValues:{uuid:values.uuid,
                        shipTitle:values.title,
                        customName:detail.custom_name,
                        orderID:detail.order_id,
                        sendTime:detail.send_time,
                        receiveAddress:detail.receive_address,
                        receiverName:detail.receiver_name,
                        receiverContact:detail.receiver_contact,
                        type:1,
                        status:values.status,
                        file_url:detail.file_url?detail.file_url:[],
                        note:detail.note,
                        productDetail:detail.product_detail,
                        manager:account2Name(detail.manager_account),
                        assigner:account2Name(detail.assigner_account),
                        createTime:values.create_time,
                    }});
    }
    handleOnEdit=(record)=>{
        for (let i = 0;i < this.props.get_ship_result.length;i++){
            let item = this.props.get_ship_result[i];
            if(item.uuid === record.uuid){
                this.updateModalValues(item);
                this.setState({showModal:true,modalMode:1,modalTitle:'更新发货单'})
                return;
            }
        }
    }
    handleDelete=(uuid)=>{
        confirm({
            content: '您确定要删除该发货单?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk:()=> {
                this.props.onDeleteShip(uuid)
            },
            onCancel() {
                console.log('Cancel');
            },
        })
    }
    handleCancel=()=>{
        const { form } = this.formRef.props;
        form.resetFields();
        this.setState({showModal:false})
    }
    handleSubmit=()=>{
        if(2 === this.state.modalMode){
            this.setState({ showModal: false });
            return;
        }
        const { form } = this.formRef.props;
        form.validateFields((err, fromValues) => {
            if(err){return;}
            let assigner_account = name2Account(fromValues.assigner);
            let manager_account = name2Account(fromValues.manager);
            let product_detail = []
            let productKind = fromValues.productName.length
            let totalAmount = 0
            let totalCount = 0
            for(let i = 0;i < productKind;i++){
                product_detail.push({
                    product_number:fromValues.productNumber[i],
                    product_uuid:productName2Uuid(fromValues.productName[i]),
                    price:fromValues.price[i],
                    count: fromValues.count[i],
                    standards:fromValues.standards[i],
                    unit:fromValues.unit[i],
                    netweight:fromValues.netweight[i],
                    grossweight:fromValues.grossweight[i],
                    amount:fromValues.amount[i]
                })
                totalAmount += fromValues.amount[i];
                totalCount += fromValues.count[i];
            }
            let detail = {
                custom_uuid:customName2Uuid(fromValues.customName),
                custom_name:fromValues.customName,
                count:totalCount,
                order_id:fromValues.orderID,
                send_time:Date.parse(fromValues.sendTime),
                receive_address:fromValues.receiveAddress,
                receiver_name:fromValues.receiverName,
                receiver_contact:fromValues.receiverContact,
                product_detail:product_detail,
                file_url:fromValues.fileUrl,
                note:fromValues.note,
                assigner_account:assigner_account,
                manager_account:manager_account
            };
            let current = new Date();
            let postData = {
                uuid:this.state.modalValues.uuid,
                enterprise_uuid:localStorage.getItem('enterprise_uuid'),
                title:fromValues.shipTitle,
                amount:totalAmount,
                type:'1',
                detail:JSON.stringify(detail),
                status:fromValues.status,
                creator_account:localStorage.getItem('account'),
                create_time:Date.parse(current),
            };
            if(this.state.modalMode){
                this.props.onEditShip(postData);
            }
            else{
                this.props.onAddShip(postData);
            }
            let modalValues = {
                title:fromValues.shipTitle,
                amount:fromValues.amount,
                type:'1',
                detail:detail,
                status:fromValues.status,
                create_time:Date.parse(current),
            }
            this.updateModalValues(modalValues);
            this.setState({ showModal: false });
            form.resetFields();
        })
    }
    renderExportModal=(dataSource,exportDataSource)=>{
        return (<Modal title="导出结果选项" visible={this.state.showExportModal} width={1000}
                        cancelText={"取消"} onCancel={()=>{this.setState({showExportModal:false})}}
                        okText={"导出"} onOk={()=>{this.OnClickExport()}} >
                <Radio.Group onChange={this.OnChangeExportFormat} defaultValue={'xlsx'}>
                    <Radio value={'xlsx'}>Excel(.xlsx)</Radio>
                    <Radio value={'xls'}>Excel(.xls)</Radio>
                    <Radio value={'csv'}>CSV</Radio>
                    <Radio value={'txt'}>文本(TXT)</Radio>
                </Radio.Group>
        </Modal>);
    }
    renderStatusStyleText(text){
        const statusColor = ["","#2db7f5","#87d068",];
        return (<Tag color={statusColor[shipStatusList.indexOf(text)]}>{text}</Tag>)
    }
    renderSetting() {
        return(<ShipForm wrappedComponentRef={this.saveFormRef} visible={this.state.showModal} 
                values={this.state.modalValues} mode={this.state.modalMode} title={this.state.modalTitle}
                onCancel={this.handleCancel} onOk={this.handleSubmit}/>);
    }
    renderTable(){
        const shipStatus = [];
        shipStatusList.forEach((item)=>{shipStatus.push({text:item,value:item})});
        let showColumns =  [{title:"客户",dataIndex:'custom',key:'custom',width:150,ellipsis:true,isSearch:true,sorter: (a, b) =>StringCompareSort(a.custom,b.custom)},
            {title:"发货时间",dataIndex:'sendTime',key:'sendTime',width:180,ellipsis:true,isSearch:true,sorter: (a, b) =>Date.parse(a.sendTime)- Date.parse(b.sendTime)},
            {title:"订单号",dataIndex:'orderID',key:'orderID',width:120,ellipsis:true,isSearch:true,sorter:(a,b)=>StringCompareSort(a.orderID,b.orderID)},
            {title:"产品总数",dataIndex:'count',key:'count',width:120,ellipsis:true,isSearch:true,sorter:(a,b)=>StringCompareSort(a.product,b.product)},
            {title:"总金额(元)",dataIndex:"money",key:'money',width:120,ellipsis:true,isSearch:true,sorter:(a,b)=>a.money - b.money},
            {title:"状态",dataIndex:"status",key:'status',width:120,ellipsis:true,filters:shipStatus,onFilter: (value, record) => record.status.indexOf(value) === 0,
                sorter: (a, b) => StringCompareSort(a.status,b.status),render:(text)=>this.renderStatusStyleText(text)},
            {title:"开具发票",dataIndex:"isInvoice",key:'isInvoice',width:100,ellipsis:true,filters:[{text:"是",value:"是"},{text:"否",value:"否"}],
                onFilter: (value, record) => record.isInvoice.indexOf(value) === 0},
            {title:"抄送给",dataIndex:"assigner",key:'assigner',width:120,ellipsis:true,isSearch:true,sorter:(a,b)=>StringCompareSort(a.assigner,b.assigner),
                render:(text, record) =><Row>
                    {text instanceof Array?text.map((tag,index)=>{
                        const tagElem = (<Tag key={tag} color={"geekblue"}>{tag}</Tag>);
                        return (tagElem);
                    }):text?(<Tag key={text} color={"geekblue"}>{text}</Tag>):null}
                </Row>},
            {title:"负责人",dataIndex:"manager",key:'manager',width:120,ellipsis:true,isSearch:true,sorter:(a,b)=>StringCompareSort(a.manager,b.manager),
            render:(text, record) =><Row>
                {text instanceof Array?text.map((tag,index)=>{
                    const tagElem = (<Tag key={tag} color={"geekblue"}>{tag}</Tag>);
                    return (tagElem);
                }):text?(<Tag key={text} color={"geekblue"}>{text}</Tag>):null}
            </Row>},
            {title: '创建人',dataIndex:'creator',key:'creator',width:120,ellipsis:true,isSearch:true,sorter: (a, b) => a.creator.toString().localeCompare(b.creator.toString()),
                render:(text, record) => <Row>{text?(<Tag key={text} color={"geekblue"}>{text}</Tag>):null}</Row>},
            {title:"创建时间",dataIndex:"createTime",key:'createTime',width:180,ellipsis:true,sorter: (a, b) => Date.parse(a.createTime) - Date.parse(b.createTime)},
            {title:'操作',key:'actions',width:150,fixed: 'right', render: (text, record) =>false === this.props.editEnable?
            <span><Button type="link" onClick={()=>this.showContent(record)}>查看</Button></span>:
            <Row>
                <Col span={8}><Button size='small' type="link" onClick={()=>this.showContent(record)}>查看</Button></Col>
                <Col span={8}><Button size='small' type="link" onClick={()=>this.handleOnEdit(record)}>编辑</Button></Col>
                <Col span={8}><Button size='small' type="link" style={{color:'red'}} onClick={()=>this.handleDelete(record.uuid)}>删除</Button></Col>
            </Row>}];
        let dataSource = [];
        for (let i = 0;i < this.props.dataSource.length;i++){
            let record = this.props.dataSource[i];
            let detail = record.detail;
            let assigner = account2Name(detail.assigner_account);
            let manager = account2Name(detail.manager_account)
            let creator = account2Name(record.creator_account);
            let isInvoice = "";
            invoiceList.forEach((it)=>{
                if(detail.is_invoice === it.value){
                    isInvoice = it.text;
                }
            });
            dataSource.push({index:i+1,
                uuid: record.uuid,
                title:record.title,
                custom:detail.custom_name,
                sendTime:moment(new Date(detail.send_time)).format('YYYY-MM-DD HH:mm:ss'),
                count:detail.count,
                orderID:detail.order_id,
                money: record.amount,
                status: record.status,
                isInvoice:isInvoice,
                note:detail.note,
                assigner:assigner,
                manager:manager,
                creator: creator,
                createTime: moment(new Date(record.create_time)).format('YYYY-MM-DD HH:mm:ss')
            });
            
        }
        return(<Row>
                {this.renderExportModal()}
                <ExtendTable handleAddOnClick={this.handleAddOnClick} onBatchDelete={this.handleEmptySelect} 
                    customColumnsEnable={this.props.customColumnsEnable} tableKey={this.props.tableKey} columns={showColumns} 
                    dataSource={dataSource} rowKey={record => record.uuid}
                    exportEnable={this.props.exportEnable} exportTitle={"导出发货单"} exportClick={()=>this.setState({showExportModal:true})}/>
            </Row>);
    }
    render() {
        return (<Row>
                <Row>{this.renderTable()}</Row>
                <Divider/>
                {this.renderSetting()}
            </Row>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(ShipTable);