export const ActionType =({
    LOGIN_START :'LOGIN_START',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILED : 'LOGIN_FAILED',

    GET_USER_INFO:'GET_USER_INFO',
    
    REGISTER_START : 'REGISTER_START',
    REGISTER_SUCCESS : 'REGISTER_SUCCESS',
    REGISTER_FAILED : 'REGISTER_FAILED',
    
    
    LOGOUT : 'LOGOUT',
    
    GET_CUSTOM_BY_USER_START : 'GET_CUSTOM_BY_USER_START',
    GET_CUSTOM_BY_USER_SUCCESS : 'GET_CUSTOM_BY_USER_SUCCESS',
    GET_CUSTOM_BY_USER_FAILED : 'GET_CUSTOM_BY_USER_FAILED',

    GET_CUSTOM_FILTER_RESULT:'GET_CUSTOM_FILTER_RESULT',

    GET_COMMON_CUSTOM_SUCCESS:'GET_COMMON_CUSTOM_SUCCESS',
    GET_COMMON_CUSTOM_START:'GET_COMMON_CUSTOM_START',
    GET_COMMON_CUSTOM_FAILED:'GET_COMMON_CUSTOM_FAILED',

    GET_CUSTOM_DEL_START : 'GET_CUSTOM_DEL_START',
    GET_CUSTOM_DEL_SUCCESS : 'GET_CUSTOM_DEL_SUCCESS',
    GET_CUSTOM_DEL_FAILED : 'GET_CUSTOM_DEL_FAILED',

    CUSTOM_RESTORE_START : 'CUSTOM_RESTORE_START',
    CUSTOM_RESTORE_SUCCESS : 'CUSTOM_RESTORE_SUCCESS',
    CUSTOM_RESTORE_FAILED : 'CUSTOM_RESTORE_FAILED',
    ADD_CUSTOM_START : 'ADD_CUSTOM_START',
    ADD_CUSTOM_SUCCESS : 'ADD_CUSTOM_SUCCESS',
    ADD_CUSTOM_FAILED : 'ADD_CUSTOM_FAILED',
    UPDATE_CUSTOM_START : 'UPDATE_CUSTOM_START',
    UPDATE_CUSTOM_SUCCESS : 'UPDATE_CUSTOM_SUCCESS',
    UPDATE_CUSTOM_FAILED : 'UPDATE_CUSTOM_FAILED',
    DELETE_CUSTOM_START : 'DELETE_CUSTOM_START',
    DELETE_CUSTOM_SUCCESS : 'DELETE_CUSTOM_SUCCESS',
    DELETE_CUSTOM_FAILED : 'DELETE_CUSTOM_FAILED',
    
    ADD_FOLLOW_UP_START : 'ADD_FOLLOW_UP_START',
    ADD_FOLLOW_UP_SUCCESS : 'ADD_FOLLOW_UP_SUCCESS',
    ADD_FOLLOW_UP_FAILED : 'ADD_FOLLOW_UP_FAILED',
    GET_FOLLOW_UP_START : 'GET_FOLLOW_UP_START',
    GET_FOLLOW_UP_SUCCESS : 'GET_FOLLOW_UP_SUCCESS',
    GET_FOLLOW_UP_FAILED : 'GET_FOLLOW_UP_FAILED',
    GET_FOLLOW_UP_BY_CUSTOM_START : 'GET_FOLLOW_UP_BY_CUSTOM_START',
    GET_FOLLOW_UP_BY_CUSTOM_SUCCESS : 'GET_FOLLOW_UP_BY_CUSTOM_SUCCESS',
    GET_FOLLOW_UP_BY_CUSTOM_FAILED : 'GET_FOLLOW_UP_BY_CUSTOM_FAILED',
    
    UPDATE_FOLLOW_UP_START : 'UPDATE_FOLLOW_UP_START',
    UPDATE_FOLLOW_UP_SUCCESS : 'UPDATE_FOLLOW_UP_SUCCESS',
    UPDATE_FOLLOW_UP_FAILED : 'UPDATE_FOLLOW_UP_FAILED',
    DELETE_FOLLOW_UP_START : 'DELETE_FOLLOW_UP_START',
    DELETE_FOLLOW_UP_SUCCESS : 'DELETE_FOLLOW_UP_SUCCESS',
    DELETE_FOLLOW_UP_FAILED : 'DELETE_FOLLOW_UP_FAILED',
    
    GET_ENTERPRISE_START : 'GET_ENTERPRISE_START',
    GET_ENTERPRISE_SUCCESS : 'GET_ENTERPRISE_SUCCESS',
    GET_ENTERPRISE_FAILED : 'GET_ENTERPRISE_FAILED',
    UPDATE_ENTERPRISE_START : 'UPDATE_ENTERPRISE_START',
    UPDATE_ENTERPRISE_SUCCESS : 'UPDATE_ENTERPRISE_SUCCESS',
    UPDATE_ENTERPRISE_FAILED : 'UPDATE_ENTERPRISE_FAILED',
    
    ADD_ORDER_START : 'ADD_ORDER_START',
    ADD_ORDER_SUCCESS : 'ADD_ORDER_SUCCESS',
    ADD_ORDER_FAILED : 'ADD_ORDER_FAILED',
    UPDATE_ORDER_START: 'UPDATE_ORDER_START',
    UPDATE_ORDER_SUCCESS : 'UPDATE_ORDER_SUCCESS',
    UPDATE_ORDER_FALSE : 'UPDATE_ORDER_FALSE',
    ADD_ORDER_DEL_START : 'ADD_ORDER_DEL_START',
    ADD_ORDER_DEL_SUCCESS : 'ADD_ORDER_DEL_SUCCESS',
    ADD_ORDER_DEL_FAILED : 'ADD_ORDER_DEL_FAILED',
    ORDER_RESTORE_START : 'ORDER_RESTORE_START',
    ORDER_RESTORE_SUCCESS : 'ORDER_RESTORE_SUCCESS',
    ORDER_RESTORE_FAILED : 'ORDER_RESTORE_FAILED',
    DELETE_ORDER_START : 'DELETE_ORDER_START',
    DELETE_ORDER_SUCCESS : 'DELETE_ORDER_SUCCESS',
    DELETE_ORDER_FAILED : 'DELETE_ORDER_FAILED',
    GET_ORDER_DEL_START : 'GET_ORDER_DEL_START',
    GET_ORDER_DEL_SUCCESS : 'GET_ORDER_DEL_SUCCESS',
    GET_ORDER_DEL_FAILED : 'GET_ORDER_DEL_FAILED',
    GET_ORDER_BY_USER_START : 'GET_ORDER_BY_USER_START',
    GET_ORDER_BY_USER_FAILED : 'GET_ORDER_BY_USER_FAILED',
    GET_ORDER_BY_USER_SUCCESS : 'GET_ORDER_BY_USER_SUCCESS',
    
    GET_ORDER_BY_CUSTOM_START : 'GET_ORDER_BY_CUSTOM_START',
    GET_ORDER_BY_CUSTOM_FAILED : 'GET_ORDER_BY_CUSTOM_FAILED',
    GET_ORDER_BY_CUSTOM_SUCCESS : 'GET_ORDER_BY_CUSTOM_SUCCESS',
    
    GET_ORDER_AMOUNT_RATE_START:'GET_ORDER_AMOUNT_RATE_START',
    GET_ORDER_AMOUNT_RATE_SUCCESS:'GET_ORDER_AMOUNT_RATE_SUCCESS',
    GET_ORDER_AMOUNT_RATE_FAILED:'GET_ORDER_AMOUNT_RATE_FAILED',

    GET_ORDER_COUNT_RATE_START:'GET_ORDER_COUNT_RATE_START',
    GET_ORDER_COUNT_RATE_SUCCESS:'GET_ORDER_COUNT_RATE_SUCCESS',
    GET_ORDER_COUNT_RATE_FAILED:'GET_ORDER_COUNT_RATE_FAILED',

    GET_PERFORMANCE_GOAL_START:'GET_PERFORMANCE_GOAL_START',
    GET_PERFORMANCE_GOAL_SUCCESS:'GET_PERFORMANCE_GOAL_SUCCESS',
    GET_PERFORMANCE_GOAL_FAILED:'GET_PERFORMANCE_GOAL_FAILED',

    ADD_PERFORMANCE_GOAL_START:'ADD_PERFORMANCE_GOAL_START',
    ADD_PERFORMANCE_GOAL_SUCCESS:'ADD_PERFORMANCE_GOAL_SUCCESS',
    ADD_PERFORMANCE_GOAL_FAILED:'ADD_PERFORMANCE_GOAL_FAILED',

    UPDATE_PERFORMANCE_GOAL_START:'UPDATE_PERFORMANCE_GOAL_START',
    UPDATE_PERFORMANCE_GOAL_SUCCESS:'UPDATE_PERFORMANCE_GOAL_SUCCESS',
    UPDATE_PERFORMANCE_GOAL_FAILED:'UPDATE_PERFORMANCE_GOAL_FAILED',

    DELETE_PERFORMANCE_GOAL_START:'DELETE_PERFORMANCE_GOAL_START',
    DELETE_PERFORMANCE_GOAL_SUCCESS:'DELETE_PERFORMANCE_GOAL_SUCCESS',
    DELETE_PERFORMANCE_GOAL_FAILED:'DELETE_PERFORMANCE_GOAL_FAILED',
    
    GET_PRODUCT_BY_USER_START : 'GET_PRODUCT_BY_USER_START',
    GET_PRODUCT_BY_USER_SUCCESS : 'GET_PRODUCT_BY_USER_SUCCESS',
    GET_PRODUCT_BY_USER_FAILED : 'GET_PRODUCT_BY_USER_FAILED',
    
    GET_PRODUCT_DEL_START : 'GET_PRODUCT_DEL_START',
    GET_PRODUCT_DEL_SUCCESS : 'GET_PRODUCT_DEL_SUCCESS',
    GET_PRODUCT_DEL_FAILED : 'GET_PRODUCT_DEL_FAILED',

    ADD_PRODUCT_START : 'ADD_PRODUCT_START',
    ADD_PRODUCT_SUCCESS : 'ADD_PRODUCT_SUCCESS',
    ADD_PRODUCT_FAILED : 'ADD_PRODUCT_FAILED',
    ADD_PRODUCT_DEL_START : 'ADD_PRODUCT_DEL_START',
    ADD_PRODUCT_DEL_SUCCESS : 'ADD_PRODUCT_DEL_SUCCESS',
    ADD_PRODUCT_DEL_FAILED : 'ADD_PRODUCT_DEL_FAILED',
    UPDATE_PRODUCT_START : 'UPDATE_PRODUCT_START',
    UPDATE_PRODUCT_SUCCESS : 'UPDATE_PRODUCT_SUCCESS',
    UPDATE_PRODUCT_FAILED : 'UPDATE_PRODUCT_FAILED',
    PRODUCT_RESTORE_START : 'PRODUCT_RESTORE_START',
    PRODUCT_RESTORE_SUCCESS : 'PRODUCT_RESTORE_SUCCESS',
    PRODUCT_RESTORE_FAILED : 'PRODUCT_RESTORE_FAILED',
    DELETE_PRODUCT_START : 'DELETE_PRODUCT_START',
    DELETE_PRODUCT_SUCCESS : 'DELETE_PRODUCT_SUCCESS',
    DELETE_PRODUCT_FAILED : 'DELETE_PRODUCT_FAILED',
    
    GET_NOTICE_START : 'GET_NOTICE_BY_USER_START',
    GET_NOTICE_SUCCESS : 'GET_NOTICE_BY_USER_SUCCESS',
    GET_NOTICE_FAILED : 'GET_NOTICE_BY_USER_FAILED',
    ADD_NOTICE_START : 'ADD_NOTICE_START',
    ADD_NOTICE_SUCCESS : 'ADD_NOTICE_SUCCESS',
    ADD_NOTICE_FAILED : 'ADD_NOTICE_FAILED',
    ADD_NOTICE_DEL_START : 'ADD_NOTICE_DEL_START',
    ADD_NOTICE_DEL_SUCCESS : 'ADD_NOTICE_DEL_SUCCESS',
    ADD_NOTICE_DEL_FAILED : 'ADD_NOTICE_DEL_FAILED',
    UPDATE_NOTICE_START : 'UPDATE_NOTICE_START',
    UPDATE_NOTICE_SUCCESS : 'UPDATE_NOTICE_SUCCESS',
    UPDATE_NOTICE_FAILED : 'UPDATE_NOTICE_FAILED',
    DELETE_NOTICE_START : 'DELETE_NOTICE_START',
    DELETE_NOTICE_SUCCESS : 'DELETE_NOTICE_SUCCESS',
    DELETE_NOTICE_FAILED : 'DELETE_NOTICE_FAILED',
    NOTICE_RESTORE_START : 'NOTICE_RESTORE_START',
    NOTICE_RESTORE_SUCCESS : 'NOTICE_RESTORE_SUCCESS',
    NOTICE_RESTORE_FAILED : 'NOTICE_RESTORE_FAILED',
    
    GET_DEPARTMENT_BY_ENTERPRISE_START : 'GET_DEPARTMENT_BY_ENTERPRISE_START',
    GET_DEPARTMENT_BY_ENTERPRISE_SUCCESS : 'GET_DEPARTMENT_BY_ENTERPRISE_SUCCESS',
    GET_DEPARTMENT_BY_ENTERPRISE_FAILED : 'GET_DEPARTMENT_BY_ENTERPRISE_FAILED',
    
    UPDATE_DEPARTMENT_BY_ENTERPRISE_START : 'UPDATE_DEPARTMENT_BY_ENTERPRISE_START',
    UPDATE_DEPARTMENT_BY_ENTERPRISE_SUCCESS : 'UPDATE_DEPARTMENT_BY_ENTERPRISE_SUCCESS',
    UPDATE_DEPARTMENT_BY_ENTERPRISE_FAILED : 'UPDATE_DEPARTMENT_BY_ENTERPRISE_FAILED',
    
    GET_FEATURE_LIST_BY_ENTERPRISE_START : 'GET_FEATURE_LIST_BY_ENTERPRISE_START',
    GET_FEATURE_LIST_BY_ENTERPRISE_SUCCESS : 'GET_FEATURE_LIST_BY_ENTERPRISE_SUCCESS',
    GET_FEATURE_LIST_BY_ENTERPRISE_FAILED : 'GET_FEATURE_LIST_BY_ENTERPRISE_FAILED',
    
    UPDATE_FEATURE_LIST_BY_ENTERPRISE_START : 'UPDATE_FEATURE_LIST_BY_ENTERPRISE_START',
    UPDATE_FEATURE_LIST_BY_ENTERPRISE_SUCCESS : 'UPDATE_FEATURE_LIST_BY_ENTERPRISE_SUCCESS',
    UPDATE_FEATURE_LIST_BY_ENTERPRISE_FAILED : 'UPDATE_FEATURE_LIST_BY_ENTERPRISE_FAILED',
    UPDATE_FEATURE_LIST_BY_ENTERPRISE_LOCAL_START : 'UPDATE_FEATURE_LIST_BY_ENTERPRISE_LOCAL_START',
    
    UPDATE_SELECT_DEPARTMENT : 'UPDATE_SELECT_DEPARTMENT',
    
    GET_STAFF_BY_ENTERPRISE_START : 'GET_STAFF_BY_ENTERPRISE_START',
    GET_STAFF_BY_ENTERPRISE_FAILED : 'GET_STAFF_BY_ENTERPRISE_FAILED',
    GET_STAFF_BY_ENTERPRISE_SUCCESS : 'GET_STAFF_BY_ENTERPRISE_SUCCESS',
    GET_STAFF_DEL_BY_ENTERPRISE_START : 'GET_STAFF_DEL_BY_ENTERPRISE_START',
    GET_STAFF_DEL_BY_ENTERPRISE_FAILED : 'GET_STAFF_DEL_BY_ENTERPRISE_FAILED',
    GET_STAFF_DEL_BY_ENTERPRISE_SUCCESS : 'GET_STAFF_DEL_BY_ENTERPRISE_SUCCESS',
    RESTORE_STAFF_START : 'RESTORE_STAFF_START',
    RESTORE_STAFF_SUCCESS : 'RESTORE_STAFF_SUCCESS',
    RESTORE_STAFF_FAILED : 'RESTORE_STAFF_FAILED',
    UPDATE_STAFF_START : 'UPDATE_STAFF_START',
    UPDATE_STAFF_SUCCESS : 'UPDATE_STAFF_SUCCESS',
    UPDATE_STAFF_FAILED : 'UPDATE_STAFF_FAILED',
    UPDATE_PSD_START : 'UPDATE_PSD_START',
    UPDATE_PSD_SUCCESS : 'UPDATE_PSD_SUCCESS',
    UPDATE_PSD_FAILED : 'UPDATE_PSD_FAILED',
    ADD_STAFF_DEL_START : 'ADD_STAFF_DEL_START',
    ADD_STAFF_DEL_SUCCESS : 'ADD_STAFF_DEL_SUCCESS',
    ADD_STAFF_DEL_FAILED : 'ADD_STAFF_DEL_FAILED',
    ADD_STAFF_START : 'ADD_STAFF_START',
    ADD_STAFF_SUCCESS : 'ADD_STAFF_SUCCESS',
    ADD_STAFF_FAILED : 'ADD_STAFF_FAILED',
    DELETE_USER_START : 'DELETE_USER_START',
    DELETE_USER_SUCCESS : 'DELETE_USER_SUCCESS',
    DELETE_USER_FAILED : 'DELETE_USER_FAILED',
    
    ADD_TODO_WORK_START : 'ADD_TODO_WORK_START',
    ADD_TODO_WORK_SUCCESS : 'ADD_TODO_WORK_SUCCESS',
    ADD_TODO_WORK_FAILED : 'ADD_TODO_WORK_FAILED',
    GET_TODO_WORK_START : 'GET_TODO_WORK_START',
    GET_TODO_WORK_SUCCESS : 'GET_TODO_WORK_SUCCESS',
    GET_TODO_WORK_FAILED : 'GET_TODO_WORK_FAILED',
    UPDATE_TODO_WORK_START : 'UPDATE_TODO_WORK_START',
    UPDATE_TODO_WORK_SUCCESS : 'UPDATE_TODO_WORK_SUCCESS',
    UPDATE_TODO_WORK_FAILED : 'UPDATE_TODO_WORK_FAILED',
    DELETE_TODO_WORK_START : 'DELETE_TODO_WORK_START',
    DELETE_TODO_WORK_SUCCESS : 'DELETE_TODO_WORK_SUCCESS',
    DELETE_TODO_WORK_FAILED : 'DELETE_TODO_WORK_FAILED',
    
    GET_LEAVE_LIST_START : 'GET_LEAVE_LIST_START',
    GET_LEAVE_LIST_SUCCESS : 'GET_LEAVE_LIST_SUCCESS',
    GET_LEAVE_LIST_FAILED : 'GET_LEAVE_LIST_FAILED',
    ADD_LEAVE_LIST_START : 'ADD_LEAVE_LIST_START',
    ADD_LEAVE_LIST_SUCCESS : 'ADD_LEAVE_LIST_SUCCESS',
    ADD_LEAVE_LIST_FAILED : 'ADD_LEAVE_LIST_FAILED',
    UPDATE_LEAVE_LIST_START : 'UPDATE_LEAVE_LIST_START',
    UPDATE_LEAVE_LIST_SUCCESS : 'UPDATE_LEAVE_LIST_SUCCESS',
    UPDATE_LEAVE_LIST_FAILED : 'UPDATE_LEAVE_LIST_FAILED',
    DELETE_LEAVE_LIST_START : 'DELETE_LEAVE_LIST_START',
    DELETE_LEAVE_LIST_SUCCESS : 'DELETE_LEAVE_LIST_SUCCESS',
    DELETE_LEAVE_LIST_FAILED : 'DELETE_LEAVE_LIST_FAILED',
    
    
    ADD_TRAVEL_LIST_START : 'ADD_TRAVEL_LIST_START',
    ADD_TRAVEL_LIST_SUCCESS : 'ADD_TRAVEL_LIST_SUCCESS',
    ADD_TRAVEL_LIST_FAILED : 'ADD_TRAVEL_LIST_FAILED',
    GET_TRAVEL_LIST_START : 'GET_TRAVEL_LIST_START',
    GET_TRAVEL_LIST_SUCCESS : 'GET_TRAVEL_LIST_SUCCESS',
    GET_TRAVEL_LIST_FAILED : 'GET_TRAVEL_LIST_FAILED',
    UPDATE_TRAVEL_LIST_START : 'UPDATE_TRAVEL_LIST_START',
    UPDATE_TRAVEL_LIST_SUCCESS : 'UPDATE_TRAVEL_LIST_SUCCESS',
    UPDATE_TRAVEL_LIST_FAILED : 'UPDATE_TRAVEL_LIST_FAILED',
    DELETE_TRAVEL_LIST_START : 'DELETE_TRAVEL_LIST_START',
    DELETE_TRAVEL_LIST_SUCCESS : 'DELETE_TRAVEL_LIST_SUCCESS',
    DELETE_TRAVEL_LIST_FAILED : 'DELETE_TRAVEL_LIST_FAILED',
    
    
    ADD_SHIP_LIST_START : 'ADD_SHIP_LIST_START',
    ADD_SHIP_LIST_SUCCESS : 'ADD_SHIP_LIST_SUCCESS',
    ADD_SHIP_LIST_FAILED : 'ADD_SHIP_LIST_FAILED',
    GET_SHIP_LIST_START : 'GET_SHIP_LIST_START',
    GET_SHIP_LIST_SUCCESS : 'GET_SHIP_LIST_SUCCESS',
    GET_SHIP_LIST_FAILED : 'GET_SHIP_LIST_FAILED',
    UPDATE_SHIP_LIST_START : 'UPDATE_SHIP_LIST_START',
    UPDATE_SHIP_LIST_SUCCESS : 'UPDATE_SHIP_LIST_SUCCESS',
    UPDATE_SHIP_LIST_FAILED : 'UPDATE_SHIP_LIST_FAILED',
    DELETE_SHIP_LIST_START : 'DELETE_SHIP_LIST_START',
    DELETE_SHIP_LIST_SUCCESS : 'DELETE_SHIP_LIST_SUCCESS',
    DELETE_SHIP_LIST_FAILED : 'DELETE_SHIP_LIST_FAILED',
    
    
    ADD_PURCHASE_LIST_START : 'ADD_PURCHASE_LIST_START',
    ADD_PURCHASE_LIST_SUCCESS : 'ADD_PURCHASE_LIST_SUCCESS',
    ADD_PURCHASE_LIST_FAILED : 'ADD_PURCHASE_LIST_FAILED',
    GET_PURCHASE_LIST_START : 'GET_PURCHASE_LIST_START',
    GET_PURCHASE_LIST_SUCCESS : 'GET_PURCHASE_LIST_SUCCESS',
    GET_PURCHASE_LIST_FAILED : 'GET_PURCHASE_LIST_FAILED',
    UPDATE_PURCHASE_LIST_START : 'UPDATE_PURCHASE_LIST_START',
    UPDATE_PURCHASE_LIST_SUCCESS : 'UPDATE_PURCHASE_LIST_SUCCESS',
    UPDATE_PURCHASE_LIST_FAILED : 'UPDATE_PURCHASE_LIST_FAILED',
    DELETE_PURCHASE_LIST_START : 'DELETE_PURCHASE_LIST_START',
    DELETE_PURCHASE_LIST_SUCCESS : 'DELETE_PURCHASE_LIST_SUCCESS',
    DELETE_PURCHASE_LIST_FAILED : 'DELETE_PURCHASE_LIST_FAILED',
    
    
    ADD_COLLECTION_LIST_START : 'ADD_REIMBURSE_LIST_START',
    ADD_COLLECTION_LIST_SUCCESS : 'ADD_REIMBURSE_LIST_SUCCESS',
    ADD_COLLECTION_LIST_FAILED : 'ADD_REIMBURSE_LIST_FAILED',
    GET_COLLECTION_LIST_START : 'GET_REIMBURSE_LIST_START',
    GET_COLLECTION_LIST_SUCCESS : 'GET_REIMBURSE_LIST_SUCCESS',
    GET_COLLECTION_LIST_FAILED : 'GET_REIMBURSE_LIST_FAILED',
    GET_COLLECTION_LIST_BY_CUSTOM_START : 'GET_REIMBURSE_LIST_BY_CUSTOM_START',
    GET_COLLECTION_LIST_BY_CUSTOM_SUCCESS : 'GET_REIMBURSE_LIST_BY_CUSTOM_SUCCESS',
    GET_COLLECTION_LIST_BY_CUSTOM_FAILED : 'GET_REIMBURSE_LIST_BY_CUSTOM_FAILED',
    UPDATE_COLLECTION_LIST_START : 'UPDATE_REIMBURSE_LIST_START',
    UPDATE_COLLECTION_LIST_SUCCESS : 'UPDATE_REIMBURSE_LIST_SUCCESS',
    UPDATE_COLLECTION_LIST_FAILED : 'UPDATE_REIMBURSE_LIST_FAILED',
    DELETE_COLLECTION_LIST_START : 'DELETE_REIMBURSE_LIST_START',
    DELETE_COLLECTION_LIST_SUCCESS : 'DELETE_REIMBURSE_LIST_SUCCESS',
    DELETE_COLLECTION_LIST_FAILED : 'DELETE_REIMBURSE_LIST_FAILED',
    
    ADD_EXPENSE_START : 'ADD_EXPENSE_START',
    ADD_EXPENSE_SUCCESS : 'ADD_EXPENSE_SUCCESS',
    ADD_EXPENSE_FAILED : 'ADD_EXPENSE_FAILED',
    GET_EXPENSE_START : 'GET_EXPENSE_START',
    GET_EXPENSE_SUCCESS : 'GET_EXPENSE_SUCCESS',
    GET_EXPENSE_FAILED : 'GET_EXPENSE_FAILED',
    UPDATE_EXPENSE_START : 'UPDATE_EXPENSE_START',
    UPDATE_EXPENSE_SUCCESS : 'UPDATE_EXPENSE_SUCCESS',
    UPDATE_EXPENSE_FAILED : 'UPDATE_EXPENSE_FAILED',
    DELETE_EXPENSE_START : 'DELETE_EXPENSE_START',
    DELETE_EXPENSE_SUCCESS : 'DELETE_EXPENSE_SUCCESS',
    DELETE_EXPENSE_FAILED : 'DELETE_EXPENSE_FAILED',
    
    GET_LOGIN_LOG_START : 'GET_LOGIN_LOG_START',
    GET_LOGIN_LOG_SUCCESS : 'GET_LOGIN_LOG_SUCCESS',
    GET_LOGIN_LOG_FAILED : 'GET_LOGIN_LOG_FAILED',
    
    INCREASE_TEST:'INCREASE_TEST',
    
    GET_WORK_ORDER_START : 'GET_WORK_ORDER_START',
    GET_WORK_ORDER_SUCCESS : 'GET_WORK_ORDER_SUCCESS',
    GET_WORK_ORDER_FAILED : 'GET_WORK_ORDER_FAILED',
    
    GET_WORK_ORDER_BY_CUSTOM_START : 'GET_WORK_ORDER_BY_CUSTOM_START',
    GET_WORK_ORDER_BY_CUSTOM_SUCCESS : 'GET_WORK_ORDER_BY_CUSTOM_SUCCESS',
    GET_WORK_ORDER_BY_CUSTOM_FAILED : 'GET_WORK_ORDER_BY_CUSTOM_FAILED',
    
    ADD_WORK_ORDER_START : 'ADD_WORK_ORDER_START',
    ADD_WORK_ORDER_SUCCESS : 'ADD_WORK_ORDER_SUCCESS',
    ADD_WORK_ORDER_FAILED : 'ADD_WORK_ORDER_FAILED',
    
    UPDATE_WORK_ORDER_START : 'UPDATE_WORK_ORDER_START',
    UPDATE_WORK_ORDER_SUCCESS : 'UPDATE_WORK_ORDER_SUCCESS',
    UPDATE_WORK_ORDER_FAILED : 'UPDATE_WORK_ORDER_FAILED',
    
    DELETE_WORK_ORDER_START : 'DELETE_WORK_ORDER_START',
    DELETE_WORK_ORDER_SUCCESS : 'DELETE_WORK_ORDER_SUCCESS',
    DELETE_WORK_ORDER_FAILED : 'DELETE_WORK_ORDER_FAILED',
    
    GET_BUSINESS_OPPORTUNITY_START : 'GET_BUSINESS_OPPORTUNITY_START',
    GET_BUSINESS_OPPORTUNITY_SUCCESS : 'GET_BUSINESS_OPPORTUNITY_SUCCESS',
    GET_BUSINESS_OPPORTUNITY_FAILED : 'GET_BUSINESS_OPPORTUNITY_FAILED',
    
    GET_BUSINESS_OPPORTUNITY_BY_CUSTOM_START : 'GET_BUSINESS_OPPORTUNITY_BY_CUSTOM_START',
    GET_BUSINESS_OPPORTUNITY_BY_CUSTOM_SUCCESS : 'GET_BUSINESS_OPPORTUNITY_BY_CUSTOM_SUCCESS',
    GET_BUSINESS_OPPORTUNITY_BY_CUSTOM_FAILED : 'GET_BUSINESS_OPPORTUNITY_BY_CUSTOM_FAILED',
    
    GET_BUSINESS_OPPORTUNITY_STATUS_COUNT_START : 'GET_BUSINESS_OPPORTUNITY_STATUS_COUNT_START',
    GET_BUSINESS_OPPORTUNITY_STATUS_COUNT_SUCCESS : 'GET_BUSINESS_OPPORTUNITY_STATUS_COUNT_SUCCESS',
    GET_BUSINESS_OPPORTUNITY_STATUS_COUNT_FAILED : 'GET_BUSINESS_OPPORTUNITY_STATUS_COUNT_FAILED',
    
    GET_BUSINESS_OPPORTUNITY_STATUS_AMOUNT_START : 'GET_BUSINESS_OPPORTUNITY_STATUS_AMOUNT_START',
    GET_BUSINESS_OPPORTUNITY_STATUS_AMOUNT_SUCCESS : 'GET_BUSINESS_OPPORTUNITY_STATUS_AMOUNT_SUCCESS',
    GET_BUSINESS_OPPORTUNITY_STATUS_AMOUNT_FAILED : 'GET_BUSINESS_OPPORTUNITY_STATUS_AMOUNT_FAILED',
    
    ADD_BUSINESS_OPPORTUNITY_START : 'ADD_BUSINESS_OPPORTUNITY_START',
    ADD_BUSINESS_OPPORTUNITY_SUCCESS : 'ADD_BUSINESS_OPPORTUNITY_SUCCESS',
    ADD_BUSINESS_OPPORTUNITY_FAILED : 'ADD_BUSINESS_OPPORTUNITY_FAILED',
    
    UPDATE_BUSINESS_OPPORTUNITY_START : 'UPDATE_BUSINESS_OPPORTUNITY_START',
    UPDATE_BUSINESS_OPPORTUNITY_SUCCESS : 'UPDATE_BUSINESS_OPPORTUNITY_SUCCESS',
    UPDATE_BUSINESS_OPPORTUNITY_FAILED : 'UPDATE_BUSINESS_OPPORTUNITY_FAILED',
    
    DELETE_BUSINESS_OPPORTUNITY_START : 'DELETE_BUSINESS_OPPORTUNITY_START',
    DELETE_BUSINESS_OPPORTUNITY_SUCCESS : 'DELETE_BUSINESS_OPPORTUNITY_SUCCESS',
    DELETE_BUSINESS_OPPORTUNITY_FAILED : 'DELETE_BUSINESS_OPPORTUNITY_FAILED',

    ADD_BUSINESS_FLOW_START : 'ADD_BUSINESS_FLOW_START',
    ADD_BUSINESS_FLOW_SUCCESS : 'ADD_BUSINESS_FLOW_SUCCESS',
    ADD_BUSINESS_FLOW_FAILED : 'ADD_BUSINESS_FLOW_FAILED',

    GET_BUSINESS_FLOW_START : 'GET_BUSINESS_FLOW_START',
    GET_BUSINESS_FLOW_SUCCESS : 'GET_BUSINESS_FLOW_SUCCESS',
    GET_BUSINESS_FLOW_FAILED : 'GET_BUSINESS_FLOW_FAILED',

    UPDATE_BUSINESS_FLOW_START : 'UPDATE_BUSINESS_FLOW_START',
    UPDATE_BUSINESS_FLOW_SUCCESS : 'UPDATE_BUSINESS_FLOW_SUCCESS',
    UPDATE_BUSINESS_FLOW_FAILED : 'UPDATE_BUSINESS_FLOW_FAILED',
    
    DELETE_BUSINESS_FLOW_START : 'DELETE_BUSINESS_FLOW_START',
    DELETE_BUSINESS_FLOW_SUCCESS : 'DELETE_BUSINESS_FLOW_SUCCESS',
    DELETE_BUSINESS_FLOW_FAILED : 'DELETE_BUSINESS_FLOW_FAILED',

    ADD_SUPPLIER_FAILED:'ADD_SUPPLIER_FAILED',
    ADD_SUPPLIER_START:'ADD_SUPPLIER_START',
    ADD_SUPPLIER_SUCCESS:'ADD_SUPPLIER_SUCCESS',

    GET_SUPPLIER_FAILED:'GET_SUPPLIER_FAILED',
    GET_SUPPLIER_START:'GET_SUPPLIER_START',
    GET_SUPPLIER_SUCCESS:'GET_SUPPLIER_SUCCESS',

    UPDATE_SUPPLIER_FAILED:'UPDATE_SUPPLIER_FAILED',
    UPDATE_SUPPLIER_START:'UPDATE_SUPPLIER_START',
    UPDATE_SUPPLIER_SUCCESS:'UPDATE_SUPPLIER_SUCCESS',

    DELETE_SUPPLIER_FAILED:'DELETE_SUPPLIER_FAILED',
    DELETE_SUPPLIER_START:'DELETE_SUPPLIER_START',
    DELETE_SUPPLIER_SUCCESS:'DELETE_SUPPLIER_SUCCESS',

    ADD_WORKLOG_FAILED: 'ADD_WORKLOG_FAILED',
    ADD_WORKLOG_START:  'ADD_WORKLOG_START',
    ADD_WORKLOG_SUCCESS:'ADD_WORKLOG_SUCCESS',

    GET_WORKLOG_FAILED: 'GET_WORKLOG_FAILED',
    GET_WORKLOG_START:  'GET_WORKLOG_START',
    GET_WORKLOG_SUCCESS:'GET_WORKLOG_SUCCESS',

    UPDATE_WORKLOG_FAILED: 'UPDATE_WORKLOG_FAILED',
    UPDATE_WORKLOG_START:  'UPDATE_WORKLOG_START',
    UPDATE_WORKLOG_SUCCESS:'UPDATE_WORKLOG_SUCCESS',

    DELETE_WORKLOG_FAILED: 'DELETE_WORKLOG_FAILED',
    DELETE_WORKLOG_START:  'DELETE_WORKLOG_START',
    DELETE_WORKLOG_SUCCESS:'DELETE_WORKLOG_SUCCESS',

});