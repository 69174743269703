import React from 'react'
import {Input,AutoComplete} from 'antd';
import {SearchOutlined} from '@ant-design/icons';
import { getAllCustom } from '../../actions/customDBTool';
import {ActionType} from "../../constants/actionTypes";
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';

const { Option} = AutoComplete;

function mapStateToProps(state){
    return {...state.custom};
}
function mapDispatchToProps(dispatch) {
    return {onGetCustom:()=>dispatch(getAllCustom()),
            updateFiltCustom:(value)=>dispatch({type:ActionType.GET_CUSTOM_FILTER_RESULT,payload:value})
            }
}

class HeaderSearcher extends React.Component{
    constructor(props){
        super(props);
        this.state = {dataSource: []};
        this.searchResult = this.searchResult.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.onSelect = this.onSelect.bind(this);
    }
    componentDidMount(){
        this.props.onGetCustom();
    }
    searchResult(query) {
        let resultList = [];
        this.props.get_custom_result.forEach(element => {
            if(-1 !== JSON.stringify(element).indexOf(query)){
                resultList.push({query,category:`${element.name}`});
            }
        });
        return (resultList)
    }
    onSelect(value) {
        let filtCustom = '';
        this.props.get_custom_result.forEach(element => {
            if(value === element.name){
                filtCustom = element;
            }
        })
        this.props.updateFiltCustom(filtCustom);
        return this.props.history.push('/allItems/crm/all_custom');
    }
    handleSearch = value => {
        this.setState({dataSource: value ? this.searchResult(value) : [],});
    };
    renderOption(item) {
        return (<Option key={item.category} text={item.category}>
                <div className="global-search-item">
                    <span className="global-search-item-desc"> {item.category}</span>
                </div>
            </Option>);
    }
    render(){
        const { dataSource } = this.state;
        return (<AutoComplete placeholder="搜索客户名称、手机号等" 
                dataSource={dataSource.map(this.renderOption)} optionLabelProp="text"
                onSelect={this.onSelect} onSearch={this.handleSearch}> 
                <Input suffix={<SearchOutlined className="certain-category-icon" />} />
            </AutoComplete>);
    }
}
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(HeaderSearcher));