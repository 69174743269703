import React from 'react';
import {Tabs,Table,Row,Col,Select} from 'antd';
import SaleGoalTable from '../../controls/SaleGoalTable';
import {addPerformanceGoal,updatePerformanceGoal,getAllPerformanceGoal} from '../../../actions/performanceGoalDBTool';
import {getAllOrder} from "../../../actions/orderDBTool"
import {connect} from "react-redux";
import {USER_LEVEL_ADMIN, USER_LEVEL_LEADER, ORDER_STATUS_SUCCESS} from "../../../constants/constant";
import { getAllUser } from '../../../actions/userDBTool';
import { name2Account, account2Name } from '../../../utils/accountUtils';
import { getEnterpriseDepartment } from '../../../actions/enterpriseDBTool';
const { TabPane } = Tabs;
const { Option } = Select;

function mapStateToProps(state) {
  return {...state.staff,...state.order,...state.enterprise,...state.performanceGoal}
}
function mapDispatchToProps(dispatch) {
  return {onGetAllPerformanceGoal:()=>dispatch(getAllPerformanceGoal()),
          onUpdatePerformanceGoal:(postData)=>dispatch(updatePerformanceGoal(postData)),
          onAddPerformanceGoal:(postData)=>dispatch(addPerformanceGoal(postData)),
          onGetAllOrder:()=>dispatch(getAllOrder()),
          onGetAllUser:()=>dispatch(getAllUser()),
          onGetDepartment:()=>dispatch(getEnterpriseDepartment())};
}
class SaleGoal extends React.Component{
  constructor(props){
    super(props);
    this.state = {fiscalYear:new Date().getFullYear()};
    this.saveDataSubmit = this.saveDataSubmit.bind(this);
    this.onChangeFiscalYear = this.onChangeFiscalYear.bind(this);
    this.updateGoalDataSource = this.updateGoalDataSource.bind(this);
    this.getDataFromResult = this.getDataFromResult.bind(this);
  }
  componentDidMount(){
    this.props.onGetAllPerformanceGoal();
    this.props.onGetAllOrder();
    this.props.onGetAllUser();
    this.props.onGetDepartment();
  }
  getDataFromResult(targetType,nameOrAccount,performanceResult){
    let orderResult = this.props.get_order_result?this.props.get_order_result:[];
    let completeList = [0,0,0,0,0,0,0,0,0,0,0,0];
    let goalList = [0,0,0,0,0,0,0,0,0,0,0,0];
    let uuid = null;
    for(let i = 0;i < orderResult.length;i++){
      if(new Date(orderResult[i].detail.send_time).getFullYear() !== this.state.fiscalYear || orderResult[i].status !== ORDER_STATUS_SUCCESS){
        continue;
      }
      if(-1 !== orderResult[i].detail.follower_account.indexOf(nameOrAccount)){
        console.log(orderResult[i].detail.send_time);
        let index = new Date(orderResult[i].detail.send_time).getMonth();
        completeList[index] += orderResult[i].amount;
      }
    }
    for(let i = 0;i < performanceResult.length;i++){
      if(performanceResult[i].name === nameOrAccount){
          goalList[0] = Number(performanceResult[i].goal_data.January);
          goalList[1] = Number(performanceResult[i].goal_data.February);
          goalList[2] = Number(performanceResult[i].goal_data.March);
          goalList[3] = Number(performanceResult[i].goal_data.April);
          goalList[4] = Number(performanceResult[i].goal_data.May);
          goalList[5] = Number(performanceResult[i].goal_data.June);
          goalList[6] = Number(performanceResult[i].goal_data.July);
          goalList[7] = Number(performanceResult[i].goal_data.August);
          goalList[8] = Number(performanceResult[i].goal_data.September);
          goalList[9] = Number(performanceResult[i].goal_data.October);
          goalList[10] = Number(performanceResult[i].goal_data.November);
          goalList[11] = Number(performanceResult[i].goal_data.December);
          uuid = performanceResult[i].uuid;
      }
    }
    if(uuid){
      return ({uuid:uuid,name:1===targetType?account2Name(nameOrAccount):nameOrAccount,
              goal1:goalList[0],complete1:completeList[0],percent1:(completeList[0]/goalList[0]*100).toFixed(2)+'%',
              goal2:goalList[1],complete2:completeList[1],percent2:(completeList[1]/goalList[1]*100).toFixed(2)+'%',
              goal3:goalList[2],complete3:completeList[2],percent3:(completeList[2]/goalList[2]*100).toFixed(2)+'%',
              goal4:goalList[3],complete4:completeList[3],percent4:(completeList[3]/goalList[3]*100).toFixed(2)+'%',
              goal5:goalList[4],complete5:completeList[4],percent5:(completeList[4]/goalList[4]*100).toFixed(2)+'%',
              goal6:goalList[5],complete6:completeList[5],percent6:(completeList[5]/goalList[5]*100).toFixed(2)+'%',
              goal7:goalList[6],complete7:completeList[6],percent7:(completeList[6]/goalList[6]*100).toFixed(2)+'%',
              goal8:goalList[7],complete8:completeList[7],percent8:(completeList[7]/goalList[7]*100).toFixed(2)+'%',
              goal9:goalList[8],complete9:completeList[8],percent9:(completeList[8]/goalList[8]*100).toFixed(2)+'%',
              goal10:goalList[9],complete10:completeList[9],percent10:(completeList[9]/goalList[9]*100).toFixed(2)+'%',
              goal11:goalList[10],complete11:completeList[10],percent11:(completeList[10]/goalList[10]*100).toFixed(2)+'%',
              goal12:goalList[11],complete12:completeList[11],percent12:(completeList[11]/goalList[11]*100).toFixed(2)+'%'})
    }
    else{
      return ({name:1===targetType?account2Name(nameOrAccount):nameOrAccount,
              goal1:goalList[0],complete1:completeList[0],percent1:(completeList[0]/goalList[0]*100).toFixed(2)+'%',
              goal2:goalList[1],complete2:completeList[1],percent2:(completeList[1]/goalList[1]*100).toFixed(2)+'%',
              goal3:goalList[2],complete3:completeList[2],percent3:(completeList[2]/goalList[2]*100).toFixed(2)+'%',
              goal4:goalList[3],complete4:completeList[3],percent4:(completeList[3]/goalList[3]*100).toFixed(2)+'%',
              goal5:goalList[4],complete5:completeList[4],percent5:(completeList[4]/goalList[4]*100).toFixed(2)+'%',
              goal6:goalList[5],complete6:completeList[5],percent6:(completeList[5]/goalList[5]*100).toFixed(2)+'%',
              goal7:goalList[6],complete7:completeList[6],percent7:(completeList[6]/goalList[6]*100).toFixed(2)+'%',
              goal8:goalList[7],complete8:completeList[7],percent8:(completeList[7]/goalList[7]*100).toFixed(2)+'%',
              goal9:goalList[8],complete9:completeList[8],percent9:(completeList[8]/goalList[8]*100).toFixed(2)+'%',
              goal10:goalList[9],complete10:completeList[9],percent10:(completeList[9]/goalList[9]*100).toFixed(2)+'%',
              goal11:goalList[10],complete11:completeList[10],percent11:(completeList[10]/goalList[10]*100).toFixed(2)+'%',
              goal12:goalList[11],complete12:completeList[11],percent12:(completeList[11]/goalList[11]*100).toFixed(2)+'%'})
    }
  }
  updateGoalDataSource(targetType,result){
    let memberDataSource = [];
    let departmentDataSource = [];
    let companyDataSource = [];
    let staffResult = this.props.get_staff_list_result?this.props.get_staff_list_result:[];
    let departmentResult = this.props.get_department_result?this.props.get_department_result:[];
    switch(targetType){
      case 1:
        if((USER_LEVEL_ADMIN||USER_LEVEL_LEADER) === Number(localStorage.getItem('level'))){
          for(let i = 0;i < staffResult.length;i++){
            memberDataSource.push(this.getDataFromResult(1,staffResult[i].account,result));
          }
        }
        else{
          for(let i = 0;i < result.length;i++){
            memberDataSource.push(this.getDataFromResult(1,result[i].name,result));
          }
        }
        return memberDataSource;
      case 2:
        for(let i = 0;i < departmentResult.length;i++){
          departmentDataSource.push(this.getDataFromResult(2,departmentResult[i].name,result));
        }
        return departmentDataSource;
      case 3:
        companyDataSource.push(this.getDataFromResult(3,localStorage.getItem('enterprise_name'),result));
        return companyDataSource;
      default:
      return [];
    }
  }
  onChangeFiscalYear(value){
    this.setState({fiscalYear:value});
    this.props.onGetAllPerformanceGoal();
    this.props.onGetAllOrder();
  }
  saveDataSubmit(saveData,targetType,goalType){
    let goal = {
      name:1===targetType?name2Account(saveData.name):saveData.name,
      target_type:targetType,
      performance_goal_type:goalType,
      fiscal_year:this.state.fiscalYear,
      goal_data:JSON.stringify({
        January:saveData.goal1?saveData.goal1:0,
        February:saveData.goal2?saveData.goal2:0,
        March:saveData.goal3?saveData.goal3:0,
        April:saveData.goal4?saveData.goal4:0,
        May:saveData.goal5?saveData.goal5:0,
        June:saveData.goal6?saveData.goal6:0,
        July:saveData.goal7?saveData.goal7:0,
        August:saveData.goal8?saveData.goal8:0,
        September:saveData.goal9?saveData.goal9:0,
        October:saveData.goal10?saveData.goal10:0,
        November:saveData.goal11?saveData.goal11:0,
        December:saveData.goal12?saveData.goal12:0}),
      creator_account:localStorage.getItem('account'),
      create_time:Date.parse(new Date())
    };
    goal['enterprise_uuid']=localStorage.getItem('enterprise_uuid');
    if(saveData.uuid){
      goal['uuid'] = saveData.uuid;
      this.props.onUpdatePerformanceGoal(goal);
    }
    else{
      let uuid = Date.parse(new Date())/1000+(Math.round(Math.random() * (9000))+999);
      goal['uuid'] = uuid;
      this.props.onAddPerformanceGoal(goal);
    }
  }

  render(){
    const childrenList = [];
    for(let i = 1;i <= 12;i++){
      childrenList.push([{title:'目标',dataIndex:'goal'+i,key:'goal'+i,width:120},{title:'已完成',dataIndex:'complete'+i,key:'complete'+i,width:120},{title:'完成度',dataIndex:'percent'+i,key:'percent'+i,width:120}]);
    }
    const column = [ {title:'名称',dataIndex:'name',key:'name',width:150,fixed:true},
                      {title:'一月',children:childrenList[0]},
                      {title:'二月',children:childrenList[1]},
                      {title:'三月',children:childrenList[2]},
                      {title:'四月',children:childrenList[3]},
                      {title:'五月',children:childrenList[4]},
                      {title:'六月',children:childrenList[5]},
                      {title:'七月',children:childrenList[6]},
                      {title:'八月',children:childrenList[7]},
                      {title:'九月',children:childrenList[8]},
                      {title:'十月',children:childrenList[9]},
                      {title:'十一月',children:childrenList[10]},
                      {title:'十二月',children:childrenList[11]}];
    let performanceResult = this.props.get_performance_goal_result?this.props.get_performance_goal_result:[];
    let memberGoalResult = [];
    let departmentGoalResult = [];
    let companyGoalResult = [];
    for(let i = 0;i < performanceResult.length;i++){
      switch(performanceResult[i].target_type){
        case 1:
          memberGoalResult.push(performanceResult[i])
          break;
        case 2:
          departmentGoalResult.push(performanceResult[i]);
          break;
        case 3:
          companyGoalResult.push(performanceResult[i]);
          break;
        default:
          break;
      }
    }
    return(<Row>
            <Row>
              <Col span={4}>
                <Select defaultValue={this.state.fiscalYear} style={{width:'90%'}} onChange={(value)=>{this.onChangeFiscalYear(value)}}>
                  <Option value={this.state.fiscalYear-1}>{this.state.fiscalYear - 1}财年</Option>
                  <Option value={this.state.fiscalYear}>{this.state.fiscalYear}财年</Option>
                  <Option value={this.state.fiscalYear+1}>{this.state.fiscalYear + 1}财年</Option>
                </Select>
              </Col>
              <Col span={4}><Select defaultValue={'dealMoney'} style={{width:'90%'}}>
                  <Option value={'dealMoney'}>订单成交总额</Option>
                </Select>
              </Col>
            </Row>
            <Tabs defaultActiveKey={"1"}>
              {(USER_LEVEL_ADMIN||USER_LEVEL_LEADER) === Number(localStorage.getItem('level'))?
              <TabPane key={"1"} tab={"成员业绩目标"}>
                <SaleGoalTable dataSource={this.updateGoalDataSource(1,memberGoalResult)} saveData={(newdata)=>{this.saveDataSubmit(newdata,1,1)}}/>
              </TabPane>:
                <TabPane key={"1"} tab={"我的业绩目标"}>
                  <Table dataSource={this.updateGoalDataSource(1,memberGoalResult)} columns={column} bordered scroll={{x: 'calc(600px + 50%)'}} rowKey={(record)=>record.uuid}/>
                </TabPane>}
              {(USER_LEVEL_ADMIN||USER_LEVEL_LEADER) === Number(localStorage.getItem('level'))?
              <TabPane key={"2"} tab={"部门业绩目标"}><SaleGoalTable dataSource={this.updateGoalDataSource(2,departmentGoalResult)} saveData={(newData)=>{this.saveDataSubmit(newData,2,1)}}/></TabPane>:null}
              {(USER_LEVEL_ADMIN||USER_LEVEL_LEADER) === Number(localStorage.getItem('level'))?
              <TabPane key={"3"} tab={"公司业绩目标"}>
                <SaleGoalTable dataSource={this.updateGoalDataSource(3,companyGoalResult)} saveData={(newData)=>{this.saveDataSubmit(newData,3,1)}}/>
              </TabPane>:null}
            </Tabs>
          </Row>)
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(SaleGoal)