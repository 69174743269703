import React from 'react';
import {Row,Tabs} from 'antd';
import OrderTable from '../../controls/OrderTable';
import {connect} from "react-redux";
import {USER_LEVEL_MEMBER} from "../../../constants/constant";
import { isToday } from '../../../utils/common';
const { TabPane } = Tabs;

function mapStateToProps(state) {
    return {...state.order};
}
function mapDispatchToProps(dispatch){
    return {}
}
class AllOrder extends React.Component {
    constructor(props){
        super(props);
        this.state = {isAllowed:true};
    }
    componentDidMount() {}
    renderAllOrder(){
        let orderDS = [];
        let myOrderDS = [];
        let myFollowerDS = [];
        let todayOrderDS = [];
        if (this.props.get_order_result){
            let result = this.props.get_order_result;
            result.sort(function (a,b) { return Date.parse(b.create_time) - Date.parse(a.create_time);})
            for(let i = 0;i < result.length;i++){
                let detail = result[i].detail;
                if(detail.manager_account && -1 !== detail.manager_account.indexOf(localStorage.getItem('account'))){
                    myOrderDS.push(result[i]);
                }
                if(detail.follower_account && -1 !== detail.follower_account.indexOf(localStorage.getItem('account'))){
                    myFollowerDS.push(result[i]);
                }
                if(isToday(detail.start_time)){
                    todayOrderDS.push(result[i]);
                }
                orderDS.push(result[i]);
            }
        }
        const orderEditable = USER_LEVEL_MEMBER < Number(localStorage.getItem('level'))?true:false
        const isManager = USER_LEVEL_MEMBER < Number(localStorage.getItem('level'))?true:false
        return(<Tabs defaultActiveKey={"1"} type='card'>
                {isManager?(
                    <TabPane key="1" tab="所有订单">
                        <OrderTable dataSource={orderDS} exportEnable={orderEditable} customColumnsEnable={true} editEnable={orderEditable} batchDelEnable={orderEditable} tableKey={'crm/all_order'} />
                    </TabPane>):null}
                    <TabPane key="2" tab="我负责的订单">
                        <OrderTable dataSource={myOrderDS} exportEnable={orderEditable} customColumnsEnable={true} editEnable={orderEditable} batchDelEnable={orderEditable} tableKey={'crm/all_order'}/>
                    </TabPane>
                    <TabPane key="3" tab="我参与的订单">
                        <OrderTable dataSource={myFollowerDS} exportEnable={orderEditable} customColumnsEnable={true} editEnable={orderEditable} batchDelEnable={orderEditable} tableKey={'crm/all_order'}/>
                    </TabPane>
                    <TabPane key="4" tab="今日新增订单">
                        <OrderTable dataSource={todayOrderDS} exportEnable={orderEditable} customColumnsEnable={true} editEnable={orderEditable} batchDelEnable={orderEditable} tableKey={'crm/all_order'}/>
                    </TabPane>
                </Tabs>);
    }
    render(){
        return (<Row>{this.renderAllOrder()}</Row>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(AllOrder)