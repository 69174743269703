import {ActionType} from "../constants/actionTypes";

const travelReducer = (state = {},action)=>{
    switch (action.type) {
        case ActionType.ADD_TRAVEL_LIST_START:
            return {...state,add_travel:false}
        case ActionType.ADD_TRAVEL_LIST_SUCCESS:
            return {...state,add_travel:true,add_travel_result:action.payload}
        case ActionType.ADD_TRAVEL_LIST_FAILED:
            return {...state,add_travel:false,add_travel_errors:action.error?action.payload.errors:null}

        case ActionType.GET_TRAVEL_LIST_START:
            return {...state,get_travel:false}
        case ActionType.GET_TRAVEL_LIST_SUCCESS:
            return {...state,get_travel:true,get_travel_result:action.payload}
        case ActionType.GET_TRAVEL_LIST_FAILED:
            return {...state,get_travel:false,get_travel_errors:action.error?action.payload.errors:null}

        case ActionType.UPDATE_TRAVEL_LIST_START:
            return {...state,update_travel:false}
        case ActionType.UPDATE_TRAVEL_LIST_SUCCESS:
            return {...state,update_travel:true,update_travel_result:action.payload}
        case ActionType.UPDATE_TRAVEL_LIST_FAILED:
            return {...state,update_travel:false,update_travel_errors:action.error?action.payload.errors:null}

        case ActionType.DELETE_TRAVEL_LIST_START:
            return {...state,delete_travel:false}
        case ActionType.DELETE_TRAVEL_LIST_SUCCESS:
            return {...state,delete_travel:true,delete_travel_result:action.payload}
        case ActionType.DELETE_TRAVEL_LIST_FAILED:
            return {...state,delete_travel:false,delete_travel_errors:action.error?action.payload.errors:null}

        default:
            return {...state};
    }
}
export default travelReducer;
