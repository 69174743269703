import React from 'react';
import { Bullet } from '@ant-design/plots';

export default class BarChart extends React.Component{
    constructor(props) {
        super(props);
        this.state = {};
    }
    render(){
        
        var config = {
            data: this.props.data,
            measureField: 'measures',
            rangeField: 'ranges',
            targetField: 'target',
            xField: 'title',
            color: {
                range: ['#5B8FF9'],
                measure: '#5B8FF9',
                target: '#5B8FF9',
            },
            label: {
                measure: {
                  position: 'middle',
                  style: {fill: '#fff',},
                },
            }
        };
        return <Bullet {...config} />;
    }
}