import React from 'react';

class ExportData extends React.Component{
    constructor(props){
        super(props);
        this.state = {};
    }
    render(){
        return (<div>the user ExportData</div>);
    }
}
export default ExportData;