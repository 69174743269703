import React from 'react'
import { Layout, Menu} from 'antd';
import {withRouter} from 'react-router-dom';
import {USER_LEVEL_ADMIN} from "../constants/constant";
import {ImportOutlined,CarOutlined,CodepenOutlined,BoldOutlined,InsertRowBelowOutlined,ScanOutlined,RiseOutlined,
    FallOutlined,AuditOutlined,UsergroupAddOutlined,OrderedListOutlined,DesktopOutlined,TeamOutlined,
    ClockCircleOutlined,ShoppingOutlined,FormOutlined,SettingOutlined,ContactsOutlined,UserOutlined,
    LaptopOutlined,BellOutlined,CheckSquareOutlined,MoneyCollectOutlined,AccountBookOutlined,RestOutlined,LineChartOutlined,
    ApartmentOutlined,DeploymentUnitOutlined,CalendarOutlined,ShoppingCartOutlined,UnorderedListOutlined,FileSearchOutlined
} from '@ant-design/icons';

const {SubMenu} = Menu;
const {Sider} = Layout
class FrameSide extends React.Component {
    rootSubmenuKeys = ['sub1','sub2','sub3','sub4','sub5','sub6','sub7','sub8'];
    constructor(props) {
        super(props);
        //console.log(localStorage.getItem('openKeys'));
        this.state = {
            collapsed: false,
            openKeys: localStorage.getItem('openKey') ? [localStorage.getItem('openKey')]:['sub1']
        }
    }
    onCollapse = collapsed => {
        console.log(collapsed);
        this.setState({collapsed});
    }
    onOpenChange = openKeys => {
        const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
        if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            this.setState({openKeys});
        } else {
            this.setState({openKeys: latestOpenKey ? [latestOpenKey] : [],});
        }
        localStorage.setItem('openKey',latestOpenKey);
        console.log(latestOpenKey);
    };
    handleClickItem=(item, key, keyPath, domEvent)=>{
        this.props.history.push('/allItems/'+item.key);
    }
    render(){
        let defaultKey = this.props.location.pathname;
        defaultKey = defaultKey.replace("/allItems/","");
        let isAdmin = USER_LEVEL_ADMIN === Number(localStorage.getItem("level"))?true:false;
        let openFeatureList = [];
        if(localStorage.getItem("feature")){
            openFeatureList = localStorage.getItem("feature");
            console.log(openFeatureList);
        }
        return(<Sider breakpoint='md' collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse} theme={'light'}>
                <Menu style={{width:'100%', height:'100%',overflowY:'auto',overflowX:'hidden',background:'RGB(225,228,231)'}} mode="inline"
                    defaultSelectedKeys={[defaultKey]} defaultOpenKeys={this.state.openKeys}
                    onClick={this.handleClickItem} openKeys={this.state.openKeys} onOpenChange={this.onOpenChange}>
                    <Menu.Item key="workstation"><DesktopOutlined/><span>工作台</span></Menu.Item>
                    <SubMenu key="sub1" title={<span><ClockCircleOutlined/><span>常用</span></span>}>
                        <Menu.Item key="crm/followup"><FormOutlined />跟进记录</Menu.Item>
                        <Menu.Item key="work/todo"><ClockCircleOutlined/>待办任务</Menu.Item>
                        <Menu.Item key="work/worklog"><CalendarOutlined />工作日志</Menu.Item>
                        <Menu.Item key="work/workorder"><UnorderedListOutlined/>工单</Menu.Item>
                        {isAdmin || -1 !== openFeatureList.indexOf('custom')?
                            <Menu.Item key="crm/all_custom"><TeamOutlined/>全部客户</Menu.Item>:null}
                        {isAdmin || -1 !== openFeatureList.indexOf('custom')?
                            <Menu.Item key="crm/import_custom"><ImportOutlined/>导入客户</Menu.Item>:null}
                        {isAdmin || -1 !== openFeatureList.indexOf('order')?
                            <Menu.Item key="crm/success_order"><ShoppingOutlined/>成交订单</Menu.Item>:null}
                    </SubMenu>
                    {isAdmin || -1 !== openFeatureList.indexOf('custom')?
                        <SubMenu key="sub2" title={<span><UsergroupAddOutlined/><span>客户管理</span></span>}>
                            <Menu.Item key="crm/followup"><FormOutlined/>跟进记录</Menu.Item>
                            <Menu.Item key="crm/all_custom"><TeamOutlined/>全部客户</Menu.Item>
                            <Menu.Item key="crm/unfollow_custom"><ScanOutlined />未跟进客户</Menu.Item>
                            <Menu.Item key="crm/follow_custom"><ContactsOutlined/>跟进的客户</Menu.Item>
                            <Menu.Item key="crm/common_custom"><TeamOutlined />公海客户</Menu.Item>
                            <Menu.Item key="crm/share_custom"><TeamOutlined />共享客户</Menu.Item>
                            <Menu.Item key="crm/normal_custom"><UserOutlined/>个人客户</Menu.Item>
                            <Menu.Item key="crm/vip_custom"><BoldOutlined />企业客户</Menu.Item>
                            <Menu.Item key="crm/success_custom"><RiseOutlined />成交客户</Menu.Item>
                            <Menu.Item key="crm/failed_custom"><FallOutlined />失败客户</Menu.Item>
                            <Menu.Item key="crm/import_custom"><ImportOutlined/>导入客户</Menu.Item>
                            <Menu.Item key="crm/business"><FileSearchOutlined/>商机</Menu.Item>
                        </SubMenu>:null}
                    {isAdmin || -1 !== openFeatureList.indexOf('order')?
                        <SubMenu key="sub3" title={<span><OrderedListOutlined/><span>订单管理</span></span>}>
                            <Menu.Item key="crm/all_order"><ShoppingOutlined/>全部订单</Menu.Item>
                            <Menu.Item key="crm/unfollow_order"><ShoppingOutlined/>待处理订单</Menu.Item>
                            <Menu.Item key="crm/follow_order"><ShoppingOutlined/>交易中订单</Menu.Item>
                            <Menu.Item key="crm/success_order"><ShoppingOutlined/>已完成订单</Menu.Item>
                            <Menu.Item key="crm/closed_order"><ShoppingOutlined/>已失效订单</Menu.Item>
                            <Menu.Item key="crm/sale_goal"><LineChartOutlined />业绩目标</Menu.Item>
                        </SubMenu>:null}
                    {/*isAdmin || -1 !== openFeatureList.indexOf('supplier')?*/}
                        <SubMenu key="sub4" title={<span><OrderedListOutlined/><span>供应商</span></span>}>
                            <Menu.Item key="supplier/supplier"><ShoppingOutlined/>供应商</Menu.Item>
                            {isAdmin || -1 !== openFeatureList.indexOf('purchase')?
                                <Menu.Item key="supplier/purchase"><InsertRowBelowOutlined />采购申请</Menu.Item>:null}
                            {/*<Menu.Item key="supplier/contract"><ShoppingOutlined/>采购合同</Menu.Item>
                            <Menu.Item key="supplier/order"><ShoppingOutlined/>采购订单</Menu.Item>*/}
                        </SubMenu>
                    {isAdmin || -1 !== openFeatureList.indexOf('product')?
                    <SubMenu key="sub5" title={<span><CodepenOutlined /><span>商品管理</span></span>}>
                        <Menu.Item key="product/all_product"><ShoppingCartOutlined/>全部商品</Menu.Item>
                        <Menu.Item key="product/onsale_product"><ShoppingCartOutlined/>在售商品</Menu.Item>
                        <Menu.Item key="product/new_product"><ShoppingCartOutlined/>待发布商品</Menu.Item>
                        <Menu.Item key="product/unsale_product"><ShoppingCartOutlined/>下架商品</Menu.Item>
                        {isAdmin || -1 !== openFeatureList.indexOf('ship')?
                            <Menu.Item key="product/ship"><AuditOutlined/>发货单</Menu.Item>:null}
                    </SubMenu>:null}
                    <SubMenu key="sub6" title={<span><LaptopOutlined /><span>办公</span></span>}>
                        <Menu.Item key="work/todo"><ClockCircleOutlined />待办任务</Menu.Item>
                        <Menu.Item key="work/worklog"><CalendarOutlined />工作日志</Menu.Item>
                        <Menu.Item key="work/workorder"><UnorderedListOutlined/>工单</Menu.Item>
                        <Menu.Item key="work/notice"><BellOutlined />通知/广播</Menu.Item>
                        {isAdmin ||-1 !== openFeatureList.indexOf('leave')?
                        <Menu.Item key="work/leave"><CheckSquareOutlined />请假申请</Menu.Item>:null}
                        {isAdmin || -1 !== openFeatureList.indexOf('travel')?
                        <Menu.Item key="work/travel"><CarOutlined/>出差申请</Menu.Item>:null}
                    </SubMenu>
                    <SubMenu key="sub7" title={<span><MoneyCollectOutlined/><span>财务</span></span>}>
                        {isAdmin || -1 !== openFeatureList.indexOf('expense')?<Menu.Item key="money/expense"><CheckSquareOutlined/>报销审批</Menu.Item>:null}
                        {isAdmin || -1 !== openFeatureList.indexOf('collection')?<Menu.Item key="money/collection"><AccountBookOutlined />收款管理</Menu.Item>:null}
                    </SubMenu>
                    {//TODO later version complete it
                    /*<SubMenu key="sub5" title={<span><Icon type="project"/><span>ERP</span></span>}>
                        <Menu.Item key="erp/agent"><Icon type="right"/>代理商</Menu.Item>
                        <Menu.Item key="erp/oem"><Icon type="right"/>供应商</Menu.Item>
                    </SubMenu>*/
                    
                    /* isAdmin || -1 != featureList.indexOf('statistic')?
                        <SubMenu key="sub7" title={<span><Icon type="area-chart"/><span>统计</span></span>}>
                            <Menu.Item key="statistics/bytime"><Icon type="bar-chart"/>按时间统计 </Menu.Item>
                            <Menu.Item key="statistics/bytype"><Icon type="pie-chart"/>按类型统计</Menu.Item>
                            <Menu.Item key="statistics/sales"><Icon type="line-chart"/>产品销量</Menu.Item>
                            <Menu.Item key="statistics/ranking"><Icon type="dot-chart"/>业绩排行</Menu.Item>
                            <Menu.Item key="statistics/order"><Icon type="unordered-list"/>工单统计</Menu.Item>
                        </SubMenu>:null*/}

                    {//TODO later version complete it
                        /*<SubMenu key="sub7" title={<span><Icon type="project" /><span>项目管理</span></span>}>
                        <Menu.Item key="project/manage"><Icon type="project"/>当前项目</Menu.Item>
                    </SubMenu>*/
                    }
                    <SubMenu key="sub8" title={<span><SettingOutlined/><span>系统设置</span></span>}>
                        <Menu.Item key="system/profile"><UserOutlined/>个人设置</Menu.Item>
                        {isAdmin?<Menu.Item key="system/company_info"><ApartmentOutlined/>公司设置</Menu.Item>:null}
                        {isAdmin?<Menu.Item key="system/payment"><MoneyCollectOutlined />升级/续费</Menu.Item> :null}
                        {isAdmin?<Menu.Item key="system/department"><ApartmentOutlined/>部门和岗位</Menu.Item>:null}
                        {isAdmin?<Menu.Item key="system/staff"><TeamOutlined />员工管理</Menu.Item>:null}
                        {isAdmin?<Menu.Item key="system/control"><DeploymentUnitOutlined />功能开关</Menu.Item>:null}
                        {isAdmin?<Menu.Item key="system/login_log"><CalendarOutlined />登录记录</Menu.Item>:null}
                        <Menu.Item key="system/release"><UnorderedListOutlined />更新日志</Menu.Item>
                        <Menu.Item key="system/trash"><RestOutlined />垃圾箱</Menu.Item>
                    </SubMenu>
                </Menu>
            </Sider>);
    }
}
export default withRouter(FrameSide);
