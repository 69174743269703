import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import moment from 'moment';
import 'moment/locale/zh-cn';
import XLSX from 'xlsx';
import {Row, Col, Input, Divider, Modal, Form, Select, DatePicker, Tag, Button,Radio,InputNumber} from 'antd';
import {addOrder, addOrderDel, batchAddOrderDel, getAllOrder, updateOrder} from "../../actions/orderDBTool";
import {PlusCircleOutlined,MinusCircleOutlined} from '@ant-design/icons';
import {getAllProduct} from '../../actions/productDBTool';
import {connect} from "react-redux";
import CustomChooseSelect from "./CustomChooseSelect";
import ProductChooseSelect from "./ProductChooseSelect";
import ExtendSelect from "./ExtendSelect";
import StaffChoose from "./StaffChoose";
import ExtendTable from "./ExtendTable";
import FileManage from './FileManage';
import {orderStatusList, orderLevelList, invoiceList,ORDER_STATUS_UN_FOLLOW, ORDER_LEVEL_NORMAL, ORDER_STATUS_CLOSED, ORDER_STATUS_SUCCESS} from '../../constants/constant';
import {account2Name, name2Account, customName2Uuid, Uuid2ProductName, productName2Uuid} from "../../utils/accountUtils";
import ExtendComment from './ExtendComment';

const {Option} = Select;
const { confirm } = Modal;
//const exportOptions = ['序号','订单编号', '订单名称', '订单金额', '订单地址', '订单等级','订单状态','商品数量','开具发票','客户名称','商品名称','负责人','参与人','下单时间','记录人'];
const orderStatus = [];
const orderLevel = [];
const orderInvoiceOptions = invoiceList.map(item=> <Option key={item.value} value={item.value}>{item.text}</Option>);
orderStatusList.forEach(item=>{orderStatus.push({text:item,value:item})});
orderLevelList.forEach(item=>{orderLevel.push({text:item,value:item})});
const OrderForm = Form.create({ name: 'add_order_modal' })(
    class extends React.Component {
        constructor(props) {
            super(props);
            this.state={
                orderStatus:false
            };
        }
        handleOrderStatus=(value)=>{
            if(value !== ORDER_STATUS_UN_FOLLOW){this.setState({orderStatus:true});}
            else{this.setState({orderStatus:false});}
        }
        add = ()=>{
            if(1 < this.props.mode){return}
            const { form } = this.props;
            const keys = form.getFieldValue("product_detail_keys");
            if(keys.length >= 100){return}
            const nextKeys = keys.concat(keys.length);
            form.setFieldsValue({product_detail_keys: nextKeys});
            console.log(keys)
        }
        remove = k => {
            if(1 < this.props.mode){return}
            const { form } = this.props;
            const keys = form.getFieldValue("product_detail_keys");
            if (keys.length === 1) {return;}
            form.setFieldsValue({
                product_detail_keys: keys.filter(key => key !== k)
            });
        }
        renderDynamicProduct(values,mode){
            const { form } = this.props;
            let defaultValue = [];
            let { getFieldDecorator } = form;
            if(values.productDetail){
                for (let i = 0;i < values.productDetail.length;i++){
                    defaultValue.push(i);
                }
            }
            else{
                defaultValue.push(0);
            }
            getFieldDecorator("product_detail_keys", {initialValue: mode?defaultValue:[0]});
            const keys = form.getFieldValue("product_detail_keys");
            return(keys.map((key, index) =>
                <Row key={index} gutter={[0,0]}>
                    <Col span={3} offset={2}>
                        <Form.Item>
                            {getFieldDecorator(`productNumber[${index}]`, {initialValue:mode&&values.productDetail&&values.productDetail[index]?Uuid2ProductName(values.productDetail[index].product_number):""})
                                (<Input title={"商品编号"} disabled={1 < mode?true:false} style={{width:'150px'}} size={"small"}/>)}
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item>
                            {getFieldDecorator(`productName[${index}]`, {rules: [{ required: true, message: '商品名称不能为空'}],initialValue:mode&&values.productDetail&&values.productDetail[index]?Uuid2ProductName(values.productDetail[index].product_uuid):""})
                                (<ProductChooseSelect title={"商品名称"} disabled={1 < mode?true:false} style={{width:'150px'}} size={"small"}/>)}
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item>
                            {getFieldDecorator(`standards[${index}]`, {initialValue:mode&&values.productDetail&&values.productDetail[index]?values.productDetail[index].standards:""})
                                (<Input disabled={1 < mode?true:false} style={{width:'110px'}} size={"small"}/>)}
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item>
                            {getFieldDecorator(`unit[${index}]`, {initialValue:mode&&values.productDetail&&values.productDetail[index]?values.productDetail[index].unit:"个"})
                                (<Input disabled={1 < mode?true:false} style={{width:'90px'}} size={"small"}/>)}
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item>
                            {getFieldDecorator(`count[${index}]`, {rules:[{ required: true, message: '数量不能为空' }],initialValue:mode&&values.productDetail&&values.productDetail[index]?values.productDetail[index].count:1})(
                                <InputNumber min={1} disabled={1 < mode?true:false} style={{width:'90px'}} size={"small"} onChange={(value)=>{
                                    this.props.form.setFieldsValue({[`amount[${index}]`]:value*this.props.form.getFieldValue(`price[${index}]`),
                                                                    [`rateAmount[${index}]`]:value*this.props.form.getFieldValue(`price[${index}]`)})
                                    let totalCount = 0
                                    let totalAmount = 0
                                    for(let i = 0;i < this.props.form.getFieldValue(`product_detail_keys`).length;i++){
                                        if(i === index){
                                            totalCount += Number(value)
                                        }
                                        else{
                                            totalCount += Number(this.props.form.getFieldValue(`count[${i}]`))
                                        }
                                        totalAmount += Number(this.props.form.getFieldValue(`amount[${i}]`))
                                    }
                                    this.props.form.setFieldsValue({totalCount:totalCount,totalAmount:totalAmount})

                                }}/>)}
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item>
                            {getFieldDecorator(`price[${index}]`, {initialValue:mode&&values.productDetail&&values.productDetail[index]?values.productDetail[index].price:0})
                                (<InputNumber min={0} step={0.1} disabled={1 < mode?true:false} style={{width:'100px'}} size={"small"} onChange={(value)=>{
                                    this.props.form.setFieldsValue({[`amount[${index}]`]:value*this.props.form.getFieldValue(`count[${index}]`),
                                                                    [`rateAmount[${index}]`]:value*this.props.form.getFieldValue(`count[${index}]`)})
                                                                    let totalCount = 0
                                    let totalAmount = 0
                                    for(let i = 0;i < this.props.form.getFieldValue(`product_detail_keys`).length;i++){
                                        totalCount += Number(this.props.form.getFieldValue(`count[${i}]`))
                                        totalAmount += Number(this.props.form.getFieldValue(`amount[${i}]`))
                                    }
                                    this.props.form.setFieldsValue({totalCount:totalCount,totalAmount:totalAmount})
                                }}/>)}
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item>
                            {getFieldDecorator(`amount[${index}]`, {initialValue:mode&&values.productDetail&&values.productDetail[index]?values.productDetail[index].amount:0})
                                (<InputNumber min={0} step={0.1} disabled={1 < mode?true:false} style={{width:'100px'}} size={"small"} onChange={(value)=>{
                                    this.props.form.setFieldsValue({[`rateAmount[${index}]`]:value})
                                    let totalAmount = 0
                                    for(let i = 0;i < this.props.form.getFieldValue(`product_detail_keys`).length;i++){
                                        if(i===index){
                                            totalAmount += value
                                        }
                                        else{
                                            totalAmount += Number(this.props.form.getFieldValue(`amount[${i}]`))
                                        }
                                    }
                                    this.props.form.setFieldsValue({totalAmount:totalAmount})
                                }}/>)}
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item>
                            {getFieldDecorator(`rate[${index}]`, {initialValue:mode&&values.productDetail&&values.productDetail[index]?values.productDetail[index].rate:0})
                                (<InputNumber min={0} max={100} step={0.1} size={"small"} disabled={1 < mode?true:false} style={{width:'90px'}} formatter={value => `${value}%`}
                                    parser={value => value.replace('%', '')}/>)}
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item>
                            {getFieldDecorator(`rateAmount[${index}]`, {initialValue:mode&&values.productDetail&&values.productDetail[index]?values.productDetail[index].rate_amount:"0.0"})
                                (<InputNumber min={0} step={0.1} disabled={1 < mode?true:false} style={{width:'110px'}} size={"small"}/>)}&nbsp;
                                {index === 0?(<PlusCircleOutlined style={{color:'blue'}} onClick={() => this.add()}/>):(<MinusCircleOutlined style={{color:'red'}} onClick={() => this.remove(key)}/>)}
                        </Form.Item>
                    </Col>
                
                </Row>))
        }
        renderDynamicProductTitle(){
            return(<Row gutter={[0,0]}>
                    <Col span={3} offset={2}>
                        <span>商品编号</span>
                    </Col>
                    <Col span={3}>
                        <span>商品名称</span>
                    </Col>
                    <Col span={2}>
                        <span>商品规格</span>
                    </Col>
                    <Col span={2}>
                        <span>单位</span>
                    </Col>
                    <Col span={2}>
                        <span>数量</span>
                    </Col>
                    <Col span={2}>
                        <span>单价</span>
                    </Col>
                    <Col span={2}>
                        <span>金额</span>
                    </Col>
                    <Col span={2}>
                        <span>稅率</span>
                    </Col>
                    <Col span={3}>
                        <span>金额(含税)</span>
                    </Col>
                </Row>)
        }
        render() {
            let { visible, onCancel, onOk, form,values,title,mode } = this.props;
            let { getFieldDecorator } = form;
            let currentTime = new Date();
            return(<Modal title={title} visible={visible} width={1400} maskClosable={false}
                    onOk={onOk} okText={2===mode?"关闭":"确定"} onCancel={onCancel} cancelText={"取消"}>
                    <Form labelCol={{span:8}} wrapperCol={{span:16}}>
                        <Row>
                            <Col span={10}>
                            <Form.Item label={"客户名称"}>
                                    {getFieldDecorator('customName', {rules: [{ required: true, message: '客户名称不能为空!'}],initialValue:values.customName?values.customName:''})
                                    (<CustomChooseSelect disabled={0 < mode?true:false}/>)}
                                </Form.Item>
                                <Form.Item label={"订单名称"}>
                                    {getFieldDecorator('orderTitle', {rules: [{ required: true, message:'订单名称不能为空!'}],initialValue:values.orderTitle?values.orderTitle:''})
                                        (<Input style={{width:'90%'}} placeholder={"例如：XX客户—XX商品"} disabled={1 < mode?true:false}/>)}
                                </Form.Item>
                                <Form.Item label={"订单地址"}>
                                    {getFieldDecorator('address', {initialValue:values.address?values.address:''})
                                    (<Input style={{width:'90%'}} placeholder={"输入订单的收货地址"} disabled={1 < mode?true:false}/>)}
                                </Form.Item>
                            </Col>
                            <Col span={10} offset={2}>
                                <Form.Item label={"是否开发票"}>
                                    {getFieldDecorator('isInvoice', {rules: [{ required: true }],initialValue:values.isInvoice?values.isInvoice:0})
                                    (<Select style={{width:'90%'}} disabled={1 < mode?true:false}>{orderInvoiceOptions}</Select>)}
                                </Form.Item>
                                <Form.Item label={"下单时间"}>
                                    {getFieldDecorator('startTime', {rules: [{ required: true,message:'下单时间不能为空!' }],
                                        initialValue:mode? moment(new Date(values.startTime), 'YYYY-MM-DD'):moment(currentTime,'YYYY-MM-DD')})
                                    (<DatePicker placeholder={"选择订单开始的时间"} showTime disabled={1 < mode?true:false}/>)}
                                </Form.Item>
                                <Form.Item label={"交付方式"}>
                                    {getFieldDecorator('deliveryMethod', {initialValue:values.deliveryMethod?values.deliveryMethod:''})
                                    (<Input style={{width:'90%'}} disabled={1 < mode?true:false}/>)}
                                </Form.Item>
                            </Col>
                        </Row>
                        {this.renderDynamicProductTitle()}
                        <div style={{maxHeight:260,overflow:'auto'}}>{this.renderDynamicProduct(values,mode)}</div>
                        <Row>
                            <Col span={12}>
                                <Form.Item label={"总数量(个)"}>
                                    {getFieldDecorator('totalCount', {rules:[{required:true,message:'商品总数量不能为空!'}],initialValue:values.count?values.count:'1'})
                                    (<InputNumber step={1} min={1} style={{width:'90%'}} placeholder={"输入商品的总数量"} disabled={1 < mode?true:false}/>)}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={"总金额(元)"}>
                                    {getFieldDecorator('totalAmount', {rules:[{required:true,message:'订单总金额不能为空!'}],initialValue:values.amount?values.amount:'0.0'})
                                    (<InputNumber step={0.1} min={1} style={{width:'90%'}} placeholder={"输入订单成交的总金额"} disabled={1 < mode?true:false}/>)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label={"订单状态"}>
                                    {getFieldDecorator('status',{initialValue:values.status?values.status:ORDER_STATUS_UN_FOLLOW})
                                    (<ExtendSelect items={orderStatusList} title={"订单状态"} placeholder={"请输入自定义的订单状态"}
                                        onChange={this.handleOrderStatus} disabled={2 === mode?true:false}/>)}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={"订单等级"}>
                                    {getFieldDecorator('level',{initialValue:values.level?values.level:ORDER_LEVEL_NORMAL})
                                    (<ExtendSelect items={orderLevelList} title={"订单等级"} placeholder={"请输入自定义的订单等级"} disabled={2===mode?true:false}/>)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item label={"特别备注"}>
                                    {getFieldDecorator('note', {rules: [{ required: false}],initialValue:values.note?values.note:""})(
                                        <ReactQuill placeholder={"XXX"} readOnly={1 < mode?true:false}/>)}
                                </Form.Item>
                                <Form.Item label={"相关文档"}>
                                    {getFieldDecorator('fileUrl', {initialValue:mode?values.file_url:""})
                                        (<FileManage filePrefix={"order/" + values.orderID+"/"} fileList={values.file_url} disabled={1 < mode?true:false}/>)}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                            <Form.Item label={"负责人"}>
                                {getFieldDecorator('manager', {rules: [{ required: true,message:'负责人不能为空！' }],initialValue:values.manager?values.manager:''})
                                (<StaffChoose mode={0} disabled={1 < mode?true:false}/>)}
                            </Form.Item>
                            <Form.Item label={"参与人"}>
                                {getFieldDecorator('follower', {initialValue:mode?values.follower:''})
                                (<StaffChoose mode={1} disabled={1 < mode?true:false}/>)}
                            </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={16} offset={2}>
                                <Form.Item label="评论" hidden={0 === mode}>
                                    {getFieldDecorator('comment', {rules: [{ required: false}],initialValue:values.comments?values.comments:""})
                                    (<ExtendComment/>)}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>);
    }
});
function mapStateToProps(state){
    return {...state.order,...state.custom,...state.product};
}
function mapDispatchToProps(dispatch) {
    return {onGetAllOrder:()=>dispatch(getAllOrder()),
            onGetAllProduct:()=>dispatch(getAllProduct()),
            OnAddOrder:(postData)=>dispatch(addOrder(postData)),
            OnDeleteOrder:(postData)=>dispatch(addOrderDel(postData)),
            OnBatchDeleteOrder:(IdList)=>dispatch(batchAddOrderDel(IdList)),
            OnUpdateOrder:(postData)=>dispatch(updateOrder(postData))};
}
class OrderTable extends React.Component{
    constructor(props){
        super(props);
        this.state = {  showOrderModal:false,
                        modalMode:0,//0 means add modal,1 means edit order modal
                        modalTitle:'新增订单',
                        exportType:'xlsx',
                        //exportColumns:exportOptions,
                        modalValues:{orderID:Date.parse(new Date())/1000+(Math.round(Math.random() * (9000))+999),
                                orderTitle:'',
                                amount:'',
                                address:'',
                                isInvoice:false,
                                level:'',
                                status:'',
                                customName:'',
                                productDetail:'',
                                note:'',
                                manager:'',
                                follower:'',
                                startTime:'',
                                creator:'',
                                createTime:''
                            }
                    };
        this.handleDelete = this.handleDelete.bind(this);
        this.updateModalValues = this.updateModalValues.bind(this);
        this.OnClickExport = this.OnClickExport.bind(this);
        this.renderAction = this.renderAction.bind(this);
    }
    componentDidMount(){
        this.props.onGetAllOrder();
        this.props.onGetAllProduct();
    }
    saveFormRef = formRef => {
        this.formRef = formRef;
    }
    /*OnChangeColumn=(checkedValues)=>{
        //this.setState({exportColumns:checkedValues});
    }*/
    OnChangeExportFormat=(e)=>{
        this.setState({exportType:e.target.value})
    }
    OnClickExport(){
        for (let i = 0;i < this.props.dataSource.length;i++){
            let exportDataSource = []
            let item = this.props.dataSource[i]
            let isInvoice = "";
            let itemDetail = item.detail;
            let productDetail = itemDetail.product_detail?itemDetail.product_detail:[]
            let productArray = []
            for(let j = 0;j < productDetail.length;j++){
                productArray.push({ '商品编号':productDetail[j].product_number,
                                    '商品名称':Uuid2ProductName(productDetail[j].product_uuid),
                                    '商品规格':productDetail[j].standards,
                                    '计量单位':productDetail[j].unit,
                                    '商品数量':productDetail[j].count,
                                    '商品单价':productDetail[j].price,
                                    '商品金额':productDetail[j].amount,
                                    '商品税率':productDetail[j].rate,
                                    '金额(含税)':productDetail[j].rate_amount
                                })
            }
            let follower = account2Name(itemDetail.follower_account)
            let manager = account2Name(itemDetail.manager_account)
            let creator = account2Name(item.creator_account);
            invoiceList.forEach(invoiceItem=>{if(item.invoice === invoiceItem.value){
                isInvoice = invoiceItem.text;
            }})
            exportDataSource.push({ "序号":i+1,
                                    "订单编号": item.order_id,
                                    "订单名称": item.title,
                                    "订单金额": item.amount,
                                    "商品总数": item.count,
                                    '订单地址': itemDetail.address,
                                    "订单等级": item.level,
                                    '订单状态': item.status,
                                    '开具发票': isInvoice,
                                    '客户名称': item.detail.custom_name,
                                    '负责人': manager,
                                    '参与人': follower,
                                    '订单时间': moment(itemDetail.start_time).format('YYYY-MM-DD HH:mm:ss'),
                                    '创建人': creator});
                let wb = XLSX.utils.book_new();
                let fileName = item.order_id+".";
                fileName += this.state.exportType;
                let jsonWs = XLSX.utils.json_to_sheet(exportDataSource,{defval:true});
                /*jsonWs['!merges'] = [
                    {
                        e: { c: 5, r: 0 },
                      s: { c: 0, r: 0 }  // 表示A1到F1单元格合并
                    }]*/
                XLSX.utils.sheet_add_json(jsonWs, productArray, {skipHeader: false, origin: { r: 3, c: 0 }, header: [ "商品编号","商品名称","商品规格","计量单位","商品数量","商品单价","商品金额","商品税率","金额(含税)"]});
                XLSX.utils.book_append_sheet(wb, jsonWs, '订单内容');
                XLSX.writeFile(wb, fileName);
        }
      
    }
    updateModalValues(orderData){
        let detail = orderData.detail;
        let value = {
            orderID:orderData.order_id,
            custom_uuid:orderData.custom_uuid,
            orderTitle:orderData.title,
            createTime: orderData.create_time,
            amount:orderData.amount,
            count:orderData.count,
            address:orderData.address,
            isInvoice:orderData.invoice,
            level:orderData.level,
            status:orderData.status,
            note:detail.note,
            file_url:detail.file_url?detail.file_url:[],
            comments:detail.comments?detail.comments:'',
            startTime:detail.start_time,
            customName:detail.custom_name,
            productDetail:detail.product_detail,
            deliveryMethod:detail.delivery_method,
            manager:account2Name(detail.manager_account),
            follower:account2Name(detail.follower_account),
            creator:account2Name(orderData.creator_account),
        };
        this.setState({modalValues:value});
    }
    renderAction(record){
        if(this.props.editEnable){
            return (<Row>
                <Col span={8}><Button size='small' type="link" onClick={()=>this.showOrderContent(record)}>查看</Button></Col>
                <Col span={8}><Button size='small' type="link" onClick={()=>this.handleOnEditClick(record)}>编辑</Button></Col>
                <Col span={8}><Button size='small' type="link" style={{color:'red'}} onClick={()=>this.handleDelete(record.orderID)}>删除</Button></Col>
            </Row>)
        }
        else{
            if(-1 !== record.manager.indexOf(localStorage.getItem('name'))){
                return (<Row>
                    <Col span={8}><Button size='small' type="link" onClick={()=>this.showOrderContent(record)}>查看</Button></Col>
                    <Col span={8}><Button size='small' type="link" onClick={()=>this.handleOnEditClick(record)}>编辑</Button></Col>
                </Row>)
            }
            else{
                return (<Row>
                    <Col span={8}><Button size='small' type="link" onClick={()=>this.showOrderContent(record)}>查看</Button></Col>
                </Row>)
            }
            
        }
    }
    showOrderContent=(record)=>{
        let showItem = '';
        for (let i = 0;i < this.props.dataSource.length;i++){
            let item = this.props.dataSource[i];
            if (item.order_id === record.orderID){
                showItem = item;
            }
        }
        this.updateModalValues(showItem);
        this.setState({showOrderModal:true,modalMode:2,modalTitle:'查看订单'});
    }
    handleOnEditClick=(record)=>{
        for (let i = 0;i < this.props.dataSource.length;i++){
            let item = this.props.dataSource[i];
            if (item.order_id === record.orderID){
                this.updateModalValues(item);
                if(this.props.editEnable){
                    this.setState({showOrderModal:true,modalMode:1,modalTitle:'修改订单'});
                }
                else{
                    this.setState({showOrderModal:true,modalMode:3,modalTitle:'修改订单'});
                }
                return ;
            }
        }   
    }
    handleAddOnClick=()=>{
        if(this.props.selectCustom){
            let selectCustom = this.props.selectCustom;
            this.setState({showOrderModal:true,modalMode:0,modalTitle:'新增订单',
                            modalValues:{
                                        orderID:Date.parse(new Date())/1000+(Math.round(Math.random() * (9000))+999),
                                        address:selectCustom.detail.address,
                                        follower:account2Name(selectCustom.detail.follower_account),
                                        manager:account2Name(selectCustom.detail.manager_account),
                                        customName:selectCustom.name}});
        }
        else{
            this.setState({showOrderModal:true,modalMode:0,modalTitle:'新增订单',modalValues:{
                orderID:Date.parse(new Date())/1000+(Math.round(Math.random() * (9000))+999),
            }});
        }
    }
    handleEmptySelect=(uuid_list)=>{
        this.props.OnBatchDeleteOrder(uuid_list);
    }
    handleDelete(order_id){
        confirm({
            title: '重要提示',
            content: '您确定要删除该订单?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk:()=> {
                for (let i = 0;i < this.props.dataSource.length;i++){
                    let orderItem = this.props.dataSource[i];
                    if(orderItem.order_id === order_id){
                        return this.props.OnDeleteOrder(orderItem);
                    }
                }
            },
            onCancel() {console.log('Cancel');},
        });
    }
    handleCancel=()=>{
        console.log(this.state);
        const { form } = this.formRef.props;
        form.resetFields();
        this.setState({showOrderModal:false});
    }
    handleOrderSubmit=()=> {
        if(2 === this.state.modalMode){
            this.setState({ showOrderModal: false });
            return;
        }
        const { form } = this.formRef.props;
        form.validateFields((err, fromValues) => {
            if (err) {return;}
            let follower_account = name2Account(fromValues.follower);
            let manager_account = name2Account(fromValues.manager);
            let creator_account = localStorage.getItem('account');
            let product_detail = []
            let productKind = fromValues.productName.length
            for(let i = 0;i < productKind;i++){
                product_detail.push({
                    product_number:fromValues.productNumber[i],
                    product_uuid:productName2Uuid(fromValues.productName[i]),
                    price:fromValues.price[i],
                    count: fromValues.count[i],
                    standards:fromValues.standards[i],
                    unit:fromValues.unit[i],
                    amount:fromValues.amount[i],
                    rate:fromValues.rate[i],
                    rate_amount:fromValues.rateAmount[i]
                })
            }
            let detail = {
                custom_name:fromValues.customName,
                product_detail:product_detail,
                delivery_method:fromValues.deliveryMethod,
                note:fromValues.note,
                file_url:fromValues.fileUrl,
                follower_account: follower_account,
                comments:fromValues.comment,
                manager_account: manager_account,
                start_time:fromValues.startTime,
            }
            let currTime = new Date();
            if (ORDER_STATUS_CLOSED || ORDER_STATUS_SUCCESS !== fromValues.status){
                detail["end_time"] = currTime;
            }
            let postData = {
                order_id:this.state.modalValues.orderID,
                enterprise_uuid:localStorage.getItem('enterprise_uuid'),
                custom_uuid:customName2Uuid(fromValues.customName),
                title: fromValues.orderTitle,
                count:fromValues.totalCount,
                amount: fromValues.totalAmount,
                address:fromValues.address,
                invoice:fromValues.isInvoice,
                type:'',
                level: fromValues.level,
                status: fromValues.status,
                detail: JSON.stringify(detail),
                create_time:currTime,
                creator_account: creator_account,
            };
            if(this.state.modalMode){//update order
                postData['custom_uuid'] = this.state.modalValues.custom_uuid
                this.props.OnUpdateOrder(postData);
            }
            else{
                this.props.OnAddOrder(postData);
            }
            let modalValues = {
                order_id:postData['order_id'],
                custom_uuid:postData['custom_uuid'],
                title: fromValues.orderTitle,
                amount: fromValues.amount,
                type:'',
                level: fromValues.level,
                status: fromValues.status,
                detail:detail,
                create_time:Date.parse(currTime),
                creator_account: creator_account,
            }
            this.updateModalValues(modalValues);
            form.resetFields();
            this.setState({showOrderModal: false});
        });
    }
    renderLevelStyleText(text){
        const levelColor = ["","#108ee9","#f50"];
        return (<Tag color={levelColor[orderLevelList.indexOf(text)]}>{text}</Tag>)
    }
    renderStatusStyleText(text){
        const statusColor = ["","#2db7f5","#87d068","red"];
        return (<Tag color={statusColor[orderStatusList.indexOf(text)]}>{text}</Tag>)
    }
    renderOrderForm(){
        return(<OrderForm wrappedComponentRef={this.saveFormRef} visible={this.state.showOrderModal}
                onCancel={this.handleCancel} onOk={this.handleOrderSubmit} values={this.state.modalValues} 
                mode={this.state.modalMode} title={this.state.modalTitle}/>);
    }
    renderExportModal=()=>{
        return (<Modal title="导出结果选项" visible={this.state.showExportModal} width={1000}
                        cancelText={"取消"} onCancel={()=>{this.setState({showExportModal:false})}}
                        okText={"导出"} onOk={()=>{this.OnClickExport()}} >
                {/*<Checkbox.Group options={exportOptions}
                                defaultValue={exportOptions} onChange={this.OnChangeColumn}/>*/}
                <Radio.Group onChange={this.OnChangeExportFormat} defaultValue={'xlsx'}>
                    <Radio value={'xlsx'}>Excel(.xlsx)</Radio>
                    <Radio value={'xls'}>Excel(.xls)</Radio>
                    <Radio value={'csv'}>CSV</Radio>
                    <Radio value={'txt'}>文本(TXT)</Radio>
                </Radio.Group>

        </Modal>);
    }
    renderOrderTable(){
        let showColumns = [{title: '订单编号',dataIndex: 'orderID',key:'orderID',width:120,ellipsis:true,isSearch:true},
            {title: '订单名称',dataIndex: 'orderTitle',key:'orderTitle',width:150,ellipsis:true,isSearch:true,sorter: (a, b) => a.orderTitle.localeCompare(b.orderTitle)},
            {title: '客户名称',dataIndex: 'customName',key:'customName',width:150,ellipsis:true,isSearch:true,sorter: (a, b) => a.customName.localeCompare(b.customName)},
            {title: '总数量',dataIndex: 'productCount',key:'productCount',width:80,ellipsis:true,sorter: (a, b) => a.productCount - b.productCount},
            {title: '总金额',dataIndex: 'amount',key:'amount',width:100,ellipsis:true,sorter: (a, b) => a.amount-b.amount},
            {title: '地址',dataIndex: 'address',key:'address',width:150,ellipsis:true,isSearch:true},
            {title: '等级',dataIndex: 'level',key:'level',width:100,ellipsis:true,filters:orderLevel,onFilter: (value, record) => value.indexOf(value) === 0,
                sorter: (a, b) => a.level.localeCompare(b.level),render:(text)=>this.renderLevelStyleText(text)},
            {title: '状态',dataIndex: 'status',key:'status',width:80,ellipsis:true,isSearch:true,filters:orderStatus,onFilter: (value, record) => record.status.indexOf(value) === 0,
                sorter: (a, b) => a.status.localeCompare(b.status),render:(text)=>this.renderStatusStyleText(text)},
            {title: '开具发票',dataIndex: 'isInvoice',key:'isInvoice',width:100,ellipsis:true,filters:[{text:"是",value:"是"},{text:"否",value:"否"}],
                onFilter: (value, record) => record.isInvoice.indexOf(value) === 0},
            {title: '商品名称',dataIndex: 'productName',key:'productName',width:120,ellipsis:true},
            {title: '负责人',dataIndex:'manager',key:'manager',width:120,ellipsis:true,isSearch:true,sorter: (a, b) => a.manager.toString().localeCompare(b.manager.toString()),
                render:(text, record) =><Row>
                    {text instanceof Array?text.map((tag,index)=>{
                        const tagElem = (<Tag key={tag} color={"geekblue"}>{tag}</Tag>);
                        return (tagElem);}):text?(<Tag key={text} color={"geekblue"}>{text}</Tag>):null
                    }</Row>
            },
            {title: '参与人',dataIndex:'follower',key:'follower',width:120,ellipsis:true,isSearch:true,sorter: (a, b) => a.follower.toString().localeCompare(b.follower.toString()),
                render:(text, record) =><Row>
                    {text instanceof Array?text.map((tag,index)=>{
                        const tagElem = (<Tag key={tag} color={"geekblue"}>{tag}</Tag>);
                        return (tagElem);}):text?(<Tag key={text} color={"geekblue"}>{text}</Tag>):null
                    }</Row>
            },
            {title: '下单时间',dataIndex:'startTime',key:'startTime',width:180,ellipsis:true,sorter: (a, b) => Date.parse(a.startTime) - Date.parse(b.startTime),
                render:(text, record) =><span>{text}</span>},
            {title: '记录人',dataIndex:'creator',key:'creator',width:130,ellipsis:true,isSearch:true,sorter: (a, b) => a.creator.toString().localeCompare(b.creator.toString()),
                render:(text, record) => <Row>{text?(<Tag key={text} color={"geekblue"}>{text}</Tag>):null}</Row>},
            {title: '操作',dataIndex:'actions',key:'actions',width:150,fixed: 'right', render: (text, record) =>this.renderAction(record)}];
        let dataSource = [];
        for (let i = 0;i < this.props.dataSource.length;i++){
            let orderItem = this.props.dataSource[i];
            let isInvoice = "";
            let itemDetail = orderItem.detail;
            let productDetail = itemDetail.product_detail?itemDetail.product_detail:[]
            let product = ""
            if(productDetail.length){
                product = Uuid2ProductName(productDetail[0].product_uuid)
            }
            let follower = account2Name(itemDetail.follower_account)
            let manager = account2Name(itemDetail.manager_account)
            let creator = account2Name(orderItem.creator_account);
            invoiceList.forEach(item=>{if(orderItem.invoice === item.value){
                isInvoice = item.text;
            }})
            dataSource.push({
                orderID: orderItem.order_id,
                orderTitle: orderItem.title,
                amount: orderItem.amount,
                address: orderItem.address,
                customName: itemDetail.custom_name,
                productName: product,
                productCount: orderItem.count,
                follower: follower,
                manager: manager,
                startTime: moment(itemDetail.start_time).format('YYYY-MM-DD HH:mm:ss'),
                creator: creator,
                isInvoice: isInvoice,
                level: orderItem.level,
                status: orderItem.status,
            })
        }
        return (<Row>
                    {this.renderExportModal()}
                    <ExtendTable handleAddOnClick={this.handleAddOnClick} batchDelEnable={false === this.props.batchDelEnable?false:true} 
                        onBatchDelete={this.handleEmptySelect} customColumnsEnable={this.props.customColumnsEnable} columns={showColumns} 
                        dataSource={dataSource} rowKey={record => record.orderID} tableKey={this.props.tableKey}
                        exportEnable={this.props.exportEnable} exportTitle={"导出订单"} exportClick={()=>this.setState({showExportModal:true})}/>
                </Row>);
    }
    render(){
        return(<div>
                    <Row>{this.renderOrderTable()}</Row>
                    <Divider/>
                    <Row>{this.renderOrderForm()}</Row>
                </div>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(OrderTable);