import React from 'react';
import {Tabs, Divider, Row, Col, Card,Checkbox, Button, Alert} from 'antd';
import {AuditOutlined,CarOutlined,InsertRowBelowOutlined,AccountBookOutlined,TeamOutlined,
    ContainerOutlined,RightOutlined,CheckSquareOutlined,MoneyCollectOutlined,BarChartOutlined,ApartmentOutlined} from '@ant-design/icons';
import {connect} from "react-redux";
import {getEnterpriseDepartment, updateEnterpriseDepartment} from "../../actions/enterpriseDBTool";
const { Meta } = Card;
const { TabPane } = Tabs;
let departmentList = [];
function mapStateToProps(state) {
    return {...state.enterprise};
}
function mapDispatchToProps(dispatch) {
    return {onGetDepartment:(callback)=>dispatch(getEnterpriseDepartment(callback)),
            onUpdateDepartment:(departmentJson)=>dispatch(updateEnterpriseDepartment(departmentJson))}
}
class FeatureControl extends React.Component{
    constructor(props){
        super(props);
        this.state = {  hasChanged:false,
                        selectKey:0,
                        departmentList:null,
                        featureCheckList:null};
    }
    componentDidMount(){
        this.props.onGetDepartment(()=>{
            if(this.props.get_department){
                departmentList = this.props.get_department_result;
            }
        });
    }
    onFeatureItemClick=(event,key)=>{
        let isCheck = event.target.checked;
        let featureList = departmentList[this.state.selectKey - 1].feature;
        for (let i = 0;i < featureList.length;i++){
            if (key === featureList[i] && !isCheck){
                featureList.splice(i,1);
                departmentList[this.state.selectKey - 1].feature = featureList;
                console.log(departmentList);
                this.setState({hasChanged:true,departmentList:departmentList});
                return ;
            }
        }
        featureList.push(key);
        departmentList[this.state.selectKey - 1].feature = featureList;
        console.log(departmentList);
        this.setState({hasChanged:true,departmentList:departmentList});
    }
    handleSaveAndSync=()=>{
        if(!this.state.hasChanged){return;}
        this.props.onUpdateDepartment(this.state.departmentList);
        this.setState({hasChanged:false});
    }
    renderCrm(){
        let titleStyle = {textAlign:"center",paddingTop:'10px'};
        let itemStyle = {fontSize:'24px',color:''};//'#1E9FFF'
        let selectTabFeatureList = [];
        if(this.state.selectKey > 0 && departmentList.length > 0){
            selectTabFeatureList = departmentList[this.state.selectKey - 1].feature;
        }
        return (<Row gutter={16}>
                    <Col span={4} style={titleStyle}><h3><RightOutlined/>CRM</h3></Col>
                    <Col span={5}>
                        <Card hoverable actions={[<Checkbox
                            defaultChecked={-1!==selectTabFeatureList.indexOf('custom')?true:false}
                            onChange={event=>{this.onFeatureItemClick(event,'custom')}}>选择</Checkbox>]}>
                            <Meta avatar={<TeamOutlined style={itemStyle}/>} title="客户管理"/>
                        </Card>
                    </Col>
                    <Col span={5}>
                        <Card hoverable actions={[<Checkbox
                            defaultChecked={-1!==selectTabFeatureList.indexOf('order')?true:false}
                            onChange={event=>{this.onFeatureItemClick(event,'order')}}>选择</Checkbox>]}>
                            <Meta avatar={<ContainerOutlined style={itemStyle}/>} title="订单管理"/>
                        </Card>
                    </Col>
                    <Col span={5}>
                        <Card hoverable actions={[<Checkbox
                            defaultChecked={-1!==selectTabFeatureList.indexOf('product')?true:false}
                            onChange={event=>{this.onFeatureItemClick(event,'product')}}>选择</Checkbox>]}>
                            <Meta avatar={<AccountBookOutlined style={itemStyle}/>} title="产品管理"/>
                        </Card>
                    </Col>
                </Row>);
    }
    renderOffice(){
        let titleStyle = {textAlign:"center",paddingTop:'10px'};
        let itemStyle = {fontSize:'24px',color:''};//'#1E9FFF'
        let selectTabFeatureList = [];
        console.log(departmentList);
        if(this.state.selectKey > 0 && departmentList.length > 0){
            selectTabFeatureList = departmentList[this.state.selectKey - 1].feature;
        }
        return ( <Row gutter={16}>
            <Col span={4} style={titleStyle}>
                <h3><RightOutlined/>办公</h3>
            </Col>
            <Col span={5}>
                <Card hoverable actions={[<Checkbox
                    defaultChecked={-1!==selectTabFeatureList.indexOf('leave')?true:false}
                    onChange={event=>{this.onFeatureItemClick(event,'leave')}}>选择</Checkbox>]}>
                    <Meta avatar={<CheckSquareOutlined style={itemStyle}/>} title="请假申请"/>
                </Card>
            </Col>
            <Col span={5}>
                <Card hoverable actions={[<Checkbox
                    defaultChecked={-1!==selectTabFeatureList.indexOf('travel')?true:false}
                    onChange={event=>{this.onFeatureItemClick(event,'travel')}}>选择</Checkbox>]}>
                    <Meta avatar={<CarOutlined style={itemStyle}/>} title="出差申请"/>
                </Card>
            </Col>
            <Col span={5}>
                <Card hoverable actions={[<Checkbox
                    defaultChecked={-1!==selectTabFeatureList.indexOf('ship')?true:false}
                    onChange={event=>{this.onFeatureItemClick(event,'ship')}}>选择</Checkbox>]}>
                    <Meta avatar={<AuditOutlined style={itemStyle}/>} title="发货单"/>
                </Card>
            </Col>
        </Row>);
    }
    renderMoney=()=>{
        let titleStyle = {textAlign:"center",paddingTop:'10px'};
        let itemStyle = {fontSize:'24px',color:''};//'#1E9FFF'
        let selectTabFeatureList = [];
        if(this.state.selectKey > 0 && departmentList.length > 0){
            selectTabFeatureList = departmentList[this.state.selectKey - 1].feature;
        }
        return(<Row gutter={16}>
            <Col span={4} style={titleStyle}>
                <h3><RightOutlined/>财务</h3>
            </Col>
            <Col span={5}>
                <Card hoverable actions={[<Checkbox
                    defaultChecked={-1!==selectTabFeatureList.indexOf('expense')?true:false}
                    onChange={event=>{this.onFeatureItemClick(event,'expense')}}>选择</Checkbox>]}>
                    <Meta avatar={<AccountBookOutlined style={itemStyle}/>} title="报销审批"/>
                </Card>
            </Col>
            <Col span={5}>
                <Card hoverable actions={[<Checkbox
                    defaultChecked={-1!==selectTabFeatureList.indexOf('purchase')?true:false}
                    onChange={event=>{this.onFeatureItemClick(event,'purchase')}}>选择</Checkbox>]}>
                    <Meta avatar={<InsertRowBelowOutlined style={itemStyle}/>} title="采购申请"/>
                </Card>
            </Col>
            <Col span={5}>
                <Card hoverable actions={[<Checkbox
                    defaultChecked={-1!==selectTabFeatureList.indexOf('collection')?true:false}
                    onChange={event=>{this.onFeatureItemClick(event,'collection')}}>选择</Checkbox>]}>
                    <Meta avatar={<MoneyCollectOutlined style={itemStyle}/>} title="收款管理"/>
                </Card>
            </Col>
        </Row>);
    }
    renderStatistic=()=>{
        let titleStyle = {textAlign:"center",paddingTop:'10px'};
        let itemStyle = {fontSize:'24px',color:''};//'#1E9FFF'
        let selectTabFeatureList = [];
        if(this.state.selectKey > 0 && departmentList.length > 0){
            selectTabFeatureList = departmentList[this.state.selectKey - 1].feature;
        }
        return(
            <Row gutter={16}>
            <Col span={4} style={titleStyle}>
                <h3><RightOutlined/>统计</h3>
            </Col>
            <Col span={5}>
                <Card hoverable actions={[<Checkbox
                    defaultChecked={-1!==selectTabFeatureList.indexOf('statistic')?true:false}
                    onChange={event=>{this.onFeatureItemClick(event,'statistic')}}>选择</Checkbox>]}>
                    <Meta avatar={<BarChartOutlined style={itemStyle}/>} title="统计功能"/>
                </Card>
            </Col>
        </Row>);
    }
    renderMore=()=>{
        let titleStyle = {textAlign:"center",paddingTop:'10px'};
        let itemStyle = {fontSize:'24px',color:''};//'#1E9FFF'
        let selectTabFeatureList = [];
        if(this.state.selectKey > 0 && departmentList.length > 0){
            selectTabFeatureList = departmentList[this.state.selectKey - 1].feature;
        }
        return ( <Row gutter={16}>
            <Col span={4} style={titleStyle}>
                <h3><RightOutlined/>系统</h3>
            </Col>
            {/*<Col span={5}>
                <Card hoverable actions={[<Checkbox
                    defaultChecked={-1!==selectTabFeatureList.indexOf('company_setting')?true:false}
                    onChange={event=>{this.onFeatureItemClick(event,'company_setting')}}/>]}>
                    <Meta avatar={<ApartmentOutlined style={itemStyle}/>} title="公司设置"/>
                </Card>
                </Col>*/}
            <Col span={5}>
                <Card hoverable actions={[<Checkbox
                    defaultChecked={-1!==selectTabFeatureList.indexOf('staff_manage')?true:false}
                    onChange={event=>{this.onFeatureItemClick(event,'staff_manage')}}>选择</Checkbox>]}>
                    <Meta avatar={<ApartmentOutlined style={itemStyle}/>} title="员工管理"/>
                </Card>
            </Col>
            {/*<Col span={5}>
                <Card hoverable actions={[<Checkbox
                    defaultChecked={-1!==selectTabFeatureList.indexOf('feature_control')?true:false}
                    onChange={event=>{this.onFeatureItemClick(event,'feature_control')}}/>]}>
                    <Meta avatar={<DeploymentUnitOutlined style={itemStyle}/>} title="功能开关"/>
                </Card>
                </Col>*/}
        </Row>);
    }
    renderFeatures=()=>{
        return (<Row style={{height:'65vh',overflowY:'auto',overflowX:'hidden'}}>
                    {this.renderCrm()}
                    <Divider/>
                    {this.renderOffice()}
                    <Divider/>
                    {this.renderMoney()}
                    <Divider/>
                    {this.renderStatistic()}
                    <Divider/>
                </Row>);
    }
    renderDepartment(){
        if(this.props.get_department){
            let tempDepartmentList = this.props.get_department_result;
            if(!tempDepartmentList){return (null);}
            return ([...Array(tempDepartmentList.length).keys()].map(i =>(
                        <TabPane key={i + 1} tab={tempDepartmentList[i].name}>
                            {this.renderFeatures()}
                            {this.renderSaveAndSync()}
                        </TabPane>
                    )
                )
            )
        }
    }
    renderSaveAndSync(){
        return( <Row><Col offset={11}>
                    <Button type='primary' disabled={!this.state.hasChanged} onClick={()=>{this.handleSaveAndSync()}}>保存</Button>
                </Col></Row>);
    }
    render(){
        return(<Row>
                <Row>
                    <Col span={8} offset={1}><h3>部门</h3></Col>
                    <Col span={12}><h3>功能列表</h3></Col>
                </Row>
                <Divider/>
                <Tabs defaultActiveKey="1" tabPosition='left' type='card' bordered
                      onChange={(activeKey => {this.setState({selectKey:activeKey})})}>
                    <TabPane tab={'管理员'} key='0' >
                        <Alert showIcon type="info" message="管理员拥有所有权限!无法编辑"/>
                    </TabPane>
                    {this.renderDepartment()}
                </Tabs>
            </Row>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(FeatureControl);