import React from "react";
import {Select,Row, Col} from 'antd';
import {cityList} from "../../constants/citylist";
const { Option } = Select;
class CityChoose extends React.Component{
    constructor(props) {
        super(props);
        this.state = {provinceName:''};
    }
    componentDidMount(){
        if(undefined !== this.props.value){
            cityList.forEach((item,index)=>{
                for(let i = 0;i < item.citys.length;i++){
                    if(item.citys[i].citysName === this.props.value){
                        this.setState({provinceName:item.provinceName});
                    }
                }
            })
        }
    }
    onChooseProvince=(value)=>{
        if(undefined !== value){
            cityList.forEach((item,index)=>{
                if(item.provinceName === value){
                    this.setState({province:item,provinceName:value})
                }
            })
        }
        else{this.setState({province:''});}
    }
    triggerChange = (changedValue) => {
        // Should provide an event to pass value to Form.
        console.log(changedValue)
        const onChange = this.props.onChange;
        if (onChange) {
            onChange(changedValue);
        }
    }
    render(){
        return (<Row>
            <Col span={9}>
                <Select style={{width:'100%'}} disabled={this.props.disabled} allowClear={true} onChange={(value) =>{this.onChooseProvince(value)}} value={this.state.provinceName}>
                    {cityList.map(item=><Option value={item.provinceName} key={item.provinceName}>{item.provinceName}</Option>)}
                </Select>
            </Col>
            <Col span={15}>
                <Select style={{width:'100%'}} disabled={this.props.disabled} allowClear={true} value={this.props.value} onChange={(value)=>{this.triggerChange(value)}}>
                    {this.state.province? (this.state.province.citys.map(item=><Option value={item.citysName} key={item.citysName}>{item.citysName}</Option>)):null}
                </Select>
            </Col>
        </Row>);
    }
}
export default CityChoose;