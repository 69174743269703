import $ from 'jquery'
import {push} from 'react-router-redux';
import {message} from "antd";
import * as Service from '../config/service.json';
import {ActionType} from "../constants/actionTypes";
import {preHandle,checkAccountStatus} from './commonDBTool';
import {getEnterprise,checkExpiredTime} from './enterpriseDBTool';
export function register(postData) {
    let url = Service.dbServer + Service.apiVersion+'/user/register';
    return dispatch=>{
        dispatch({type:ActionType.REGISTER_START})
        $.ajax(url,{
            method:'POST',
            dataType:'json',
            data:(postData),
        }).then((response)=>{
            if(200 === response.code){
                localStorage.setItem("account",postData.account);
                localStorage.setItem("name",postData.account);
                const path=`/repos/${postData.account}`;
                console.log(path);
                dispatch({type:ActionType.REGISTER_SUCCESS,payload:response.result})
                message.info("注册成功!");
                dispatch(push('/allItems/'));
            }
            else{
                message.error(response.message)
                dispatch({type:ActionType.REGISTER_FAILED,payload:response.code});
            }
        }).catch(function(error){
            message.error("注册失败！")
            dispatch({type:ActionType.REGISTER_FAILED,payload:error});
        });
    }
}

export function login(account,password) {
    let url = Service.dbServer + Service.apiVersion + "/user/login"
    return dispatch=>{
        dispatch({type:ActionType.LOGIN_START})
        $.ajax(url,{
            method:'POST',
            dataType:'json',
            data:{account:account,password:password,deviceType:"PC"}
        }).then((response)=> {            
            if(200 === response.code){
                let result = response.result;
                localStorage.setItem("account",result.account);
                localStorage.setItem("name",result.name);
                localStorage.setItem("staff_detail",result.detail);
                localStorage.setItem("staff_id",result.staff_id);
                localStorage.setItem("level",result.level);
                localStorage.setItem("status",result.status);
                localStorage.setItem("login_token",result.login_token);
                result.detail = JSON.parse(result.detail);
                dispatch({type:ActionType.LOGIN_SUCCESS,payload:result});
                message.info("登录成功!");
                dispatch(checkExpiredTime());
                dispatch(getEnterprise());
                dispatch(push('/allItems/'));
            }
            else{
                message.error(response.message);
                dispatch({type:ActionType.LOGIN_FAILED,payload:response.message});
            }
        }).catch(function (error) {
            message.error("登录失败!")
            dispatch({type:ActionType.LOGIN_FAILED,payload:error})
        })
    }
}

export function getAllUser() {
    let url = Service.dbServer + Service.apiVersion + "/user/getAllUser" ;
    return dispatch=>{
        dispatch({type:ActionType.GET_STAFF_BY_ENTERPRISE_START})
        $.ajax(preHandle(url),{
            method:'GET',
            //contentType:'application/json; charset=utf-8',
            dataType:'json'
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            let result = response.result
            if(result){
                for(let i = 0;i < result.length;i++){
                    if(result[i].detail){
                        result[i].detail = JSON.parse(result[i].detail);
                    }
                }
            }
            dispatch({type:ActionType.GET_STAFF_BY_ENTERPRISE_SUCCESS,payload:result});
        }).catch(function (error) {
            dispatch({type:ActionType.GET_STAFF_BY_ENTERPRISE_FAILED,payload:error});
        })
    }
}
export function addUser(postData,staffList) {
    let url = Service.dbServer + Service.apiVersion+'/user/addUser' 
    return dispatch=>{
        dispatch({type:ActionType.ADD_STAFF_START})
        $.ajax(preHandle(url),{
            method:'POST',
            contentType:'application/json; charset=utf-8',
            dataType:'json',
            data:JSON.stringify(postData),
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            if(200 === response.code){
                dispatch({type:ActionType.ADD_STAFF_SUCCESS,payload:staffList});
                message.info("添加成员成功!");
                dispatch(getAllUser());
            }
            else{
                message.error(response.message);
                dispatch({type:ActionType.ADD_STAFF_FAILED,payload:response.message});
            }
        }).catch(function(error){
            console.log(error);
            message.error("添加成员失败");
            dispatch({type:ActionType.ADD_STAFF_FAILED,payload:error});
        });
    }
}
export function updateUserDetail(detail){
    let url = Service.dbServer + Service.apiVersion + "/user/updateDetail";
    return dispatch=>{
        $.ajax(preHandle(url),{
            method:'POST',
            contentType:'application/json; charset=utf-8',
            dataType:'json',
            data:JSON.stringify(detail)
        }).then((response)=>{
            checkAccountStatus(response.code,response.message);
            if(200 === response.code){
                message.info("更新成功！");
                localStorage.setItem("staff_detail",JSON.stringify(detail));
            }
            else{
                dispatch({type:ActionType.UPDATE_STAFF_FAILED,payload:response.message});
                message.error("更新失败！");
            }
        }).catch(function (error) {
            message.error("更新失败！");
            dispatch({type:ActionType.UPDATE_STAFF_FAILED,payload:error});
        })
    }
}
export function updateUser(postData) {
    let url = Service.dbServer + Service.apiVersion + "/user/update";
    return dispatch=>{
        dispatch({type:ActionType.UPDATE_STAFF_START});
        $.ajax(preHandle(url),{
            method:'POST',
            contentType:'application/json; charset=utf-8',
            dataType:'json',
            data:JSON.stringify(postData)
        }).then((response)=>{
            checkAccountStatus(response.code,response.message);
            if(200 === response.code){
                message.info("更新成员信息成功！");
                if(localStorage.getItem('account') === postData.account){//update the user self
                    localStorage.setItem("staff_detail",postData.detail);
                    localStorage.setItem("name",postData.name);   
                }
                dispatch({type:ActionType.UPDATE_STAFF_SUCCESS,payload:response.result});
                dispatch(getAllUser());
            }
            else{
                dispatch({type:ActionType.UPDATE_STAFF_FAILED,payload:response.message});
                message.error("更新成员信息失败！");
            }
        }).catch(function (error) {
            message.error("更新成员信息失败！");
            dispatch({type:ActionType.UPDATE_STAFF_FAILED,payload:error});
        })
    }
}
export function updateUserName(staffAccount,newName) {
    let url = Service.dbServer + Service.apiVersion + "/user/updateName" ;
    return dispatch=> {
        let postData = {"staff_account": staffAccount, "staff_new_name": newName};
        $.ajax(preHandle(url), {
            method: 'POST',
            dataType:'json',
            data: (postData)
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            if(200 === response.code){
                message.success("修改名称成功！");
                dispatch({type:ActionType.UPDATE_STAFF_SUCCESS});
                localStorage.setItem("name",newName);
            }
            else {
                message.error("修改失败");
            }
        }).catch(function (error) {
            message.error("连接错误");
        })
    }
}
export function resetUserPassword(postData) {
    let url = Service.dbServer + Service.apiVersion + "/user/resetPsd";
    return dispatch=>{
        dispatch({type:ActionType.UPDATE_PSD_START});
        $.ajax(url,{
            method:'POST',
            dataType:'json',
            data:(postData)
        }).then((response)=>{
            if(200 === response.code){
                message.success("修改密码成功!");
                dispatch(push('/login'));
            }
            else{message.error(response.message);}
        }).catch(function (error) {
            message.error("修改密码失败!");
            dispatch({type:ActionType.UPDATE_PSD_FAILED,payload:error});
        })
    }
}
export function updateUserPhone(update_account,verifyCode,newPhone){
    let url = Service.dbServer + Service.apiVersion + "/user/updatePhone" ;
    return dispatch=> {
        $.ajax(preHandle(url),{
            method:'POST',
            dataType:'json',
            data:({verify_code:verifyCode,staff_new_phone:newPhone,staff_account:update_account})
        }).then(response=>{
            if (200 === response.code){ 
                message.success("绑定成功!");
                let staff_detail = JSON.parse(localStorage.getItem('staff_detail'));
                staff_detail.phone = newPhone;
                localStorage.setItem('staff_detail',JSON.stringify(staff_detail));
            }
            else {message.error(response.message);}
        }).catch(function (error){
            message.error("绑定失败!");
        })
    }
}
export function updateUserEmail(update_account,verifyCode,newEmail) {
    let url = Service.dbServer + Service.apiVersion + "/user/updateEmail" ;
    return dispatch=> {
        $.ajax(preHandle(url),{
            method:'POST',
            dataType:'json',
            data:({verify_code:verifyCode,staff_new_email:newEmail,staff_account:update_account})
        }).then(response=>{
            if (200 === response.code){
                message.success("绑定成功!");
                let staff_detail = JSON.parse(localStorage.getItem('staff_detail'));
                staff_detail.email = newEmail;
                localStorage.setItem('staff_detail',JSON.stringify(staff_detail));
            }
            else {message.error(response.message);}
        }).catch(function (error){
            message.error("绑定新邮箱失败!");
        })
    }
}
export function deleteUser(staff_account) {
    let url = Service.dbServer + Service.apiVersion + "/user/delete" ;
    return dispatch =>{
        dispatch({type:ActionType.DELETE_USER_START});
        $.ajax(preHandle(url),{
            method:'delete',
            //contentType:'application/json; charset=utf-8',
            dataType:'json',
            data:({staff_account:staff_account})
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            if(200 === response.code){
                message.info("删除成功!",10);
                dispatch({type:ActionType.DELETE_USER_SUCCESS,payload:response.result});
                dispatch(getAllUser());
            }
            else{
                message.error(response.message,10);
                dispatch({type:ActionType.DELETE_USER_FAILED,payload:response.message});
            }
        }).catch(function (error) {
            message.error("删除失败!");
            dispatch({type:ActionType.DELETE_USER_FAILED,payload:error});
        })
    }
}
export function batchDelUser(staff_account_list){
    let url = Service.dbServer + Service.apiVersion + "/user/batchDel" ;
    return dispatch=>{
        $.ajax(preHandle(url),{
            method:'POST',
            dataType:'json',
            data:({'staff_account_list':JSON.stringify(staff_account_list)})
        }).then(response=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            if(200 === response.code){
                message.info("清空完成!",10);
                dispatch(getAllUser());
            }
        }).catch(function(error){
            console.log(error);
        })
    }
}