import React from 'react';
import {Row, Tabs} from 'antd';
import {getLeave} from "../../actions/leaveDBTool";
import {connect} from "react-redux";
import LeaveTable from "../controls/LeaveTable";
import {USER_LEVEL_MEMBER} from "../../constants/constant";
const { TabPane } = Tabs;
function  mapStateToProps(state){
    return {...state.leave};
}
function mapDispatchToProps(dispatch) {
    return {onGetLeave:()=>dispatch(getLeave())}
}
class LeaveList extends React.Component{
    constructor(props){
        super(props);
        this.state = {};
    }
    componentDidMount() { this.props.onGetLeave();}
    renderShip(){
        let allDS = []
        let myApplyDs = [];
        let myApprovalDS = [];
        let myAssignerDS = [];
        if(this.props.get_leave_result){
            let result = this.props.get_leave_result;
            result.sort(function (a,b) { return Date.parse(b.create_time) - Date.parse(a.create_time);})
            for (let i = 0;i < result.length;i++){
                let detail = result[i].detail;
                if(detail.manager_account && -1 !== detail.manager_account.indexOf(localStorage.getItem('account'))){
                    myApprovalDS.push(result[i]);
                }
                if(-1 !== result[i].creator_account.indexOf(localStorage.getItem('account'))){
                    myApplyDs.push(result[i]);
                }
                if(!detail.assigner_account){continue;}
                if( -1 !== detail.assigner_account.indexOf(localStorage.getItem('account'))){
                    myAssignerDS.push(result[i]);
                }
                allDS.push(result[i])
            }
        }
        const editable = USER_LEVEL_MEMBER < Number(localStorage.getItem('level'))?true:false
        return (<Tabs defaultActiveKey={"1"} type={"card"}>
            {editable?<TabPane key={"1"} tab={"全部"}>
                <LeaveTable dataSource={allDS} customColumnsEnable={true} tableKey={'work/leave'} exportEnable={editable} editEnable={editable}/>
            </TabPane>:null}
            <TabPane key={"2"} tab={"我申请的"}>
                <LeaveTable dataSource={myApplyDs} customColumnsEnable={true} tableKey={'work/leave'} exportEnable={editable} editEnable={editable}/>
            </TabPane>
            <TabPane key={"3"} tab={"我审批的"}>
                <LeaveTable dataSource={myApprovalDS} customColumnsEnable={true} tableKey={'work/leave'} exportEnable={editable} editEnable={editable}/>
            </TabPane>
            <TabPane key={"4"} tab={"抄送给我"}>
                <LeaveTable dataSource={myAssignerDS} customColumnsEnable={true} tableKey={'work/leave'} exportEnable={editable} editEnable={editable}/>
            </TabPane>
        </Tabs>);
    }
    render(){
        return (<Row>{this.renderShip()}</Row>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(LeaveList);