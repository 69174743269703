import React from 'react';
import {HashRouter,Route,Switch,Redirect} from 'react-router-dom';
import Login from '../component/login';
import PricePage from "../component/price";
import Register from '../component/register';
import Framework from '../component/framework';

import WorkStation from "../component/crm/workStation";
import AllCustom from "../component/crm/custommanage/allCustom";
import UnFollowCustom from "../component/crm/custommanage/unFollowCustom";
import FollowingCustom from "../component/crm/custommanage/followingCustom";
import CustomComplaint from "../component/crm/custommanage/customComplaint";
import SuccessCustom from "../component/crm/custommanage/successCustom";
import FailedCustom from "../component/crm/custommanage/failedCustom";
import NormalCustom from "../component/crm/custommanage/normalCustom";
import VIPCustom from "../component/crm/custommanage/vipCustom";
import OtherCustom from "../component/crm/custommanage/otherCustom";

import SuccessOrder from "../component/crm/ordermanage/successOrder";
import UnFollowOrder from "../component/crm/ordermanage/unFollowOrder";
import FollowOrder from "../component/crm/ordermanage/followOrder";
import ClosedOrder from "../component/crm/ordermanage/closedOrder";
import SaleGoal from "../component/crm/ordermanage/saleGoal";
import AllOrder from "../component/crm/ordermanage/allOrder";

import UnSaleProduct from "../component/productmanage/unsaleProduct";
import OnSaleProduct from "../component/productmanage/onsaleProduct";
import NewProduct from "../component/productmanage/newProduct";
import AllProduct from "../component/productmanage/allProduct";
import ShipList from "../component/productmanage/shipList";


import AllSupplier from "../component/supplier/allSupplier";
import PurchaseList from "../component/supplier/purchaseList";
import Agent from "../component/supplier/agent";
import Oem from "../component/supplier/oem";


import Notice from "../component/work/noticeList";
import Todo from "../component/work/todoWorkList";
import LeaveList from "../component/work/leaveList";
import TravelList from "../component/work/travelList";
import WorkLog from "../component/work/workLog";

import Expense from "../component/money/expense";
import Collection from "../component/money/collection";

import StatisticByTime from "../component/statistics/statisByTime";
import StatisticByType from "../component/statistics/statisByType";
import SaleStatistic from "../component/statistics/saleStatistic";
import StatisticRanking from "../component/statistics/ranking";
import OrderStatistic from "../component/statistics/orderStatistic";

import ProjectManage from "../component/project/project";

import ProFile from "../component/system/profile";
import StaffManage from "../component/system/staffManage";
import DepartmentManage from '../component/system/departmentManage';
import RoleManage from "../component/system/roleManage";
import LoginLog from "../component/system/loginLog";
import Trash from "../component/system/trash";
import Payment from "../component/system/payment";
import CompanyInfo from "../component/system/companyInfo";
import ExportData from "../component/system/exportData";

import ImportCustom from "../component/crm/custommanage/importCustom";
import PrivateRoute from "./privateRoute";
import FeatureControl from "../component/system/featureControl";
import ResetPsd from "../component/resetPsd";
import ShareCustom from "../component/crm/custommanage/shareCustom";
import CommonCustom from "../component/crm/custommanage/commonCustom";
import Followup from "../component/crm/custommanage/followup";
import Business from "../component/crm/custommanage/businessOpportunity";
import WorkOrder from "../component/work/workOrder";

import ReleaseNote from '../component/system/releaseNote';

class PageRouter extends React.Component{
    constructor(props){
        super(props);
        this.state={};
    }
    render(){
        return( <HashRouter>
                    <Switch>
                        <Route exact path='/' render={(location)=>{return localStorage.getItem("login_token")?
                            <Redirect from={location} to={'/allItems/'}/>:<Route path={'/'} component={Login}/>}
                        }/>
                        <Route exact path={'/login'} render={(location)=>{return localStorage.getItem("login_token")?
                            <Redirect from={location} to={'/allItems/'}/>:<Route path={'/login'} component={Login}/>}
                        }/>
                        {/*<Route path={'/home'} component={()=>{window.location='http://crm.lingduo.net/home';return null;}}/>*/}
                        <Route exact path={'/register'} component={Register}/>
                        <Route exact path={'/resetPassword'} component={ResetPsd}/>
                        <Route exact path={'/price'} component={PricePage}/>
                        {/*<Route exact path={'/contact'} component={()=>{window.location='http://www.lingduo.net/crm-contact-us/';}}/>*/}

                        <Route path={'/allItems'} render={(location)=>{ return localStorage.getItem("login_token")?
                            <Route path={'/allItems'} component={Framework}/>:<Redirect from={location} to={'/login'}/>}
                        }/>
                        <Redirect from='*' to='/'/>

                        {/*<Route path='/test'>
                            <Route path='/test/forbidden' component={Forbidden}/>
                        </Route>*/}
                    </Switch>
                </HashRouter>);
    }
}
class AllItemsRouter extends React.Component{
    render(){
        return(<Switch>
                <PrivateRoute path={'/allItems/index'} component={WorkStation}/>
                <PrivateRoute path={'/allItems/home'} component={WorkStation}/>
                <PrivateRoute path={'/allItems/workstation'} component={WorkStation}/>

                <PrivateRoute path={'/allItems/crm/success_order'} component={SuccessOrder}/>
                <PrivateRoute path={'/allItems/crm/all_order'} component={AllOrder}/>
                <PrivateRoute path={'/allItems/crm/unfollow_order'} component={UnFollowOrder}/>
                <PrivateRoute path={'/allItems/crm/follow_order'} component={FollowOrder}/>
                <PrivateRoute path={'/allItems/crm/closed_order'} component={ClosedOrder}/>
                <PrivateRoute path={'/allItems/crm/sale_goal'} component={SaleGoal}/>

                <PrivateRoute path={'/allItems/crm/followup'} component={Followup}/>
                <PrivateRoute path={'/allItems/crm/all_custom'} component={AllCustom}/>
                <PrivateRoute path={'/allItems/crm/custom_complaint'} component={CustomComplaint}/>
                <PrivateRoute path={'/allItems/crm/follow_custom'} component={FollowingCustom}/>
                <PrivateRoute path={'/allItems/crm/unfollow_custom'} component={UnFollowCustom}/>
                <PrivateRoute path={'/allItems/crm/success_custom'} component={SuccessCustom}/>
                <PrivateRoute path={'/allItems/crm/share_custom'} component={ShareCustom}/>
                <PrivateRoute path={'/allItems/crm/common_custom'} component={CommonCustom}/>
                <PrivateRoute path={'/allItems/crm/failed_custom'} component={FailedCustom}/>
                <PrivateRoute path={'/allItems/crm/normal_custom'} component={NormalCustom}/>
                <PrivateRoute path={'/allItems/crm/vip_custom'} component={VIPCustom}/>
                <PrivateRoute path={'/allItems/crm/import_custom'} component={ImportCustom}/>
                <PrivateRoute path={'/allItems/crm/business'} component={Business}/>

                <PrivateRoute path={'/allItems/product/all_product'} component={AllProduct}/>
                <PrivateRoute path={'/allItems/product/onsale_product'} component={OnSaleProduct}/>
                <PrivateRoute path={'/allItems/product/new_product'} component={NewProduct}/>
                <PrivateRoute path={'/allItems/product/unsale_product'} component={UnSaleProduct}/>
                <PrivateRoute path={'/allItems/product/other'} component={OtherCustom}/>
                <PrivateRoute path={'/allItems/product/ship'} component={ShipList}/>

                <PrivateRoute path={'/allItems/supplier/supplier'} component={AllSupplier}/>
                <PrivateRoute path={'/allItems/supplier/agent'} component={Agent}/>
                <PrivateRoute path={'/allItems/supplier/oem'} component={Oem}/>
                <PrivateRoute path={'/allItems/supplier/purchase'} component={PurchaseList}/>

                <PrivateRoute path={'/allItems/work/todo'} component={Todo}/>
                <PrivateRoute path={'/allItems/work/notice'} component={Notice}/>
                <PrivateRoute path={'/allItems/work/workorder'} component={WorkOrder}/>
                <PrivateRoute path={'/allItems/work/leave'} component={LeaveList}/>
                <PrivateRoute path={'/allItems/work/travel'} component={TravelList}/>
                <PrivateRoute path={'/allItems/work/worklog'} component={WorkLog}/>

                <PrivateRoute path={'/allItems/money/expense'} component={Expense}/>
                <PrivateRoute path={'/allItems/money/collection'} component={Collection}/>

                <PrivateRoute path={'/allItems/statistics/bytime'} component={StatisticByTime}/>
                <PrivateRoute path={'/allItems/statistics/bytype'} component={StatisticByType}/>
                <PrivateRoute path={'/allItems/statistics/sales'} component={SaleStatistic}/>
                <PrivateRoute path={'/allItems/statistics/ranking'} component={StatisticRanking}/>
                <PrivateRoute path={'/allItems/statistics/order'} component={OrderStatistic}/>

                <PrivateRoute path={'/allItems/project/manage'} component={ProjectManage}/>

                <PrivateRoute path={'/allItems/system/profile'} component={ProFile}/>
                <PrivateRoute path={'/allItems/system/staff'} component={StaffManage}/>
                <PrivateRoute path={'/allItems/system/role'} component={RoleManage}/>
                <PrivateRoute path={'/allItems/system/department'} component={DepartmentManage}/>
                <PrivateRoute path={'/allItems/system/control'} component={FeatureControl}/>
                <PrivateRoute path={'/allItems/system/login_log'} component={LoginLog}/>
                <PrivateRoute path={'/allItems/system/trash'} component={Trash}/>
                <PrivateRoute path={'/allItems/system/payment'} component={Payment}/>
                <PrivateRoute path={'/allItems/system/company_info'} component={CompanyInfo}/>
                <PrivateRoute path={'/allItems/system/export'} component={ExportData}/>
                <PrivateRoute path={'/allItems/system/release'} component={ReleaseNote}/>

                <PrivateRoute path={'/allItems/*'} component={WorkStation}/>
            </Switch>);
        }
}
export {PageRouter,AllItemsRouter}