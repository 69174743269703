import React from 'react';
import {Card} from 'antd'

export default class DMSCard extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            isHover:false
        };
    }
    componentDidMount(){}
    componentWillMount(){}
    render(){
        return(
            <Card type="inner" hoverable onClick={this.props.onClick}
                    onMouseEnter={()=>{this.setState({isHover:true})}} 
                    onMouseLeave={()=>{this.setState({isHover:false})}}
                    actions={this.props.actions} title={this.props.title} extra={this.props.extra}
                    headStyle={{background:this.state.isHover||this.props.value === this.props.select?'#1E9FFF':null,
                                color:this.state.isHover||this.props.value === this.props.select?'#FFFFFF':null}} 
                    style={{borderColor:this.state.isHover||this.props.value === this.props.select?'#1E9FFF':null}}>
                {this.props.children}
            </Card>);
    }
}