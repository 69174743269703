import React from 'react';
import {connect} from "react-redux";
import ReactQuill from 'react-quill';
import moment from 'moment';
import 'moment/locale/zh-cn';
import 'react-quill/dist/quill.snow.css';
import { StringCompareSort } from '../../utils/common';
import {Row, Col,Form, Input, Button,Modal,Tag} from 'antd';
import {addWorkLog,getWorkLog,updateWorkLog,deleteWorkLog} from '../../actions/worklogDBTool';
import StaffChoose from './StaffChoose';
import FileManage from './FileManage';
import ExtendTable from './ExtendTable';
import { account2Name, name2Account } from '../../utils/accountUtils';
import ExtendSelect from './ExtendSelect';
import ExtendComment from './ExtendComment';

const {confirm} = Modal;
const worklogTypeList = ['工作日报','工作周报','工作月报']

const WorkLogForm = Form.create({name:'worklog_modal'})(
    class extends React.Component{
        constructor(props) {
            super(props);
            this.state = {};
        }
        componentDidMount() {}
        render(){
            let { visible, onCancel, onOk, form,values,title,mode } = this.props;
            let { getFieldDecorator } = form;
           
            return (<Modal title={title} visible={visible} width={800} maskClosable={false} 
                        onOk={onOk} okText={2===mode?"关闭":"确定"} onCancel={onCancel}>
                    <Form labelCol={{span:6}} wrapperCol={{span:18}}>
                        <Row style={{background:'#F0F0F0'}}>基本信息</Row>
                        <Form.Item label={"分类"} required={true}>
                        {getFieldDecorator('type', {rules: [{ required: false}],initialValue:mode?values.type:'工作日报'})
                            (<ExtendSelect items={worklogTypeList} title={"类别"} placeholder={"请输入自定义的类别"} disabled={2 === mode?true:false}/>)}
                        </Form.Item>
                        <Form.Item label={"审核人"}>
                            {getFieldDecorator('manager', {rules:[{required:true,message:'审核人不能为空！'}],initialValue: mode?values.manager:''})
                            (<StaffChoose mode={0} disabled={2 === mode?true:false}/>)}
                        </Form.Item>
                        <Form.Item label={"抄送给"}>
                            {getFieldDecorator('assigner', {initialValue: mode?values.assigner:''})
                            (<StaffChoose mode={1} disabled={2 === mode?true:false}/>)}
                        </Form.Item>
                        <Row style={{background:'#F0F0F0'}}>日志内容</Row>
                        <Form.Item label={"标题"}>
                            {getFieldDecorator('title', {initialValue: mode?values.title:''})
                            (<Input disabled={1 < mode?true:false} style={{width:'70%'}} size={"small"}/>)}
                        </Form.Item>
                        <Form.Item label="内容">
                            {getFieldDecorator('note', {rules: [{ required: false}],initialValue:values.note?values.note:""})(
                            <ReactQuill placeholder={"请输入工作日志内容"} readOnly={1 < mode?true:false}/>)}
                        </Form.Item>
                        <Form.Item label={"相关文档"}>
                            {getFieldDecorator('fileUrl', {initialValue:mode?values.file_url:""})
                            (<FileManage filePrefix={"worklog/" + values.uuid+"/"} fileList={values.file_url} disabled={1 < mode?true:false}/>)}
                        </Form.Item>
                        <Form.Item label="评论" hidden={0 === mode}>
                            {getFieldDecorator('comment', {rules: [{ required: false}],initialValue:values.comments?values.comments:""})
                            (<ExtendComment/>)}
                        </Form.Item>
                    </Form>
                </Modal>)
        }
    }
)

function mapStateToProps(state) {
    return {...state.worklog}
}
function mapDispatchToProps(dispatch){
    return {onGetWorkLog:()=>dispatch(getWorkLog()),
            onUpdateWorkLog:(postData)=>dispatch(updateWorkLog(postData)),
            onAddWorkLog:(postData)=>dispatch(addWorkLog(postData)),
            onDeleteWorkLog:(uuid)=>dispatch(deleteWorkLog(uuid))
        }
}
class WorkLogTable extends React.Component{
    constructor(props){
        super(props)
        this.state = { showWorkLogModal:false,
                        modalMode:0,
                        modalTitle:'新增',
                        exportType:'xlsx',
                        modalValues:{uuid:"",
                                    title:'',
                                    creator:'',
                                    createTime:''
                                }
                    }
        this.updateModalValues = this.updateModalValues.bind(this);
        this.handleAddOnClick = this.handleAddOnClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    saveFormRef = formRef => {
        this.formRef = formRef;
    }
    updateModalValues(data){
        let detail = data.detail;
        this.setState({modalValues:{
            uuid:data.uuid,
            type:data.type,
            title:data.title,
            note:detail.note,
            comments:detail.comments?detail.comments:'',
            file_url:detail.file_url,
            manager:account2Name(detail.manager_account),
            assigner:account2Name(detail.assigner_account),
            createTime:data.create_time,
            creator:account2Name(data.creator_account)
        }})
    }
    handleDelete(uuid){
        confirm({
            title: '重要提示',
            content: '您确定要删除该工作日志?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk:()=> {
                for (let i = 0;i < this.props.dataSource.length;i++){
                    let item = this.props.dataSource[i];
                    if(item.uuid === uuid){
                        return this.props.onDeleteWorkLog(uuid);
                    }
                }
            },
            onCancel() {console.log('Cancel');},
        });
    }
    handleCancel=()=>{
        const { form } = this.formRef.props;
        form.resetFields();
        this.setState({showWorkLogModal:false});
    }
    showContent(record){
        let showItem = '';
        for (let i = 0;i < this.props.dataSource.length;i++){
            let item = this.props.dataSource[i];
            if (item.uuid === record.uuid){
                showItem = item;
            }
        }
        this.updateModalValues(showItem);
        this.setState({showWorkLogModal:true,modalMode:2,modalTitle:'查看工作日志'});
    }
    handleOnEditClick(record){
        for (let i = 0;i < this.props.dataSource.length;i++){
            let item = this.props.dataSource[i];
            if (item.uuid === record.uuid){
                this.updateModalValues(item);
                this.setState({showWorkLogModal:true,modalMode:1,modalTitle:'修改工作日志'});
                return ;
            }
        }   
    }
    handleAddOnClick(){
        this.setState({showWorkLogModal:true,modalMode:0,modalTitle:'新增工作日志',
                        modalValues:{uuid:Date.parse(new Date())/1000+(Math.round(Math.random() * (9000))+999),
                                    creator:""
                    }})
    }
    handleSubmit(){
        const { form } = this.formRef.props;
        form.validateFields((err, fromValues) => {
            if (err) {return;}
            let manager_account = name2Account(fromValues.manager);
            let creator_account = localStorage.getItem('account');
            let currTime = new Date();
            let detail = {
                file_url:fromValues.fileUrl,
                note:fromValues.note,
                comments:fromValues.comment,
                manager_account:manager_account,
                assigner_account:name2Account(fromValues.assigner)
            }
            let postData = {
                uuid:this.state.modalValues.uuid,
                enterprise_uuid:localStorage.getItem('enterprise_uuid'),
                type:fromValues.type,
                title:fromValues.title,
                status:"",
                detail:JSON.stringify(detail),
                create_time:Date.parse(currTime),
                creator_account:creator_account
            }
            console.log(postData)
            if(this.state.modalMode){
                this.props.onUpdateWorkLog(postData);
            }
            else{
                this.props.onAddWorkLog(postData);
            }
            let modalValues = {
                uuid:postData['uuid'],
                type:fromValues.type,
                title:fromValues.title,
                detail:detail,                
                create_time:Date.parse(currTime),
                creator_account:creator_account
            }
            this.updateModalValues(modalValues);
            form.resetFields();
            this.setState({ showWorkLogModal:false});
        })
    }
    renderAction(record){
        if(this.props.editEnable){
            return (<Row>
                <Col span={8}><Button size='small' type="link" onClick={()=>this.showContent(record)}>详情</Button></Col>
                <Col span={8}><Button size='small' type="link" onClick={()=>this.handleOnEditClick(record)}>编辑</Button></Col>
                <Col span={8}><Button size='small' type="link" style={{color:'red'}} onClick={()=>this.handleDelete(record.uuid)}>删除</Button></Col>
            </Row>)
        }
        else{
            if(-1 !== record.manager.indexOf(localStorage.getItem('name'))){
                return (<Row>
                    <Col span={8}><Button size='small' type="link" onClick={()=>this.showContent(record)}>详情</Button></Col>
                    <Col span={8}><Button size='small' type="link" onClick={()=>this.handleOnEditClick(record)}>编辑</Button></Col>
                </Row>)
            }
            else{
                return (<Row>
                    <Col span={8}><Button size='small' type="link" onClick={()=>this.showContent(record)}>详情</Button></Col>
                </Row>)
            }
        }
    }
    OnChangeExportFormat=(e)=>{
        this.setState({exportType:e.target.value})
    }
    renderWorkLogForm(){
        return(<WorkLogForm wrappedComponentRef={this.saveFormRef} visible={this.state.showWorkLogModal}
            onCancel={this.handleCancel} onOk={this.handleSubmit} values={this.state.modalValues} 
            mode={this.state.modalMode} title={this.state.modalTitle}/>)
    }
    renderWorkLog(){
        let showColumns = [ {title:'标题',dataIndex:'title',key:'title',width:120,ellipsis:true,isSearch:true},
                            {title:'类型',dataIndex:'type',key:'type',width:120,ellipsis:true,isSearch:true,sorter:(a,b)=>a.type.localeCompare(b.type),
                                onFilter: (value, record) => record.type.indexOf(value) === 0},
                            {title:'抄送',dataIndex: 'assigner',key:'assigner',width:120,ellipsis:true,isSearch:true,sorter:(a,b)=>StringCompareSort(a.assigner,b.assigner),
                                render:(text, record) =><Row>
                                    {record.assigner instanceof Array?record.assigner.map((tag,index)=>{
                                        const tagElem = (<Tag key={tag} color={"geekblue"}>{tag}</Tag>);
                                        return (tagElem);
                                    }):record.assigner?(<Tag key={record.assigner} color={"geekblue"}>{record.assigner}</Tag>):null}
                                </Row>},
                            {title:'负责人',dataIndex:'manager',key:'manager',width:120,ellipsis:true,isSearch:true,sorter:(a,b)=>StringCompareSort(a.manager,b.manager),
                                render:(text, record) =><Row>
                                    {record.manager instanceof Array?record.manager.map((tag,index)=>{
                                        const tagElem = (<Tag key={tag} color={"geekblue"}>{tag}</Tag>);
                                        return (tagElem);
                                    }):record.manager?(<Tag key={record.manager} color={"geekblue"}>{record.manager}</Tag>):null}
                                    </Row>},
                            {title: '记录人',dataIndex:'creator',key:'creator',width:130,ellipsis:true,isSearch:true,sorter: (a, b) => a.creator.toString().localeCompare(b.creator.toString()),
                                    render:(text, record) => <Row>{text?(<Tag key={text} color={"geekblue"}>{text}</Tag>):null}</Row>},
                            {title: '创建时间',dataIndex:'createTime',key:'createTime',width:130,ellipsis:true,isSearch:true,sorter: (a, b) => a.createTime.toString().localeCompare(b.createTime.toString())},
                            {title: '操作',dataIndex:'actions',key:'actions',width:150,fixed: 'right', render: (text, record) =>this.renderAction(record)},
                        ]
        let dataSource = [];
        for (let i = 0;i < this.props.dataSource.length;i++){
            let item = this.props.dataSource[i];
            let detail = item.detail;
            let assigner = account2Name(detail.assigner_account);
            let manager = account2Name(detail.manager_account);
            let creator = account2Name(item.creator_account)
            dataSource.push({uuid:item.uuid,
                             title:item.title,
                             type:item.type,
                             manager:manager,
                             assigner:assigner,
                             note:detail.note,
                             createTime:moment(item.create_time).format('YYYY-MM-DD HH:mm:ss'),
                             creator:creator,
            })
        }
        return (<Row>
                <ExtendTable handleAddOnClick={this.handleAddOnClick} batchDelEnable={false === this.props.batchDelEnable?false:true} 
                    onBatchDelete={this.handleEmptySelect} customColumnsEnable={this.props.customColumnsEnable} columns={showColumns} 
                    dataSource={dataSource} rowKey={record => record.uuid} tableKey={this.props.tableKey}exportEnable={false}/>
        </Row>)
    }
    render(){
        return (<div>
            <Row>{this.renderWorkLogForm()}</Row>
            <Row>{this.renderWorkLog()}</Row>
        </div>)
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(WorkLogTable)