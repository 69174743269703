import React from 'react';
import {Row,Tabs} from 'antd';
import TodoWorkTable from '../controls/TodoWorkTable';
import { getTodoWork} from "../../actions/todoDBTool";
import {connect} from "react-redux";
import {isToday} from "../../utils/common";
import {TODO_WORK_STATUS_CLOSE, TODO_WORK_STATUS_PROGRESS, TODO_WORK_STATUS_TODO} from "../../constants/constant";
const { TabPane } = Tabs;

function mapStateToProps(state) {
    return {...state.todoWork}
}
function mapDispatchToProps(dispatch) {
    return {onGetTodoWork:()=>dispatch(getTodoWork())}
}
class TodoWorkList extends React.Component{
    constructor(props){
        super(props);
        this.state = { };
    }
    componentDidMount(){
        this.props.onGetTodoWork();
    }
    renderTodoWork(){
        let allDS = [];
        let followDS = [];
        let creatorDS = [];
        let assignerDS = [];
        let unStartDS = [];
        let ProcessDS = [];
        let successDS = [];
        let todayDS= [];
        if(this.props.get_todo_result){
            let result = this.props.get_todo_result;
            result.sort(function (a,b) { return Date.parse(b.create_time) - Date.parse(a.create_time);})
            for (let i = 0;i < result.length;i++){
                let item = result[i];
                let detail = item.detail;
                allDS.push(item)
                if (-1 !== item.creator_account.indexOf(localStorage.getItem('account'))){creatorDS.push(item);}
                if (detail.manager_account && -1 !== detail.manager_account.indexOf(localStorage.getItem('account'))){followDS.push(item);}
                if (detail.assigner_account && -1 !== detail.assigner_account.indexOf(localStorage.getItem('account'))){assignerDS.push(item);}
                if (item.status === TODO_WORK_STATUS_TODO){unStartDS.push(item);}
                if (item.status === TODO_WORK_STATUS_PROGRESS){ProcessDS.push(item);}
                if (item.status === TODO_WORK_STATUS_CLOSE){successDS.push(item);}
                if (isToday(item.create_time)){todayDS.push(item);}
            }
        }
        return(<Tabs defaultActiveKey="2" type="card">
                    <TabPane key={"1"} tab="全部">
                        <TodoWorkTable dataSource={allDS} customColumnsEnable={true} tableKey={'work/todo'}/>
                    </TabPane>
                    <TabPane key={"2"} tab="由我创建">
                        <TodoWorkTable dataSource={creatorDS} customColumnsEnable={true} tableKey={'work/todo'}/>
                    </TabPane>
                    <TabPane key={"3"} tab="由我负责">
                        <TodoWorkTable dataSource={followDS} customColumnsEnable={true} tableKey={'work/todo'}/>
                    </TabPane>
                    <TabPane key={"4"} tab="抄送给我">
                        <TodoWorkTable dataSource={assignerDS} customColumnsEnable={true} tableKey={'work/todo'}/>
                    </TabPane>
                    <TabPane key={"5"} tab={"未开始"}>
                        <TodoWorkTable dataSource={unStartDS} customColumnsEnable={true} tableKey={'work/todo'}/>
                    </TabPane>
                    <TabPane key={"6"} tab={"处理中"}>
                        <TodoWorkTable dataSource={ProcessDS} customColumnsEnable={true} tableKey={'work/todo'}/>
                    </TabPane>
                    <TabPane key={"7"} tab={"已完成"}>
                        <TodoWorkTable dataSource={successDS} customColumnsEnable={true} tableKey={'work/todo'}/>
                    </TabPane>
                    <TabPane key={"8"} tab={"今日新增"}>
                        <TodoWorkTable dataSource={todayDS} customColumnsEnable={true} tableKey={'work/todo'}/>
                    </TabPane>
                </Tabs>);
    }
    render(){
        return (<Row>{this.renderTodoWork()}</Row>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(TodoWorkList);