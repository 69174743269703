import {
    ActionType

} from "../constants/actionTypes";
const enterpriseReducer = (state={},action)=>{
    switch (action.type) {
        case  ActionType.GET_ENTERPRISE_START:
            return {...state,get_enterprise: false}
        case  ActionType.GET_ENTERPRISE_SUCCESS:
            return {...state,get_enterprise: true,get_enterprise_result: action.payload}
        case  ActionType.GET_ENTERPRISE_FAILED:
            return {...state,get_enterprise: false,get_enterprise_errors: action.payload?action.payload:null}

        case  ActionType.UPDATE_ENTERPRISE_START:
            return {...state,update_enterprise:false}
        case  ActionType.UPDATE_ENTERPRISE_SUCCESS:
            return {...state,update_enterprise: true,update_enterprise_result:action.payload}
        case  ActionType.UPDATE_ENTERPRISE_FAILED:
            return {...state,update_enterprise: false,update_enterprise_errors:action.payload?action.payload:null}

        case  ActionType.GET_DEPARTMENT_BY_ENTERPRISE_START:
            return {...state, get_department:false}
        case  ActionType.GET_DEPARTMENT_BY_ENTERPRISE_FAILED:
            return {...state, get_department:false,get_department_errors:action.payload?action.payload:null}
        case  ActionType.GET_DEPARTMENT_BY_ENTERPRISE_SUCCESS:
            return {...state, get_department:true,get_department_result:action.payload}


        case  ActionType.UPDATE_DEPARTMENT_BY_ENTERPRISE_START:
            return {...state, update_department: false}
        case  ActionType.UPDATE_DEPARTMENT_BY_ENTERPRISE_FAILED:
            return {...state, update_department: false,update_department_errors:action.payload?action.payload:null}
        case  ActionType.UPDATE_DEPARTMENT_BY_ENTERPRISE_SUCCESS:
            return {...state, update_department:true, update_department_result:action.payload}


        case  ActionType.GET_FEATURE_LIST_BY_ENTERPRISE_START:
            return {...state, get_feature:false,}
        case  ActionType.GET_FEATURE_LIST_BY_ENTERPRISE_FAILED:
            return {...state, get_feature:false,get_feature_errors:action.payload?action.payload:null}
        case  ActionType.GET_FEATURE_LIST_BY_ENTERPRISE_SUCCESS:
            return {...state, get_feature:true, get_feature_list:action.payload}


        case  ActionType.UPDATE_FEATURE_LIST_BY_ENTERPRISE_START:
            return {...state, update_feature:false};
        case  ActionType.UPDATE_FEATURE_LIST_BY_ENTERPRISE_FAILED:
            return {...state, update_feature:false,update_feature_errors:action.payload?action.payload:null};
        case  ActionType.UPDATE_FEATURE_LIST_BY_ENTERPRISE_SUCCESS:
            return {...state, update_feature:true, update_feature_list:action.payload};


        case  ActionType.UPDATE_SELECT_DEPARTMENT:
            return{...state, select_department:action.payload};
        default:
            return {...state}
    }
}
export default enterpriseReducer;