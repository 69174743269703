import React from 'react';
import {Row,Tabs} from 'antd';
import {connect} from "react-redux";
import {getExpense} from "../../actions/expenseDBTool";
import ExpenseTable from "../controls/ExpenseTable";
import {USER_LEVEL_MEMBER} from "../../constants/constant";

const { TabPane } = Tabs;
function mapStateToProps(state) {
    return {...state.expense}
}
function mapDispatchToProps(dispatch) {
    return {onGetexpense:()=>dispatch(getExpense())}
}
class Expense extends React.Component{
    constructor(props){
        super(props);
        this.state = {};
    }
    componentDidMount(){
        this.props.onGetexpense();
    }
    renderDetail(){
        let allDS = [];
        let myCreateDS = [];
        let myAssignerDS = []
        let myManageDS = [];
        if(this.props.get_expense_result){
            for(let i = 0;i < this.props.get_expense_result.length;i++){
                let record = this.props.get_expense_result[i];
                let detail = record.detail;
                if(detail.manager_account && -1 !== detail.manager_account.indexOf(localStorage.getItem('account'))){
                    myManageDS.push(record);
                }
                if (record.creator_account === localStorage.getItem('account')){
                    myCreateDS.push(record);
                }
                if(detail.assigner_account && -1 !== detail.assigner_account.indexOf(localStorage.getItem('account'))){
                    myAssignerDS.push(record)
                }
                allDS.push(record)
            }
        }
        const editable = USER_LEVEL_MEMBER < Number(localStorage.getItem('level'))?true:false
        return <Tabs defaultActiveKey={'1'} type={"card"}>
            {editable?<TabPane key={'1'} tab={"全部"}>
                <ExpenseTable dataSource={allDS} tableKey={'money/expense'} exportEnable={editable} customColumnsEnable={true} editEnable={editable}/>
            </TabPane>:null}
            <TabPane key={'2'} tab={"我创建的"}>
                <ExpenseTable dataSource={myCreateDS} tableKey={'money/expense'} exportEnable={editable} customColumnsEnable={true} editEnable={editable}/>
            </TabPane>
            <TabPane key={'3'} tab={"我负责的"}>
                <ExpenseTable dataSource={myManageDS} tableKey={'money/expense'} exportEnable={editable} customColumnsEnable={true} editEnable={editable}/>
            </TabPane>
            <TabPane key={'4'} tab={"抄送给我"}>
                <ExpenseTable dataSource={myAssignerDS} tableKey={'money/expense'} exportEnable={editable} customColumnsEnable={true} editEnable={editable}/>
            </TabPane>
        </Tabs>
    }
    render(){
        return (<Row>{this.renderDetail()}</Row>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Expense);