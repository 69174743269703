import React from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import moment from "moment";
import {Row,Col, Form, Input,Modal, Button, Divider, DatePicker, Tag, Select, message} from "antd";
import {connect} from "react-redux";
import CustomChooseSelect from "./CustomChooseSelect";
import StaffChoose from "./StaffChoose";
import ExtendTable from "./ExtendTable";
import ExtendSelect from "./ExtendSelect";
import BusinessOpportunityStatusModal from './BusinessOpportunityStatusModal';
import {getBusinessByUser,addBusiness, delBusiness, updateBusiness, batchDelBusiness, getBusinessFlow} from "../../actions/businessDBTool";
import {businessOpportunityPercentList,businessOpportunitySourceList, businessOpportunityStatusList,businessOpportunityTypeList,USER_LEVEL_ADMIN, USER_LEVEL_LEADER} from "../../constants/constant";
import {name2Account,account2Name, customName2Uuid} from "../../utils/accountUtils"
import { StringCompareSort } from "../../utils/common";
import FileManage from './FileManage';

const {confirm} = Modal;
const {Option} = Select;

let businessOpportunityPercent = businessOpportunityPercentList
let businessFlowOptions = businessOpportunityStatusList.map(item=><Option key={item} value={item}>{item}</Option>)

const BusinessOpportunityFrom = Form.create({name:"business_modal"})(
    class extends React.Component{
        constructor(props) {
            super(props);
            this.state = {bussinessVisible:false};
        }
        handleOnBusinessFlowOK(){
            this.setState({bussinessVisible:false})
            this.props.businessflowChange();
        }
        render(){
            let { visible, onCancel, onOk, form, mode,values,title} = this.props;
            let { getFieldDecorator } = form;
            let formItemLayout = {labelCol: {span:6}, wrapperCol: {span:16}};
            let currentTime = new Date();
            return (<Modal title={title} visible={visible} maskClosable={false} onOk={onOk} okText={2===mode?"关闭":"确定"}
                        onCancel={onCancel} cancelText={"取消"} width={800}>
                        <Form {...formItemLayout}>
                            <BusinessOpportunityStatusModal visible={this.state.bussinessVisible} 
                                onCancel={()=>{this.setState({bussinessVisible:false})}} onOk={()=>{this.handleOnBusinessFlowOK()}}/>
                            <Row style={{background:'#F0F0F0'}}>商机信息</Row>
                            <Form.Item label={"商机名称"}>
                                {getFieldDecorator('businessOpportunityTitle',{rules: [{required: true, message: '商机名称不能为空!'}],
                                    initialValue: values.businessOpportunityTitle ? values.businessOpportunityTitle: ''})
                                (<Input placeholder={"输入商机的名称"} disabled={2 === mode?true:false}/>)}
                            </Form.Item>
                            <Form.Item label={"客户名称"}>
                                {getFieldDecorator('custom',{rules: [{required: false, message: '客户名称不能为空!'}],initialValue: values.custom ? values.custom: ''})
                                (<CustomChooseSelect disabled={2 === mode?true:false}/>)}
                            </Form.Item>
                            <Form.Item label={"商机类型"}>
                                {getFieldDecorator('type',{rules: [{required: true, message: '商机类型不能为空!'}],
                                    initialValue: values.type ? values.type: businessOpportunityTypeList[0]})
                                (<ExtendSelect items={businessOpportunityTypeList} placeholder={"请选择商机的类型"} disabled={2 === mode?true:false}/>)}
                            </Form.Item>
                            <Form.Item label={"商机来源"}>
                                {getFieldDecorator('source',{initialValue: values.source?values.source: businessOpportunitySourceList[0]})
                                (<ExtendSelect items={businessOpportunitySourceList} placeholder={"请选择商机的来源"} disabled={2 === mode?true:false}/>)}
                            </Form.Item>
                            <Form.Item label={"商机描述"}>
                                {getFieldDecorator('content',{initialValue: values.content ? values.content: ''})
                                (<ReactQuill placeholder={"请输入商机描述"} readOnly={2 === mode?true:false}/>)}
                            </Form.Item>
                            <Form.Item label={"商机阶段"}>
                                <Row>
                                    <Col span={16}>
                                        {getFieldDecorator('status',{rules: [{required: true, message: '商机阶段不能为空!'}],
                                            initialValue: values.status ? values.status:''})
                                        (<Select disabled={2 === mode?true:false}>{businessFlowOptions}</Select>)}
                                    </Col>
                                    <Col span={8}>
                                        {(USER_LEVEL_ADMIN||USER_LEVEL_LEADER) === Number(localStorage.getItem('level'))?
                                        <Button type="link" onClick={()=>{this.setState({bussinessVisible:true})}}>自定义阶段</Button>:null
                                        }
                                    </Col>
                                </Row>
                            </Form.Item>
                            <Form.Item label={"成交概率"}>
                                {getFieldDecorator('percent',{initialValue: values.percent ? values.percent: '10%'})
                                (<ExtendSelect items={businessOpportunityPercent} placeholder={"选择该商机成交可能性"} title={"商机成交概率"} 
                                    disabled={2 === mode?true:false} style={{width:'70%'}}/>)}
                            </Form.Item>
                            <Form.Item label={"预计成交金额"}>
                                {getFieldDecorator('expectDealMoney',{initialValue: values.expect_deal_money ? values.expect_deal_money: ''})
                                (<Input placeholder={"预计成交金额"} addonAfter={<span>元</span>} disabled={2 === mode?true:false}/>)}
                            </Form.Item>
                            <Form.Item label={"预计成交日期"}>
                                {getFieldDecorator('expectDealTime', {rules: [{ required: true,message:'录入时间不能为空!' }],
                                    initialValue:values.expect_deal_time? moment(new Date(values.expect_deal_time), 'YYYY-MM-DD'):moment(currentTime, 'YYYY-MM-DD')})
                                (<DatePicker placeholder={"选择预计成交的时间"} showTime disabled={2 === mode?true:false}/>)}
                            </Form.Item>
                            <Row style={{background:'#F0F0F0'}}>基础信息</Row>
                            <Form.Item label={"负责人"}>
                                {getFieldDecorator('manager',{rules: [{required: true, message: '负责人不能为空!'}],
                                    initialValue:values.manager?values.manager:''})
                                (<StaffChoose mode={0} disabled={2 === mode?true:false}/>)}
                            </Form.Item>
                            <Form.Item label={"参与人"}>
                                {getFieldDecorator('follower',{rules: [{required: true, message: '负责人不能为空!'}],
                                    initialValue:values.follower?values.follower:''})
                                (<StaffChoose mode={1} disabled={2 === mode?true:false}/>)}
                            </Form.Item>
                            <Form.Item label={"相关文档"}>
                                {getFieldDecorator('fileUrl', {initialValue:mode?values.file_url:""})
                                (<FileManage filePrefix={"business/"+values.uuid+"/"} fileList={values.file_url} disabled={1 < mode?true:false}/>)}
                            </Form.Item>
                        </Form>
                    </Modal>);
        }
    }
)
function mapStateToProps(state) {
    return {...state.businessOpportunity};
}
function mapDispatchToProps(dispatch) {
    return {onGetByUser:()=>dispatch(getBusinessByUser()),
            onAdd:(postData)=>dispatch(addBusiness(postData)),
            onUpdate:(postData)=>dispatch(updateBusiness(postData)),
            onDelete:(postData)=>dispatch(delBusiness(postData)),
            onBatchDelete:(uuid_list)=>dispatch(batchDelBusiness(uuid_list)),
            onGetBusinessFlow:(callback)=>dispatch(getBusinessFlow(callback))
        }
}
class BusinessOpportunityTable extends React.Component{
    constructor(props) {
        super(props);
        this.state = {showModal:false,modalMode:0,modalTitle:'新增商机',modalValues:{},bussinessVisible:false}
        this.handleSubmit = this.handleSubmit.bind(this);
        this.showBusiness = this.showBusiness.bind(this);
        this.handleOnEdit = this.handleOnEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.updateModalValues = this.updateModalValues.bind(this);
        this.syncBusinessFlowOptions = this.syncBusinessFlowOptions.bind(this);
    }
    componentDidMount(){ 
        this.props.onGetByUser();
        this.props.onGetBusinessFlow(()=>{
            this.syncBusinessFlowOptions();
        });
    }
    syncBusinessFlowOptions(){
        let businessFlowList = this.props.get_business_flow_result?this.props.get_business_flow_result:[];
        for(let i = 0;i < businessFlowList.length;i++){
            if(businessFlowList[i].detail.isUsing){
                businessFlowOptions = businessFlowList[i].detail.flows.map(item=><Option key={item} value={item}>{item}</Option>);
                businessOpportunityPercent = businessFlowList[i].detail.percent
                return;
            }
        }
        businessFlowOptions = businessOpportunityStatusList.map(item=><Option key={item} value={item}>{item}</Option>)
        businessOpportunityPercent = businessOpportunityPercentList;
    }
    saveFormRef = formRef => {
        this.formRef = formRef;
    }
    updateModalValues(values){
        if(!values){
            this.setState({modalValues: {
                uuid:'',
                businessOpportunityTitle:'',
                type: '',
                level: '',
                status:'',
                custom:'',
                source:'',
                percent:'',
                content:'',
                expect_deal_money:'',
                follower:'',
                manager:'',
                file_url:'',
                creator:'',
                createTime:''
            }})
            return;
        }
        let detail = values.detail
        this.setState({modalValues: {
            uuid:values.uuid,
            businessOpportunityTitle:values.title,
            type: values.type,
            level: values.level,
            status:values.status,
            custom:detail.custom_name,
            source:detail.source,
            percent:detail.percent,
            content:detail.content,
            expect_deal_money:detail.expect_deal_money,
            follower:account2Name(detail.follower_account),
            manager:account2Name(detail.manager_account),
            file_url:detail.file_url?detail.file_url:[],
            creator:account2Name(values.creator_account),
            createTime:values.create_time
        }});
    }
    handleEmptySelect=(uuid_list)=>{
        this.props.onBatchDelete(uuid_list);
    }
    handleAddOnClick=()=>{
        if(this.props.selectCustom){
            let selectCustom = this.props.selectCustom;
            this.setState({showModal:true,modalMode:0,modalTitle:'新增商机',
                            modalValues:{custom:selectCustom.name,follower:account2Name(selectCustom.detail.follower_account)}
                        });
        }
        else{
            this.setState({showModal:true,modalMode:0,modalTitle:'新增商机',modalValues:{}});
        }
    }
    showBusiness(record){
        for (let i = 0;i < this.props.get_business_opportunity_result.length;i++){
            let item = this.props.get_business_opportunity_result[i];
            if(item.uuid === record.uuid){
                this.updateModalValues(item);
                this.setState({showModal:true,modalMode:2,modalTitle:"查看商机"})
                return ;
            }
        }
    }
    handleOnEdit(record){
        for (let i = 0;i < this.props.get_business_opportunity_result.length;i++){
            let item = this.props.get_business_opportunity_result[i];
            if(item.uuid === record.uuid){
                this.updateModalValues(item);
                this.setState({showModal:true,modalMode:1,modalTitle:"更新商机"})
                return ;
            }
        }
    }
    handleCancel=()=>{
        const { form } = this.formRef.props;
        form.resetFields();
        this.setState({showModal:false})
    }
    handleSubmit(){
        if(2 === this.state.modalMode){
            this.setState({ showModal: false });
            return;
        }
        const {form} = this.formRef.props;
        form.validateFields((err,fromValues)=>{
            if (err){return;}
            if("自定义商机阶段" === fromValues.status){
                return message.error("商机阶段选择错误!");
            }
            let follower_account = name2Account(fromValues.follower);
            let manager_account = name2Account(fromValues.manager);
            let creator_account = localStorage.getItem('account');
        
            if(0 >= fromValues.follower.length){
                follower_account = "公海客户";
            }
            let detail = {  custom_uuid:customName2Uuid(fromValues.custom),
                            custom_name:fromValues.custom,
                            source:fromValues.source,
                            percent:fromValues.percent,
                            content:fromValues.content,
                            expect_deal_money:fromValues.expectDealMoney,
                            expect_deal_time:fromValues.expectDealTime,
                            follower_account:follower_account,
                            manager_account:manager_account,
                            file_url:fromValues.fileUrl
                        };
            let current = new Date();
            let postData = {
                enterprise_uuid:localStorage.getItem('enterprise_uuid'),
                title:fromValues.businessOpportunityTitle,
                type:fromValues.type,
                level:1,
                status:fromValues.status,
                detail:JSON.stringify(detail),
                creator_account:creator_account,
                create_time:Date.parse(current)
            };
            if(this.state.modalMode){
                postData['uuid'] = this.state.modalValues.uuid;
                this.props.onUpdate(postData);
            }
            else{
                let uuid = Date.parse(new Date())/1000+(Math.round(Math.random() * (9000))+999);
                postData['uuid'] = uuid;
                this.props.onAdd(postData);
            }
            let modalValues = {
                uuid:postData['uuid'],
                title:fromValues.businessOpportunityTitle,
                type:fromValues.type,
                level:1,
                status:fromValues.status,
                detail:detail,
                creator_account:creator_account,
                create_time:Date.parse(current)
            }
            this.updateModalValues(modalValues);
            this.setState({showModal:false});
            form.resetFields();
        })
    }
    handleDelete(uuid){
        confirm({
            content: '您确定要删除该商机?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk:()=> {
                for (let i = 0;i < this.props.dataSource.length;i++){
                    let item = this.props.dataSource[i];
                    if(item.uuid === uuid){
                        return this.props.onDelete(item);
                    }
                }
            },
            onCancel() {
                console.log('Cancel');
            },
        })
    }
    renderStatusStyleText(text){
        const statusColor = ["","#2db7f5","#87d068","red"];
        return (<Tag color={statusColor[businessOpportunityStatusList.indexOf(text)]}>{text}</Tag>)
    }
    renderTypeStyleText(text){
        const typeColor = ["","#87d068","#2db7f5"];
        return (<Tag color={typeColor[businessOpportunityTypeList.indexOf(text)]}>{text}</Tag>)
    }
    renderPercentStyleText(text){
        let color = ""
        console.log(text)
        if(text > 90){
            color = "#87d068"
        }
        else if(text > 60){
            color = "#2db7f5"
        }
        else if(text > 30){
            color = "#2db7f5"
        }
        
        return (<Tag color={color}>{text}</Tag>)
    }
    renderSetting(){
        return (<BusinessOpportunityFrom wrappedComponentRef={this.saveFormRef} visible={this.state.showModal}
                    values={this.state.modalValues} title={this.state.modalTitle} mode={this.state.modalMode}
                    onCancel={this.handleCancel} onOk={this.handleSubmit} businessflowChange={()=>{this.syncBusinessFlowOptions()}}/>)
    }
    renderTable(){
        let showColumns =  [{title:'标题',key: 'title',dataIndex: 'title',width:120,ellipsis:true},
                            {title:'类型',key: 'type',dataIndex: 'type',width:120,ellipsis:true,isSearch:true,sorter:(a,b)=>StringCompareSort(a.type,b.type),
                            render:(text)=>this.renderTypeStyleText(text)},
                            {title:'客户',key: 'custom',dataIndex: 'custom',width:120,ellipsis:true,isSearch:true,sorter:(a,b)=>StringCompareSort(a.custom,b.custom)},
                            {title:'商机来源',key: 'source',dataIndex: 'source',width:120,ellipsis:true,isSearch:true,sorter:(a,b)=>StringCompareSort(a.source,b.source)},
                            {title:'阶段',key: 'status',dataIndex: 'status',width:100,ellipsis:true,isSearch:true,sorter:(a,b)=>StringCompareSort(a.status,b.status),
                                render:(text)=>this.renderStatusStyleText(text)},
                            {title:'成交意向(%)',key: 'percent',dataIndex: 'percent',width:120,ellipsis:true,sorter: (a, b) => parseFloat(a.percent) - parseFloat(b.percent),
                                render:(text)=>this.renderPercentStyleText(text)},
                            {title:'预计金额',key:'expectDealMoney',dataIndex:'expectDealMoney',width:130,ellipsis:true,sorter:(a, b) => a.expectDealMoney - b.expectDealMoney},
                            {title: '负责人',key: 'manager',dataIndex: "manager",width:120,ellipsis:true,isSearch:true,sorter:(a,b)=>StringCompareSort(a.manager,b.manager),
                                render:(text, record) =><Row>{text instanceof Array?text.map((tag,index)=>{
                                                    const tagElem = (<Tag key={tag} color={"geekblue"}>{tag}</Tag>);
                                                    return (tagElem);
                                                }):text?(<Tag key={text} color={"geekblue"}>{text}</Tag>):null}
                                                </Row>},
                            {title:'参与人',key: 'follower',dataIndex: "follower",width:120,ellipsis:true,isSearch:true,sorter:(a,b)=>StringCompareSort(a.follower,b.follower),
                                render:(text, record) =><Row>{text instanceof Array?text.map((tag,index)=>{
                                                    const tagElem = (<Tag key={tag} color={"geekblue"}>{tag}</Tag>);
                                                    return (tagElem);
                                                }):text?(<Tag key={text} color={"geekblue"}>{text}</Tag>):null}
                                                </Row>},
                            {title:'创建时间',key:"createTime",dataIndex: "createTime",width:180,ellipsis:true,sorter: (a, b) => Date.parse(a.createTime) - Date.parse(b.createTime),
                                render:(text, record) =><span>{moment(new Date(text)).format('YYYY-MM-DD HH:mm:ss')}</span>},
                            {title:'创建人',key:"creator",dataIndex: "creator",width:130,ellipsis:true,isSearch:true,sorter:(a,b)=>StringCompareSort(a.creator,b.creator),
                                render:(text, record) =><Row>
                                    {text instanceof Array?text.map((tag,index)=>{
                                        const tagElem = (<Tag key={tag} color={"geekblue"}>{tag}</Tag>);
                                        return (tagElem);
                                    }):text?(<Tag key={text} color={"geekblue"}>{text}</Tag>):null}
                                </Row>},
                            {title:'操作',key:'actions',width:130,fixed: 'right', render: (text, record) => <Row>
                                <Col span={8}><Button type="link" onClick={()=>this.showBusiness(record)}>查看</Button></Col>
                                <Col span={8}><Button type="link" onClick={()=>this.handleOnEdit(record)}>编辑</Button></Col>
                                <Col span={8}><Button  type="link" style={{color:'red'}} onClick={()=>this.handleDelete(record.uuid)}>删除</Button></Col>
                            </Row>}]
        let dataSource = [];
        if(this.props.dataSource){
            for (let i = 0;i < this.props.dataSource.length;i++){
                let item = this.props.dataSource[i];
                let follower = account2Name(item.detail.follower_account)
                let manager = account2Name(item.detail.manager_account)
                let creator = account2Name(item.creator_account);
                dataSource.push({index:i+1,
                        uuid:item.uuid,
                        title:item.title,
                        type:item.type,
                        status:item.status,
                        custom:item.detail.custom_name,
                        percent:item.detail.percent,
                        source:item.detail.source,
                        expectDealMoney:item.detail.expect_deal_money,
                        follower:follower,
                        manager:manager,
                        creator:creator,
                        createTime:item.create_time});
            }
        }
        return (<ExtendTable columns={showColumns} tableKey={this.props.tableKey} customColumnsEnable={this.props.customColumnsEnable} 
                    dataSource={dataSource} rowKey={record => record.uuid} 
                    handleAddOnClick={this.handleAddOnClick} onBatchDelete={this.handleEmptySelect} />);
    }
    render(){
        return (<Row>
                    {this.renderTable()}
                    <Divider/>
                    {this.renderSetting()}
                </Row>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(BusinessOpportunityTable);