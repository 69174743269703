import React from 'react';
import {Tabs} from 'antd';
import {connect} from "react-redux";
import WorkLogTable from '../controls/WorkLogTable';
import {USER_LEVEL_MEMBER} from "../../constants/constant";
import { getWorkLog } from '../../actions/worklogDBTool';
const { TabPane } = Tabs;
function mapStateToProps(state) {
    return {...state.worklog}
}
function mapDispatchToProps(dispatch) {
    return {onGetWorkLog:()=>dispatch(getWorkLog())}
}
class WorkLog extends React.Component{
    constructor(props){
        super(props);
        this.state = {};
    }
    componentDidMount(){
        this.props.onGetWorkLog();
    }

    render(){
        let allDayDS = [];
        let allWeeksDS = [];
        let allMonthDS = [];
        let myManagerDayDS = [];
        let myManagerWeekDS = [];
        let myManagerMonthDS = [];
        let assignerDayDS = [];
        let assignerWeekDS = [];
        let assignerMonthDS = [];
        let myCreateDayDS = [];
        let myCreateWeekDS = [];
        let myCreateMonthDS = [];
        if(this.props.get_workLog_result){
            let result = this.props.get_workLog_result;
            for(let i = 0;i < result.length;i++){
                let detail = result[i].detail;
                if(detail.manager_account && -1 !== detail.manager_account.indexOf(localStorage.getItem('account'))){
                    if(result[i].type === "工作日报"){
                        myManagerDayDS.push(result[i]);
                    }
                    else if(result[i].type === "工作周报"){
                        myManagerWeekDS.push(result[i]);
                    }
                    else{
                        myManagerMonthDS.push(result[i]);
                    }
                }
                if(result[i].creator_account === localStorage.getItem("account")){
                    if(result[i].type === "工作日报"){
                        myCreateDayDS.push(result[i]);
                    }
                    else if(result[i].type === "工作周报"){
                        myCreateWeekDS.push(result[i]);
                    }
                    else{
                        myCreateMonthDS.push(result[i]);
                    }
                }
                if(detail.assigner_account && -1 !== detail.assigner_account.indexOf(localStorage.getItem('account'))){
                    if(result[i].type === "工作日报"){
                        assignerDayDS.push(result[i]);
                    }
                    else if(result[i].type === "工作周报"){
                        assignerWeekDS.push(result[i]);
                    }
                    else{
                        assignerMonthDS.push(result[i]);
                    }
                }
                if(result[i].type === "工作日报"){
                    allDayDS.push(result[i]);
                }
                else if(result[i].type === "工作周报"){
                    allWeeksDS.push(result[i]);
                }
                else{
                    allMonthDS.push(result[i]);
                }
            }
        }
        const editable = USER_LEVEL_MEMBER < Number(localStorage.getItem('level'))?true:false
        const isManager = USER_LEVEL_MEMBER < Number(localStorage.getItem('level'))?true:false
        return (<Tabs defaultActiveKey={'1'} type={"card"}>
            {isManager?<TabPane key={'1'} tab={"全部"}>
                <Tabs defaultActiveKey='1' type='card'>
                    <TabPane key='1' tab={'工作日报'}>
                        <WorkLogTable dataSource={allDayDS} customColumnsEnable={true} tableKey={'work/worklog'} exportEnable={editable} editEnable={editable}/>
                    </TabPane>
                    <TabPane key='2' tab={'工作周报'}>
                    <WorkLogTable dataSource={allWeeksDS} customColumnsEnable={true} tableKey={'work/worklog'} exportEnable={editable} editEnable={editable}/>
                    </TabPane>
                    <TabPane key='3' tab={'工作月报'}>
                    <WorkLogTable dataSource={allMonthDS} customColumnsEnable={true} tableKey={'work/worklog'} exportEnable={editable} editEnable={editable}/>
                    </TabPane>
                </Tabs>
            </TabPane>:null}
            <TabPane key={'2'} tab={"我创建的"}>
                <Tabs defaultActiveKey='1' type='card'>
                    <TabPane key='1' tab={'工作日报'}>
                        <WorkLogTable dataSource={myCreateDayDS} customColumnsEnable={true} tableKey={'work/worklog'} exportEnable={editable} editEnable={editable}/>
                    </TabPane>
                    <TabPane key='2' tab={'工作周报'}>
                        <WorkLogTable dataSource={myCreateWeekDS} customColumnsEnable={true} tableKey={'work/worklog'} exportEnable={editable} editEnable={editable}/>
                    </TabPane>
                    <TabPane key='3' tab={'工作月报'}>
                        <WorkLogTable dataSource={myCreateMonthDS} customColumnsEnable={true} tableKey={'work/worklog'} exportEnable={editable} editEnable={editable}/>
                    </TabPane>
                </Tabs>
            </TabPane>
            <TabPane key={'3'} tab={"我审核的"}>
                <Tabs defaultActiveKey='1' type='card'>
                    <TabPane key='1' tab={'工作日报'}>
                        <WorkLogTable dataSource={myManagerDayDS} customColumnsEnable={true} tableKey={'work/worklog'} exportEnable={editable} editEnable={editable}/>
                    </TabPane>
                    <TabPane key='2' tab={'工作周报'}>
                        <WorkLogTable dataSource={myManagerWeekDS} customColumnsEnable={true} tableKey={'work/worklog'} exportEnable={editable} editEnable={editable}/>
                    </TabPane>
                    <TabPane key='3' tab={'工作月报'}>
                        <WorkLogTable dataSource={myManagerMonthDS} customColumnsEnable={true} tableKey={'work/worklog'} exportEnable={editable} editEnable={editable}/>
                    </TabPane>
                </Tabs>
            </TabPane>
            <TabPane key={'4'} tab={"抄送给我"}>
            <TabPane key='1' tab={'工作日报'}>
                        <WorkLogTable dataSource={assignerDayDS} customColumnsEnable={true} tableKey={'work/worklog'} exportEnable={editable} editEnable={editable}/>
                    </TabPane>
                    <TabPane key='2' tab={'工作周报'}>
                        <WorkLogTable dataSource={assignerWeekDS} customColumnsEnable={true} tableKey={'work/worklog'} exportEnable={editable} editEnable={editable}/>
                    </TabPane>
                    <TabPane key='3' tab={'工作月报'}>
                        <WorkLogTable dataSource={assignerMonthDS} customColumnsEnable={true} tableKey={'work/worklog'} exportEnable={editable} editEnable={editable}/>
                    </TabPane>
            </TabPane>
        </Tabs>)
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(WorkLog);