import React from 'react';
import {Input, Row, Col, Table,  Modal, Select, Tabs, Button} from 'antd';
import {PlusCircleOutlined} from '@ant-design/icons';
import {getAllCustom} from "../../actions/customDBTool";
import {connect} from "react-redux";
import {customLevelList, customStatusList} from "../../constants/constant";
import {industryList} from '../../constants/industrylist';
import {cityList} from '../../constants/citylist';
import ExtendSelect from "./ExtendSelect";
const { Search } = Input;
const { TabPane } = Tabs;
const {Option} = Select;


let dataSource = [];
let commonCustom = []
let keyWord = '';
let customStatus = '';
let customLevel = '';
let customCity = '';
let customIndustry = '';
function mapStateToProps(state){
    return {...state.custom};
}
function mapDispatchToProps(dispatch){
    return {onGetAllCustom:(pageStart,pageSize)=>dispatch(getAllCustom(pageStart,pageSize)),
            onGetAllCommonCustom:(pageStart,pageSize)=>dispatch(getAllCustom({isCommon:true,pageStart:pageStart,pageSize:pageSize}))};
}
class CustomChooseSelect extends React.Component{
    constructor(props) {
        super(props);
        this.state = { visible:false,
                    selectCustom:'',
                    searchUpdate:false};
        this.onChooseCustomOk = this.onChooseCustomOk.bind(this);
        this.onSearchCustom = this.onSearchCustom.bind(this);
        this.onFilterCustom = this.onFilterCustom.bind(this);
    }
    componentDidMount(){
        this.props.onGetAllCustom();
        this.props.onGetAllCommonCustom();
        if(this.props.get_custom){
            dataSource = [];
            for (let i = 0;i < this.props.get_custom_result.length;i++){
                let item = this.props.get_custom_result[i];
                dataSource.push({name:item.name,uuid:item.uuid});
            }
        }
        if(this.props.get_common_custom){
            commonCustom = []
            for(let i = 0;i < this.props.get_common_custom_result.length;i++){
                let item = this.props.get_common_custom_result[i];
                commonCustom.push({name:item.name,uuid:item.uuid})
            }
        }
    }
    onChooseCustomOk(record){
        this.setState({visible:false,selectCustom:record.name});
        this.triggerChange(record.name)
    }
    onSearchCustom(value){
        if(undefined !== value){ keyWord = value;}
        else { keyWord = '';}
        this.onFilterCustom()
    }
    OnCustomLevel(value){
        if(undefined !== value){
            customLevel = value;
        }
        else{
            customLevel = '';
        }
        this.onFilterCustom()
    }
    OnCustomStatus(value){
        console.log(value);
        if(undefined !== value){
            customStatus = value;
        }
        else{
            customStatus = '';
        }
        this.onFilterCustom()
    }
    onChooseProvince(value){
        if(undefined !== value){
            cityList.forEach((item,index)=>{
                if(item.provinceName === value){
                    this.setState({province:item})
                }
            })
        }
        else{
            this.setState({province:''})
        }
    }
    onChooseCity(value){
        if(undefined !== value){
            customCity = value;
        }
        else{
            customCity = '';
        }
        this.onFilterCustom();
    }
    onChooseBigIndustry(value){
        let smallIndustry = [];
        if (undefined !== value){
                industryList.forEach((item,index)=>{
                if(item.name === value){
                    item.children.forEach(item=>{
                        smallIndustry.push(item.name);
                    })
                }
            });
            this.setState({bigIndustry:value,smallIndustryList:smallIndustry});
        }
    else{
            this.setState({bigIndustry:''});
        }
    }
    onChooseIndustry(value){
        console.log(value)
        if(undefined !== value){
            customIndustry = value;
            this.setState({industry:value});
        }
        else{
            customIndustry = '';
        }
        this.onFilterCustom();
    }
    onFilterCustom(){
        if(this.props.get_custom){
            dataSource = [];
            for (let i = 0;i < this.props.get_custom_result.length;i++){
                let item = this.props.get_custom_result[i];
                if((-1 === item.name.indexOf(keyWord) && 0 !== keyWord.length) ||
                    (customStatus !== item.status && 0 !== customStatus.length) ||
                    (customLevel !== item.level && 0 !== customLevel.length)||
                    (customIndustry !== item.detail.industry && 0 !== customIndustry.length)||
                    (customCity !== item.detail.city && 0 !== customCity.length)){
                    continue;
                }
                dataSource.push({name:item.name,uuid:item.uuid});
            }
            this.setState({searchUpdate:true});
        }
    }
    resetCustom(){
        if(this.props.get_custom){
            dataSource = [];
            for (let i = 0;i < this.props.get_custom_result.length;i++){
                let item = this.props.get_custom_result[i];
                dataSource.push({name:item.name,uuid:item.uuid});
            }
        }
        if(this.props.get_common_custom){
            commonCustom = []
            for(let i = 0;i<this.props.get_common_custom_result.length;i++){
                let item = this.props.get_common_custom_result[i];
                commonCustom.push({name:item.name,uuid:item.uuid});
            }
        }
        this.setState({searchUpdate:true});
    }
    triggerChange = (changedValue) => {
    // Should provide an event to pass value to Form.
        console.log(changedValue)
        if(undefined === changedValue){
            keyWord = '';
        }
        const onChange = this.props.onChange;
        if (onChange) {
            onChange(changedValue);
        }
    }

    renderChooseModel(){
        const columns = [{title:"客户名称",dataIndex:'name',
                    render:(text,record)=><Button type="link" onClick={()=>{this.onChooseCustomOk(record)}}>{text}</Button>}];
        return (<Modal title={"选择客户"} visible={this.state.visible} width={1200}
                    okText={"确定"} cancelText={"取消"}
                    onOk={()=>{this.setState({visible:false})}} onCancel={()=>{this.setState({visible:false})}}>
                    <Tabs defaultActiveKey={"1"}>
                        <TabPane key={"1"} tab={"我的客户"}>
                            <br/>
                            <Row>
                                <Col span={9}>
                                    <Row>
                                        <Col span={5}>所在城市：</Col>
                                        <Col span={8}>
                                            <Select style={{width:'100%'}} allowClear={true} onChange={(value) =>{this.onChooseProvince(value)}}>
                                                {cityList.map(item=><Option value={item.provinceName} key={item.provinceName}>
                                                    {item.provinceName}
                                                </Option>)}
                                            </Select>
                                        </Col>
                                        <Col span={8}>
                                            <Select style={{width:'100%'}} allowClear={true} onChange={(value)=>{this.onChooseCity(value)}}>
                                                {this.state.province?
                                                    (this.state.province.citys.map(item=><Option value={item.citysName} key={item.citysName}>{item.citysName}</Option>)):null}
                                            </Select>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col span={5}>所在行业：</Col>
                                        <Col span={8}>
                                            <Select style={{width:'100%'}} allowClear={true} 
                                                onChange={(value)=>{this.onChooseBigIndustry(value)}} value={this.state.bigIndustry}>
                                                {industryList.map(item=><Option value={item.name} key={item.name}>{item.name}</Option>)}
                                            </Select>
                                        </Col>
                                        <Col span={8}>
                                            <ExtendSelect style={{width:'100%'}} items={this.state.smallIndustryList} value={this.state.industry}
                                                allowClear={true} title={'行业'} onChange={(value)=>{this.onChooseIndustry(value)}}/>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col span={5}>客户等级：</Col>
                                        <Col span={16}>
                                            <Select style={{width:'100%'}} allowClear onChange={(value)=>{this.OnCustomLevel(value)}}>
                                                {customLevelList.map(item=><Option key={item} value={item}>{item}</Option>)}</Select>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col span={5}>客户状态：</Col>
                                        <Col span={16}>
                                            <Select style={{width:'100%'}} allowClear onChange={value =>{this.OnCustomStatus(value)}}>
                                                {customStatusList.map(item=><Option key={item} value={item}>{item}</Option>)}</Select>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={15}>
                                    <Row>
                                    <Search placeholder={"输入客户名称，可自动匹配"}
                                        onChange={event =>{this.onSearchCustom(event.target.value)}}
                                        onSearch={(value, event)=>{this.onSearchCustom(value)}}/>
                                        <br/>
                                        <br/>
                                        <Table dataSource={dataSource} columns={columns} rowKey={record => record.uuid} size={'small'}/>
                                    </Row>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane key={"2"} tab={"公海客户"}>
                        <br/>
                            <Row>
                                <Col span={9}>
                                    <Row>
                                        <Col span={5}>所在城市：</Col>
                                        <Col span={8}>
                                            <Select style={{width:'100%'}} allowClear={true} onChange={(value) =>{this.onChooseProvince(value)}}>
                                                {cityList.map(item=><Option value={item.provinceName} key={item.provinceName}>
                                                    {item.provinceName}
                                                </Option>)}
                                            </Select>
                                        </Col>
                                        <Col span={8}>
                                            <Select style={{width:'100%'}} allowClear={true} onChange={(value)=>{this.onChooseCity(value)}}>
                                                {this.state.province?
                                                    (this.state.province.citys.map(item=><Option value={item.citysName} key={item.citysName}>{item.citysName}</Option>)):null}
                                            </Select>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col span={5}>所在行业：</Col>
                                        <Col span={8}>
                                            <Select style={{width:'100%'}} allowClear={true} 
                                                onChange={(value)=>{this.onChooseBigIndustry(value)}} value={this.state.bigIndustry}>
                                                {industryList.map(item=><Option value={item.name} key={item.name}>{item.name}</Option>)}
                                            </Select>
                                        </Col>
                                        <Col span={8}>
                                            <ExtendSelect style={{width:'100%'}} items={this.state.smallIndustryList} value={this.state.industry}
                                                allowClear={true} title={'行业'} onChange={(value)=>{this.onChooseIndustry(value)}}/>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col span={5}>客户等级：</Col>
                                        <Col span={16}>
                                            <Select style={{width:'100%'}} allowClear onChange={(value)=>{this.OnCustomLevel(value)}}>
                                                {customLevelList.map(item=><Option key={item} value={item}>{item}</Option>)}</Select>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col span={5}>客户状态：</Col>
                                        <Col span={16}>
                                            <Select style={{width:'100%'}} allowClear onChange={value =>{this.OnCustomStatus(value)}}>
                                                {customStatusList.map(item=><Option key={item} value={item}>{item}</Option>)}</Select>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={15}>
                                    <Row>
                                    <Search placeholder={"输入客户名称，可自动匹配"}
                                        onChange={event =>{this.onSearchCustom(event.target.value)}}
                                        onSearch={(value, event)=>{this.onSearchCustom(value)}}/>
                                        <br/>
                                        <br/>
                                        <Table dataSource={commonCustom} columns={columns} rowKey={record => record.uuid} size={'small'}/>
                                    </Row>
                                </Col>
                            </Row>
                        </TabPane>
                    </Tabs>
            </Modal>);
    }
    render(){
        return (<Row style={this.props.style}>
                {this.renderChooseModel()}
                <Col span={15}>
                    <Select placeholder={"输入关键词，匹配客户"} value={this.props.value}
                            allowClear={true} showSearch={true} onSearch={value => {this.onSearchCustom(value)}}
                            onChange={(value) => {this.triggerChange(value)}} disabled={this.props.disabled}>
                        {keyWord?dataSource.map(item=><Option value={item.name} key={item.name}>{item.name}</Option>):null}
                    </Select>
                </Col>
                <Col span={8}>
                    <Button type="link" disabled={this.props.disabled} onClick={()=>{
                                                                                this.setState({visible:true});
                                                                                this.resetCustom();
                                                                            }}>
                        <PlusCircleOutlined />选择
                    </Button>
                </Col>
            </Row>)
    }
}
export default connect(mapStateToProps,mapDispatchToProps,null,{forwardRef: true})(CustomChooseSelect)
