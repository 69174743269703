import {store} from '../stores/store';
export function account2Name(account){
    if(!account){return "";}
    const state = store.getState();
    const staffState = state.staff;
    let nameList = [];
    if (staffState.get_staff_list_result) {
        let staffList = staffState.get_staff_list_result;
        if(staffList instanceof Array){
            staffList.forEach(item => {
                if(account instanceof Array){
                    for(let i = 0;i < account.length;i++){
                        if(account[i] === item.account){
                            nameList.push(item.name);
                        }
                    }
                }
                else if(account === "公海客户"){nameList = "公海客户";}
                else if(-1 !== account.indexOf(item.account) ){
                    nameList.push(item.name);
                }
            })
        }else{
            if(account === staffList.account){
                nameList = staffList.name;
            }
            else{
                nameList = account;
            }
        }
    }
    if(!nameList.length){nameList = account;}
    if(nameList instanceof Array && 1 === nameList.length){ nameList = nameList[0];}
    return nameList;
}
export function name2Account(name){
    if(!name){return;}
    const state = store.getState();
    const staffState = state.staff;
    let accountList = [];
    if (staffState.get_staff_list_result) {
        let staffList = staffState.get_staff_list_result;
        staffList.forEach(item => {
            if(name instanceof Array){
                for(let i = 0;i < name.length;i++){
                    if(item.name === name[i]){
                        accountList.push(item.account);
                    }
                }
            } else if(name === "公海客户"){
                accountList = "公海客户";
            }
            else if(item.name === name){
                accountList.push(item.account);
            }
        })
    }else{
        accountList = name;
    }
    if(accountList instanceof Array && 1 === accountList.length){ accountList = accountList[0];}
    return accountList;
}
export function customName2Uuid(customName){
    if(!customName){return;}
    const state = store.getState();
    const customState = state.custom;
    if(customState.get_custom_result){
        let customList = customState.get_custom_result;
        for(let i = 0;i < customList.length;i++){
            if(customList[i].name === customName){
                return customList[i].uuid.toString();
            }
        }
    }
    if(customState.get_common_custom_result){
        let commonCustomList = customState.get_common_custom_result;
        for(let i = 0;i < commonCustomList.length;i++){
            if(commonCustomList[i].name === customName){
                return commonCustomList[i].uuid.toString();
            }
        }
    }
}
export function productName2Uuid(productName){
    if(!productName){return;}
    const state = store.getState();
    const productState = state.product;
    let uuid = productName; //for the special that the product is not define in the product list
    if(productState.get_product_result){
        let productList = productState.get_product_result;
        for(let i = 0;i < productList.length;i++){
            if(productName === productList[i].name){
                return productList[i].uuid;
            }
        }
    }
    return uuid;
}
export function Uuid2ProductName(uuid){
    if(!uuid){return;}
    const state = store.getState();
    const productState = state.product;
    let name = uuid; //for the special that the product is not define in the product list
    if(productState.get_product_result){
        let productList = productState.get_product_result;
        for(let i = 0;i < productList.length;i++){
            if(uuid === productList[i].uuid){
                return productList[i].name;
            }
        }
    }
    return name;
}
export function id2Department(id){
    let departmentList = JSON.parse(localStorage.getItem('department_list'))
    for(let i = 0;i < departmentList.length;i++){
        if(id === departmentList[i].id){
            return departmentList[i].name;
        }
    }
    return ""
}