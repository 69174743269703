import React from 'react';
import {Row,Tabs} from 'antd';
import CollectionTable from "../controls/CollectionTable";
import {connect} from "react-redux";
import {USER_LEVEL_MEMBER} from "../../constants/constant";

const { TabPane } = Tabs;
function mapStateToProps(status) {
    return {...status.collection}
}
function mapDispatchToProps(dispatch){
    return {}
}
class Collection extends React.Component{
    constructor(props){
        super(props);
        this.state = { };
    }
    componentDidMount(){}
    renderCollection(){
        let allDS = [];
        let myCreateDS = [];
        let myManageDS = [];
        let myAssignDS = [];
        if(this.props.get_collection_result){
            for (let i = 0;i < this.props.get_collection_result.length;i++){
                let record = this.props.get_collection_result[i];
                let detail = record.detail;
                console.log(detail)
                if( detail.manager_account && -1 !== detail.manager_account.indexOf(localStorage.getItem('account'))){
                    myManageDS.push(record);
                }
                if(-1 !== record.creator_account.indexOf(localStorage.getItem('account'))){
                    myCreateDS.push(record);
                }
                if(detail.assigner_account && -1 !== detail.assigner_account.indexOf(localStorage.getItem('account'))){
                    myAssignDS.push(record);
                }
                allDS.push(record)
            }
        }
        const editable = USER_LEVEL_MEMBER < Number(localStorage.getItem('level'))?true:false
        return (<Tabs defaultActiveKey="1" type="card">
                {editable?<TabPane key="1" tab="全部">
                        <CollectionTable dataSource={allDS} exportEnable={editable} customColumnsEnable={true} tableKey={'money/collection'} editEnable={editable}/>
                    </TabPane>:null}
                    <TabPane key="2" tab="我审核的">
                        <CollectionTable dataSource={myManageDS} exportEnable={editable} customColumnsEnable={true} tableKey={'money/collection'} editEnable={editable}/>
                    </TabPane>
                    <TabPane key="3" tab="我创建的">
                        <CollectionTable dataSource={myCreateDS} exportEnable={editable} customColumnsEnable={true} tableKey={'money/collection'} editEnable={editable}/>
                    </TabPane>
                    <TabPane key="4" tab="抄送给我">
                        <CollectionTable dataSource={myAssignDS} exportEnable={editable} customColumnsEnable={true} tableKey={'money/collection'} editEnable={editable}/>
                    </TabPane>
            </Tabs>);
    }
    render(){
            return(<Row>{this.renderCollection()}</Row>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Collection);