import $ from 'jquery'
//import axios from "axios";
import {message} from "antd";
import * as Service from '../config/service.json';
import {ActionType} from "../constants/actionTypes";
import {preHandle,checkAccountStatus} from './commonDBTool';

export function getEnterprise(){
    let url = Service.dbServer + Service.apiVersion +"/enterprise/get" ;
    return dispatch =>{
        dispatch({type:ActionType.GET_ENTERPRISE_START});
        $.ajax(preHandle(url),{
            method:'GET',
            contentType:'application/json; charset=utf-8',
            dataType:'json'
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            let result = response.result;
            localStorage.setItem("enterprise_uuid",result.uuid)
            localStorage.setItem("enterprise_name",result.name);
            localStorage.setItem("enterprise_level",result.level);
            localStorage.setItem("enterprise_type",result.type);

            localStorage.setItem("max_storage",result.max_storage_size);
            localStorage.setItem("expired_time",new Date(result.expired_time).getTime());
            localStorage.setItem("enterprise_detail",result.detail);
            localStorage.setItem("enterprise_feature_list",result.open_feature_list);
            localStorage.setItem("department_list",result.department_list)
            dispatch(checkExpiredTime());
            dispatch({type:ActionType.GET_ENTERPRISE_SUCCESS,payload:result});
        }).catch(function (error) {
            dispatch({type:ActionType.GET_ENTERPRISE_FAILED,payload:error});
        })
    }
}
export function getEnterpriseDepartment(callback) {
    let url = Service.dbServer + Service.apiVersion +"/enterprise/get" ;
    return dispatch =>{
        dispatch({type:ActionType.GET_ENTERPRISE_START});
        $.ajax(preHandle(url),{
            method:'GET',
            contentType:'application/json; charset=utf-8',
            dataType:'json'
        }).then((response)=>{
            let result = response.result
            let departmentList = JSON.parse(result.department_list);
            for(let i = 0;i < departmentList.length;i++){
                if(departmentList[i].id === JSON.parse(localStorage.getItem('staff_detail')).position_id1){
                    localStorage.setItem("feature",departmentList[i].feature);
                }
            }
            dispatch({type:ActionType.GET_DEPARTMENT_BY_ENTERPRISE_SUCCESS,payload:departmentList});
            if(typeof callback == "function") {callback();}
        }).catch(function (error) {
            dispatch({type:ActionType.GET_ENTERPRISE_FAILED,payload:error});
        })
    }
}
export function updateEnterprise(postData){
    let url = Service.dbServer + Service.apiVersion + "/enterprise/update"
    return dispatch=>{
        dispatch({type:ActionType.UPDATE_DEPARTMENT_BY_ENTERPRISE_START});
        $.ajax(preHandle(url),{
            method:'POST',
            contentType:'application/json; charset=utf-8',
            dataType:'json',
            data:JSON.stringify(postData)
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            if (200 === response.code){
                message.info("更新成功!");
                dispatch({type:ActionType.UPDATE_ENTERPRISE_SUCCESS,payload:response.result});
                dispatch(getEnterprise());
            }
            else {
                message.error("修改失败!");
                dispatch({type:ActionType.UPDATE_ENTERPRISE_FAILED,payload:response.result});
            }
        }).catch(function (error) {
            message.error("修改失败!");
            dispatch({type:ActionType.UPDATE_ENTERPRISE_FAILED,payload:error});
        })
    }
}
export function updateEnterpriseDepartment(departmentList){
    let postData = {
        uuid:localStorage.getItem("enterprise_uuid"),
        name:localStorage.getItem("enterprise_name"),
        level:localStorage.getItem("enterprise_level"),
        type:localStorage.getItem("enterprise_type"),
        status:1,
        balance:0,
        max_member_count:1,
        max_custom_count:1,
        max_order_count:1,
        max_product_count:1,
        max_trash_count:1,
        max_trash_keep_time:1,
        max_storage_size:localStorage.getItem("max_storage"),
        detail:localStorage.getItem("enterprise_detail"),
        open_feature_list:localStorage.getItem("enterprise_feature_list"),
        department_list:JSON.stringify(departmentList),
        expired_time:localStorage.getItem("expired_time"),
        update_time:Date.parse(new Date()),
        create_time:Date.parse(new Date())
    }
    return dispatch =>{dispatch(updateEnterprise(postData));}
}
export function updateEnterpriseProfile(name,founder){
    let detail = JSON.parse(localStorage.getItem("enterprise_detail"))
    detail['founder_account'] = founder
    let postData = {
        uuid:localStorage.getItem("enterprise_uuid"),
        name:name,
        level:localStorage.getItem("enterprise_level"),
        type:localStorage.getItem("enterprise_type"),
        status:1,
        balance:0,
        max_member_count:1,
        max_custom_count:1,
        max_order_count:1,
        max_product_count:1,
        max_trash_count:1,
        max_trash_keep_time:1,
        max_storage_size:localStorage.getItem("max_storage"),
        detail:JSON.stringify(detail),
        open_feature_list:localStorage.getItem("enterprise_feature_list"),
        department_list:localStorage.getItem("department_list"),
        expired_time:localStorage.getItem("expired_time"),
        update_time:Date.parse(new Date()),
        create_time:Date.parse(new Date())
    }
    return dispatch =>{dispatch(updateEnterprise(postData));}
}
export function updateEnterpriseDetail(detail){
    console.log(detail)
    let postData = {
        uuid:localStorage.getItem("enterprise_uuid"),
        name:localStorage.getItem("enterprise_name"),
        level:localStorage.getItem("enterprise_level"),
        type:localStorage.getItem("enterprise_type"),
        status:1,
        balance:0,
        max_member_count:1,
        max_custom_count:1,
        max_order_count:1,
        max_product_count:1,
        max_trash_count:1,
        max_trash_keep_time:1,
        max_storage_size:localStorage.getItem("max_storage"),
        detail:JSON.stringify(detail),
        open_feature_list:localStorage.getItem("enterprise_feature_list"),
        department_list:localStorage.getItem("department_list"),
        expired_time:localStorage.getItem("expired_time"),
        update_time:Date.parse(new Date()),
        create_time:Date.parse(new Date())
    }
    return dispatch =>{dispatch(updateEnterprise(postData));}
}

export function checkExpiredTime() {
    let url = Service.dbServer + Service.apiVersion + "/enterprise/checkExpired" ;
    return dispatch=>{
        $.ajax(preHandle(url),{
            method:'GET',
            contentType:'application/json; charset=utf-8',
            dataType:'json'
        }).then((response)=>{
            checkAccountStatus(response.code,response.message)
        }).catch(function (error) {
        })
    }
}