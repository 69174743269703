import {ActionType} from "../constants/actionTypes";

const loginLogReducer = (state={},action)=>{
    switch (action.type) {
        case  ActionType.GET_LOGIN_LOG_START:
            return {...state,get_login_log: false};
        case  ActionType.GET_LOGIN_LOG_FAILED:
            return {...state,get_login_log: false,get_login_log_errors:action.payload?action.payload:null};
        case  ActionType.GET_LOGIN_LOG_SUCCESS:
            return {...state,get_login_log: true,get_login_log_result:action.payload};
        default:
            return {...state};
    }
};
export default loginLogReducer;