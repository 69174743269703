import React from 'react';
import {Row,Col,Tabs,Table,Input,Button,Divider} from 'antd';
import { connect } from 'react-redux';
import {ActionType} from "../../../constants/actionTypes";
const {TabPane} = Tabs;
const {Search} = Input;
function mapStateToProps(state){
    return {...state.custom,
        value:state.count,
    };
}
function mapDispatchToProps(dispatch){
    return {onIncreaseClick:(value) => dispatch({ type: ActionType.INCREASE_TEST, value })};
};
class CustomComplaint extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            hasAdminPermit:true,
            complaintDS:[]
        };
    }
    renderTable(){
        const columns = [
            {title:'主题',dataIndex:'title'},
            {title:'投诉内容',dataIndex:'content'},
            {title:'状态',dataIndex:'status'},
            {title:'记录时间',dataIndex:'createTime'},
            {title:'记录人',dataIndex:'creator'},
            {title: '操作',key: 'actions',render: (text, record) =>
                <span>
                    <a href onClick={()=>this.handleRestore(record.key)}>编辑</a>
                    <a href onClick={()=>this.handleDelete(record.key)}>删除</a>
                </span>
            }
        ];
        return(<Row>
            <Row>
                <Table columns={columns} dataSource={this.state.complaintDS}/>
            </Row>
            <Divider/>
            <Row>
                {this.renderSetting()}
            </Row>
        </Row>);
    }
    renderComplaint(){
        return(<Tabs defaultActiveKey="1" type="card">
            <TabPane key="1" tab="指派给我">{this.renderTable()}</TabPane>
            <TabPane key="2" tab="由我创建">{this.renderTable()}</TabPane>
            {this.state.hasAdminPermit?(
                <TabPane key="3" tab="全部投诉">
                    {this.renderTable()}
                </TabPane>
            ):null}
        </Tabs>);
    }
    renderSetting(){
        return(<Row>
            {this.props.count}
                <Col span={4}><Button type="primary">新增</Button></Col>
                <Col span={8}><Search placeholder="搜索" style={{width:'200px'}}/></Col>
                {/*//TODO search support mult key such custom name,staff ,order and so on*/}
            </Row>);
    }
    render(){
        return (<Row>{this.renderComplaint()}</Row>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(CustomComplaint);