import React from 'react';
import {Button, Form, Row,Tabs, InputNumber, Col, Switch} from 'antd';
import CustomTable from '../../controls/CustomTable';
import {USER_LEVEL_MEMBER} from "../../../constants/constant";
import {getAllCustom} from "../../../actions/customDBTool";
import {getAllFollowUp} from "../../../actions/followupDBTool"
import {updateUserDetail} from '../../../actions/userDBTool';
import {connect} from "react-redux";
import {isToday,out7Days,out30Days,out60Days,out90Days} from "../../../utils/common";
const { TabPane } = Tabs;
function mapStateToProps(state){
    return {...state.custom};
}
function mapDispatchToProps(dispatch){
    return {onGetCustom:()=>dispatch(getAllCustom()),
            onGetFollow:()=>dispatch(getAllFollowUp()),
            onUpdateDetail:(detail)=>dispatch(updateUserDetail(detail))};
}
class UnFollowCustom extends React.Component{
    constructor(props){
        super(props);
        this.state = {unfollow_days_enable:false};
    }
    componentDidMount(){
        let detail = JSON.parse(localStorage.getItem('staff_detail'));
        this.props.onGetCustom();
        this.props.onGetFollow();
        this.setState({unfollow_days_enable:detail['unfollow_days_enable']?detail['unfollow_days_enable']:false})
    }
    handleUnFollowTime(e){
        e.preventDefault();
        let newTime = e.target.time.value;
        let detail = JSON.parse(localStorage.getItem('staff_detail'));
        detail['unfollow_days'] = newTime;
        detail['unfollow_days_enable'] = this.state.unfollow_days_enable;
        this.props.onUpdateDetail(detail);
    }
    renderCustomConfig(){
        let detail = JSON.parse(localStorage.getItem('staff_detail'));
        return(<Form onSubmit={e=>{this.handleUnFollowTime(e)}} labelCol={{span:8}} wrapperCol={{span:10}} style={{textAlign:'left'}}>
            <Row>
                <Col span={10} offset={6}>
                    <span>默认未跟进客户自动转移至公海的时间为180天</span>
                </Col>
            </Row>
            <Row>
                <Col span={8} offset={2}>
                    <Form.Item label={"无跟进客户保留天数"}>
                        <InputNumber name={'time'} defaultValue={detail.unfollow_days?detail.unfollow_days:180} disabled={!this.state.unfollow_days_enable}/>
                    </Form.Item>
                    <Form.Item label={"开启过期自动转入公海"}>
                        <Switch onChange={(value)=>{this.setState({unfollow_days_enable:value})}}
                        defaultChecked={detail.unfollow_days_enable?detail.unfollow_days_enable:false}/>
                    </Form.Item>
                </Col>
                <Col span={2}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">确认</Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>)
    }
    renderUnFollowCustom(){
        let myUnFollowCustomDS = [];
        let allUnFollowCustomDS = [];
        let todayUnFollowCustomDS = [];
        let out7DayUnFollowCustomDS = [];
        let out30DayUnFollowCustomDS = [];
        let out60DayUnFollowCustomDS = [];
        let neverUnFollowCustomDS = [];
        let result = this.props.get_custom_result?this.props.get_custom_result:[];
        let followList = this.props.get_followup_result?this.props.get_followup_result:[]
        let customIDList = []
        for(let j = 0;j < followList.length;j++){
            customIDList.push(followList[j].custom_uuid)
        }
        for (let i = 0;i < result.length;i++){
            /*if(result[i].detail.manager_account === '公海客户'){
                continue;
            }*/
            if(-1 === customIDList.indexOf(result[i].uuid)){
                allUnFollowCustomDS.push(result[i]);
                if(result[i].detail.manager_account && -1 !== result[i].detail.manager_account.indexOf(localStorage.getItem('account'))){
                    myUnFollowCustomDS.push(result[i]);
                }
                if(isToday(result[i].update_time)){
                    todayUnFollowCustomDS.push(result[i]);
                }
                else if(out7Days(result[i].update_time) && !out30Days(result[i].update_time)){
                    out7DayUnFollowCustomDS.push(result[i]);
                }
                else if(out30Days(result[i].update_time) && !out60Days(result[i].update_time)){
                    out30DayUnFollowCustomDS.push(result[i]);
                }
                else if(out60Days(result[i].update_time) && !out90Days(result[i].update_time)){
                    out60DayUnFollowCustomDS.push(result[i]);
                }
                else{
                    neverUnFollowCustomDS.push(result[i]);
                }
            }
        }
        const customEditable = USER_LEVEL_MEMBER < Number(localStorage.getItem('level'))?true:false
        const isManager = USER_LEVEL_MEMBER < Number(localStorage.getItem('level'))?true:false
        return(<Tabs defaultActiveKey={"2"}>
                {isManager?
                (<TabPane key="1" tab="全部未跟进客户">
                    <CustomTable dataSource={allUnFollowCustomDS} exportEnable={customEditable} editEnable={customEditable} batchDelEnable={customEditable} batchEditEnable={customEditable} tableKey={'crm/trend_custom'}/>
                </TabPane>):null}
                <TabPane key="2" tab="我的未跟进客户">
                    <CustomTable dataSource={myUnFollowCustomDS} exportEnable={customEditable} editEnable={customEditable} batchDelEnable={customEditable} batchEditEnable={customEditable} tableKey={'crm/trend_custom'}/>
                </TabPane>
                <TabPane key={"3"} tab={"7天以上"}>
                    <CustomTable dataSource={out7DayUnFollowCustomDS} exportEnable={customEditable} editEnable={customEditable} batchDelEnable={customEditable} batchEditEnable={customEditable} tableKey={'crm/trend_custom'}/>
                </TabPane>
                <TabPane key={"4"} tab={"30天以上"}>
                    <CustomTable dataSource={out30DayUnFollowCustomDS} exportEnable={customEditable} editEnable={customEditable} batchDelEnable={customEditable} batchEditEnable={customEditable} tableKey={'crm/trend_custom'}/>
                </TabPane>
                <TabPane key={"5"} tab={"60天以上"}>
                    <CustomTable dataSource={out60DayUnFollowCustomDS} exportEnable={customEditable} editEnable={customEditable} batchDelEnable={customEditable} batchEditEnable={customEditable} tableKey={'crm/trend_custom'}/>
                </TabPane>
                <TabPane key={"6"} tab={"90天以上"}>
                    <CustomTable dataSource={neverUnFollowCustomDS} exportEnable={customEditable} editEnable={customEditable} batchDelEnable={customEditable} batchEditEnable={customEditable} tableKey={'crm/trend_custom'}/>
                </TabPane>
                {isManager?<TabPane key='7' tab={"自定义设置"}>
                    {this.renderCustomConfig()}
                </TabPane>:null}
            </Tabs>);
    }
    render(){
        return (<Row>{this.renderUnFollowCustom()}</Row>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(UnFollowCustom);
