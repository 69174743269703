import {ActionType} from "../constants/actionTypes";

const orderReducer = (state={},action)=>{
    switch (action.type) {
        case ActionType.GET_ORDER_BY_USER_START:
            return {...state, get_order:false}
        case ActionType.GET_ORDER_BY_USER_FAILED:
            return {...state, get_order:false, get_order_errors: action.payload?action.payload:null}
        case ActionType.GET_ORDER_BY_USER_SUCCESS:
            return {...state, get_order:true, get_order_result:action.payload}

        case ActionType.GET_ORDER_BY_CUSTOM_START:
            return {...state,get_order_by_custom:false}
        case ActionType.GET_ORDER_BY_CUSTOM_SUCCESS:
            return {...state,get_order_by_custom: true,get_order_by_custom_result:action.payload}
        case ActionType.GET_ORDER_BY_CUSTOM_FAILED:
            return {...state,get_order_by_custom:false,get_order_by_custom_error:action.payload?action.payload:null}

        case ActionType.GET_ORDER_AMOUNT_RATE_START:
            return {...state,get_order_amount_rate:false}
        case ActionType.GET_ORDER_AMOUNT_RATE_SUCCESS:
            return {...state,get_order_amount_rate:true,get_order_amount_rate_result:action.payload}
        case ActionType.GET_ORDER_AMOUNT_RATE_FAILED:
            return {...state,get_order_amount_rate:false,get_order_amount_rate_error:action.payload?action.payload:null}

        case ActionType.GET_ORDER_COUNT_RATE_START:
            return {...state,get_order_count_rate:false}
        case ActionType.GET_ORDER_COUNT_RATE_SUCCESS:
            return {...state,get_order_count_rate:true,get_order_count_rate_result:action.payload}
        case ActionType.GET_ORDER_COUNT_RATE_FAILED:
            return {...state,get_order_count_rate:false,get_order_count_rate_error:action.payload?action.payload:null}

        case ActionType.DELETE_ORDER_START:
            return {...state, delete_order:false,}
        case ActionType.DELETE_ORDER_FAILED:
            return {...state, delete_order:false, order_del_errors:action.payload?action.payload:null}
        case ActionType.DELETE_ORDER_SUCCESS:
            return {...state, delete_order:true, order_del_result:action.payload}


        case ActionType.ADD_ORDER_START:
            return {...state, add_order:false,}
        case ActionType.ADD_ORDER_FAILED:
            return {...state, add_order:false, add_order_error:action.payload?action.payload:null,}
        case ActionType.ADD_ORDER_SUCCESS:
            return {...state, add_order:true, add_order_result:action.payload}


        case ActionType.ADD_ORDER_DEL_START:
            return {...state, add_order_del:false}
        case ActionType.ADD_ORDER_DEL_FAILED:
            return {...state, add_order_del:false, add_order_del_error:action.payload?action.payload:null}
        case ActionType.ADD_ORDER_DEL_SUCCESS:
            return {...state, add_order_del:true, add_order_del_result:action.payload}


        case ActionType.GET_ORDER_DEL_START:
            return{...state, get_order_del:false}
        case ActionType.GET_ORDER_DEL_FAILED:
            return{...state, get_order_del:false, order_del_errors: action.payload?action.payload:null,}
        case ActionType.GET_ORDER_DEL_SUCCESS:
            return{...state, get_order_del:true, order_del_result:action.payload}


        case ActionType.ORDER_RESTORE_START:
            return {...state, restore_order:false,}
        case ActionType.ORDER_RESTORE_FAILED:
            return {...state, restore_order:false, order_restore_errors:action.payload?action.payload:null,}
        case ActionType.ORDER_RESTORE_SUCCESS:
            return {...state, restore_order:true, order_restore_result:action.payload}

        default:
            return {...state};
    }
}
export default orderReducer;