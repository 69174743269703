import React from 'react';
import {connect} from "react-redux";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'moment/locale/zh-cn';
import {exportExcel} from './ExcelUtil';
import { StringCompareSort } from '../../utils/common';
import {CaretDownOutlined} from '@ant-design/icons';
import {Row, Col,Form, Input, Button,  Modal,  Radio, Tag} from 'antd';
import { account2Name, name2Account } from '../../utils/accountUtils';
import ExtendTable from './ExtendTable';
import StaffChoose from './StaffChoose';
import FileManage from './FileManage';
import { addSupplier, deleteSupplier, getSupplier, updateSupplier } from '../../actions/supplierDBTool';

const {confirm} = Modal;
const SupplierForm = Form.create({name:'supplier_modal'})(
    class extends React.Component{
        constructor(props) {
            super(props);
            this.state = {};
        }
        componentDidMount() {}
        render(){
            let { visible, onCancel, onOk, form,values,title,mode } = this.props;
            let { getFieldDecorator } = form;
            return (<Modal title={title} visible={visible} width={800} maskClosable={false} 
                        onOk={onOk} okText={2===mode?"关闭":"确定"} onCancel={onCancel}>
                <Form labelCol={{span:6}} wrapperCol={{span:18}}>
                    <Form.Item label={"供应商名称"} required={true}>
                        {getFieldDecorator('name', {rules: [{ required: true, message:'供应商名称不能为空!'}],initialValue:mode?values.name:''})
                        (<Input placeholder={"例如：XXXX"} style={{width:'60%'}} disabled={2 === mode?true:false}/>)}
                    </Form.Item>
                    <Form.Item label={"供应商类别"}>
                        {getFieldDecorator('type', {rules: [{ required: false}],initialValue:mode?values.type:''})
                        (<Input placeholder={"例如：XXXX"} style={{width:'60%'}} disabled={2 === mode?true:false}/>)}
                    </Form.Item>
                    <Form.Item label={"供应商等级"}>
                        {getFieldDecorator('level', {rules: [{ required: false}],initialValue:mode?values.level:''})
                        (<Input placeholder={"例如：XXXX"} style={{width:'60%'}} disabled={2 === mode?true:false}/>)}
                    </Form.Item>
                    <Form.Item label={"供应商状态"}>
                        {getFieldDecorator('status', {rules: [{ required: false}],initialValue:mode?values.status:''})
                        (<Input placeholder={"例如：XXXX"} style={{width:'60%'}} disabled={2 === mode?true:false}/>)}
                    </Form.Item>
                    <Form.Item label={"供应商来源"}>
                        {getFieldDecorator('source', {rules: [{ required: false}],initialValue:mode?values.source:''})
                        (<Input placeholder={"例如：XXXX"} style={{width:'60%'}} disabled={2 === mode?true:false}/>)}
                    </Form.Item>
                    <Form.Item label={"地址"}>
                        {getFieldDecorator('address', {rules: [{ required: false}],initialValue:mode?values.address:''})
                        (<Input placeholder={"例如：XXXX"} style={{width:'60%'}} disabled={2 === mode?true:false}/>)}
                    </Form.Item>
                    <Form.Item label={"联系人"}>
                        {getFieldDecorator('contact', {rules: [{ required: false}],initialValue:mode?values.contact:''})
                        (<Input placeholder={"例如：XXXX"} style={{width:'60%'}} disabled={2 === mode?true:false}/>)}
                    </Form.Item>
                    <Form.Item label={"手机"}>
                        {getFieldDecorator('phone', {rules: [{ required: false}],initialValue:mode?values.phone:''})
                        (<Input placeholder={"例如：XXXX"} style={{width:'60%'}} disabled={2 === mode?true:false}/>)}
                    </Form.Item>
                    <Form.Item label={"座机"}>
                        {getFieldDecorator('tel', {rules: [{ required: false}],initialValue:mode?values.tel:''})
                        (<Input placeholder={"例如：XXXX"} style={{width:'60%'}} disabled={2 === mode?true:false}/>)}
                    </Form.Item>
                    <Form.Item label={"邮箱地址"}>
                        {getFieldDecorator('email', {rules: [{ required: false}],initialValue:mode?values.email:''})
                        (<Input placeholder={"例如：XXXX"} style={{width:'60%'}} disabled={2 === mode?true:false}/>)}
                    </Form.Item>
                    <Form.Item label={"负责人"} required={true}>
                        {getFieldDecorator('manager', {rules:[{required:true,message:'负责人不能为空！'}],initialValue: mode?values.manager:''})
                        (<StaffChoose mode={0} disabled={2 === mode?true:false}/>)}
                    </Form.Item>
                    <Form.Item label={"特别备注"}>
                        {getFieldDecorator('note', {rules: [{ required: false}],initialValue:values.note?values.note:""})(
                            <ReactQuill placeholder={"XXX"} readOnly={1 < mode?true:false}/>)}
                    </Form.Item>
                    <Form.Item label={"相关文档"}>
                        {getFieldDecorator('fileUrl', {initialValue:mode?values.file_url:""})
                            (<FileManage filePrefix={"supplier/" + values.uuid+"/"} fileList={values.file_url} disabled={1 < mode?true:false}/>)}
                    </Form.Item>
                </Form>
            </Modal>)
        }
    })
function mapStateToProps(state) {
    return {...state.supplier,...state.product}
}
function mapDispatchToProps(dispatch){
    return {onGetAllSupplier:()=>dispatch(getSupplier()),
            onUpdateSupplier:(postData)=>dispatch(updateSupplier(postData)),
            onDeleteSupplier:(uuid)=>dispatch(deleteSupplier(uuid)),
            onAddSupplier:(postData)=>dispatch(addSupplier(postData))
        }
}
class SupplierTable extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            showSupplierModal:false,
            modalMode:0,
            modalTitle:'新增',
            exportType:'xlsx',
            modalValues:{uuid:"",
                        title:'',
                        creator:'',
                        createTime:''
                    }
            }
        this.handleDelete = this.handleDelete.bind(this);
        this.updateModalValues = this.updateModalValues.bind(this);
        this.OnClickExport = this.OnClickExport.bind(this);
        this.renderAction = this.renderAction.bind(this);
        this.showContent = this.showContent.bind(this);
        this.handleOnEditClick = this.handleOnEditClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.renderExportModal = this.renderExportModal.bind(this);
        this.handleAddOnClick = this.handleAddOnClick.bind(this);
    }
    saveFormRef = formRef => {
        this.formRef = formRef;
    }
    updateModalValues(data){
        let detail = data.detail;
        this.setState({modalValues:{
            uuid:data.uuid,
            name:data.name,
            type:data.type,
            status:data.status,
            level:data.level,
            source:detail.source,
            contact:detail.contact,
            tel:detail.tel,
            phone:detail.phone,
            email:detail.email,
            address:detail.address,
            note:detail.note,
            file_url:detail.file_url?detail.file_url:[],
            manager:account2Name(detail.manager_account),
            createTime:data.create_time,
            creator:account2Name(data.creator_account)
        }});
    }
    OnClickExport(){
        let dataSource = this.props.dataSource
        let exportDataSource = []
        for(let i=0;i<dataSource.length;i++){
            exportDataSource.push({"序号":i+1,
                                   "供应商名称":dataSource[i].name,
                                   "供应商类别":dataSource[i].type,
                                   "供应商等级":dataSource[i].level,
                                   "供应商状态":dataSource[i].status,
                                   "供应商来源":dataSource[i].detail.source,
                                   "联系地址":dataSource[i].detail.address,
                                   "联系电话":dataSource[i].detail.phone,
                                   "座机":dataSource[i].detail.tel,
                                   "邮箱":dataSource[i].detail.email,
                                   "特别备注":dataSource[i].detail.note,
                                   "负责人":account2Name(dataSource[i].detail.manager_account),
                                   "创建人":account2Name(dataSource[i].creator_account),
                                   "创建时间":dataSource[i].create_time
                                })
        }
        exportExcel(exportDataSource,this.state.exportType,'供应商');
    }
    handleDelete(uuid){
        confirm({
            title: '重要提示',
            content: '您确定要删除该供应商?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk:()=> {
                console.log(this.props.dataSource)
                for (let i = 0;i < this.props.dataSource.length;i++){
                    let item = this.props.dataSource[i];
                    if(item.uuid === uuid){
                        return this.props.onDeleteSupplier(uuid);
                    }
                }
            },
            onCancel() {console.log('Cancel');},
        });
    }
    handleCancel=()=>{
        const { form } = this.formRef.props;
        form.resetFields();
        this.setState({showSupplierModal:false});
    }
    showContent(record){
        let showItem = '';
        for (let i = 0;i < this.props.dataSource.length;i++){
            let item = this.props.dataSource[i];
            if (item.uuid === record.uuid){
                showItem = item;
            }
        }
        this.updateModalValues(showItem);
        this.setState({showSupplierModal:true,modalMode:2,modalTitle:'查看供应商'});
    }
    handleOnEditClick(record){
        for (let i = 0;i < this.props.dataSource.length;i++){
            let item = this.props.dataSource[i];
            if (item.uuid === record.uuid){
                this.updateModalValues(item);
                this.setState({showSupplierModal:true,modalMode:1,modalTitle:'修改供应商'});
                return ;
            }
        }   
    }
    handleAddOnClick(){
        this.setState({showSupplierModal:true,modalMode:0,modalTitle:'新增供应商',
                        modalValues:{uuid:Date.parse(new Date())/1000+(Math.round(Math.random() * (9000))+999),
                                    name:"",
                                    status:"",
                                    level:"",
                                    type:"",
                                    source:"",
                                    contact:"",
                                    tel:"",
                                    phone:"",
                                    email:"",
                                    address:"",
                                    creator:account2Name(localStorage.getItem("account")),
                                    manager:""}});
    }
    handleSubmit(){
        if(2 === this.state.modalMode){
            this.setState({ showSupplierModal: false });
            return;
        }
        const { form } = this.formRef.props;
        form.validateFields((err, fromValues) => {
            if (err) {return;}
            let manager_account = name2Account(fromValues.manager);
            let creator_account = name2Account(this.state.modalValues.creator);
            let currTime = new Date();
            let detail = {
                source:fromValues.source,
                contact:fromValues.contact,
                tel:fromValues.tel,
                phone:fromValues.phone,
                email:fromValues.email,
                address:fromValues.address,
                note:fromValues.note,
                file_url:fromValues.fileUrl,
                manager_account:manager_account,
            }
            let postData = {
                uuid:this.state.modalValues.uuid,
                enterprise_uuid:localStorage.getItem('enterprise_uuid'),
                name:fromValues.name,
                status:fromValues.status,
                type:fromValues.type,
                level:fromValues.level,
                detail:JSON.stringify(detail),
                create_time:Date.parse(currTime),
                creator_account:creator_account
            }
            if(this.state.modalMode){
                this.props.onUpdateSupplier(postData)
            }
            else{
                this.props.onAddSupplier(postData)
            }
            let modalValues = {
                uuid:postData['uuid'],
                name:fromValues.name,
                status:fromValues.status,
                type:fromValues.type,
                level:fromValues.level,
                detail:detail,
                createTime:Date.parse(currTime),
                creator:creator_account
            }
            this.updateModalValues(modalValues);
            form.resetFields();
            this.setState({ showSupplierModal: false });
        })
    }
    renderAction(record){
        if(this.props.editEnable){
            return (<Row>
                <Col span={8}><Button size='small' type="link" onClick={()=>this.showContent(record)}>详情</Button></Col>
                <Col span={8}><Button size='small' type="link" onClick={()=>this.handleOnEditClick(record)}>编辑</Button></Col>
                <Col span={8}><Button size='small' type="link" style={{color:'red'}} onClick={()=>this.handleDelete(record.uuid)}>删除</Button></Col>
            </Row>)
        }
        else{
            if(-1 !== record.manager.indexOf(localStorage.getItem('name'))){
                return (<Row>
                    <Col span={8}><Button size='small' type="link" onClick={()=>this.showContent(record)}>详情</Button></Col>
                    <Col span={8}><Button size='small' type="link" onClick={()=>this.handleOnEditClick(record)}>编辑</Button></Col>
                </Row>)
            }
            else{
                return (<Row>
                    <Col span={8}><Button size='small' type="link" onClick={()=>this.showContent(record)}>详情</Button></Col>
                </Row>)
            }
        }
    }
    OnChangeExportFormat=(e)=>{
        this.setState({exportType:e.target.value})
    }
    renderExportModal(){
        return (<Modal title="导出结果选项" visible={this.state.showExportModal} width={1000}
                                cancelText={"取消"} onCancel={()=>{this.setState({showExportModal:false})}}
                                okText={"导出"} onOk={()=>{this.OnClickExport()}} >
                        {/*<Checkbox.Group options={exportOptions}
                                        defaultValue={exportOptions} onChange={this.OnChangeColumn}/>*/}
                        <Radio.Group onChange={this.OnChangeExportFormat} defaultValue={'xlsx'}>
                            <Radio value={'xlsx'}>Excel(.xlsx)</Radio>
                            <Radio value={'xls'}>Excel(.xls)</Radio>
                            <Radio value={'csv'}>CSV</Radio>
                            <Radio value={'txt'}>文本(TXT)</Radio>
                        </Radio.Group>

                </Modal>);
    }
    renderSupplierForm(){
        return(<SupplierForm wrappedComponentRef={this.saveFormRef} visible={this.state.showSupplierModal}
            onCancel={this.handleCancel} onOk={this.handleSubmit} values={this.state.modalValues} 
            mode={this.state.modalMode} title={this.state.modalTitle}/>)
    }
    renderSupplierTable(){
        const supplierSource = []
        let showColumns = [{title: '供应商名称',dataIndex:'name',key: 'name',ellipsis:true,width:130,isSearch:true,render: (text, record) => 
                            <span style={{color:'#2F5BEA',cursor:'pointer'}}><CaretDownOutlined/>&nbsp;{text}</span>},
                        {title:'供应商类别',dataIndex:'type',key:'type', width:120,filterMultiple:true,filters:supplierSource,
                            sorter:(a, b) => StringCompareSort(a.type,b.type),
                            onFilter: (value, record) => record.level.indexOf(value) === 0,render:(text)=>(<span>{text}</span>)},
                        {title:'供应商等级',dataIndex:'level',key:'level', width:120,filterMultiple:true,filters:supplierSource,
                            sorter:(a, b) => StringCompareSort(a.level,b.level),
                            onFilter: (value, record) => record.level.indexOf(value) === 0,render:(text)=>(<span>{text}</span>)},
                        {title:'供应商来源',dataIndex:'source',key:'source', width:120,filterMultiple:true,filters:supplierSource,
                            sorter:(a, b) => StringCompareSort(a.detail.source,b.detail.source),
                            onFilter: (value, record) => record.source.indexOf(value) === 0,render:(text)=>(<span>{text}</span>)},
                        {title:'联系地址',dataIndex:'address',key:'address', width:120,filterMultiple:true,filters:supplierSource,
                            sorter:(a, b) => StringCompareSort(a.detail.address,b.detail.address),
                            onFilter: (value, record) => record.address.indexOf(value) === 0,render:(text)=>(<span>{text}</span>)},
                        {title:'联系电话',dataIndex:'phone',key:'phone', width:120,filterMultiple:true,filters:supplierSource,
                            sorter:(a, b) => StringCompareSort(a.detail.phone,b.detail.phone),
                            onFilter: (value, record) => record.phone.indexOf(value) === 0,render:(text)=>(<span>{text}</span>)},
                        {title:'负责人',dataIndex:'manager',key:'manager', width:120,filterMultiple:true,filters:supplierSource,
                            sorter:(a, b) => StringCompareSort(a.manager,b.manager),onFilter: (value, record) => record.level.indexOf(value) === 0,
                            render:(text, record) => <Row>{text?(<Tag key={text} color={"geekblue"}>{text}</Tag>):null}</Row>},
                        {title: '记录人',dataIndex:'creator',key:'creator',width:130,ellipsis:true,isSearch:true,sorter: (a, b) => a.creator.toString().localeCompare(b.creator.toString()),
                            render:(text, record) => <Row>{text?(<Tag key={text} color={"geekblue"}>{text}</Tag>):null}</Row>},
                        {title: '创建时间',dataIndex:'createTime',key:'createTime',width:130,ellipsis:true,isSearch:true,sorter: (a, b) => a.createTime.toString().localeCompare(b.createTime.toString())},
                        {title: '操作',dataIndex:'actions',key:'actions',width:150,fixed: 'right', render: (text, record) =>this.renderAction(record)}];

        let dataSource = [];
        for (let i = 0;i < this.props.dataSource.length;i++){
            let item = this.props.dataSource[i];
            dataSource.push({uuid:item.uuid,
                            name:item.name,
                            type:item.type,
                            level:item.level,
                            source:item.detail.source,
                            address:item.detail.address,
                            phone:item.detail.phone,
                            manager:account2Name(item.detail.manager_account),
                            createTime:item.create_time,
                            creator:account2Name(item.creator_account)})
        }
        return (<Row>
                {this.renderExportModal()}
                <ExtendTable handleAddOnClick={this.handleAddOnClick} batchDelEnable={false === this.props.batchDelEnable?false:true} 
                    onBatchDelete={this.handleEmptySelect} customColumnsEnable={this.props.customColumnsEnable} columns={showColumns} 
                    dataSource={dataSource} rowKey={record => record.uuid} tableKey={this.props.tableKey}
                    exportEnable={this.props.exportEnable} exportTitle={"导出表格"} exportClick={()=>this.setState({showExportModal:true})}/>
            </Row>)
    }
    render(){
        return (<div>
            <Row>{this.renderSupplierTable()}</Row>
            <Row>{this.renderSupplierForm()}</Row>
        </div>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(SupplierTable)