import React from 'react';

class Oem extends React.Component{
    constructor(props){
        super(props);
        this.state = {};
    }
    render(){
        return (<div>the user Oem Page</div>);
    }
}
export default Oem;