import {ActionType} from "../constants/actionTypes";

const productReducer = (state={},action)=>{
    switch (action.type) {
        case ActionType.GET_PRODUCT_BY_USER_START:
            return {...state, get_product: false};
        case ActionType.GET_PRODUCT_BY_USER_SUCCESS:
            return {...state, get_product:true, get_product_result:action.payload}
        case ActionType.GET_PRODUCT_BY_USER_FAILED:
            return {...state, get_product:false, get_product_errors:action.payload?action.payload:null}


        case ActionType.GET_PRODUCT_DEL_START:
            return {...state, get_product_del:false}
        case ActionType.GET_PRODUCT_DEL_SUCCESS:
            return {...state, get_product_del: true, get_product_del_result:action.payload}
        case ActionType.GET_PRODUCT_DEL_FAILED:
            return {...state, get_product_del:false, get_product_del_errors:action.payload?action.payload:null,}


        case ActionType.ADD_PRODUCT_START:
            return {...state, add_product:false}
        case ActionType.ADD_PRODUCT_FAILED:
            return {...state, add_product:false,add_product_errors:action.payload?action.payload:null}
        case ActionType.ADD_PRODUCT_SUCCESS:
            return {...state, add_product: true, add_product_result:action.payload}


        case ActionType.ADD_PRODUCT_DEL_START:
            return {...state, add_product_del:false,}
        case ActionType.ADD_PRODUCT_DEL_FAILED:
            return {...state, add_product_del:false,add_product_del_errors:action.payload?action.payload:null}
        case ActionType.ADD_PRODUCT_DEL_SUCCESS:
            return {...state, add_product_del:true, add_product_del_result:action.payload}


        case ActionType.UPDATE_PRODUCT_START:
            return {...state, update_product:false}
        case ActionType.UPDATE_PRODUCT_FAILED:
            return {...state, update_product:false,update_product_errors:action.payload?action.payload:null}
        case ActionType.UPDATE_PRODUCT_SUCCESS:
            return {...state, update_product: true,update_product_result:action.payload}


        case ActionType.DELETE_PRODUCT_START:
            return {...state, delete_product:false,}
        case ActionType.DELETE_PRODUCT_FAILED:
            return {...state, delete_product:false,delete_product_errors:action.payload?action.payload:null}
        case ActionType.DELETE_PRODUCT_SUCCESS:
            return {...state, delete_product:true, delete_product_result:action.payload}


        case ActionType.PRODUCT_RESTORE_START:
            return {...state, restore_product:false}
        case ActionType.PRODUCT_RESTORE_FAILED:
            return {...state, restore_product:false,restore_product_errors:action.payload?action.payload:null}
        case ActionType.PRODUCT_RESTORE_SUCCESS:
            return {...state, restore_product:true, restore_product_result:action.payload}


        default:
            return {...state};
    }
}
export default productReducer;