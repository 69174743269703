import {ActionType} from "../constants/actionTypes";

const performanceReducer = (state={},action)=>{
    switch(action.type){
        case ActionType.ADD_PERFORMANCE_GOAL_START:
            return {...state,add_performance_goal:false}
        case ActionType.ADD_PERFORMANCE_GOAL_SUCCESS:
            return {...state,add_performance_goal:true,add_performance_goal_result:action.payload}
        case ActionType.ADD_PERFORMANCE_GOAL_FAILED:
            return {...state,add_performance_goal:false,add_performance_goal_errors:action.payload?action.payload:null}

        case ActionType.GET_PERFORMANCE_GOAL_START:
            return {...state,get_performance_goal:false}
        case ActionType.GET_PERFORMANCE_GOAL_SUCCESS:
            return {...state,get_performance_goal:true,get_performance_goal_result:action.payload}
        case ActionType.GET_PERFORMANCE_GOAL_FAILED:
            return {...state,get_performance_goal:false,get_performance_goal_errors:action.payload?action.payload:null}
        
        case ActionType.UPDATE_PERFORMANCE_GOAL_START:
            return {...state,update_performance:false}
        case ActionType.UPDATE_PERFORMANCE_GOAL_SUCCESS:
            return {...state,update_performance:true,update_performance_result:action.payload}
        case ActionType.UPDATE_PERFORMANCE_GOAL_FAILED:
            return {...state,update_performance:false,update_performance_errors:action.payload}
        
        case ActionType.DELETE_PERFORMANCE_GOAL_START:
            return {...state,delete_performance:false}
        case ActionType.DELETE_PERFORMANCE_GOAL_SUCCESS:
            return {...state,delete_performance:true,delete_performance_result:action.payload}
        case ActionType.DELETE_PERFORMANCE_GOAL_FAILED:
            return {...state,delete_performance:false,delete_performance_errors:action.payload}
            
        default:
            return {...state};    
    }
}
export default performanceReducer;