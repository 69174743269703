import React from 'react';
import {Row} from 'antd';
class StatisticByType extends React.Component {
    constructor(props){
        super(props);
        this.state = {};
    }
    componentDidMount(){
    }
    render(){
                return (
                    <Row>
                    </Row>
                    );
    }
}
export default StatisticByType;