import React from 'react';
import {Row, Col,Form, Input, Button, Divider, Modal, Select, Radio, Checkbox,Tag} from 'antd';
import {PlusCircleOutlined,MinusCircleOutlined} from '@ant-design/icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import moment from "moment";
import {CaretDownOutlined} from '@ant-design/icons';
import {exportExcel} from './ExcelUtil';
import ExtendSelect from "./ExtendSelect";
import CustomDescription from "./CustomDescription";
import EditableTagGroup from "./EditableTagGroup";
import CustomRepeatConfigModal from "./CustomRepeatConfigModal";
import CityChoose from "./CityChoose";
import IndustryChoose from "./IndustryChoose";
import StaffChoose from "./StaffChoose";
import ExtendTable from "./ExtendTable";
import ProductChooseSelect from './ProductChooseSelect';
import {connect} from "react-redux";
import vCardsJS from 'vcards-js';
import FileSaver from 'file-saver';
import {addCustom, updateCustom,batchUpdateCustom} from "../../actions/customDBTool";
import {CUSTOM_TYPE_PERSONAL, customLevelList, customStatusList,customTypeList,customSourceList} from "../../constants/constant";
import {account2Name, name2Account} from "../../utils/accountUtils";
import { StringCompareSort } from '../../utils/common';
import FileManage from './FileManage';
import ExtendComment from './ExtendComment';

const {Option} = Select;
const {confirm} = Modal;
const exportOptions = ['序号','客户名称', '客户类别', '客户等级','客户来源','客户状态','城市','手机号码','座机','联系地址','负责人','参与人','录入人','录入时间'];
let customTypeOptions = customTypeList.map(item=><Option key={item} value={item}>{item}</Option>);

const CustomForm = Form.create({name:'custom_modal'})(
    class extends React.Component{
    constructor(props) {
        super(props);
        this.state = {customType:CUSTOM_TYPE_PERSONAL,};
        
    }
    shouldComponentUpdate(nextProps, nextState, nextContext){
        if(this.props.visible !== nextProps.visible){
            if(nextProps.mode){
                this.setState({customType:nextProps.values.type});
            }
            else{ 
                this.setState({customType:CUSTOM_TYPE_PERSONAL});
            }
        }
        return true;
    }
    
    onCustomType(value){this.setState({customType:value});}
    removeOtherInfo = k =>{
        if(1 < this.props.mode){return}
        const { form } = this.props;
        const keys = form.getFieldValue("other_info_keys");
        if (keys.length === 1) {return;}
        form.setFieldsValue({
            other_info_keys: keys.filter(key => key !== k)
        });
    }
    remove = k => {
        if(1 < this.props.mode){return}
        const { form } = this.props;
        const keys = form.getFieldValue("enterprise_contact_keys");
        // We need at least one passenger
        if (keys.length === 1) {return;}
        // can use data-binding to set
        form.setFieldsValue({enterprise_contact_keys: keys.filter(key => key !== k)});
    }
    addOtherInfo=()=>{
        if(1 < this.props.mode){return}
        const { form } = this.props;
        const keys = form.getFieldValue("other_info_keys");
        if(keys.length >= 4){return}
        const nextKeys = keys.concat(keys.length);
        form.setFieldsValue({other_info_keys: nextKeys});
    }
    add=()=>{
        if(1 < this.props.mode){return}
        const { form } = this.props;
        const keys = form.getFieldValue("enterprise_contact_keys");
        if(keys.length >= 4){return}
        const nextKeys = keys.concat(keys.length);
        form.setFieldsValue({enterprise_contact_keys: nextKeys});
    }
    renderPersonalDynamic(values,mode){
        const { form } = this.props;
        let defaultValue = [];
        let { getFieldDecorator } = form;
        if(values.other_info_name){
            for (let i = 0;i < values.other_info_name.length;i++){
                defaultValue.push(i);
            }
        }
        else{
            defaultValue.push(0);
        }
        getFieldDecorator("other_info_keys", { initialValue: mode?defaultValue:[0]});
        const keys = form.getFieldValue("other_info_keys");
        let formItemLayout = {wrapperCol: {span:24}};
        return keys.map((key, index) =>
        <Row key={key}>
            <Col span={6} offset={3}>
                <Form.Item {...formItemLayout}>
                    {getFieldDecorator(`other_info_name[${index}]`,{validateTrigger: ["onChange", "onBlur"],
                        initialValue:mode&&values.other_info_name?values.other_info_name[index]:'自定义类别'})
                    (<ExtendSelect style={{width:'100%'}} placeholder="请输入自定义的信息类别" items={[]} title={"自定义类别"} disabled={1 < mode?true:false}/>)}
                </Form.Item>
            </Col>
            <Col span={15}>
                <Form.Item {...formItemLayout}>
                    {getFieldDecorator(`other_info_content[${index}]`, {validateTrigger: ["onChange", "onBlur"],
                        initialValue:mode&&values.other_info_content?values.other_info_content[index]:''})
                        (<Input placeholder="输入自定义类别的内容"  disabled={1 < mode?true:false} 
                            addonAfter={index === 0?(<PlusCircleOutlined style={{color:'blue'}} onClick={() => this.addOtherInfo()}/>):
                                                    (<MinusCircleOutlined style={{color:'red'}} onClick={() => this.removeOtherInfo(key)}/>)}/>)}
                    
                </Form.Item>
            </Col>
        </Row>
        );
    }
    renderDynamicItem(values,mode){
        const { form } = this.props;
        let defaultValue = [];
        if(values.enterprise_contact_name){
            for (let i = 0;i < values.enterprise_contact_name.length;i++){
                defaultValue.push(i);
            }
        }
        else{defaultValue.push(0);}
        let { getFieldDecorator } = form;
        getFieldDecorator("enterprise_contact_keys", { initialValue: mode?defaultValue:[0]});
        const keys = form.getFieldValue("enterprise_contact_keys");
        let formItemLayout = {labelCol: {span:8}, wrapperCol: {span:16}};
        return keys.map((key, index) =>
            <Row key={key}>
                <Col span={10}>
                    <Form.Item {...formItemLayout} label={<span style={{fontsize:'24px'}}>联系人&nbsp;&nbsp;姓名</span>} key={key}>
                        {getFieldDecorator(`enterprise_contact_name[${index}]`,
                        {validateTrigger: ["onChange", "onBlur"],rules: [{required: false, message: "请输入新增的联系方式或者删除该行!"}],
                                initialValue:mode&&values.enterprise_contact_name?values.enterprise_contact_name[index]:''})
                        (<Input placeholder="输入联系人的名称" disabled={1 < mode?true:false}/>)}
                    </Form.Item>
                </Col>
                <Col span={14}>
                    <Form.Item {...formItemLayout} label={"电话"} key={key}>
                        {getFieldDecorator(`enterprise_contact_phone[${index}]`, {validateTrigger: ["onChange", "onBlur"],
                            rules: [{required: false, message: "请输入新增的联系方式或者删除该行!"}],
                                initialValue:mode&&values.enterprise_contact_phone?values.enterprise_contact_phone[index]:''})
                        (<Input placeholder="输入联系电话/手机号码" style={{width:'70%',marginRight: 5}} disabled={1 < mode?true:false}/>)}
                        {index === 0?(<PlusCircleOutlined style={{color:'blue'}} onClick={() => this.add()}/>):
                            (<MinusCircleOutlined style={{color:'red'}} onClick={() => this.remove(key)}/>)}
                    </Form.Item>
                </Col>
            </Row>);
    }
    
    renderPersonalCustom(values,mode){
        const { form } = this.props;
        let { getFieldDecorator } = form;
        console.log(values.file_url);
        return (<div>
                    <Row style={{background:'#F0F0F0'}}>其他信息</Row>
                    <Row>
                        <Col span={10}>
                            <Form.Item label={"名称"} required={true}>
                                {getFieldDecorator('name',{rules:[{ required: true,message:'客户名称不能为空!'}],initialValue:mode?values.name:""})
                                (<Input placeholder={"请输入客户的名称"} disabled={1 < mode?true:false}/>)}
                            </Form.Item>
                            <Form.Item label={"行业"}>
                                {getFieldDecorator('industry',{rules:[{required:false}],initialValue:mode?values.industry:''})
                                (<IndustryChoose disabled={1 < mode?true:false}/>)}
                            </Form.Item>
                            <Form.Item label={"城市"}>
                                {getFieldDecorator('city',{rules:[{required:false}],initialValue:mode?values.city:''})
                                (<CityChoose disabled={1 < mode?true:false}/>)}
                            </Form.Item>
                            <Form.Item label={"联系地址"}>
                                {getFieldDecorator('address', {rules:[{required:false}],initialValue:mode?values.address:''})
                                (<Input placeholder={"请输入客户地址"} disabled={1 < mode?true:false}/>)}
                            </Form.Item>
                            <Form.Item label={"手机"}>
                                {getFieldDecorator('phone', {rules:[{required:false},{pattern: new RegExp(/(1[3|4|5|6|7|8|9][\d]{9})/,"g"),message:'请输入正确的手机格式'}],
                                    initialValue:mode?values.phone:''})
                                (<Input placeholder={"输入客户手机号,多个用'/'隔开"} disabled={1 < mode?true:false}/>)}
                            </Form.Item>
                            <Form.Item label={"座机"}>
                                {getFieldDecorator('tel', {rules:[{required:false},
                                {pattern:new RegExp(/[\d]{7,8}|(0[\d]{2,3}[-_－—]?[\d]{7,8}|400[-_－—]?[\d]{3}[-_－—]?[\d]{4}|\(0[\d]{2,3}\)[-_－—]?[\d]{7,8}|\(400\)[-_－—]?[\d]{3}[-_－—]?[\d]{4})/g),message:"请输入正确的电话格式"}],
                                    initialValue:mode?values.tel:''})
                                (<Input placeholder={"输入客户座机，多个用'/'隔开"} disabled={1 < mode?true:false}/>)}
                            </Form.Item>
                            <Row>{this.renderPersonalDynamic(values,mode)}</Row>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={"邮箱"}>
                                {getFieldDecorator('email',{rules:[{type:'email',message: "请输入正确的邮箱格式"}],
                                    initialValue:mode?values.email:''})
                                (<Input placeholder={"输入客户邮箱，多个用'/'隔开"} disabled={1 < mode?true:false}/>)}
                            </Form.Item>
                            <Form.Item label={"微信"}>
                                {getFieldDecorator('wechat',{rules:[{required:false}],initialValue:mode?values.wechat:''})
                                (<Input type="text" placeholder={"输入客户微信号，多个用'/'隔开"} disabled={1 < mode?true:false}/>)}
                            </Form.Item>
                            <Form.Item label={"QQ"}>
                                {getFieldDecorator('QQ',{rules:[{required:false},{pattern: new RegExp(/^[0-9]\d*$/, "g"),message:"请输入正确的QQ"}], 
                                initialValue:mode?values.QQ:''})
                                (<Input type="text" placeholder={"输入客户QQ号，多个用'/'隔开"} disabled={1 < mode?true:false}/>)}
                            </Form.Item>
                            <Form.Item label={"特别备注"}>
                                {getFieldDecorator('note', {rules: [{ required: false}],initialValue:mode?values.note:""})
                                    (<ReactQuill placeholder={"请输入客户的特别备注"} readOnly={1 < mode?true:false}/>)}
                            </Form.Item>
                            <Form.Item label={"标签"}>
                                {getFieldDecorator('tags', {rules: [{ required: false}],initialValue:mode?values.tags:""})
                                    (<EditableTagGroup disabled={1 < mode?true:false}/>)}
                            </Form.Item>
                            <Form.Item label={"相关文档"}>
                                {getFieldDecorator('fileUrl', {initialValue:mode?values.file_url:[]})
                                    (<FileManage filePrefix={"custom/" + values.uuid+"/"} fileList={values.file_url} disabled={1 < mode?true:false}/>)}
                            </Form.Item>
                        </Col>
                    </Row>
                </div>);
    }
    renderEnterpriseCustom(values,mode){
        const { form } = this.props;
        let { getFieldDecorator } = form;
        return ( <div>
                    <Row style={{background:'#F0F0F0'}}>企业信息</Row>
                    <Row>
                        <Col span={10}>
                            <Form.Item label={"公司名称"} required={true}>
                                {getFieldDecorator('name',{rules:[{ required: true,message:'公司名称不能为空!'}],
                                    initialValue:mode?values.name:""})
                                (<Input placeholder={"请输入客户公司的名称"} disabled={1 < mode?true:false}/>)}
                            </Form.Item>
                            <Form.Item label={"行业"}>
                                {getFieldDecorator('industry',{initialValue:mode?values.industry:''})
                                (<IndustryChoose disabled={1 < mode?true:false}/>)}
                            </Form.Item>
                            <Form.Item label={"城市"}>
                                {getFieldDecorator('city',{initialValue:mode?values.city:''})
                                (<CityChoose disabled={1 < mode?true:false}/>)}
                            </Form.Item>
                            <Form.Item label={"公司地址"}>
                                {getFieldDecorator('address', {initialValue:mode?values.address:''})
                                (<Input placeholder={"请输入客户地址"} disabled={1 < mode?true:false}/>)}
                            </Form.Item>
                            <Form.Item label={"公司邮箱"}>
                                {getFieldDecorator('email', {rules:[{type:'email',message: "请输入正确的邮箱格式"}],initialValue:mode?values.email:''})
                                (<Input placeholder={"输入客户邮箱，多个用'/'隔开"} disabled={1 < mode?true:false}/>)}
                            </Form.Item>
                            <Form.Item label={"公司座机"}>
                                {getFieldDecorator('tel', {rules:[{pattern:new RegExp(/[\d]{7,8}|(0[\d]{2,3}[-_－—]?[\d]{7,8}|400[-_－—]?[\d]{3}[-_－—]?[\d]{4}|\(0[\d]{2,3}\)[-_－—]?[\d]{7,8}|\(400\)[-_－—]?[\d]{3}[-_－—]?[\d]{4})/g),message:"请输入正确的电话格式"}],
                                    initialValue:mode?values.tel:''})
                                (<Input placeholder={"输入客户座机，多个用'/'隔开"} disabled={1 < mode?true:false}/>)}
                            </Form.Item>
                            <Form.Item label={"公司手机"}>
                                {getFieldDecorator('phone', {rules:[{pattern: new RegExp(/(1[3|4|5|6|7|8|9][\d]{9})/,"g"),message:'请输入正确的手机格式'}],
                                    initialValue:mode?values.phone:''})
                                (<Input placeholder={"输入公司手机号码，多个用'/'隔开"} disabled={1 < mode?true:false}/>)}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={"公司QQ"}>
                                {getFieldDecorator('QQ', {rules:[{pattern: new RegExp(/^[0-9]\d*$/, "g"),message:"请输入正确的QQ"}],
                                    initialValue:mode?values.QQ:''})
                                (<Input placeholder={"输入客户QQ号，多个用'/'隔开"} disabled={1 < mode?true:false}/>)}
                            </Form.Item>
                            <Form.Item label={"钉钉"}>
                                {getFieldDecorator('dingding', {rules:[{pattern: new RegExp(/^[0-9]\d*$/, "g"),message:"请输入正确的钉钉号"}],
                                    initialValue:mode?values.dingding:''})
                                (<Input placeholder={"输入客户的钉钉号"} disabled={1 < mode?true:false}/>)}
                            </Form.Item>
                            <Form.Item label={"企业微信"}>
                                {getFieldDecorator('enterprise_wechat', {initialValue:mode?values.enterprise_wechat:''})
                                (<Input placeholder={"输入客户的企业微信，多个用'/'隔开"} disabled={1 < mode?true:false}/>)}
                            </Form.Item>
                            <Form.Item label={"特别备注"}>
                                {getFieldDecorator('note', {initialValue:mode?values.note:""})
                                (<ReactQuill placeholder={"请输入客户的特别备注"} readOnly={1 < mode?true:false}/>)}
                            </Form.Item>
                            <Form.Item label={"标签"}>
                                {getFieldDecorator('tags', {initialValue:mode?values.tags:""})
                                    (<EditableTagGroup disabled={1 < mode?true:false}/>)}
                            </Form.Item>
                            <Form.Item label={"相关文档"}>
                                {getFieldDecorator('fileUrl', {initialValue:mode?values.file_url:[]})
                                    (<FileManage filePrefix="custom/" fileList={values.file_url} disabled={1 < mode?true:false}/>)}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{background:'#F0F0F0'}}>企业联系人</Row>
                    {this.renderDynamicItem(values,mode)}
                </div>)
    }
    render() {
        let { visible, onCancel, onOk, form, values,title,mode } = this.props;
        let { getFieldDecorator } = form;
        let formItemLayout = {labelCol: {span:6}, wrapperCol: {span:18}};
        return (<Modal title={title} visible={visible} width={1200} maskClosable={false}
                    onOk={onOk} okText={2===mode?"关闭":"确定"} onCancel={onCancel} cancelText={"取消"}>
                    <Form {...formItemLayout}>
                        <Row style={{background:'#F0F0F0'}}>基本信息</Row>
                        <Row>
                            <Col span={10}>
                                <Form.Item label={"客户类别"}>
                                    {getFieldDecorator('type', {initialValue:this.state.customType})
                                    (<Select style={{width:'90%'}} onChange={(value)=>{this.onCustomType(value)}} disabled={1 < mode?true:false}>
                                        {customTypeOptions}
                                    </Select>)}
                                </Form.Item>
                                <Form.Item label={"客户来源"}>
                                    {getFieldDecorator('source', {rules: [{ required: false}],initialValue:mode?values.source:''})
                                    (<ExtendSelect items={customSourceList} disabled={1 < mode?true:false}/>)}
                                </Form.Item>
                                <Form.Item label={"客户状态"}>
                                    {getFieldDecorator('status',{initialValue:mode?values.status:''})
                                    (<ExtendSelect items={customStatusList} title={"客户状态"} placeholder={"请输入自定义客户状态"} disabled={2 === mode?true:false}/>)}
                                </Form.Item>
                                <Form.Item label={"客户等级"}>
                                    {getFieldDecorator('level',{rules:[{required:false,message:'客户等级不能为空'}],initialValue:mode?values.level:''})
                                    (<ExtendSelect items={customLevelList} title={"客户等级"} placeholder={"请输入自定义客户等级"} disabled={2 === mode?true:false}/>)}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={"意向产品"}>
                                    {getFieldDecorator('intentProduct',{rules:[{required:false}],initialValue:mode?values.intent_product:''})
                                    (<ProductChooseSelect disabled={1 < mode?true:false}/>)}
                                </Form.Item>
                                <Form.Item label={"负责人"}>
                                    {getFieldDecorator('manager', {initialValue:mode?values.manager:''})
                                    (<StaffChoose mode={0} commonEnable={3 === mode?true:false}disabled={1 < mode?true:false}/>)}
                                    {<span style={{color:'red'}}>(不选择任何跟进成员员，即为公海客户)</span>}
                                </Form.Item>
                                <Form.Item label={"参与人"}>
                                    {getFieldDecorator('follower', {initialValue:mode?values.follower:''})
                                    (<StaffChoose mode={1} disabled={1 < mode?true:false}/>)}
                                </Form.Item>
                            </Col>
                        </Row>
                        {this.state.customType === CUSTOM_TYPE_PERSONAL?this.renderPersonalCustom(values,mode):this.renderEnterpriseCustom(values,mode)}
                        <Row>
                            <Col span={16} offset={2}>
                                <Form.Item label="评论" hidden={0 === mode}>
                                    {getFieldDecorator('comment', {rules: [{ required: false}],initialValue:values.comments?values.comments:""})
                                    (<ExtendComment/>)}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>);
        }
})

const BatchEditCustomFrom = Form.create({name:'batch_edit_modal'})(
    class extends React.Component{
    constructor(props) {
        super(props);
        this.state = {  modifyItems:['客户类别','客户来源','客户状态','客户等级','意向产品','行业','所在城市','电话','手机','负责人'],
                        modifyWays:['内容替换','清空'],
                        selectModifyItem:'客户类别',
                        selectModifyWay:'内容替换'
                    };
    }
    componentDidMount(){
        this.setState({selectModifyItem:'客户类别',selectModifyWay:'内容替换'});
    }
    modifyItemOnChange(value){
        this.setState({selectModifyItem:value});
        if("手机" === value||"电话" === value){
            this.setState({modifyWays:['内容替换','添加前缀','添加后缀','清空']})
        }
    }
    modifyWayOnChange(value){
        this.setState({selectModifyWay:value});
    }
    renderModifyContent(){
        const { form } = this.props;
        let { getFieldDecorator } = form;
        let formItemLayout = {labelCol: {span:6}, wrapperCol: {span:18}};
        if("清空" === this.state.selectModifyWay){
            return (<Form.Item label={"修改内容"} {...formItemLayout}>
                        {getFieldDecorator('modifyContent', {rules: [{ required: false}],initialValue:''})
                            (<Input disabled style={{width:'80%'}} placeholder={"清空"}/>)}
                    </Form.Item>);
        }
        switch(this.state.selectModifyItem){
            case "客户类别":
                return (<Form.Item label={"修改内容"} {...formItemLayout}>
                            {getFieldDecorator('modifyContent', {rules: [{ required: false,message:'客户类别不能为空'}],initialValue:''})
                            (<Select style={{width:'80%'}}>{customTypeOptions}</Select>)}
                        </Form.Item>)
            case "客户来源":
                return (<Form.Item label={"修改内容"} {...formItemLayout}>
                            {getFieldDecorator('modifyContent', {rules: [{ required: false}],initialValue:''})
                            (<ExtendSelect items={customSourceList}/>)}
                        </Form.Item>);
            case "客户状态":
                return (<Form.Item label={"修改内容"} {...formItemLayout}>
                            {getFieldDecorator('modifyContent', {rules: [{ required: false}],initialValue:''})
                            (<ExtendSelect items={customStatusList}/>)}
                        </Form.Item>);
            case "客户等级":
                return (<Form.Item label={"修改内容"} {...formItemLayout}>
                            {getFieldDecorator('modifyContent', {rules: [{ required: false}],initialValue:''})
                            (<ExtendSelect items={customLevelList}/>)}
                        </Form.Item>);
            case "意向产品":
                return (<Form.Item label={"修改内容"} {...formItemLayout}>
                            {getFieldDecorator('modifyContent', {rules: [{ required: false}],initialValue:''})
                            (<ProductChooseSelect/>)}
                        </Form.Item>);
            case '行业':
                return (<Form.Item label={"修改内容"} {...formItemLayout}>
                            {getFieldDecorator('modifyContent', {rules: [{ required: false}],initialValue:''})
                            (<IndustryChoose/>)}
                        </Form.Item>);
            case '所在城市':
                return (<Form.Item label={"修改内容"} {...formItemLayout}>
                            {getFieldDecorator('modifyContent', {rules: [{ required: false}],initialValue:''})
                            (<CityChoose/>)}
                        </Form.Item>);
            case '负责人':
                return (<Form.Item label={"修改内容"} {...formItemLayout}>
                            {getFieldDecorator('modifyContent', {rules: [{ required: false}],initialValue:''})
                            (<StaffChoose mode={0}/>)}
                            {<span style={{color:'red'}}>(不选择任何跟进成员员，即为公海客户)</span>}
                        </Form.Item>);
            case '电话':
                if("添加前缀" === this.state.selectModifyWay||"添加后缀" === this.state.selectModifyWay){
                    return (<Form.Item label={"修改内容"}>
                            {getFieldDecorator('modifyContent', {rules:[{required:false}],initialValue:''})
                            (<Input placeholder={"添加前缀或者后缀内容"} style={{width:'80%'}}/>)}
                        </Form.Item>);
                }
                else{
                    return (<Form.Item label={"修改内容"}>
                            {getFieldDecorator('modifyContent', {rules:[{required:false},
                            {pattern:new RegExp(/[\d]{7,8}|(0[\d]{2,3}[-_－—]?[\d]{7,8}|400[-_－—]?[\d]{3}[-_－—]?[\d]{4}|\(0[\d]{2,3}\)[-_－—]?[\d]{7,8}|\(400\)[-_－—]?[\d]{3}[-_－—]?[\d]{4})/g),message:"请输入正确的电话格式"}],
                                initialValue:''})
                            (<Input placeholder={"输入客户座机，多个用'/'隔开"} style={{width:'80%'}}/>)}
                        </Form.Item>);
                }
            case '手机':
                if("添加前缀" === this.state.selectModifyWay||"添加后缀" === this.state.selectModifyWay){
                    return (<Form.Item label={"修改内容"}>
                            {getFieldDecorator('modifyContent', {rules:[{required:false}],initialValue:''})
                            (<Input placeholder={"添加前缀或者后缀内容"} style={{width:'80%'}}/>)}
                        </Form.Item>);
                }
                else{
                    return (<Form.Item label={"修改内容"}>
                            {getFieldDecorator('modifyContent', {rules:[{required:false},
                            {pattern: new RegExp(/(1[3|4|5|6|7|8|9][\d]{9})/,"g"),message:'请输入正确的手机格式'}],
                                initialValue:''})
                            (<Input placeholder={"输入客户手机号,多个用'/'隔开"} style={{width:'80%'}}/>)}
                        </Form.Item>);
                }
            default:
                return (<Form.Item label={"修改内容"} {...formItemLayout}>
                            {getFieldDecorator('modifyContent', {rules: [{ required: false}],initialValue:''})
                                (<Input style={{width:'80%'}}/>)}
                        </Form.Item>);
        }
    }
    render(){
        let { visible, onCancel, onOk, form} = this.props;
        let { getFieldDecorator } = form;
        let formItemLayout = {labelCol: {span:6}, wrapperCol: {span:18}};
        const modifyItemsOptions = this.state.modifyItems.map(item=><Option key={item} value={item}>{item}</Option>);
        const modifyWaysOptions = this.state.modifyWays.map(item=><Option key={item} value={item}>{item}</Option>);
        return (<Modal title="批量编辑客户" visible={visible} width={600} cancelText={"取消"} 
                    onCancel={onCancel} okText={"确定"} onOk={onOk}>
                    <Row style={{background:'#F0F0F0'}}>批量编辑修改选择的项 [共<span style={{color:'red'}}>1</span>个]</Row>
                    <Form {...formItemLayout}>
                        <Form.Item label={"修改项"}>
                            {getFieldDecorator('modifyItem', {rules: [{ required: false}],initialValue:this.state.modifyItems[0]})
                                (<Select style={{width:'80%'}} onChange={(value)=>{this.modifyItemOnChange(value)}}>{modifyItemsOptions}</Select>)}
                        </Form.Item>
                        <Form.Item label={"修改方式"}>
                            {getFieldDecorator('modifyWay', {rules: [{ required: false}],initialValue:this.state.modifyWays[0]})
                                (<Select style={{width:'80%'}} onChange={(value)=>{this.modifyWayOnChange(value)}}>{modifyWaysOptions}</Select>)}
                        </Form.Item>
                        {this.renderModifyContent()}
                    </Form>
                </Modal>)
    }
})

function mapStateToProps(state) {
    return {...state.custom};
}
function mapDispatchToProps(dispatch) {
    return {OnBatchDeleteCustom:(uuid_list)=>dispatch(batchUpdateCustom({uuid_list:uuid_list,isTrash:true})),
            onAddCustom:(postData)=>dispatch(addCustom(postData)),
            onEditCustom:(postData)=>dispatch(updateCustom(postData)),
            onBatchUpdateCustom:(postDataList)=>dispatch(batchUpdateCustom(postDataList))
        };
}
class CustomTable extends React.Component{
    constructor(props){
        super(props);
        this.state={showCustomModal:false,
                    showExportModal:false,
                    showBatchEditModal:false,
                    showAddFollowUp:false,
                    showDescription:false,
                    showExtendModal:false,
                    exportType:'xlsx',
                    exportColumns:exportOptions,
                    vcfSize:'0',
                    customType:[],
                    selectCustom:{},
                    modalMode:0,
                    modalTitle:'新增客户',
                    customValues:{uuid:Date.parse(new Date())/1000+(Math.round(Math.random() * (9000))+999)}
                }
        this.showDeleteConfirm = this.showDeleteConfirm.bind(this);
        this.showDescription = this.showDescription.bind(this);
        this.updateModalValues = this.updateModalValues.bind(this);
        this.handleOnEditClick = this.handleOnEditClick.bind(this);
        this.handleSaveTags = this.handleSaveTags.bind(this);
        this.handleCustomSubmit = this.handleCustomSubmit.bind(this);
        this.batchOnEditClick = this.batchOnEditClick.bind(this);
        this.handleBatchEditSubmit = this.handleBatchEditSubmit.bind(this);
        this.OnClickExport = this.OnClickExport.bind(this);
    }
    componentDidMount(){
        customTypeList.forEach(item=>{
            this.state.customType.push({text:item,value:item})
        });
    }
    saveFormRef = formRef =>{this.formRef = formRef;}
    saveBatchEditFormRef = batchEditFormRef =>{this.batchEditFormRef = batchEditFormRef;}
    updateModalValues(customData){
        let detail = customData.detail;
        let values = {
            uuid:customData.uuid,
            name:customData.name,
            type:customData.type,
            source:detail.source,
            level:customData.level,
            status:customData.status,
            industry:detail.industry,
            intent_product:detail.intent_product,
            address:detail.address,
            city:detail.city,
            phone:detail.phone,
            tel:detail.tel,
            email:detail.email,
            wechat:detail.wechat,
            QQ:detail.QQ,
            note:detail.note,
            comments:detail.comments?detail.comments:'',
            file_url:detail.file_url?detail.file_url:[],
            tags:detail.tags,
            dingding:detail.dingding,
            enterprise_contact_name:detail.enterprise_contact_name,
            enterprise_contact_phone:detail.enterprise_contact_phone,
            enterprise_wechat:detail.enterprise_wechat,
            other_info_name:detail.other_info_name,
            other_info_content:detail.other_info_content,
            manager:account2Name(detail.manager_account),
            follower:account2Name(detail.follower_account),
            createTime:customData.create_time,
            detail:detail
        };
        this.setState({customValues:values});
    }
    handleShowClick(record){
        for(let i = 0;i < this.props.dataSource.length;i++){
            let item = this.props.dataSource[i];
            if(item.uuid === record.uuid){
                this.updateModalValues(item);
                this.setState({showCustomModal:true,modalMode:2,modalTitle:'查看客户'});
                return ;
            }
        }
    }
    handleOnEditClick(record){
        for(let i = 0;i < this.props.dataSource.length;i++){
            let item = this.props.dataSource[i];
            if(item.uuid === record.uuid){
                this.updateModalValues(item);
                if(this.props.editEnable){
                    this.setState({showCustomModal:true,modalMode:1,modalTitle:'修改客户'});
                }
                else{
                    this.setState({showCustomModal:true,modalMode:3,modalTitle:'修改客户'});
                }
                return ;
            }
        }
    }
    handleAddOnClick=()=>{
        this.setState({showCustomModal:true, modalMode:0,modalTitle:'新增客户',customValues:{uuid:Date.parse(new Date())/1000+(Math.round(Math.random() * (9000))+999)}});
    }
    handleCancel=()=>{
        const { form } = this.formRef.props;
        form.resetFields();
        this.setState({showCustomModal:false});
    }
    batchDelete=(uuid_list)=>{
        this.props.OnBatchDeleteCustom(JSON.stringify(uuid_list));
    }
    handleSaveTags(record,tags){
        for(let i = 0;i < this.props.dataSource.length;i++){
            let item = this.props.dataSource[i];
            if(item.uuid === record.uuid){
                item.detail['tags']=tags;
                this.props.onEditCustom(item)
            }
        }
    }
    batchOnEditClick(uuid_list){
        this.setState({showBatchEditModal:true,modifyItems:uuid_list});
    }
    handleBatchEditSubmit(){
        const { form } = this.batchEditFormRef.props;
        form.validateFields((err, fromValues) => {
            if (err) {return;}
            if(2 === this.state.modalMode){
                this.setState({ showCustomModal: false });
                return;
            }
            let postDataList = [];
            this.props.dataSource.forEach((item)=>{
                if(-1 !== this.state.modifyItems.indexOf(item.uuid)){
                    switch(fromValues.modifyItem){
                        case "客户类别":
                            item.type = fromValues.modifyContent;
                            break;
                        case "客户来源":
                            item.detail.source = fromValues.modifyContent;
                            break
                        case "客户状态":
                            item.status = fromValues.modifyContent;
                            break;
                        case "客户等级":
                            item.level = fromValues.modifyContent;
                            break;
                        case "意向产品":
                            item.detail.intent_product = fromValues.modifyContent;
                            break;
                        case '行业':
                            item.detail.industry = fromValues.modifyContent;
                            break;
                        case '所在城市':
                            item.detail.city = fromValues.modifyContent;
                            break;
                        case '电话':
                            if('添加前缀' === fromValues.modifyWay){
                                item.detail.tel = fromValues.modifyContent + item.detail.tel;
                            }
                            else if('添加后缀' === fromValues.modifyWay){
                                item.detail.tel = item.detail.tel + fromValues.modifyContent;
                            }
                            else{
                                item.detail.tel = fromValues.modifyContent;
                            }
                            break;
                        case '手机':
                            if('添加前缀' === fromValues.modifyWay){
                                item.detail.phone = fromValues.modifyContent + item.detail.phone;
                            }
                            else if('添加后缀' === fromValues.modifyWay){
                                item.detail.phone = item.detail.phone + fromValues.modifyContent;
                            }
                            else{
                                item.detail.phone = fromValues.modifyContent;
                            }
                            break;
                        case '负责人':
                            item.detail.manager_account = fromValues.modifyContent.length?name2Account(fromValues.modifyContent):"公海客户";
                            break;
                        case '参与人':
                            item.detail.follower_account = fromValues.modifyContent.length?name2Account(fromValues.modifyContent):"";
                            break;
                        default: break;
                    }
                    postDataList.push(item);
                }
            })
            this.props.onBatchUpdateCustom({custom_list:JSON.stringify(postDataList)});
            this.setState({ showBatchEditModal: false});
            form.resetFields();
        })
    }
    handleCustomSubmit(){
        const { form } = this.formRef.props;
        form.validateFields((err, fromValues) => {
            if (err) {return;}
            let manager_account = name2Account(fromValues.manager);
            let follower_account = name2Account(fromValues.follower);
            if(0 >= fromValues.manager.length){
                manager_account = "公海客户";
            }
            let detail = this.state.customValues.detail?this.state.customValues.detail:{};
            detail['city'] = fromValues.city;
            detail['source']=fromValues.source;
            detail['address']=fromValues.address;
            detail['phone']=fromValues.phone;
            detail['tel']=fromValues.tel;
            detail['email']=fromValues.email;
            detail['wechat']=fromValues.wechat;
            detail['dingding']=fromValues.dingding;
            detail['QQ']=fromValues.QQ;
            detail['industry']=fromValues.industry;
            detail['intent_product']=fromValues.intentProduct;
            detail['other_info_name']=fromValues.other_info_name;
            detail['other_info_content']=fromValues.other_info_content;
            detail['enterprise_contact_name']= fromValues.enterprise_contact_name;
            detail['enterprise_contact_phone']=fromValues.enterprise_contact_phone;
            detail['enterprise_wechat']=fromValues.enterprise_wechat;
            detail['note']=fromValues.note;
            detail['tags']=fromValues.tags;
            detail['manager_account']=manager_account;
            detail['follower_account']=follower_account;
            detail['file_url'] = fromValues.fileUrl;
            detail['comments'] = fromValues.comment;
            let currTime = new Date();
            let postData = {
                uuid:this.state.customValues.uuid,
                enterprise_uuid:localStorage.getItem('enterprise_uuid'),
                name:fromValues.name,
                type:fromValues.type,
                level:fromValues.level,
                status:fromValues.status,
                detail:JSON.stringify(detail),
                update_time:Date.parse(currTime),
                create_time:Date.parse(currTime),
                creator_account:localStorage.getItem('account')
            };
            if(this.state.modalMode){//update custom
                this.props.onEditCustom(postData);
            }
            else{
                this.props.onAddCustom(postData);
            }
            postData['detail'] = detail;
            let modalValues = {
                uuid:postData['uuid'],
                name:fromValues.name,
                type:fromValues.type,
                level:fromValues.level,
                status:fromValues.status,
                detail:detail,
                creator_account:postData['creator_account'],
                create_time:Date.parse(currTime)
            }
            this.updateModalValues(modalValues);
            this.setState({ showCustomModal: false,modalMode:1 });
            form.resetFields();
        });
    }
    showDeleteConfirm = (uuid)=> {
        confirm({
            title: '重要提示',
            content: '您确定要删除该客户?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk:()=> {this.props.OnBatchDeleteCustom(JSON.stringify([uuid]));},
            onCancel() {console.log('Cancel');},
        });
    }
    OnChangeColumn=(checkedValues)=>{
        console.log(checkedValues);
        this.setState({exportColumns:checkedValues});
    }
    OnChangeExportFormat=(e)=>{
        this.setState({exportType:e.target.value})
    }
    OnChangeVcfSize=(e)=>{
        this.setState({vcfSize:e.target.value});
    }
    ExportVcf=(dataSource)=>{
        console.log(dataSource);
        let vCard = vCardsJS();
        let contactStr = "";
        let fileIndex = 1;
        for (let i = 0;i < dataSource.length;i++){
            vCard.firstName = dataSource[i].name;
            vCard.workPhone = dataSource[i].tel;
            vCard.cellPhone = dataSource[i].phone;
            vCard.workAddress = dataSource[i].address;
            contactStr+=(vCard.getFormattedString());
            if((0 !== this.state.vcfSize && 0 === i%this.state.vcfSize && 0 < i)||dataSource.length - 1 === i){
                let blob = new Blob([contactStr], {type: "text/plain;charset=utf-8"});
                FileSaver.saveAs(blob,"记录表"+fileIndex+".vcf");
                contactStr = "";
                fileIndex++;
            }
        }
        if(0 === this.state.vcfSize){
            let blob = new Blob([contactStr], {type: "text/plain;charset=utf-8"});
            FileSaver.saveAs(blob,"记录表.vcf");
        }
    }
    OnClickExport(dataSource){
        for (let i = 0;i < dataSource.length;i++){
            for (let key in dataSource[i]){
                if (-1 === this.state.exportColumns.indexOf(key)){
                    delete dataSource[i][key];
                    delete dataSource[i].key;
                }
            }
        }
        exportExcel(dataSource,this.state.exportType,'客户记录表');
    }
    showDescription(record){
        for (let i = 0;i < this.props.dataSource.length;i++){
            let item = this.props.dataSource[i];
            if(item.uuid === record.uuid){
                this.setState({showDescription:true,selectCustom:item});
                console.log(this.state.selectCustom)
                return;
            }
        }
    }
    renderExportModal=(vcfDataSource,exportDataSource)=>{
        return (<Modal title="导出结果选项" visible={this.state.showExportModal} width={1000}
                        cancelText={"取消"} onCancel={()=>{this.setState({showExportModal:false})}}
                        okText={"导出"} onOk={()=>{this.state.exportType === "vcf"?this.ExportVcf(vcfDataSource):this.OnClickExport(exportDataSource)}} >
                <Checkbox.Group disabled={this.state.exportType === "vcf"} options={exportOptions}
                                defaultValue={exportOptions} onChange={this.OnChangeColumn}/>
                <Divider/>
                <Radio.Group onChange={this.OnChangeExportFormat} defaultValue={'xlsx'}>
                    <Radio value={'xlsx'}>Excel(.xlsx)</Radio>
                    <Radio value={'xls'}>Excel(.xls)</Radio>
                    <Radio value={'csv'}>CSV</Radio>
                    <Radio value={'txt'}>文本(TXT)</Radio>
                    <Radio value={'vcf'}>手机通讯录(vcard)</Radio>
                </Radio.Group>
                <Divider/>
                <Row hidden={this.state.exportType !== 'vcf'}>
                    <Radio.Group onChange={this.OnChangeVcfSize} defaultValue={'all'}>
                        <Radio value={'200'}>拆分成多个文件(200条/个)</Radio>
                        <Radio value={'2000'}>拆分成多个文件(2000条/个)</Radio>
                        <Radio value={'0'}>不拆分</Radio>
                    </Radio.Group>
                </Row>
        </Modal>);
    }
    renderLevelStyleText(text){
        const levelColor = ["","#108ee9","#f50"];
        return (<Tag color={levelColor[customLevelList.indexOf(text)]}>{text}</Tag>)
    }
    renderStatusStyleText(text){
        const statusColor = ["","#2db7a0","#2db7f5",'#108ee9',"#87d068","red"];
        return (<Tag color={statusColor[customStatusList.indexOf(text)]}>{text}</Tag>)
    }
    renderTypeStyleText(text){
        const typeColor = ["","#1369bf"];
        return (<Tag color={typeColor[customTypeList.indexOf(text)]}>{text}</Tag>)
    }
    renderAction(record){
        if(this.props.editEnable){
            return (<Row>
                <Col span={8}><Button size='small' type="link"onClick={()=>{this.handleShowClick(record)}}>详细</Button></Col>
                <Col span={8}><Button size='small' type="link" onClick={()=>{this.handleOnEditClick(record)}}>编辑</Button></Col>
                <Col span={6} offset={1}><Button size='small' type="link" style={{color:'red'}} onClick={()=>this.showDeleteConfirm(record.uuid)}>删除</Button></Col>
            </Row>)
        }
        else{
            if(-1 !== record.manager.indexOf(localStorage.getItem('name'))){
                return (<Row>
                    <Col span={8}><Button size='small' type="link" onClick={()=>{this.handleShowClick(record)}}>详细</Button></Col>
                    <Col span={8}><Button size='small' type="link" onClick={()=>{this.handleOnEditClick(record)}}>编辑</Button></Col>
                </Row>)
            }
            else{
                return (<Row>
                    <Col span={8}><Button size='small' type="link" onClick={()=>{this.handleShowClick(record)}}>详细</Button></Col>
                </Row>)
            }
        }
    }
    renderCustom(){
        const customStatus = [];
        const customLevel = [];
        const customSource = [];
        customLevelList.forEach(item=>{customLevel.push({text:item,value:item})});
        customStatusList.forEach(item=>{customStatus.push({text:item,value:item})});
        customSourceList.forEach(item=>{customSource.push({text:item,value:item})});
        let showColumns = [{title: '客户名称',dataIndex:'name',key: 'name',ellipsis:true,width:130,isSearch:true,render: (text, record) => 
            <span style={{color:'#2F5BEA',cursor:'pointer'}} onClick={()=>{this.showDescription(record);}}><CaretDownOutlined/>&nbsp;{text}</span>},
        {title: '客户类别',dataIndex:'type',key: 'type',width:120,sorter:(a, b) => StringCompareSort(a.type,b.type),
            filters:this.state.customType,onFilter: (value, record) => record.type.indexOf(value) === 0,render:(text)=>this.renderTypeStyleText(text)},
        {title: '客户等级',dataIndex:'level',key: 'level',width:120,sorter:(a, b) => StringCompareSort(a.level,b.level),filters:customLevel,
            onFilter: (value, record) => record.level.indexOf(value) === 0,render:(text)=>this.renderLevelStyleText(text)},
        {title:'客户来源',dataIndex:'source',key:'source', width:120,filterMultiple:true,filters:customSource,sorter:(a, b) => StringCompareSort(a.source,b.source),
            onFilter: (value, record) => record.source.indexOf(value) === 0,render:(text)=>(<span>{text}</span>)},
        {title: '客户状态',dataIndex:'status',key:'status',width:120,filterMultiple:true,filters:customStatus,sorter:(a, b) => StringCompareSort(a.status,b.status),
            onFilter: (value, record) => record.status.indexOf(value) === 0,render:(text)=>this.renderStatusStyleText(text)},
        {title: '城市',dataIndex:'city',key: 'city',width:90,isSearch:true,sorter:(a,b)=>StringCompareSort(a.city,b.city),
            onFilter: (value, record) => record.city.indexOf(value) === 0},
        {title: '手机号码',dataIndex:'phone',key: 'phone',width:120,ellipsis:true,sorter:(a, b) => StringCompareSort(a.phone,b.phone)},
        {title: '座机',dataIndex:'tel',key: 'tel',width:80,ellipsis:true,sorter:(a, b) => StringCompareSort(a.tel,b.tel)},
        {title: '联系地址',dataIndex:'address',key: 'address',width:120,ellipsis:true,isSearch:true},
        {title: '负责人',dataIndex:'manager',key: 'manager',width:120,ellipsis:true,isSearch:true, sorter:(a, b) => StringCompareSort(a.manager,b.manager),
            render:(text, record) =><Row>{text instanceof Array?text.map((tag,index)=>{
                const tagElem = (<Tag key={tag} color={"geekblue"}>{tag}</Tag>);
                return (tagElem);}):text !== "公海客户"?(<Tag key={text} color={"geekblue"}>{text}</Tag>):<Tag color={"#f50"}>公海客户</Tag>}</Row>,
            onFilter: (value, record) => record.manager.indexOf(value) === 0},
        {title: '参与人',dataIndex:'follower',key: 'follower',width:120,ellipsis:true,isSearch:true, sorter:(a, b) => StringCompareSort(a.follower,b.follower),
            render:(text, record) =><Row>{text instanceof Array?text.map((tag,index)=>{
                const tagElem = (<Tag key={tag} color={"geekblue"}>{tag}</Tag>);
                return (tagElem);}):text !== "公海客户"?(<Tag key={text} color={"geekblue"}>{text}</Tag>):<Tag color={"#f50"}>公海客户</Tag>}</Row>,
            onFilter: (value, record) => record.manager.indexOf(value) === 0},
        {title: '录入人',dataIndex:'creator',key: 'creator',width:120,ellipsis:true,isSearch:true,sorter:(a, b) =>StringCompareSort(a.creator,b.creator),
            render:(text, record) =><Row>{text instanceof Array?text.map((tag,index)=>{
                const tagElem = (<Tag key={tag} color={"geekblue"}>{tag}</Tag>);
                    return (tagElem);
                }):text?(<Tag key={text} color={"geekblue"}>{text}</Tag>):null}</Row>},
        {title: '录入时间',dataIndex:'createTime',key: 'createTime',width:180,ellipsis:true,sorter: (a, b) => Date.parse(a.createTime) - Date.parse(b.createTime),
            render:(text, record) =><span>{moment(new Date(text)).format('YYYY-MM-DD HH:mm:ss')}</span>},
        {title: '标签',dataIndex: 'tags',key: 'tags',isSearch:true,width:120,render:(text,record)=><EditableTagGroup sourceData={record} value={text} onSaveTags={this.handleSaveTags}/>},
        {title: '操作',dataIndex:'actions',key:'actions',width:150,fixed: 'right', render: (text, record) =>this.renderAction(record)}];
        let dataSource = [];
        let exportDataSource = [];
        for (let i = 0;i < this.props.dataSource.length;i++){
            let record = this.props.dataSource[i];
            let detail = record.detail;
            let manager = account2Name(detail.manager_account);
            let follower = account2Name(detail.follower_account);
            let creator = account2Name(record.creator_account);
            dataSource.push({
                index:i+1,
                uuid:record.uuid,
                name: record.name,
                level:record.level,
                type: record.type,
                source:detail.source,
                status: record.status,
                city:detail.city,
                tel:detail.tel,
                phone: detail.phone,
                address: detail.address,
                manager: manager,
                follower: follower,
                creator: creator,
                createTime:record.create_time,
                tags:detail.tags?detail.tags:""
            });
            exportDataSource.push({
                "序号":i+1,
                "客户名称": record.name,
                "客户类别": record.type,
                "客户等级": record.level,
                '客户来源': detail.source,
                '客户状态': record.status,
                "城市":detail.city,
                '手机号码': detail.phone,
                '座机': detail.tel,
                '联系地址': detail.address,
                '负责人': manager,
                '参与人': follower,
                '录入人': creator,
                '录入时间':record.create_time});
        }
        /*const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            getCheckboxProps: record => ({
                disabled: record.name === 'Disabled User', // Column configuration not to be checked
                name: record.name,
            }),
        };*/
        return (<div>
                    <ExtendTable expandIconAsCell={false} expandIconColumnIndex={1} rowKey={record => record.uuid} 
                        customColumnsEnable={true} tableKey={this.props.tableKey} columns={showColumns} dataSource={dataSource} 
                        handleAddOnClick={this.handleAddOnClick} batchDelEnable={false === this.props.batchDelEnable?false:true}
                        onBatchDelete={this.batchDelete} editEnable={this.props.editEnable} batchEditEnable={false === this.props.batchEditEnable?false:true} 
                        onBatchEdit={this.batchOnEditClick} exportEnable={this.props.exportEnable} exportTitle={"导出客户"} exportClick={()=>this.setState({showExportModal:true})} 
                        extendEnable={this.props.extendEnable} extendTitle={"客户去重规则"} extendClick={()=>{this.setState({showExtendModal:Math.round(Math.random()*9000)})}}
                        total={this.props.total}/>
                    <CustomForm wrappedComponentRef={this.saveFormRef} values={this.state.customValues} mode={this.state.modalMode} title={this.state.modalTitle} 
                        visible={this.state.showCustomModal} onCancel={this.handleCancel} onOk={this.handleCustomSubmit}/>
                    <CustomRepeatConfigModal visible={this.state.showExtendModal}/>
                    {this.renderExportModal(dataSource,exportDataSource)}
                    <BatchEditCustomFrom wrappedComponentRef={this.saveBatchEditFormRef} visible={this.state.showBatchEditModal} 
                        onCancel={()=>{this.setState({showBatchEditModal:false})}} onOk={this.handleBatchEditSubmit}/>
                    <CustomDescription selectCustom={this.state.selectCustom} visible={this.state.showDescription} onClose={()=>{this.setState({showDescription:false})}}/>
                </div>);
    }
    render(){
        return(this.renderCustom());
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(CustomTable)