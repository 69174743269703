import React from 'react';

class OtherCustom extends React.Component {
    constructor(props){
        super(props);
        this.state = {};
    }
    render(){
        return (<div>the user OtherCustom page</div>);
    }
}
export default OtherCustom;