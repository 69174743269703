import React from 'react';
import { Tag, Input, Tooltip } from 'antd';
import {PlusOutlined} from '@ant-design/icons';

export default class EditableTagGroup extends React.Component {
    state = {
        tags: this.props.value?this.props.value:[],
        inputVisible: false,
        inputValue: '',
    };

  handleClose = removedTag => {
    const tags = this.state.tags.filter(tag => tag !== removedTag);
    console.log(tags);
    this.setState({ tags });
    this.triggerChange(tags);
    if(this.props.onSaveTags){
      this.props.onSaveTags(this.props.sourceData,tags);
    }
  };

  showInput = () => {
    if(this.props.disabled){return}
    this.setState({ inputVisible: true }, () => this.input.focus());
  };

  handleInputChange = e => {
    this.setState({ inputValue: e.target.value });
    this.triggerChange(e.target.value);
  };

  handleInputConfirm = () => {
    const { inputValue } = this.state;
    let { tags } = this.state;
    if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [...tags, inputValue];
    }
    console.log(tags);
    this.setState({tags,inputVisible: false,inputValue: ''});
    this.triggerChange(tags);
    if(this.props.onSaveTags){
      this.props.onSaveTags(this.props.sourceData,tags);
    }
  };
  triggerChange = (changedValue) => {
    // Should provide an event to pass value to Form.
    console.log(changedValue)
    const onChange = this.props.onChange;
    if (onChange) {
        onChange(changedValue);
    }
}
  saveInputRef = input => (this.input = input);
  render() {
    const { tags, inputVisible, inputValue } = this.state;
    return (
      <div>
        {tags.map((tag, index) => {
          const isLongTag = tag.length > 20;
          const tagElem = (
            <Tag key={tag} closable={true} onClose={() => this.handleClose(tag)}>
              {isLongTag ? `${tag.slice(0, 20)}...` : tag}
            </Tag>
          );
          return isLongTag ? (
            <Tooltip title={tag} key={tag}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          );
        })}
        {inputVisible && (
          <Input ref={this.saveInputRef} type="text" size="small"
            style={{ width: 78 }}
            value={inputValue}
            onChange={this.handleInputChange}
            onBlur={this.handleInputConfirm}
            onPressEnter={this.handleInputConfirm}
          />
        )}
        {!inputVisible && (
          <Tag onClick={this.showInput} style={{ background: '#fff', borderStyle: 'dashed' }}><PlusOutlined /> </Tag>
        )}
      </div>
    );
  }
}
