import {ActionType} from "../constants/actionTypes";

const expenseReducer = (state = {},action)=>{
    switch (action.type) {
        case ActionType.ADD_EXPENSE_START:
            return {...state,add_:false}
        case ActionType.ADD_EXPENSE_SUCCESS:
            return {...state,add_expense:true,add_expense_result:action.payload}
        case ActionType.ADD_EXPENSE_FAILED:
            return {...state,add_expense:false,add_expense_errors:action.error?action.payload.errors:null}

        case ActionType.GET_EXPENSE_START:
            return {...state,get_expense:false}
        case ActionType.GET_EXPENSE_SUCCESS:
            return {...state,get_expense:true,get_expense_result:action.payload}
        case ActionType.GET_EXPENSE_FAILED:
            return {...state,get_expense:false,get_expense_errors:action.error?action.payload.errors:null}

        case ActionType.UPDATE_EXPENSE_START:
            return {...state,update_expense:false}
        case ActionType.UPDATE_EXPENSE_SUCCESS:
            return {...state,update_expense:true,update_expense_result:action.payload}
        case ActionType.UPDATE_EXPENSE_FAILED:
            return {...state,update_expense:false,update_expense_errors:action.error?action.payload.errors:null}

        case ActionType.DELETE_EXPENSE_START:
            return {...state,delete_expense:false}
        case ActionType.DELETE_EXPENSE_SUCCESS:
            return {...state,delete_expense:true,delete_expense_result:action.payload}
        case ActionType.DELETE_EXPENSE_FAILED:
            return {...state,delete_expense:false,delete_expense_errors:action.error?action.payload.errors:null}

        default:
            return {...state};
    }
}
export default expenseReducer;
