import {applyMiddleware,createStore} from "redux";
import thunkMiddleware from 'redux-thunk' ;
import { createLogger } from 'redux-logger';
import reducer from '../reducer';
import { routerMiddleware } from 'react-router-redux'
import { createHashHistory as createHistory } from 'history'
const loggerMiddleware = createLogger()
export const history = createHistory();
const myRouterMiddleware = routerMiddleware(history);
const getMiddleware = () => {
    if (process.env.NODE_ENV === 'production') {
        return applyMiddleware(thunkMiddleware,myRouterMiddleware);
    } else {
        // Enable additional logging in non-production environments.
        return applyMiddleware(thunkMiddleware,myRouterMiddleware,loggerMiddleware)
    }
};
export const store = createStore(reducer,getMiddleware())
