import React from 'react';
import {Row,Tabs} from 'antd';
import CustomTable from '../../controls/CustomTable';
import {getAllCustom} from "../../../actions/customDBTool";
import {connect} from "react-redux";
import {CUSTOM_TYPE_PERSONAL, USER_LEVEL_MEMBER} from "../../../constants/constant";
const { TabPane } = Tabs;

function mapStateToProps(state){
    return {...state.custom};
}
function mapDispatchToProps(dispatch){
    return {onGetCustom:()=>dispatch(getAllCustom())};
}
class NormalCustom extends React.Component {
    constructor(props){
        super(props);
        this.state = {};
    }
    componentDidMount() {
        this.props.onGetCustom();
    }

    renderNormalCustom(){
        let normalCustomDS = [];
        let allCustomDs = [];
        if(this.props.get_custom_result){
            let result = this.props.get_custom_result;
            for (let i = 0;i < result.length;i++){
                if(CUSTOM_TYPE_PERSONAL === result[i].type){
                    if(result[i].detail.manager_account && -1 !== result[i].detail.manager_account.indexOf(localStorage.getItem('account'))){
                        normalCustomDS.push(result[i]);
                    }
                    allCustomDs.push(result[i]);
                }
            }
        }
        const customEditable = USER_LEVEL_MEMBER < Number(localStorage.getItem('level'))?true:false
        const isManager = USER_LEVEL_MEMBER < Number(localStorage.getItem('level'))?true:false
        return(<Tabs defaultActiveKey={"2"}>
            {isManager?
                (<TabPane key="1" tab="全部个人客户">
                    <CustomTable dataSource={allCustomDs} tableKey={'crm/normal_custom'} exportEnable={customEditable} editEnable={customEditable} batchDelEnable={customEditable} batchEditEnable={customEditable}/>
                </TabPane>):null}
            <TabPane key="2" tab="我的个人客户">
                <CustomTable dataSource={normalCustomDS} tableKey={'crm/normal_custom'} exportEnable={customEditable} editEnable={customEditable} batchDelEnable={customEditable} batchEditEnable={customEditable}/>
            </TabPane>
        </Tabs>);
    }
    render(){
        return (<Row>{this.renderNormalCustom()}</Row>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(NormalCustom);