import React from 'react'
import {Button,Row,Col,Form,Table,Modal,Input,Divider, message,InputNumber} from 'antd';
import {connect} from "react-redux";
import moment from "moment";
import {PlusCircleOutlined,MinusCircleOutlined} from '@ant-design/icons';
import {addBusinessFlow, deleteBusinessFlow, getBusinessFlow, updateBusinessFlow} from "../../actions/businessDBTool"
import { account2Name } from '../../utils/accountUtils';
const {confirm} = Modal;
const BusinessOpportunityStatusFrom = Form.create({title:"business_status_modal"})(
    class extends React.Component{
        constructor(props){
            super(props);
            this.state = {};
            this.add = this.add.bind(this);
            this.remove = this.remove.bind(this);
        }
        remove(k){
            const { form } = this.props;
            const keys = form.getFieldValue("flows_keys");
            if (keys.length === 4) {return;}
            form.setFieldsValue({flows_keys: keys.filter(key => key !== k)});
        }
        add(){
            const { form } = this.props;
            const keys = form.getFieldValue("flows_keys");
            if(keys.length >= 10){return;}
            const nextKeys = keys.concat(keys.length);
            form.setFieldsValue({flows_keys: nextKeys});
        }
        render(){
            let {visible,onCancel,onOk,form,values,mode} = this.props;
            let formItemLayout = {labelCol: {span:6}, wrapperCol: {span:16}};
            let { getFieldDecorator } = form;
            let defaultFlows = [];
            if(values.flows){
                for (let i = 0;i < values.flows.length;i++){
                    defaultFlows.push(i);
                }
            }
            else{
                defaultFlows.push(0);
            }
            getFieldDecorator("flows_keys", { initialValue: mode?defaultFlows:[0]});
            const flows = form.getFieldValue("flows_keys");
            console.log(flows)
            return (<Modal title={"商机阶段流程设置"} visible={visible} onCancel={onCancel} onOk={onOk} width={1000}>
                <Form {...formItemLayout}>
                    <Form.Item label={'商机流程名称'}>
                        {getFieldDecorator('title', {rules: [{ required: true, message: '商机流程名称不能为空!'}],initialValue:values?values.title:''})
                            (<Input placeholder="请输入自定义商机流程的名称" style={{width:'70%'}}/>)}
                    </Form.Item>
                    <Divider/>
                    <br/>
                    {flows.map((key,index) => 
                        <Row key={index}>
                            <Col span={10} offset={2}>
                                <Form.Item label={`阶段${index+1}`}>
                                    {getFieldDecorator(`flows[${index}]`, {rules: [{ required: true, message: '商机阶段名称不能为空!'}],initialValue:values.flows?values.flows[index]:''})
                                        (<Input placeholder="请输入自定义商机阶段的名称" style={{width:'90%'}}/>)}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={`成交百分比(%)`}>
                                    {getFieldDecorator(`percent[${index}]`, {rules: [{ required: true, message: '不能为空'}],initialValue:values.percent?values.percent[index]:10*(index+1)})
                                        (<InputNumber min={0} max={100} step={5} style={{width:'70%',marginRight: 5}}/>)}
                                    {index === 0?
                                        (<PlusCircleOutlined style={{color:'blue'}} onClick={() => this.add()}/>):(<MinusCircleOutlined style={{color:'red'}} onClick={() => this.remove(key)}/>)}
                                </Form.Item>
                            </Col>
                        </Row>)}
                </Form>
            </Modal>);
        }
    }
)
function mapStateToProps(state){
    return {...state.businessOpportunity}
}
function mapDispatchToProps(dispatch){
    return {onAddBusinessFlow:(postData)=>dispatch(addBusinessFlow(postData)),
            onUpdateBusinessFlow:(postData)=>dispatch(updateBusinessFlow(postData)),
            onGetBusinessFlow:(callback)=>dispatch(getBusinessFlow(callback)),
            onDeleteBusinessFlow:(uuid)=>dispatch(deleteBusinessFlow(uuid))}
}
class BusinessOpportunityStatusModal extends React.Component{
    constructor(props){
        super(props);
        this.state={visible:false,enableCount:0,mode:0,values:{title:'',flows:['','','','','','','','','',''],isUsing:false}}
        this.onSubmit = this.onSubmit.bind(this);
        this.handleOnEditClick = this.handleOnEditClick.bind(this);
        this.handleOnAddClick = this.handleOnAddClick.bind(this);
        this.handleDisableClick = this.handleDisableClick.bind(this);
        this.handleEnableClick = this.handleEnableClick.bind(this);
    }
    componentDidMount(){
        this.props.onGetBusinessFlow(()=>{
            let result = this.props.get_business_flow_result?this.props.get_business_flow_result:[];
            for(let i = 0;i < result.length;i++){
                if(result[i].detail.isUsing){
                    this.setState({enableCount:this.state.enableCount+1});
                }
            }
        });
    }
    saveFormRef = formRef =>{this.formRef = formRef;}
    onSubmit(){
        const { form } = this.formRef.props;
        form.validateFields((err, fromValues) => {
            if (err) {return;}
            let detail = {flows:fromValues.flows,
                          isUsing:this.state.mode?this.state.values.isUsing:false,
                          percent:fromValues.percent};
            let postData = {
                title:fromValues.title,
                detail:JSON.stringify(detail),
                update_account:localStorage.getItem('account'),
                update_time:Date.parse(new Date())
            };
            if(this.state.mode){
                postData['uuid'] = this.state.values.uuid;
                postData['creator_account'] = this.state.values.creator_account;
                postData['create_time'] = this.state.values.create_time;
                postData['enterprise_uuid'] = localStorage.getItem('enterprise_uuid')
                this.props.onUpdateBusinessFlow(postData);
            }
            else{
                postData['uuid'] = Date.parse(new Date())/1000+(Math.round(Math.random() * (9000))+999);
                postData['creator_account'] = localStorage.getItem('account');
                postData['create_time'] = Date.parse(new Date());
                postData['enterprise_uuid'] = localStorage.getItem('enterprise_uuid')
                this.props.onAddBusinessFlow(postData);
            }
            this.setState({visible:false});
            form.resetFields();
        })
    }
    handleOnEditClick(record){
        let result = this.props.get_business_flow_result?this.props.get_business_flow_result:[];
        for(let i = 0;i < result.length;i++){
            if(record.uuid === result[i].uuid){
                return this.setState({mode:1,visible:true,values:{uuid:record.uuid,
                                            title:record.title,
                                            flows:result[i].detail.flows,
                                            percent:result[i].detail.percent,
                                            isUsing:result[i].detail.isUsing,
                                            update_account:result[i].update_account,
                                            update_time:result[i].update_time,
                                            creator_account:result[i].creator_account,
                                            create_time:Date.parse(result[i].create_time)}});
            }
        }
    }
    handleOnAddClick(){
        if(this.props.get_business_flow_result.length>=4){
            message.info("已达到自定义商机阶段的数量上限！删除部分商机阶段后可创建");
            return;
        }
        return this.setState({mode:0,visible:true,values:{title:'',flows:[],isUsing:false}});
    }
    handleEnableClick(record){
        if(1 <= this.state.enableCount){
            return message.info("只能启用一个商机阶段！");
        }
        let result = this.props.get_business_flow_result?this.props.get_business_flow_result:[];
        for(let i = 0;i < result.length;i++){
            if(record.uuid === result[i].uuid){
                let detail = {
                    flows:result[i].detail.flows,
                    percent:result[i].detail.percent,
                    isUsing:true
                }
                let postData = {
                    uuid:result[i].uuid,
                    enterprise_uuid:localStorage.getItem('enterprise_uuid'),
                    title:result[i].title,
                    detail:JSON.stringify(detail),
                    update_account:result[i].update_account,
                    update_time:Date.parse(result[i].update_time),
                    creator_account:result[i].creator_account,
                    create_time:Date.parse(result[i].create_time)
                }
                this.props.onUpdateBusinessFlow(postData);
                this.setState({enableCount:this.state.enableCount+1});
            }
        }
    }
    handleDisableClick(record){
        console.log(this.state.enableCount)
        let result = this.props.get_business_flow_result?this.props.get_business_flow_result:[];
        for(let i = 0;i < result.length;i++){
            if(record.uuid === result[i].uuid){
                let detail = {
                    flows:result[i].detail.flows,
                    percent:result[i].detail.percent,
                    isUsing:false
                }
                let postData = {
                    uuid:result[i].uuid,
                    enterprise_uuid:localStorage.getItem('enterprise_uuid'),
                    title:result[i].title,
                    detail:JSON.stringify(detail),
                    update_account:result[i].update_account,
                    update_time:Date.parse(result[i].update_time),
                    creator_account:result[i].creator_account,
                    create_time:Date.parse(result[i].create_time)
                }
                this.props.onUpdateBusinessFlow(postData);
                this.setState({enableCount:this.state.enableCount-1});
            }
        }
    }
    showDeleteConfirm(uuid){
        confirm({
            title: '重要提示',
            content: '您确定要删除该商机流程?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk:()=> {this.props.onDeleteBusinessFlow(uuid);},
            onCancel() {console.log('Cancel');},
        });
    }
    render(){
        let result = this.props.get_business_flow_result?this.props.get_business_flow_result:[];
        let dataSource = [];
        const columns = [{title:'商机阶段名称',dataIndex:'title',key:'title'},
                        {title:'启用状态',dataIndex:'isUsing',key:'isUsing'},
                        {title:'最后修改时间',dataIndex:'updateTime',key:'updateTime'},
                        {title:'修改人',dataIndex:'updater',key:'updater'},
                        {title:'创建人',dataIndex:'creator',key:'creator'},
                        {title:'创建时间',dataIndex:'create_time',key:'create_time'},
                        {title:'操作',dataIndex:'actions',key:'actions', render: (text, record) =>
                        <span>
                            <Button type="link" disabled={"正在使用"===record.isUsing} onClick={()=>{this.handleEnableClick(record)}}>启用</Button>
                            <Button type="link" disabled={"未使用"===record.isUsing} onClick={()=>{this.handleDisableClick(record)}}>停用</Button>
                            <Button type="link" onClick={()=>{this.handleOnEditClick(record)}}>修改</Button>
                            <Button type="link" style={{color:'red'}} onClick={()=>this.showDeleteConfirm(record.uuid)}>删除</Button>
                        </span>}]
        for(let i = 0;i < result.length;i++){
            dataSource.push({uuid:result[i].uuid,
                            title:result[i].title,
                            isUsing:result[i].detail.isUsing?"正在使用":"未使用",
                            updateTime:moment(new Date(result[i].update_time)).format('YYYY-MM-DD HH:mm:ss'),
                            updater:account2Name(result[i].update_account),
                            creator:account2Name(result[i].creator_account),
                            create_time:moment(new Date(result[i].create_time)).format('YYYY-MM-DD HH:mm:ss')});
        }
        return(<Modal title="设置商机阶段" width={1000} visible={this.props.visible} 
                onCancel={this.props.onCancel} onOk={this.props.onOk}>
            <Row>
                <BusinessOpportunityStatusFrom wrappedComponentRef={this.saveFormRef} visible={this.state.visible} mode={this.state.mode}
                    values={this.state.values} onCancel={()=>{this.setState({visible:false})}} onOk={this.onSubmit}/>
            </Row>
            <Row>
                <Table dataSource={dataSource} columns={columns} rowKey={record=>record.uuid}></Table>
            </Row>
            <br/>
            <Row><Button onClick={()=>{this.handleOnAddClick()}}>新建</Button></Row>
        </Modal>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(BusinessOpportunityStatusModal);