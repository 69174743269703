import React from 'react';

class Agent extends React.Component{
    constructor(props){
        super(props);
        this.state = {};
    }
    render(){
        return (<div>the user Agent Page</div>);
    }
}
export default Agent;