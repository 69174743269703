import React from 'react';
import {Row, Collapse, Table, Descriptions, Button, Col, Tabs, Modal, Tag, Divider, Drawer} from 'antd';
import {UserOutlined,EditOutlined,DeleteOutlined,PlusOutlined} from '@ant-design/icons';
import {connect} from "react-redux";
import {deleteFollowUp,getAllFollowUpByCustom} from "../../actions/followupDBTool";
import {getAllOrderByCustom} from "../../actions/orderDBTool"
import {getBusinessByCustom} from "../../actions/businessDBTool";
import {updateCustom} from "../../actions/customDBTool";
import {getWorkOrderByCustom} from "../../actions/workOrderDBTool";
import {getCollectionByCustom} from "../../actions/collectionDBTool";
import FollowUpModal from "./FollowUpModal";
import moment from "moment";
import OrderTable from "./OrderTable";
import {USER_LEVEL_MEMBER} from "../../constants/constant";
import CollectionTable from "./CollectionTable";
import BusinessOpportunityTable from "./BusinessOpportunityTable";
import WorkOrderTable from "./WorkOrderTable";
import CustomRelateModal from './CustomRelateModal';
import {account2Name} from "../../utils/accountUtils";
import {delHtmlTag} from "../../utils/common";

const {TabPane} = Tabs;
const { Panel } = Collapse;
const {confirm} = Modal;

function mapStateToProps(state) {
    return {...state.custom,
            ...state.order,
            ...state.collection,
            ...state.workOrder,
            ...state.businessOpportunity}
}
function mapDispatchToProps(dispatch) {
    return {onGetCollectionByCustom:(custom_uuid)=>dispatch(getCollectionByCustom(custom_uuid)),
            onGetWorkOrderByCustom:(custom_uuid)=>dispatch(getWorkOrderByCustom(custom_uuid)),
            onGetBusinessOpportunity:(custom_uuid)=>dispatch(getBusinessByCustom(custom_uuid)),
            onGetOrderByCustom:(custom_uuid)=>dispatch(getAllOrderByCustom(custom_uuid)),
            onGetFollowUpByCustom:(custom_uuid)=>dispatch(getAllFollowUpByCustom(custom_uuid)),
            onDeleteFollowUp:(postData)=>dispatch(deleteFollowUp(postData)),
            onUpdateCustom:(postData)=>dispatch(updateCustom(postData))};
}
class CustomDescription extends React.Component{
    constructor(props) {
        super(props);
        this.state = {  relateVisible:false,
                        relateModifyValues:'',
                        relateMode:0,
                        followupVisible:false,
                        followUpValues:{uuid:Date.parse(new Date())/1000+(Math.round(Math.random() * (9000))+999),file_url:[]},
                        followUpMode:0,
                        customInfo:'',
                        needFreshRelate:false
        };
        this.onClickAddFollowUp = this.onClickAddFollowUp.bind(this);
        this.onClickAddRelate = this.onClickAddRelate.bind(this);
        this.OnClickEditCustom = this.OnClickEditCustom.bind(this);
        this.onClickEditFollowUp = this.onClickEditFollowUp.bind(this);
    }
    componentDidMount(){
        /*this.props.onGetCollectionByCustom(this.props.selectCustom.name);
        this.props.onGetBusinessOpportunity(this.props.selectCustom.name);
        this.props.onGetWorkOrderByCustom(this.props.selectCustom.name);
        this.props.onGetOrderByCustom(this.props.selectCustom.name);
        this.props.onGetFollowUpByCustom(this.props.selectCustom.uuid);
        this.setState({customInfo:this.props.selectCustom});*/
    }
    shouldComponentUpdate(nextProps, nextState, nextContext){
        if(this.props.selectCustom.uuid !== nextProps.selectCustom.uuid){
            this.props.onGetWorkOrderByCustom(nextProps.selectCustom.uuid);
            this.props.onGetBusinessOpportunity(nextProps.selectCustom.uuid);
            this.props.onGetCollectionByCustom(nextProps.selectCustom.uuid);
            this.props.onGetOrderByCustom(nextProps.selectCustom.uuid);
            this.props.onGetFollowUpByCustom(nextProps.selectCustom.uuid);
            this.setState({customInfo:nextProps.selectCustom});
            return true;
        }
        else if(this.props.visible !== nextProps.visible||
                this.state.followupVisible !== nextState.followupVisible ||
                this.state.relateVisible !== nextState.relateVisible ||
                this.state.needFreshRelate !== nextState.needFreshRelate ||
                this.props.get_followup_by_custom_result !== nextProps.get_followup_by_custom_result ||
                this.props.get_order_by_custom_result !== nextProps.get_order_by_custom_result ||
                this.props.get_collection_by_custom_result !== nextProps.get_collection_by_custom_result ||
                this.props.get_work_order_by_custom_result !== nextProps.get_work_order_by_custom_result ||
                this.props.get_business_opportunity_by_custom_result !== nextProps.get_business_opportunity_by_custom_result){
                return true;
        }
        else if(this.props.get_order_result && this.props.get_order_result !== nextProps.get_order_result){// for mult select on the description page
            this.props.onGetOrderByCustom(nextProps.selectCustom.uuid);
            return true;
        }
        else if(this.props.get_collection_result && this.props.get_collection_result !== nextProps.get_collection_result){
            this.props.onGetCollectionByCustom(nextProps.selectCustom.uuid);
            return true;
        }
        else if(this.props.get_work_order_result && this.props.get_work_order_result !== nextProps.get_work_order_result){
            this.props.onGetWorkOrderByCustom(nextProps.selectCustom.uuid);
            return true;
        }
        else if(this.props.get_business_opportunity_result && this.props.get_business_opportunity_result !== nextProps.get_business_opportunity_result){
            this.props.onGetBusinessOpportunity(nextProps.selectCustom.uuid);
            return true;
        }
        return false
    }
    showDeleteConfirm = (followUp)=> {
        confirm({
            title: '重要提示',
            content: '您确定要删除该跟进记录?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk:()=> {this.props.onDeleteFollowUp(followUp);},
            onCancel() {console.log('Cancel');},
        });
    }
    onClickAddFollowUp(customInfo){
        let detail = {  custom_status:customInfo.status,
                        custom_name:customInfo.name,
                        phone:customInfo.detail.phone,
                        content:'',
                        assigner:'',
                        file_url:[]};
        let values = {  uuid:Date.parse(new Date())/1000+(Math.round(Math.random() * (9000))+999),
                        title:'',type:'',status:'',
                        custom_uuid:customInfo.uuid,
                        detail:detail,
                        creator_account:customInfo.creator_account};
        this.setState({followupVisible:Math.round(Math.random()*9000),followUpMode:0,followUpValues:values})
    }
    onClickEditFollowUp(followUpItem){
        followUpItem.detail.file_url = followUpItem.detail.file_url?followUpItem.detail.file_url:[]
        this.setState({followupVisible:Math.round(Math.random()*9000),followUpMode:1,followUpValues:followUpItem});
    }
    onClickAddRelate(){
        this.setState({relateVisible:true,relateMode:0});
    }
    onClickEditRelate(record){
        this.setState({relateVisible:true,relateMode:1,relateModifyValues:record});
    }
    showDeleteRelateConfirm(record){
        confirm({
            title: '重要提示',
            content: '您确定要解除该客户的关联?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk:()=> {
                let detail = {}
                let customInfo = this.state.customInfo;
                if (customInfo) {
                    detail = customInfo.detail;
                }
                for (let i = 0; i < detail['relateArray'].length;i++){
                    if (detail['relateArray'][i].relate_custom === record.relate_custom){
                        detail['relateArray'].splice(i, 1);
                    }
                }
                let postData={
                    enterprise_uuid:localStorage.getItem('enterprise_uuid'),
                    uuid:customInfo.uuid,
                    name:customInfo.name,
                    age:customInfo.age,
                    sex:customInfo.sex,
                    type:customInfo.type,
                    level:customInfo.level,
                    status:customInfo.status,
                    detail:JSON.stringify(detail),
                    update_time:Date.parse(new Date()),
                    creator_account:customInfo.creator_account,
                    create_time:Date.parse(customInfo['create_time'])
                }
                this.props.onUpdateCustom(postData);
                this.setState({needFreshRelate:!this.state.needFreshRelate});//for fresh the page when we delete the record
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }
    OnClickEditCustom(customInfo){
        console.log(customInfo);
    }
    renderFollowUp(customInfo){
        const followupEditable = USER_LEVEL_MEMBER < Number(localStorage.getItem('level'))?true:false
        if(this.props.get_followup_by_custom_result){
            let follow_list = this.props.get_followup_by_custom_result;
            follow_list.sort(function (a,b) {
                return Date.parse(b.create_time) - Date.parse(a.create_time);
            })
            let stepList = follow_list.map(item=>{
                let itemDetail = item.detail;
                return  <Panel key={item.uuid} header={<Row>
                                <Col span={5}>{moment(new Date(item.create_time)).format('YYYY-MM-DD HH:mm:ss')}</Col>
                                <Col span={5}>创建人:<Button type="link">{account2Name(item.creator_account)}</Button></Col>
                                {followupEditable?<Col span={1} offset={8} onClick={()=>{this.onClickEditFollowUp(item)}}><EditOutlined/></Col>:null}
                                {followupEditable?<Col span={1} onClick={()=>{this.showDeleteConfirm(item)}}><DeleteOutlined style={{color:'red'}}/></Col>:null}
                            </Row>}>
                    <Descriptions>
                        <Descriptions.Item label="客户名称">{itemDetail.custom_name}</Descriptions.Item>
                        <Descriptions.Item label="客户联系电话">{itemDetail.phone}</Descriptions.Item>
                        <Descriptions.Item label="客户当前状态">{itemDetail.custom_status}</Descriptions.Item>
                        <Descriptions.Item label="当前跟进阶段">{item.status}</Descriptions.Item>
                        <Descriptions.Item label="此次跟进内容">{delHtmlTag(itemDetail.content)}</Descriptions.Item>
                    </Descriptions>
                </Panel>
            })
            return (<Row>
                        <FollowUpModal visible={this.state.followupVisible} values={this.state.followUpValues} mode={this.state.followUpMode}/>
                        <Row style={{fontSize:'20px'}}>
                            <Button type="link" onClick={()=>{this.onClickAddFollowUp(this.state.customInfo)}}>
                                <PlusOutlined />&nbsp;添加跟进记录
                            </Button>
                        </Row>
                        <Collapse>{stepList}</Collapse>
                    </Row>);
        }
        return (<Row>
                <FollowUpModal visible={this.state.followupVisible} values={this.state.followUpValues} mode={this.state.followUpMode}/>
                <Button type="link" style={{fontSize:'20px'}} onClick={()=>{this.onClickAddFollowUp(this.state.customInfo)}}>
                    <PlusOutlined />&nbsp;添加跟进记录
                </Button>
            </Row>);
    }
    renderCustomProfile(customInfo){
        console.log(customInfo)
        let customDetail = '';
        if(customInfo.detail){
            customDetail = customInfo.detail;
        }
        return (<Descriptions title={(<Row>
                                        <Col span={15}><Button type="link">{customInfo.name}</Button></Col>
                                        {/*<Col span={6} offset={2}><Button type="primary" onClick={()=>{this.OnClickEditCustom(this.state.customInfo)}}>修改</Button></Col>*/}
                                    </Row>)}>
                    <Descriptions.Item label="客户类别">{customInfo.type}</Descriptions.Item>
                    <Descriptions.Item label="当前状态">{customInfo.status}</Descriptions.Item>

                    <Descriptions.Item label="联系方式">{customDetail.phone}</Descriptions.Item>
                    <Descriptions.Item label="所在城市">{customDetail.city}</Descriptions.Item>
                    <Descriptions.Item label="联系地址">{customDetail.address}</Descriptions.Item>

                    <Descriptions.Item label="所在行业">{customDetail.industry}</Descriptions.Item>

                    <Descriptions.Item label="特别备注">{delHtmlTag(customDetail.note)}</Descriptions.Item>
                </Descriptions>);
    }
    renderOrderInfo(customInfo){
        let dataSource = [];
        const orderEditable = USER_LEVEL_MEMBER < Number(localStorage.getItem('level'))?true:false
        if(this.props.get_order_by_custom){
            for (let i = 0;i < this.props.get_order_by_custom_result.length;i++){
                let orderItem = this.props.get_order_by_custom_result[i];
                dataSource.push(orderItem);
            }
        }
        return (<OrderTable customColumnsEnable={false} editEnable={orderEditable} batchDelEnable={orderEditable} selectCustom={customInfo} dataSource={dataSource}/>)
    }
    renderCollection(customInfo){
        let dataSource = [];
        if(this.props.get_collection_by_custom){
            for (let i = 0;i < this.props.get_collection_by_custom_result.length;i++){
                let item = this.props.get_collection_by_custom_result[i];
                dataSource.push(item);
            }
        }
        return (<CollectionTable customColumnsEnable={false} selectCustom={customInfo} dataSource={dataSource}/>)
    }
    renderBusiness(customInfo){
        let dataSource = [];
        if(this.props.get_business_opportunity_by_custom_result){
            for (let i = 0;i < this.props.get_business_opportunity_by_custom_result.length;i++){
                let record = this.props.get_business_opportunity_by_custom_result[i];
                dataSource.push(record);
            }
        }
        return (<BusinessOpportunityTable customColumnsEnable={false} selectCustom={customInfo} dataSource={dataSource}/>)
    }
    renderWorkOrder(customInfo){
        let dataSource = [];
        if(this.props.get_work_order_by_custom_result){
            let result = this.props.get_work_order_by_custom_result;
            for (let i = 0;i < result.length;i++){
                dataSource.push(result[i]);
            }
        }
        return (<WorkOrderTable customColumnsEnable={false} selectCustom={customInfo} dataSource={dataSource}/>)
    }
    renderCustomRelate(customInfo){
        let coulmns = [{title:'关联客户',key:'relate_custom',dataIndex:'relate_custom'},
                        {title:'关系',key:'relate',dataIndex:'relate'},
                        {title:'备注',key:'relate_note',dataIndex:'relate_note'},
                        {title: '操作人',dataIndex:'creator',key: 'creator',render:(text, record) =>
                        <Row>{text instanceof Array?text.map((tag,index)=>{
                                const tagElem = (<Tag key={tag} color={"geekblue"}>{tag}</Tag>);
                                return (tagElem);
                            }):text?(<Tag key={text} color={"geekblue"}>{text}</Tag>):null}
                        </Row>},
                        {title:'操作时间',key:'createTime',dataIndex:'createTime',render:(text, record) =><span>{moment(new Date(text)).format('YYYY-MM-DD HH:mm:ss')}</span>},
                        {title:'操作',key:'actions', render: (text, record) => <span>
                            <Button type="link" onClick={()=>this.onClickEditRelate(record)}>修改</Button>
                            <Divider type="vertical"/>
                            <Button type="link" style={{color:'red'}} onClick={()=>this.showDeleteRelateConfirm(record)}>解除</Button>
                        </span>}];
        let dataSource = [];
        if(customInfo.detail){
            let detail = customInfo.detail;
            if(detail.relateArray){
                detail.relateArray.forEach((element,index) => {
                    dataSource.push({index:index,
                                    relate:element.relate,
                                    relate_custom:element.relate_custom,
                                    relate_note:element.relate_note,
                                    creator:account2Name(element.relate_creator),
                                    createTime:element.relate_create_time});
                });
            }
        }
        return (<Row>
                <CustomRelateModal visible={this.state.relateVisible} onClose={()=>{this.setState({relateVisible:false})}}
                    values={customInfo} modifyValues={this.state.relateModifyValues} mode={this.state.relateMode}/>
                <Button type={'primary'} span={3} onClick={()=>{this.onClickAddRelate()}}><PlusOutlined />添加关联</Button>
                <Table columns={coulmns} dataSource={dataSource} rowKey={(record)=>record.index}/>
            </Row>);
    }
    render(){
        let selectCustom = this.state.customInfo;
        let manager = "";
        if(this.state.customInfo.detail){
            manager = account2Name(selectCustom.detail.manager_account);
        }
        return (<Drawer width={'65%'} placement="right" closable={false} visible={this.props.visible}
                    onClose={this.props.onClose}
                    title={<Row>
                    <Col span={6}>
                        <UserOutlined style={{color:'#AAA',fontSize:'24px'}}/>&nbsp;
                        {this.state.customInfo.name}</Col>
                    <Col span={6}>
                        <span style={{color:'#AAA'}}>负责人:&nbsp;</span>
                        <span>
                            {manager instanceof Array?manager.map((tag,index)=>{
                                const tagElem = (<Tag key={tag} color={"geekblue"}>{tag}</Tag>);
                                return (tagElem);}):
                                manager?(<Tag key={manager} color={"geekblue"}>{manager}</Tag>):(<Tag color={"#f50"}>公海客户</Tag>)}
                        </span>
                    </Col>
                </Row>}>
            <Tabs defaultActiveKey={"1"}>
                    <TabPane key={"1"} tab={"跟进记录"}>{this.renderFollowUp()}</TabPane>
                    <TabPane key={"2"} tab={"客户资料"}>{this.renderCustomProfile(selectCustom)}</TabPane>
                    <TabPane key={"3"} tab={"相关订单"}>{this.renderOrderInfo(selectCustom)}</TabPane>
                    <TabPane key={"4"} tab={"回款记录"}>{this.renderCollection(selectCustom)}</TabPane>
                    <TabPane key={"5"} tab={"商机"}>{this.renderBusiness(selectCustom)}</TabPane>
                    <TabPane key={"6"} tab={"工单"}>{this.renderWorkOrder(selectCustom)}</TabPane>
                    <TabPane key={"7"} tab={"关联客户"}>{this.renderCustomRelate(selectCustom)}</TabPane>
                </Tabs>
            </Drawer>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(CustomDescription);