import React from 'react';
import {connect} from "react-redux";
import {Table, Row, Col, Divider,Input, Button,Modal,Form} from 'antd';
import { updateEnterpriseDepartment, getEnterpriseDepartment } from '../../actions/enterpriseDBTool';

const {confirm} = Modal;
const DepartmentForm = Form.create({name:'department_form'})(
    class extends React.Component{
        render(){
            let { visible, onCancel, onOk, form, values,title,mode } = this.props;
            let { getFieldDecorator } = form;
            let formItemLayout = {labelCol: {span:8}, wrapperCol: {span:16}};
            return (<Modal title={title} visible={visible} onOk={onOk} okText={"确定"} onCancel={onCancel} cancelText={"取消"}>
                <Form {...formItemLayout}>
                    {mode?<Form.Item label="当前部门(职位)名称" required={true} >
                        {getFieldDecorator('name',{rules:[{ required: true,message:'名称不能为空!'}],initialValue:mode?values.name:""})
                        (<Input placeholder={"请输入部门(职位)的名称"}/>)}
                    </Form.Item>:
                    <div><Form.Item label="上级部门组织" required={true} hidden={mode}>
                    {getFieldDecorator('parent',{rules:[{ required: true}],initialValue:values.parent})
                    (<Input placeholder={"请输入部门的名称"} disabled/>)}
                    </Form.Item>
                    <Form.Item label="下级部门(职位)名称" required={true} >
                    {getFieldDecorator('name',{rules:[{ required: true,message:'名称不能为空!'}],initialValue:mode?values.name:""})
                    (<Input placeholder={"请输入部门(职位)的名称"}/>)}
                </Form.Item></div>}
                    {/*<Form.Item label="部门(职位)状态">
                        <Radio.Group defaultValue={mode?values.status:"enable"}>
                            <Radio value={"enable"}>正常</Radio>
                            <Radio value={"disable"}>禁用</Radio>
                        </Radio.Group>
                        </Form.Item>*/}
                </Form>
            </Modal>)
        }
})
function mapStateToProps(state) {
    return {...state.staff,...state.enterprise};
}
function mapDispatchToProps(dispatch) {
    return {onGetDepartment:()=>dispatch(getEnterpriseDepartment()),
            onUpdateDepartment: (departmentJson) => dispatch(updateEnterpriseDepartment(departmentJson))};
}
class DepartmentManage extends React.Component{
    constructor(props){
        super(props);
        this.state = { visible:false,modalMode:0,modalTitle:'添加组织',
                       modalValues:{parent:localStorage.getItem('enterprise_name'),status:'enable'}};
        this.handleOnEdit = this.handleOnEdit.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount(){this.props.onGetDepartment();}
    saveFormRef = formRef =>{this.formRef = formRef;}
    showDeleteConfirm = (name)=> {
        confirm({
            title: '重要提示',
            content: '您确定要删除该部门或职位?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk:()=> {
                if(this.props.get_department_result){
                    let department = this.props.get_department_result;
                    for(let i = 0;i < department.length;i++){
                        if(department[i].name === name){
                            department.splice(i,1);
                            this.props.onUpdateDepartment(department);
                            return;
                        }
                        for(let j = 0;j < department[i].children.length;j++){
                            if(department[i].children[j].name === name){
                                department[i].children.splice(j,1);
                                this.props.onUpdateDepartment(department);
                                return;
                            }
                            for(let k = 0;k < department[i].children[j].children.length;k++){
                                if(department[i].children[j].children[k].name === name){
                                    department[i].children[j].children.splice(k,1);
                                    this.props.onUpdateDepartment(department);
                                    return
                                }
                            }
                        }
                    }
                }
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }
    renderSetting(){
        return (<Row>
                    <Col span={2} style={{verticalAlign:'center'}}>
                        <h4>组织名称</h4>
                    </Col>
                    <Col span={6}>
                        <Input placeholder="请输入部门名称" style={{width:'90%'}}/>
                    </Col>
                    <Col span={2}>
                        <Button type="primary" icon="search">Search</Button>
                    </Col>
                </Row>);
    }
    handleSubmit(){
        const { form } = this.formRef.props;
        form.validateFields((err, formValues) => {
            if (err) {return;}
            let department = this.props.get_department_result?this.props.get_department_result:[];
            if(this.state.modalMode){//edit
                for(let i = 0;i < department.length;i++){
                    if(this.state.modalValues.name === department[i].name){
                        department[i].name = formValues.name;
                        break;
                    }
                    for(let j=0;j < department[i].children.length;j++){
                        if(department[i].children[j].name === this.state.modalValues.name){
                            department[i].children[j].name = formValues.name;
                            break;
                        }
                        for(let k = 0;k < department[i].children[j].children.length;k++){
                            if(department[i].children[j].children[k].name === this.state.modalValues.name){
                                department[i].children[j].children[k].name = formValues.name;
                                break;
                            }
                        }
                    }
                }
            }else{
                if(formValues.parent === localStorage.getItem('enterprise_name')) {
                    let maxid = 0;
                    for(let i = 0;i < department.length;i++){
                        if(!department[i].id){continue;}
                        if(maxid < department[i].id){maxid = department[i].id}
                    }
                    department.push({name: formValues.name,children: [],id:maxid+1,feature: ['custom', 'order', 'product', 'leave', 'travel', 'ship', 'expense', 'purchase', 'collection', 'statistic']});
                } else{
                    for (let i = 0; i < department.length; i++) {
                        if (department[i].name === formValues.parent) {
                            let childMaxid = 0;
                            for(let j = 0;j < department[i].children.length;j++){
                                if(!department[i].children[j].id){continue;}
                                if(childMaxid < department[i].children[j].id){childMaxid = department[i].children[j].id;}
                            }
                            department[i].children.push({name:formValues.name,id:childMaxid + 1,children:[]});
                            break;
                        }
                        for(let j = 0;j < department[i].children.length;j++){
                            if(department[i].children[j].name === formValues.parent){
                                let positionMaxid = 0;
                                for(let k = 0;k < department[i].children[j].children.length;k++){
                                    if(!department[i].children[j].children[k].id){continue;}
                                    if(department[i].children[j].children[k].id > positionMaxid){
                                        positionMaxid = department[i].children[j].children[k].id;
                                    }
                                }
                                department[i].children[j].children.push({name:formValues.name,id:positionMaxid+1})
                                break;
                            }
                        }
                    }
                }
            }
            this.props.onUpdateDepartment(department);
            this.setState({visible:false})
            form.resetFields();
        })   
    }
    handleOnEdit(record){
        let status = "enable";
        this.setState({visible:true,modalMode:1,modalTitle:'修改组织',
                        modalValues:{name:record.name,status:status}});
    }
    handleAdd(record){
        this.setState({visible: true,modalMode: 0,modalTitle: '添加组织',
                        modalValues: {parent:record.name,status: 'enable'}
                    });
    }
    renderAction(record){
        if(localStorage.getItem("enterprise_name")===record.name){
            return(<Button type={'primary'} onClick={()=>this.handleAdd(record)}>添加部门</Button>)
        }
        else if(record.children){
            return(<span>
                <Button type={"link"} onClick={()=>this.handleAdd(record)}>添加</Button>
                <Divider type="vertical" />
                <Button type={"link"} onClick={()=>this.handleOnEdit(record)}>编辑</Button>
                <Divider type="vertical" />
                <Button type={"danger"} onClick={()=>this.showDeleteConfirm(record.name)}>删除</Button>
            </span>)
        }
        return(<span>
            <Button type={"link"} onClick={()=>this.handleOnEdit(record)}>编辑</Button>
            <Divider type="vertical" />
            <Button type={"danger"} onClick={()=>this.showDeleteConfirm(record.name)}>删除</Button>
        </span>)
    }
    render(){
        let staffList = this.props.get_staff_list_result?this.props.get_staff_list_result:[]
        const columns = [{title:'组织名称',dataIndex:'name',key:'name'},
                        //{title:'排序',dataIndex: 'index',key: 'index'},
                        {title:'人数',dataIndex:'count',key:'count'},
                        //{title:'状态',dataIndex:'status',key:'status'},
                        {title:'操作',dataIndex:'action',key:'action', render: (text, record) =>this.renderAction(record)}];
        let dataSource = [{name:localStorage.getItem('enterprise_name'),index:0,status:'正常',count:staffList.length,number:0}];
        let departmentList = this.props.get_department_result?this.props.get_department_result:[];
        for(let i = 0;i < departmentList.length;i++){
            let departmentItem = departmentList[i];
            let staffCount = 0
            let children = [];
            for(let t = 0;t < staffList.length;t++){
                if(staffList[t].detail.position === departmentList[i].name){
                    staffCount++;
                }
            }
            if(departmentItem.children){
                for(let j = 0;j < departmentItem.children.length;j++){
                    let childCount = 0
                    let subchildren = []
                    for(let t = 0;t < staffList.length;t++){
                        if(staffList[t].detail.position === departmentItem.name){
                            childCount++;
                        }
                    }
                    for(let k=0;k < departmentItem.children[j].children.length;k++){
                        let subChildCount = 0
                        for(let t = 0;t < staffList.length;t++){
                            if(staffList[t].detail.position === departmentItem.children[j].children[k].name){
                                subChildCount++;
                            }
                        }
                        subchildren.push({
                            name:departmentItem.children[j].children[k].name,
                            count:subChildCount,
                            type:'position',
                        })
                        childCount += subChildCount;
                    }
                    children.push({name:departmentItem.children[j].name,
                                    children:subchildren,
                                    count:childCount,
                                    //status:"正常",
                                    //index:i+j+1,
                                    });
                    staffCount += childCount
                }
            }
            dataSource.push({
                name: departmentItem.name,
                count: staffCount,
                //status: "正常",
                //index: i + 1,
                children:children
                });
        }
        return (<div>
                    <DepartmentForm wrappedComponentRef={this.saveFormRef} visible={this.state.visible} 
                        values={this.state.modalValues} mode={this.state.modalMode} title={this.state.modalTitle}
                        onCancel={()=>{this.setState({visible:false})}} onOk={this.handleSubmit}/>
                    {/*<Row>{this.renderSetting()}</Row>*/}
                    <Row>
                        <Table dataSource={dataSource} columns={columns} fixed={true} rowKey={(record)=>record.name}/>
                    </Row>
                </div>)
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(DepartmentManage);