import {ActionType} from "../constants/actionTypes";

const supplierReducer = (state = {},action)=>{
    switch(action.type){
        case ActionType.ADD_SUPPLIER_START:
            return {...state,add_supplier:false}
        case ActionType.ADD_SUPPLIER_FAILED:
            return {...state,add_supplier:false,add_supplier_errors:action.error?action.payload.error:null}
        case ActionType.ADD_SUPPLIER_SUCCESS:
            return {...state,add_supplier:true,add_supplier_result:action.payload}

        case ActionType.GET_SUPPLIER_START:
            return {...state,get_supplier:false}
        case ActionType.GET_SUPPLIER_SUCCESS:
            return {...state,get_supplier:false,get_supplier_result:action.payload}
        case ActionType.GET_SUPPLIER_FAILED:
            return {...state,get_supplier:false,get_supplier_errors:action.error?action.payload.error:null}

        case ActionType.UPDATE_SUPPLIER_START:
            return {...state,update_supplier:false}
        case ActionType.UPDATE_SUPPLIER_FAILED:
            return {...state,update_supplier:false,update_supplier_errors:action.error?action.payload.error:null}
        case ActionType.UPDATE_SUPPLIER_SUCCESS:
            return {...state,update_supplier:true,update_supplier_result:action.payload}

        case ActionType.DELETE_SUPPLIER_START:
            return {...state,delete_supplier:false}
        case ActionType.DELETE_SUPPLIER_FAILED:
            return {...state,delete_supplier:false,delete_supplier_errors:action.error?action.payload.error:null}
        case ActionType.DELETE_SUPPLIER_SUCCESS:
            return {...state,delete_supplier:true,delete_supplier_result:action.payload}
            
        default:
            return {...state};
    }
}
export default supplierReducer;