import React from 'react';
import {Button,Form,Input,Row, Col,Layout,message,Modal,Checkbox,Tabs} from 'antd';
import {UserOutlined,LockOutlined,EyeInvisibleOutlined,BankOutlined,EyeOutlined,ArrowLeftOutlined,MailOutlined,MobileOutlined} from '@ant-design/icons';
import {sendEmailVerifyCode,sendPhoneRegisterVerifyCode} from "../actions/commonDBTool";
import {register} from "../actions/userDBTool";
import {connect} from "react-redux";
import FrameHeader from "./header";
import { delFriAndEndSpaceWord } from '../utils/common';

const {Content,Footer} = Layout;
const { TabPane } = Tabs;

function mapStateToProps(state){
    return {...state.auth};
}
function mapDispatchToProps(dispatch) {
    return {onRegister:(postData)=>dispatch(register(postData))}
}
const RegisterForm = Form.create({name:'register'})(
    class extends React.Component{
        constructor(props) {
            super(props);
            this.state = {  maxTime:120,agree:false,licenseVisible:false,tabKey:'phone',
                            verifyButtonStatus:true,verifyButtonText:'获取验证码'};
            this.sendEmailRegisterCode = this.sendEmailRegisterCode.bind(this);
            this.sendPhoneRegisterCode = this.sendPhoneRegisterCode.bind(this);
        }
        handleConfirmPassword = (rule, value, callback) => {
            const { getFieldValue } = this.props.form
            if (value && value !== getFieldValue('password')) {
                callback('两次输入不一致！')
            }
            // Note: 必须总是返回一个 callback，否则 validateFieldsAndScroll 无法响应
            callback()
        }
        countDown=()=>{
            if (this.state.maxTime === 0) {
                this.setState({maxTime:120,
                            verifyButtonStatus:true,
                            verifyButtonText:'获取验证码'});
            } else {
                let maxTime = this.state.maxTime;
                this.setState({maxTime:--maxTime,
                            verifyButtonStatus:false,
                            verifyButtonText:maxTime + "秒后重新获取"});
                setTimeout(this.countDown, 1000);
            }
        }
        sendEmailRegisterCode(){
            this.props.form.validateFields((err, fromValues) => {
                console.log(fromValues.email)
                if(0>= fromValues.email.length){
                    return ;
                }
                sendEmailVerifyCode(fromValues.email,"【多易CRM】邮箱验证码","【多易CRM】提醒您,您的注册验证码为:");
                this.countDown();
            })
        }
        sendPhoneRegisterCode(){
            this.props.form.validateFields((err, fromValues) => {
                console.log(fromValues.phone)
                if(0>= fromValues.phone.length){
                    return ;
                }
                sendPhoneRegisterVerifyCode(fromValues.phone);
                this.countDown();
            })
        }
        onOk=()=>{this.setState({licenseVisible:false});}
        onCancel=()=>{this.setState({licenseVisible:false});}
        onAgreeClick=(isChecked)=>{
            this.setState({agree:isChecked})
        }
        renderLicense(){
            return (<Modal title={"软件注册协议、服务条款以及隐私政策"} visible={this.state.licenseVisible}
                            onOk={this.onOk} onCancel={this.onCancel} okText={"确定"} cancelText={"取消"}>
                        <Content style={{height:'65vh',overflow:'auto'}}>
                        <h2>一、总则</h2>
                        <h3>
                            此份协议是注册用户接受《多易CRM--客户关系管理系统》网站（以下简称本站/本应用）产品和服务时适用的通用条款。
                            因此，请您在注册成为本站用户前或接受本站的产品和服务之前，请您详细地阅读本注册用户协议的所有内容。
                        </h3>
                        <h3>1、注册用户了解并同意：</h3>
                        <p>
                            ①只要注册用户勾选“同意”按钮并完成注册，注册用户就已接受了本注册用户协议及本站公布的各项服务规则（包括填写实名的联系方式等等），
                            并愿意受其约束。如果发生纠纷，注册用户不得以未仔细阅读为由进行抗辩。
                        </p>
                        <p>
                            ②随着市场经营情况的变化，本站有权随时更改本注册用户协议及相关服务规则。
                            修改本注册用户协议时，本站将于相关页面公告修改的事实，有权不对注册用户进行个别通知。
                            注册用户应该在每次登录前查询网站的相关公告，以了解注册用户协议及其他服务规则的变化。
                        </p>
                        <h3>2、若注册用户不同意本注册用户协议或相关服务规则，或者不同意本站作出的修改，
                            注册用户可以主动停止使用本站提供的产品和服务，如果在本站修改协议或服务规则后，注册用户仍继续使用本站提供的产品和服务，
                            即表示注册用户同意本站对本注册用户协议及相关服务规则所做的所有修改。
                            由于注册用户在注册用户协议变更后因未熟悉公告规定而引起的损失，本站将不会承担任何责任
                        </h3>
                        <h3>
                            3、本站的各项电子服务的所有权和运作权归本站。本站提供的服务将完全按照其发布的服务条款和操作规则严格执行。
                            注册用户必须完全同意所有服务条款并完成注册程序，才能成为本站的注册用户。
                            注册用户确认：本协议条款是处理双方权利义务的当然约定依据，除非违反国家强制性法律，否则始终有效。
                        </h3>
                        <h2>二、服务简介</h2>
                        <h3>1、本站运用自己的操作系统通过国际互联网络为注册用户提供网络服务。
                            同时，注册用户必须：自行配备上网的所需设备，包括个人电脑、调制解调器或其他必备上网装置。
                            自行负担个人上网所支付的与此服务有关的电话费用、网络费用。
                        </h3>
                        <h3>
                            2、基于本站所提供的网络服务的重要性，注册用户应同意：提供详尽、准确的个人资料。不断更新注册资料，符合及时、详尽、准确的要求。
                        </h3>
                        <h3>
                            3、本站对注册用户的电子邮件、手机号等隐私资料进行保护，承诺不会在未获得注册用户许可的情况下擅自将注册用户的个人资料信息出租或出售给任何第三方，
                            但以下情况除外：注册用户同意让第三方共享资料；注册用户同意公开其个人资料，享受为其提供的产品和服务；本站需要听从法庭传票、法律命令或遵循法律程序；
                            本站发现注册用户违反了本站服务条款或本站其它使用规定。
                        </h3>
                        <h3>
                            4、关于注册用户隐私的具体协议以本站的隐私声明为准。如果注册用户提供的资料包含有不正确的信息，本站保留结束注册用户使用网络服务资格的权利。
                        </h3>
                        <h2>三、帐户密码和安全性</h2>
                        <h3>
                            注册用户一旦注册成功，成为本站的合法的注册用户。您可随时根据需要改变您的密码。注册用户将对注册用户名和密码安全负全部责任。
                            另外，每个注册用户都要对以其注册用户名进行的所有活动和事件负全责。注册用户若发现任何非法使用注册用户帐户或存在安全漏洞的情况，请立即通告本站。
                        </h3>
                        <h2>四、拒绝提供担保</h2>
                        <h3>
                            注册用户个人对网络服务的使用承担风险。本站对此不作任何类型的担保，不论是明确的或隐含的，但是不对商业性的隐含担保、特定目的和不违反规定的适当担保作限制。本站不担保服务一定能满足注册用户的要求，也不担保服务不会受中断，对服务的及时性，安全性，出错发生都不作担保。
                        </h3>
                        <h2>五、有限责任</h2>
                        <h3>
                            本站对任何直接、间接、偶然、特殊及继起的损害不负责任，这些损害可能来自：不正当使用网络服务，在网上购买商品或进行同类型服务，在网上进行交易，非法使用网络服务或注册用户传送的信息有所变动。这些行为都有可能会导致本站的形象受损，所以本站事先提出这种损害的可能性。
                        </h3>
                        <h2>六、通告</h2>
                        <h3>所有发给注册用户的通告都可通过重要页面的公告或电子邮件或常规的信件传送。本站的活动信息也将定期通过页面公告及电子邮件方式向注册用户发送。注册用户协议条款的修改、服务变更、或其它重要事件的通告会以电子邮箱或者短信进行通知。</h3>

                        <h2>七、注册用户的建议奖励</h2>

                        <h3>注册用户在他们发表的一些良好建议以及一些比较有价值的策划方案时，本站愿意展示用户的构想落于实现，这其中本站会对一些比较好的注册用户反馈信息进行不等的产品奖励或者是积分奖励，但如出现用户策划与广告销售商之间的矛盾本站不承担任何责任。</h3>

                        <h2>八、责任限制</h2>

                        <h3>1、如因不可抗力或其他本站无法控制的原因使本站销售系统崩溃或无法正常使用导致网上交易无法完成或丢失有关的信息、记录等，本站不承担责任。但是本站会尽可能合理地协助处理善后事宜，并努力使客户免受经济损失。</h3>

                        <h3>2、除了本站的使用条件中规定的其它限制和除外情况之外，在中国法律法规所允许的限度内，对于因交易而引起的或与之有关的任何直接的、间接的、特殊的、附带的、后果性的或惩罚性的损害，或任何其它性质的损害，本站、本站的董事、管理人员、雇员、代理或其它代表在任何情况下都不承担责任。</h3>

                        <h2>九、法律管辖和适用</h2>

                        <h3>1、本协议的订立、执行和解释及争议的解决均应适用中国法律。</h3>

                        <h3>2、如发生本站服务条款与中国法律相抵触时，则这些条款将完全按法律规定重新解释，而其它合法条款则依旧保持对注册用户产生法律效力和影响。</h3>

                        <h3>3、本协议的规定是可分割的，如本协议任何规定被裁定为无效或不可执行，该规定可被删除而其余条款应予以执行。</h3>

                        <h3>4、如双方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，任何一方均可向本站所在地的____________人民法院提起诉讼。</h3>

                        <h2>十、其他规定</h2>

                        <h3>1、如本用户协议中的任何内容无论因何种原因完全或部分无效或不具有执行力，本用户协议的其余内容仍应有效并且对协议各方有约束力。</h3>

                        <h3>2、本用户协议中的标题仅为方便而设，不具法律或契约效果。</h3>
                    </Content>
                </Modal>);
        }
        renderEmailRegister(){
            const layout = {
                labelCol: { xs: { span: 24 }, sm: { span: 12 }, md: { span: 8 }, lg: { span: 8 } },
                wrapperCol: { xs: { span: 24 }, sm: { span: 12 }, md: { span: 12 }, lg: { span: 12 } }
            }
            const tailLayout = {
                wrapperCol: {
                  offset: 8,
                  span: 16,
                },
              };
            let { getFieldDecorator } = this.props.form;
            return (<Form>
                        <Form.Item>
                            {getFieldDecorator('enterpriseName',{rules:[{required:true,message:'请输入企业/公司的名称'}]})
                                (<Input size={'large'} style={{width:'75%'}} minLength={2} maxLength={16} prefix={<BankOutlined type="home"/>} placeholder={'请输入企业/公司的名称'}/>)}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator('name',{rules:[{required:true,message: '联系人不能为空'},{max:12,message:'用户名超出长度！'}]})
                            (<Input size={'large'} style={{width:'75%'}} prefix={<UserOutlined/>} placeholder="请输入联系人的名称，中英文均可"/>)}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator('email',{rules:[{required:true,message:'请输入您的电子邮箱地址'},{type:'email',message: "请输入正确的邮箱格式"}]})
                                (<Input size={'large'} style={{width:'75%'}} prefix={<MailOutlined/>} placeholder="请输入您的电子邮箱地址"/>)}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator('password',{rules:[{required:true,message:'请输入密码'}, 
                                {max:16,message: '最长不能超过16位'},{min:6,message: '最短不能低于6位'}]})(
                                <Input size={'large'} style={{width:'75%'}} prefix={<LockOutlined/>} placeholder="请输入密码" type={this.state.showPass?"text":"password"} addonAfter={this.state.showPass?
                                    <EyeInvisibleOutlined onClick={()=>{this.setState({showPass:!this.state.showPass})}}/>:
                                    <EyeOutlined onClick={()=>{this.setState({showPass:!this.state.showPass})}}/>}/>)}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator('passwordConfirm',{rules:[{required:true,message:'请再输入一次密码'},
                                {max:16,message: '最长不能超过16位'},{min:6,message: '最短不能低于6位'},{validator: this.handleConfirmPassword,validateStatus:"warning"}]})
                                (<Input size={'large'} style={{width:'75%'}} prefix={<LockOutlined/>} placeholder="请再输入一次密码" type={this.state.showPass?"text":"password"} addonAfter={this.state.showPass?
                                    <EyeInvisibleOutlined onClick={()=>{this.setState({showPass:!this.state.showPass})}}/>:
                                    <EyeOutlined onClick={()=>{this.setState({showPass:!this.state.showPass})}}/>}/>)}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator('verifyCode',{rules:[{required:true,message:'请输入邮箱验证码!'}]})
                                (<Input size={'large'} style={{width:'48%'}} placeholder="请输入邮箱验证码"/>)}
                                {<Button size={'large'} type={'primary'} onClick={this.sendEmailRegisterCode} disabled={!this.state.verifyButtonStatus}>{this.state.verifyButtonText}</Button>}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator('agree',{rules:[{required:true,message:'请先勾选同意服务条款和隐私政策!'}]})(
                                <Checkbox>同意 <Button type="link" onClick={()=>{this.setState({licenseVisible:true})}}>服务条款和隐私政策</Button></Checkbox>
                            )}
                        </Form.Item>
                        <Form.Item>
                            <Button size='large' type={"primary"} style={{width:'75%'}} onClick={this.props.onOk} loading={this.props.registerLoading}>注册</Button>
                        </Form.Item>
                    </Form>)
        }
        renderPhoneRegister(){
           /* const layout = {
                labelCol: { xs: { span: 24 }, sm: { span: 12 }, md: { span: 8 }, lg: { span: 8 } },
                wrapperCol: { xs: { span: 18 }, sm: { span: 12 }, md: { span: 12 }, lg: { span: 12 } }
            }*/
            let { getFieldDecorator } = this.props.form;
            return ( <Form >
                        <Form.Item>
                            {getFieldDecorator('enterpriseName',{rules:[{required:true,message:'请输入企业/公司的名称'}]})
                                (<Input size={'large'} style={{width:'75%'}} minLength={2} maxLength={16} prefix={<BankOutlined type="home"/>} placeholder={'请输入企业/公司的名称'}/>)}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator('name',{rules:[{required:true,message: '联系人不能为空'},{max:12,message:'用户名超出长度！'}]})
                            (<Input size={'large'} style={{width:'75%'}} prefix={<UserOutlined/>} placeholder="请输入联系人的名称，中英文均可"/>)}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator('phone',{rules:[{required:true,message:"请输入您的手机号码"},{pattern: new RegExp(/(1[3|4|5|6|7|8|9][\d]{9})/,"g"),message:'请输入正确的手机格式'}]})
                                (<Input size={'large'} style={{width:'75%'}} prefix={<MobileOutlined />} placeholder="请输入您的手机号码"/>)}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator('password',{rules:[{required:true,message:'请输入密码'},{max:16,message: '最长不能超过16位'},{min:6,message: '最短不能低于6位'}]})(
                                <Input size={'large'} style={{width:'75%'}} prefix={<LockOutlined/>} placeholder="请输入密码"type={this.state.showPass?"text":"password"} addonAfter={this.state.showPass?
                                    <EyeInvisibleOutlined onClick={()=>{this.setState({showPass:!this.state.showPass})}}/>:
                                    <EyeOutlined onClick={()=>{this.setState({showPass:!this.state.showPass})}}/>}/>)}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator('passwordConfirm',{rules:[{required:true,message:'请再输入一次密码'},{max:16,message: '最长不能超过16位'},{min:6,message: '最短不能低于6位'},
                                {validator: this.handleConfirmPassword,validateStatus:"warning"}]})
                                (<Input size={'large'} style={{width:'75%'}} prefix={<LockOutlined/>} placeholder="请再输入一次密码" type={this.state.showPass?"text":"password"} addonAfter={this.state.showPass?
                                    <EyeInvisibleOutlined onClick={()=>{this.setState({showPass:!this.state.showPass})}}/>:
                                    <EyeOutlined onClick={()=>{this.setState({showPass:!this.state.showPass})}}/>}/>)}
                        </Form.Item>
                        <Form.Item>
                                {getFieldDecorator('verifyCode',{rules:[{required:true,message:'请输入正确的验证码!'}]})
                                (<Input size='large' style={{width:'48%'}} placeholder="请输入短信验证码"/>)}
                                <Button size='large' type={'primary'} onClick={this.sendPhoneRegisterCode} disabled={!this.state.verifyButtonStatus}>{this.state.verifyButtonText}</Button>
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator('agree',{rules:[{required:true,message:'请先勾选同意服务条款和隐私政策!'}]})
                            (<Checkbox>
                                同意 <Button type="link" onClick={()=>{this.setState({licenseVisible:true})}}>服务条款和隐私政策</Button>
                            </Checkbox>)}
                        </Form.Item>
                        <Form.Item>
                            <Button size='large' type={"primary"} style={{width:'75%'}} onClick={this.props.onOk} loading={this.props.registerLoading}>注册</Button>
                        </Form.Item>
                    </Form>)
        }
        render(){
            return (<Row>
                        {this.renderLicense()}
                        <Tabs defaultActiveKey={"phone"} onChange={(value)=>{this.props.tabOnChange(value);this.setState({tabKey:value})}}>
                            <TabPane key={"phone"} tab={"通过手机注册"}>
                                {"phone" === this.state.tabKey?this.renderPhoneRegister():null}
                            </TabPane>
                            <TabPane key={"email"} tab={"通过邮箱注册"}>
                                {"email" === this.state.tabKey?this.renderEmailRegister():null}
                            </TabPane>
                        </Tabs>
                    </Row>);
        }
    }
)
class Register extends React.Component{
    constructor(props){
        super(props);
        this.state = {maxTime:5,registerLoading:false,tabKey:'phone'};
        this.handleSubmit = this.handleSubmit.bind(this);
        this.countDown = this.countDown.bind(this);
    }
    countDown(){
        if (this.state.maxTime === 0) {
            this.setState({maxTime:5,registerLoading:false});
        } else {
            let maxTime = this.state.maxTime;
            this.setState({maxTime:--maxTime,registerLoading:true});
            setTimeout(this.countDown, 1000);
        }
    }
    componentDidMount(){
        document.title = "多易CRM--注册";
        this.setState({registerLoading:false})
    }
    handleSubmit(){
        const { form } = this.formRef.props;
        form.validateFields((err, fromValues) => {
            if (err) {return;}
            if(!fromValues.agree){
                message.error("未同意服务条款和隐私政策！");
                return;
            }
            console.log(delFriAndEndSpaceWord(fromValues.name))
            if(0 === delFriAndEndSpaceWord(fromValues.name).length){
                message.error("用户名输入错误");
                return ;
            }
            this.setState({registerLoading:true});
            let current_time = new Date().getTime();
            let postData = {name:fromValues.name,
                            password:fromValues.password,
                            enterprise_name:fromValues.enterpriseName,
                            login_time:current_time,
                            create_time:current_time
                        };
            if("email" === this.state.tabKey){
                postData['account'] = delFriAndEndSpaceWord(fromValues.email);
                postData['email_verify_code'] = fromValues.verifyCode
            }
            else{
                postData['account'] = delFriAndEndSpaceWord(fromValues.phone);
                postData['phone_verify_code'] = fromValues.verifyCode
            }
            this.props.onRegister(postData);
        });
        this.countDown();
    }
    saveFormRef = formRef =>{this.formRef = formRef;}
    renderRegisterWindow(){
        return (<Row style={{textAlign:"center",marginTop:'5vh',paddingTop:'2vh',paddingBottom:'2vh', background:'#FaFaFa40',minWidth:'300px'}}>
                    <Row><h2 style={{color:'#40a9ff'}}>欢迎注册企业账号</h2></Row>
                    <RegisterForm tabOnChange={(value)=>{this.setState({tabKey:value});}} onOk={this.handleSubmit} 
                        wrappedComponentRef={this.saveFormRef} registerLoading={this.state.registerLoading}/>
                    <Button type="link" onClick={()=>{this.props.history.push('/login');}}><ArrowLeftOutlined/>已有帐户，前往登录</Button>
                </Row>);
    }
    render(){
    return (<Layout breakpoint='lg'>
                <Content style={{minHeight:'100vh',backgroundImage:"url("+require('../assets/images/register_1.jpg') +")"}}>
                <FrameHeader/>
                <Row breakpoint='lg'>
                    <Col lg={{span:8, offset:2}} xs={{span:20,offset:2}}>
                        {this.renderRegisterWindow()}
                    </Col>
                </Row>
            </Content>
            <Footer className='footer'>
                <span>©2020 Created by lingduo copyright</span>
            </Footer>
    </Layout>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Register);