import $ from 'jquery'
//import axios from "axios";
import {message,Modal, Table} from "antd";
import React from "react";
import * as Service from '../config/service.json';
import {ActionType} from "../constants/actionTypes";
import {preHandle,checkAccountStatus} from './commonDBTool';
import { account2Name } from '../utils/accountUtils';
const {info} = Modal;
export function getAllCustom(postData) {
    let url = Service.dbServer + Service.apiVersion + "/custom/batchGet";
    return dispatch=>{
        dispatch({type:ActionType.GET_CUSTOM_BY_USER_START});
        $.ajax(preHandle(url),{
            method:'GET',
            contentType:'application/json; charset=utf-8',
            dataType:'json',
            data:postData
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            let result = response.result?response.result:[]
            for(let i = 0;i < result.length;i++){
                result[i].detail = JSON.parse(result[i].detail);
            }
            if(postData && postData.isCommon){
                dispatch({type:ActionType.GET_COMMON_CUSTOM_SUCCESS,payload:result});
            }
            else if(postData && postData.isTrash){
                dispatch({type:ActionType.GET_CUSTOM_DEL_SUCCESS,payload:response.result});
            }
            else{
                dispatch({type:ActionType.GET_CUSTOM_BY_USER_SUCCESS,payload:{custom:result,count:response.count}});
            }
        }).catch(function (error) {
            dispatch({type:ActionType.GET_CUSTOM_BY_USER_FAILED,payload:error});
    })};
}
export function getCustomByUuid(uuid){
    let url = Service.dbServer + Service.apiVersion + "/custom/"+uuid;
    return dispatch=>{
        $.ajax(preHandle(url),{
            method:'GET',
            contentType:'application/json; charset=utf-8',
            dataType:'json',
            data:{custom_uuid:uuid}
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            let result = response.result
            console.log(result)
            return result.name
        }).catch(function (error) {
        })};
}
/*export function getAllCommonCustom(pageStart=0,pageSize=50){
    let url = Service.dbServer + Service.apiVersion + "/custom/getCommonCustom";
    return dispatch=>{
        dispatch({type:ActionType.GET_COMMON_CUSTOM_START});
        $.ajax(preHandle(url),{
            method:'GET',
            contentType:'application/json; charset=utf-8',
            dataType:'json',
            data:{pageStart:pageStart,pageSize:pageSize}
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            let result = response.result?response.result:[]
            for(let i = 0;i < result.length;i++){
                result[i].detail = JSON.parse(result[i].detail);
            }
            dispatch({type:ActionType.GET_COMMON_CUSTOM_SUCCESS,payload:result});
        }).catch(function (error) {
            dispatch({type:ActionType.GET_COMMON_CUSTOM_FAILED,payload:error});
        })
    }
}
export function getAllCustomDel(pageStart=0,pageSize=50) {
    return dispatch=>{
        let url = Service.dbServer + Service.apiVersion + "/custom/getDelByUser" ;
        dispatch({type:ActionType.GET_CUSTOM_DEL_START});
        $.ajax(preHandle(url),{
            method:'GET',
            contentType:'application/json; charset=utf-8',
            dataType:'json',
            data:{pageStart:pageStart,pageSize:pageSize}
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            let result = response.result?response.result:[]
            for(let i = 0;i < result.length;i++){
                result[i].detail = JSON.parse(result[i].detail);
            }
            dispatch({type:ActionType.GET_CUSTOM_DEL_SUCCESS,payload:response.result});
        }).catch(function (error) {
            dispatch({type:ActionType.GET_CUSTOM_DEL_FAILED,payload:error});
        });
    }
}*/
export function batchAddCustom(customList) {
    let url = Service.dbServer + Service.apiVersion + "/custom/batchUpdate";
    return dispatch=>{
        dispatch({type:ActionType.ADD_CUSTOM_START});
        $.ajax(preHandle(url),{
            method:'POST',
            contentType:'application/json; charset=utf-8',
            dataType:'json',
            data:{'custom_list':JSON.stringify(customList)}
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            if(200 === response.code){
                let result = response.result;
                let repeatList = result.repeatList;
                let dataSource = [];
                const showColumns = [
                    {title:'客户姓名',dataIndex:'name'},
                    {title:'手机号码',dataIndex:'phone'},
                    {title:'座机',dataIndex:'tel'},
                    {title:'地址',dataIndex:'address'},
                    {title:'客户等级',dataIndex:'level'},
                    {title:'客户状态',dataIndex:'status'}
                ];
                for(let i = 0;i < repeatList.length;i++){
                    let detail = JSON.parse(repeatList[i].detail);
                    let manager = account2Name(detail.manager_account);
                    let follower = account2Name(detail.follower_account);
                    let creator = account2Name(repeatList[i].creator_account);
                    dataSource.push({
                        uuid:repeatList[i].uuid,
                        name: repeatList[i].name,
                        level:repeatList[i].level,
                        type: repeatList[i].type,
                        source:detail.source,
                        status: repeatList[i].status,
                        city:detail.city,
                        tel:detail.tel,
                        phone: detail.phone,
                        address: detail.address,
                        manager: manager,
                        follower: follower,
                        creator: creator,
                        createTime:repeatList[i].create_time,
                        tags:detail.tags?detail.tags:""
                    });
                }
                let title = '添加成功'+result.successCount+'位客户';
                let content = <div></div>
                if(result.repeatList.length){
                    title += '发现系统中已存在'+repeatList.length+'客户'
                    content = <Table size='small' scroll={{y:'480px'}} rowKey={record=>record.uuid} columns={showColumns} dataSource={dataSource} pagination={false}/> 
                }
                return info({
                    width:'800px',
                    height:'600px',
                    title:title,
                    content: content,
                    okText: '确定',
                    okType: 'danger',
                    onOk:()=> {
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                })
            }
            else {
                return message.error(response.message);
            }
            
        }).catch(function (error) {
            message.error("批量添加客户失败!");
            dispatch({type:ActionType.ADD_CUSTOM_FAILED,payload:error})
        })
    }
}
export function addCustom(postData){
    let url = Service.dbServer + Service.apiVersion + "/custom/add" ;
    return dispatch=>{
        dispatch({type:ActionType.ADD_CUSTOM_START});
        $.ajax(preHandle(url),{
            method:'POST',
            contentType:'application/json; charset=utf-8',
            dataType:'json',
            data:JSON.stringify(postData)
        }).then((response)=>{
            if(200 !== response.code){
                let result = response.result;
                let detail = JSON.parse(result.detail);
                let manager = account2Name(detail.manager_account);
                let follower = account2Name(detail.follower_account);
                let creator = account2Name(result.creator_account);
                let dataSource = [];
                console.log(detail)
                const showColumns = [
                    {title:'客户姓名',dataIndex:'name'},
                    {title:'手机号码',dataIndex:'phone'},
                    {title:'座机',dataIndex:'tel'},
                    {title:'地址',dataIndex:'address'},
                    {title:'客户等级',dataIndex:'level'},
                    {title:'客户状态',dataIndex:'status'}
                ];
                dataSource.push({
                    uuid:result.uuid,
                    name: result.name,
                    level:result.level,
                    type: result.type,
                    source:detail.source,
                    status: result.status,
                    city:detail.city,
                    tel:detail.tel,
                    phone: detail.phone,
                    address: detail.address,
                    manager: manager,
                    follower: follower,
                    creator: creator,
                    createTime:result.create_time,
                    tags:detail.tags?detail.tags:""
                });
                return info({
                    width:'800px',
                    title:'添加失败！发现系统中已存在客户',
                    content: <div><Table size='small' rowKey={record=>record.uuid} columns={showColumns} dataSource={dataSource} pagination={false}/></div>,
                    okText: '确定',
                    okType: 'danger',
                    onOk:()=> {
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                })
            }
            message.info("添加客户成功!");
            dispatch({type:ActionType.ADD_CUSTOM_SUCCESS,payload:response.result});
            dispatch(getAllCustom());
        }).catch(function (error) {
            message.info("添加客户失败!");
            dispatch({type:ActionType.ADD_CUSTOM_FAILED,payload:error})
        });
    };
}
export function updateCustom(postData) {
    console.log(postData)
    let url = Service.dbServer + Service.apiVersion + "/custom/"+postData.uuid+"/update" ;
    return dispatch=>{
        dispatch({type:ActionType.UPDATE_CUSTOM_START});
        $.ajax(preHandle(url),{
            method:'POST',
            contentType:'application/json; charset=utf-8',
            dataType:'json',
            data:JSON.stringify(postData)
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            message.info("修改成功！");
            dispatch({type:ActionType.UPDATE_CUSTOM_SUCCESS,payload:response.result});
            dispatch(getAllCustom());
        }).catch(function (error) {
            message.error("修改失败！");
            dispatch({type:ActionType.UPDATE_CUSTOM_FAILED,payload:error});
        })
    }
}
export function batchUpdateCustom(postDataList){
    let url = Service.dbServer + Service.apiVersion + "/custom/batchUpdate" ;
    return dispatch=>{
        dispatch({type:ActionType.UPDATE_CUSTOM_START});
        $.ajax(preHandle(url),{
            method:'POST',
            dataType:'json',
            data:postDataList
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            message.info("修改成功！");
            dispatch({type:ActionType.UPDATE_CUSTOM_SUCCESS,payload:response.result});
            dispatch(getAllCustom());
        }).catch(function (error) {
            message.error("修改失败！");
            dispatch({type:ActionType.UPDATE_CUSTOM_FAILED,payload:error});
        })
    }
}/*
export function addCustomDel(custom_uuid) {
    let url = Service.dbServer + Service.apiVersion + "/custom/addDel";
    return dispatch=>{
        dispatch({type:ActionType.DELETE_CUSTOM_START});
        $.ajax(preHandle(url),{method:'POST',
            dataType:'json',
            data:{custom_uuid:custom_uuid}
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            if(200 === response.code){
                message.info("删除客户成功!")
                dispatch({type:ActionType.DELETE_CUSTOM_SUCCESS,payload:response.result});
                dispatch(getAllCustom());
                dispatch(getAllCommonCustom())
            }
            else{
                message.error(response.message);
                dispatch({type:ActionType.DELETE_CUSTOM_FAILED,payload:response.result});
            }
        }).catch(function (error) {
            message.info("删除客户失败!")
            dispatch({type:ActionType.DELETE_CUSTOM_FAILED,payload:error});
        })
    }
}
export function batchAddCustomDel(uuidList){
    let url = Service.dbServer + Service.apiVersion + "/custom/batchAddDel" ;
    return dispatch=>{
        $.ajax(preHandle(url),{
            method:'POST',
            dataType:'json',
            data:({'custom_list':JSON.stringify(uuidList)})
        }).then(response=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            if(200 === response.code){
                message.info("删除成功!");
                dispatch(getAllCustom());
            }
            else{
                message.error(response.message);
            }
        }).catch(function(error){
            console.log(error);
        })
    }
}
export function restoreCustom(uuid) {
    let url = Service.dbServer + Service.apiVersion + "/custom/restore" ;
    return dispatch=>{
        dispatch({type:ActionType.CUSTOM_RESTORE_START});
        $.ajax(preHandle(url),{
            method:'POST',
            dataType:'json',
            data:({'custom_uuid':uuid})
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            if(200 === response.code){
                message.info("客户恢复成功!");
                dispatch({type:ActionType.CUSTOM_RESTORE_SUCCESS,payload:response.result});
                dispatch(getAllCustomDel());
            }
            else{
                message.error(response.message);
                dispatch({type:ActionType.CUSTOM_RESTORE_FAILED,payload:response.result});
            }
        }).catch(function (error) {
            message.info("恢复失败!");
            dispatch({type:ActionType.CUSTOM_RESTORE_FAILED,payload:error});
        })
    }
}
export function batchRestoreCustom(uuidList){
    console.log(uuidList);
    let url = Service.dbServer + Service.apiVersion + "/custom/batchRestore" ;
    return dispatch=>{
        $.ajax(preHandle(url),{
            method:'POST',
            data:({'custom_list':JSON.stringify(uuidList)})
        }).then(response=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            if(200 === response.code){
                message.info("还原成功！");
                dispatch(getAllCustomDel());
            }
        }).catch(function(error){
            console.log(error);
        })
    }
}*/
export function deleteCustom(uuid) {
    let url = Service.dbServer + Service.apiVersion + "/custom/del";
    return dispatch=>{
        dispatch({type:ActionType.DELETE_CUSTOM_START});
        $.ajax(preHandle(url),{
            method:'delete',
            //contentType:'application/json; charset=utf-8',
            dataType:'json',
            data:{uuid:uuid}
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            message.info("删除客户成功!")
            dispatch({type:ActionType.DELETE_CUSTOM_SUCCESS,payload:response.result});
            dispatch(getAllCustom({isTrash:true}));
        }).catch(function (error) {
            console.log(error);
            message.info("删除客户失败!")
            dispatch({type:ActionType.DELETE_CUSTOM_FAILED,payload:error});
        })
    }
}
export function batchDelCustom(uuidList){
    let url = Service.dbServer + Service.apiVersion + "/custom/batchDel" ;
    return dispatch=>{
        $.ajax(preHandle(url),{
            method:'POST',
            dataType:'json',
            data:({'uuid_list':JSON.stringify(uuidList)})
        }).then(response=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            if(200 === response.code){
                message.info("清空完成!");
                dispatch(getAllCustom({isTrash:true}));
            }
        }).catch(function(error){
            console.log(error);
        })
    }
}