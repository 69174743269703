import React from 'react';
import {Row,Tabs} from 'antd';
import OrderTable from '../../controls/OrderTable';
import {ORDER_STATUS_CLOSED, USER_LEVEL_MEMBER} from '../../../constants/constant';
import {connect} from "react-redux";
import { isToday } from '../../../utils/common';

const { TabPane } = Tabs;
function mapStateToProps(status) {
    return {...status.order};
}
function mapDispatchToProps(dispatch){
    return {}
}
class ClosedOrder extends React.Component {
    constructor(props){
        super(props);
        this.state = {};
    }
    componentDidMount() {}
    renderFailedOrder(){
        let myFailedOrderDS = [];
        let allFailedOrderDS = [];
        let todayFailedORderDS = [];
        if (this.props.get_order_result){
            let result = this.props.get_order_result;
            result.sort(function (a,b) { return Date.parse(b.create_time) - Date.parse(a.create_time);})
            for(let i = 0;i < result.length;i++){
                if(ORDER_STATUS_CLOSED !== result[i].status){continue;}
                let detail = (result[i].detail);
                allFailedOrderDS.push(result[i]);
                if(detail.manager_account && -1 !== detail.manager_account.indexOf(localStorage.getItem('account'))){
                    myFailedOrderDS.push(result[i]);
                }
                if(isToday(detail.end_time)){
                    todayFailedORderDS.push(result[i]);
                }
            }
        }
        const orderEditable = USER_LEVEL_MEMBER < Number(localStorage.getItem('level'))?true:false
        const isManager = USER_LEVEL_MEMBER < Number(localStorage.getItem('level'))?true:false
        return(<Tabs defaultActiveKey={"2"} type='card'>
        {isManager?(
            <TabPane key="1" tab="所有失效订单">
                <OrderTable dataSource={allFailedOrderDS} exportEnable={orderEditable} customColumnsEnable={true} tableKey={'crm/close_order'} editEnable={orderEditable} batchDelEnable={orderEditable}/>
            </TabPane>):null}
            <TabPane key="2" tab="我的失效订单">
                <OrderTable dataSource={myFailedOrderDS} exportEnable={orderEditable} customColumnsEnable={true} tableKey={'crm/close_order'} editEnable={orderEditable} batchDelEnable={orderEditable}/>
            </TabPane>
            <TabPane key="3" tab="今日失效订单">
                <OrderTable dataSource={todayFailedORderDS} exportEnable={orderEditable} customColumnsEnable={true} tableKey={'crm/close_order'} editEnable={orderEditable} batchDelEnable={orderEditable}/>
            </TabPane>
        </Tabs>);
    }
    render(){
        return (<Row>{this.renderFailedOrder()}</Row>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(ClosedOrder);