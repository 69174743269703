import { DislikeFilled, DislikeOutlined, LikeFilled, LikeOutlined } from '@ant-design/icons';
import { Avatar, Comment, Tooltip,Form,Input,Button,List } from 'antd';
import moment from 'moment';
import React, { createElement } from 'react';
import * as Service from '../../config/service.json';

const { TextArea } = Input;

const Editor = ({ onChange, onSubmit, submitting, value }) => (
    <>
      <Form.Item>
        <TextArea rows={4} onChange={onChange} value={value} />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
          发布评论
        </Button>
      </Form.Item>
    </>
  );

class ExtendComment extends React.Component {
  constructor(props){
    super(props);
    this.state = {likes:0,dislikes:0,replying:false,action:null,author:'',comments:[],submitting:false,value:''}
    this.setlike = this.setlike.bind(this)
    this.setDislike = this.setDislike.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this)
  }
    componentDidMount(){
      if(undefined !== this.props.value){
        this.setState({comments:this.props.value})
      }
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
      
      if(this.props.value !== nextProps.value){
        this.setState({comments:nextProps.value})
      }
      return true
    }
    setComments= ()=>{

    }
    setlike(item){
      if(item.likes && -1!==item.likes.indexOf(localStorage.getItem("name"))){
        return;
      }
      let comments = this.state.comments;
      for(let i = 0;i < comments.length;i++){
        if(item === comments[i]){
          if(item.likes){
            item.likes.push(localStorage.getItem("name"));
          }
          else{
            item.likes = [localStorage.getItem("name")];
          }
          if(item.dislikes){
            item.dislikes.splice(localStorage.getItem("name"));
          }
          comments[i] = item
        }
      }
      this.setState({comments:comments})
    };
  
    setDislike(item){
      if(item.dislikes && -1!==item.dislikes.indexOf(localStorage.getItem("name"))){
        return;
      }
      let comments = this.state.comments;
      for(let i = 0;i < comments.length;i++){
        if(item === comments[i]){
          if(item.dislikes){
            item.dislikes.push(localStorage.getItem("name"));
          }
          else{
            item.dislikes = [localStorage.getItem("name")];
          }
          if(item.likes){
            item.likes.splice(localStorage.getItem("name"));
          }
          comments[i] = item
        }
      }
      this.setState({comments:comments})
      //this.setState({likes:0,dislikes:1,action:'disliked'})
    };
    setreply = () =>{
      this.setState({replying:true})
    }
    deleteComment = (item)=>{
      let comments = this.state.comments;
      for(let i = 0;i < comments.length;i++){
        if(item === comments[i]){
          comments.splice(i,1)
        }
      }
        this.setState({comments:comments})
    }
    handleChange = (e) => {
      this.setState({value:e.target.value});
    };
    handleSubmit = () => {
      if (!this.state.value) return;
      this.setState({submitting:true})
      setTimeout(() => {
        this.setState({submitting:false})
        let comments = this.state.comments?this.state.comments:[];
        comments.push({
          author: localStorage.getItem('name'),
          avatar: localStorage.getItem("account")+"/profile/"+ JSON.parse(localStorage.getItem('staff_detail')).profile_thumb,
          content: <p>{this.state.value}</p>,
          datetime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
        })
        this.setState({comments:comments})
        this.setState({value:'',replying:false})
        this.triggerChange(comments)
      }, 1000);
    };
    triggerChange = (changedValue) => {
      console.log(changedValue)
      const onChange = this.props.onChange;
      if (onChange) {
          onChange(changedValue);
      }
  }
  renderComments(){
    return( <>
            {this.state.comments.length > 0 && <List
              className="comment-list"
              header={`${this.state.comments.length} ${this.state.comments.length > 1 ? '评论' : '回复'}`}
              itemLayout="horizontal"
              dataSource={this.state.comments}
              renderItem={item => (
                  <Comment
                    author={(item).author}
                    avatar={Service.resServer +localStorage.getItem("enterprise_uuid") + "/"+(item).avatar}
                    content={item.content.props.children}
                    datetime={(item).datetime}
                    actions={[<Tooltip key="comment-basic-like" title="Like">
                                <span onClick={()=>{this.setlike(item)}}>
                                  {createElement((item.likes && -1 !== item.likes.indexOf(localStorage.getItem('name'))) ? LikeFilled : LikeOutlined)}
                                  <span className="comment-action">{item.likes?item.likes.length:0}</span>
                                </span>
                              </Tooltip>,
                              <Tooltip key="comment-basic-dislike" title="Dislike">
                                <span onClick={()=>{this.setDislike(item)}}>
                                  {React.createElement(item.dislikes && -1 !== item.dislikes.indexOf(localStorage.getItem('name'))? DislikeFilled : DislikeOutlined)}
                                  <span className="comment-action">{item.dislikes?item.dislikes.length:0}</span>
                                </span>
                              </Tooltip>,
                              <span key="comment-basic-reply-to" onClick={this.reply}>回复</span>,
                              localStorage.getItem("name") === item.author?<span key="comment-basic-reply-to" onClick={()=>{this.deleteComment(item)}}>删除</span>:null
                            ]}/>)}
                />}
            </>)
  }
    render(){
      return (<>
            {this.renderComments()}
            <Comment
            avatar={<Avatar src={Service.resServer +localStorage.getItem("enterprise_uuid") + "/"+ localStorage.getItem("account")+"/profile/"+ JSON.parse(localStorage.getItem('staff_detail')).profile_thumb} alt={localStorage.getItem('name')}/>}
            content={
              <Editor
                onChange={this.handleChange}
                onSubmit={this.handleSubmit}
                submitting={this.state.submitting}
                value={this.state.value}
              />
            }
          />
        </>
      );
    }
  };
  
  export default ExtendComment;