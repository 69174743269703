import $ from 'jquery'
//import axios from "axios";
import {message} from "antd";
import * as Service from '../config/service.json';
import {ActionType} from "../constants/actionTypes";
import {preHandle,checkAccountStatus} from './commonDBTool';

export function getAllPerformanceGoal(pageStart=0,pageSize=50){
    let url = Service.dbServer + Service.apiVersion + "/performance/getAll" ;
    return dispatch=>{
        dispatch({type:ActionType.GET_PERFORMANCE_GOAL_START});
        $.ajax(preHandle(url),{
            method:'GET',
            dataType:'json',
            data:{pageStart:pageStart,pageSize:pageSize}
        }).done((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            let result = response.result
            if(result){
                for(let i = 0;i < result.length;i++){
                    result[i].goal_data = JSON.parse(result[i].goal_data);
                }
            }
            dispatch({type:ActionType.GET_PERFORMANCE_GOAL_SUCCESS,payload:result});
        }).catch(function (error) {
            dispatch({type:ActionType.GET_PERFORMANCE_GOAL_FAILED,payload:error});
        })
    }
}
export function addPerformanceGoal(postData){
    let url = Service.dbServer + Service.apiVersion + "/performance/add" ;
    return dispatch=>{
        dispatch({type:ActionType.ADD_PERFORMANCE_GOAL_START});
        $.ajax(preHandle(url),{
            method:'POST',
            contentType:'application/json; charset=utf-8',
            dataType:'json',
            data:JSON.stringify(postData)
        }).done((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            message.info("设置成功");
            dispatch({type:ActionType.ADD_PERFORMANCE_GOAL_SUCCESS,payload:response.result});
            dispatch(getAllPerformanceGoal());
        }).catch(function (error) {
            message.error("设置失败");
            dispatch({type:ActionType.ADD_PERFORMANCE_GOAL_FAILED,payload:error});
        })
    }
}
export function updatePerformanceGoal(postData){
    console.log(postData)
    let url = Service.dbServer + Service.apiVersion + "/performance/update" ;
    return dispatch =>{
        dispatch({type:ActionType.UPDATE_BUSINESS_OPPORTUNITY_START});
        $.ajax(preHandle(url),{
            method:'POST',
            contentType:'application/json; charset=utf-8',
            dataType:'json',
            data:JSON.stringify(postData)
        }).then(response=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            message.info("更新成功！")
            dispatch({type:ActionType.UPDATE_BUSINESS_OPPORTUNITY_SUCCESS,payload:response.result});
            dispatch(getAllPerformanceGoal());
        }).catch(function (error) {
            message.error("更新失败!");
            dispatch({type:ActionType.UPDATE_BUSINESS_OPPORTUNITY_FAILED,payload:error});
        })
    }
}
export function deletePerformanceGoal(postData){
    let url = Service.dbServer + Service.apiVersion + "/performance/del" ;
    return dispatch =>{
        dispatch({type:ActionType.DELETE_PERFORMANCE_GOAL_START});
        $.ajax(preHandle(url),{
            method:'delete',
            dataType:'json',
            data:({uuid:postData.uuid})
        }).then(response=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            message.info("删除成功！");
            dispatch({type:ActionType.DELETE_PERFORMANCE_GOAL_SUCCESS,payload:response.result});
            dispatch(getAllPerformanceGoal());
        }).catch(function (error) {
            message.error("删除失败！");
            dispatch({type:ActionType.DELETE_PERFORMANCE_GOAL_FAILED,payload:error});
        })
    }
}