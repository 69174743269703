import React from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {Row,Col,Input, Form,Modal, DatePicker, Divider,Select, Tag, Button,Radio} from "antd";
import moment from "moment";
import {exportExcel} from './ExcelUtil';
import {connect} from "react-redux";
import {addLeave, deleteLeave, updateLeave, batchDeleteLeave} from "../../actions/leaveDBTool";
import {LEAVE_STATUS_TODO, leaveStatusList} from "../../constants/constant";
import {account2Name, name2Account} from "../../utils/accountUtils";
import StaffChoose from "./StaffChoose";
import ExtendTable from "./ExtendTable";
import { StringCompareSort } from "../../utils/common";

const {Option} = Select;
const {confirm} = Modal;
const exportOptions = ['序号','请假理由', '起始时间', '结束时间', '事由','申请进度','审核人','抄送给','申请人','创建时间'];
const LeaveForm = Form.create({name:'approval_modal'})(
    class extends React.Component{
        constructor(props) {
            super(props);
            this.state = {};
        }
        render(){
            let { visible, onCancel, onOk, form, values,mode,title } = this.props;
            let { getFieldDecorator } = form;
            let formItemLayout = {labelCol: {span:6}, wrapperCol: {span:16}};
            let currentTime = new Date();
            return (<Modal title={title} visible={visible} maskClosable={false} onOk={onOk} onCancel={onCancel} 
                        okText={2===mode?"关闭":"确定"} cancelText={"取消"}>
                    <Form layout={'horizontal'} {...formItemLayout}>
                        <Form.Item label={"标题"}>
                            {getFieldDecorator('leaveTitle', {rules: [{required:true,message:'标题不能为空!'}],initialValue:mode?values.leaveTitle: ''})
                            (<Input placeholder={"例如:请假条"} disabled={1 < mode?true:false}/>)}
                        </Form.Item>
                        <Form.Item label={"事由"}>
                            {getFieldDecorator('content', {rules: [{required: true, message: '事由不能为空!'}],initialValue:mode?values.content:''})
                            (<ReactQuill placeholder={"例如:请假具体原因及时间"} readOnly={1 < mode?true:false}/>)}
                        </Form.Item>
                        <Form.Item label={"请假时间"}>
                            {getFieldDecorator('leaveTime',{rules: [{ required: true,message:'请假时间不能为空!' }], 
                            initialValue:mode?moment(new Date(values.leaveTime), 'YYYY-MM-DD'): moment(currentTime, 'YYYY-MM-DD')})
                            (<DatePicker placeholder={"选择请假的时间"} showTime disabled={1 < mode?true:false}/>)}
                        </Form.Item>
                        <Form.Item label={"回归时间"}>
                            {getFieldDecorator('backTime',{rules: [{ required: true,message:'回归时间不能为空!' }], 
                            initialValue:mode?moment(new Date(values.backTime), 'YYYY-MM-DD'): moment(currentTime, 'YYYY-MM-DD')})
                            (<DatePicker placeholder={"选择回归的时间"} showTime disabled={1 < mode?true:false}/>)}
                        </Form.Item>
                        <Form.Item label={"请假进度"} hidden={!mode}>
                            {getFieldDecorator('status',{rules: [{ required: true,message:'请假状态不能为空' }],initialValue:mode? values.status:LEAVE_STATUS_TODO})
                            (<Select disabled={2 === mode?true:false}>
                                {leaveStatusList.map(item=><Option key={item} value={item}>{item}</Option>)}
                            </Select>)}
                        </Form.Item>
                        <Form.Item label={"审核人"}>
                            {getFieldDecorator('manager', {rules: [{ required: true,message:'审核人必填' }],initialValue:mode?values.manager:''})
                            (<StaffChoose mode={0} disabled={1 < mode?true:false}/>)}
                        </Form.Item>
                        <Form.Item label={"抄送给"}>
                            {getFieldDecorator('assigner', {initialValue: values.assigner ? values.assigner : ''})
                            (<StaffChoose mode={1} disabled={1 < mode?true:false}/>)}
                        </Form.Item>
                    </Form>
                </Modal>)
        }
    }
)
function mapStateToProps(state) {
    return {...state.leave};
}
function mapDispatchToProps(dispatch){
    return {onAddLeave:(postData)=>dispatch(addLeave(postData)),
            onEditLeave:(postData)=>dispatch(updateLeave(postData)),
            onDeleteLeave:(uuid)=>dispatch(deleteLeave(uuid)),
            onBatchDelete:(uuid_list)=>dispatch(batchDeleteLeave(uuid_list))
        }
}
class LeaveTable extends React.Component{
    constructor(props) {
        super(props);
        this.state = {showModal:false, modalMode:0,modalTitle:'新增请假条',
                        exportColumns:exportOptions,
                        exportType:'xlsx',
                        vcfSize:'0',
                        modalValues:{
                        uuid:'',
                        leaveTitle:'',
                        content:'',
                        status:'',
                        leaveTime:'',
                        backTime:'',
                        manager:'',
                        creator:'',
                        createTime:''
                    }}
    }
    saveFormRef = formRef => {this.formRef = formRef;}
    showContent = (record)=>{
        for (let i = 0;i < this.props.get_leave_result.length;i++){
            let item = this.props.get_leave_result[i];
            if(item.uuid === record.uuid){
                this.updateModalValues(item);
                this.setState({showModal:true, modalMode:2,modalTitle:'查看请假申请'});
                return;
            }
        }
    }
    updateModalValues(values){
        let detail = (values.detail);
        this.setState({modalValues:{uuid:values.uuid,
            leaveTitle:values.title,
            content:detail.leave_reason_content,
            status:values.status,
            leaveTime:detail.leave_time,
            backTime:detail.back_time,
            manager:account2Name(detail.manager_account),
            assigner:account2Name(detail.assigner_account),
            creator:account2Name(values.creator_account),
            createTime:values.create_time}});
    }
    handleAddOnClick=()=>{
        this.setState({showModal:true,modalMode:0,modalTitle:'新增请假条',
            modalValues:{  uuid:'',
                            leaveTitle:'',
                            content:'',
                            status:'',
                            leaveTime:'',
                            backTime:'',
                            manager:'',
                            creator:'',
                            createTime:''}})
    }
    handleEmptySelect=(uuid_list)=>{
        this.props.onBatchDelete(uuid_list);
    }
    handleOnEdit=(record)=>{
        for (let i = 0;i < this.props.get_leave_result.length;i++){
            let item = this.props.get_leave_result[i];
            if(item.uuid === record.uuid){
                this.updateModalValues(item);
                if(this.props.editEnable){
                    this.setState({showModal:true, modalMode:1,modalTitle:'更新假条'});
                }
                else{
                    this.setState({showModal:true, modalMode:3,modalTitle:'更新假条'});
                }
                return;
            }
        }
    }
    handleDelete=(uuid)=>{
        confirm({
            content: '您确定要删除该请假条?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk:()=> {this.props.onDeleteLeave(uuid)},
            onCancel() {
                console.log('Cancel');
            },
        })
    }
    handleCancel=()=>{
        const { form } = this.formRef.props;
        form.resetFields();
        this.setState({showModal:false})
    }
    handleSubmit=()=>{
        if(2 === this.state.modalMode){
            this.setState({ showModal: false });
            return;
        }
        const { form } = this.formRef.props;
        form.validateFields((err, fromValues) => {
            if(err){return;}
            let manager_account = name2Account(fromValues.manager);
            let assigner_account = name2Account(fromValues.assigner);
            let creator_account = localStorage.getItem('account');
            if (0 >= fromValues.manager.length){
                manager_account = localStorage.getItem('account');
            }
            let current = new Date();
            let detail = {
                leave_reason_content:fromValues.content,
                leave_time:Date.parse(fromValues.leaveTime),
                back_time:Date.parse(fromValues.backTime),
                manager_account:manager_account,
                assigner_account:assigner_account
            };
            let postData = {
                enterprise_uuid:localStorage.getItem('enterprise_uuid'),
                title:fromValues.leaveTitle,
                type:"1",
                detail:JSON.stringify(detail),
                creator_account:creator_account,
                create_time:Date.parse(current)
            };
            if(this.state.modalMode){
                postData['status'] = fromValues.status;
                postData['uuid'] = this.state.modalValues.uuid;
                this.props.onEditLeave(postData);
            }
            else{
                postData['status'] = LEAVE_STATUS_TODO;
                let uuid = Date.parse(new Date())/1000+(Math.round(Math.random() * (9000))+999);
                postData['uuid'] = uuid;
                this.props.onAddLeave(postData);
            }
            let modalValues = {
                uuid:postData['uuid'],
                title:fromValues.leaveTitle,
                type:"1",
                status:postData['status'],
                detail:detail,
                creator_account:creator_account,
                create_time:Date.parse(current)
            }
            this.updateModalValues(modalValues);
            this.setState({ showModal: false });
            form.resetFields();
        })
    }
    OnChangeColumn=(checkedValues)=>{
        console.log(checkedValues);
        this.setState({exportColumns:checkedValues});
    }
    OnChangeExportFormat=(e)=>{
        this.setState({exportType:e.target.value})
    }
    OnClickExport(dataSource){
        for (let i = 0;i < dataSource.length;i++){
            for (let key in dataSource[i]){
                if (-1 === this.state.exportColumns.indexOf(key)){
                    delete dataSource[i][key];
                    delete dataSource[i].key;
                }
            }
        }
        exportExcel(dataSource,this.state.exportType,'请假列表');
    }
    renderAction(record){
        if(this.props.editEnable){
            return (<Row>
                <Col span={8}><Button size='small' type="link" onClick={()=>this.showContent(record)}>查看</Button></Col>
                <Col span={8}><Button size='small' type="link" onClick={()=>this.handleOnEdit(record)}>编辑</Button></Col>
                <Col span={8}><Button size='small' type="link" style={{color:'red'}} onClick={()=>this.handleDelete(record.uuid)}>删除</Button></Col>
            </Row>)
        }
        else{
            if(-1 !== record.manager.indexOf(localStorage.getItem('name'))){
                return (<Row>
                    <Col span={8}><Button size='small' type="link" onClick={()=>this.showContent(record)}>查看</Button></Col>
                    <Col span={8}><Button size='small' type="link" onClick={()=>this.handleOnEdit(record)}>编辑</Button></Col>
                    </Row>)
            }
            else{
                return (<Row><Button size='small' type="link" onClick={()=>this.showContent(record)}>查看</Button></Row>)
            }
            
        }   
    }
    renderExportModal=(exportDataSource)=>{
        return (<Modal title="导出结果选项" visible={this.state.showExportModal} width={1000}
                        cancelText={"取消"} onCancel={()=>{this.setState({showExportModal:false})}}
                        okText={"导出"} onOk={()=>{this.OnClickExport(exportDataSource)}} >
                
                <Radio.Group onChange={this.OnChangeExportFormat} defaultValue={'xlsx'}>
                    <Radio value={'xlsx'}>Excel(.xlsx)</Radio>
                    <Radio value={'xls'}>Excel(.xls)</Radio>
                    <Radio value={'csv'}>CSV</Radio>
                    <Radio value={'txt'}>文本(TXT)</Radio>
                </Radio.Group>
        </Modal>);
    }
    renderStatusStyleText(text){
        const statusColor = ["#fa0","#2db7f5","","red"];
        return (<Tag color={statusColor[leaveStatusList.indexOf(text)]}>{text}</Tag>)
    }
    renderSetting(){
        return(<LeaveForm wrappedComponentRef={this.saveFormRef} visible={this.state.showModal} 
                values={this.state.modalValues} mode={this.state.modalMode} title={this.state.modalTitle}
                onCancel={this.handleCancel} onOk={this.handleSubmit}/>);
    }
    renderTable(){
        const leaveStatus = [];
        leaveStatusList.forEach((item)=>{leaveStatus.push({text:item,value:item});});
        let showColumns = 
        [{title:"请假理由",dataIndex:'leaveTitle',key:'leaveTitle',width:120,ellipsis:true,},
        {title:"起始时间",dataIndex:'startTime',key:'startTime',width:180,ellipsis:true,sorter: (a, b) => Date.parse(a.startTime) - Date.parse(b.startTime)},
        {title:"结束时间",dataIndex:'endTime',key:'endTime',width:180,ellipsis:true,sorter: (a, b) => Date.parse(a.endTime) - Date.parse(b.endTime)},
        {title:"申请进度",dataIndex:'status',key:'status',width:120,ellipsis:true,isSearch:true,filters:leaveStatus,
            onFilter: (value, record) => record.status.indexOf(value) === 0,sorter: (a, b) => StringCompareSort(a.status,b.status),
            render:(text)=>this.renderStatusStyleText(text)},
        {title:"审核人",dataIndex:"manager",key:'manager',width:120,ellipsis:true,isSearch:true,sorter: (a, b) =>StringCompareSort(a.manager,b.manager),
            render:(text, record) =><Row>
                {record.manager instanceof Array?record.manager.map((tag,index)=>{
                    const tagElem = (<Tag key={tag} color={"geekblue"}>{tag}</Tag>);
                    return (tagElem);
                }):record.manager?(<Tag key={record.manager} color={"geekblue"}>{record.manager}</Tag>):null}
            </Row>},
        {title:"抄送给",dataIndex:'assigner',key:'assigner',width:120,ellipsis:true,isSearch:true,sorter:(a,b)=>StringCompareSort(a.creator,b.creator),
            render:(text, record) =><Row>
                {text instanceof Array?text.map((tag,index)=>{const tagElem = (<Tag key={tag} color={"geekblue"}>{tag}</Tag>);
                    return (tagElem);}):text?(<Tag key={text} color={"geekblue"}>{text}</Tag>):null}
            </Row>},
        {title:"申请人",dataIndex:"creator",key:'creator',width:120,ellipsis:true,isSearch:true,sorter: (a, b) =>StringCompareSort(a.creator,b.creator),
            render:(text, record) =><Row>
                {record.creator instanceof Array?record.creator.map((tag,index)=>{
                    const tagElem = (<Tag key={tag} color={"geekblue"}>{tag}</Tag>);
                    return (tagElem);
                }):record.creator?(<Tag key={record.creator} color={"geekblue"}>{record.creator}</Tag>):null}
            </Row>},
        {title:"创建时间",dataIndex:"createTime",key:'createTime',width:180,ellipsis:true,sorter: (a, b) => Date.parse(a.createTime) - Date.parse(b.createTime)},
        {title:'操作',key:'actions',width:150,fixed: 'right', render: (text, record) =>this.renderAction(record)}];
        let dataSource = [];
        let exportDataSource = [];
        for (let i = 0;i < this.props.dataSource.length;i++){
            let record = this.props.dataSource[i];
            let detail = record.detail;
            let manager = account2Name(detail.manager_account);
            let assigner = account2Name(detail.assigner_account);
            let creator = account2Name(record.creator_account);
            dataSource.push({index:i+1,
                uuid: record.uuid,
                leaveTitle: record.title,
                content:detail.leave_reason_content,
                startTime:moment(new Date(detail.leave_time)).format('YYYY-MM-DD HH:mm:ss'),
                endTime:moment(detail.back_time).format('YYYY-MM-DD HH:mm:ss'),
                status: record.status,
                manager:manager,
                assigner:assigner,
                creator: creator,
                createTime: moment(new Date(record.create_time)).format('YYYY-MM-DD HH:mm:ss'),
            });
            exportDataSource.push({'序号':i+1,
                                    '请假理由':record.title, 
                                    '起始时间':moment(new Date(detail.leave_time)).format('YYYY-MM-DD HH:mm:ss'), 
                                    '结束时间':moment(detail.back_time).format('YYYY-MM-DD HH:mm:ss'), 
                                    '事由':detail.leave_reason_content,
                                    '申请进度':record.status,
                                    '审核人':manager,
                                    '抄送给':assigner,
                                    '申请人':creator,
                                    '创建时间':moment(new Date(record.create_time)).format('YYYY-MM-DD HH:mm:ss')})
        }
        return(<Row>
                {this.renderExportModal(exportDataSource)}
                <ExtendTable handleAddOnClick={this.handleAddOnClick} onBatchDelete={this.handleEmptySelect} dataSource={dataSource}
                    customColumnsEnable={this.props.customColumnsEnable} tableKey={this.props.tableKey} columns={showColumns} 
                    rowKey={record => record.uuid} exportEnable={this.props.exportEnable} exportTitle={"导出请假列表"} exportClick={()=>this.setState({showExportModal:true})}/>
                </Row>);
    }
    render() {
        return (<Row>
            <Row>{this.renderTable()}</Row>
            <Divider/>
            <Row>{this.renderSetting()}</Row>
        </Row>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(LeaveTable)