import React from 'react';
import FunnelChart from "../controls/echarts/FunnelChart";
import BarChart from "../controls/echarts/BarChart";
import GaugeChart from "../controls/echarts/GaugeChart";
import {Row, Card, Col, List, Select, Button, DatePicker,Tabs, Tooltip,Modal} from 'antd';
import {connect} from "react-redux";
import {getTodoWork} from "../../actions/todoDBTool";
import {getAllUser} from '../../actions/userDBTool'
import {getWorkOrder} from "../../actions/workOrderDBTool";
import {getAllNotice} from '../../actions/noticeDBTool';
import {getAllFollowUp} from '../../actions/followupDBTool';
import {getEnterpriseDepartment} from '../../actions/enterpriseDBTool';
import {getAllOrder,getOrderAmountRate,getOrderCountRate} from '../../actions/orderDBTool';
import {getAllCustom} from '../../actions/customDBTool';
import {getBusinessFlow, getBusinessStatusAmount,getBusinessStatusCount} from '../../actions/businessDBTool';
import {ORDER_STATUS_SUCCESS, TODO_WORK_STATUS_CLOSE,USER_LEVEL_ADMIN,USER_LEVEL_LEADER,workOrderStatusList} from "../../constants/constant";
import {isThisMonth, isThisWeek, isToday, delHtmlTag, isThisYear} from "../../utils/common";
import moment from "moment";
import locale from 'antd/es/date-picker/locale/zh_CN';
import { account2Name } from '../../utils/accountUtils';
import { getAllPerformanceGoal } from '../../actions/performanceGoalDBTool';
import BusinessOpportunityStatusModal from '../controls/BusinessOpportunityStatusModal';

const {MonthPicker} = DatePicker;
const {Option,OptGroup} = Select;
const {info} = Modal;
const { TabPane } = Tabs;

function mapStateToProps(state) {
    return {...state.todoWork,
            ...state.workOrder,
            ...state.order,
            ...state.custom,
            ...state.notice,
            ...state.staff,
            ...state.businessOpportunity,
            ...state.performanceGoal,
            ...state.enterprise}
}
function mapDispatchToProps(dispatch){
    return {onGetDepartment:()=>dispatch(getEnterpriseDepartment()),
            onGetNotice:()=>dispatch(getAllNotice()),
            onGetTodoWork:()=>dispatch(getTodoWork()),
            onGetStaff:()=>dispatch(getAllUser()),
            onGetCustom:()=>dispatch(getAllCustom()),
            onGetOrder:()=>dispatch(getAllOrder()),
            onGetFollowUp:()=>dispatch(getAllFollowUp()),
            onGetWorkOrder:()=>dispatch(getWorkOrder()),
            onGetPerformance:()=>dispatch(getAllPerformanceGoal()),
            onGetOrderCountRate:(topCount,status,start_time,end_time)=>dispatch(getOrderCountRate(topCount,status,start_time,end_time)),
            onGetOrderAmountRate:(topCount,status,start_time,end_time)=>dispatch(getOrderAmountRate(topCount,status,start_time,end_time)),
            onGetBusinessStatusCount:(topCount,start_time,end_time)=>dispatch(getBusinessStatusCount(topCount,start_time,end_time)),
            onGetBusinessStatusAmount:(topCount,start_time,end_time)=>dispatch(getBusinessStatusAmount(topCount,start_time,end_time)),
            onGetBusinessFlow:(callBack)=>dispatch(getBusinessFlow(callBack))
    };
}
class WorkStation extends React.Component{
    constructor(props) {
        super(props);
        this.state = { briefTimeSelect:'week',
                        rateCount:5,rateTimeSelect:'recentMonth',
                        funnelScope:'self',funnelTimeSelect:'recentMonth',goalTimeSelect:new Date(),
                        businessFlowVisible:false,businessFlowList:['接触客户','需求确定','方案报价','商务谈判','已下单/成交','已失败/丢失']};
        this.syncBrief = this.syncBrief.bind(this);
        this.syncBusinessFlow = this.syncBusinessFlow.bind(this);
        this.onChangeRateCount = this.onChangeRateCount.bind(this);
        this.onChangeRateTime = this.onChangeRateTime.bind(this);
        this.onChangeFunnelScope = this.onChangeFunnelScope.bind(this);
        this.onChangeFunnelTime = this.onChangeFunnelTime.bind(this);
        this.handleBusinessFlowOk = this.handleBusinessFlowOk.bind(this);
        this.getPercent = this.getPercent.bind(this);
    }
    componentDidMount(){
        this.props.onGetBusinessFlow(()=>{this.syncBusinessFlow();});
        this.syncBrief()
        this.props.onGetDepartment();
        this.props.onGetTodoWork();
        this.props.onGetNotice();
        this.props.onGetWorkOrder();
        this.props.onGetPerformance();
        this.props.onGetOrderAmountRate(5,"已完成",Date.parse(new Date())-2592000000,Date.parse(new Date()));
        this.props.onGetOrderCountRate(5,"已完成",Date.parse(new Date())-2592000000,Date.parse(new Date()));
        this.props.onGetBusinessStatusAmount(this.state.businessFlowList,Date.parse(new Date())-2592000000,Date.parse(new Date()));
        this.props.onGetBusinessStatusCount(this.state.businessFlowList,Date.parse(new Date())-2592000000,Date.parse(new Date()));
        
    }
    syncBusinessFlow(){
        let businessFlowList = this.props.get_business_flow_result?this.props.get_business_flow_result:[];
        for(let i = 0;i < businessFlowList.length;i++){
            if(businessFlowList[i].detail.isUsing){
                let flowList = [];
                let flows = businessFlowList[i].detail.flows;
                for(let i = 0;i < flows.length;i++){
                    flowList.push(flows[i]);
                }
                return this.setState({businessFlowList:flowList});
            }
        }
        this.setState({businessFlowList:['接触客户','需求确定','方案报价','商务谈判','已下单/成交','已失败/丢失']});
    }
    syncBrief(){
        this.props.onGetCustom();
        this.props.onGetFollowUp();
        this.props.onGetOrder();
    }
    onChangeRateCount(value){
        let lastTime = 2592000000;
        switch(this.state.rateTimeSelect){
            case "recentWeek":
                lastTime = 604800000;
            break;
            case "recentMonth":
                lastTime = 2592000000;
            break;
            case "recentYear":
                lastTime = 31536000000;
            break;
            default:
                lastTime = 2592000000;
            break;
        }
        this.props.onGetOrderAmountRate(value,"已完成",Date.parse(new Date())-lastTime,Date.parse(new Date()));
        this.props.onGetOrderCountRate(value,"已完成",Date.parse(new Date())-lastTime,Date.parse(new Date()));
        this.setState({rateCount:value});
    }
    onChangeRateTime(value){
        let lastTime = 2592000000;
        switch(value){
            case "recentWeek":
                lastTime = 604800000;
            break;
            case "recentMonth":
                lastTime = 2592000000;
            break;
            case "recentYear":
                lastTime = 31536000000;
            break;
            default:
                lastTime = 2592000000;
            break;
        }
        this.props.onGetOrderAmountRate(this.state.rateCount,"已完成",Date.parse(new Date())-lastTime,Date.parse(new Date()));
        this.props.onGetOrderCountRate(this.state.rateCount,"已完成",Date.parse(new Date())-lastTime,Date.parse(new Date()));
        this.setState({rateTimeSelect:value});
    }
    renderRate(){
        let countList = [];
        let amountList = [];
        let countResult = this.props.get_order_count_rate_result?this.props.get_order_count_rate_result:[];
        for(let i = countResult.length - 1;i >= 0;i--){
            let title = "";
            let staffName = account2Name(countResult[i].manager_account);
            if(staffName instanceof Array){
                for(let j = 0;j < staffName.length;j++){
                    if(j > 0){
                        title += "、";
                    }
                    title += staffName[j];
                }
            }else{
                title = staffName;
            }
            countList.push({title:title,measures:[countResult[i].count],ranges:[countResult[i].count],target:countResult[i].count})
        }
        let amountResult = this.props.get_order_amount_rate_result?this.props.get_order_amount_rate_result:[];
        for(let i = amountResult.length - 1;i >=0;i--){
            let title = "";
            let staffName = account2Name(amountResult[i].manager_account);
            if(staffName instanceof Array){
                for(let j = 0;j < staffName.length;j++){
                    if(j > 0){
                        title += "、";
                    }
                    title += staffName[j];
                }
            }else{
                title = staffName;
            }
            amountList.push({title:title,measures:[amountResult[i].amount],ranges:[amountResult[i].amount],target:amountResult[i].amount})
        }
        console.log(amountList)
        console.log(countList)
        let header = <Row>
                        <Col lg={3} xs={20}>排行榜</Col>
                        <Col lg={5} xs={20}>
                            <Select style={{width:'100%'}} defaultValue={"5"} onChange={(value)=>{this.onChangeRateCount(value)}}>
                                <Option value={"5"}>前5名</Option>
                                <Option value={"10"}>前10名</Option>
                                <Option value={"20"}>前20名</Option>
                                <Option value={"50"}>前50名</Option>
                            </Select>
                        </Col>
                        <Col lg={{span:5,offset:1}} xs={20}>
                            <Select style={{width:'100%'}} defaultValue={"recentMonth"} onChange={(value)=>{this.onChangeRateTime(value)}}>
                                <Option value={"recentWeek"}>最近一周</Option>
                                <Option value={"recentMonth"}>最近一月</Option>
                                <Option value={"recentYear"}>最近一年</Option>
                                <Option value={"custom"}>自定义</Option>
                            </Select>
                        </Col>
                    </Row>
        let extra = <Tooltip title={"更新"}><Button type={"link"} icon="sync"/></Tooltip>;
        return (<Card title={header} extra={extra}>
                    <Tabs defaultActiveKey={'1'}>
                        <TabPane key={'1'} tab={"订单成交金额"}><BarChart data={amountList}/></TabPane>
                        <TabPane key={'2'} tab={"订单成交数量"}><BarChart data={countList}/></TabPane>
                    </Tabs>
                </Card>);
    }
    getPercent(result,time){
        if(!time){return;}
        let orderResult = this.props.get_order_result?this.props.get_order_result:[];
        let complete = 0.0;
        let goal = 1.0;
        for(let i = 0;i < orderResult.length;i++){
            if(new Date(orderResult[i].detail.send_time).getFullYear() !== new Date(time).getFullYear() ||
                new Date(orderResult[i].detail.send_time).getMonth() !== new Date(time).getMonth() ||
                orderResult[i].status !== ORDER_STATUS_SUCCESS){continue;}
            if(-1 !== orderResult[i].detail.follower_account.indexOf(localStorage.getItem('account'))){
                complete += orderResult[i].amount;
            }
        }
        for(let i = 0;i < result.length;i++){
            if(result[i].name === localStorage.getItem('account')){
                let goalList = [result[i].goal_data.January,result[i].goal_data.February,result[i].goal_data.March,
                result[i].goal_data.April,result[i].goal_data.May,result[i].goal_data.June,result[i].goal_data.July,
                result[i].goal_data.August,result[i].goal_data.September,result[i].goal_data.October,result[i].goal_data.November,
                result[i].goal_data.December];
                goal = Number(goalList[new Date(time).getMonth()])>0?Number(goalList[new Date(time).getMonth()]):1;
            }
        }
        let percent = parseFloat(complete/goal);
        return percent;
    }
    renderWorkGoal(){
        let header = <Row>
                        <Col lg={6} xs={12}>业绩目标</Col>
                        <Col lg={{span:6,offset:1}} xs={20}>
                            <MonthPicker locale={locale} defaultPickerValue={moment(new Date(), 'YYYY-MM-DD')} defaultValue={moment(new Date(), 'YYYY-MM-DD')} 
                            onChange={(date, dateString)=>{this.setState({goalTimeSelect:dateString});}}/>
                        </Col>
                    </Row>;
        let extra = <Tooltip title={"更新"}><Button type={"link"} icon="sync"/></Tooltip>;
        let performanceGoalList = this.props.get_performance_goal_result?this.props.get_performance_goal_result:[];
        let percent = this.getPercent(performanceGoalList,this.state.goalTimeSelect);
        return (<Card title={header} extra={extra}><GaugeChart percent={percent>0?percent:0}/></Card>);
    }
    onChangeFunnelScope(value){
        let lastTime = 2592000000;
        switch(this.state.funnelTimeSelect){
            case 'recentWeek':
                lastTime = 604800000;
                break;
            case 'recentMonth':
                lastTime = 2592000000;
                break;
            case 'recentYear':
                lastTime = 31536000000;
                break;
            default:
                break;
        }
        this.props.onGetBusinessStatusAmount(this.state.businessFlowList,Date.parse(new Date())-lastTime,Date.parse(new Date()));
        this.props.onGetBusinessStatusCount(this.state.businessFlowList,Date.parse(new Date())-lastTime,Date.parse(new Date()));
        this.setState({funnelScope:value});
    }
    onChangeFunnelTime(value){
        let lastTime = 2592000000;
        switch(value){
            case 'recentWeek':
                lastTime = 604800000;
                break;
            case 'recentMonth':
                lastTime = 2592000000;
                break;
            case 'recentYear':
                lastTime = 31536000000;
                break;
            default:
                break;
        }
        this.props.onGetBusinessStatusAmount(this.state.businessFlowList,Date.parse(new Date())-lastTime,Date.parse(new Date()));
        this.props.onGetBusinessStatusCount(this.state.businessFlowList,Date.parse(new Date())-lastTime,Date.parse(new Date()));
        this.setState({funnelTimeSelect:value});
    }
    handleBusinessFlowOk(){
        this.syncBusinessFlow();
        this.setState({businessFlowVisible:false})
    }
    renderSalesFunnel(){
        let departOptions = null;
        let staffOptions = null;
        if(this.props.get_staff&&this.props.get_department){
            departOptions = this.props.get_department_result.map(item=><Option key={item.name} value={item.name}>{item.name}</Option>);
            staffOptions = this.props.get_staff_list_result.map(item=>
                item.account !== localStorage.getItem("account")?(<Option key={item.name} value={item.name}>{item.name}</Option>):null);
        }
        let header = <Row>
                        <Col lg={3} xs={20}>销售漏斗</Col>
                        <Col lg={{span:6,offset:1}} xs={20}>
                            <Select style={{width:'100%'}} defaultValue={"我自己"} onChange={(value)=>{this.onChangeFunnelScope(value)}}>
                                <Option value={"全公司"}>全公司</Option>
                                <Option value={"我自己"}>我自己</Option>
                                <OptGroup label="指定部门">{departOptions}</OptGroup>
                                <OptGroup label="指定成员">{staffOptions}</OptGroup>
                            </Select>
                        </Col>
                        <Col lg={{span:6,offset:1}} xs={20}>
                            <Select style={{width:'100%'}} defaultValue={"recentMonth"} onChange={(value)=>{this.onChangeFunnelTime(value)}}>
                                <Option value={"recentWeek"}>最近一周</Option>
                                <Option value={"recentMonth"}>最近一月</Option>
                                <Option value={"recentYear"}>最近一年</Option>
                                <Option value={"week"}>按周统计</Option>
                                <Option value={"month"}>按月统计</Option>
                                <Option value={"year"}>按年统计</Option>
                            </Select>
                        </Col>
                    </Row>;
        let extra = <Tooltip title={"设置"}><Button type={"link"} icon={"setting"} onClick={()=>{this.setState({businessFlowVisible:true})}}/></Tooltip>;
        let amountDataSource = [];
        let countDataSource = [];
        this.state.businessFlowList.forEach((item)=>{                
            amountDataSource.push({stage:item,number:0})
            countDataSource.push({stage:item,number:0});
        })
        let bussinessAmountList = this.props.get_business_opportunity_status_amount_result?this.props.get_business_opportunity_status_amount_result:[];
        bussinessAmountList.forEach(item=>{
            for(let i = 0;i < amountDataSource.length;i++){
                if(amountDataSource[i].stage === item.status){
                    amountDataSource[i].number += item.amount;
                }
            }
        })
                   
        let bussinessCountList = this.props.get_business_opportunity_status_count_result?this.props.get_business_opportunity_status_count_result:[];
        bussinessCountList.forEach(item=>{
            for(let i = 0;i < countDataSource.length;i++){
                if(countDataSource[i].stage === item.status){
                    countDataSource[i].number += item.count;
                }
            }
        });
        return (<Card title={header} extra={(USER_LEVEL_ADMIN||USER_LEVEL_LEADER) === Number(localStorage.getItem('level'))?extra:null}>
                    <BusinessOpportunityStatusModal visible={this.state.businessFlowVisible} 
                        onCancel={()=>{this.setState({businessFlowVisible:false})}} onOk={()=>{this.handleBusinessFlowOk()}}/>
                    <Tabs defaultActiveKey={'1'}>
                        <TabPane tab={"商机金额"} key={'1'}><FunnelChart data={amountDataSource}/></TabPane>
                        <TabPane tab={"商机数量"} key={'2'}><FunnelChart data={countDataSource}/></TabPane>
                    </Tabs>
                </Card>);
    }
    showContent = (uuid) =>{
        let showItem = "";
        for (let i = 0;i < this.props.get_todo_result.length;i++){
            let item = this.props.get_todo_result[i];
            if(item.uuid === uuid){
                showItem = item;
                break;
            }
        }
        info({title:showItem.title,
            content:delHtmlTag((showItem.detail).content),
            okType:'primary',
            cancelText:'取消',
            okText:'确定',
            onOk:()=>{},
            onCancel(){
                console.log('Cancel');
            }
        })
    }
    renderTodoWork(){
        let data = [];
        if(this.props.get_todo_result){
            for (let i = 0;i < this.props.get_todo_result.length;i++){
                let item = this.props.get_todo_result[i];
                if(TODO_WORK_STATUS_CLOSE !== item.status){
                    data.push({title:item.title,uuid:item.uuid,remind_time: item.detail.remind_time})
                }
            }
        }
        let extra = <div>
                    <Tooltip title="更新"><Button type={"link"} icon={"sync"} onClick={()=>{this.props.onGetTodoWork();}}/></Tooltip>
                    <Tooltip title="查看全部"><Button type={"link"} icon={"more"} onClick={()=>{this.props.history.push('work/todo')}}/></Tooltip>
                </div>;
        return (<Card title={"待办任务"} extra={extra}>
                <List dataSource={data} renderItem={item => (
                    <List.Item.Meta title={<Row>
                        <Col span={12}><Button type="link" onClick={()=>{this.showContent(item.uuid)}}>{item.title}</Button></Col>
                        <Col span={6} offset={6}>{moment(new Date(item.remind_time)).format('YYYY-MM-DD HH:mm:ss')}</Col>
                        </Row>} description={""}/>)}/>
                </Card>);
    }
    renderApprove(){
        let data = [];
        return (<Card title={"审批"} extra={<Button type={"link"} icon="sync"/>}>
            <List dataSource={data} renderItem={item => (
                    <List.Item.Meta title={<Row>
                        <Col span={12}><Button type="link" onClick={()=>{this.showContent(item.uuid)}}>{item.title}</Button></Col>
                        <Col span={6} offset={6}>{moment(new Date(item.remind_time)).format('YYYY-MM-DD HH:mm:ss')}</Col>
                        </Row>} description={""}/>)}/>
            </Card>);
    }
    renderNotice(){
        const colorStyle = {color:'RGB(53,164,254)'};
        let unreadList = [];
        let allList = [];
        if(this.props.get_notice_result){
            for (let i = 0;i < this.props.get_notice_result.length;i++){
                let item = this.props.get_notice_result[i];
                let detail = item.detail;
                if(-1 === detail.read_list.indexOf(localStorage.getItem('account'))){
                    unreadList.push({title:item.title,content:detail.content_file_name});
                }
                allList.push({title:item.title,content:detail.content_file_name});
            }
        }
        let extra = <div>
                    <Tooltip title={"更新"}><Button type={"link"} onClick={()=>{this.props.onGetNotice();}} icon={"sync"}/></Tooltip>
                    <Tooltip title={"查看全部"}><Button type={"link"} onClick={()=>{return this.props.history.push('work/notice')}} icon={"more"}/></Tooltip>
                </div>;
        return (<Card title={"通知/广播"} extra={extra}>
            <Tabs defaultActiveKey={"1"}>
                <TabPane tab="未读" key={"1"}>
                    <List dataSource={unreadList} renderItem={item => 
                    (<List.Item.Meta title={<span style={colorStyle}>{item.title}</span>} description={delHtmlTag(item.content)}/>)}/>
                </TabPane>
                <TabPane tab="全部" key={"2"}>
                    <List dataSource={allList} renderItem={item => 
                    (<List.Item.Meta title={<span style={colorStyle}>{item.title}</span>} description={delHtmlTag(item.content)}/>)}/>
                </TabPane>
            </Tabs>
        </Card>);
    }
    renderWorkOrder(){
        const colorStyle = {color:'RGB(53,164,254)'};
        let data = [];
        if(this.props.get_work_order_result){
            let result = this.props.get_work_order_result;
            for(let i = 0;i<result.length;i++){
                let detail = result[i].detail;
                if(workOrderStatusList[0] === result[i].status){
                    data.push({title:result[i].title,content:detail.content});
                }
            }
        }
        let extra = <div>
                        <Tooltip title={"更新"}><Button type={"link"} icon="sync"/></Tooltip>
                        <Tooltip title={"查看全部"}><Button type={"link"} onClick={()=>{this.props.history.push('work/work_order')}} icon="more"/></Tooltip>
                    </div>;
        return (<Card title={"工单"} extra={extra}>
                    <List dataSource={data} renderItem={item => 
                        <List.Item.Meta title={<span style={colorStyle}>{item.title}</span>} description={delHtmlTag(item.content)}/>}/>
        </Card>);
    }
    renderBrief(){
        let header = <Row>
            <Col lg={3} xs={10}>个人简报</Col>
            <Col lg={3} xs={12}>
                <Select defaultValue={'本周'} style={{width:'100%'}} onChange={(value)=>{this.setState({briefTimeSelect:value})}}>
                    <Option value={'today'}>今日</Option>
                    <Option value={'week'}>本周</Option>
                    <Option value={'month'}>本月</Option>
                    <Option value={'year'}>本年</Option>
                </Select>
            </Col>
        </Row>
        let extra = <div>
                <Tooltip title={"更新"}><Button type={"link"} onClick={()=>{this.syncBrief()}} icon="sync"/></Tooltip>
                <Tooltip title={"设置"}><Button type={"link"} icon="setting"/></Tooltip>
            </div>;
        let newCustomCount = 0;
        if(this.props.get_custom_result){
            for(let i = 0;i < this.props.get_custom_result.length;i++){
                let item = this.props.get_custom_result[i];
                if(isToday(item.create_time) && "today" === this.state.briefTimeSelect){
                    newCustomCount++;
                }
                else if(isThisWeek(item.create_time) && "week" === this.state.briefTimeSelect){
                    newCustomCount++;
                }
                else if(isThisMonth(item.create_time) && "month" === this.state.briefTimeSelect){
                    newCustomCount++;
                }
                else if(isThisYear(item.create_time) && "year" === this.state.briefTimeSelect){
                    newCustomCount++;
                }
            }
        }
        let followUpCount = 0;
        if(this.props.get_followup){
            for (let i = 0;i < this.props.get_followup_result.length;i++){
                let item = this.props.get_followup_result[i];
                if("today" === this.state.briefTimeSelect && isToday(item.create_time)){
                    followUpCount++;
                }
                else if("week"===this.state.briefTimeSelect && isThisWeek(item.create_time)){
                    followUpCount++;
                }
                else if("month" === this.state.briefTimeSelect && isThisMonth(item.create_time)){
                    followUpCount++;
                }
                else if("year" === this.state.briefTimeSelect && isThisYear(item.create_time)){
                    followUpCount++;
                }
            }
        }
        let successAmount = 0;
        let successOrderCount = 0;
        if(this.props.get_order_result){
            for(let i = 0;i < this.props.get_order_result.length;i++){
                let item = this.props.get_order_result[i];
                let detail = (item.detail);
                if(isToday(detail.end_time)&& "today" === this.state.briefTimeSelect && item.status === ORDER_STATUS_SUCCESS){
                    successOrderCount++;
                    successAmount+=item.amount;
                }
                else if(isThisWeek(detail.end_time) && "week" === this.state.briefTimeSelect&& item.status === ORDER_STATUS_SUCCESS){
                    successOrderCount++;
                    successAmount+=item.amount;
                }
                else if(isThisMonth(detail.end_time) && "month" === this.state.briefTimeSelect && item.status === ORDER_STATUS_SUCCESS){
                    successOrderCount++;
                    successAmount+=item.amount;
                }
                else if(isThisYear(detail.end_time) && "year" === this.state.briefTimeSelect && item.status === ORDER_STATUS_SUCCESS){
                    successOrderCount++;
                    successAmount+=item.amount;
                }
            }
        }
        const style = {color:'RGB(53,164,254)',fontSize:'30px'};
        const cardStyle = {maxWidth:'200px'};
        const layout = {lg:{span:5},xs:{span:20}}
        return (<Card title={header} extra={extra}>
                    <Row>
                        <Col {...layout}>
                            <Card style={cardStyle} title={<span style={style}>{newCustomCount}</span>}>新增客户数</Card>
                        </Col>
                        <Col {...layout}>
                            <Card style={cardStyle} title={<span style={style}>{followUpCount}</span>}>联系跟进数量</Card>
                        </Col>
                        <Col {...layout}>
                            <Card style={cardStyle} title={<span style={style}>{successAmount}</span>}>成交金额</Card>
                        </Col>
                        <Col {...layout}>
                            <Card style={cardStyle} title={<span style={style}>{successOrderCount}</span>}>成交订单数</Card>
                        </Col>
                    </Row>
                </Card>);
    }
    render() {
        return (<Row style={{maxHeight:'100%',overflow:'auto'}}>
                    <Row><Col span={23}>{this.renderBrief()}</Col></Row>
                    <br/>
                    <Row>
                        <Col lg={11} xs={22}>{this.renderRate()}</Col>
                        <Col lg={{span:11,offset:1}} xs={22}>{this.renderWorkGoal()}</Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col lg={11} xs={22}>
                            <Row>{this.renderSalesFunnel()}</Row>
                        </Col>
                        <Col lg={{span:11,offset:1}} xs={22}>
                            <Row>{this.renderWorkOrder()}</Row>
                            <br/>
                            <Row>{this.renderTodoWork()}</Row>
                            <br/>
                            <Row>{this.renderNotice()}</Row>
                        </Col>
                    </Row>
                </Row>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(WorkStation)