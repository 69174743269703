import React from "react";
import {Select,Input,Modal, Divider} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
const { Option } = Select;

class ExtendSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {items:this.props.items,
                        showItems:[],
                        newItemName:'',
                        visible:false};
    }
    componentDidMount(){
        if(!this.props.items){return ;}
        let items = this.props.items;
        let showItems = []
        for (let i = 0;i < items.length;i++){
            if (i > 20){break;}
            let item = items[i];
            showItems.push(item);
        }
        this.setState({showItems:showItems});
    }
    shouldComponentUpdate(nextProps, nextState, nextContext){
        if(this.props.items !== nextProps.items){
            this.setState({items:nextProps.items});
            let data = [];
            let items = nextProps.items;
            for (let i = 0;i < items.length;i++){
                if (i > 20){break;}
                let item = items[i];
                data.push(item);
            }
            this.setState({showItems:data});
        }
        return true;
    }
    onNameChange = event => {
        this.setState({newItemName: event.target.value});
    };
    fetchItem = value => {
        console.log('fetching user', value);
        if (0 === value.length){
            let data = []
            for (let i = 0;i < this.state.items.length;i++){
                if (i > 20){break;}
                let item = this.state.items[i];
                data.push(item);
            }
            this.setState({ showItems:data, fetching: false });
            return;
        }
        //this.lastFetchId += 1;
        //const fetchId = this.lastFetchId;
        this.setState({ data: [], fetching: true });
        let data = []
        for (let i = 0;i < this.props.items.length;i++){
            let item = this.props.items[i];
            if(-1 !== item.indexOf(value)){
                data.push(item);
            }
        }
        console.log(this.state.showItems)
        this.setState({ showItems:data, fetching: false });
    };
    onOk = () => {
        let { items, newItemName,showItems } = this.state;
        if(-1 !== items.indexOf(newItemName)){
            this.setState({visible:false});
            return ;
        }
        this.setState({showItems:[...showItems,newItemName], newItemName: '', visible:false});
    }
    render() {
        return (<div>
                    <Modal title={this.props.title} visible={this.state.visible}
                        onCancel={()=>{this.setState({visible:false})}} onOk={()=>{this.onOk()}}
                        cancelText={"取消"} okText={"添加"}>
                        <Input placeholder={this.props.placeholder} type={this.props.type}
                            onChange={(event) =>this.onNameChange(event)}/>
                    </Modal>
                    <Select style={this.props.style} placeholder={this.props.placeholder} onChange={this.props.onChange} size={this.props.size}
                        onSearch={this.fetchItem} showSearch={true} disabled={this.props.disabled} value={this.props.value} defaultValue={this.props.defaultValue}
                        allowClear={this.props.allowClear} dropdownRender={menu => (
                            <div>
                                {menu}
                                <Divider style={{ margin: '4px 0' }} />
                                <div style={{ padding: '4px 8px', cursor: 'pointer' }}
                                onMouseDown={e => e.preventDefault()} disabled={this.props.disabled}
                                onClick={()=>{this.setState({visible:true})}}>
                                <PlusOutlined/><span>自定义</span>
                                </div>
                            </div>
                            )}>
                        {this.state.showItems.map(item => <Option key={item} value={item}>{item}</Option>)}
                    </Select>
                </div>);
    }
}
export default ExtendSelect;
