import auth from "./reducers/auth";
import custom from "./reducers/custom";
import order from "./reducers/order"
import notice from "./reducers/notice";
import product from "./reducers/product";
import staff from "./reducers/staff";
import enterprise from "./reducers/enterprise";
import todoWork from "./reducers/todoWork";
import purchase from "./reducers/purchase";
import ship from "./reducers/ship";
import travel from "./reducers/travel";
import leave from "./reducers/leave";
import collection from "./reducers/collection";
import expense from "./reducers/expense";
import workOrder from "./reducers/workOrder";
import businessOpportunity from "./reducers/businessOpportunity";
import loginLog from "./reducers/loginlog";
import performanceGoal from "./reducers/performancegoal";
import supplier from "./reducers/suppliper";
import worklog from "./reducers/workLog";
import { combineReducers } from 'redux';

export default combineReducers({auth,custom,order,notice,product,staff,enterprise,todoWork,purchase,supplier,worklog,
    ship,travel,leave,collection,expense,workOrder,businessOpportunity,loginLog,performanceGoal});