import React from 'react';
import {Table,Input, Tag,Button,Row,Col,Modal,Divider,Form} from 'antd';
import {updateUserDetail} from "../../actions/userDBTool";
import {SearchOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import {connect} from "react-redux";
import $ from 'jquery'
const {confirm} = Modal;
function mapStateToProps(status) {
    return {...status.staff};
}
function mapDispatchToProps(dispatch) {
    return {onUpdateUserDetail:(detail)=>dispatch(updateUserDetail(detail))}
}
class ExtendTable extends Table{
    constructor(props) {
        super(props);
        this.state = {columns:[], allColumns:[],selectColumns:[],unSelectColumns:[],selectedRowKeys:[],customColumnsVisible:false};
        this.handleBatchDelete = this.handleBatchDelete.bind(this);
        this.handleBatchEdit = this.handleBatchEdit.bind(this);
        this.handleColumnsSubmit = this.handleColumnsSubmit.bind(this);
        this.closeSelectColumns = this.closeSelectColumns.bind(this);
        this.addSelectColumns = this.addSelectColumns.bind(this);
        this.selectAllCoumns = this.selectAllCoumns.bind(this);
        this.resetDefaultColumns   = this.resetDefaultColumns.bind(this);
    }
    componentDidMount(){
        let selectColumns = [];
        let unSelectColumns = [];
        let allColumns = [];
        let columns = [];
        this.props.columns.forEach(item=>{
            if(item.dataIndex === 'actions'||item.key === 'actions'){return;}
            selectColumns.push({key:item.key,title:item.title,dataIndex:item.dataIndex});
            allColumns.push({key:item.key,title:item.title,dataIndex:item.dataIndex});
        })
        let detail = JSON.parse(localStorage.getItem('staff_detail'));
        if(detail && detail['field']){
            for(let i = 0;i < detail['field'].length;i++){
                if(detail['field'][i].key === this.props.tableKey && this.props.customColumnsEnable){
                    selectColumns = detail['field'][i].columns;
                }
            }
            allColumns.forEach(item=>{
                if(item.dataIndex === 'actions'||item.key === 'actions'){
                    return;
                }
                else if(-1 === selectColumns.findIndex(text=>text.key===item.key)){
                    unSelectColumns.push(item);
                }
            })
        }
        this.props.columns.forEach(item=>{
            if(item.dataIndex === 'actions'||item.key === 'actions'){
                columns.push(item);
            }
            else if(-1 !== selectColumns.findIndex(text=>text.key===item.key)){
                if(!item.isSearch){ 
                    columns.push(item);
                }
                else{
                    let temp = $.extend({},{...this.getColumnSearchProps(item.dataIndex)},item);
                    columns.push(temp);
                }
            }
        });
        this.setState({columns: columns,allColumns:allColumns,selectColumns:selectColumns,unSelectColumns:unSelectColumns});
    }
    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    }
    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input ref={node => {this.searchInput = node;}} placeholder={`搜索 ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}/>
                <Button
                    type="primary" icon="search" size="small"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 90, marginRight: 8 }}>
                    筛选
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    重置
                </Button>
            </div>),
        filterIcon: filtered => (
            <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />),
        onFilter: (value, record) =>record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => this.state.searchedColumn === dataIndex ? (
                <Highlighter highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }} searchWords={[this.state.searchText]}
                    autoEscape textToHighlight={text.toString()}
                />) : (text),
    })
    handleColumnsSubmit(){
        let detail = JSON.parse(localStorage.getItem('staff_detail'));
        let field = {key:this.props.tableKey,columns:this.state.selectColumns};
        if(detail['field']){
            for(let i = 0;i < detail['field'].length;i++){
                if(detail['field'][i].key === this.props.tableKey){
                    detail['field'].splice(i,1);
                }
            }
        }
        else{ 
            detail['field'] = [];
        }
        detail['field'].push(field);
        this.props.onUpdateUserDetail(detail);
        let columns = [];
        this.props.columns.forEach(item=>{
            if(item.dataIndex === 'actions'||item.key === 'actions'){
                columns.push(item);
            }
            else if(-1 !== this.state.selectColumns.findIndex(text=>text.key===item.key)){
                if(!item.isSearch){ 
                    columns.push(item);
                }
                else{
                    let temp = $.extend({},{...this.getColumnSearchProps(item.dataIndex)},item);
                    columns.push(temp);
                }
            }
        });
        this.setState({customColumnsVisible:false,columns:columns});
    }
    handleBatchEdit(){
        if(this.props.onBatchEdit){
            this.props.onBatchEdit(this.state.selectedRowKeys);
        }
    }
    handleBatchDelete(){
        confirm({
            content: '确认是否要删除已选？删除后无法撤回?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk:()=> {
                this.props.onBatchDelete(this.state.selectedRowKeys);
            },
            onCancel() {
                console.log('Cancel');
            },
        })
    }
    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };
    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    }
    closeSelectColumns(item){
        let unSelectColumns = this.state.unSelectColumns;
        let selectColumns = this.state.selectColumns;
        unSelectColumns.push(item);
        selectColumns.splice(selectColumns.indexOf(item),1);
        this.setState({unSelectColumns:unSelectColumns,selectColumns:selectColumns});
    }
    addSelectColumns(item){
        let selectColumns = this.state.selectColumns;
        selectColumns.push(item);
        this.setState({selectColumns:selectColumns});
    }
    selectAllCoumns(){
        let allColumns = [];
        this.state.allColumns.forEach(element=>{
            allColumns.push(element);
        })
        this.setState({selectColumns:allColumns,unSelectColumns:[]});
    }
    resetDefaultColumns(){
        this.selectAllCoumns();
    }
    renderCustomColumn(){
        return (<Modal visible={this.state.customColumnsVisible} title="自定义列" width='800px' maskClosable={false}
                    onCancel={()=>{this.setState({customColumnsVisible:false})}} 
                    onOk={()=>this.handleColumnsSubmit()} okText="确定" cancelText="取消">
                    <Form>
                        <Row>
                            <Col span={6}>已添加 &nbsp;{/*<span style={{fontSize:'12px',color:'grey'}}>可拖动以调整次序</span>*/}</Col>
                            <Col span={3} offset={12}>
                                <Button type="link" onClick={()=>{this.selectAllCoumns()}}>选择所有列</Button> &nbsp;
                            </Col>
                            <Col span={3}>
                                <Button type="link" onClick={()=>{this.resetDefaultColumns()}}>恢复默认</Button>
                            </Col>
                        </Row>
                        <Row>
                            {this.state.selectColumns?this.state.selectColumns.map(item => {
                                const tagElem = (<Tag key={item.key} closable onClose={()=>{this.closeSelectColumns(item)}}>{item.title}</Tag>)
                                return (tagElem)
                            }):null}
                        </Row>
                        <Divider/>
                        <Row>
                            <span>可添加 &nbsp;<span style={{fontSize:'12px',color:'grey'}}>点击X号,添加到显示列</span></span>
                        </Row>
                        <Row>
                            {this.state.unSelectColumns?this.state.unSelectColumns.map(item =>{
                                const tagElem = (<Tag key={item.key} closable onClose={()=>{this.addSelectColumns(item)}}>{item.title}</Tag>)
                                return (tagElem)
                            }):null}
                        </Row>
                    </Form>
                </Modal>);
    }
    renderSetting(){
        const hasSelected = this.state.selectedRowKeys.length > 0;
        return (<div>
                    <Button type="primary" onClick={this.props.handleAddOnClick}>新增</Button>&nbsp;&nbsp;
                    {this.props.batchEditEnable?(<Button disabled={!hasSelected} type="primary" onClick={this.handleBatchEdit}>批量编辑</Button>):null}&nbsp;&nbsp;
                    {this.props.batchDelEnable?(<Button disabled={!hasSelected} type='danger' onClick={this.handleBatchDelete}>删除选中</Button>):null}&nbsp;&nbsp;
                    {this.props.customColumnsEnable?(<Button type={"primary"} onClick={()=>{this.setState({customColumnsVisible:true});}}>自定义列</Button>):null}&nbsp;&nbsp;
                    {this.props.exportEnable?(<Button type="primary" onClick={this.props.exportClick}>{this.props.exportTitle}</Button>):null}&nbsp;&nbsp;
                    <Button type='primary' hidden={!this.props.extendEnable} onClick={this.props.extendClick}>{this.props.extendTitle}</Button>
            </div>);
    }
    render() {
        const {selectedRowKeys} = this.state;
        const rowSelection = {selectedRowKeys,onChange: this.onSelectChange};
        let maxHeight = document.body.clientHeight > 900?600:500
        return (<div>
                    <Table style={{background:'#fff'}} size="small" scroll={{y:maxHeight,x:1500}} columns={this.state.columns}
                        dataSource={this.props.dataSource} rowKey={this.props.rowKey} rowSelection={rowSelection}
                        pagination={{showSizeChanger:true,pageSizeOptions:['10','20','50','100','200'],total:this.props.total}}/>
                    {this.renderSetting()}
                    {this.renderCustomColumn()}
                </div>)
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(ExtendTable)