import React from 'react';
import {Row,Tabs} from 'antd';
import ProductTable from '../controls/ProductTable';
import {isToday} from "../../utils/common";
import {PRODUCT_STATUS_NEW} from "../../constants/constant";
import {getAllProduct} from "../../actions/productDBTool";
import {USER_LEVEL_MEMBER} from "../../constants/constant";
import {connect} from "react-redux";
const { TabPane } = Tabs;
function mapStateToProps(state) {
    return {...state.product};
}
function mapDispatchToProps(dispatch) {
    return {onGetAllProduct:()=>dispatch(getAllProduct())};
}
class NewProduct extends React.Component{
    constructor(props){
        super(props);
        this.state = { };
    }
    renderNewProduct(){
        let todayNewDS = [];
        let allNewDS = [];
        if(this.props.get_product_result){
            let result = this.props.get_product_result;
            for(let i = 0;i < result.length;i++){
                if(PRODUCT_STATUS_NEW === result[i].status){
                    if(isToday(result[i].create_time)){todayNewDS.push(result[i]);}
                    allNewDS.push(result[i]);
                }
            }
        }
        const productEditable = USER_LEVEL_MEMBER < Number(localStorage.getItem('level'))?true:false
        return (<Tabs defaultActiveKey="1" type="card">
                    <TabPane key="1" tab="全部待上架">
                        <ProductTable dataSource={allNewDS} customColumnsEnable={true} exportEnable={productEditable} editEnable={productEditable} batchDelEnable={productEditable} tableKey={'product/new_product'}/>
                    </TabPane>
                    <TabPane key="2" tab="今日新增待上架">
                        <ProductTable dataSource={todayNewDS} customColumnsEnable={true} exportEnable={productEditable} editEnable={productEditable} batchDelEnable={productEditable} tableKey={'product/new_product'}/>
                    </TabPane>
                </Tabs>);
    }
    render(){
        return(<Row>{this.renderNewProduct()}</Row>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(NewProduct)