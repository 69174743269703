import React from 'react';
import {Row,Tabs} from 'antd';
import ProductTable from '../controls/ProductTable';
import { PRODUCT_STATUS_ON_SALE} from "../../constants/constant";
import {isToday} from "../../utils/common";
import {getAllProduct} from "../../actions/productDBTool";
import {USER_LEVEL_MEMBER} from "../../constants/constant";
import {connect} from "react-redux";
const { TabPane } = Tabs;
function mapStateToProps(state) {
    return {...state.product};
}
function mapDispatchToProps(dispatch) {
    return {onGetAllProduct:()=>dispatch(getAllProduct())};
}
 class OnsaleProduct extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            OnSaleProductDS:[]
        };
    }
    renderOnSaleProduct(){
        let todayDS = [];
        let allDS = [];
        if(this.props.get_product_result){
            let result = this.props.get_product_result;
            console.log(result);
            for(let i = 0;i < result.length;i++){
                if(PRODUCT_STATUS_ON_SALE === result[i].status){
                    if(isToday(result[i].create_time)){todayDS.push(result[i]);}
                    allDS.push(result[i]);
                }
            }
        }
        const productEditable = USER_LEVEL_MEMBER < Number(localStorage.getItem('level'))?true:false
        return (<Tabs defaultActiveKey="1" type="card">
                    <TabPane key="1" tab="全部在售">
                        <ProductTable dataSource={allDS} customColumnsEnable={true} exportEnable={productEditable} editEnable={productEditable} batchDelEnable={productEditable} tableKey={'product/onsale_product'}/>
                    </TabPane>
                    <TabPane key="2" tab="今日新增在售">
                        <ProductTable dataSource={todayDS} customColumnsEnable={true} exportEnable={productEditable} editEnable={productEditable} batchDelEnable={productEditable} tableKey={'product/onsale_product'}/>
                    </TabPane>
                </Tabs>);
    }
    render(){
        return(<Row>{this.renderOnSaleProduct()}</Row>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(OnsaleProduct)