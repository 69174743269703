import React from 'react';
import { Gauge } from '@ant-design/charts';
export default class GaugeChart extends React.Component{
    constructor(props) {
        super(props);
        this.state = {};
    }
    render(){
      var config = {
          percent: this.props.percent,
          range: {
            ticks: [0, 1 / 3, 3/5, 4 / 5, 1],
            color: ['#F4664A', '#FAAD14','#6294f8', '#30BF78'],
          },
          indicator: {
            pointer: { style: { stroke: '#D0D0D0' } },
            pin: { style: { stroke: '#D0D0D0' } },
          },
          statistic: {
            content: {
              style: {
                fontSize: '36px',
                lineHeight: '36px',
              },
            },
          },
        };
        return <Gauge {...config} />;
    }
}