import React from "react";
import {connect} from "react-redux";
import { getAllOrder } from "../../actions/orderDBTool";
import ExtendSelect from "./ExtendSelect";
function mapStateToProps(state) {
    return {...state.custom,...state.order}
}
function mapDispatchToProps(dispatch) {
    return {
        //onGetAllCustom:()=>dispatch(getAllCustom()),
        onGetAllOrder:()=>dispatch(getAllOrder())
    }
}
class OrderChoose extends React.Component{
    constructor(props){
        super(props);
        this.state = {visible:false};
    }
    componentDidMount(){
        //this.props.onGetAllCustom();
        this.props.onGetAllOrder();
    }
    saveFormRef = formRef => {this.formRef = formRef;}
    render(){
        let orderList = [];
        if(this.props.get_order_result){
            for(let i = 0;i < this.props.get_order_result.length;i++){
                let item = this.props.get_order_result[i];
                orderList.push(item.order_id);
            }
        }
        return (<ExtendSelect items={orderList} title={this.props.title} placeholder={this.props.placeholder}
                    value={this.props.value} onChange={this.props.onChange} disabled={this.props.disabled}/>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps,null,{forwardRef: true})(OrderChoose);