import React from 'react';
import {Row} from 'antd';
class StatisticByTime extends React.Component {
    constructor(props){
        super(props);
        this.state = {};
    }
    render(){
        return (
            <Row>
            </Row>
        );
    }
}
export default StatisticByTime;