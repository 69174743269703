import {ActionType} from "../constants/actionTypes";

const staffReducer = (state={},action)=>{
    switch (action.type) {
        case ActionType.ADD_STAFF_START:
            return{...state, add_staff:false};
        case ActionType.ADD_STAFF_FAILED:
            return{...state, add_staff:false, add_staff_errors:action.payload?action.payload:null};
        case ActionType.ADD_STAFF_SUCCESS:
            return {...state, add_staff:true, add_staff_result:action.payload};


        case ActionType.ADD_STAFF_DEL_START:
            return {...state,add_staff_del:false};
        case ActionType.ADD_STAFF_DEL_FAILED:
            return {...state,add_staff_del:false,add_staff_del_errors:action.payload?action.payload:null};
        case ActionType.ADD_STAFF_DEL_SUCCESS:
            return {...state, add_staff_del:true,add_staff_del_result:action.payload};


        case ActionType.UPDATE_STAFF_START:
            return {...state, update_staff:false}
        case ActionType.UPDATE_STAFF_FAILED:
            return {...state, update_staff:false,update_staff_errors:action.payload?action.payload:null}
        case ActionType.UPDATE_STAFF_SUCCESS:
            return {...state, update_staff:true,update_staff_result:action.payload}


        case ActionType.GET_STAFF_BY_ENTERPRISE_START:
            return {...state, get_staff:false}
        case ActionType.GET_STAFF_BY_ENTERPRISE_FAILED:
            return {...state, get_staff:false,get_staff_errors:action.payload?action.payload:null}
        case ActionType.GET_STAFF_BY_ENTERPRISE_SUCCESS:
            return {...state, get_staff:true,get_staff_list_result:action.payload}


        case ActionType.GET_STAFF_DEL_BY_ENTERPRISE_START:
            return {...state, get_staff_del:false}
        case ActionType.GET_STAFF_DEL_BY_ENTERPRISE_FAILED:
            return {...state,get_staff_del:false,get_staff_del_errors:action.payload?action.payload:null}
        case ActionType.GET_STAFF_DEL_BY_ENTERPRISE_SUCCESS:
            return {...state, get_staff_del:true, get_staff_del_result:action.payload}


        default:
            return {...state};
    }
}
export default staffReducer;