import $ from 'jquery'
//import axios from "axios";
import {message} from "antd";
import * as Service from '../config/service.json';
import {ActionType} from "../constants/actionTypes";
import {preHandle,checkAccountStatus} from './commonDBTool';

export function addExpense(postData){
    let url = Service.dbServer + Service.apiVersion + "/expense/add" ;
    return dispatch => {
        dispatch({type: ActionType.ADD_EXPENSE_START});
        $.ajax(preHandle(url), {
            method: 'POST',
            contentType: 'application/json; charset=utf-8',
            data:JSON.stringify(postData)
        }).then((response) => {
            if(!checkAccountStatus(response.code,response.message)){return;}
            message.info("添加成功!");
            dispatch({type: ActionType.ADD_EXPENSE_SUCCESS, payload: response.result});
            dispatch(getExpense());
        }).catch(function (error) {
            message.error("添加失败!");
            dispatch({type: ActionType.ADD_EXPENSE_FAILED, payload: error});
        })
    }
}
export function getExpense(pageStart=0,pageSize=50) {
    let url = Service.dbServer + Service.apiVersion + "/expense/getAll"  ;
    return dispatch => {
        dispatch({type: ActionType.GET_EXPENSE_START});
        $.ajax(preHandle(url), {
            method: 'GET',
            contentType: 'application/json; charset=utf-8',
            dataType: 'json',
            data:{pageStart:pageStart,pageSize:pageSize}
        }).then((response) => {
            if(!checkAccountStatus(response.code,response.message)){return;}
            let result = response.result
            if(result){
                for(let i = 0;i < result.length;i++){
                    result[i].detail = JSON.parse(result[i].detail);
                }
            }
            dispatch({type: ActionType.GET_EXPENSE_SUCCESS, payload: result});
        }).catch(function (error) {
            dispatch({
                type: ActionType.GET_EXPENSE_FAILED,
                payload: error
            });
        })
    }
}
export function updateExpense(postData){
    let url = Service.dbServer + Service.apiVersion + "/expense/update" ;
    return dispatch => {
        dispatch({type: ActionType.UPDATE_EXPENSE_START});
        $.ajax(preHandle(url), {
            method: 'POST',
            contentType: 'application/json; charset=utf-8',
            data: JSON.stringify(postData)
        }).then((response) => {
            if(!checkAccountStatus(response.code,response.message)){return;}
            message.info("更新成功!");
            dispatch({type: ActionType.UPDATE_EXPENSE_SUCCESS, payload: response.result});
            dispatch(getExpense());
        }).catch(function (error) {
            message.error("更新失败!")
            dispatch({type: ActionType.UPDATE_EXPENSE_FAILED, payload: error});
        })
    };
}
export function deleteExpense(uuid){
    let url = Service.dbServer + Service.apiVersion + "/expense/del" ;
    return dispatch => {
        dispatch({type: ActionType.DELETE_EXPENSE_START});
        $.ajax(preHandle(url), {
            method: 'delete',
            //contentType: 'application/json; charset=utf-8',
            dataType: 'json',
            data:({uuid:uuid})
        }).then(response => {
            if(!checkAccountStatus(response.code,response.message)){return;}
            message.info("删除成功!");
            dispatch({type: ActionType.DELETE_EXPENSE_SUCCESS, payload: response.result});
            dispatch(getExpense());
        }).catch(function (error) {
            message.error("删除失败!");
            dispatch({type: ActionType.DELETE_EXPENSE_FAILED, payload: error});
        })
    };
}
export function batchDeleteExpense(uuid_list){
    let url = Service.dbServer + Service.apiVersion + "/expense/batchDel" ;
    return dispatch=>{
        $.ajax(preHandle(url), {
            method: 'post',
            dataType: 'json',
            data:({'expense_list':JSON.stringify(uuid_list),})
        }).then(response => {
            if(!checkAccountStatus(response.code,response.message)){return;}
            if(200 === response.code){
                message.info("删除成功!");
                dispatch(getExpense());
            }
        }).catch(function (error) {
            message.error("删除失败!");
        })
    }
}