import React from 'react';
import {Row,Tabs} from 'antd';
import CustomTable from '../../controls/CustomTable';
import {getAllCustom} from "../../../actions/customDBTool";
import {connect} from "react-redux";
import {isToday} from "../../../utils/common";
import {USER_LEVEL_MEMBER} from "../../../constants/constant";

const { TabPane } = Tabs;
function mapStateToProps(state) {
    return {...state.custom};
}
function mapDispatchToProps(dispatch) {
    return {onGetCustom:()=>dispatch(getAllCustom({isCommon:true}))};
}
class CommonCustom extends React.Component{
    constructor(props) {
        super(props);
        this.state = {}
    }
    componentDidMount() {
        this.props.onGetCustom();
    }
    renderCommonCustom(){
        let commonCustomDS = [];
        let todayCommonDS = [];
        if(this.props.get_common_custom_result){
            let result = this.props.get_common_custom_result;
            for(let i = 0;i < result.length;i++){
                commonCustomDS.push(result[i]);
                if(isToday(result[i].create_time)){
                    todayCommonDS.push(result[i]);
                }
            }
        }
        const customEditable = USER_LEVEL_MEMBER < Number(localStorage.getItem('level'))?true:false
        return(<Tabs defaultActiveKey={"1"}>
            <TabPane key={"1"} tab={"全部"}>
                <CustomTable dataSource={commonCustomDS} tableKey={'crm/common_custom'} exportEnable={customEditable} editEnable={customEditable} batchDelEnable={customEditable} batchEditEnable={customEditable}/>
            </TabPane>
            <TabPane key={"2"} tab={"今日新增"}>
                <CustomTable dataSource={todayCommonDS} tableKey={'crm/common_custom'} exportEnable={customEditable} editEnable={customEditable} batchDelEnable={customEditable} batchEditEnable={customEditable}/>
            </TabPane>
        </Tabs>)
    }
    render(){
        return (<Row>
                    {this.renderCommonCustom()}
                    <span style={{color:'red'}}>公海客户:</span>指未被成员跟进，且所有成员均可以查看的客户
                </Row>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(CommonCustom);