import * as Minio from "minio";
import stream from 'stream';
import * as Service from '../config/service.json';
const minioClient = new Minio.Client({
    endPoint: 'usc1.contabostorage.com',
    port: 443,
    useSSL: true,
    accessKey: 'd3c423bc765d4a6c803f8b848c96ccf0',
    secretKey: '7c1f901abb67aef5fe22a67d63d95be7',
}); 
export function sizeOfStoreage(callback){
    let data = []
    let size = 0;
    let streambuff = minioClient.listObjects(Service.resBucket,localStorage.getItem("enterprise_uuid")+"/",true, {IncludeVersion:true})
    streambuff.on('data', function(obj) {data.push(obj)})
    streambuff.on("end",function(obj){
        for(let i=0; i<data.length;i++){
            size += data[i].size
        }
        callback(size)
    })
}
export function uploadFile(path,file,callback){
    let metaData = { //属性值
        'Content-Type': file.type,
        'Content-Length': file.size,
        'x-amz-acl': 'public-read'
    }
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file)
    /*let policy = {
        "Id": "CntbPolicy",
        "Version": "2012-10-17",
        "Statement": [{
            "Sid": "PublicRead",
            "Action": ["s3:GetObject", "s3:GetObjectVersion"],
            "Effect": "Allow",
            "Resource": ["arn:aws:s3:::res.crm/*"],
            "Principal": "*"
        }]
    }
    minioClient.setBucketPolicy(Service.resBucket,JSON.stringify(policy),function(err,policy){
        console.log(err)
        //console.log(policy)
    })*/
    /*minioClient.getBucketPolicy(Service.resBucket,function(err,policy){
        console.log(err)
        console.log(policy)
    })*/
    fileReader.onload = (e)=>{
        try {
            const bufferStream = new stream.PassThrough();
            bufferStream.end(Buffer.from(e.target.result));// 将buffer写入
            let completePath = localStorage.getItem("enterprise_uuid") +"/" + path;
            minioClient.putObject(Service.resBucket, completePath + file.name,bufferStream,metaData,function(err, etag){
                if (err) return console.log(err)
                console.log('File uploaded successfully.')
                callback()
                /*minioClient.presignedGetObject(Service.resBucket, file.name,(err1, presignedUrl) => {
                    if (err1) return;
                    console.log(presignedUrl)
                  });*/
            })
           
            /*minioClient.statObject(Service.resBucket,localStorage.getItem("enterprise_uuid")+"/profile/",function(err,stat){
                console.log(stat)
            })*/
        }
        catch (e) {
            console.log(e)
            return false;
        }
    }
}
export function checkObjectExist(fileName,callback){
    minioClient.getObject(Service.resBucket,localStorage.getItem("enterprise_uuid") +"/" + fileName,function(err,dataStream){
        callback(err,dataStream)
    })
}
export function deleteObject(fileName,callback){
    minioClient.removeObject(Service.resBucket,localStorage.getItem("enterprise_uuid") +"/" + fileName,function(err3){
        callback(err3)
    })
}