import $ from 'jquery'
//import axios from "axios";
import {push} from 'react-router-redux';
import {message} from "antd";
import * as Service from '../config/service.json';
import {ActionType} from "../constants/actionTypes";

/*export function preHandle(postData){
        let data = (postData);
        data['account'] = localStorage.getItem("account");
        data["login_token"] = localStorage.getItem("login_token");
        return data;
    }*/
export function preHandle(url){
    return  url + "?account="+localStorage.getItem("account") + "&login_token=" + localStorage.getItem("login_token");
} 
export function checkAccountStatus(resultCode,info) {
    if(!localStorage.getItem("account")){
        return false;
    }
    if( 2005 === resultCode || 2006 === resultCode ||
        2007 === resultCode || 2009 === resultCode){
        message.error(info);
        logout();
        return false;
    }
    else if(2002 === resultCode){//account expired
        localStorage.setItem("account_expired",true);
        message.error("账户已过期，请续费后再继续操作!",10)
        push('/allItems/more/payment');
        return false
    }
    else if(200 !== resultCode){
        message.error(info);
        return false
    }
    return true;
}
    
export function logout() {
    message.info("退出成功！")
    localStorage.removeItem('login_token');
    localStorage.removeItem('expired_time');
    localStorage.removeItem("login_time");
    localStorage.removeItem("create_time");
    localStorage.removeItem("staff_detail");
    localStorage.removeItem("feature");
    localStorage.removeItem('account');
    localStorage.removeItem('name');
    localStorage.removeItem("staff_id");
    localStorage.removeItem("level");
    localStorage.removeItem("status");
    localStorage.removeItem("max_storage");

    localStorage.removeItem("enterprise_uuid")
    localStorage.removeItem("enterprise_name");
    localStorage.removeItem("enterprise_level");
    localStorage.removeItem("enterprise_type");
    localStorage.removeItem("enterprise_detail");
    localStorage.removeItem("department_list");
    localStorage.removeItem("enterprise_feature_list");
    localStorage.removeItem("account_expired");
    
    return dispatch=>{dispatch(push('/'))};
}
export function  getAllLoginLog(pageStart=0,pageSize=50){
    let url = Service.dbServer + Service.apiVersion + "/log/get";
    return dispatch => {
        dispatch({type: ActionType.GET_LOGIN_LOG_START});
        $.ajax(preHandle(url), {
            method: 'GET',
            contentType: 'application/json; charset=utf-8',
            //dataType: 'json',
            data:{pageStart:pageStart,pageSize:pageSize}
        }).then((response) => {
            checkAccountStatus(response.code,response.message)
            dispatch({type: ActionType.GET_LOGIN_LOG_SUCCESS, payload: response.result});
        }).catch(function (error) {
            dispatch({type: ActionType.GET_LOGIN_LOG_FAILED, payload: error});
        })
    }
}
export function sendEmailVerifyCode(email,title,info) {
    let url = Service.dbServer + Service.apiVersion + "/sendEmailVerifyCode";
    if (!email.length||!title.length||!info.length){return;}
    $.ajax(url,{
        method:'POST',
        dataType: 'json',
        data:{email:email,title:title,message:info}
    }).then((response)=>{
        if (200 === response.code){
            message.success("验证码已发送至您的邮箱，请查收！")
        }
        else{
            message.error(response.message);    
        }
    }).catch(function (error) {
        message.error("获取验证码失败！")
    })
}
export function sendPhoneVerifyCode(phoneNumber){
    let url = Service.dbServer + Service.apiVersion + "/sendPhoneVerifyCode";
    if(!phoneNumber.length){return ;}
    $.ajax(url,{
        method:'post',
        dataType:'json',
        data:{phone:phoneNumber}
    }).then((response)=>{
        if(200 === response.code){
            message.success("验证码已发送至您手机中，请注意查收！");
        }
        else{
            message.error(response.message);    
        }
    }).catch(function(error){
        message.error("获取验证码失败");
    })
}
export function sendPhoneRegisterVerifyCode(phoneNumber) {
    let url = Service.dbServer + Service.apiVersion + "/sendRegisterPhoneVerifyCode";
    if(!phoneNumber.length){return ;}
    $.ajax(url,{
        method:'post',
        dataType:'json',
        data:{phone:phoneNumber}
    }).then((response)=>{
        if(200 === response.code){
            message.success("验证码已发送至您手机中，请注意查收！");
        }
        else{
            message.error(response.message);    
        }
    }).catch(function(error){
        message.error("获取验证码失败");
    })
}
export function sendPhoneResetPSDVerifyCode(phoneNumber){
    let url = Service.dbServer + Service.apiVersion + "/sendResetPSDPhoneVerifyCode";
    if(!phoneNumber.length){return ;}
    $.ajax(url,{
        method:'post',
        dataType:'json',
        data:{phone:phoneNumber}
    }).then((response)=>{
        if(200 === response.code){
            message.success("验证码已发送至您手机中，请注意查收！");
        }
        else{
            message.error(response.message);    
        }
    }).catch(function(error){
        message.error("获取验证码失败");
    })
}