import React from 'react';
import {Tabs} from 'antd';
import OrderTable from '../../controls/OrderTable';
import {connect} from "react-redux";
import {ORDER_STATUS_FOLLOWING, USER_LEVEL_MEMBER} from "../../../constants/constant";
const { TabPane } = Tabs;
function mapStateToProps(status) {
    return {...status.order};
}
function mapDispatchToProps(dispatch){
    return {}
}
class FollowOrder extends React.Component {
    constructor(props){
        super(props);
        this.state = {};
    }
    render(){
        let myFollowDS = [];
        let allFollowDS = [];
        if (this.props.get_order_result){
            let result = this.props.get_order_result;
            result.sort(function (a,b) { return Date.parse(b.create_time) - Date.parse(a.create_time);})
            for(let i = 0;i < result.length;i++){
                if(ORDER_STATUS_FOLLOWING === result[i].status){
                    if(result[i].detail.manager_account && -1 !== result[i].detail.manager_account.indexOf(localStorage.getItem('account'))){
                        myFollowDS.push(result[i]);
                    }
                    allFollowDS.push(result[i]);
                }
            }
        }
        const orderEditable = USER_LEVEL_MEMBER < Number(localStorage.getItem('level'))?true:false
        const isManager = USER_LEVEL_MEMBER < Number(localStorage.getItem('level'))?true:false
        return(<Tabs defaultActiveKey={"2"} type='card'>
            {isManager?(
                <TabPane key={"1"} tab={"所有交易中订单"}>
                    <OrderTable dataSource={allFollowDS} exportEnable={orderEditable} customColumnsEnable={true} editEnable={orderEditable} batchDelEnable={orderEditable} tableKey={'crm/follow_order'}/>
                </TabPane>):null}
            <TabPane key="2" tab="我的交易中订单">
                <OrderTable dataSource={myFollowDS} exportEnable={orderEditable} customColumnsEnable={true} editEnable={orderEditable} batchDelEnable={orderEditable} tableKey={'crm/follow_order'}/>
            </TabPane>
        </Tabs>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(FollowOrder)