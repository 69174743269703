import React from 'react';
import {Row,Tabs} from 'antd';
import CustomTable from '../../controls/CustomTable';
import {connect} from "react-redux";
import {getAllCustom} from "../../../actions/customDBTool";
import {CUSTOM_STATUS_FAILED,USER_LEVEL_MEMBER} from "../../../constants/constant";
const { TabPane } = Tabs;

function mapStateToProps(state){
    return {...state.custom};
}
function mapDispatchToProps(dispatch){
    return {onGetCustom:()=>dispatch(getAllCustom())};
}
class FailedCustom extends React.Component{
    constructor(props){
        super(props);
        this.state = { };
    }
    componentDidMount(){
        this.props.onGetCustom();
    }
    renderFailedCustom(){
        let failedCustomDS = [];
        let allFailedCustomDS = [];
        if(this.props.get_custom_result){
            let result = this.props.get_custom_result;
            for (let i = 0;i < result.length;i++){
                let item = result[i];
                if(CUSTOM_STATUS_FAILED === result[i].status){
                    allFailedCustomDS.push(item);
                    if(result[i].detail.manager_account && -1 !== result[i].detail.manager_account.indexOf(localStorage.getItem('account'))){
                        failedCustomDS.push(item);
                    }
                }
            }
        }
        const customEditable = USER_LEVEL_MEMBER < Number(localStorage.getItem('level'))?true:false
        const isManager = USER_LEVEL_MEMBER < Number(localStorage.getItem('level'))?true:false
        return(<Tabs defaultActiveKey={"2"}>
            {isManager?(
                <TabPane key="1" tab="全部失败客户">
                    <CustomTable dataSource={allFailedCustomDS} tableKey={'crm/failed_custom'} exportEnable={customEditable} editEnable={customEditable} batchDelEnable={customEditable} batchEditEnable={customEditable}/>
                </TabPane>
            ):null}
            <TabPane key="2" tab="我的失败客户">
                <CustomTable dataSource={failedCustomDS} tableKey={'crm/failed_custom'} exportEnable={customEditable} editEnable={customEditable} batchDelEnable={customEditable} batchEditEnable={customEditable}/>
            </TabPane>
        </Tabs>);
    }
    render(){
        return (<Row>{this.renderFailedCustom()}</Row>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(FailedCustom);