import React from 'react';
import {Row,Tabs} from 'antd';
import CustomTable from '../../controls/CustomTable';
import { connect } from 'react-redux';
import {isToday} from "../../../utils/common";
import {getAllCustom} from "../../../actions/customDBTool";
import {CUSTOM_STATUS_FAILED, CUSTOM_STATUS_SUCCESS, CUSTOM_STATUS_UN_FOLLOW,CUSTOM_TYPE_ENTERPRISE, CUSTOM_TYPE_PERSONAL,USER_LEVEL_MEMBER} from "../../../constants/constant";

const { TabPane } = Tabs;
function mapStateToProps(state){
    return {...state.custom};
}
function mapDispatchToProps(dispatch){
    return {onGetCustom:(pageStart,pageSize)=>dispatch(getAllCustom({pageStart:pageStart,pageSize:pageSize}))};
};
class AllCustom extends React.Component{
    constructor(props){
        super(props);
        this.state = {};
    }
    componentDidMount() {this.props.onGetCustom(0,100);}
    renderCustom(){
        let allCustomDS         = [];
        let myCustomDS          = [];
        let myFollowerDS        = [];
        let todayCustomDS       = [];
        let enterpriseCustomDS  = [];
        let personalCustomDS    = [];
        let followingCustomDS   = [];
        let unFollowCustomDS    = [];
        let successCustomDS     = [];
        let failedCustomDS      = [];
        if(this.props.get_custom_result){
            let result = this.props.get_custom_result;
            for(let i = 0;i < result.length;i++){
                let item = result[i];
                switch (item.status) {
                    case CUSTOM_STATUS_UN_FOLLOW:
                        unFollowCustomDS.push(item);
                        break;
                    case CUSTOM_STATUS_SUCCESS:
                        successCustomDS.push(item);
                        break;
                    case CUSTOM_STATUS_FAILED:
                        failedCustomDS.push(item);
                        break;
                    default:
                        break;
                }
                switch (item.type) {
                    case CUSTOM_TYPE_ENTERPRISE:
                        enterpriseCustomDS.push(item);
                        break;
                    case CUSTOM_TYPE_PERSONAL:
                        personalCustomDS.push(item);
                        break;
                    default:
                        break;
                }
                if(CUSTOM_STATUS_UN_FOLLOW !== item.status && CUSTOM_STATUS_SUCCESS !== item.status && CUSTOM_STATUS_FAILED !== item.status){
                    followingCustomDS.push(item);
                }
                if(isToday(item.create_time)){
                    todayCustomDS.push(item);
                }
                if(item.detail.manager_account && -1 !== item.detail.manager_account.indexOf(localStorage.getItem('account'))){
                    myCustomDS.push(item);
                }
                if(item.detail.follower_account && -1 !== item.detail.follower_account.indexOf(localStorage.getItem('account'))){
                    myFollowerDS.push(item);
                }
                allCustomDS.push(item);
            }
        }
        const customEditable = USER_LEVEL_MEMBER < Number(localStorage.getItem('level'))?true:false
        const isManager = USER_LEVEL_MEMBER < Number(localStorage.getItem('level'))?true:false
        return (<Tabs defaultActiveKey={this.props.get_custom_filter_result?'11':'1'}>
                    {isManager?
                    (<TabPane key="1" tab="全部客户">
                        <CustomTable dataSource={allCustomDS} tableKey={'crm/all_custom'} extendEnable={customEditable} exportEnable={customEditable} editEnable={customEditable} batchDelEnable={customEditable} batchEditEnable={customEditable} total={this.props.count}/>
                    </TabPane>):null}
                    <TabPane key={"2"} tab={"我负责的"}>
                        <CustomTable dataSource={myCustomDS} tableKey={'crm/all_custom'} extendEnable={customEditable} exportEnable={customEditable} editEnable={customEditable} batchDelEnable={customEditable} batchEditEnable={customEditable}/>
                    </TabPane>
                    <TabPane key={"3"} tab={"我参与的"}>
                        <CustomTable dataSource={myFollowerDS} tableKey={'crm/all_custom'} extendEnable={customEditable} exportEnable={customEditable} editEnable={customEditable} batchDelEnable={customEditable} batchEditEnable={customEditable}/>
                    </TabPane>
                    <TabPane key="4" tab="今日新增">
                        <CustomTable dataSource={todayCustomDS} tableKey={'crm/all_custom'} extendEnable={customEditable} exportEnable={customEditable} editEnable={customEditable} batchDelEnable={customEditable} batchEditEnable={customEditable}/>
                    </TabPane>
                    <TabPane key="5" tab="企业客户">
                        <CustomTable dataSource={enterpriseCustomDS} tableKey={'crm/all_custom'} extendEnable={customEditable} exportEnable={customEditable} editEnable={customEditable} batchDelEnable={customEditable} batchEditEnable={customEditable}/>
                    </TabPane>
                    <TabPane key="6" tab="个人客户">
                        <CustomTable dataSource={personalCustomDS} tableKey={'crm/all_custom'} extendEnable={customEditable} exportEnable={customEditable} editEnable={customEditable} batchDelEnable={customEditable} batchEditEnable={customEditable}/>
                    </TabPane>
                    <TabPane key="7" tab="未联系">
                        <CustomTable dataSource={unFollowCustomDS} tableKey={'crm/all_custom'} extendEnable={customEditable} exportEnable={customEditable} editEnable={customEditable} batchDelEnable={customEditable} batchEditEnable={customEditable}/>
                    </TabPane>
                    <TabPane key="8" tab="联系中">
                        <CustomTable dataSource={followingCustomDS} tableKey={'crm/all_custom'} extendEnable={customEditable} exportEnable={customEditable} editEnable={customEditable} batchDelEnable={customEditable} batchEditEnable={customEditable}/>
                    </TabPane>
                    <TabPane key="9" tab="已成交">
                        <CustomTable dataSource={successCustomDS} tableKey={'crm/all_custom'} extendEnable={customEditable} exportEnable={customEditable} editEnable={customEditable} batchDelEnable={customEditable} batchEditEnable={customEditable}/>
                    </TabPane>
                    <TabPane key="10" tab="已失败">
                        <CustomTable dataSource={failedCustomDS} tableKey={'crm/all_custom'} extendEnable={customEditable} exportEnable={customEditable} editEnable={customEditable} batchDelEnable={customEditable} batchEditEnable={customEditable}/>
                    </TabPane>
                    {this.props.get_custom_filter_result?
                    <TabPane key="11" tab="搜索结果">
                        <CustomTable dataSource={Array(this.props.get_custom_filter_result)} tableKey={'crm/all_custom'} exportEnable={customEditable} editEnable={customEditable} batchDelEnable={customEditable} batchEditEnable={customEditable}/>
                    </TabPane>:null}
                </Tabs>);
    }
    renderCounter(){
        const count = this.props.count;
        return (
            <div>
                <span>当前计数:{count}</span>
                <button onClick={this.increaseClick}>Increase</button>
            </div>);
    }
    render(){
        return(<Row>
            {/*this.renderCounter()*/}
            {this.renderCustom()}
            </Row>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(AllCustom);