import {ActionType} from "../constants/actionTypes";
export default function auth(state = {},action){
    switch (action.type) {
        case  ActionType.LOGIN_START:
            return{...state, login:false};
        case  ActionType.LOGIN_SUCCESS:
            return {...state, login:true, user_info: action.payload,};
        case  ActionType.LOGIN_FAILED:
            return {...state, login:false, errors: action.error ? action.payload.errors : null};

        case  ActionType.REGISTER_START:
            return{...state, register:false};
        case  ActionType.REGISTER_SUCCESS:
            return {...state, register:true, result: action.payload,};
        case  ActionType.REGISTER_FAILED:
            return {...state, register:false, errors: action.error ? action.payload.errors : null};
        
        //case  ActionType.GET_USER_INFO:
            //console.log(JSON.parse(localStorage.getItem('staff_detail')));
            //return {user_detail:JSON.parse(localStorage.getItem('staff_detail'))};
        case  ActionType.LOGOUT:
            return {...state};
        default:
            return {...state};
    }
}