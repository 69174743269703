import React from 'react';
import moment from "moment";
import {Row,Col,Tabs,Table, Button,Modal} from 'antd';
import {deleteProduct,getAllProductDel,batchRestoreProduct, batchDelProduct} from "../../actions/productDBTool";
import {deleteCustom,batchDelCustom, getAllCustom, batchUpdateCustom} from "../../actions/customDBTool"
import {deleteOrder,getAllOrderDel,batchDeleteOrder,batchRestoreOrder} from "../../actions/orderDBTool";
import {connect} from "react-redux";
import { account2Name } from '../../utils/accountUtils';

const { TabPane } = Tabs;
const {confirm} = Modal;
function mapStateToProps(state) {
    return {...state.custom,...state.order,...state.product};
}
function mapDispatchToProps(dispatch) {
    return {onGetCustomDel:()=>dispatch(getAllCustom({isTrash:true})),
            onBatchRestoreCustom:(uuidList)=>dispatch(batchUpdateCustom({uuid_list:JSON.stringify(uuidList),restore:true})),
            onDeleteCustom:(uuid)=>dispatch(deleteCustom(uuid)),
            onBatchDeleteCustom:(uuidList)=>dispatch(batchDelCustom(uuidList)),

            onGetOrderDel:()=>dispatch(getAllOrderDel()),
            onBatchRestoreOrder:(IdList)=>dispatch(batchRestoreOrder(IdList)),
            onDeleteOrder:(orderId)=>dispatch(deleteOrder(orderId)),
            onBatchDelOrder:(IdList)=>dispatch(batchDeleteOrder(IdList)),

            onGetProductDel:()=>dispatch(getAllProductDel()),
            onBatchRestoreProduct:(IdList)=>dispatch(batchRestoreProduct(IdList)),
            onDeleteProduct:(productID)=>dispatch(deleteProduct(productID)),
            onBatchDelProduct:(IdList)=>dispatch(batchDelProduct(IdList))

        };
}
class Trash extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            selectedRowKeys: [],
            selectTab:'1'
        };
        this.handleEmptySelect = this.handleEmptySelect.bind(this);
        this.handleRestoreSelect = this.handleRestoreSelect.bind(this);
    }
    componentDidMount(){
        this.props.onGetCustomDel();
        this.props.onGetOrderDel();
        this.props.onGetProductDel();
    }
    handleRestoreSelect(){
        confirm({
            content: '确认是否要恢复所选项？',
            okText: '确定',
            okType: 'message',
            cancelText: '取消',
            onOk:()=> {
                console.log(this.state.selectedRowKeys);
                switch(this.state.selectTab){
                    case "1":
                        this.props.onBatchRestoreCustom(this.state.selectedRowKeys);
                        break;
                    case "2":
                        this.props.onBatchRestoreOrder(this.state.selectedRowKeys);
                        break;
                    case "3":
                        this.props.onBatchRestoreProduct(this.state.selectedRowKeys);
                    break;
                    default:
                    break;
                }
            },
            onCancel() {
                console.log('Cancel');
            },
        })
    }
    handleEmptySelect(){
        console.log(this.state.selectedRowKeys);
        confirm({
            content: '确认是否要删除已选？删除后无法撤回?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk:()=> {
                switch(this.state.selectTab){
                    case "1":
                        this.props.onBatchDeleteCustom(this.state.selectedRowKeys);
                        break;
                    case "2":
                        this.props.onBatchDelOrder(this.state.selectedRowKeys);
                        break;
                    case "3":
                        this.props.onBatchDelProduct(this.state.selectedRowKeys);
                        break;
                    default:
                    break;
                }
                this.setState({selectedRowKeys:[]});
            },
            onCancel() {
                console.log('Cancel');
            },
        })
    }
    onSelectChange = selectedRowKeys => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    }
    onChangeTab(key){
        this.setState({selectTab:key,selectedRowKeys:[]})
    }
    renderCustomPage(){
        let customDS = [];
        if(this.props.get_custom_del){
            let result = this.props.custom_del_result;
            for(let i = 0;i < result.length;i++){
                let detail = (result[i].detail);
                customDS.push({
                    key:result[i].uuid,
                    name:result[i].name,
                    phone:detail.phone,
                    tel:detail.tel,
                    address:detail.address,
                    company:detail.custom_enterprise_name,
                    level:result[i].level,
                    status:result[i].status,
                    delTime:detail.trash_time
                });
            }
        }
        const {selectedRowKeys} = this.state;
        const rowSelection = {selectedRowKeys,onChange: this.onSelectChange};
        const showColumns = [
            {title:'客户姓名',dataIndex:'name'},
            {title:'手机号码',dataIndex:'phone'},
            {title:'座机',dataIndex:'tel'},
            {title:'地址',dataIndex:'address'},
            {title:'所属公司',dataIndex:'company'},
            {title:'客户等级',dataIndex:'level'},
            {title:'客户状态',dataIndex:'status'},
            {title:'删除时间',dataIndex:'delTime',render:(text, record) =><span>{moment(new Date(text)).format('YYYY-MM-DD HH:mm:ss')}</span>},
            {title:'操作',key:'actions', render: (text, record) =>
                <span>
                    <Button type="link" onClick={()=>{this.props.onBatchRestoreCustom([record.key])}}>恢复</Button>
                    <Button type="link" style={{color:'red'}} onClick={()=>{this.props.onDeleteCustom(record.key)}}>删除</Button>
                </span>
            }
        ];
        let maxHeight = document.body.clientHeight > 900?600:500
        return(<Table rowSelection={rowSelection} columns={showColumns} scroll={{y:maxHeight,x:1500}}
                pagination={{showSizeChanger:true,pageSizeOptions:['10','20','50','100','200']}}
                dataSource={customDS} rowKey={record => record.key}/>);
    }
    renderOrderPage(){
        let orderDataSource  = [];
        if(this.props.get_order_del){
            let result = this.props.order_del_result;
            for(let i = 0;i < result.length;i++){
                let detail = (result[i].detail);
                orderDataSource.push({
                    orderID:result[i].order_id,
                    orderName:result[i].title,
                    address:detail.address,
                    status:result[i].status,
                    delTime:detail.trash_time
                });
            }
        }
        const {selectedRowKeys} = this.state;
        const rowSelection = {selectedRowKeys,onChange: this.onSelectChange};
        const orderColumns  = [{title:'订单编号',dataIndex:'orderID'},
                                {title:'名称',dataIndex:'orderName'},
                                {title:'地址',dataIndex:'address'},
                                {title:'状态',dataIndex:'status'},
                                {title:'删除时间',dataIndex:'delTime',render:(text, record) =><span>{moment(new Date(text)).format('YYYY-MM-DD HH:mm:ss')}</span>},
                                {title:'操作',key:'actions', render: (text, record) => (
                                    <span>
                                        <Button type="link" onClick={() =>{this.props.onBatchRestoreOrder([record.orderID])}}>恢复</Button>
                                        <Button type="link" style={{color:"red"}} onClick={() =>{this.props.onDeleteOrder(record.orderID)}}>删除</Button>
                                    </span>
                                    )
                                }
                            ];
        let maxHeight = document.body.clientHeight > 900?600:500
        return(<Table rowSelection={rowSelection} columns={orderColumns} scroll={{y:maxHeight,x:1500}}
                pagination={{showSizeChanger:true,pageSizeOptions:['10','20','50','100','200']}}
                dataSource={orderDataSource} rowKey={record => record.orderID}/>);
    }
    renderProductPage(){
        let productDS = [];
        if(this.props.get_product_del){
            let result = this.props.get_product_del_result;
            for (let i = 0;i < result.length;i++){
                let detail = (result[i].detail);
                console.log(detail)
                let creator = account2Name(result[i].creator_account);
                productDS.push({
                    productID: result[i].uuid,
                    productName: result[i].name,
                    productCount: result[i].count,
                    productStatus: result[i].status,
                    productSalePrice: result[i].sale_price,
                    productCostPrice: result[i].cost_price,
                    delTime:detail.trash_time,
                    creator: creator
                });
            }
        }
        const {selectedRowKeys} = this.state;
        const rowSelection = {selectedRowKeys,onChange: this.onSelectChange};
        const productColumns = [
            {title:'商品编号',dataIndex:'productID'},
            {title:'产品名称',dataIndex:'productName'},
            {title:'库存数量',dataIndex:'productCount'},
            {title:'商品状态',dataIndex:'productStatus'},
            {title:'商品出售价',dataIndex:'productSalePrice'},
            {title:'商品成本价',dataIndex:'productCostPrice'},
            {title:'删除时间',dataIndex:'delTime',render:(text, record) =><span>{moment(new Date(text)).format('YYYY-MM-DD HH:mm:ss')}</span>},
            {title:'操作',key:'actions', render: (text, record) => (
                    <span>
                    <Button type="link" onClick={() =>{this.props.onBatchRestoreProduct([record.productID])}}>恢复</Button>
                    <Button type="link" style={{color:'red'}} onClick={() =>{this.props.onDeleteProduct(record.productID)}}>删除</Button>
                </span>
                )
            }
        ];
        let maxHeight = document.body.clientHeight > 900?600:500
        return(<Table size={"middle"} rowSelection={rowSelection} columns={productColumns} scroll={{y:maxHeight,x:1500}} 
                pagination={{showSizeChanger:true,pageSizeOptions:['10','20','50','100','200']}}
                dataSource={productDS} rowKey={record => record.productID}/>);
    }
    renderEmptyTrash(){
        const hasSelected = this.state.selectedRowKeys.length > 0;
        return(<Row style={{paddingTop:'20px'}}>
                <Col span={3} offset={18}>
                    <Button disabled={!hasSelected} type={'primary'} onClick={this.handleRestoreSelect}>还原选中</Button>
                </Col>
                <Col span={3}>
                    <Button disabled={!hasSelected} type='danger' onClick={this.handleEmptySelect}>清空选中</Button>
                </Col>
            </Row>);
    }
    render(){
        return (<Row style={{height:'100%',overflow:'auto'}}>
                    <Tabs defaultActiveKey="1" onTabClick={(key)=>{this.onChangeTab(key)}} type="card">
                        <TabPane key="1" tab="客户">
                            {this.renderCustomPage()}
                        </TabPane>
                        <TabPane key="2" tab="订单">
                            {this.renderOrderPage()}
                        </TabPane>
                        <TabPane key={"3"} tab={"商品"}>
                            {this.renderProductPage()}
                        </TabPane>
                    </Tabs>
                    {this.renderEmptyTrash()}
                </Row>)
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Trash);