import React from 'react'
class ProjectManage extends React.Component{
    constructor(props){
        super(props);
        this.state={};
    }
    componentDidMount(){}
    componentWillMount(){}
    render(){
        return (<div> this is project page</div>);
    }
}
export default ProjectManage;