import $ from 'jquery'
//import axios from "axios";
import {message} from "antd";
import * as Service from '../config/service.json';
import {ActionType} from "../constants/actionTypes";
import {preHandle,checkAccountStatus} from './commonDBTool';

export function getAllOrder(pageStart=0,pageSize=50) {
    let url = Service.dbServer + Service.apiVersion + "/order/getAll" ;
    return dispatch=>{
        dispatch({type:ActionType.GET_ORDER_BY_USER_START});
        $.ajax(preHandle(url),{
            method:'GET',
            contentType: 'application/json; charset=utf-8',
            dataType:'json',
            data:{pageStart:pageStart,pageSize:pageSize}
        }).done((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            let result = response.result
            if(result){
                for(let i = 0;i < result.length;i++){
                    result[i].detail = JSON.parse(result[i].detail);
                }
            }
            dispatch({type:ActionType.GET_ORDER_BY_USER_SUCCESS,payload:result});
        }).catch(function (error) {
            dispatch({type:ActionType.GET_ORDER_BY_USER_FAILED,payload:error});
        })
    }
}
export function getOrderAmountRate(top_count,status,start_time,end_time) {
    let url = Service.dbServer + Service.apiVersion + "/order/getAmountRate" ;
    return dispatch=>{
        dispatch({type:ActionType.GET_ORDER_AMOUNT_RATE_START});
        $.ajax(preHandle(url),{
            method:'GET',
            dataType:'json',
            data:({top_count:top_count,status:status,start_time:start_time,end_time:end_time})
        }).then(response=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            dispatch({type:ActionType.GET_ORDER_AMOUNT_RATE_SUCCESS,payload:response.result});
        }).catch(function (error) {
            dispatch({type:ActionType.GET_ORDER_AMOUNT_RATE_FAILED,payload:error});
        })
    }
}
export function getOrderCountRate(top_count,status,start_time,end_time){
    let url = Service.dbServer + Service.apiVersion + "/order/getCountRate" ;
    return dispatch=>{
        dispatch({type:ActionType.GET_ORDER_COUNT_RATE_START});
        $.ajax(preHandle(url),{
            method:'GET',
            dataType:'json',
            data:({top_count:top_count,status:status,start_time:start_time,end_time:end_time})
        }).then(response=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            dispatch({type:ActionType.GET_ORDER_COUNT_RATE_SUCCESS,payload:response.result});
        }).catch(function (error) {
            dispatch({type:ActionType.GET_ORDER_COUNT_RATE_FAILED,payload:error});
        })
    }
}
export function getAllOrderByCustom(custom_uuid,pageStart=0,pageSize=50) {
    console.log(custom_uuid);
    let url = Service.dbServer + Service.apiVersion + "/order/getByCustom" ;
    return dispatch=>{
        dispatch({type:ActionType.GET_ORDER_BY_CUSTOM_START});
        $.ajax(preHandle(url),{
            method:'GET',
            dataType:'json',
            data:({custom_uuid:custom_uuid,pageStart:pageStart,pageSize:pageSize})
        }).done((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            let result = response.result
            if(result){
                for(let i = 0;i < result.length;i++){
                    result[i].detail = JSON.parse(result[i].detail);
                }
            }
            dispatch({type:ActionType.GET_ORDER_BY_CUSTOM_SUCCESS,payload:result});
        }).catch(function (error) {
            dispatch({type:ActionType.GET_ORDER_BY_CUSTOM_FAILED,payload:error});
        })
    }
}
export function getAllOrderDel(pageStart=0,pageSize=50) {
    let url = Service.dbServer + Service.apiVersion + "/order/getDelByUser" ;
    return dispatch=>{
        dispatch({type:ActionType.GET_ORDER_DEL_START});
        $.ajax(preHandle(url),{
            method:'GET',
            contentType: 'application/json; charset=utf-8',
            //dataType:'json'
            data:{pageStart:pageStart,pageSize:pageSize}
        }).done((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            let result = response.result
            if(result){
                for(let i = 0;i < result.length;i++){
                    result[i].detail = JSON.parse(result[i].detail);
                }
            }
            dispatch({type:ActionType.GET_ORDER_DEL_SUCCESS,payload:result});
        }).catch(function (error) {
            dispatch({type:ActionType.GET_ORDER_DEL_FAILED,payload:error});
        })
    }
}
export function updateOrder(postData) {
console.log(postData);
let url = Service.dbServer + Service.apiVersion + "/order/update" ;
return dispatch=>{
    dispatch({type:ActionType.UPDATE_ORDER_START});
    $.ajax(preHandle(url),{
        method:'POST',
        contentType:'application/json; charset=utf-8',
        dataType:'json',
        data:JSON.stringify(postData)
    }).then((response)=>{
        if(!checkAccountStatus(response.code,response.message)){return;}
        if(200 === response.code){
            message.info("更新订单成功!");
            dispatch({type:ActionType.UPDATE_ORDER_SUCCESS,payload:response.result});
            dispatch(getAllOrder());
            dispatch(getAllOrderByCustom(postData.custom_uuid))
        }
        else {
            message.error("更新订单失败!");
            dispatch({type:ActionType.UPDATE_ORDER_FALSE,payload:response.result});
        }
    }).catch(function (error) {
        console.log(error);
        message.error("更新订单失败!");
        dispatch({type:ActionType.UPDATE_ORDER_FALSE,payload:error});
    })
}
}
export function addOrder(postData) {
console.log(postData);
let url = Service.dbServer + Service.apiVersion + "/order/add" ;
return dispatch=>{
    dispatch({type:ActionType.ADD_ORDER_START});
    $.ajax(preHandle(url),{
        method:'POST',
        contentType:'application/json; charset=utf-8',
        dataType:'json',
        data:JSON.stringify(postData)
    }).then((response)=>{
        if(!checkAccountStatus(response.code,response.message)){return;}
        if (200 === response.code){
            message.info("添加成功!");
            dispatch({type:ActionType.ADD_ORDER_SUCCESS,payload:response.result});
            dispatch(getAllOrder());
            dispatch(getAllOrderByCustom(postData.custom_uuid))
        }
        else{
            message.error(response.message)
            dispatch({type:ActionType.ADD_ORDER_FAILED,payload:response.result});
        }
    }).catch(function (error) {
        console.log(error);
        dispatch({type:ActionType.ADD_ORDER_FAILED,payload:error});
    })
}
}
export function addOrderDel(postData) {
    console.log(postData)
    let url = Service.dbServer + Service.apiVersion + "/order/batchAddDel" ;
    return dispatch=>{
        dispatch({type:ActionType.ADD_ORDER_DEL_START});
        $.ajax(preHandle(url),{
            method:'POST',
            dataType:'json',
            data:({'order_list':JSON.stringify([postData.order_id])})
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            if(200 === response.code){
                message.info("删除成功!");
                dispatch({type:ActionType.ADD_ORDER_DEL_SUCCESS,payload:response.result});
                dispatch(getAllOrder());
                dispatch(getAllOrderByCustom(postData.custom_uuid))
            }
            else{
                message.error(response.message);
                dispatch({type:ActionType.ADD_ORDER_DEL_FAILED,payload:response.result});
            }
        }).catch(function (error) {
            console.log(error);
            dispatch({type:ActionType.ADD_ORDER_DEL_FAILED,payload:error});
        })
    }
}
export function batchAddOrderDel(uuidList){
    let url = Service.dbServer + Service.apiVersion + "/order/batchAddDel" ;
    return dispatch=>{
        $.ajax(preHandle(url),{ 
            method:'POST',
            dataType:'json',
            data:({'order_list':JSON.stringify(uuidList),})
        }).then(response =>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            if(200 === response.code){
                message.info("删除成功!");
                dispatch(getAllOrder());
            }
        }).catch(function(error){
            console.log(error);
        })
    }
}

export function batchRestoreOrder(IdList){
    let url = Service.dbServer + Service.apiVersion + "/order/batchRestore" ;
    return dispatch=>{
        $.ajax(preHandle(url),{
            method:'POST',
            dataType:'json',
            data:({'order_list':JSON.stringify(IdList)})
        }).then(response=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            if(200 === response.code){
                message.info("还原成功！");
                dispatch(getAllOrderDel());
            }
        }).catch(function(error){
            console.log(error);
        })
    }
}
export function deleteOrder(uuid) {
    let url = Service.dbServer + Service.apiVersion + "/order/del" ;
    return dispatch=>{
        dispatch({type:ActionType.DELETE_ORDER_START});
        $.ajax(preHandle(url),{
            method:'delete',
            //contentType:'application/json; charset=utf-8',
            dataType:'json',
            data:({uuid:uuid})
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            message.info("删除订单成功！")
            dispatch({type:ActionType.DELETE_ORDER_SUCCESS,payload:response.result});
            dispatch(getAllOrderDel());
        }).catch(function (error) {
            message.error("删除失败");
            dispatch({type:ActionType.DELETE_ORDER_FAILED,payload:error});
        })
    }
}
export function batchDeleteOrder(IdList){
let url = Service.dbServer + Service.apiVersion + "/order/batchDel" ;
return dispatch=>{
    $.ajax(preHandle(url),{
        method:'POST',
        dataType:'json',
        data:({'order_list':JSON.stringify(IdList)})
    }).then(response=>{
        if(!checkAccountStatus(response.code,response.message)){return;}
        if(200 === response.code){
            message.info("清空完成");
            dispatch(getAllOrderDel());
        }
    }).catch(function(error){
        console.log(error);
    })
}
}