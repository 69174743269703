import React from 'react';
import {Route, withRouter} from 'react-router-dom';
import Login from "../component/login";
import Payment from "../component/system/payment";
import { Alert} from 'antd';
class PrivateRoute extends React.Component{
    constructor(props) {
        super(props);
        this.state = {}
    }
    render(){
        let { component: Component, ...rest} = this.props;
        if(!localStorage.getItem('login_token')){
            return <Route path={'/'} Component={Login}/>
        }
        else if(localStorage.getItem('account_expired')){
            return (<div>
                <Alert type="error" message="账号已过期" description="账户已过期，请续费后再继续操作" showIcon closable/>
                <Route path={'/'} component={Payment}/>
            </div>);
        }
        return <Route {...rest} render={props => <Component {...props}/>}/>;
    }
}
export default withRouter(PrivateRoute);