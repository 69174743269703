import React from 'react';
import {Tabs,Table,Row,Col,Input,Select} from 'antd';
import {getAllUser} from "../../actions/userDBTool";
import {getAllLoginLog} from "../../actions/commonDBTool";
import {connect} from "react-redux";
import moment from "moment";
import { account2Name } from '../../utils/accountUtils';

const { TabPane } = Tabs;
const InputGroup = Input.Group;
const { Option } = Select;
const tableColumns = [  {title:'登录账号',dataIndex:'loginAccount',key:'loginAccount',width:180},
                        {title:'登录名称',dataIndex:'loginName',key:'loginName',width:100},
                        {title:'登录时间',dataIndex:'loginTime',key:'loginTime',width:200},
                        {title:'登录地点',dataIndex:'loginAddress',key:'loginAddress',width:150},
                        {title:'登录IP',dataIndex:'loginIP',key:'loginIP',width:150},
                        {title:'终端类型',dataIndex:'contextType',key:'contextType',width:100}];
function mapStateToProps(state) {
    return {...state.staff,
            ...state.loginLog}
}
function mapDispatchToProps(dispatch) {
    return {onGetStaff:()=>dispatch(getAllUser()),onGetLoginLog:()=>dispatch(getAllLoginLog())};
}
class LoginLog extends React.Component{
    constructor(props){
        super(props);
        this.state = {staffAccount:''};
    }
    componentDidMount(){
        this.props.onGetStaff();
        this.props.onGetLoginLog();
    }
    onStaffChange=(value)=>{
        this.setState({staffAccount:value});
    }
    renderMemberSearch(){
        let staffOptions = [];
        if(this.props.get_staff_list_result){
            let staffList = this.props.get_staff_list_result;
            staffOptions = staffList.map(item=><Option key={item.account}>{item.name}</Option>);
        }
        return(<Row>
                <Col span={2} style={{textAlign:'center'}}>
                    <span>指定用户</span>
                </Col>
                <Col span={6} style={{textAlign:'left'}}>
                    <InputGroup compact>
                        <Select defaultValue="选择用户" style={{width:'100%'}} onChange={(value => {this.onStaffChange(value)})}>
                            {staffOptions}
                        </Select>
                    </InputGroup>
                </Col>
                </Row>);
    }
    renderLoginTable(){
        let maxHeight = document.body.clientHeight > 900?600:500
        let loginDS = [];
        if(this.props.get_login_log_result){
            let logList = this.props.get_login_log_result;
            console.log(this.state.staffAccount)
            for (let i = logList.length - 1;i >= 0;i--){
                if(0 >= this.state.staffAccount.length){
                    loginDS.push({
                        index:i,
                        loginAccount:logList[i].account,
                        loginName:account2Name(logList[i].account),
                        loginTime:moment(logList[i].login_time).format('YYYY-MM-DD HH:mm:ss'),
                        loginAddress:logList[i].login_address,
                        loginIP:logList[i].login_ip_address,
                        contextType:logList[i].login_context});
                }
                else if(this.state.staffAccount === logList[i].account){
                    loginDS.push({
                        index:i,
                        loginAccount:logList[i].account,
                        loginName:account2Name(logList[i].account),
                        loginTime:moment(logList[i].login_time).format('YYYY-MM-DD HH:mm:ss'),
                        loginAddress:logList[i].login_address,
                        loginIP:logList[i].login_ip_address,
                        contextType:logList[i].login_context});
                }
            }
        }
        return(<Tabs defaultActiveKey="1" >
                    <TabPane tab="用户登录记录" key="1">
                        <Table columns={tableColumns} scroll={{y:maxHeight,x:900}} style={{maxWidth:1000}} dataSource={loginDS} rowKey={record=>record.index} bordered></Table>
                    </TabPane>
                </Tabs>);
    }
    render(){
        return (<div>
                {this.renderMemberSearch()}
                <br/>
                {this.renderLoginTable()}
            </div>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(LoginLog);