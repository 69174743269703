import {ActionType} from "../constants/actionTypes";

const leaveReducer = (state = {},action)=>{
    switch (action.type) {
        case ActionType.ADD_LEAVE_LIST_START:
            return {...state,add_leave:false}
        case ActionType.ADD_LEAVE_LIST_SUCCESS:
            return {...state,add_leave: true,add_leave_result:action.payload}
        case ActionType.ADD_LEAVE_LIST_FAILED:
            return {...state,add_leave: false,add_leave_errors:action.error?action.payload.errors : null}

        case ActionType.GET_LEAVE_LIST_START:
            return {...state,get_leave:false}
        case ActionType.GET_LEAVE_LIST_SUCCESS:
            return {...state,get_leave: true,get_leave_result:action.payload}
        case ActionType.GET_LEAVE_LIST_FAILED:
            return {...state,get_leave: false,get_leave_errors:action.error?action.payload.errors : null}


        case ActionType.UPDATE_LEAVE_LIST_START:
            return {...state,update_leave:false}
        case ActionType.UPDATE_LEAVE_LIST_SUCCESS:
            return {...state,update_leave: true,update_leave_result:action.payload}
        case ActionType.UPDATE_LEAVE_LIST_FAILED:
            return {...state,update_leave: false,update_leave_errors:action.error?action.payload.errors : null}

        case ActionType.DELETE_LEAVE_LIST_START:
            return {...state,delete_leave:false}
        case ActionType.DELETE_LEAVE_LIST_SUCCESS:
            return {...state,delete_leave: true,delete_leave_result:action.payload}
        case ActionType.DELETE_LEAVE_LIST_FAILED:
            return {...state,delete_leave: false,delete_leave_errors:action.error?action.payload.errors : null}

        default:
            return {...state};
    }
}
export default leaveReducer;