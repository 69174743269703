import React from 'react';
import { Funnel } from '@ant-design/charts';

export default class FunnelChart extends React.Component{
    constructor(props) {
        super(props);
        this.state = {};
    }

    render(){
          var config = {
            data: this.props.data,
            xField: 'stage',
            yField: 'number',
            legend: true,
            conversionTag: false
          };
        return (<Funnel style={{width:'90%'}} {...config}/>);
    }
}