import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import moment from 'moment';
import 'moment/locale/zh-cn';
import {exportExcel} from './ExcelUtil';
import {Row,Col, Input, Modal, Divider, Form, Tag, Button,Radio,Checkbox} from 'antd';
import {addProduct, addProductDel,updateProduct, batchAddProductDel} from "../../actions/productDBTool";
import {connect} from "react-redux";
import vCardsJS from 'vcards-js';
import FileSaver from 'file-saver';
import {PRODUCT_STATUS_NEW, productStatusList} from "../../constants/constant";
import ExtendSelect from "./ExtendSelect";
import ExtendTable from "./ExtendTable";
import {account2Name} from "../../utils/accountUtils";
import {StringCompareSort} from '../../utils/common';
import FileManage from './FileManage';

const { confirm } = Modal;
const exportOptions = ['序号','商品编号', '产品名称', '库存数量', '商品状态', '售价（元）','成本价格（元）','创建时间','创建人'];
const ProductForm = Form.create({name:'product_modal'})(
    class extends React.Component{
        constructor(props) {
            super(props);
            this.state = {};
        }
        componentDidMount() {}
        render() {
            let { visible, onCancel, onOk, form,values,title,mode } = this.props;
            let { getFieldDecorator } = form;
            return (<Modal title={title} visible={visible} maskClosable={false}
                onOk={onOk} okText={2===mode?"关闭":"确定"} onCancel={onCancel} >
                <Form labelCol={{span:6}} wrapperCol={{span:18}}>
                    <Form.Item label={"商品名称"} required={true}>
                        {getFieldDecorator('productName', {rules: [{ required: true, message:'商品名称不能为空!'}],initialValue:mode?values.productName:''})
                        (<Input placeholder={"例如：XX商品"} style={{width:'60%'}} disabled={2 === mode?true:false}/>)}
                    </Form.Item>
                    <Form.Item label={"商品状态"} required={true}>
                        {getFieldDecorator('productStatus',{rules:[{required:false}],
                            initialValue:mode?values.productStatus:PRODUCT_STATUS_NEW})
                        //(<Select style={{width:'60%'}}>{productStatusOptions}</Select>)
                        (<ExtendSelect items={productStatusList} title={"商品状态"} placeholder={"请输入自定义的商品状态"} disabled={2 === mode?true:false}/>)}
                    </Form.Item>
                    <Form.Item label={"商品数量"} required={true}>
                        {getFieldDecorator('productCount',{rules:[{required:false}],
                            initialValue:mode?values.productCount:1})
                        (<Input placeholder={"输入商品的数量"} style={{width:'60%'}} disabled={2 === mode?true:false}/>)}
                    </Form.Item>
                    <Form.Item label={"商品售价(元)"} required={true}>
                        {getFieldDecorator('productSalePrice',{rules:[{required:false}],
                            initialValue:mode?values.productSalePrice:1})
                        (<Input placeholder={"输入商品的售价"} style={{width:'60%'}} disabled={2 === mode?true:false}/>)}
                    </Form.Item>
                    <Form.Item label={"成本价(元)"} required={true}>
                        {getFieldDecorator('productCostPrice',{rules:[{required:false}],
                            initialValue:mode?values.productCostPrice:1})
                        (<Input placeholder={"输入商品的成本价"} style={{width:'60%'}} disabled={2 === mode?true:false}/>)}
                    </Form.Item>
                    <Form.Item label={"特别备注"}>
                        {getFieldDecorator('note', {rules: [{ required: false}],
                            initialValue:mode?values.note:""})
                        (<ReactQuill placeholder={"请输入备注内容"} readOnly={2 === mode?true:false}/>)}
                    </Form.Item>
                    <Form.Item label={"相关文档"}>
                        {getFieldDecorator('fileUrl', {initialValue:mode?values.file_url:""})
                            (<FileManage filePrefix={"product/"+values.productID+"/"} fileList={values.file_url} disabled={1 < mode?true:false}/>)}
                    </Form.Item>
                </Form>
            </Modal>)
        }
    })
function mapStateToProps(state){
    return {...state.product};
}
function mapDispatchToProps(dispatch) {
    return {OnAddProduct:(postData)=>dispatch(addProduct(postData)),
        OnDeleteProduct:(productID)=>dispatch(addProductDel(productID)),
        OnUpdateProduct:(postData)=>dispatch(updateProduct(postData)),
        onBatchDelete:(uuid_list)=>dispatch(batchAddProductDel(uuid_list))};
}
class ProductTable extends React.Component{
    constructor(props){
        super(props);
        this.state = { showProductModal:false,
                       modalMode:0,
                       modalTitle:'新增商品',
                       exportColumns:exportOptions,
                       vcfSize:'0',
                       exportType:'xlsx',
                       modalValues:{productID:Date.parse(new Date())/1000+(Math.round(Math.random() * (9000))+999)}
                    };
        this.updateModalValues = this.updateModalValues.bind(this);
        this.OnClickExport = this.OnClickExport.bind(this);
        this.handleOnReadOnlyClick = this.handleOnReadOnlyClick.bind(this)
    }
    componentDidMount(){    }
    saveFormRef = formRef => {
        this.formRef = formRef;
    }
    updateModalValues(values){
        let detail = values.detail;
        this.setState({modalValues:{
                    productName:values.name,
                    productID:values.uuid,
                    productStatus:values.status,
                    productCount:values.count,
                    productCostPrice: detail.cost_price,
                    productSalePrice: detail.sale_price,
                    note: detail.note,
                    file_url:detail.file_url?detail.file_url:[],
                    createTime:values.create_time,
                    creator:account2Name(values.creator_account)}});
    }
    handleCancel=()=>{
        const { form } = this.formRef.props;
        form.resetFields();
        this.setState({showProductModal:false});
    }
    handleDelete=(productID)=>{
        confirm({title:'重要提示',
            content:'您确定要删除该商品',
            okText:'确定',
            okType:'danger',
            cancelText:'取消',
            onOk:()=>{this.props.OnDeleteProduct(productID);},
            onCancel(){console.log('Cancel');}
        })
    }
    OnChangeColumn=(checkedValues)=>{
        this.setState({exportColumns:checkedValues});
    }
    OnChangeExportFormat=(e)=>{
        this.setState({exportType:e.target.value})
    }
    OnChangeVcfSize=(e)=>{
        this.setState({vcfSize:e.target.value});
    }
    ExportVcf=(dataSource)=>{
        console.log(dataSource);
        let vCard = vCardsJS();
        let contactStr = "";
        let fileIndex = 1;
        for (let i = 0;i < dataSource.length;i++){
            vCard.firstName = dataSource[i].name;
            vCard.workPhone = dataSource[i].tel;
            vCard.cellPhone = dataSource[i].phone;
            vCard.workAddress = dataSource[i].address;
            contactStr+=(vCard.getFormattedString());
            if((0 !== this.state.vcfSize && 0 === i%this.state.vcfSize && 0 < i)||dataSource.length - 1 === i){
                let blob = new Blob([contactStr], {type: "text/plain;charset=utf-8"});
                FileSaver.saveAs(blob,"记录表"+fileIndex+".vcf");
                contactStr = "";
                fileIndex++;
            }
        }
        if(0 === this.state.vcfSize){
            let blob = new Blob([contactStr], {type: "text/plain;charset=utf-8"});
            FileSaver.saveAs(blob,"记录表.vcf");
        }
    }
    OnClickExport(dataSource){
        for (let i = 0;i < dataSource.length;i++){
            for (let key in dataSource[i]){
                if (-1 === this.state.exportColumns.indexOf(key)){
                    delete dataSource[i][key];
                    delete dataSource[i].key;
                }
            }
        }
        exportExcel(dataSource,this.state.exportType,'商品列表');
    }
    handleEmptySelect = (uuid_list)=>{
        this.props.onBatchDelete(uuid_list);
    }
    handleAddOnClick=()=>{
        this.setState({showProductModal:true, modalMode:0,modalTitle:'新增商品',modalValues:{productID:Date.parse(new Date())/1000+(Math.round(Math.random() * (9000))+999)}});
    }
    handleOnReadOnlyClick=(record)=>{
        for (let i = 0;i < this.props.get_product_result.length;i++){
            let item = this.props.get_product_result[i];
            if (item.uuid === record.productID){
                this.updateModalValues(item);
                this.setState({showProductModal:true,modalMode:2,modalTitle:"查看商品"});
                return ;
            }
        }
    }
    handleOnEditClick=(record)=>{
        for (let i = 0;i < this.props.get_product_result.length;i++){
            let item = this.props.get_product_result[i];
            if (item.uuid === record.productID){
                this.updateModalValues(item);
                this.setState({showProductModal:true,modalMode:1,modalTitle:"更新商品"});
                return ;
            }
        }
    }
    handleProductSubmit = ()=>{
        if(2 === this.state.modalMode){
            this.setState({ showProductModal: false });
            return;
        }
        const { form } = this.formRef.props;
        form.validateFields((err, fromValues) => {
            if (err) {return;}
            let creator_account = localStorage.getItem('account');
            let detail = {sale_price:fromValues.productSalePrice,
                            cost_price:fromValues.productCostPrice,
                            note:fromValues.note,
                            file_url:fromValues.fileUrl};
            let currTime = new Date();
            let postData = {
                uuid:this.state.modalValues.productID,
                enterprise_uuid:localStorage.getItem('enterprise_uuid'),
                name:fromValues.productName,
                status:fromValues.productStatus,
                count:fromValues.productCount,
                detail:detail,
                creator_account: creator_account,
                create_time:Date.parse(currTime)
            };
            if(this.state.modalMode){//update product
                this.props.OnUpdateProduct(postData)
            }
            else{ 
                this.props.OnAddProduct(postData);
            }
            let modalValues = {
                uuid:postData['uuid'],
                name:fromValues.productName,
                status:fromValues.productStatus,
                count:fromValues.productCount,
                detail:detail,
                creator_account: creator_account,
                create_time:Date.parse(currTime)
            };
            this.updateModalValues(modalValues);
            this.setState({showProductModal:false,modalMode:1});
            form.resetFields();
        })
    }
    renderStatusStyleText(text){
        const statusColor = ["","#2db7f5","red","#87d068"];
        return (<Tag color={statusColor[productStatusList.indexOf(text)]}>{text}</Tag>)
    }
    renderSetting(){
        return(<ProductForm wrappedComponentRef={this.saveFormRef} visible={this.state.showProductModal}
                    onCancel={this.handleCancel} onOk={this.handleProductSubmit} values={this.state.modalValues} 
                    title={this.state.modalTitle} mode={this.state.modalMode}/>);
    }
    renderExportModal=(dataSource,exportDataSource)=>{
        return (<Modal title="导出结果选项" visible={this.state.showExportModal} width={1000}
                        cancelText={"取消"} onCancel={()=>{this.setState({showExportModal:false})}}
                        okText={"导出"} onOk={()=>{this.state.exportType === "vcf"?this.ExportVcf(dataSource):this.OnClickExport(exportDataSource)}} >
                <Checkbox.Group disabled={this.state.exportType === "vcf"} options={exportOptions}
                                defaultValue={exportOptions} onChange={this.OnChangeColumn}/>
                <Divider/>
                <Radio.Group onChange={this.OnChangeExportFormat} defaultValue={'xlsx'}>
                    <Radio value={'xlsx'}>Excel(.xlsx)</Radio>
                    <Radio value={'xls'}>Excel(.xls)</Radio>
                    <Radio value={'csv'}>CSV</Radio>
                    <Radio value={'txt'}>文本(TXT)</Radio>
                </Radio.Group>
        </Modal>);
    }
    renderProductTable(){
        const productStatus = [];
        productStatusList.forEach((item)=>{productStatus.push({text:item,value:item})});
        let showColumns = [{title:'商品编号',dataIndex: 'productID',key:'productID',width:120,ellipsis:true,isSearch:true},
                            {title:'产品名称',dataIndex:'productName',key:'productName',width:120,ellipsis:true,isSearch:true,sorter: (a, b) => StringCompareSort(a.productName,b.productName)},
                            {title:'库存数量',dataIndex:'productCount',key:'productCount',width:120,ellipsis:true,sorter: (a, b) => a.productCount - b.productCount},
                            {title:'商品状态',dataIndex:'productStatus',key:'productStatus',width:120,ellipsis:true,isSearch:true,filters:productStatus,
                                onFilter: (value, record) => record.productStatus.indexOf(value) === 0,sorter: (a, b) => StringCompareSort(a.productStatus,b.productStatus),
                                render:(text)=>this.renderStatusStyleText(text)},
                            {title:'售价(元)',dataIndex:'productSalePrice',key:'productSalePrice',width:120,ellipsis:true,sortDirections: ['ascend','descend'],sorter: (a, b) => a.productSalePrice - b.productSalePrice},
                            {title:'成本价(元)',dataIndex:'productCostPrice',key:'productCostPrice',width:120,ellipsis:true,sortDirections: ['ascend','descend'],sorter: (a, b) => a.productCostPrice - b.productCostPrice},
                            {title:'创建时间',dataIndex:'createTime',key:'createTime',width:180,ellipsis:true,sorter: (a, b) => Date.parse(a.createTime) - Date.parse(b.createTime),render:(text)=><span>{text}</span>},
                            {title:'创建人',dataIndex:'creator',key:'creator',width:120,ellipsis:true,isSearch:true,sorter: (a, b) =>StringCompareSort(a.creator,b.creator),
                                render:(text,record)=><Row>
                                    {text instanceof Array?text.map((tag,index)=>{
                                        const tagElem = (<Tag key={tag} color={"geekblue"}>{tag}</Tag>);
                                        return (tagElem);
                                    }):text?(<Tag key={text} color={"geekblue"}>{text}</Tag>):null}
                                </Row>},
                            {title:'操作',key:'actions',width:150,fixed: 'right', render: (text, record) =>false === this.props.editEnable?
                            <Button size='small' type="link" onClick={()=>this.handleOnReadOnlyClick(record)}>查看</Button>:
                            <Row>
                                <Col span={8}><Button size='small' type="link" onClick={()=>this.handleOnEditClick(record)}>编辑</Button></Col>
                                <Col span={8}><Button size='small' type="link" style={{color:'red'}} onClick={()=>this.handleDelete(record.productID)}>删除</Button></Col>
                            </Row>}]
        let dataSource = [];
        let exportDataSource = [];
        let result = this.props.dataSource;
        for (let i = 0;i < result.length;i++){
            let record = result[i];
            let creator = account2Name(record.creator_account);
            let itemDetail = result[i].detail;
            dataSource.push({
                productID: record.uuid,
                productName: record.name,
                productStatus: record.status,
                productSalePrice: itemDetail.sale_price,
                productCostPrice: itemDetail.cost_price,
                productCount: record.count,
                creator: creator,
                createTime: moment(new Date(record.create_time)).format('YYYY-MM-DD HH:mm:ss')
            })
            exportDataSource.push({'序号':i+1,
                                '商品编号':record.uuid,
                                '产品名称':record.name, 
                                '库存数量':record.count, 
                                '商品状态':record.state, 
                                '售价（元）':itemDetail.sale_price,
                                '成本价格（元）':itemDetail.cost_price,
                                '创建时间':moment(new Date(record.create_time)).format('YYYY-MM-DD HH:mm:ss'),
                                '创建人':creator})
        }
        return (<Row>
                    {this.renderExportModal(dataSource,exportDataSource)}
                    <ExtendTable handleAddOnClick={this.handleAddOnClick} batchDelEnable={false === this.props.batchDelEnable?false:true} 
                        onBatchDelete={this.handleEmptySelect} customColumnsEnable={this.props.customColumnsEnable} tableKKey={this.props.tableKKey}
                        columns={showColumns} dataSource={dataSource} rowKey={record => record.productID}
                        exportEnable={this.props.exportEnable} exportTitle={"导出商品"} exportClick={()=>this.setState({showExportModal:true})}/>
            </Row>);
    }
    render(){
        return(<div>
            <Row>{this.renderProductTable()}</Row>
            <Divider/>
            {this.renderSetting()}
        </div>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(ProductTable)