import React from "react";
import {Row,Tabs} from 'antd';
import { connect } from 'react-redux';
import WorkOrderTable from "../controls/WorkOrderTable";
import {USER_LEVEL_MEMBER} from "../../constants/constant";
const {TabPane} = Tabs;
function mapStateToProps(state) {
    return {...state.workOrder}
}
function mapDispatchToProps(dispatch) {
    return {}
}
class WorkOrder extends React.Component{
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount(){}
    render(){
        let myManagerDS = [];
        let myCreateDS = [];
        let myAssignerDS = [];
        let allDS = [];
        if(this.props.get_work_order_result){
            let workOrderList = this.props.get_work_order_result;
            workOrderList.sort(function (a,b) { return Date.parse(b.create_time) - Date.parse(a.create_time);})
            for (let i = 0;i < workOrderList.length;i++){
                let record = workOrderList[i];
                let detail = (record.detail);
                if(detail.manager_account && -1 !== detail.manager_account.indexOf(localStorage.getItem('account'))){
                    myManagerDS.push(record);
                }
                if(-1 !== record.creator_account.indexOf(localStorage.getItem('account'))){
                    myCreateDS.push(record);
                }
                if(detail.assigner_account && -1 !== detail.assigner_account.indexOf(localStorage.getItem('account'))){
                    myAssignerDS.push(record);
                }
                allDS.push(record);
            }
        }
        const editable = USER_LEVEL_MEMBER < Number(localStorage.getItem('level'))?true:false
        return (<Row>
            <Tabs defaultActiveKey={"1"}>
                <TabPane key={"1"} tab={"全部"}>
                    <WorkOrderTable dataSource={allDS} customColumnsEnable={true} exportEnable={editable} tableKey={'work/work_order'}/>
                </TabPane>
                <TabPane key={"2"} tab={"由我创建"}>
                    <WorkOrderTable dataSource={myCreateDS} customColumnsEnable={true} exportEnable={editable} tableKey={'work/work_order'}/>
                </TabPane>
                <TabPane key={"3"} tab={"由我负责"}>
                    <WorkOrderTable dataSource={myManagerDS} customColumnsEnable={true} exportEnable={editable} tableKey={'work/work_order'}/>
                </TabPane>
                <TabPane key={"4"} tab={"抄送给我"}>
                    <WorkOrderTable dataSource={myAssignerDS} customColumnsEnable={true} exportEnable={editable} tableKey={'work/work_order'}/>
                </TabPane>
            </Tabs>
        </Row>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(WorkOrder);