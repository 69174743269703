import React from 'react';
import { Row,  Modal, Tabs, Tag, Button} from 'antd';
import {connect} from "react-redux";
import {deleteFollowUp,  getAllFollowUp, batchDeleteFollowUp} from "../../../actions/followupDBTool";
import {getAllCustom} from "../../../actions/customDBTool"
import {isThisMonth, isThisWeek, isToday} from "../../../utils/common";
import {followUpStatusList,USER_LEVEL_MEMBER} from "../../../constants/constant";
import FollowUpModal from "../../controls/FollowUpModal";
import ExtendTable from "../../controls/ExtendTable";
import {CaretDownOutlined} from "@ant-design/icons";
import CustomDescription from "../../controls/CustomDescription";
import moment from "moment";
import { account2Name} from '../../../utils/accountUtils';

const {confirm} = Modal;
const { TabPane } = Tabs;
function mapStateToProps(state) {
    return {...state.custom,...state.staff};
}
function mapDispatchToProps(dispatch) {
    return {onGetAllFollowUp:()=>dispatch(getAllFollowUp()),
            onDeleteFollowUp:(followUp)=>dispatch(deleteFollowUp(followUp)),
            onBatchDelete:(followUpList)=>dispatch(batchDeleteFollowUp(followUpList)),
            onGetCustom:()=>dispatch(getAllCustom())};
}
class Followup extends React.Component{
    constructor(props) {
        super(props);
        this.state = {showFollowUp:false,showDescription:false, selectCustom:{},values:{}};
        this.handleAddOnClick = this.handleAddOnClick.bind(this);
        this.handleOnEditClick = this.handleOnEditClick.bind(this);
        this.showDescription = this.showDescription.bind(this);
    }
    componentDidMount() {
        this.props.onGetAllFollowUp();
        this.props.onGetCustom();
    }
    handleEmptySelect=(uuid_list)=>{
        this.props.onBatchDelete(uuid_list);
    }
    handleAddOnClick(){
        let detail = {  file_url:[],
                        custom_name:'',
                        phone:'',
                        content:'',
                        custom_status:'',
                        status:'',
                        assigner_account:''} //必须添加初始值，否则new followup时，will crash
        this.setState({showFollowUp:Math.round(Math.random()*9000),followUpMode:0,
                        values:{
                            uuid:Date.parse(new Date())/1000+(Math.round(Math.random() * (9000))+999), 
                            detail:detail
                        }});
    }
    handleOnReadOnly(record){
        if(this.props.get_followup_result){
            for (let i=0;i < this.props.get_followup_result.length;i++){
                let item = this.props.get_followup_result[i];
                if(item.uuid === record.uuid){
                    this.setState({showFollowUp:Math.round(Math.random()*9000),
                        followUpMode:2,
                        values:item
                    });
                    return;
                }
            }
        }
    }
    handleOnEditClick(record){
        if(this.props.get_followup_result){
            for (let i=0;i < this.props.get_followup_result.length;i++){
                let item = this.props.get_followup_result[i];
                if(item.uuid === record.uuid){
                    item.detail.file_url = item.detail.file_url?item.detail.file_url:[];//必须如此设置file_url,否则第一个文件上传无法显示超链接
                    this.setState({showFollowUp:Math.round(Math.random()*9000),followUpMode:1,values:item});
                    return;
                }
            }
        }
    }
    showDeleteConfirm = (followUp)=> {
        confirm({
            title: '重要提示',
            content: '您确定要删除该跟进记录?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk:()=> {this.props.onDeleteFollowUp(followUp);},
            onCancel() {console.log('Cancel');},
        });
    }
    showDescription(record){
        for (let i = 0;i < this.props.get_custom_result.length;i++){
            let item = this.props.get_custom_result[i];
            if(item.uuid === record.custom_uuid){
                this.setState({showDescription:true,selectCustom:item});
            }
        }
    }
    renderDescription(){
        return (<CustomDescription selectCustom={this.state.selectCustom} visible={this.state.showDescription} onClose={()=>{this.setState({showDescription:false})}}/>);
    }
    renderFollowUp(){
        const followupEditable = USER_LEVEL_MEMBER < Number(localStorage.getItem('level'))?true:false
        const followUpStatus = [];
        followUpStatusList.forEach((item)=>{followUpStatus.push({text:item,value:item})});
        const showColumns = [
            {title: '客户名称',dataIndex: 'custom',key: 'custom', isSearch:true,
                render: (text, record) => <div style={{cursor:'pointer',color:"#2F5BEA"}} onClick={()=>{this.showDescription(record);}}><CaretDownOutlined/>&nbsp;{text}</div>},
            {title: '跟进时间',dataIndex: 'followUpTime',key: 'followUpTime', sortDirections: ['ascend','descend'],sorter: (a, b) => Date.parse(a.followUpTime) - Date.parse(b.followUpTime)},
            {title: '联系方式',dataIndex: 'contact',key: 'contact',isSearch:true},
            {title: '跟进阶段',dataIndex: 'status',key: 'status',filters:followUpStatus, onFilter: (value, record) => record.status.indexOf(value) === 0,
                sorter: (a, b) => a.status.length - b.status.length},
            {title: '客户状态',dataIndex: 'customStatus',key: 'customStatus',isSearch:true},
            {title: '创建人',dataIndex: 'creator',key: 'creator',isSearch:true,render:(text, record) =>
                <Row>
                    {text instanceof Array?text.map((tag,index)=>{
                        const tagElem = (<Tag key={tag} color={"geekblue"}>{tag}</Tag>);
                        return (tagElem);
                    }):text?(<Tag key={text} color={"geekblue"}>{text}</Tag>):null}
                </Row>},
            {title: '操作',dataIndex:'actions',key:'actions', render: (text, record) =>
            followupEditable?
                <span>
                    <Button type="link" onClick={()=>this.handleOnEditClick(record)}>编辑</Button>
                    <Button type="link" style={{color:'red'}} onClick={()=>this.showDeleteConfirm(record)}>删除</Button>
                </span>:<span><Button type="link" onClick={()=>this.handleOnReadOnly(record)}>查看</Button></span>
            }];
        let allFollowUpDS = [];
        let todayFollowUpDS = [];
        let weekFollowUpDS = [];
        let monthFollowUpDS = [];
        if(this.props.get_followup_result){
            let followUp_list = this.props.get_followup_result;
            followUp_list.sort(function (a,b) { return Date.parse(b.create_time) - Date.parse(a.create_time);})
            for (let i = 0;i < followUp_list.length;i++){
                let record = followUp_list[i];
                let creator = account2Name(record.creator_account);
                let detail = followUp_list[i].detail;
                if(isToday(followUp_list[i].create_time)){
                    todayFollowUpDS.push({
                        uuid:followUp_list[i].uuid,
                        custom_uuid:followUp_list[i].custom_uuid,
                        custom:detail.custom_name,
                        followUpTime:moment(new Date(followUp_list[i].create_time)).format('YYYY-MM-DD HH:mm:ss'),
                        contact:detail.phone,
                        status:followUp_list[i].status,
                        customStatus:detail.custom_status,
                        creator:creator})
                }
                if (isThisWeek(followUp_list[i].create_time)){
                    weekFollowUpDS.push({
                        uuid:followUp_list[i].uuid,
                        custom_uuid:followUp_list[i].custom_uuid,
                        custom:detail.custom_name,
                        followUpTime:moment(new Date(followUp_list[i].create_time)).format('YYYY-MM-DD HH:mm:ss'),
                        contact:detail.phone,
                        status:followUp_list[i].status,
                        customStatus:detail.custom_status,
                        creator:creator});
                }
                if (isThisMonth(followUp_list[i].create_time)){
                    monthFollowUpDS.push({
                        uuid:followUp_list[i].uuid,
                        custom_uuid:followUp_list[i].custom_uuid,
                        custom:detail.custom_name,
                        followUpTime:moment(new Date(followUp_list[i].create_time)).format('YYYY-MM-DD HH:mm:ss'),
                        contact:detail.phone,
                        status:followUp_list[i].status,
                        customStatus:detail.custom_status,
                        creator:creator});
                }
                allFollowUpDS.push({
                        uuid:followUp_list[i].uuid,
                        custom_uuid:followUp_list[i].custom_uuid,
                        custom:detail.custom_name,
                        followUpTime:moment(new Date(followUp_list[i].create_time)).format('YYYY-MM-DD HH:mm:ss'),
                        contact:detail.phone,
                        status:followUp_list[i].status,
                        customStatus:detail.custom_status,
                        creator:creator})
            }
        }
        return (<Tabs defaultActiveKey={"2"}>
            <TabPane key={"1"} tab={"全部"}>
                <ExtendTable handleAddOnClick = {this.handleAddOnClick} onBatchDelete={this.handleEmptySelect} 
                    dataSource={allFollowUpDS} columns={showColumns} customColumnsEnable={false} rowKey={record => record.uuid}/>
            </TabPane>
            <TabPane key={"2"} tab={"今日"}>
                <ExtendTable handleAddOnClick = {this.handleAddOnClick} onBatchDelete={this.handleEmptySelect} 
                    dataSource={todayFollowUpDS} columns={showColumns} customColumnsEnable={false} rowKey={record => record.uuid}/>
            </TabPane>
            <TabPane key={"3"} tab={"本周"}>
                <ExtendTable handleAddOnClick = {this.handleAddOnClick} onBatchDelete={this.handleEmptySelect} 
                    dataSource={weekFollowUpDS} columns={showColumns} customColumnsEnable={false} rowKey={record => record.uuid}/>
            </TabPane>
            <TabPane key={"4"} tab={"本月"}>
                <ExtendTable handleAddOnClick = {this.handleAddOnClick} onBatchDelete={this.handleEmptySelect} 
                    dataSource={monthFollowUpDS} columns={showColumns} customColumnsEnable={false} rowKey={record => record.uuid}/>
            </TabPane>
        </Tabs>);
    }
    render(){
        return (<Row>
                    {this.renderFollowUp()}
                    {this.renderDescription()}
                    <FollowUpModal visible={this.state.showFollowUp} mode={this.state.followUpMode} values={this.state.values}/>
                </Row>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Followup);