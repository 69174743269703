import React from 'react';
import {Row,Tabs} from 'antd';
import ProductTable from '../controls/ProductTable';
import {getAllProduct} from "../../actions/productDBTool";
import {connect} from "react-redux";
import {USER_LEVEL_MEMBER} from "../../constants/constant";
import {isToday} from "../../utils/common";
const { TabPane } = Tabs;

function mapStateToProps(state) {
    return {...state.product};
}
function mapDispatchToProps(dispatch) {
    return {onGetAllProduct:()=>dispatch(getAllProduct())};
}
class AllProduct extends React.Component{
    constructor(props){
        super(props);
        this.state = { };
    }
    componentDidMount() {
        this.props.onGetAllProduct();
    }
    renderAllProduct(){
        let todayDS = [];
        let allProductDS = [];
        let myProductDS = [];
        if(this.props.get_product_result){
            let result = this.props.get_product_result;
            for(let i = 0;i < result.length;i++){
                if(isToday(result[i].create_time)){todayDS.push(result[i]);}
                if(result[i].creator_account === localStorage.getItem('account')){
                    myProductDS.push(result[i]);
                }
                allProductDS.push(result[i]);
            }
        }
        const productEditable = USER_LEVEL_MEMBER < Number(localStorage.getItem('level'))?true:false
        return (<Tabs defaultActiveKey="1" type="card">
                    <TabPane key="1" tab="全部产品">
                        <ProductTable dataSource={allProductDS} customColumnsEnable={true} exportEnable={productEditable} editEnable={productEditable} batchDelEnable={productEditable} tableKey={'product/all_product'}/>
                    </TabPane>
                    <TabPane key="2" tab="我创建的产品">
                        <ProductTable dataSource={myProductDS} customColumnsEnable={true} exportEnable={productEditable} editEnable={productEditable} batchDelEnable={productEditable} tableKey={'product/all_product'}/>
                    </TabPane>
                    <TabPane key="3" tab="今日新增产品">
                        <ProductTable dataSource={todayDS} customColumnsEnable={true} exportEnable={productEditable} editEnable={productEditable} batchDelEnable={productEditable} tableKey={'product/all_product'}/>
                    </TabPane>
                </Tabs>);
    }
    render(){
        return(<Row>{this.renderAllProduct()}</Row>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(AllProduct)