import React from 'react';
import moment from "moment";
import {Tabs, Row, Col, Card} from 'antd';
import { getAllUser} from "../../actions/userDBTool";
import DepartmentPositionList from '../controls/DepartmentPositionList';
import StaffTable from '../controls/StaffTable';
import {connect} from "react-redux";
import {USER_STATUS_DISABLE, USER_STATUS_NORMAL, userStatusList} from "../../constants/constant";
import { id2Department } from '../../utils/accountUtils';

const {TabPane} = Tabs;

function mapStateToProps(state) {
    return {...state.staff,...state.enterprise};
}
function mapDispatchToProps(dispatch) {
    return {onGetStaff:()=>dispatch(getAllUser())};
}
class StaffManage extends React.Component{
    constructor(props){
        super(props);
        this.state = {};
    }
    componentDidMount(){this.props.onGetStaff();}
    handleOnTabChange=(key)=>{console.log(key);}
    render(){
        let normalStaffList = [];
        let disableStaffList = [];
        let allStaffList = [];
        if(this.props.get_staff_list_result){
            let staffList = this.props.get_staff_list_result;
            let index = 0;
            for (let i = 0;i < staffList.length;i++){
                let detail = staffList[i].detail;
                let positionName = detail.position;
                let staffStatus = "";
                userStatusList.forEach(item=>{if(item.value===staffList[i].status){staffStatus = item.text;}});
                let item = {
                    index:index+1,
                    account:staffList[i].account,
                    name:staffList[i].name,
                    department:id2Department(detail.position_id1),
                    position:positionName,
                    phone:detail.phone,
                    email:detail.email,
                    status:staffStatus,
                    login_time:moment(new Date(staffList[i].login_time)).format('YYYY-MM-DD HH:mm:ss')
                };
                if(positionName === this.props.select_department || localStorage.getItem("enterprise_name") === this.props.select_department){
                    if(USER_STATUS_NORMAL === staffList[i].status){
                        normalStaffList.push(item);
                    }
                    else if(USER_STATUS_DISABLE === staffList[i].status){
                        disableStaffList.push(item);
                    }
                    allStaffList.push(item);
                    index++;
                }
            }
        }
        return(<Row gutter={[10,1]}>
                    <Col span={5}>
                        <DepartmentPositionList/>
                    </Col>
                    <Col span={17}>
                        <Card>
                            <Tabs defaultActiveKey="1" onChange={this.handleOnTabChange}>
                                <TabPane tab="正常状态" key="1">
                                    <StaffTable dataSource={normalStaffList}/>
                                </TabPane>
                                <TabPane tab="禁止登录" key="2">
                                    <StaffTable dataSource={disableStaffList}/>
                                </TabPane>
                                <TabPane tab="全部" key="3">
                                    <StaffTable dataSource={allStaffList}/>
                                </TabPane>
                            </Tabs>
                        </Card>
                    </Col>
                </Row>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(StaffManage);