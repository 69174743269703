import React from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {Row,Col, Form, Input, Modal, Button, Divider, Tag,Radio,Checkbox} from "antd";
import moment from "moment";
import vCardsJS from 'vcards-js';
import FileSaver from 'file-saver';
import {exportExcel} from './ExcelUtil';
import {connect} from "react-redux";
import ExtendSelect from "./ExtendSelect";
import {WORK_ORDER_TYPE_AFTER_SALE, WORK_ORDER_TYPE_COMPLAINT,
        workOrderLevelList, workOrderStatusList, workOrderTypeList} from "../../constants/constant";
import ExtendTable from "./ExtendTable";
import StaffChoose from "./StaffChoose";
import CustomChooseSelect from "./CustomChooseSelect";
import ProductChooseSelect from "./ProductChooseSelect";
import OrderChoose from "./OrderChoose";
import {getWorkOrder,addWorkOrder, deleteWorkOrder, updateWorkOrder, batchDeleteWorkOrder} from "../../actions/workOrderDBTool";
import {account2Name, name2Account,customName2Uuid} from "../../utils/accountUtils";
import { StringCompareSort } from "../../utils/common";

const {confirm} = Modal;
const exportOptions = ['序号','工单标题', '工单类型', '相关商品', '相关订单', '处理状态','工单等级','内容','负责人','抄送给','创建时间'];
const WorkOrderForm = Form.create({name:"work_modal"})(
    class extends React.Component{
    constructor(props) {
        super(props);
        this.state = {type:WORK_ORDER_TYPE_COMPLAINT};
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(this.props.visible !== nextProps.visible){
            if(nextProps.mode){
                this.setState({type:nextProps.values.type});
            }
            else{
                this.setState({type:WORK_ORDER_TYPE_COMPLAINT});
            }
        }
        return true;
    }
    onChangeType(value){this.setState({type:value});}
    renderAfterSale(values,mode){
        const { form } = this.props;
        let { getFieldDecorator } = form;
        return (<div>
            <Row style={{background:'#F0F0F0'}}>售后工单</Row>
            <Row>
                <Form.Item label={"客户名称"}>
                    {getFieldDecorator('customName',{initialValue:values.custom?values.custom:""})
                    (<CustomChooseSelect disabled={2 === mode?true:false}/>)}
                </Form.Item>
                <Form.Item label={"相关商品"}>
                    {getFieldDecorator('product',{rules:[{ required: false,message:'相关商品不能为空!'}],
                        initialValue:values.product?values.product:""})
                        (<ProductChooseSelect placeholder={"输入相关的商品"} disabled={2 === mode?true:false}/>)}
                </Form.Item>
                <Form.Item label={"相关订单"}>
                    {getFieldDecorator('order',{initialValue:values.order?values.order:""})
                    (<OrderChoose placeholder="请输入相关订单" disabled={2 === mode?true:false}/>)}
                </Form.Item>
                <Form.Item label={"问题描述"}>
                    {getFieldDecorator('content',{rules:[{ required: true,message:'问题描述不能为空!'}],
                        initialValue:values.content?values.content:""})
                    (<ReactQuill placeholder={"请输入问题描述!"} readOnly={2 === mode?true:false}/>)}
                </Form.Item>
            </Row>
        </div>);
    }
    renderComplaint(values,mode){
        const { form } = this.props;
        let { getFieldDecorator } = form;
        return (<div>
            <Row style={{background:'#F0F0F0'}}>投诉工单</Row>
            <Row>
                <Form.Item label={"客户名称"}>
                    {getFieldDecorator('customName',{initialValue:values.custom?values.custom:""})
                    (<CustomChooseSelect disabled={2 === mode?true:false}/>)}
                </Form.Item>
                <Form.Item label={"相关商品"}>
                    {getFieldDecorator('product',{initialValue:values.product?values.product:""})
                    (<ProductChooseSelect placeholder={"输入与投诉相关的商品"} disabled={2 === mode?true:false}/>)}
                </Form.Item>
                <Form.Item label={"相关订单"}>
                    {getFieldDecorator('order',{initialValue:values.order?values.order:""})
                    (<OrderChoose placeholder="请输入相关订单" disabled={2 === mode?true:false}/>)}
                </Form.Item>
                <Form.Item label={"投诉内容"}>
                    {getFieldDecorator('content',{rules:[{ }],initialValue:values.content?values.content:""})
                    (<ReactQuill placeholder={"请输入投诉的内容!"} readOnly={2 === mode?true:false}/>)}
                </Form.Item>
            </Row>
        </div>);
    }
    renderOther(values,mode){
        const { form } = this.props;
        let { getFieldDecorator } = form;
        return (<div>
            <Row style={{background:'#F0F0F0'}}>其他工单</Row>
            <Row>
                <Form.Item label={"客户名称"}>
                    {getFieldDecorator('customName',{initialValue:values.custom?values.custom:""})
                    (<CustomChooseSelect disabled={2 === mode?true:false}/>)}
                </Form.Item>
                <Form.Item label={"相关商品"}>
                    {getFieldDecorator('product',{rules:[{ required: false,message:'相关商品不能为空!'}],
                        initialValue:values.product?values.product:""})
                        (<ProductChooseSelect placeholder={"输入相关的商品"} disabled={2 === mode?true:false}/>)}
                </Form.Item>
                <Form.Item label={"相关订单"}>
                    {getFieldDecorator('order',{initialValue:values.order?values.order:""})
                    (<OrderChoose placeholder="请输入相关订单" disabled={2 === mode?true:false}/>)}
                </Form.Item>
                <Form.Item label={"工单内容"}>
                    {getFieldDecorator('content',{rules:[{ required: true,message:'工单内容不能为空!'}],
                        initialValue:values.content?values.content:""})
                    (<ReactQuill placeholder={"请输入工单的内容!"} readOnly={2 === mode?true:false}/>)}
                </Form.Item>
            </Row>
        </div>);
    }
    render(){
        let { visible, onCancel, onOk, form, values,title,mode } = this.props;
        let { getFieldDecorator } = form;
        let formItemLayout = {labelCol: {span:6}, wrapperCol: {span:16}};
        return (<Modal title={title} visible={visible} maskClosable={false}
                onOk={onOk} okText={2===mode?"关闭":"确定"} onCancel={onCancel} cancelText={"取消"} width={600}>
            <Form {...formItemLayout}>
                <Form.Item label={"工单标题"}>
                    {getFieldDecorator('workOrderTitle',{rules: [{required: true, message: '工单标题不能为空!'}],initialValue: mode ? values.title: ''})
                    (<Input placeholder={"请输入工单的标题"} disabled={2 === mode?true:false}/>)}
                </Form.Item>
                <Form.Item label={"工单类型"}>
                    {getFieldDecorator('type',{rules: [{required: true, message: '工单类型不能为空!'}],initialValue: mode ? values.type: workOrderTypeList[0]})
                    (<ExtendSelect items={workOrderTypeList} title={"工单类型"} onChange={(value)=>this.onChangeType(value)} disabled={2 === mode?true:false}/>)}
                </Form.Item>
                {this.state.type === WORK_ORDER_TYPE_COMPLAINT?this.renderComplaint(values,mode):null}
                {this.state.type === WORK_ORDER_TYPE_AFTER_SALE?this.renderAfterSale(values,mode):null}
                {(this.state.type !== WORK_ORDER_TYPE_COMPLAINT)&&(this.state.type !== WORK_ORDER_TYPE_AFTER_SALE)?this.renderOther(values,mode):null}
                <Form.Item label={"处理状态"}>
                    {getFieldDecorator('status',{rules: [{required: true, message: '处理状态不能为空!'}],
                        initialValue: values.status ? values.status: workOrderStatusList[0]})
                    (<ExtendSelect items={workOrderStatusList} title={"处理状态"} disabled={2 === mode?true:false}/>)}
                </Form.Item>
                <Form.Item label={"工单等级"}>
                    {getFieldDecorator('level',{rules: [{required: true, message: '工单等级不能为空!'}],
                        initialValue: values.level ? values.level: workOrderLevelList[0]})
                    (<ExtendSelect items={workOrderLevelList} title={"工单等级"} disabled={2 === mode?true:false}/>)}
                </Form.Item>
                <Row style={{background:'#F0F0F0'}}>基本信息</Row>
                <Row>
                    <Form.Item label={"负责人"}>
                        {getFieldDecorator('manager', {rules: [{ required: true,message:'负责人不能为空!'}],initialValue:values.manager?values.manager:''})
                        (<StaffChoose mode={0} disabled={2 === mode?true:false}/>)}
                    </Form.Item>
                    <Form.Item label={"抄送给"}>
                        {getFieldDecorator('assigner', {initialValue: values.assigner ? values.assigner : ''})
                        (<StaffChoose mode={1} disabled={2 === mode?true:false}/>)}
                    </Form.Item>
                </Row>
            </Form>
        </Modal>);
    }
}
)
function mapStateToProps(state) {
    return {...state.workOrder,...state.product};
}
function mapDispatchToProps(dispatch) {
    return {onGetWorkOrder:()=>dispatch(getWorkOrder()),
            onAddWorkOrder:(postData)=>dispatch(addWorkOrder(postData)),
            onEditWorkOrder:(postData)=>dispatch(updateWorkOrder(postData)),
            onDeleteWorkOrder:(postData)=>dispatch(deleteWorkOrder(postData)),
            onBatchDelete:(uuid_list)=>dispatch(batchDeleteWorkOrder(uuid_list))
        }
}
class WorkOrderTable extends React.Component{
    constructor(props) {
        super(props);
        this.state = {showModal:false,
                        modalMode:0,
                        modalTitle:'新增工单',
                        exportColumns:exportOptions,
                        exportType:'xlsx',
                        vcfSize:'0',
                        modalValues:{}}
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleOnEdit = this.handleOnEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.showContent  = this.showContent.bind(this);
        this.handleOnAdd = this.handleOnAdd.bind(this);
        this.handleEmptySelect = this.handleEmptySelect.bind(this);
    }
    componentDidMount(){
        this.props.onGetWorkOrder();
    }
    saveFormRef = formRef => {
        this.formRef = formRef;
    }
    handleEmptySelect(uuid_list){
        this.props.onBatchDelete(uuid_list);
    }
    handleCancel(){
        const {form} = this.formRef.props;
        form.resetFields();
        this.setState({showModal:false})
    }
    updateModalValues(values){
        let detail = values.detail;
        this.setState({ modalValues:{  
            uuid:values.uuid,
            title:values.title,
            type:values.type,
            status:values.status,
            level:values.level,
            manager:account2Name(detail.manager_account),
            assigner:account2Name(detail.assigner_account),
            custom:detail.custom_name,
            product:detail.product,
            order:detail.order,
            content:detail.content,
            createTime:values.create_time}});
    }
    showContent(record){
        for (let i = 0;i < this.props.get_work_order_result.length;i++){
            let item = this.props.get_work_order_result[i];
            if(item.uuid === record.uuid){
                this.updateModalValues(item);
                this.setState({showModal:true,modalMode:2,modalTitle:"查看工单"})
                return;
            }
        }
    }
    handleOnAdd(){
        if(this.props.selectCustom){
            let selectCustom = this.props.selectCustom;
            this.setState({showModal:true,modalMode:0,modalTitle:'新增工单',
                            modalValues:{custom:selectCustom.name,
                                manager:account2Name(selectCustom.detail.manager_account)}});
        }
        else{
            this.setState({showModal:true,modalMode:0,modalTitle:'新增工单',modalValues:{title:''}});
        }
    }
    handleOnEdit(record){
        for (let i = 0;i < this.props.get_work_order_result.length;i++){
            let item = this.props.get_work_order_result[i];
            if(item.uuid === record.uuid){
                this.updateModalValues(item);
                this.setState({showModal:true,modalMode:1,modalTitle:"更新工单"})
                return;
            }
        }
    }
    handleDelete(uuid){
        confirm({
            content: '您确定要删除该工单?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk:()=> {
                if(this.props.dataSource){
                    let result = this.props.dataSource;
                    for (let i = 0;i < result.length;i++){
                        if(result[i].uuid === uuid){
                            return this.props.onDeleteWorkOrder(result[i]);
                        }
                    }
                }
            },
            onCancel() {console.log('Cancel');},
        })
    }
    handleSubmit(){
        if(2 === this.state.modalMode){
            this.setState({ showModal: false });
            return;
        }
        const { form } = this.formRef.props;
        form.validateFields((err,fromValues)=>{
            if (err) {return;}
            let manager_account = name2Account(fromValues.manager);
            let assigner_account = name2Account(fromValues.assigner);
            let detail = {  custom_uuid:customName2Uuid(fromValues.customName),
                            custom_name:fromValues.customName,
                            order:fromValues.order,
                            product:fromValues.product,
                            assigner_account:assigner_account,
                            manager_account:manager_account,
                            content:fromValues.content
                        };
            let current = new Date();
            let postData = {
                enterprise_uuid:localStorage.getItem('enterprise_uuid'),
                title:fromValues.workOrderTitle,
                type:fromValues.type,
                level:fromValues.level,
                status:fromValues.status,
                detail:JSON.stringify(detail),
                creator_account:localStorage.getItem("account"),
                create_time:Date.parse(current)
            }
            if(this.state.modalMode){
                postData['uuid'] = this.state.modalValues.uuid;
                this.props.onEditWorkOrder(postData);
            }
            else{
                let uuid = Date.parse(new Date())/1000+(Math.round(Math.random() * (9000))+999);
                postData['uuid'] = uuid;
                this.props.onAddWorkOrder(postData);
            }
            let modalValues = {
                title:fromValues.workOrderTitle,
                type:fromValues.type,
                level:fromValues.level,
                status:fromValues.status,
                detail:detail,
                creator_account:localStorage.getItem("account"),
                create_time:Date.parse(current)
            }
            this.updateModalValues(modalValues);
            this.setState({showModal:false})
            form.resetFields();
        });
    }
    OnChangeColumn=(checkedValues)=>{
        console.log(checkedValues);
        this.setState({exportColumns:checkedValues});
    }
    OnChangeExportFormat=(e)=>{
        this.setState({exportType:e.target.value})
    }
    OnChangeVcfSize=(e)=>{
        this.setState({vcfSize:e.target.value});
    }
    ExportVcf=(dataSource)=>{
        console.log(dataSource);
        let vCard = vCardsJS();
        let contactStr = "";
        let fileIndex = 1;
        for (let i = 0;i < dataSource.length;i++){
            vCard.firstName = dataSource[i].name;
            vCard.workPhone = dataSource[i].tel;
            vCard.cellPhone = dataSource[i].phone;
            vCard.workAddress = dataSource[i].address;
            contactStr+=(vCard.getFormattedString());
            if((0 !== this.state.vcfSize && 0 === i%this.state.vcfSize && 0 < i)||dataSource.length - 1 === i){
                let blob = new Blob([contactStr], {type: "text/plain;charset=utf-8"});
                FileSaver.saveAs(blob,"记录表"+fileIndex+".vcf");
                contactStr = "";
                fileIndex++;
            }
        }
        if(0 === this.state.vcfSize){
            let blob = new Blob([contactStr], {type: "text/plain;charset=utf-8"});
            FileSaver.saveAs(blob,"记录表.vcf");
        }
    }
    OnClickExport(dataSource){
        for (let i = 0;i < dataSource.length;i++){
            for (let key in dataSource[i]){
                if (-1 === this.state.exportColumns.indexOf(key)){
                    delete dataSource[i][key];
                    delete dataSource[i].key;
                }
            }
        }
        exportExcel(dataSource,this.state.exportType,'工单列表');
    }
    renderExportModal=(dataSource,exportDataSource)=>{
        return (<Modal title="导出结果选项" visible={this.state.showExportModal} width={1000}
                        cancelText={"取消"} onCancel={()=>{this.setState({showExportModal:false})}}
                        okText={"导出"} onOk={()=>{this.state.exportType === "vcf"?this.ExportVcf(dataSource):this.OnClickExport(exportDataSource)}} >
                <Checkbox.Group disabled={this.state.exportType === "vcf"} options={exportOptions}
                                defaultValue={exportOptions} onChange={this.OnChangeColumn}/>
                <Divider/>
                <Radio.Group onChange={this.OnChangeExportFormat} defaultValue={'xlsx'}>
                    <Radio value={'xlsx'}>Excel(.xlsx)</Radio>
                    <Radio value={'xls'}>Excel(.xls)</Radio>
                    <Radio value={'csv'}>CSV</Radio>
                    <Radio value={'txt'}>文本(TXT)</Radio>
                </Radio.Group>
        </Modal>);
    }
    renderLevelStyleText(text){
        const levelColor = ["","#108ee9","#f50"];
        return (<Tag color={levelColor[workOrderLevelList.indexOf(text)]}>{text}</Tag>)
    }
    renderStatusStyleText(text){
        const statusColor = ["#fa0","#2db7f5","","red"];
        return (<Tag color={statusColor[workOrderStatusList.indexOf(text)]}>{text}</Tag>)
    }
    renderTypeStyleText(text){
        const typeColor = ["#f50","#87d068","#2db7f5"];
        return (<Tag color={typeColor[workOrderTypeList.indexOf(text)]}>{text}</Tag>)
    }
    renderSetting(){
        return(<WorkOrderForm wrappedComponentRef={this.saveFormRef} visible={this.state.showModal} 
                    values={this.state.modalValues} title={this.state.modalTitle} mode={this.state.modalMode}
                    onCancel={this.handleCancel} onOk={this.handleSubmit}/>);
    }
    renderTable(){
        const workOrderType = [];
        const workOrderStatus = [];
        const workOrderLevel = [];
        workOrderTypeList.forEach(item=>{workOrderType.push({text:item,value:item})});
        workOrderStatusList.forEach(item=>{workOrderStatus.push({text:item,value:item})});
        workOrderLevelList.forEach(item=>{workOrderLevel.push({text:item,value:item})});
        let showColumns =  [{title:"标题",key:"title",dataIndex:'title',width:120,ellipsis:true,isSearch:true,sorter: (a, b) =>StringCompareSort( a.title,b.title)},
                    {title:"类型",key:"type",dataIndex:'type',width:100,ellipsis:true,filters:workOrderType,onFilter: (value, record) => record.type.indexOf(value) === 0,
                        sorter: (a, b) => StringCompareSort(a.type,b.type),render:(text,record)=>this.renderTypeStyleText(text)},
                    {title:"相关商品",key:"product",dataIndex:'product',width:120,ellipsis:true,isSearch:true,sorter: (a, b) => StringCompareSort(a.product,b.product)},
                    {title:"相关订单",key:"order",dataIndex:'order',width:130,ellipsis:true,isSearch:true,sorter: (a, b) => StringCompareSort(a.order,b.order)},
                    {title:"处理状态",key:"status",dataIndex:'status',width:120,ellipsis:true,filters:workOrderStatus,onFilter: (value, record) => record.status.indexOf(value) === 0,
                        sorter: (a, b) => StringCompareSort(a.status,b.status),render:(text,record)=>this.renderStatusStyleText(text)},
                    {title:"工单等级",key:"level",dataIndex:'level',width:120,ellipsis:true,filters:workOrderLevel,onFilter: (value, record) => record.level.indexOf(value) === 0,
                        sorter: (a, b) => StringCompareSort(a.level,b.level),render:(text,record)=>this.renderLevelStyleText(text)},
                    {title:"负责人",key:"manager",dataIndex:'manager',width:120,ellipsis:true,isSearch:true,sorter: (a, b) => StringCompareSort(a.manager,b.manager),
                        render:(text, record) => <Row>{text instanceof Array?text.map((tag,index)=>{
                            const tagElem = (<Tag key={tag} color={"geekblue"}>{tag}</Tag>);
                            return (tagElem);}):text?(<Tag key={text} color={text==="公海客户"?"#f50":"geekblue"}>{text}</Tag>):null}
                        </Row>},
                    {title:"抄送给",key:"assigner",dataIndex:'assigner',width:120,ellipsis:true,isSearch:true,sorter: (a, b) =>StringCompareSort(a.assigner,b.assigner),
                        render:(text, record) => <Row>{text instanceof Array?text.map((tag,index)=>{
                            const tagElem = (<Tag key={tag} color={"geekblue"}>{tag}</Tag>);
                            return (tagElem);}):text?(<Tag key={text} color={"geekblue"}>{text}</Tag>):null}
                        </Row>},
                    {title: '创建人',dataIndex:'creator',key:'creator',width:120,ellipsis:true,isSearch:true,sorter: (a, b) => a.creator.toString().localeCompare(b.creator.toString()),
                    render:(text, record) => <Row>{text?(<Tag key={text} color={"geekblue"}>{text}</Tag>):null}</Row>},
                    {title:"创建时间",key:"createTime",dataIndex:'createTime',width:150,ellipsis:true,sorter: (a, b) => Date.parse(a.createTime) - Date.parse(b.createTime)},
                    {title:'操作',key:'actions',width:150, fixed: 'right',render: (text, record) => <Row>
                       <Col span={8}><Button size='small' type="link" onClick={()=>this.showContent(record)}>查看</Button></Col>
                       <Col span={8}><Button size='small' type="link" onClick={()=>this.handleOnEdit(record)}>编辑</Button></Col>
                       <Col span={8}><Button size='small' type="link" style={{color:'red'}} onClick={()=>this.handleDelete(record.uuid)}>删除</Button></Col>
                    </Row>}]
        let dataSource = [];
        let exportDataSource = [];
        if(this.props.dataSource){
            for (let i = 0;i < this.props.dataSource.length;i++){
                let item = this.props.dataSource[i];
                let itemDetail = item.detail;
                let assigner = account2Name(itemDetail.assigner_account);
                let manager = account2Name(itemDetail.manager_account) 
                let creator = account2Name(item.creator_account);
                dataSource.push({index:i+1,
                    uuid:item.uuid,
                    title:item.title,
                    type:item.type,
                    product:itemDetail.product,
                    order:itemDetail.order,
                    level:item.level,
                    status:item.status,
                    content:itemDetail.content,
                    manager:manager,
                    assigner:assigner,
                    creator:creator,
                    createTime:moment(new Date(item.create_time)).format('YYYY-MM-DD HH:mm:ss')});
                exportDataSource.push({'序号':i+1,
                                        '工单标题':item.title,
                                        '工单类型':item.type, 
                                        '相关商品':itemDetail.product, 
                                        '相关订单':itemDetail.order, 
                                        '处理状态':item.status,
                                        '工单等级':item.level,
                                        '内容':itemDetail.content,
                                        '负责人':manager,
                                        '抄送给':assigner,
                                        '创建时间':moment(new Date(item.create_time)).format('YYYY-MM-DD HH:mm:ss')})
            }
        }
        return (<Row>
                {this.renderExportModal(dataSource,exportDataSource)}
                    <ExtendTable handleAddOnClick={this.handleOnAdd} onBatchDelete={this.handleEmptySelect} columns={showColumns}
                        customColumnsEnable={this.props.customColumnsEnable} tableKey={this.props.tableKey}
                        dataSource={dataSource} rowKey={record => record.uuid}
                        exportEnable={this.props.exportEnable} exportTitle={"导出工单列表"} exportClick={()=>this.setState({showExportModal:true})}/>
                </Row>);
    }
    render(){
        return (<Row>
                {this.renderTable()}
                <Divider/>
                {this.renderSetting()}
            </Row>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(WorkOrderTable);