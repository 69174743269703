import React from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import XLSX from 'xlsx';
import {Row,Col, Input, Form, Modal, DatePicker, Divider, Tag, Button,Radio,InputNumber} from "antd";
import moment from "moment";
import {connect} from "react-redux";
import {PlusCircleOutlined,MinusCircleOutlined} from '@ant-design/icons';
import {addPurchase, deletePurchase, updatePurchase, batchDeletePurchase} from "../../actions/purchaseDBTool";
import {purchaseStatusList,purchaseLevelList} from "../../constants/constant";
import ExtendSelect from "./ExtendSelect";
import ProductChooseSelect from "./ProductChooseSelect";
import ExtendTable from "./ExtendTable";
import StaffChoose from "./StaffChoose";
import {account2Name, name2Account,Uuid2ProductName, productName2Uuid} from "../../utils/accountUtils";
import { delHtmlTag, StringCompareSort } from "../../utils/common";
import FileManage from './FileManage';
import SupplierChooseSelect from "./SupplierChooseSelect";

const {confirm} = Modal;
const exportOptions = ['序号','标题', '采购产品', '金额（元）','优先级', '状态','备注','申请人','申请时间','审核人','创建时间','创建人'];

const PurchaseForm = Form.create({name:'purchase_modal'})(
    class extends React.Component{
        constructor(props) {
            super(props);
            this.state = {};
        }
        add = ()=>{
            if(2 === this.props.mode){return}
            const { form } = this.props;
            const keys = form.getFieldValue("product_detail_keys");
            if(keys.length >= 100){return}
            const nextKeys = keys.concat(keys.length);
            form.setFieldsValue({product_detail_keys: nextKeys});
            console.log(keys)
        }
        remove = k => {
            if(2 === this.props.mode){return}
            const { form } = this.props;
            const keys = form.getFieldValue("product_detail_keys");
            if (keys.length === 1) {return;}
            form.setFieldsValue({
                product_detail_keys: keys.filter(key => key !== k)
            });
        }
        renderDynamicProduct(values,mode){
            const { form } = this.props;
            let defaultValue = [];
            let { getFieldDecorator } = form;
            if(values.productDetail){
                for (let i = 0;i < values.productDetail.length;i++){
                    defaultValue.push(i);
                }
            }
            else{
                defaultValue.push(0);
            }
            getFieldDecorator("product_detail_keys", {initialValue: mode?defaultValue:[0]});
            const keys = form.getFieldValue("product_detail_keys");
            return(keys.map((key, index) =>
                <Row key={index} gutter={[0,0]}>
                    <Col span={3} offset={2}>
                        <Form.Item>
                            {getFieldDecorator(`productNumber[${index}]`, {initialValue:mode&&values.productDetail&&values.productDetail[index]?Uuid2ProductName(values.productDetail[index].product_number):""})
                                (<Input title={"商品编号"} disabled={2 === mode?true:false} style={{width:'150px'}} size={"small"}/>)}
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item>
                            {getFieldDecorator(`productName[${index}]`, {rules: [{ required: true, message: '商品名称不能为空'}],initialValue:mode&&values.productDetail&&values.productDetail[index]?Uuid2ProductName(values.productDetail[index].product_uuid):""})
                                (<ProductChooseSelect title={"商品名称"} disabled={2 === mode?true:false} style={{width:'150px'}} size={"small"}/>)}
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item>
                            {getFieldDecorator(`standards[${index}]`, {initialValue:mode&&values.productDetail&&values.productDetail[index]?values.productDetail[index].standards:""})
                                (<Input disabled={2 === mode?true:false} style={{width:'110px'}} size={"small"}/>)}
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item>
                            {getFieldDecorator(`unit[${index}]`, {initialValue:mode&&values.productDetail&&values.productDetail[index]?values.productDetail[index].unit:"个"})
                                (<Input disabled={2 === mode?true:false} style={{width:'90px'}} size={"small"}/>)}
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item>
                            {getFieldDecorator(`count[${index}]`, {rules:[{ required: true, message: '数量不能为空' }],initialValue:mode&&values.productDetail&&values.productDetail[index]?values.productDetail[index].count:1})(
                                <InputNumber min={1} disabled={2 === mode?true:false} style={{width:'90px'}} size={"small"} onChange={(value)=>{
                                    this.props.form.setFieldsValue({[`amount[${index}]`]:value*this.props.form.getFieldValue(`price[${index}]`),
                                                                    [`rateAmount[${index}]`]:value*this.props.form.getFieldValue(`price[${index}]`)})
                                    let totalCount = 0
                                    let totalAmount = 0
                                    for(let i = 0;i < this.props.form.getFieldValue(`product_detail_keys`).length;i++){
                                        if(i === index){
                                            totalCount += Number(value)
                                        }
                                        else{
                                            totalCount += Number(this.props.form.getFieldValue(`count[${i}]`))
                                        }
                                        totalAmount += Number(this.props.form.getFieldValue(`amount[${i}]`))
                                    }
                                    this.props.form.setFieldsValue({totalCount:totalCount,totalAmount:totalAmount})

                                }}/>)}
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item>
                            {getFieldDecorator(`price[${index}]`, {initialValue:mode&&values.productDetail&&values.productDetail[index]?values.productDetail[index].price:0})
                                (<InputNumber min={0} step={0.1} disabled={2 === mode?true:false} style={{width:'100px'}} size={"small"} onChange={(value)=>{
                                    this.props.form.setFieldsValue({[`amount[${index}]`]:value*this.props.form.getFieldValue(`count[${index}]`),
                                                                    [`rateAmount[${index}]`]:value*this.props.form.getFieldValue(`count[${index}]`)})
                                                                    let totalCount = 0
                                    let totalAmount = 0
                                    for(let i = 0;i < this.props.form.getFieldValue(`product_detail_keys`).length;i++){
                                        totalCount += Number(this.props.form.getFieldValue(`count[${i}]`))
                                        totalAmount += Number(this.props.form.getFieldValue(`amount[${i}]`))
                                    }
                                    this.props.form.setFieldsValue({totalCount:totalCount,totalAmount:totalAmount})
                                }}/>)}
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item>
                            {getFieldDecorator(`amount[${index}]`, {initialValue:mode&&values.productDetail&&values.productDetail[index]?values.productDetail[index].amount:0})
                                (<InputNumber min={0} step={0.1} disabled={2 === mode?true:false} style={{width:'100px'}} size={"small"} onChange={(value)=>{
                                    this.props.form.setFieldsValue({[`rateAmount[${index}]`]:value})
                                    let totalAmount = 0
                                    for(let i = 0;i < this.props.form.getFieldValue(`product_detail_keys`).length;i++){
                                        if(i===index){
                                            totalAmount += value
                                        }
                                        else{
                                            totalAmount += Number(this.props.form.getFieldValue(`amount[${i}]`))
                                        }
                                    }
                                    this.props.form.setFieldsValue({totalAmount:totalAmount})
                                }}/>)}
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item>
                            {getFieldDecorator(`rate[${index}]`, {initialValue:mode&&values.productDetail&&values.productDetail[index]?values.productDetail[index].rate:0})
                                (<InputNumber min={0} max={100} step={0.1} size={"small"} disabled={2 === mode?true:false} style={{width:'90px'}} formatter={value => `${value}%`}
                                    parser={value => value.replace('%', '')}/>)}
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item>
                            {getFieldDecorator(`rateAmount[${index}]`, {initialValue:mode&&values.productDetail&&values.productDetail[index]?values.productDetail[index].rate_amount:"0.0"})
                                (<InputNumber min={0} step={0.1} disabled={2 === mode?true:false} style={{width:'110px'}} size={"small"}/>)}&nbsp;
                                {index === 0?(<PlusCircleOutlined style={{color:'blue'}} onClick={() => this.add()}/>):(<MinusCircleOutlined style={{color:'red'}} onClick={() => this.remove(key)}/>)}
                        </Form.Item>
                    </Col>
                
                </Row>))
        }
        renderDynamicProductTitle(){
            return(<Row gutter={[0,0]}>
                    <Col span={3} offset={2}>
                        <span>商品编号</span>
                    </Col>
                    <Col span={3}>
                        <span>商品名称</span>
                    </Col>
                    <Col span={2}>
                        <span>商品规格</span>
                    </Col>
                    <Col span={2}>
                        <span>单位</span>
                    </Col>
                    <Col span={2}>
                        <span>数量</span>
                    </Col>
                    <Col span={2}>
                        <span>单价</span>
                    </Col>
                    <Col span={2}>
                        <span>金额</span>
                    </Col>
                    <Col span={2}>
                        <span>稅率</span>
                    </Col>
                    <Col span={3}>
                        <span>金额(含税)</span>
                    </Col>
                </Row>)
        }
        render(){
            let { visible, onCancel, onOk, form, values,title,mode } = this.props;
            let { getFieldDecorator } = form;
            let formItemLayout = {labelCol: {span:6}, wrapperCol: {span:16}};
            let currentTime = new Date();
            return (<Modal title={title} visible={visible} maskClosable={false} width={1400}
                    onOk={onOk} onCancel={onCancel} okText={"确定"} cancelText={"取消"}>
                <Form {...formItemLayout}>
                    <Row>
                        <Col span={8}>
                            <Form.Item label={"标题"}>
                                {getFieldDecorator('purchaseTitle', {rules:[{required:true,message:'标题不能为空!'}],initialValue:mode?values.purchaseTitle:''})
                                (<Input placeholder={"例如:采购XX商品"} disabled={2 === mode?true:false}/>)}
                            </Form.Item>
                           
                        </Col>
                        <Col span={8}>
                            <Form.Item label={"采购编号"}>
                                {getFieldDecorator('purchaseNumber', {initialValue:mode?values.purchaseNumber:''})
                                (<Input disabled={2 === mode?true:false}/>)}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={"供应商"}>
                                {getFieldDecorator('supplier', {initialValue:mode?values.supplier:''})
                                (<SupplierChooseSelect title={"供应商"} disabled={2 === mode?true:false}/>)}
                            </Form.Item>
                        </Col>
                    </Row>
                    {this.renderDynamicProductTitle()}
                    <div style={{maxHeight:260,overflow:'auto'}}>{this.renderDynamicProduct(values,mode)}</div>
                    <Row>
                        <Col span={12}>
                            <Form.Item label={"状态"}>
                                {getFieldDecorator('status', {initialValue: mode ? values.status : ''})
                                (<ExtendSelect items={purchaseStatusList} title={"状态"} disabled={2 === mode?true:false}/>)}
                            </Form.Item>
                            <Form.Item label={"优先级"}>
                                {getFieldDecorator('level', {initialValue:mode?values.level : ''})
                                (<ExtendSelect items={purchaseLevelList} title={"优先级"} disabled={2 === mode?true:false}/>)}
                            </Form.Item>
                            <Form.Item label={"备注"}>
                                {getFieldDecorator('note', {rules: [{ required: false}],initialValue:mode?values.note:""})
                                (<ReactQuill placeholder={"例如:XXX"} readOnly={2 === mode?true:false}/>)}
                            </Form.Item>
                            <Form.Item label={"相关文档"}>
                                {getFieldDecorator('fileUrl', {initialValue:mode?values.file_url:""})
                                    (<FileManage filePrefix={"purchase/"+values.uuid+"/"} fileList={values.file_url} disabled={1 < mode?true:false}/>)}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={"申请时间"}>
                                {getFieldDecorator('purchaseTime',{initialValue:mode? moment(new Date(values.purchaseTime), 'YYYY-MM-DD'):moment(currentTime, 'YYYY-MM-DD')})
                                (<DatePicker placeholder={"选择录入的时间"} showTime disabled={2 === mode?true:false}/>)}
                            </Form.Item>
                            <Form.Item label={"采购期限"}>
                                {getFieldDecorator('purchaseEndTime',{initialValue:mode? moment(new Date(values.purchaseEndTime), 'YYYY-MM-DD'):moment(currentTime, 'YYYY-MM-DD')})
                                (<DatePicker placeholder={"选择录入的时间"} showTime disabled={2 === mode?true:false}/>)}
                            </Form.Item>
                            <Form.Item label={"申请人"}>
                                {getFieldDecorator('purchaser', {rules: [{required: false}],initialValue: mode ? values.purchaser: ''})
                                (<StaffChoose mode={1} disabled={2 === mode?true:false}/>)}
                            </Form.Item>
                            <Form.Item label={"审核人"} required={true}>
                                {getFieldDecorator('manager', {rules:[{required:true,message:'审核人不能为空！'}],initialValue: mode?values.manager:''})
                                (<StaffChoose mode={0} disabled={2 === mode?true:false}/>)}
                            </Form.Item>
                            <Form.Item label={"抄送给"}>
                                {getFieldDecorator('assigner', {rules: [{required: false}],initialValue: mode ? values.assigner: ''})
                                (<StaffChoose mode={1} disabled={2 === mode?true:false}/>)}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>);
        }
    }
)
function mapStateToProps(state) {
    return {...state.purchase,...state.auth}
}
function mapDispatchToProps(dispatch) {
    return {onAddPurchase:(postData)=>dispatch(addPurchase(postData)),
            onEditPurchase:(postData)=>dispatch(updatePurchase(postData)),
            onDeletePurchase:(uuid)=>dispatch(deletePurchase(uuid)),
            onBatchDelete:(uuid_list)=>dispatch(batchDeletePurchase(uuid_list))
        }
}
class PurchaseTable extends React.Component{
    constructor(props) {
        super(props);
        this.state = {showModal:false, modalMode:0,modalTitle:'新增采购申请',
                        exportColumns:exportOptions,
                        exportType:'xlsx',
                        vcfSize:'0',
                        modalValues:{uuid:Date.parse(new Date())/1000+(Math.round(Math.random() * (9000))+999)}}
        this.handleOnReadOnly = this.handleOnReadOnly.bind(this)
        this.handleAddOnClick = this.handleAddOnClick.bind(this)
        this.handleOnEdit = this.handleOnEdit.bind(this)
    }
    saveFormRef = formRef => {this.formRef = formRef;}
    updateModalValues(values){
        let detail = values.detail;
        this.setState({modalValues: {
            uuid:values.uuid,
            purchaseTitle:values.title,
            purchaseNumber:detail.purchase_number,
            supplier:detail.supplier,
            status:values.status,
            level:values.level,
            amount:detail.amount,
            assigner:account2Name(detail.assigner_account),
            manager:account2Name(detail.manager_account),
            purchaser: account2Name(detail.purchaser_account),
            purchaseTime:detail.purchase_time,
            purchaseEndTime:detail.purchase_endtime,
            file_url:detail.file_url?detail.file_url:[],
            note:detail.note,
            productDetail:detail.product_detail,
            createTime:values.create_time,
            creator:account2Name(values.creator_account),
        }});
    }
    handleEmptySelect=(uuid_list)=>{this.props.onBatchDelete(uuid_list);}
    handleAddOnClick(){
        this.setState({showModal:true, modalMode:0,modalTitle:'新增采购申请',modalValues:{uuid:Date.parse(new Date())/1000+(Math.round(Math.random() * (9000))+999)}});
    }
    handleOnEdit(record){
        for (let i = 0;i < this.props.get_purchase_result.length;i++){
            let item = this.props.get_purchase_result[i];
            if(item.uuid === record.uuid){
                this.updateModalValues(item);
                this.setState({showModal:true,modalMode:1,itle:'更新采购申请'})
                return;
            }
        }
    }
    handleOnReadOnly(record){
        for (let i = 0;i < this.props.get_purchase_result.length;i++){
            let item = this.props.get_purchase_result[i];
            if(item.uuid === record.uuid){
                this.updateModalValues(item);
                this.setState({showModal:true,modalMode:2,itle:'查看采购申请'})
                return;
            }
        }
    }
    handleCancel=()=>{
        const { form } = this.formRef.props;
        form.resetFields();
        this.setState({showModal:false})
    }
    handleDelete=(uuid)=>{
        confirm({
            content: '您确定要删除该采购申请?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk:()=> {this.props.onDeletePurchase(uuid)},
            onCancel() {console.log('Cancel');},
        })
    }
    handleSubmit=()=>{
        if(2 === this.state.modalMode){
            this.setState({ showModal: false });
            return;
        }
        const { form } = this.formRef.props;
        form.validateFields((err, fromValues) => {
            if(err){return;}
            let manager_account = fromValues.manager? name2Account(fromValues.manager) : "";
            let purchaser_account = fromValues.purchasr? name2Account(fromValues.purchaser) : "";
            let assigner_account = fromValues.assigner? name2Account(fromValues.assigner) : "";
            let creator_account = localStorage.getItem('account');
            if(0 >= fromValues.manager.length){
                manager_account = localStorage.getItem('account');
            }
            if(0 >= fromValues.purchaser.length){
                purchaser_account = localStorage.getItem('account');
            }
            let current = new Date();
            let product_detail = []
            let productKind = fromValues.productName.length
            let totalAmount = 0
            let totalCount = 0;
            for(let i = 0;i < productKind;i++){
                product_detail.push({
                    product_number:fromValues.productNumber[i],
                    product_uuid:productName2Uuid(fromValues.productName[i]),
                    price:fromValues.price[i],
                    count: fromValues.count[i],
                    standards:fromValues.standards[i],
                    unit:fromValues.unit[i],
                    amount:fromValues.amount[i],
                    rate:fromValues.rate[i],
                    rate_amount:fromValues.rateAmount[i]
                })
                totalAmount += fromValues.amount[i];
                totalCount += fromValues.count[i]
            }
            let detail = {
                amount:totalAmount,
                count:totalCount,
                purchase_number:fromValues.purchaseNumber,
                supplier:fromValues.supplier,
                product_detail:product_detail,
                purchaser_account: purchaser_account,
                purchase_time:Date.parse(fromValues.purchaseTime),
                purchase_endtime:Date.parse(fromValues.purchaseEndTime),
                file_url:fromValues.fileUrl,
                note:fromValues.note,
                assigner_account:assigner_account,
                manager_account:manager_account,
            }
            let postData = {
                uuid:this.state.modalValues.uuid,
                enterprise_uuid:localStorage.getItem('enterprise_uuid'),
                title:fromValues.purchaseTitle,
                type:1,
                level:fromValues.level,
                status:fromValues.status,
                detail:JSON.stringify(detail),
                creator_account:creator_account,
                create_time:Date.parse(current),
            };
            if(this.state.modalMode){
                this.props.onEditPurchase(postData);
            }
            else{
                this.props.onAddPurchase(postData);
            }
            let modalValues = {
                uuid:postData['uuid'],
                title:fromValues.purchaseTitle,
                type:1,
                level:1,
                status:fromValues.status,
                detail:detail,
                creator_account:creator_account,
                create_time:Date.parse(current),
            }
            this.updateModalValues(modalValues);
            this.setState({ showModal: false });
            form.resetFields();
        })
    }
    OnChangeColumn=(checkedValues)=>{
        console.log(checkedValues);
        this.setState({exportColumns:checkedValues});
    }
    OnChangeExportFormat=(e)=>{
        this.setState({exportType:e.target.value})
    }
    OnClickExport(){
        for (let i = 0;i < this.props.dataSource.length;i++){
            let exportDataSource = [];
            let item = this.props.dataSource[i]
            let itemDetail = item.detail;
            let productDetail = itemDetail.product_detail?itemDetail.product_detail:[]
            let productArray = []
            for(let j = 0;j < productDetail.length;j++){
                productArray.push({ '商品编号':productDetail[j].product_number,
                                    '商品名称':Uuid2ProductName(productDetail[j].product_uuid),
                                    '商品规格':productDetail[j].standards,
                                    '计量单位':productDetail[j].unit,
                                    '商品数量':productDetail[j].count,
                                    '商品单价':productDetail[j].price,
                                    '商品金额':productDetail[j].amount,
                                    '商品税率':productDetail[j].rate,
                                    '金额(含税)':productDetail[j].rate_amount
                                })
            }
            let manager = account2Name(itemDetail.manager_account)
            let creator = account2Name(item.creator_account)
            let purchaser = account2Name(itemDetail.purchaser_account)
            exportDataSource.push({'序号':i+1,
            '标题':item.title, 
            '商品总数':itemDetail.count, 
            '总金额（元）':itemDetail.amount, 
            '状态':item.status,
            '优先级':item.level,
            '备注':itemDetail.note,
            '申请人':purchaser,
            '申请时间':moment(new Date(itemDetail.purchase_time)).format('YYYY-MM-DD HH:mm:ss'),
            '审核人':manager,
            '创建时间':moment(new Date(item.create_time)).format('YYYY-MM-DD HH:mm:ss'),
            '创建人':creator})
            let wb = XLSX.utils.book_new();
            let fileName = item.title+"采购单.";
            fileName += this.state.exportType;
            let jsonWs = XLSX.utils.json_to_sheet(exportDataSource,{defval:true});
            /*jsonWs['!merges'] = [
                {
                    e: { c: 5, r: 0 },
                    s: { c: 0, r: 0 }  // 表示A1到F1单元格合并
                }]*/
            XLSX.utils.sheet_add_json(jsonWs, productArray, {skipHeader: false, origin: { r: 3, c: 0 }, header: [ "商品编号","商品名称","商品规格","计量单位","商品数量","商品单价","商品金额","商品税率","金额(含税)"]});
            XLSX.utils.book_append_sheet(wb, jsonWs, '采购申请单');
            XLSX.writeFile(wb, fileName);
        }
        //exportExcel(dataSource,this.state.exportType,'采购申请');
    }
    renderExportModal=()=>{
        return (<Modal title="导出结果选项" visible={this.state.showExportModal} width={1000}
                        cancelText={"取消"} onCancel={()=>{this.setState({showExportModal:false})}}
                        okText={"导出"} onOk={()=>{this.OnClickExport()}} >
                <Divider/>
                <Radio.Group onChange={this.OnChangeExportFormat} defaultValue={'xlsx'}>
                    <Radio value={'xlsx'}>Excel(.xlsx)</Radio>
                    <Radio value={'xls'}>Excel(.xls)</Radio>
                    <Radio value={'csv'}>CSV</Radio>
                    <Radio value={'txt'}>文本(TXT)</Radio>
                </Radio.Group>
        </Modal>);
    }
    renderStatusStyleText(text){
        const statusColor = ["","#2db7f5","#87d068","red"];
        return (<Tag color={statusColor[purchaseStatusList.indexOf(text)]}>{text}</Tag>)
    }
    renderSetting() {
        return (<PurchaseForm wrappedComponentRef={this.saveFormRef} visible={this.state.showModal} 
                values={this.state.modalValues} mode={this.state.modalMode} title={this.state.modalTitle}
                onCancel={this.handleCancel} onOk={this.handleSubmit}/>);
    }
    renderTable(){
        const purchaseStatus = [];
        purchaseStatusList.forEach((item)=>{purchaseStatus.push({text:item,value:item})});
        let showColumns =  [{title:"标题",dataIndex:'title',key:'title',width:120,ellipsis:true,isSearch:true,sorter: (a, b) => StringCompareSort( a.title,b.title)},
        {title:"商品总数",dataIndex:'count',key:'count',width:120,ellipsis:true,isSearch:true,sorter: (a, b) => StringCompareSort(a.product,b.product)},
        {title:"总金额(元)",dataIndex:"money",key:"money",width:120,ellipsis:true,isSearch:true,sorter: (a, b) => a.money - b.money},
        {title:"状态",dataIndex:"status",key:"status",width:120,ellipsis:true,filters:purchaseStatus,onFilter: (value, record) => record.status.indexOf(value) === 0,
            sorter: (a, b) => StringCompareSort(a.status,b.status),render:(text)=>this.renderStatusStyleText(text)},
        {title:"备注",dataIndex:"note",key:"note",width:120,ellipsis:true,isSearch:true,render:(text)=>{return delHtmlTag(text)}},
        {title:"申请人",dataIndex:"purchaser",key:"purchaser",width:120,ellipsis:true,isSearch:true,sorter: (a, b) =>StringCompareSort(a.purchaser,b.purchaser),render:(text, record) =><Row>
        {text instanceof Array?text.map((tag,index)=>{
            const tagElem = (<Tag key={tag} color={"geekblue"}>{tag}</Tag>);
            return (tagElem);
        }):text?(<Tag key={text} color={"geekblue"}>{text}</Tag>):null}
    </Row>},
        {title:"申请时间",dataIndex:"purchaseTime",key:"purchaseTime",width:180,ellipsis:true,sorter: (a, b) => Date.parse(a.createTime) - Date.parse(b.createTime)},
        {title:"审核人",dataIndex:"manager",key:"manager",width:120,ellipsis:true,isSearch:true,sorter: (a, b) =>StringCompareSort(a.manager,b.manager),
            render:(text, record) =><Row>
                {record.manager instanceof Array?record.manager.map((tag,index)=>{
                    const tagElem = (<Tag key={tag} color={"geekblue"}>{tag}</Tag>);
                    return (tagElem);
                }):record.manager?(<Tag key={record.manager} color={"geekblue"}>{record.manager}</Tag>):null}
            </Row>},
        {title:"创建时间",dataIndex:"createTime",key:"createTime",width:180,ellipsis:true,sortDirections: ['ascend','descend'],sorter: (a, b) => Date.parse(a.createTime) - Date.parse(b.createTime)},
        {title:"创建人",dataIndex:"creator",key:"creator",width:120,ellipsis:true,isSearch:true,sorter: (a, b) =>StringCompareSort(a.creator,b.creator),
            render:(text, record) =><Row>
                {text instanceof Array?text.map((tag,index)=>{
                    const tagElem = (<Tag key={tag} color={"geekblue"}>{tag}</Tag>);
                    return (tagElem);
                }):text?(<Tag key={text} color={"geekblue"}>{text}</Tag>):null}
            </Row>},
        {title:'操作',key:'actions',width:150,fixed: 'right', render: (text, record) => false === this.props.editEnable?
            <span><Button size='small' type="link" onClick={()=>this.handleOnReadOnly(record)}>查看</Button></span>:
            <Row>
                <Col span={8}><Button size='small' type="link" onClick={()=>this.handleOnReadOnly(record)}>查看</Button></Col>
                <Col span={8}><Button size='small' type="link" onClick={()=>this.handleOnEdit(record)}>编辑</Button></Col>
                <Col span={8}><Button size='small' type="link" style={{color:'red'}} onClick={()=>this.handleDelete(record.uuid)}>删除</Button></Col>
            </Row>}]
        let dataSource = [];
        for (let i = 0;i < this.props.dataSource.length;i++){
            let record = this.props.dataSource[i];
            let detail = record.detail;
            let creator = account2Name(record.creator_account);
            let manager = account2Name(detail.manager_account);
            let purchaser = account2Name(detail.purchaser_account);
            dataSource.push({index:i+1,
                uuid: record.uuid,
                title: record.title,
                supplier:detail.supplier,
                count:detail.count,
                money:detail.amount,
                status: record.status,
                note:detail.note,
                purchaser:purchaser,
                purchaseTime:moment(new Date(detail.purchase_time)).format('YYYY-MM-DD HH:mm:ss'),
                manager:manager,
                creator: creator,
                createTime: moment(new Date(record.create_time)).format('YYYY-MM-DD HH:mm:ss')});
        }
        return(<Row>
                {this.renderExportModal()}
                <ExtendTable dataSource={dataSource} rowKey={record => record.uuid} handleAddOnClick={this.handleAddOnClick} onBatchDelete={this.handleEmptySelect} 
                    customColumnsEnable={this.props.customColumnsEnable} tableKey={this.props.tableKey} columns = {showColumns} 
                    exportEnable={this.props.exportEnable} exportTitle={"导出采购申请单"} exportClick={()=>this.setState({showExportModal:true})}/>
                </Row>);
    }
    render() {
        return (<Row>
            <Row>{this.renderTable()}</Row>
            <Divider/>
            {this.renderSetting()}
        </Row>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(PurchaseTable)