import React from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import moment from "moment";
import {Row, Form, Input, Modal, Tag, Button, Col} from "antd";
import {connect} from "react-redux";
import {addNotice, deleteNotice, updateNotice, batchDeleteNotice} from "../../actions/noticeDBTool";
import StaffChoose from "./StaffChoose";
import ExtendTable from "./ExtendTable";
import {account2Name, name2Account} from "../../utils/accountUtils";
import { delHtmlTag, StringCompareSort } from "../../utils/common";

const {confirm,info} = Modal;

const NoticeForm = Form.create({name:'message_modal'})(
    class extends React.Component{
        constructor(props) {
            super(props);
            this.state = {}
        }
        componentDidMount() { }
        render() {
            let { visible, onCancel, onOk, form,values,mode,title } = this.props;
            let { getFieldDecorator } = form;
            return(<Modal title={title} visible={visible} onOk={onOk} maskClosable={false}
                    okText={"确定"} onCancel={onCancel} cancelText={"取消"}>
                <Form labelCol={{span:6}} wrapperCol={{span:18}}>
                    <Form.Item label={"标题"}>
                        {getFieldDecorator('title', {rules: [{required: true, message: '标题不能为空!'}],initialValue: mode ? values.title : ''})
                        (<Input placeholder={"例如:关于XX事宜的通知"}/>)}
                    </Form.Item>
                    <Form.Item label={"内容"}>
                        {getFieldDecorator('content',{initialValue:mode?values.content:''})
                        (<ReactQuill placeholder={"输入通知内容"}/>)}
                    </Form.Item>
                    <Form.Item label={"抄送给"}>
                        {getFieldDecorator('assigner', {initialValue:values.assigner?values.assigner:""})
                        (<StaffChoose mode={1}/>)}
                        {<span style={{color:'red'}}>(不选择任何抄送人，则为全部成员)</span>}
                    </Form.Item>
                </Form>
            </Modal>);
        }
    }
);
function mapStateToProps(state) {
    return {...state.notice};
}
function mapDispatchToProps(dispatch) {
    return {onAddNotice:(postData)=>dispatch(addNotice(postData)),
            onUpdateNotice:(postData)=>dispatch(updateNotice(postData)),
            onDeleteNotice:(uuid)=>dispatch(deleteNotice(uuid)),
            onBatchDelete:(uuid_list)=>dispatch(batchDeleteNotice(uuid_list))};
}
class NoticeTable extends React.Component{
    constructor(props) {
        super(props);
        this.state = {showMsgModal:false, modalMode:0,modalTitle:'',modalValues:{}}
        this.handleEditNotice = this.handleEditNotice.bind(this);
    }
    componentDidMount(){}
    saveFormRef = formRef => {this.formRef = formRef;}
    handleEmptySelect=(uuid_list)=>{
        this.props.onBatchDelete(uuid_list);
    }
    handleAddOnClick=()=>{
        this.setState({showMsgModal:true,modalMode:0,modalTitle:'新增通知',modalValues:{}});
    }
    handleEditNotice(record){
        for(let i = 0;i < this.props.get_notice_result.length;i++){
            let item = this.props.get_notice_result[i];
            if(item.uuid === record.uuid){
                this.setState({showMsgModal:true,modalMode:1,modalTitle:'修改通知内容',
                                modalValues:{uuid:item.uuid,title:item.title,
                                            content:item.detail.content_file_name,
                                            assigner:account2Name(item.detail.assigner_account),
                                            creator:account2Name(item.creator_account),
                                            createTime:item.create_time}})
            }
        }
    }
    handleNoticeSubmit =()=>{
        const { form } = this.formRef.props;
        form.validateFields((err, fromValues) => {
            if (err) {return;}
            let assigner_account = name2Account(fromValues.assigner);
            if(0 >= fromValues.assigner.length){
                assigner_account = "";
            }
            let detail = {  content_file_name:fromValues.content,
                            assigner_account:assigner_account,
                            read_list:[]
                        }
            let postData = {
                enterprise_uuid:localStorage.getItem('enterprise_uuid'),
                title:fromValues.title,
                status:1,
                type:1,
                detail:JSON.stringify(detail),
                creator_account:localStorage.getItem('account'),
                create_time:Date.parse(new Date())
            };
            if(this.state.modalMode){
                postData['uuid'] = this.state.modalValues.uuid;
                this.props.onUpdateNotice(postData);
            }
            else{
                let uuid = Date.parse(new Date())/1000+(Math.round(Math.random() * (9000))+999);
                postData['uuid'] = uuid;
                this.props.onAddNotice(postData);
            }
            form.resetFields();
            this.setState({ showMsgModal: false });
        });
    }
    handleCancel = ()=>{
        const { form } = this.formRef.props;
        form.resetFields();
        this.setState({showMsgModal:false})
    }
    showDeleteConfirm = (record) =>{
        confirm({
            content: '您确定要删除该通知?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk:()=> {
                let item = this.props.get_notice_result[record.index - 1];
                this.props.onDeleteNotice(item.uuid);
            },
            onCancel() {
                console.log('Cancel');
            },
        })
    }
    showContentInfo = (record) =>{//show the read notice modal
        let modifyItem = "";
        for (let i = 0;i < this.props.get_notice_result.length;i++){
            let item = this.props.get_notice_result[i];
            if(item.uuid === record.uuid){
                modifyItem = item;
                break;
            }
        }
        let detail = modifyItem.detail;
        info({
            title:modifyItem.title,
            content:delHtmlTag(detail.content_file_name),//TODO
            okType:'primary',
            cancelText:'取消',
            okText:'确定',
            onOk:()=>{
                let readList = detail.read_list;
                if(readList && readList.length > 0){
                    for(let j = 0;j<readList.length;j++){
                        if(localStorage.getItem('account') === readList[j]){
                            return;
                        }
                    }
                }
                readList.push(localStorage.getItem('account'));
                detail = {"read_list":readList,"content_file_name":modifyItem.detail.content_file_name};
                let postData = {
                    "uuid":modifyItem.uuid,
                    "enterprise_uuid":localStorage.getItem('enterprise_uuid'),
                    "title":modifyItem.title,
                    "type":modifyItem.type,
                    "status":modifyItem.status,
                    "detail":JSON.stringify(detail),
                    "creator_account":modifyItem.creator_account,
                    "create_time":Date.parse(modifyItem.create_time)
                };
                this.props.onUpdateNotice(postData);
            },
            onCancel(){
                console.log('Cancel');
            }
        })
    }
    renderSetting(){
        return(<NoticeForm wrappedComponentRef={this.saveFormRef} visible={this.state.showMsgModal} 
                values={this.state.modalValues} mode={this.state.modalMode} title={this.state.modalTitle} 
                onCancel={this.handleCancel} onOk={this.handleNoticeSubmit}/>);
    }
    renderNoticeTable(){
        const columns = [
            {title: '序号',dataIndex:'index',key:'index',width:80,ellipsis:true,},
            {title: '标题',dataIndex: 'noticeTitle',key:'noticeTitle',width:120,ellipsis:true,isSearch:true},
            {title: '创建时间',dataIndex: 'createTime',key: 'createTime',width:150,ellipsis:true,sorter: (a, b) => Date.parse(a.createTime) - Date.parse(b.createTime),
                render:(text, record) =><span>{text}</span>},
            {title: '创建者',dataIndex: 'creator',key: 'creator',width:120,ellipsis:true,isSearch:true,sorter: (a, b) =>StringCompareSort(a.creator,b.creator),
                render:(text, record) =><Row>{text instanceof Array?text.map((tag,index)=>{
                    const tagElem = (<Tag key={tag} color={"geekblue"}>{tag}</Tag>);
                    return (tagElem);
                }):text?(<Tag key={text} color={"geekblue"}>{text}</Tag>):null}
            </Row>},
            {title: '抄送给',dataIndex: 'assigner',key: 'assigner',width:120,ellipsis:true,isSearch:true,sorter: (a, b) =>StringCompareSort(a.assigner,b.assigner),
            render:(text, record) =><Row>{text instanceof Array?text.map((tag,index)=>{
                const tagElem = (<Tag key={tag} color={"geekblue"}>{tag}</Tag>);
                return (tagElem);}):text?(<Tag key={text} color={"geekblue"}>{text}</Tag>):null}
            </Row>},
            {title: '操作',dataIndex:'actions',key:'actions',width:150,fixed: 'right', render: (text, record) =><Col>
                <Col span={8}><Button size='small' type="link" onClick={()=>this.showContentInfo(record)}>查看</Button></Col>
                <Col span={8}>{localStorage.getItem('name')===record.creator?<Button size='small' type="link" onClick={()=>this.handleEditNotice(record)}>编辑</Button>:null}</Col>
                <Col span={8}>{localStorage.getItem('name')===record.creator?<Button size='small' type="link" style={{color:'red'}} onClick={()=>this.showDeleteConfirm(record)}>删除</Button>:null}</Col>
            </Col>
            }
        ];
        let dataSource = [];
        for(let i = 0;i < this.props.dataSource.length;i++){
            let noticeItem = this.props.dataSource[i];
            let creator = account2Name(noticeItem.creator_account);
            let assigner = noticeItem.detail.assigner_account?noticeItem.detail.assigner_account:"";
            if(0 === assigner.length){
                assigner="所有成员";
            }
            else{
                assigner = account2Name(assigner);
            }
            let item = {index:i+1,
                uuid:noticeItem.uuid,
                noticeTitle: noticeItem.title,
                creator:creator,
                assigner:assigner,
                createTime:moment(noticeItem.create_time).format('YYYY-MM-DD HH:mm:ss')
            }
            dataSource.push(item);
        }
        return (<ExtendTable handleAddOnClick={this.handleAddOnClick} onBatchDelete={this.handleEmptySelect} 
                    columns={columns} dataSource={dataSource} rowKey={record => record.uuid}/>);
    }
    render() {
        return (<Row>
                    <Row>{this.renderNoticeTable()}</Row>
                    {this.renderSetting()}
                </Row>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(NoticeTable)