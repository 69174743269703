import {
    ActionType
} from "../constants/actionTypes"
export default (state={},action)=>{
    switch (action.type) {
        case ActionType.ADD_WORK_ORDER_START:
            return {...state,add_work_order:false};
        case ActionType.ADD_WORK_ORDER_SUCCESS:
            return {...state,add_work_order: true,add_work_order_result:action.payload};
        case ActionType.ADD_WORK_ORDER_FAILED:
            return {...state,add_work_order: false,add_work_order_error:action.errors?action.payload.errors:null};

        case ActionType.GET_WORK_ORDER_START:
            return {...state,get_work_order:false}
        case ActionType.GET_WORK_ORDER_SUCCESS:
            return {...state,get_work_order: true,get_work_order_result:action.payload}
        case ActionType.GET_WORK_ORDER_FAILED:
            return {...state,get_work_order: false,get_work_order_error:action.errors?action.payload.errors:null}

        case ActionType.GET_WORK_ORDER_BY_CUSTOM_START:
            return {...state,get_work_order_by_custom: false}
        case ActionType.GET_WORK_ORDER_BY_CUSTOM_SUCCESS:
            return {...state,get_work_order_by_custom: true,get_work_order_by_custom_result:action.payload}
        case ActionType.GET_WORK_ORDER_BY_CUSTOM_FAILED:
            return {...state,get_work_order_by_custom:false,get_work_order_by_custom_error:action.errors?action.payload.errors:null}

        case ActionType.UPDATE_WORK_ORDER_START:
            return {...state,update_work_order:false};
        case ActionType.UPDATE_WORK_ORDER_SUCCESS:
            return {...state,update_work_order:false,update_work_order_result:action.payload};
        case ActionType.UPDATE_WORK_ORDER_FAILED:
            return {...state,update_work_order:false,update_work_order_error:action.payload?action.payload.errors:null};

        case ActionType.DELETE_WORK_ORDER_START:
            return {...state,delete_work_order:false};
        case ActionType.DELETE_WORK_ORDER_FAILED:
            return {...state,delete_work_order:false,delete_work_order_error:action.errors?action.payload.errors:null};
        case ActionType.DELETE_WORK_ORDER_SUCCESS:
            return {...state,delete_work_order:false,delete_work_order_result:action.payload};
        default:return {...state};
    }
}