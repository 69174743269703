import React from "react";
import {Tabs} from 'antd';
import { connect } from 'react-redux';
import {USER_LEVEL_ADMIN, USER_LEVEL_LEADER} from '../../../constants/constant';
import BusinessOpportunityTable from "../../controls/BusinessOpportunityTable";

const {TabPane} = Tabs;
function mapStateToProps(state) {
    return {...state.businessOpportunity}
}
function mapDispatchToProps(dispatch) {
    return {}
}
class BusinessOpportunity extends React.Component{
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount(){}
    render(){
        let allDS = [];
        let myBusinessDS = [];
        if(this.props.get_business_opportunity_result){
            for (let i =0;i < this.props.get_business_opportunity_result.length;i++){
                let item = this.props.get_business_opportunity_result[i];
                allDS.push(item);
                if(item.detail.manager_account && -1 !== item.detail.manager_account.indexOf(localStorage.getItem('account'))){
                    myBusinessDS.push(item);
                }
            }
        }
        return (<Tabs defaultActiveKey={"2"}>
                {(USER_LEVEL_ADMIN||USER_LEVEL_LEADER) === Number(localStorage.getItem('level'))?
                    (<TabPane key={"1"} tab={"全部"}>
                        <BusinessOpportunityTable dataSource={allDS} customColumnsEnable={true} tableKey={'crm/business'}/>
                    </TabPane>):null}
                    <TabPane key={"2"} tab={"由我负责"}>
                        <BusinessOpportunityTable dataSource={myBusinessDS} customColumnsEnable={true} tableKey={'crm/business'}/>
                    </TabPane>
                </Tabs>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(BusinessOpportunity);