import React from 'react';
import { Upload,Button, Row,Modal, message,Progress } from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import * as Service from '../../config/service.json';
import { checkObjectExist, deleteObject, sizeOfStoreage, uploadFile } from '../../utils/minioutil';
import { connect } from 'react-redux';
const {confirm} = Modal;
function mapStateToProps(state){
    return{}
}
function mapDispatchToProps(dispatch){
    return{}
}
class FileManager extends React.Component{
    constructor(props){
        super(props);
        this.state = {fileUrl:[],uploadProcessValue:10,uploadProcessVisible:false};
        this.getFileName = this.getFileName.bind(this);
        this.beforeUpload = this.beforeUpload.bind(this);
    }
    componentDidMount(){
        message.config({top:200,duration:5})
    }
    componentWillUnmount(){
        message.destroy()
    }
    componentDidUpdate(){
        //this.setState({fileUrl:this.props.fileList?this.props.fileList:[]})
    }
    countDown=()=>{
        if (this.state.uploadProcessValue === 0) {
            this.setState({uploadProcessValue:20});
            setTimeout(this.countDown, 1000);
        } else {
            let uploadProcessValue = this.state.uploadProcessValue;
            if(uploadProcessValue >= 90){return}
            this.setState({uploadProcessValue:uploadProcessValue + 5});
            setTimeout(this.countDown, 1000);
        }
    }
    triggerChange=(changedValue)=>{
        const onChange = this.props.onChange;
        if (onChange) {onChange(changedValue);}
        console.log(changedValue)
    }
    saveFormRef = formRef => {this.formRef = formRef;}
    beforeUpload(file,fileList){
        if(file.size/1024/1024 > 100){
            message.error("文件太大，仅支持最大单个文件大小为100M！",8)
            return false
        }
        sizeOfStoreage((size)=>{
            let totalSize = localStorage.getItem('max_storage');
            if(size/1024/1024/1024 >= totalSize){
                message.error('超过了总容量，请扩容后继续上传！',8)
                return false
            }
            else{
                let filePrefix = this.props.filePrefix?this.props.filePrefix:""
                checkObjectExist(filePrefix + file.name,(err,dataStream)=>{
                    console.log(err)
                    console.log(dataStream)
                    if(dataStream){
                        this.showFileExistTips(file)
                    }
                    else{
                        this.setState({uploadProcessVisible:true,uploadProcessValue:0})
                        this.countDown()
                        uploadFile(filePrefix,file,()=>{
                            message.success("文件上传成功")
                            let fileUrlList = []
                            if(this.props.fileList instanceof Array){
                                fileUrlList = this.props.fileList
                            }
                            else if(this.props.fileList){
                                fileUrlList.push(this.props.fileList)
                            }
                            fileUrlList.push(file.name);
                            this.setState({fileUrl:fileUrlList,uploadProcessVisible:false,uploadProcessValue:100})
                            this.triggerChange(fileUrlList)
                        })
                    }
                })
            }
        })
        return false
    }
    showFileExistTips(file){
        confirm({
            content: '已存在名称:'+file.name+'文件，是否替换?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk:()=> {
                this.setState({uploadProcessVisible:true,uploadProcessValue:0})
                this.countDown()
                let filePrefix = this.props.filePrefix?this.props.filePrefix:""
                uploadFile(filePrefix,file,()=>{
                    message.success("文件上传成功")
                    let fileUrlList = []
                    if(this.props.fileList instanceof Array){
                        fileUrlList = this.props.fileList
                    }
                    else {
                        fileUrlList.push(this.props.fileList);
                    }
                    fileUrlList.push(file.name);
                    this.setState({fileUrl:fileUrlList,uploadProcessVisible:false,uploadProcessValue:100})
                    this.triggerChange(fileUrlList)
                })
            },
            onCancel:()=>{
                this.setState({FileExistVisible:false,selectFile:null})
                console.log('Cancel');
            },
        })
    }
    deleteFile(filePrefix,fileName){
        console.log(fileName)
        deleteObject(filePrefix + fileName,(error)=>{
            let fileUrlList = []
            if(this.props.fileList instanceof Array){
                fileUrlList = this.props.fileList;
                for(let i=0;i < fileUrlList.length;i++){
                    if(fileUrlList[i] === fileName){
                        fileUrlList.splice(i,1)
                    }
                }
            }
            else{
                if(fileUrlList === fileName){
                    fileUrlList = ""
                }
            }
            this.triggerChange(fileUrlList)
            this.setState({fileUrl:fileUrlList})
        })
    }
    getFileName(fileUrl){
        if(!fileUrl){
            return;
        }
        return fileUrl.split("/")[fileUrl.split("/").length - 1]
    }
    renderFileUrl(){
        console.log(this.props.fileList)
        return(<Row>
                    { this.props.fileList instanceof Array?this.props.fileList.map(item=><div key={item}>
                        <a href={Service.resServer +localStorage.getItem("enterprise_uuid") + "/" + this.props.filePrefix + item} rel="noopener noreferrer" target="_blank">{this.getFileName(item)}</a>
                        <Button disabled={this.props.disabled} type='link' style={{color:this.props.disabled?'#a0a0a0':'#FF0000'}} onClick={()=>{this.deleteFile(this.props.filePrefix,item)}}>删除</Button>
                    </div>):
                    <div>
                        <a href={Service.resServer +localStorage.getItem("enterprise_uuid") + "/" + this.props.fileList} 
                            rel="noopener noreferrer" target="_blank">{this.getFileName(this.props.fileList)}</a>
                            {this.props.fileList?<div>
                        <Button disabled={this.props.disabled} type='link' style={{color:'#FF0000'}} onClick={()=>{this.deleteFile(this.props.filePrefix,this.props.fileList)}}>删除</Button></div>:null}

                    </div>}
                    <Upload beforeUpload={this.beforeUpload}>
                        <Progress percent={this.state.uploadProcessValue} size="small" hidden={!this.state.uploadProcessVisible}/>
                        {this.props.disabled || (this.props.maxCount && this.props.fileList.length > this.props.maxCount)?null:<Button type='link'><PlusOutlined/>添加上传</Button>}
                    </Upload>
                    {this.props.maxCount && this.props.fileList.length < this.props.maxCount?<span style={{color:'red'}}>到达最大文件个数!</span>:null}
                </Row>)
                    
    }
    render(){
        return(this.renderFileUrl())
    }
}
export default connect(mapStateToProps,mapDispatchToProps,null,{forwardRef: true})(FileManager);