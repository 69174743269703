import React from 'react';
import {Row, Tabs} from 'antd';
import {connect} from "react-redux";
import {getTravel} from "../../actions/travelDBTool";
import TravelTable from "../controls/TravelTable";
import {USER_LEVEL_MEMBER} from "../../constants/constant";
const { TabPane } = Tabs;
function mapStateToProps(state) {
    return {...state.travel}
}
function mapDispatchToProps(dispatch) {
    return {onGetTravel:()=>dispatch(getTravel())}
}

class TravelList extends React.Component{
    constructor(props){
        super(props);
        this.state = {};
    }
    componentDidMount(){ this.props.onGetTravel();}
    renderTravel(){
        let allDS = []
        let myManagerDs = [];
        let myCreateDS = [];
        let myAssignerDS = [];
        if(this.props.get_travel_result){
            let result = this.props.get_travel_result;
            result.sort(function (a,b) { return Date.parse(b.create_time) - Date.parse(a.create_time);})
            for (let i = 0;i < result.length;i++){
                let detail = result[i].detail
                if(detail.manager_account && -1 !== detail.manager_account.indexOf(localStorage.getItem('account'))){
                    myManagerDs.push(result[i]);
                }
                if(detail.assigner_account && -1 !== detail.assigner_account.indexOf(localStorage.getItem('account'))){
                    myAssignerDS.push(result[i]);
                }
                if(-1 !== result[i].creator_account.indexOf(localStorage.getItem('account'))){
                    myCreateDS.push(result[i]);
                }
                allDS.push(result[i])
            }
        }
        const editable = USER_LEVEL_MEMBER < Number(localStorage.getItem('level'))?true:false
        return (<Tabs defaultActiveKey={"1"} type={"card"}>
             {editable?<TabPane key={"1"} tab={"全部"}>
                <TravelTable dataSource={allDS} customColumnsEnable={true} tableKey={'work/travel'} exportEnable={editable} editEnable={editable}/>
            </TabPane>:null}
            <TabPane key={"2"} tab={"我申请的"}>
                <TravelTable dataSource={myCreateDS} customColumnsEnable={true} tableKey={'work/travel'} exportEnable={editable} editEnable={editable}/>
            </TabPane>
            <TabPane key={"3"} tab={"我审批的"}>
                <TravelTable dataSource={myManagerDs} customColumnsEnable={true} tableKey={'work/travel'} exportEnable={editable} editEnable={editable}/>
            </TabPane>
            <TabPane key={"4"} tab={"抄送给我"}>
                <TravelTable dataSource={myAssignerDS} customColumnsEnable={true} tableKey={'work/travel'} exportEnable={editable} editEnable={editable}/>
            </TabPane>
        </Tabs>);
    }
    render(){return (<Row>{this.renderTravel()}</Row>);}
}
export default connect(mapStateToProps,mapDispatchToProps)(TravelList);