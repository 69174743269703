import React from 'react';
import {Row,Tabs} from 'antd';
import CustomTable from '../../controls/CustomTable';
import {connect} from "react-redux";
import {getAllCustom} from "../../../actions/customDBTool";
import {CUSTOM_STATUS_SUCCESS, USER_LEVEL_MEMBER} from "../../../constants/constant";
const { TabPane } = Tabs;
function mapStateToProps(state){
    return {...state.custom};
}
function mapDispatchToProps(dispatch){
    return {onGetCustom:()=>dispatch(getAllCustom())};
}

class SuccessCustom extends React.Component {
    constructor(props){
        super(props);
        this.state = {};
    }
    componentDidMount() {
        this.props.onGetCustom();
    }

    renderSuccessCustom(){
        let successCustomDS = [];
        let allSuccessCustomDS = [];
        if(this.props.get_custom_result){
            let result = this.props.get_custom_result;
            for (let i = 0;i < result.length;i++){
                if(CUSTOM_STATUS_SUCCESS === result[i].status){
                    if(-1 !== result[i].detail.manager_account.indexOf(localStorage.getItem('account'))){
                        successCustomDS.push(result[i]);
                    }
                    allSuccessCustomDS.push(result[i]);
                }
            }
        }
        const customEditable = USER_LEVEL_MEMBER < Number(localStorage.getItem('level'))?true:false
        const isManager = USER_LEVEL_MEMBER < Number(localStorage.getItem('level'))?true:false
        return(<Tabs defaultActiveKey={"2"}>
            {isManager?(
                <TabPane key="1" tab="全部成交客户">
                    <CustomTable dataSource={allSuccessCustomDS} tableKey={'crm/success_custom'} exportEnable={customEditable} editEnable={customEditable} batchDelEnable={customEditable} batchEditEnable={customEditable}/>
                </TabPane>
            ):null}
            <TabPane key="2" tab="我的成交客户">
                <CustomTable dataSource={successCustomDS} tableKey={'crm/success_custom'} exportEnable={customEditable} editEnable={customEditable} batchDelEnable={customEditable} batchEditEnable={customEditable}/>
            </TabPane>
        </Tabs>);
    }
    render(){
        return (<Row>{this.renderSuccessCustom()}</Row>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(SuccessCustom);