import React from 'react'
import {Layout} from 'antd';
import FrameSide from './side';
import FrameHeader from './header';
import {AllItemsRouter} from "../router/router";
import {getAllUser} from "../actions/userDBTool";
import {getEnterprise} from "../actions/enterpriseDBTool";
import {connect} from "react-redux";

const {Content} = Layout

function mapStateToProps(state) {
    return {...state.auth,...state.enterprise,...state.staff};
}
function mapDispatchToProps(dispatch) {
    return {onGetEnterprise:()=>dispatch(getEnterprise()),
            onGetAllStaff:()=>dispatch(getAllUser()),
        }
}
class FrameWorkComponent extends React.Component{
    constructor(props) {
        super(props);
        this.state = {}
    }
    componentDidMount() {
        document.title = "多易CRM--客户关系管理系统";
        this.props.onGetEnterprise();
        this.props.onGetAllStaff();
    }

    renderContent(){
        return (<Content style={{background: '#f5f5f5',padding: 18,margin: 0}}>
                    <AllItemsRouter/>
                </Content>);
    }
    render(){
        return(<Layout breakpoint='lg' className="layout" style={{maxHeight:document.body.clientHeight}}>
                <FrameHeader/>
                <Layout breakpoint='lg'>
                    <FrameSide/>
                    <Layout breakpoint='lg'>
                        {this.renderContent()}
                        {/*<Footer style={{background:'#FFF'}}>©2020 Created by lingduo copyright </Footer>*/}
                    </Layout>
                </Layout>
            </Layout>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(FrameWorkComponent);