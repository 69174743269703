import $ from 'jquery'
//import axios from "axios";
import {message} from "antd";
import * as Service from '../config/service.json';
import {ActionType} from "../constants/actionTypes";
import {preHandle,checkAccountStatus} from './commonDBTool';
export function getAllNotice(pageStart=0,pageSize=50) {
    let url = Service.dbServer + Service.apiVersion + "/notice/getAll" ;
    return dispatch =>{
        dispatch({type:ActionType.GET_NOTICE_START});
        $.ajax(preHandle(url),{
            method:'get',
            dataType:'json',
            data:{pageStart:pageStart,pageSize:pageSize}
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            if(200 === response.code){
                let result = response.result
                if(result){
                    for(let i = 0;i < result.length;i++){
                        result[i].detail = JSON.parse(result[i].detail);
                    }
                }
                dispatch({type:ActionType.GET_NOTICE_SUCCESS,payload:result});
            }
            else{
                dispatch({type:ActionType.GET_NOTICE_FAILED,payload:response.message});
            }
        }).catch(function (error) {
            dispatch({type:ActionType.GET_NOTICE_FAILED,payload:error});
        })
    }
}
export function addNotice(postData) {
    let url = Service.dbServer + Service.apiVersion + "/notice/add" ;
    return dispatch =>{
        dispatch({type:ActionType.ADD_NOTICE_START});
        $.ajax(preHandle(url),{
            method:'POST',
            contentType:'application/json; charset=utf-8',
            dataType:'json',
            data: JSON.stringify(postData)
        }).then(response =>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            message.info("添加成功!");
            dispatch({type:ActionType.ADD_NOTICE_SUCCESS,payload:response.result});
            dispatch(getAllNotice());
        }).catch(function (error) {
            message.error("添加失败！");
            dispatch({type:ActionType.ADD_NOTICE_FAILED,payload:error});
        })
    }
}
export function updateNotice(postData) {
    let url = Service.dbServer + Service.apiVersion + "/notice/update";
    console.log(postData);
    return dispatch =>{
        dispatch({type:ActionType.UPDATE_NOTICE_START});
        $.ajax(preHandle(url),{
            method:'POST',
            contentType:'application/json; charset=utf-8',
            dataType:'json',
            data: JSON.stringify(postData)
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            dispatch({type:ActionType.UPDATE_NOTICE_SUCCESS,payload:response.result});
            dispatch(getAllNotice());
        }).catch(function (error) {
            dispatch({type:ActionType.UPDATE_NOTICE_FAILED,payload:error});
        })
    }
}
export function deleteNotice(uuid) {
    let url = Service.dbServer + Service.apiVersion + "/notice/delete" ;
    return dispatch =>{
        dispatch({type:ActionType.DELETE_NOTICE_START});
        $.ajax(preHandle(url),{
            method:'delete',
            //contentType:'application/json; charset=utf-8',
            dataType:'json',
            data:preHandle({uuid:uuid})
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            message.info("删除成功！");
            dispatch({type:ActionType.DELETE_NOTICE_SUCCESS,payload:response.result});
            dispatch(getAllNotice());
        }).catch(function (error) {
            message.error("删除失败！")
            dispatch({type:ActionType.DELETE_NOTICE_FAILED,payload:error});
        })
    }
}
export function batchDeleteNotice(uuid_list){
    let url = Service.dbServer + Service.apiVersion + "/notice/batchDel" ;
    return dispatch=>{
        $.ajax(preHandle(url),{
            method:'post',
            dataType:'json',
            data:({notice_list:JSON.stringify(uuid_list),})
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            if(200 === response.code){
                message.info("删除成功！");
                dispatch(getAllNotice());
            }
        }).catch(function (error) {
            message.error("删除失败！")
        })
    }
}