import React from 'react';
import {Button, Form, Input, Layout, Row, Col, Divider,Checkbox} from 'antd';
import {UserOutlined,LockOutlined,EyeInvisibleOutlined,EyeOutlined} from '@ant-design/icons';
import {login} from "../actions/userDBTool";
import {connect} from "react-redux";
import FrameHeader from "./header";

const {Content,Footer} = Layout;
function mapStateToProps(state){
    return {...state.auth};
}
function mapDispatchToProps(dispatch) {
    return {onLogin:(account,password)=>dispatch(login(account,password))}
}
class Login extends React.Component{
    constructor(props){
        super(props);
        this.state = {loginLoading:false,maxTime:8};
        this.countDown = this.countDown.bind(this);
    }
    componentDidMount(){
        document.title = "多易CRM--登录"
        if (this.props.login){
            this.setState({loginLoading:false})
        }
    }
    componentWillUnmount(){
        this.setState = (state,callback)=>{
            return;
        };      
    }
    countDown(){
        if (this.state.maxTime === 0) {
            this.setState({maxTime:8,loginLoading:false});
        } else {
            let maxTime = this.state.maxTime;
            this.setState({maxTime:--maxTime,loginLoading:true});
            setTimeout(this.countDown, 1000);
        }
    }
    handleSubmit(event){
        event.preventDefault();
        this.setState({loginLoading:true})
        this.props.onLogin(event.target.account.value, event.target.password.value);
        this.countDown();
    }
    renderLoginWindow(){
        return (<Row style={{textAlign:"center",background:'#e0e0e080',marginTop:'10vh'}}>
                    <br/>
                    <Row><h2 style={{color:'#40a9ff'}}>欢迎登录</h2></Row>
                    <Form onSubmit={e => this.handleSubmit(e)}>
                        <Form.Item>
                            <Input name='account' size='large' style={{width:'75%'}} prefix={<UserOutlined />}
                                placeholder="请输入账号/邮箱/手机号" defaultValue={localStorage.getItem('account')} required/>
                        </Form.Item>
                        <Form.Item>
                            <Input name='password' size='large' style={{width:'75%'}} prefix={<LockOutlined />}
                                placeholder="请输入密码" defaultValue={localStorage.getItem('password')} required
                                type={this.state.showPass?"text":"password"}
                                addonAfter={this.state.showPass?
                                    <EyeInvisibleOutlined onClick={()=>{this.setState({showPass:!this.state.showPass})}}/>:
                                    <EyeOutlined onClick={()=>{this.setState({showPass:!this.state.showPass})}}/>}/>
                        </Form.Item>
                        <Form.Item>
                        <Row>
                            <Button size='large' type="primary" style={{width:'75%'}} htmlType="submit" loading={this.state.loginLoading}>登录</Button>
                        </Row>
                        <Row>
                            <Col span={8} offset={4}><Checkbox><span>记住用户名</span></Checkbox></Col>
                            <Col span={8}><Button type="link" onClick={()=>{this.props.history.push('/resetPassword')}}>忘记密码</Button></Col>
                        </Row>
                        <Divider/>
                        <Row>
                            <span style={{fontWeight: "bold"}}>没有账号？</span>
                            <Button size='large' type={'primary'} onClick={()=>{this.props.history.push('/register')}}>免费注册企业</Button>
                        </Row>
                    </Form.Item>
                </Form>
                </Row>);
    }
    renderContent(){
        return (<Row style={{textAlign:'center',marginTop:'10vh'}}>
            <h1 style={{color:'RGB(255,255,255)'}}>1. 助力业绩增长</h1>
            <h1 style={{color:'RGB(255,255,255)'}}>2. 提高管理效率</h1>
            <h1 style={{color:'RGB(255,255,255)'}}>3. 有效积累客户</h1>
            <h1 style={{color:'RGB(255,255,255)'}}>4. 团队高效合作</h1>
            <h1 style={{color:'RGB(255,255,255)'}}>5. 降低企业成本</h1>
            <h1 style={{color:'RGB(255,255,255)'}}>6. 免费使用30天</h1>
        </Row>)
    }
    render(){
        return (<Layout>
                    <Content style={{minHeight:'100vh',backgroundImage:"url("+require('../assets/images/login_1.jpg') +")"}}>
                        <FrameHeader/>
                        <Row>
                            <Col lg={12} xs={24}>{this.renderContent()}</Col>
                            <Col lg={8} xs={24}>{this.renderLoginWindow()}</Col>
                        </Row>
                    </Content>
                    <Footer className='footer'>
                        <span>©2020 Created by lingduo copyright</span>
                    </Footer>
                </Layout>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Login);