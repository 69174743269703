import {ActionType} from "../constants/actionTypes";

const noticeReducer = (state={},action)=>{
    switch (action.type) {
        case ActionType.ADD_NOTICE_START:
            return {...state, add_notice:false}
        case ActionType.ADD_NOTICE_FAILED:
            return {...state, add_notice:false,add_notice_errors:action.payload?action.payload:null}
        case ActionType.ADD_NOTICE_SUCCESS:
            return {...state, add_notice: true, add_notice_result:action.payload}


        case ActionType.GET_NOTICE_START:
            return {...state, get_notice: false};
        case ActionType.GET_NOTICE_FAILED:
            return {...state, get_notice: false,get_notice_errors:action.payload?action.payload:null};
        case ActionType.GET_NOTICE_SUCCESS:
            return {...state, get_notice: true, get_notice_result:action.payload}


        default:
            return {...state};
    }
}
export default noticeReducer;