import React from 'react';
import {Row,Tabs} from 'antd';
import CustomTable from '../../controls/CustomTable';
import {connect} from "react-redux";
import {getAllCustom} from "../../../actions/customDBTool";
import {isToday} from "../../../utils/common";
import {CUSTOM_STATUS_UN_FOLLOW,USER_LEVEL_MEMBER} from "../../../constants/constant";

const { TabPane } = Tabs;
function mapStateToProps(state) {
    return {...state.custom};
}
function mapDispatchToProps(dispatch) {
    return {onGetCustom:()=>dispatch(getAllCustom())};
}
class ShareCustom extends React.Component{
    constructor(props) {
        super(props);
        this.state = {}
    }
    componentDidMount() {
        this.props.onGetCustom();
    }
    renderShareCustom(){
        let shareCustomDS = [];
        let neverFollowDS = [];
        let todayNewDS = [];
        if(this.props.get_custom_result){
            let result = this.props.get_custom_result;
            for (let i = 0;i < result.length;i++){
                let item = result[i];
                let detail = (item.detail);
                if(detail.manager_account instanceof Array &&-1 !== detail.manager_account.indexOf(localStorage.getItem('account'))){
                    shareCustomDS.push(item);
                    if(CUSTOM_STATUS_UN_FOLLOW === item.status){
                        neverFollowDS.push(item);
                    }
                    if(isToday(item.create_time)){
                        todayNewDS.push(item);
                    }
                }
            }
        }
        const customEditable = USER_LEVEL_MEMBER < Number(localStorage.getItem('level'))?true:false
        return (<Tabs defaultActiveKey={"1"}>
            <TabPane key={"1"} tab={"全部"}>
                <CustomTable dataSource={shareCustomDS} exportEnable={customEditable} editEnable={customEditable} batchDelEnable={customEditable} batchEditEnable={customEditable} tableKey={'crm/share_custom'}/>
            </TabPane>
            <TabPane key={"2"} tab={"未跟进"}>
                <CustomTable dataSource={neverFollowDS} exportEnable={customEditable} editEnable={customEditable} batchDelEnable={customEditable} batchEditEnable={customEditable} tableKey={'crm/share_custom'}/>
            </TabPane>
            <TabPane key={"3"} tab={"今日新增"}>
                <CustomTable dataSource={todayNewDS} exportEnable={customEditable} editEnable={customEditable} batchDelEnable={customEditable} batchEditEnable={customEditable} tableKey={'crm/share_custom'}/>
            </TabPane>
        </Tabs>);
    }
    render(){
        return (<Row>
                    {this.renderShareCustom()}
                    <span style={{color:'red'}}>共享客户：</span>是指2个成员以上跟进的客户
                </Row>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(ShareCustom);