import React from 'react';
import {Row, Tabs} from 'antd';
import {getShip} from "../../actions/shipDBTool";
import {connect} from "react-redux";
import ShipTable from "../controls/ShipTable";
import {USER_LEVEL_MEMBER} from "../../constants/constant";
const { TabPane } = Tabs;
function mapStateToProps(state) {
    return {...state.ship}
}
function mapDispatchToProps(dispatch) {
    return {onGetShip:()=>dispatch(getShip())}
}
class ShipList extends React.Component {
    constructor(props){
        super(props);
        this.state = { };
    }
    componentDidMount() { this.props.onGetShip(); }
    renderShip(){
        let allDS = [];
        let myDS = [];
        let assignerDs = [];
        if(this.props.get_ship_result){
            for (let i = 0;i < this.props.get_ship_result.length;i++){
                let record = this.props.get_ship_result[i];
                allDS.push(record)
                if(record.detail.assigner_account && -1 !== record.detail.assigner_account.indexOf(localStorage.getItem('account'))){
                    assignerDs.push(record);
                }
                if(record.detail.manager_account && -1 !== record.detail.manager_account.indexOf(localStorage.getItem('account'))){
                    myDS.push(record);
                }
            }
        }
        const editable = USER_LEVEL_MEMBER < Number(localStorage.getItem('level'))?true:false
        return <Tabs defaultActiveKey={'1'} type={"card"}>
            <TabPane key={'1'} tab={"全部"}>
                <ShipTable dataSource={allDS} customColumnsEnable={true} tableKey={'work/ship'} exportEnable={editable} editEnable={editable}/>
            </TabPane>
            <TabPane key={'2'} tab={"我负责的"}>
                <ShipTable dataSource={myDS} customColumnsEnable={true} tableKey={'work/ship'} exportEnable={editable} editEnable={editable}/>
            </TabPane>
            <TabPane key={'3'} tab={"抄送给我"}>
                <ShipTable dataSource={assignerDs} customColumnsEnable={true} tableKey={'work/ship'} exportEnable={editable} editEnable={editable}/>
            </TabPane>
        </Tabs>
    }
    render(){
        return (<Row>{this.renderShip()}</Row>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(ShipList)