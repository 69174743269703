import React from "react";
import {connect} from "react-redux";
import ExtendSelect from './ExtendSelect';
import {getAllProduct} from "../../actions/productDBTool";
function mapStateToProps(state) {
    return {...state.product}
}
function mapDispatchToProps(dispatch) {
    return {onGetAllProduct:()=>dispatch(getAllProduct())}
}
class ProductChooseSelect extends React.Component{
    constructor(props){
        super(props);
        this.state = {};
    }
    componentDidMount(){
        this.props.onGetAllProduct();
    }
    render(){
        let productList = [];
        if(this.props.get_product_result){
            for(let i = 0;i < this.props.get_product_result.length;i++){
                let item = this.props.get_product_result[i];
                productList.push(item.name);
            }
        }
        return (<ExtendSelect style={this.props.style} items={productList} title={this.props.title} placeholder={this.props.placeholder} size={this.props.size}
                    value={this.props.value} onChange={this.props.onChange} disabled={this.props.disabled}/>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps,null,{forwardRef: true})(ProductChooseSelect);