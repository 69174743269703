import React from 'react';
import {connect} from "react-redux";
import {Form,Modal,Switch} from 'antd'
import {updateEnterpriseDetail} from "../../actions/enterpriseDBTool";

const CustomRepeatForm = Form.create({name:'customRepeatForm'})(
class extends React.Component{
    render(){
        let { form,onOk,onCancel} = this.props;
        let { getFieldDecorator } = form;
        let formItemLayout = {labelCol: {span:10}, wrapperCol: {span:14}};
        let detail = JSON.parse(localStorage.getItem("enterprise_detail")).custom_repeat_rule;
        return(<Modal title="客户自定义重复标准" visible={this.props.visible} maskClosable={false} 
                onOk={onOk} onCancel={onCancel} okText={"确定"} cancelText={"取消"}>
                <Form {...formItemLayout}>
                    <Form.Item label={"客户名称"}>
                        {getFieldDecorator('customName',{initialValue:detail?detail.custom_name:true,valuePropName:"checked"})(
                            <Switch checkedChildren="开" unCheckedChildren="关">开启</Switch>)}
                    </Form.Item>
                    <Form.Item label={"手机号码"}>
                        {getFieldDecorator('phone',{initialValue:detail?detail.phone:true,valuePropName:"checked"})(
                            <Switch checkedChildren="开" unCheckedChildren="关">开启</Switch>)}
                    </Form.Item>
                    <Form.Item label={"座机号码"}>
                        {getFieldDecorator('tel',{initialValue:detail?detail.tel:false,valuePropName:"checked"})(
                            <Switch checkedChildren="开" unCheckedChildren="关">开启</Switch>)}
                    </Form.Item>
                    <Form.Item label={"联系地址"}>
                        {getFieldDecorator('address',{initialValue:detail?detail.address:true,valuePropName:"checked"})(
                            <Switch checkedChildren="开" unCheckedChildren="关">开启</Switch>)}
                    </Form.Item>
                    <Form.Item label={"微信号"}>
                        {getFieldDecorator('wechat',{initialValue:detail?detail.wechat:false,valuePropName:"checked"})(
                            <Switch checkedChildren="开" unCheckedChildren="关">开启</Switch>)}
                    </Form.Item>
                    <Form.Item label={"QQ"}>
                        {getFieldDecorator('QQ',{initialValue:detail?detail.QQ:false,valuePropName:"checked"})(
                            <Switch checkedChildren="开" unCheckedChildren="关">开启</Switch>)}
                    </Form.Item>
                </Form>
        </Modal>)
    }
})

function mapStateToProps(state) {
    return {...state.enterprise}
}
function mapDispatchToProps(dispatch) {
    return{onUpdateEnterpriseDetail:(postData)=>dispatch(updateEnterpriseDetail(postData))}
}
class CustomRepeatConfigModal extends React.Component{
    constructor(props){
        super(props);
        this.state = {visible:false,values:{}}
        this.onOk = this.onOk.bind(this)
        this.onCancel = this.onCancel.bind(this)
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(this.props.visible !== nextProps.visible){
            this.setState({visible:true})
        }
        return true
    }
    saveFormRef=formRef=>{this.formRef = formRef;}
    onOk(){
        const {form} = this.formRef.props;
        form.validateFields((err, fromValues) => {
            if (err) {return;}
            let postData = JSON.parse(localStorage.getItem("enterprise_detail"))
            let custom_repeat_rule = {custom_name:fromValues.customName,
                                      phone:fromValues.phone,
                                      tel:fromValues.tel,
                                      address:fromValues.address,
                                      wechat:fromValues.wechat,
                                      QQ:fromValues.QQ
                                    }
            postData['custom_repeat_rule'] = custom_repeat_rule;
            this.props.onUpdateEnterpriseDetail(postData);
            form.resetFields();
            this.setState({visible:false})
        })
    }
    onCancel(){
        this.setState({visible:false})
        const { form } = this.formRef.props;
        form.resetFields();
    }
    render(){
        return (<CustomRepeatForm  wrappedComponentRef={this.saveFormRef} visible={this.state.visible} onCancel={this.onCancel} onOk={this.onOk}/>)
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(CustomRepeatConfigModal);