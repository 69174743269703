import $ from 'jquery'
//import axios from "axios";
import {message} from "antd";
import * as Service from '../config/service.json';
import {ActionType} from "../constants/actionTypes";
import {preHandle,checkAccountStatus} from './commonDBTool';
export function getAllProduct(pageStart=0,pageSize=50) {
    let url = Service.dbServer + Service.apiVersion + "/product/getAll" ;
    return dispatch=>{
        dispatch({type:ActionType.GET_PRODUCT_BY_USER_START});
        $.ajax(preHandle(url),{
            method:'get',
            contentType:'application/json; charset=utf-8',
            dataType:'json',
            data:{pageStart:pageStart,pageSize:pageSize}
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            let result = response.result
            if(result){
                for(let i = 0;i < result.length;i++){
                    result[i].detail = JSON.parse(result[i].detail);
                }
            }
            dispatch({type:ActionType.GET_PRODUCT_BY_USER_SUCCESS,payload:result});
        }).catch(function (error) {
            dispatch({type:ActionType.GET_PRODUCT_BY_USER_FAILED,payload:error});
        })
    }
}
export function getAllProductDel(pageStart=0,pageSize=50) {
    let url = Service.dbServer + Service.apiVersion + "/product/getDelByUser" ;
    return dispatch=>{
        dispatch({type:ActionType.GET_PRODUCT_DEL_START});
        $.ajax(preHandle(url),{
            method:'GET',
            dataType:'json',
            data:{pageStart:pageStart,pageSize:pageSize}
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            let result = response.result
            if(result){
                for(let i = 0;i < result.length;i++){
                    result[i].detail = JSON.parse(result[i].detail);
                }
            }
            dispatch({type:ActionType.GET_PRODUCT_DEL_SUCCESS,payload:response.result});
        }).catch(function (error) {
            dispatch({type:ActionType.GET_PRODUCT_DEL_FAILED,payload:error});
        })
    }
}
export function addProduct(postData) {
    let url = Service.dbServer + Service.apiVersion + "/product/add" ;
    return dispatch=>{
        dispatch({type:ActionType.ADD_PRODUCT_START});
        $.ajax(preHandle(url),{
            method:'POST',
            contentType:'application/json; charset=utf-8',
            dataType:'json',
            data:JSON.stringify(postData)
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            if (200 === response.code){
                message.info("添加商品成功！");
                dispatch({type:ActionType.ADD_PRODUCT_SUCCESS,payload:response.result});
                dispatch(getAllProduct())
            }
            else {
                message.error(response.message);
                dispatch({type:ActionType.ADD_PRODUCT_FAILED,payload:response.result});
            }
        }).catch(function (error) {
            message.error("添加失败！")
            dispatch({type:ActionType.ADD_PRODUCT_FAILED,payload:error});
        })
    }
}
export function addProductDel(productID) {
    let url = Service.dbServer + Service.apiVersion + "/product/addDel" ;
    return dispatch => {
        dispatch({type:ActionType.ADD_PRODUCT_DEL_START});
        $.ajax(preHandle(url),{
            method:'POST',
            //contentType:'application/json; charset=utf-8',
            dataType:'json',
            data:({'product_uuid':productID})
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            if(200 === response.code){
                message.info("删除成功！");
                dispatch({type:ActionType.ADD_PRODUCT_DEL_SUCCESS,payload:response.result});
                dispatch(getAllProduct(localStorage.getItem('account')));
            }
            else{
                message.error(response.message);
                dispatch({type:ActionType.ADD_PRODUCT_DEL_FAILED,payload:response.result});
            }
        }).catch(function (error) {
            message.error("删除失败");
            dispatch({type:ActionType.ADD_PRODUCT_DEL_FAILED,payload:error});
        })
    }
}
export function batchAddProductDel(uuidList){
    let url = Service.dbServer + Service.apiVersion + "/product/batchAddDel" ;
    return dispatch=>{
        $.ajax(preHandle(url),{
            method:'POST',
            dataType:'json',
            data:({product_list:JSON.stringify(uuidList)})
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            if(200 === response.code){
                message.info("删除成功！");
                dispatch(getAllProduct(localStorage.getItem('account')));
            }
        }).catch(function (error) {
            message.error("删除失败");
        })
    }
}
export function updateProduct(postData) {
    let url = Service.dbServer + Service.apiVersion + "/product/update" ;
    return dispatch =>{
        dispatch({type:ActionType.UPDATE_PRODUCT_START});
        $.ajax(preHandle(url),{
            method:'POST',
            contentType:'application/json; charset=utf-8',
            dataType:'json',
            data:JSON.stringify(postData)
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            message.info("修改成功！");
            dispatch({type:ActionType.UPDATE_PRODUCT_SUCCESS,payload:response.result});
            dispatch(getAllProduct());
        }).catch(function (error) {
            message.error("更新失败!");
            dispatch({type:ActionType.UPDATE_PRODUCT_FAILED,payload:error});
        })
    }
}

export function batchRestoreProduct(IdList){
    let url = Service.dbServer + Service.apiVersion + "/product/batchRestore" ;
    return dispatch=>{
        $.ajax(preHandle(url),{
            method:'POST',
            //contentType:'application/json; charset=utf-8',
            dataType:'json',
            data:({'product_list':JSON.stringify(IdList)})
        }).then(response=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            if(200 === response.code){
                message.info("还原成功!");
                dispatch(getAllProductDel());
            }
        }).catch(function(error){
            console.log(error)
        })
    }
}
export function deleteProduct(uuid) {
    let url = Service.dbServer + Service.apiVersion + "/product/del" ;
    return dispatch =>{
        dispatch({type:ActionType.DELETE_PRODUCT_START});
        $.ajax(preHandle(url),{
            method:'delete',
            //contentType:'application/json; charset=utf-8',
            dataType:'json',
            data:({uuid:uuid})
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            message.info("删除成功！");
            dispatch({type:ActionType.DELETE_PRODUCT_SUCCESS,payload:response.result});
            dispatch(getAllProductDel())
        }).catch(function (error) {
            message.error("删除失败！")
            dispatch({type:ActionType.DELETE_PRODUCT_FAILED,payload:error});
        })
    }
}
export function batchDelProduct(IdList){
    let url = Service.dbServer + Service.apiVersion + "/product/batchDel" ;
    return dispatch=>{
        $.ajax(preHandle(url),{
            method:'POST',
            dataType:'json',
            data:({'product_list':JSON.stringify(IdList)})
        }).then(response=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            if(200 === response.code){
                message.info("删除成功!");
                dispatch(getAllProductDel());
            }
        }).catch(function(error){
            console.log(error)
        })
    }
}