import React from 'react';
import {Row,Tabs} from 'antd';
import {getPurchase} from "../../actions/purchaseDBTool";
import PurchaseTable from "../controls/PurchaseTable";
import {USER_LEVEL_MEMBER} from "../../constants/constant";
import {connect} from "react-redux";
const { TabPane } = Tabs;
function mapStateToProps(state) {
    return {...state.purchase}
}
function mapDispatchToProps(dispatch) {
    return {onGetPurchase:()=>dispatch(getPurchase())}
}
class PurchaseList extends React.Component{
    constructor(props){
        super(props);
        this.state = {};
    }
    componentDidMount(){this.props.onGetPurchase();}
    renderPurchase(){
        let allDs = [];
        let myApplyDs = [];
        let myCreateDS = [];
        let myApprovalDS = [];
        let myAssignerDS = [];
        if(this.props.get_purchase_result){
            for (let i = 0;i < this.props.get_purchase_result.length;i++){
                let record = this.props.get_purchase_result[i];
                let detail = record.detail;
                allDs.push(record)
                if(detail.purchaser_account && -1 !== detail.purchaser_account.indexOf(localStorage.getItem('account'))){
                    myApplyDs.push(record);
                }
                if(detail.manager_account && -1 !== detail.manager_account.indexOf(localStorage.getItem('account'))){
                    myApprovalDS.push(record);
                }
                if(record.creator_account === localStorage.getItem('account')){
                    myCreateDS.push(record);
                }
                if(detail.assigner_account && -1 !== detail.assigner_account.indexOf(localStorage.getItem('account'))){
                    myAssignerDS.push(record)
                }
            }
        }
        const editable = USER_LEVEL_MEMBER < Number(localStorage.getItem('level'))?true:false
        return <Tabs defaultActiveKey={'1'} type={"card"}>
            {editable?<TabPane key={'1'} tab={"全部"}>
                <PurchaseTable dataSource={allDs} customColumnsEnable={true} tableKey={'money/purchase'} exportEnable={editable} editEnable={editable}/>
            </TabPane>:null}
             
            <TabPane key={'2'} tab={"我创建的"}>
                <PurchaseTable dataSource={myCreateDS} customColumnsEnable={true} tableKey={'money/purchase'} exportEnable={editable} editEnable={editable}/>
            </TabPane>
            <TabPane key={'3'} tab={"我申请的"}>
                <PurchaseTable dataSource={myApplyDs} customColumnsEnable={true} tableKey={'money/purchase'} exportEnable={editable} editEnable={editable}/>
            </TabPane>
            <TabPane key={'4'} tab={"我审批的"}>
                <PurchaseTable dataSource={myApprovalDS} customColumnsEnable={true} tableKey={'money/purchase'} exportEnable={editable} editEnable={editable}/>
            </TabPane>
            <TabPane key={'5'} tab={"抄送给我"}>
                <PurchaseTable dataSource={myAssignerDS} customColumnsEnable={true} tableKey={'money/purchase'} exportEnable={editable} editEnable={editable}/>
            </TabPane>
        </Tabs>
    }
    render(){
        return (<Row>{this.renderPurchase()}</Row>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(PurchaseList);