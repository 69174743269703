import React from 'react'
import * as Service from '../config/service.json';
import {Menu, Badge, Button,Modal,Avatar} from 'antd';
import {ExportOutlined,MessageOutlined,AppstoreOutlined,HomeOutlined,UserOutlined,BellOutlined} from '@ant-design/icons';
import {Link, withRouter} from 'react-router-dom';
import {getAllNotice, updateNotice} from "../actions/noticeDBTool";
import {logout} from '../actions/commonDBTool';
import {delHtmlTag} from "../utils/common"
import {connect} from "react-redux";
import HeaderSearcher from './controls/HeaderSearcher';

const {SubMenu} = Menu;
const {info} = Modal;

function mapStateToProps(state){
    return {...state.auth,...state.notice,...state.custom};
}
function mapDispatchToProps(dispatch) {
    return {onGetNotice:()=>dispatch(getAllNotice()),
            onEditNotice:(postData)=>dispatch(updateNotice(postData)),
            onLogout:()=>dispatch(logout())}
}
class FrameHeader extends React.Component{
    constructor(props){
        super(props);
        this.state={};
        this.handleLogout = this.handleLogout.bind(this);
    }
    componentDidMount(){
        if(localStorage.getItem("login_token")){
            this.props.onGetNotice();
        }
    }
    handleLogout(){
        this.props.onLogout();
    }
    showContentInfo = (record) =>{//show the read notice modal
        let item = record.item;
        let detail = (item.detail);
        info({
            title:item.title,
            content:delHtmlTag(detail.content_file_name),//TODO
            okType:'primary',
            cancelText:'取消',
            okText:'确定',
            onOk:()=>{
                let readList = detail.read_list;
                if(readList.length > 0){
                    for(let j = 0;j<readList.length;j++){
                        if(localStorage.getItem('account') === readList[j]){
                            return;
                        }
                    }
                }
                readList.push(localStorage.getItem('account'));
                let postData = {
                    enterprise_uuid:localStorage.getItem('enterprise_uuid'),
                    uuid:item.uuid,
                    title:item.title,
                    type:item.type,
                    status:item.status,
                    detail:JSON.stringify({"read_list":readList,"content_file_name":detail.content_file_name}),
                    creator_account:item.creator_account,
                    create_time:Date.parse(item.create_time)
                };
                this.props.onEditNotice(postData);
            },
            onCancel(){
                console.log('Cancel');
            }
        })
    }
    renderContactModal(){
        return(info({
            title:"联系客服",
            content:"咨询 QQ: 1241365697 微信: ad1133558",
            okType:'primary',
            cancelText:'取消',
            okText:'确定',
            onOk:()=>{
            },
            onCancel(){
                console.log('Cancel');
            }
        }))
    }
    render(){
        let unreadList = [];
        if(this.props.get_notice_result){
            for (let i = 0;i < this.props.get_notice_result.length;i++) {
                let record = this.props.get_notice_result[i];
                let readJson = (record.detail).read_list;
                let assignerList = record.detail.assigner_account?record.detail.assigner_account:[];
                if(0 < assignerList.length && -1 === assignerList.indexOf(localStorage.getItem('account'))){
                    continue;
                }
                if(readJson.length > 0){
                    for(let j = 0;j<readJson.length;j++){
                        if(localStorage.getItem('account') === readJson[j]){break;}
                        if(readJson.length -1 === j){unreadList.push(record);}
                    }
                }
                else{ unreadList.push(record); }
            }
        }
        let expiredStr = "";
        if(localStorage.getItem("login_token") && localStorage.getItem("expired_time")){
            if(localStorage.getItem("expired_time") >= new Date().getTime()){
                expiredStr = "还有"+Math.round((localStorage.getItem("expired_time") - new Date().getTime())/86400000)+"天过期!";
            }
            else{
                expiredStr = "已过期"+Math.round((new Date().getTime() - localStorage.getItem("expired_time"))/86400000)+"天!";
            }
        }
        const style={background:"#fefefe",fontSize:'15px',fontWeight: "bold",borderBottom:0,display:"inline-block",width:'100%'};
        const menuItemStyle = {background:'#fefefe',borderBottom:0,align:'center',paddingLeft:'30px'}
        const rightMenuItemStyle = {background:'#fefefe',borderBottom:0,float:'right'}
        const userMenuItemStyle = {background:'#fefefe',borderBottom:0,float:'right',paddingRight:'30px'}
        let menu = (<Menu>{unreadList.map(item=>
                        <Menu.Item key={item.uuid}>
                            <Button type="link" onClick={(event)=>{this.showContentInfo({item})}}>{item.title}</Button>
                        </Menu.Item>)}
                    </Menu>);
        const imageUrl = localStorage.getItem('staff_detail')?Service.resServer +localStorage.getItem("enterprise_uuid") + "/"+localStorage.getItem("account")+"/profile/"+ JSON.parse(localStorage.getItem('staff_detail')).profile_thumb:<UserOutlined/>
        if(localStorage.getItem("login_token")){
            return (<Menu mode={'horizontal'} defaultSelectedKeys={['2']} style={style}>
                        <Menu.Item style={menuItemStyle}>多易客户管理系统</Menu.Item>
                        <Menu.Item style={menuItemStyle} onClick={()=>{window.open('http://www.space01.cn/crm/')}}><HomeOutlined style={{fontSize:'18px'}}/>主页</Menu.Item>
                        <Menu.Item style={menuItemStyle}><Link to="/allItems/"/><AppstoreOutlined style={{fontSize:'18px'}}/><span>全部功能</span></Menu.Item>
                        <SubMenu style={userMenuItemStyle} title={<div>{localStorage.getItem('name')}&nbsp;&nbsp;
                            <Avatar shape="square" src={imageUrl}/>
                            </div>}>
                            <Menu.Item onClick={()=>{this.props.history.push('/allItems/system/payment')}}>
                                升级/续费&nbsp;<span style={{color:'red'}}>[{expiredStr}]</span>
                            </Menu.Item>
                            <Menu.Item onClick={()=>{this.handleLogout()}}><ExportOutlined/>退出</Menu.Item>
                        </SubMenu>
                        <SubMenu overlay={menu} style={rightMenuItemStyle} trigger={['click']} title={<div>
                            <span className="ant-dropdown-link">消息&nbsp;</span>
                            <Badge dot={unreadList.length?true:false}><BellOutlined/></Badge>
                        </div>}>
                            {menu}
                        </SubMenu>
                        <Menu.Item style={rightMenuItemStyle}><HeaderSearcher/></Menu.Item>
                        <Menu.Item style={rightMenuItemStyle} onClick={()=>{this.renderContactModal()}}><MessageOutlined style={{fontSize:'18px'}}/><span>联系客服</span></Menu.Item>
                    </Menu>)
        }
        return (<Menu mode={'horizontal'} defaultSelectedKeys={['2']} style={style}>
                    <Menu.Item style={menuItemStyle}>多易客户管理系统</Menu.Item>
                    <Menu.Item style={menuItemStyle} onClick={()=>{window.open('http://www.space01.cn/crm/')}}><HomeOutlined style={{fontSize:'18px'}}/>主页</Menu.Item>
                    <Menu.Item style={menuItemStyle} onClick={()=>{this.renderContactModal()}}><MessageOutlined style={{fontSize:'18px'}}/><span>联系客服</span></Menu.Item>
                    <Menu.Item style={rightMenuItemStyle}><Link to={"/register"}>
                        <Button type='primary' shape="round"><span style={{fontWeight: "bold"}}>免费注册</span></Button></Link>
                    </Menu.Item>
                    <Menu.Item style={userMenuItemStyle}><Link to={"/login"}/>登录</Menu.Item>
                </Menu>)
    };
}
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(FrameHeader));