import {ActionType} from "../constants/actionTypes";
const customReducer = (state={},action)=>{
    switch (action.type) {
        case ActionType.GET_CUSTOM_BY_USER_START:
            return {...state, get_custom:false,get_custom_result:[]};
        case ActionType.GET_CUSTOM_BY_USER_SUCCESS:
            console.log(action.payload)
            return {...state, get_custom:true, get_custom_result: action.payload.custom,count:action.payload.count};
        case ActionType.GET_CUSTOM_BY_USER_FAILED:
            return {...state, get_custom:false, get_custom_errors: action.payload?action.payload:null};

        case ActionType.GET_COMMON_CUSTOM_SUCCESS:
            return {...state,get_common_custom:true,get_common_custom_result:action.payload};
        case ActionType.GET_COMMON_CUSTOM_START:
            return {...state,get_common_custom:false,get_common_custom_result:[]};
        case ActionType.GET_COMMON_CUSTOM_FAILED:
            return {...state,get_common_custom:false,get_common_custom_error:action.payload?action.payload:null}

        case ActionType.ADD_CUSTOM_START:
            return {...state,add_custom:false}
        case ActionType.ADD_CUSTOM_FAILED:
            return {...state,add_custom:false,add_custom_errors:action.payload?action.payload:null}
        case ActionType.ADD_CUSTOM_SUCCESS:
            return {...state, add_custom:true, add_custom_result:action.payload}


        case ActionType.GET_CUSTOM_DEL_START:
            return {...state,get_custom_del:false}
        case ActionType.GET_CUSTOM_DEL_FAILED:
            return {...state,get_custom_del:false,custom_del_errors:action.payload?action.payload:null}
        case ActionType.GET_CUSTOM_DEL_SUCCESS:
            return {...state, get_custom_del:true,custom_del_result:action.payload}

        case ActionType.UPDATE_CUSTOM_START:
            return {...state,update_custom:false}
        case ActionType.UPDATE_CUSTOM_FAILED:
            return {...state,update_custom:false,custom_update_errors:action.payload?action.payload:null}
        case ActionType.UPDATE_CUSTOM_SUCCESS:
            return {...state,update_custom:true, custom_update_result:action.payload}


        case ActionType.DELETE_CUSTOM_START:
            return {...state, delete_custom:false}
        case ActionType.DELETE_CUSTOM_FAILED:
            return {...state, delete_custom:false,delete_custom_error:action.payload?action.payload:null}
        case ActionType.DELETE_CUSTOM_SUCCESS:
            return {...state, delete_custom:true, delete_custom_result:action.payload}

        case ActionType.GET_CUSTOM_FILTER_RESULT:
            return {...state,get_custom_filter_result:action.payload}

        case ActionType.ADD_FOLLOW_UP_START:
            return {...state,add_followup:false};
        case ActionType.ADD_FOLLOW_UP_SUCCESS:
            return {...state,add_followup:true,add_followup_result:action.payload};
        case ActionType.ADD_FOLLOW_UP_FAILED:
            return {...state,add_followup:false,add_followup_errors:action.payload?action.payload:null}


        case ActionType.GET_FOLLOW_UP_START:
            return {...state,get_followup:false}
        case ActionType.GET_FOLLOW_UP_SUCCESS:
            return {...state,get_followup:true,get_followup_result:action.payload}
        case ActionType.GET_FOLLOW_UP_FAILED:
            return {...state,get_followup:false,get_followup_errors:action.payload?action.payload:null}


        case ActionType.UPDATE_FOLLOW_UP_START:
            return {...state,update_followup:false}
        case ActionType.UPDATE_FOLLOW_UP_SUCCESS:
            return {...state,update_followup:true,update_followup_result:action.payload};
        case ActionType.UPDATE_FOLLOW_UP_FAILED:
            return {...state,update_followup:false,update_followup_errors:action.payload?action.payload:null}

        case ActionType.GET_FOLLOW_UP_BY_CUSTOM_START:
            return {...state,get_followup_by_custom:false}
        case ActionType.GET_FOLLOW_UP_BY_CUSTOM_SUCCESS:
            return {...state,get_followup_by_custom:true,get_followup_by_custom_result:action.payload}
        case ActionType.GET_FOLLOW_UP_BY_CUSTOM_FAILED:
            return {...state,get_followup_by_custom:false,get_followup_by_custom_errors:action.payload?action.payload:null}

        case ActionType.DELETE_FOLLOW_UP_START:
            return {...state,}
        case ActionType.INCREASE_TEST:
            let count = state.count;
            count = count + 1;
            return {count:count};
        default:
            return state;
    }
}
export default customReducer;