import React from 'react';
import {Layout, Row} from 'antd';
import FrameHeader from "./header";
const {Content} = Layout;
export default class PricePage extends React.Component{
    constructor(props) {
        super(props);
        this.state = {};
    }
    render(){
        return (<Content style={{height:'100vh',background:'RGB(220,220,220)'}}>
            <FrameHeader/>
            <Row>
                price page
            </Row>
        </Content>);
    }
}