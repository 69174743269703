import $ from 'jquery'
//import axios from "axios";
import {message} from "antd";
import * as Service from '../config/service.json';
import {ActionType} from "../constants/actionTypes";
import {preHandle,checkAccountStatus} from './commonDBTool';
export function addWorkOrder(postData) {
    let url = Service.dbServer + Service.apiVersion + "/workOrder/add";
    return dispatch =>{
        dispatch({type:ActionType.ADD_WORK_ORDER_START});
        $.ajax(preHandle(url),{
            method: 'POST',
            contentType:'application/json; charset=utf-8',
            dataType: 'json',
            data:JSON.stringify(postData)
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            message.info("添加成功!");
            dispatch({type:ActionType.ADD_WORK_ORDER_SUCCESS,payload:response.result});
            dispatch(getWorkOrder());
            dispatch(getWorkOrderByCustom(JSON.parse(postData.detail).custom_uuid));
        }).catch(function (error) {
            console.log(error);
            message.error("添加失败!");
            dispatch({type:ActionType.ADD_WORK_ORDER_FAILED,payload:error});
        })
    }
}
export function getWorkOrder(pageStart=0,pageSize=50) {
    let url = Service.dbServer + Service.apiVersion + "/workOrder/getAll";
    return dispatch =>{
        dispatch({type:ActionType.GET_WORK_ORDER_START});
        $.ajax(preHandle(url),{
            method:'GET',
            contentType:'application/json; charset=utf-8',
            dataType:'json',
            data:{pageStart:pageStart,pageSize:pageSize}
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            let result = response.result
            if(result){
                for(let i = 0;i < result.length;i++){
                    result[i].detail = JSON.parse(result[i].detail);
                }
            }
            dispatch({type:ActionType.GET_WORK_ORDER_SUCCESS,payload:result});
        }).catch(function (error) {
            dispatch({type:ActionType.GET_WORK_ORDER_FAILED,payload:error})
            console.log(error)
        })
    }
}
export function getWorkOrderByCustom(custom_uuid,pageStart=0,pageSize=50) {
    let url = Service.dbServer + Service.apiVersion + "/workOrder/getByCustom";
    return dispatch =>{
        dispatch({type:ActionType.GET_WORK_ORDER_BY_CUSTOM_START});
        $.ajax(preHandle(url),{
            method:'GET',
            dataType:'json',
            data:({custom_uuid:custom_uuid,pageStart:pageStart,pageSize:pageSize})
        }).then(response=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            let result = response.result
            if(result){
                for(let i = 0;i < result.length;i++){
                    result[i].detail = JSON.parse(result[i].detail);
                }
            }
            dispatch({type:ActionType.GET_WORK_ORDER_BY_CUSTOM_SUCCESS,payload:result});
        }).catch(function (error) {
            dispatch({type:ActionType.GET_WORK_ORDER_BY_CUSTOM_FAILED,payload:error});
        })
    }
}
export function updateWorkOrder(postData) {
    let url = Service.dbServer + Service.apiVersion + "/workOrder/update";
    return dispatch =>{
        dispatch({type:ActionType.UPDATE_WORK_ORDER_START});
        $.ajax(preHandle(url),{
            method:'POST',
            contentType:'application/json; charset=utf-8',
            dataType:'json',
            data:JSON.stringify(postData)
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            message.info("更新成功！");
            dispatch({type:ActionType.UPDATE_WORK_ORDER_SUCCESS,payload:response.result});
            dispatch(getWorkOrder());
            dispatch(getWorkOrderByCustom(JSON.parse(postData.detail).custom_uuid));
        }).catch(function (error) {
            message.error("更新失败！");
            dispatch({type:ActionType.UPDATE_WORK_ORDER_FAILED,payload:error});
        })
    }
}
export function deleteWorkOrder(data) {
    console.log(data)
    let url = Service.dbServer + Service.apiVersion + "/workOrder/del";
    return dispatch =>{
        dispatch({type:ActionType.DELETE_WORK_ORDER_START});
        $.ajax(preHandle(url),{
            method:'delete',
            //contentType:'application/json; charset=utf-8',
            dataType:'json',
            data:({uuid:data.uuid})
        }).then(response=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            message.info("删除成功！");
            dispatch({type:ActionType.DELETE_WORK_ORDER_SUCCESS,payload:response.result});
            dispatch(getWorkOrder());
            dispatch(getWorkOrderByCustom(data.detail.custom_uuid));
        }).catch(function (error) {
            message.error("删除失败！");
            dispatch({type:ActionType.DELETE_WORK_ORDER_FAILED,payload:error});
        })
    }
}
export function batchDeleteWorkOrder(uuid_list){
    let url = Service.dbServer + Service.apiVersion + "/workOrder/batchDel";
    return dispatch =>{
        dispatch({type:ActionType.DELETE_WORK_ORDER_START});
        $.ajax(preHandle(url),{
            method:'post',
            //contentType:'application/json; charset=utf-8',
            dataType:'json',
            data:{workorder_list:JSON.stringify(uuid_list)}
        }).then(response=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            if(200 === response.code){
                message.info("删除成功！");
                dispatch(getWorkOrder());
            }
        }).catch(function (error) {
            message.error("删除失败！");
        })
    }
}