import React from 'react';

class Ranking extends React.Component {
    constructor(props){
        super(props);
        this.state = {};
    }
    render(){
        return (<div>the user Ranking page</div>);
    }
}
export default Ranking;