import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import zhCN from 'antd/es/locale/zh_CN';
import { ConfigProvider } from 'antd';
import './less/main.less'
import 'antd/dist/antd.css';
import './css/index.css';
import {PageRouter} from './router/router';
import * as serviceWorker from './serviceWorker';
import {store} from './stores/store';

ReactDOM.render(<ConfigProvider locale={zhCN}>
                    <Provider store={store}>
                        <PageRouter/>
                    </Provider>
                </ConfigProvider> , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
