import React from 'react';
import * as Service from '../../config/service.json';
import {Row,Button, Col,Form,Input, Divider, Modal,Progress,message, Upload,Card} from 'antd';
import { connect } from 'react-redux';
import {deleteObject, uploadFile} from '../../utils/minioutil'
import {getEnterprise, updateEnterpriseProfile,updateEnterpriseDetail} from "../../actions/enterpriseDBTool";
import {enterpriseLevelList} from "../../constants/constant";
import moment from 'moment';
import { sizeOfStoreage } from '../../utils/minioutil';

const StoragePath = Service.resServer +localStorage.getItem("enterprise_uuid") + "/logo/"
const CompanyInfoForm = Form.create({name:'company_name_modal'})(
    class extends React.Component{
        constructor(props) {
            super(props);
            this.state={};
        }
        render(){
            let {visible,onCancel,onOk,form,values} = this.props;
            let { getFieldDecorator } = form;
            let formItemLayout = {labelCol: {span:6}, wrapperCol: {span:16}};
            return (<Modal title={"修改公司信息"} visible={visible} onOk={onOk} okText={"确定"} onCancel={onCancel} cancelText={"取消"}>
                <Form {...formItemLayout}>
                    <Form.Item label={"公司名称:"}>
                        {getFieldDecorator('name', {rules: [{ required: true,message:'名称不能为空!' }],
                            initialValue:values.name})
                        (<Input placeholder={"请输入新的公司名称!"}/>)}
                    </Form.Item>
                    <Form.Item label={"创始人账号:"}>
                        {getFieldDecorator('founder', {rules: [{ required: true,message:'创始人账号不能为空!' }],
                            initialValue:values.founder})
                        (<Input placeholder={"请输入新的创始人的账号"}/>)}
                    </Form.Item>
                </Form>
            </Modal>);
        }
    }
);

function mapStateToProps(state){
    return {...state.enterprise,...state.auth};
}
function mapDispatchToProps(dispatch){
    return {onGetEnterprise:()=>dispatch(getEnterprise()),
            onUpdateEnterpriseDetail:(detail)=>dispatch(updateEnterpriseDetail(detail)),
            onUpdateEnterpriseProfile:(name,founder)=>dispatch(updateEnterpriseProfile(name,founder))};
}
class CompanyInfo extends React.Component{
    constructor(props){
        super(props);
        this.state={companyInfoVisible:false,values:{name:'',founder:''},
                    imageUrl: StoragePath + JSON.parse(localStorage.getItem('enterprise_detail')).company_logo
                };
    }
    componentDidMount(){
        this.props.onGetEnterprise();
        sizeOfStoreage((size)=>{
            this.setState({size:size})
        })
    }
    saveCompanyFormRef= formRef =>{
        this.formRef = formRef;
    }
    handleShowCompany=()=>{
        let enterpriseDetail = JSON.parse(localStorage.getItem("enterprise_detail"));
        const {form} = this.formRef.props;
        this.setState({companyInfoVisible:true,values:{name:localStorage.getItem('enterprise_name'),founder:enterpriseDetail.founder_account}});
        form.resetFields();
    }
    handleCompanyCancel=()=>{
        const {form} = this.formRef.props;
        this.setState({companyInfoVisible:false});
        form.resetFields();
    }
    handleCompanySubmit=()=>{
        const {form} = this.formRef.props;
        form.validateFields((err,formValues)=>{
            if (err) {return;}
            if(this.props.get_enterprise){
                this.props.onUpdateEnterpriseProfile(formValues.name,formValues.founder);
            }
            this.setState({companyInfoVisible:false});
            form.resetFields();
        })
    }
    beforeUpload(file){
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('仅支持JPG/PNG 文件!');
            return false
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('仅支持小于5M的图片');
            return false
        }
        try {
            if(JSON.parse(localStorage.getItem('enterprise_detail')).company_logo){//remove the old thumb file
                let oldfile = JSON.parse(localStorage.getItem('enterprise_detail')).company_logo;
                deleteObject(oldfile,(error)=>{
                    console.log(error)
                })
            }
            uploadFile("logo/",file,()=>{
                let detail = JSON.parse(localStorage.getItem('enterprise_detail'));
                detail.company_logo = file.name
                this.setState({imageUrl:StoragePath + file.name})
                this.props.onUpdateEnterpriseDetail(detail)
                //this.props.onUpdateDetail(detail)
            })
        }catch (e) {
            console.log(e)
            return false;
        }
        return true
    }
    renderCompanyInfo(){
        let enterpriseLevel = localStorage.getItem('enterprise_level');
        enterpriseLevelList.forEach(item=>{
            if(item.value === enterpriseLevel){
                enterpriseLevel = item.text;
            }
        });
        let enterpriseDetail = JSON.parse(localStorage.getItem("enterprise_detail"));
        const usedSize = this.state.size/(1024*1024*1024)
        console.log(usedSize)
        const percent = (usedSize*100/localStorage.getItem('max_storage')).toFixed(1);
        console.log(percent)
        const uploadButton = (<Button type="link" className="ant-upload-text">编辑</Button>);
        const {imageUrl} = this.state;
        return(<Row>
                    <Col span={12} offset={2}>
                    <Card title={"公司信息"}>
                        <CompanyInfoForm wrappedComponentRef={this.saveCompanyFormRef} visible={this.state.companyInfoVisible}
                            values={this.state.values} onCancel={this.handleCompanyCancel} onOk={this.handleCompanySubmit} />
                        <Row>
                            <Col span={6} style={{textAlign:'right'}}>公司名称：</Col>
                            <Col span={6} style={{textAlign:'center'}}>
                                {localStorage.getItem('enterprise_name')?localStorage.getItem('enterprise_name'):''}
                            </Col>
                            <Col span={6} style={{textAlign:'left'}}><Button type="link" onClick={this.handleShowCompany}>编辑</Button></Col>
                        </Row>
                        <Divider/>
                        <Row>
                            <Col span={6} style={{textAlign:'right'}}>公司logo：</Col>
                            <Col span={6} style={{textAlign:'center'}}>
                                <img src={imageUrl} alt="" style={{ width: '100px',height:'100px' }} />
                            </Col>
                            <Col span={6}>
                            <Upload accept="image/jpeg,image/png" listType="picture" beforeUpload={(file)=>{
                                    this.beforeUpload(file);
                                    return false /*阻止组件自带上传事件*/
                                    }} onChange={this.handleChange}>
                                    {uploadButton}
                                </Upload>
                            </Col>
                        </Row>
                        <Divider/>
                        <Row>
                            <Col span={6} style={{textAlign:"right"}}>创始人账号：</Col>
                            <Col span={6} style={{textAlign:"center"}}>{enterpriseDetail.founder_account?enterpriseDetail.founder_account:''}</Col>
                            <Col span={6} style={{textAlign:"left"}}><Button type="link" onClick={this.handleShowCompany}>变更</Button></Col>
                        </Row>
                        <Divider/>
                        <Row>
                            <Col span={6} style={{textAlign:"right"}}>账号到期时间：</Col>
                            <Col span={6} style={{textAlign:"center"}}>{localStorage.getItem('expired_time')? moment(new Date(Number(localStorage.getItem('expired_time')))).format('YYYY-MM-DD'):''}</Col>
                            <Col span={6} style={{textAlign:"left"}}>
                                <Button type="link" onClick={()=>{this.props.history.push('/allItems/system/payment')}}>续期</Button>
                            </Col>
                        </Row>
                        <Divider/>
                        <Row>
                            <Col span={6} style={{textAlign:"right"}}>账号等级：</Col>
                            <Col span={6} style={{textAlign:"center"}}>{localStorage.getItem('enterprise_level')?enterpriseLevel:0}</Col>
                            <Col span={6} style={{textAlign:"left"}}>
                                <Button type="link" onClick={()=>{this.props.history.push('/allItems/system/payment')}}>升级</Button>
                            </Col>
                        </Row>
                        <Divider/>
                        <Row>
                            <Col span={6} style={{textAlign:"right"}}>存储容量：</Col>
                            <Col span={6} style={{textAlign:"center"}}><Progress percent={percent} format={percent => `${usedSize.toFixed(1)}G/${localStorage.getItem('max_storage')}G`}></Progress></Col>
                        </Row>
                        </Card>
                    </Col>
            </Row>);
    }
    render(){
        return(this.renderCompanyInfo());
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(CompanyInfo);