import React from 'react';
import {Row,Tabs} from 'antd';
import CustomTable from '../../controls/CustomTable';
import {getAllCustom} from "../../../actions/customDBTool";
import {connect} from "react-redux";
import {CUSTOM_TYPE_ENTERPRISE, USER_LEVEL_MEMBER} from "../../../constants/constant";
const { TabPane } = Tabs;
function mapStateToProps(state){
    return {...state.custom};
}
function mapDispatchToProps(dispatch){
    return {onGetCustom:()=>dispatch(getAllCustom())};
}
class VIPCustom extends React.Component {
    constructor(props){
        super(props);
        this.state = { };
    }
    componentDidMount() {
        this.props.onGetCustom();
    }
    renderEnterCustom(){
        let enterpriseCustomDS= [];
        let allEnterpriseDS = [];
        if(this.props.get_custom_result){
            let result = this.props.get_custom_result;
            for (let i = 0;i < result.length;i++){
                if(CUSTOM_TYPE_ENTERPRISE === result[i].type){
                    allEnterpriseDS.push(result[i]);
                    if(-1 !== result[i].detail.manager_account.indexOf(localStorage.getItem('account'))){
                        enterpriseCustomDS.push(result[i]);
                    }
                }
            }
        }
        const customEditable = USER_LEVEL_MEMBER < Number(localStorage.getItem('level'))?true:false
        const isManager = USER_LEVEL_MEMBER < Number(localStorage.getItem('level'))?true:false
        return(<Tabs defaultActiveKey={"2"}>
                {isManager?(
                <TabPane key="1" tab="全部企业客户">
                    <CustomTable dataSource={allEnterpriseDS} exportEnable={customEditable} editEnable={customEditable} batchDelEnable={customEditable} batchEditEnable={customEditable} tableKey={'crm/vip_custom'}/>
                </TabPane>):null}
                <TabPane key="2" tab="我的企业客户">
                    <CustomTable dataSource={enterpriseCustomDS} exportEnable={customEditable} editEnable={customEditable} batchDelEnable={customEditable} batchEditEnable={customEditable} tableKey={'crm/vip_custom'}/>
                </TabPane>
            </Tabs>);
    }
    render(){
        return (<Row>{this.renderEnterCustom()}</Row>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(VIPCustom);