import React from 'react';
import {Row,Tabs} from 'antd';
import CustomTable from '../../controls/CustomTable';
import {getAllCustom} from "../../../actions/customDBTool";
import {getAllFollowUp} from "../../../actions/followupDBTool"
import {connect} from "react-redux";
import {USER_LEVEL_MEMBER} from "../../../constants/constant";
import {out30Days,out60Days,out90Days,out180Days} from "../../../utils/common";
const { TabPane } = Tabs;

function mapStateToProps(state){
    return {...state.custom};
}
function mapDispatchToProps(dispatch){
    return {onGetCustom:()=>dispatch(getAllCustom()),
        onGetFollow:()=>dispatch(getAllFollowUp())};
}
class FollowingCustom extends React.Component{
    constructor(props){
        super(props);
        this.state = { };
    }
    componentDidMount() {
        this.props.onGetCustom();
        this.props.onGetFollow();
    }

    renderFollowingCustom(){
        let myFllowCustomDS = [];
        let allFollowCustomDS = [];
        let out30DayFollowCustomDS = [];
        let out60DayFollowCustomDS = [];
        let out90DayFollowCustomDS = [];
        let out180DayFollowCustomDS = [];
        let result = this.props.get_custom_result?this.props.get_custom_result:[];
        let followList = this.props.get_followup_result?this.props.get_followup_result:[]
        for (let i = 0;i < result.length;i++){
            for(let j = 0;j < followList.length;j++){
                if(followList[j].custom_uuid === result[i].uuid){
                    allFollowCustomDS.push(result[i]);
                    if(result[i].detail.manager_account && -1 !== result[i].detail.manager_account.indexOf(localStorage.getItem('account'))){
                        myFllowCustomDS.push(result[i]);
                    }
                    if(out30Days(followList[j].update_time) && !out60Days(followList[j].update_time)){
                        out30DayFollowCustomDS.push(result[i]);
                    }
                    else if(out60Days(followList[j].update_time) && !out90Days(followList[j].update_time)){
                        out60DayFollowCustomDS.push(result[i]);
                    }
                    else if(out90Days(followList[j].update_time) && !out180Days(followList[j].update_time)){
                        out90DayFollowCustomDS.push(result[i]);
                    }
                    else if(out180Days(followList[j].update_time)){
                        out180DayFollowCustomDS.push(result[i]);
                    }
                    break;
                }
            }
        }
        const customEditable = USER_LEVEL_MEMBER < Number(localStorage.getItem('level'))?true:false
        const isManager = USER_LEVEL_MEMBER < Number(localStorage.getItem('level'))?true:false
        return(<Tabs defaultActiveKey={"2"}>
            {isManager?(<TabPane key="1" tab="全部跟进">
                <CustomTable dataSource={allFollowCustomDS} tableKey={'crm/follow_custom'} exportEnable={customEditable} editEnable={customEditable} batchDelEnable={customEditable} batchEditEnable={customEditable}/>
            </TabPane>):null}
            <TabPane key="2" tab="我的跟进">
                <CustomTable dataSource={myFllowCustomDS} tableKey={'crm/follow_custom'} exportEnable={customEditable} editEnable={customEditable} batchDelEnable={customEditable} batchEditEnable={customEditable}/>
            </TabPane>
            <TabPane key="3" tab="30天以上">
                <CustomTable dataSource={out30DayFollowCustomDS} tableKey={'crm/follow_custom'} exportEnable={customEditable} editEnable={customEditable} batchDelEnable={customEditable} batchEditEnable={customEditable}/>
            </TabPane>
            <TabPane key="4" tab="60天以上">
                <CustomTable dataSource={out60DayFollowCustomDS} tableKey={'crm/follow_custom'} exportEnable={customEditable} editEnable={customEditable} batchDelEnable={customEditable} batchEditEnable={customEditable}/>
            </TabPane>
            <TabPane key="5" tab="90天以上">
                <CustomTable dataSource={out90DayFollowCustomDS} tableKey={'crm/follow_custom'} exportEnable={customEditable} editEnable={customEditable} batchDelEnable={customEditable} batchEditEnable={customEditable}/>
            </TabPane>
            <TabPane key="6" tab="180天以上">
                <CustomTable dataSource={out180DayFollowCustomDS} tableKey={'crm/follow_custom'} exportEnable={customEditable} editEnable={customEditable} batchDelEnable={customEditable} batchEditEnable={customEditable}/>
            </TabPane>
        </Tabs>);
    }
    render(){
        return (<Row>{this.renderFollowingCustom()}</Row>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(FollowingCustom);