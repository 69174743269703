import React from "react";
import {Select,Row, Col} from 'antd';
import ExtendSelect from "./ExtendSelect";
import {industryList} from "../../constants/industrylist";
const { Option } = Select;
class IndustryChoose extends React.Component{
    constructor(props) {
        super(props);
        this.state = {bigIndustry:'',smallIndustryList:''};
    }
    componentDidMount(){
        if (undefined !== this.props.value){
            industryList.forEach((item,index)=>{
                let smallIndustryList = item.children;
                for(let i = 0;i < smallIndustryList.length;i++){
                    if(smallIndustryList[i].name === this.props.value){
                        this.setState({bigIndustry:item.name});
                    }
                }
            });
        }
    }
    onChooseBigIndustry(value){
        let smallIndustry = [];
        if (undefined !== value){
            industryList.forEach((item,index)=>{
                if(item.name === value){
                    item.children.forEach(item=>{
                        smallIndustry.push(item.name);
                    })
                }
            });
        }
        this.setState({bigIndustry:value,smallIndustryList:smallIndustry});
    }
    triggerChange = (changedValue) => {
        // Should provide an event to pass value to Form.
        console.log(changedValue)
        const onChange = this.props.onChange;
        if (onChange) {
            onChange(changedValue);
        }
    }
    render(){
        return (<Row>
                <Col span={9}>
                <Select style={{width:'100%'}} allowClear={true} disabled={this.props.disabled}
                    onChange={(value)=>{this.onChooseBigIndustry(value)}} value={this.state.bigIndustry}>
                    {industryList.map(item=><Option value={item.name} key={item.name}>{item.name}</Option>)}
                </Select>
                </Col>
                <Col span={15}>
                <ExtendSelect style={{minWidth:'150px',width:'100%'}} items={this.state.smallIndustryList} 
                    allowClear={true} title={'行业'} value={this.props.value} disabled={this.props.disabled}
                        onChange={(value)=>{this.triggerChange(value)}}/>
            </Col>
            </Row>);
    }
}
export default IndustryChoose;