import React from 'react';
import {Row,Tabs} from 'antd';
import OrderTable from '../../controls/OrderTable';
import {ORDER_STATUS_SUCCESS,USER_LEVEL_MEMBER} from "../../../constants/constant";
import {connect} from "react-redux";
import {isToday} from "../../../utils/common";
const { TabPane } = Tabs;

function mapStateToProps(status) {
    return {...status.order};
}
function mapDispatchToProps(dispatch){
    return {}
}
class SuccessOrder extends React.Component {
    constructor(props){
        super(props);
        this.state = {isAllowed:true};
    }
    componentDidMount() {}
    renderSuccessOrder(){
        let myOrderDS = [];
        let allOrderDS = [];
        let todayOrderDS = [];
        if (this.props.get_order_result){
            let result = this.props.get_order_result;
            result.sort(function (a,b) { return Date.parse(b.create_time) - Date.parse(a.create_time);})
            for(let i = 0;i < result.length;i++){
                if(ORDER_STATUS_SUCCESS !== result[i].status){continue;}
                allOrderDS.push(result[i]);
                let detail = result[i].detail;
                if(detail.manager_account && -1 !== detail.manager_account.indexOf(localStorage.getItem('account'))){
                    myOrderDS.push(result[i]);
                }
                if(isToday(detail.end_time)){
                    todayOrderDS.push(result[i])
                }
            }
        }
        const orderEditable = USER_LEVEL_MEMBER < Number(localStorage.getItem('level'))?true:false
        const isManager = USER_LEVEL_MEMBER < Number(localStorage.getItem('level'))?true:false
        return(<Tabs defaultActiveKey={"2"} type='card'>
            {isManager?(
            <TabPane key="1" tab="所有已完成订单">
                <OrderTable dataSource={allOrderDS} exportEnable={orderEditable} customColumnsEnable={true} editEnable={orderEditable} batchDelEnable={orderEditable} tableKey={'crm/success_order'}/>
            </TabPane>):null}
            <TabPane key="2" tab="我的已完成订单">
                <OrderTable dataSource={myOrderDS} exportEnable={orderEditable} customColumnsEnable={true} editEnable={orderEditable} batchDelEnable={orderEditable} tableKey={'crm/success_order'}/>
            </TabPane>
            <TabPane key={"3"} tab={"今日已完成订单"}>
                <OrderTable dataSource={todayOrderDS} exportEnable={orderEditable} customColumnsEnable={true} editEnable={orderEditable} batchDelEnable={orderEditable} tableKey={'crm/success_order'}/>
            </TabPane>
        </Tabs>);
    }
    render(){
        return (<Row>{this.renderSuccessOrder()}</Row>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(SuccessOrder);