import {ActionType} from "../constants/actionTypes";

const purchaseReducer = (state = {},action)=>{
    switch (action.type) {
        case ActionType.ADD_PURCHASE_LIST_START:
            return {...state,add_purchase:false}
        case ActionType.ADD_PURCHASE_LIST_SUCCESS:
            return {...state,add_purchase:true,add_purchase_result:action.payload}
        case ActionType.ADD_PURCHASE_LIST_FAILED:
            return {...state,add_purchase:false,add_purchase_errors:action.error?action.payload.errors:null}

        case ActionType.GET_PURCHASE_LIST_START:
            return {...state,get_purchase:false}
        case ActionType.GET_PURCHASE_LIST_SUCCESS:
            return {...state,get_purchase:true,get_purchase_result:action.payload}
        case ActionType.GET_PURCHASE_LIST_FAILED:
            return {...state,get_purchase:false,get_purchase_errors:action.error?action.payload.errors:null}

        case ActionType.UPDATE_PURCHASE_LIST_START:
            return {...state,update_purchase:false}
        case ActionType.UPDATE_PURCHASE_LIST_SUCCESS:
            return {...state,update_purchase:true,update_purchase_result:action.payload}
        case ActionType.UPDATE_PURCHASE_LIST_FAILED:
            return {...state,update_purchase:false,update_purchase_errors:action.error?action.payload.errors:null}

        case ActionType.DELETE_PURCHASE_LIST_START:
            return {...state,delete_purchase:false}
        case ActionType.DELETE_PURCHASE_LIST_SUCCESS:
            return {...state,delete_purchase:true,delete_purchase_result:action.payload}
        case ActionType.DELETE_PURCHASE_LIST_FAILED:
            return {...state,delete_purchase:false,delete_purchase_errors:action.error?action.payload.errors:null}

        default:
            return {...state};
    }
}
export default purchaseReducer;
