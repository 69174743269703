import $ from 'jquery'
//import axios from "axios";
import {message} from "antd";
import * as Service from '../config/service.json';
import {ActionType} from "../constants/actionTypes";
import {preHandle,checkAccountStatus} from './commonDBTool';
export function addShip(postData) {
    let url = Service.dbServer + Service.apiVersion + "/ship/add";
    return dispatch=>{
        dispatch({type:ActionType.ADD_SHIP_LIST_START});
        $.ajax(preHandle(url),{
            method:'POST',
            contentType:'application/json; charset=utf-8',
            data:JSON.stringify(postData)
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            message.info("添加成功!");
            dispatch({type:ActionType.ADD_SHIP_LIST_SUCCESS,payload:response.result});
            dispatch(getShip());
        }).catch(function (error) {
            message.error("添加失败!");
            dispatch({type:ActionType.ADD_SHIP_LIST_FAILED,payload:error});
        })
    }
}
export function getShip(pageStart=0,pageSize=50) {
    let url = Service.dbServer + Service.apiVersion + "/ship/getAll";
    return dispatch=>{
        dispatch({type:ActionType.GET_SHIP_LIST_START});
        $.ajax(preHandle(url),{
            method:'GET',
            contentType:'application/json; charset=utf-8',
            dataType:'json',
            data:{pageStart:pageStart,pageSize:pageSize}
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            let result = response.result
            if(result){
                for(let i = 0;i < result.length;i++){
                    result[i].detail = JSON.parse(result[i].detail);
                }
            }
            dispatch({type:ActionType.GET_SHIP_LIST_SUCCESS,payload:result});
        }).catch(function (error) {
            dispatch({type:ActionType.GET_SHIP_LIST_FAILED,payload:error});
        })
    }
}
export function updateShip(postData) {
    let url = Service.dbServer + Service.apiVersion + "/ship/update";
    return dispatch=>{
        dispatch({type:ActionType.UPDATE_SHIP_LIST_START})
        $.ajax(preHandle(url),{
            method:'POST',
            contentType:'application/json; charset=utf-8',
            data:JSON.stringify(postData)
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            message.info("更新成功!");
            dispatch({type:ActionType.UPDATE_SHIP_LIST_SUCCESS,payload:response.result});
            dispatch(getShip());
        }).catch(function (error) {
            message.error("更新失败!")
            dispatch({type:ActionType.UPDATE_SHIP_LIST_FAILED,payload:error});
        })
    };
}
export function deleteShip(uuid) {
    let url = Service.dbServer + Service.apiVersion + "/ship/del";
    return dispatch=>{
        dispatch({type:ActionType.DELETE_SHIP_LIST_START});
        $.ajax(preHandle(url),{
            method:'delete',
            //contentType:'application/json; charset=utf-8',
            dataType:'json',
            data:({uuid:uuid})
        }).then(response=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            message.info("删除成功!");
            dispatch({type:ActionType.DELETE_TRAVEL_LIST_SUCCESS,payload:response.result});
            dispatch(getShip());
        }).catch(function (error) {
            message.error("删除失败!");
            dispatch({type:ActionType.DELETE_SHIP_LIST_FAILED,payload:error});
        })
    };
}
export function batchDeleteShip(uuid_list){
    let url = Service.dbServer + Service.apiVersion + "/ship/batchDel";
    return dispatch=>{
        $.ajax(preHandle(url),{
            method:'post',
            //contentType:'application/json; charset=utf-8',
            dataType:'json',
            data:({ship_list:JSON.stringify(uuid_list)})
        }).then(response=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            if(200 === response.code){
                message.info("删除成功!");
                dispatch(getShip());
            }
        }).catch(function (error) {
            message.error("删除失败!");
        })
    }
}