import React from "react";
import {connect} from "react-redux";
import { Modal,Input,Form } from "antd";
import { updateCustom } from "../../actions/customDBTool";
import CustomChooseSelect from "./CustomChooseSelect";

const {TextArea} = Input;
const CustomRelateForm = Form.create({name:'custom_relate'})(
    class extends React.Component{
    constructor(props) {
        super(props);
        this.state = {};
    }
    render(){
        let { visible, onCancel, onOk, form, modifyValues } = this.props;
        let formItemLayout = {labelCol: {span:6}, wrapperCol: {span:16}};
        let { getFieldDecorator } = form;
        return (<Modal title="关联其他客户" visible={visible} onOk={onOk} maskClosable={false}
                    onCancel={onCancel} okText={"确定"} cancelText={"取消"}>
                    <Form {...formItemLayout}>
                        <Form.Item label={"选择客户"} required={true}>
                            {getFieldDecorator('relate_custom', {rules: [{ required: true, message: '客户名称不能为空!'}],
                                initialValue: modifyValues ? modifyValues.relate_custom : ''})
                            (<CustomChooseSelect placeholder="选择客户"/>)}
                        </Form.Item>
                        <Form.Item label={"关系"} required={true}>
                            {getFieldDecorator('relate', {rules: [{ required: true, message: '关系不能为空!'}],
                                initialValue: modifyValues ? modifyValues.relate : ''})
                            (<Input placeholder="关系"/>)}
                        </Form.Item>
                        <Form.Item label="备注">
                            {getFieldDecorator('relate_note', {rules: [{ required: false}],
                                initialValue: modifyValues ? modifyValues.relate_note : ''})
                            (<TextArea placeholder="备注"/>)}
                        </Form.Item>
                    </Form>
        </Modal>)
    }
})
function mapStateToProps(state) {
    return {...state.custom}
}
function mapDispatchToProps(dispatch) {
    return {onUpdateCustom:(postData)=>dispatch(updateCustom(postData))};
}
class CustomRelateModal extends React.Component{
    constructor(props){
        super(props);
        this.state = {};
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount(){}
    /*shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(this.props.visible !== nextProps.visible){
            this.setState({visible:true});
        }
        return true;
    }*/
    handleSubmit(customInfo){
        const {form} = this.formRef.props;
        form.validateFields((err, fromValues) => {
            if(err){return;}
            let detail = {}
            if(customInfo){detail = customInfo.detail;}
            if(!detail['relateArray']){
                detail['relateArray'] = [];
            }
            if(this.props.mode){
                detail['relateArray'].forEach(item=>{
                    if (item['relate_custom'] === fromValues.relate_custom) {
                        item['relate'] = fromValues.relate;
                        item['relate_note'] = fromValues.relate_note;
                        item['relate_create_time'] = Date.parse(new Date());
                        item['relate_creator'] = localStorage.getItem('account')
                    }
                })
            }
            else{
                detail['relateArray'].push({
                    'relate_custom': fromValues.relate_custom,
                    'relate': fromValues.relate,
                    'relate_note': fromValues.relate_note,
                    'relate_create_time': Date.parse(new Date()),
                    'relate_creator': localStorage.getItem('account')
                });
            }
            let postData = {
                uuid:customInfo.uuid,
                enterprise_uuid:localStorage.getItem('enterprise_uuid'),
                name:customInfo.name,
                age:customInfo.age,
                sex:customInfo.sex,
                type:customInfo.type,
                level:customInfo.level,
                status:customInfo.status,
                detail:JSON.stringify(detail),
                update_time:Date.parse(new Date()),
                creator_account:customInfo.creator_account,
                create_time:Date.parse(customInfo['create_time'])
            }
            this.props.onUpdateCustom(postData);
            form.resetFields();
            this.props.onClose();
        })        
    }
    saveFormRef = formRef => {this.formRef = formRef;}
    render(){
        return (<CustomRelateForm wrappedComponentRef={this.saveFormRef} visible={this.props.visible}
                onCancel={this.props.onClose} onOk={()=>{this.handleSubmit(this.props.values)}}
                modifyValues={this.props.modifyValues} mode={this.props.mode}/>);
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CustomRelateModal);