import React from 'react';
import {Button,Form,Input, Row, Col,Layout, Tabs} from 'antd';
import {UserOutlined,LockOutlined,EyeInvisibleOutlined,EyeOutlined,LeftOutlined } from '@ant-design/icons';
import {sendEmailVerifyCode,sendPhoneResetPSDVerifyCode} from "../actions/commonDBTool";
import {resetUserPassword} from "../actions/userDBTool";
import FrameHeader from "./header";
import {connect} from "react-redux";

const {Content} = Layout;
const { TabPane } = Tabs;
const ResetPsdForm = Form.create({name:'resetPsdForm'})(
    class extends React.Component{
        constructor(props) {
            super(props);
            this.state = {maxTime:60,tabKey:'email',verifyButtonStatus:true,verifyButtonText:'获取验证码'};
            this.sendEmailResetPsdVerifyCode = this.sendEmailResetPsdVerifyCode.bind(this);
            this.sendPhoneVerifyCode = this.sendPhoneVerifyCode.bind(this);
        }
        sendEmailResetPsdVerifyCode(){
            this.props.form.validateFields((err, fromValues) => {
                console.log(fromValues.email)
                if(0>= fromValues.email.length){
                    return ;
                }
                sendEmailVerifyCode(fromValues.email,"【多易CRM】邮箱验证码","【多易CRM】提醒您,当前账号正在重置密码，验证码为:");
                this.countDown();
            });
        }
        sendPhoneVerifyCode(){
            this.props.form.validateFields((err, fromValues) => {
                console.log(fromValues.phone)
                if(0>= fromValues.phone.length){return ;}
                sendPhoneResetPSDVerifyCode(fromValues.phone);
                this.countDown();
            });
        }
        handleConfirmPassword = (rule, value, callback) => {
            const { getFieldValue } = this.props.form
            if (value && value !== getFieldValue('password')) {
                callback('两次输入不一致！')
            }
            // Note: 必须总是返回一个 callback，否则 validateFieldsAndScroll 无法响应
            callback()
        }
        countDown=()=>{
            if (this.state.maxTime === 0) {
                this.setState({maxTime:60,
                    verifyButtonStatus:true,
                    verifyButtonText:'获取验证码'});
            } else {
                let maxTime = this.state.maxTime
                this.setState({maxTime:--maxTime,
                    verifyButtonStatus:false,
                    verifyButtonText:maxTime + "秒后重新获取"});
                setTimeout(this.countDown, 1000);
            }
        }
        renderPhoneResetPsd(){
            let { getFieldDecorator } = this.props.form;
            return (<Form>
                <Form.Item required={true}>
                    {getFieldDecorator('phone',{rules:[{required:true,message:'请输入账号绑定的手机号'},
                            {pattern: new RegExp(/(1[3|4|5|6|7|8|9][\d]{9})/,"g"),message:'请输入正确的手机格式'}]})
                    (<Input size={"large"} style={{width:'75%'}} prefix={<UserOutlined/>} placeholder="请输入账号绑定的手机号"/>)}
                </Form.Item>
                <Form.Item required={true}>
                    {getFieldDecorator('password',{rules:[{required:true,message:'请输入新密码'},{max:16,message: '最长不能超过16位'},{min:6,message: '最短不能低于6位'}]})(
                        <Input size={"large"} style={{width:'75%'}} prefix={<LockOutlined/>} placeholder="请输入新密码"
                            type={this.state.showPass?"text":"password"} addonAfter={this.state.showPass?
                                <EyeInvisibleOutlined onClick={()=>{this.setState({showPass:!this.state.showPass})}}/>:
                                <EyeOutlined onClick={()=>{this.setState({showPass:!this.state.showPass})}}/>}/>)}
                </Form.Item>
                <Form.Item required={true}>
                    {getFieldDecorator('passwordConfirm',{rules:[{required:true,message:'请再次输入以确认新密码'},
                            {max:16,message: '最长不能超过16位'},{min:6,message: '最短不能低于6位'},
                            {validator: this.handleConfirmPassword,validateStatus:"warning"}]})
                    (<Input size='large' style={{width:'75%'}} prefix={<LockOutlined/>} placeholder="请再输入一次新密码"
                        type={this.state.showPassConfirm?"text":"password"} addonAfter={this.state.showPass?
                                <EyeInvisibleOutlined onClick={()=>{this.setState({showPass:!this.state.showPass})}}/>:
                                <EyeOutlined onClick={()=>{this.setState({showPass:!this.state.showPass})}}/>}/>)}
                </Form.Item>
                <Form.Item required={true}>
                    <Row>
                        <Col span={12} offset={3}>
                            {getFieldDecorator('verifyCode',{rules:[{required:true,message:'请输入正确的验证码!'}]})
                            (<Input size='large' placeholder="请输入手机验证码"/>)}
                        </Col>
                        <Col span={5}>
                            <Button size='large' type={'primary'} onClick={this.sendPhoneVerifyCode} disabled={!this.state.verifyButtonStatus}>{this.state.verifyButtonText}</Button>
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item required={true}>
                    <Button size='large' style={{width:'75%'}} type={'primary'} onClick={this.props.onOk}>确定</Button>
                </Form.Item>
            </Form>);
        }
        renderEmailResetPsd(){
            let { getFieldDecorator } = this.props.form;
            return (<Form>
                <Form.Item required={true}>
                    {getFieldDecorator('email',{rules:[{required:true,message:'请输入账号绑定的邮箱'},
                            {type:'email',message: "请输入正确的邮箱格式"}]})
                    (<Input size={"large"} style={{width:'75%'}}
                            prefix={<UserOutlined/>} placeholder="请输入账号绑定的邮箱"/>)}
                </Form.Item>
                <Form.Item required={true}>
                    {getFieldDecorator('password',{rules:[{required:true,message:'请输入新密码'},
                            {max:16,message: '最长不能超过16位'},{min:6,message: '最短不能低于6位'}]})(
                        <Input size={"large"} style={{width:'75%'}}
                            prefix={<LockOutlined/>} placeholder="请输入新密码"
                            type={this.state.showPass?"text":"password"}
                            addonAfter={this.state.showPass?
                                <EyeInvisibleOutlined onClick={()=>{this.setState({showPass:!this.state.showPass})}}/>:
                                <EyeOutlined onClick={()=>{this.setState({showPass:!this.state.showPass})}}/>}/>)}
                </Form.Item>
                <Form.Item required={true}>
                    {getFieldDecorator('passwordConfirm',{rules:[{required:true,message:'请再次输入以确认新密码'},
                            {max:16,message: '最长不能超过16位'},{min:6,message: '最短不能低于6位'},
                            {validator: this.handleConfirmPassword,validateStatus:"warning"}]})
                    (<Input size='large' style={{width:'75%'}}
                            prefix={<LockOutlined/>} placeholder="请再输入一次新密码"
                            type={this.state.showPassConfirm?"text":"password"}
                            addonAfter={this.state.showPass?
                                <EyeInvisibleOutlined onClick={()=>{this.setState({showPass:!this.state.showPass})}}/>:
                                <EyeOutlined onClick={()=>{this.setState({showPass:!this.state.showPass})}}/>}/>)}
                </Form.Item>
                <Form.Item required={true}>
                    <Row>
                        <Col span={12} offset={3}>
                            {getFieldDecorator('verifyCode',{rules:[{required:true,message:'请输入正确的验证码!'}]})
                            (<Input size='large' placeholder="请输入邮箱验证码"/>)}
                        </Col>
                        <Col span={5}>
                            <Button size='large' type={'primary'} onClick={this.sendEmailResetPsdVerifyCode} disabled={!this.state.verifyButtonStatus}>{this.state.verifyButtonText}</Button>
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item required={true}>
                    <Button size='large' style={{width:'75%'}} type={'primary'} onClick={this.props.onOk}>确定</Button>
                </Form.Item>
            </Form>);
        }
        render(){
            return(<Tabs defaultActiveKey={"email"} onChange={(value)=>{this.props.tabOnChange(value);this.setState({tabKey:value})}}>
                <TabPane key={"email"} tab={"通过邮箱找回"}>
                    {"email" === this.state.tabKey?this.renderEmailResetPsd():null}
                </TabPane>
                <TabPane key={"phone"} tab={"通过手机号找回"}>
                    {"phone" === this.state.tabKey?this.renderPhoneResetPsd():null}
                </TabPane>
            </Tabs>);
        }
    }
)
function mapStateToProps(state){
    return {...state.auth};
}
function mapDispatchToProps(dispatch) {
    return {onResetPsd:(postData)=>dispatch(resetUserPassword(postData))
    }
}
class ResetPsd extends React.Component{
    constructor(props) {
        super(props);
        this.state = {resetPsdLoading:false,tabKey:'email'}
    }
    componentDidMount(){
        document.title = "多易CRM--找回密码";
        this.setState({resetPsdLoading:false});
    }
    handleResetPsd=()=>{
        const { form } = this.formRef.props;
        form.validateFields((err, fromValues) => {
            if (err) {return;}
            let postData = {old_password:"",
                            new_password:fromValues.password};
            if("phone" === this.state.tabKey){
                postData['account'] = fromValues.phone;
            }
            else{
                postData['account'] = fromValues.email;
            }
            postData["verify_code"] = fromValues.verifyCode
            this.props.onResetPsd(postData)
            this.setState({resetPsdLoading:true})
        });
    }
    saveFormRef = formRef =>{this.formRef = formRef;}
    render(){
        return (<Content style={{minHeight:'100vh',backgroundImage:`url('https://zos.alipayobjects.com/rmsportal/gGlUMYGEIvjDOOw.jpg')`}}>
                <FrameHeader/>
                <Row>
                    <Col span={9} offset={13}>
                        <Row style={{textAlign:"center",background:'#FAFaFa50',marginTop:'10vh'}}>
                            <br/>
                            <Row><h2>找回密码</h2></Row>
                            <ResetPsdForm tabOnChange={(value)=>{this.setState({tabKey:value});}} onOk={this.handleResetPsd} wrappedComponentRef={this.saveFormRef}/>
                            <Button type="link" onClick={()=>{this.props.history.push('/login');}}>
                                <LeftOutlined/>前往登录
                            </Button>
                            <br/><br/>
                        </Row>
                    </Col>
                </Row>
        </Content>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(ResetPsd);