import {ActionType} from "../constants/actionTypes";

const collectionReducer = (state = {},action)=>{
    switch (action.type) {
        case ActionType.ADD_COLLECTION_LIST_START:
            return {...state,add_:false}
        case ActionType.ADD_COLLECTION_LIST_SUCCESS:
            return {...state,add_collection:true,add_collection_result:action.payload}
        case ActionType.ADD_COLLECTION_LIST_FAILED:
            return {...state,add_collection:false,add_collection_errors:action.error?action.payload.errors:null}

        case ActionType.GET_COLLECTION_LIST_START:
            return {...state,get_collection:false}
        case ActionType.GET_COLLECTION_LIST_SUCCESS:
            return {...state,get_collection:true,get_collection_result:action.payload}
        case ActionType.GET_COLLECTION_LIST_FAILED:
            return {...state,get_collection:false,get_collection_errors:action.error?action.payload.errors:null}

        case ActionType.GET_COLLECTION_LIST_BY_CUSTOM_START:
            return {...state,get_collection_by_custom:false}
        case ActionType.GET_COLLECTION_LIST_BY_CUSTOM_SUCCESS:
            return {...state,get_collection_by_custom:true,get_collection_by_custom_result:action.payload}
        case ActionType.GET_COLLECTION_LIST_BY_CUSTOM_FAILED:
            return {...state,get_collection_by_custom:false,get_collection_by_custom_errors:action.errors?action.payload.errors:null}

        case ActionType.UPDATE_COLLECTION_LIST_START:
            return {...state,update_collection:false}
        case ActionType.UPDATE_COLLECTION_LIST_SUCCESS:
            return {...state,update_collection:true,update_collection_result:action.payload}
        case ActionType.UPDATE_COLLECTION_LIST_FAILED:
            return {...state,update_collection:false,update_collection_errors:action.error?action.payload.errors:null}

        case ActionType.DELETE_COLLECTION_LIST_START:
            return {...state,delete_collection:false}
        case ActionType.DELETE_COLLECTION_LIST_SUCCESS:
            return {...state,delete_collection:true,delete_collection_result:action.payload}
        case ActionType.DELETE_COLLECTION_LIST_FAILED:
            return {...state,delete_collection:false,delete_collection_errors:action.error?action.payload.errors:null}

        default:
            return {...state};
    }
}
export default collectionReducer;
