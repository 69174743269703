export function isToday(date) {
    //Code goes here.
    let d = new Date(date);
    let todayDate = new Date();
    if(d.setHours(0,0,0,0) === todayDate.setHours(0,0,0,0)){
        return true;
    } else {
        return false;
    }
}
export function isThisWeek(date) {
    let d = new Date(date).setHours(0,0,0,0);
    let todayDate = new Date();
    let monday =  new Date(todayDate.getTime() - (todayDate.getDay()-1)* 24*3600*1000).setHours(0,0,0,0);
    let sunday = new Date(todayDate.getTime() + (7-todayDate.getDay())*24*3600*1000).setHours(23,59,59,0);
    if(d >= monday && d <= sunday){return true}
    return false;
}
export function out7Days(date){
    if(Date.parse(new Date()) - Date.parse(date) > 7*3600*24*1000){
        return true
    }
    return false
}
export function out30Days(date){
    if(Date.parse(new Date()) - Date.parse(date) > 30*3600*24*1000){
        return true
    }
    return false
}
export function out60Days(date){
    if(Date.parse(new Date()) - Date.parse(date) > 60*3600*24*1000){
        return true
    }
    return false
}
export function out90Days(date){
    if(Date.parse(new Date()) - Date.parse(date) > 90*3600*24*1000){
        return true
    }
    return false
}
export function out180Days(date){
    if(Date.parse(new Date()) - Date.parse(date) > 180*3600*24*1000){
        return true
    }
    return false
}
export function isThisMonth(date) {
    let d = new Date(date);
    let todayDate = new Date();
    if (d.getMonth() === todayDate.getMonth()){
        return true;
    } else{
        return false;
    }
}
export function isThisYear(date) {
    let d = new Date(date);
    let todayDate = new Date();
    if (d.getFullYear() === todayDate.getFullYear()){
        return true;
    } else{
        return false;
    }
}
export function delFriAndEndSpaceWord(str){ //去掉首尾两端的空格(^\s*)|(\s*$)
    if(!str){return;}
    str = str.replace( / +/g, ' ' );
    return str.replace(/(^\s*)|(\s*$)/g, "");
}
export function delHtmlTag(str){
    if(!str){return;}
    return str.replace(/<[^>]+>/g,"");
}
export function StringCompareSort(a,b){
    if(b){
        return a?a.toString().localeCompare(b.toString()):"".localeCompare(b.toString());
    }
    else{
        return a?a.toString().localeCompare(""):"".localeCompare("");
    }
}
export function getCookie(key) {// 获取cookie
        const name = key + "=";
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            const c = ca[i].trim();
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
export function setCookie(key, value) {// 设置cookie,默认是30天
        const d = new Date();
        d.setTime(d.getTime() + (30 * 24 * 60 * 60 * 1000));
        const expires = "expires=" + d.toGMTString();
        document.cookie = key + "=" + value + "; " + expires;
        console.log(getCookie("JSESSIONID"))
        console.log("success");
    }