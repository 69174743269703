import React from 'react';

class OrderStatistics extends React.Component {
    constructor(props){
        super(props);
        this.state = {};
    }
    render(){
        return (<div>the user OrderStatistics page</div>);
    }
}
export default OrderStatistics;