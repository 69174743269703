import $ from 'jquery'
//import axios from "axios";
import {message} from "antd";
import * as Service from '../config/service.json';
import {ActionType} from "../constants/actionTypes";
import {preHandle,checkAccountStatus} from './commonDBTool';
export function addLeave(postData) {
    let url = Service.dbServer + Service.apiVersion + "/leave/add" ;
    return dispatch=>{
        dispatch({type:ActionType.ADD_LEAVE_LIST_START});
        $.ajax(preHandle(url),{
            method:'POST',
            dataType:'json',
            contentType: 'application/json; charset=utf-8',
            data:JSON.stringify(postData)
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            message.info("添加成功!");
            dispatch({type:ActionType.ADD_LEAVE_LIST_SUCCESS,payload:response.result});
            dispatch(getLeave());
        }).catch(function (error) {
            message.error("添加失败!");
            dispatch({type:ActionType.ADD_LEAVE_LIST_FAILED,payload:error});
        })
    }
}
export function getLeave(pageStart=0,pageSize=50) {
    let url = Service.dbServer + Service.apiVersion + "/leave/getAll" ;
    return dispatch=>{
        dispatch({type:ActionType.GET_LEAVE_LIST_START});
        $.ajax(preHandle(url),{
            method:'get',
            contentType:'application/json; charset=utf-8',
            dataType:'json',
            data:{pageStart:pageStart,pageSize:pageSize}
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            let result = response.result
            if(result){
                for(let i = 0;i < result.length;i++){
                    result[i].detail = JSON.parse(result[i].detail);
                }
            }
            dispatch({type:ActionType.GET_LEAVE_LIST_SUCCESS,payload:result});
        }).catch(function (error) {
            dispatch({type:ActionType.GET_LEAVE_LIST_FAILED,payload:error});
        })
    }
}
export function updateLeave(postData) {
    let url = Service.dbServer + Service.apiVersion + "/leave/update" ;
    return dispatch=>{
        dispatch({type:ActionType.UPDATE_LEAVE_LIST_START})
        $.ajax(preHandle(url),{
            method:'POST',
            contentType: 'application/json; charset=utf-8',
            data:JSON.stringify(postData)
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            message.info("更新成功!");
            dispatch({type:ActionType.UPDATE_LEAVE_LIST_SUCCESS,payload:response.result});
            dispatch(getLeave());
        }).catch(function (error) {
            message.error("更新失败!")
            dispatch({type:ActionType.UPDATE_LEAVE_LIST_FAILED,payload:error});
        })
    };
}
export function deleteLeave(uuid) {
    let url = Service.dbServer + Service.apiVersion + "/leave/del" ;
    return dispatch=>{
        dispatch({type:ActionType.DELETE_LEAVE_LIST_START});
        $.ajax(preHandle(url),{
            method:'delete',
            //contentType:'application/json; charset=utf-8',
            dataType:'json',
            data:({uuid:uuid})
        }).then(response=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            if(200 === response.code){
                message.info("删除成功!");
                dispatch({type:ActionType.DELETE_LEAVE_LIST_SUCCESS,payload:response.result});
                dispatch(getLeave());
            }
            else{
                message.error(response.message);
                dispatch({type:ActionType.DELETE_LEAVE_LIST_FAILED,payload:response.message});
            }
        }).catch(function (error) {
            message.error("删除失败!");
            dispatch({type:ActionType.DELETE_LEAVE_LIST_FAILED,payload:error});
        })
    };
}
export function batchDeleteLeave(uuid_list){
    let url = Service.dbServer + Service.apiVersion + "/leave/batchDel" ;
    return dispatch=>{
        $.ajax(preHandle(url),{
            method:'post',
            dataType:'json',
            data:({leave_list:JSON.stringify(uuid_list),})
        }).then(response=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            if(200 === response.code){
                message.info("删除成功!");
                dispatch(getLeave());
            }
        }).catch(function (error) {
            message.error("删除失败!");
        })
    };
}