import React from 'react';
import {Input, Select, Form, Modal, DatePicker, Row, Button, message} from 'antd';
import {addUser, updateUser,deleteUser} from "../../actions/userDBTool";
import {USER_LEVEL_ADMIN, userLevelList, userStatusList} from "../../constants/constant";
import moment from "moment";
import {connect} from "react-redux";
import ExtendTable from "./ExtendTable";
import {EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";

const {confirm} = Modal;
const {Option} = Select;

let userStatusOptions = userStatusList.map(item=><Option key={item.value} value={item.value}>{item.text}</Option>);
let userLevelOptions = userLevelList.map(item=><Option key={item.value} value={item.value}>{item.text}</Option>)
const StaffForm = Form.create({name:'staff_modal'})(
    class extends React.Component{
        constructor(props) {
            super(props);
            this.state = {showPass:false,childOptions:null,positionOptions:null}
            this.handleDepartmentChange = this.handleDepartmentChange.bind(this);
            this.handleChildrenChange = this.handleChildrenChange.bind(this);
        }
        /*componentWillUnmount(){
            this.setState({showPass:false,childOptions:null,positionOptions:null})
            return;
        }*/
        compareToFirstPassword = (rule, value, callback) => {
            const { form } = this.props;
            if (value && value !== form.getFieldValue('password')) {
                callback('两次输入的密码需要保持一致!');
            } else {
                callback();
            }
        };
        /*validateNoChinese = (rule, value, callback) => {
            let reg = /^[^\u4e00-\u9fa5]+$/g;
            let regEmpty = /^\s*$/g;
            if (value && !reg.test(value)) {
                callback('书写格式错误');
            } else if(value && regEmpty.test(value)) {
                callback('缺陷编号不能为空');
            } else {
                callback();
            }
        }*/
        handleDepartmentChange(value){
            console.log(value)
            let departmentList = this.props.departmentList;
            let childOptions = []
            for(let i = 0;i < departmentList.length;i++){
                if(value.label === departmentList[i].name&&departmentList[i].children){
                    if(departmentList[i].children.length){
                        for(let j = 0;j < departmentList[i].children.length;j++){
                            childOptions.push(<Option key={j} value={departmentList[i].children[j].id}>{departmentList[i].children[j].name}</Option>)
                        }
                        this.setState({childOptions:childOptions})
                        return;
                    }
                }
            }
            this.setState({childOptions:null,positionOptions:null})
            this.props.form.setFieldsValue({position:value.label});
        }
        handleChildrenChange(value){
            console.log(value)
            let departmentList = this.props.departmentList;
            let positionOptions = []
            for(let i = 0;i < departmentList.length;i++){
                for(let j = 0;j < departmentList[i].children.length;j++){
                    if(departmentList[i].children[j].name === value.label && departmentList[i].children[j].children){
                        if(departmentList[i].children[j].children.length){
                            for(let k = 0;k < departmentList[i].children[j].children.length;k++){
                                positionOptions.push(<Option key={k} value={departmentList[i].children[j].children[k].id}>{departmentList[i].children[j].children[k].name}</Option>)
                            }
                            this.setState({positionOptions:positionOptions})
                            return;
                        }
                    }
                }
            }
            this.setState({positionOptions:null})
            this.props.form.setFieldsValue({position:value.label});
        }
        handlePositionChange(value){
            console.log(value)
            this.props.form.setFieldsValue({position:value.label});
        }
        renderDepartmentPosition(values,mode){
            const { departmentList,form } = this.props;
            let { getFieldDecorator } = form;
            const departmentOptions = [];
            if(null != departmentList){
                for (let i = 0;i < departmentList.length;i++){
                    departmentOptions.push(<Option key={i} value={departmentList[i].id}>{departmentList[i].name}</Option>);
                }
                return(<div>
                    <Form.Item label="职位">
                        {getFieldDecorator('position_id1',{initialValue:{label:values.position}})
                            (<Select labelInValue={true} onChange={(value)=>{this.handleDepartmentChange(value)}}>{departmentOptions}</Select>)}
                    </Form.Item>
                    {this.state.childOptions?<Form.Item wrapperCol={{span:17,offset:6}}>
                        {getFieldDecorator('position_id2',{initialValue:{label:values.position}})
                            (<Select labelInValue={true} onChange={(value)=>{this.handleChildrenChange(value)}}>{this.state.childOptions}</Select>)}
                    </Form.Item>:null}
                    {this.state.positionOptions?<Form.Item wrapperCol={{span:17,offset:6}}>
                        {getFieldDecorator('position_id3',{initialValue:{label:values.position}})
                            (<Select labelInValue={true} onChange={(value)=>{this.handlePositionChange(value)}}>{this.state.positionOptions}</Select>)}
                    </Form.Item>:null}
                    <Form.Item label={""}>
                        {getFieldDecorator('position', {initialValue:values.position})
                        (<Input hidden={true}/>)}
                    </Form.Item>
                </div>)
            }
        }
        render(){
            let { visible, onCancel, onOk, form, values,mode,title} = this.props;
            let { getFieldDecorator } = form;
            return(<Modal title={title} visible={visible} maskClosable={false} onOk={onOk} okText={"确定"} onCancel={onCancel} cancelText={"取消"} destroyOnClose={true}>
                <Form labelCol={{span:6}} wrapperCol={{span:17}}>
                    <Form.Item label={"成员名称"}>
                        {getFieldDecorator('name',{rules:[{required: true,message:'成员名称不能为空!' }],initialValue:mode?values.name:""})
                        (<Input placeholder={"请输入员工名称"}/>)}
                    </Form.Item>
                    <Form.Item label={"内部ID"}>
                        {getFieldDecorator('staffID',{rules:[{required: true,message:'成员ID不能为空'},{pattern: new RegExp(/^[0-9]\d*$/, "g"),message: '只支持数字格式'}],
                            initialValue:mode?values.staffID:""})
                        (<Input placeholder={"请输入成员的内部员工ID"}/>)}
                    </Form.Item>
                    <Form.Item label={"登录账号"}>
                        {getFieldDecorator('account',{rules:[{required: true,message:'登录账号不能为空!'},
                            {pattern: new RegExp(/(1[3|4|5|6|7|8|9][\d]{9}|^[A-Za-z0-9-._]+@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,6})$)/,"g"),message:'请输入正确的手机格式或者邮箱地址'}],
                            initialValue:mode?values.account:""},)
                        (<Input placeholder={"员工登录的账号,请输入手机号码或者邮箱"} disabled={mode?true:false}/>)}
                    </Form.Item>
                    <Form.Item label={"登录密码"} required={mode?false:true}>
                        {getFieldDecorator('password',{rules:[{required:mode?false:true,message:'密码不能为空!' },{min:6,message: '密码长度不得低于6位!'}], initialValue:""})
                        (<Input placeholder={"请输入成员的密码，员工凭该密码登录系统，员工也可自行修改密码!"} type={this.state.showPass?"text":"password"}
                            addonAfter={this.state.showPass?<EyeInvisibleOutlined onClick={()=>{this.setState({showPass:!this.state.showPass})}}/>:<EyeOutlined onClick={()=>{this.setState({showPass:!this.state.showPass})}}/>}/>)}
                    </Form.Item>
                    <Form.Item label={"密码确认"} required={mode?false:true}>
                        {getFieldDecorator('passwordConfig',{rules:[{required:mode?false:true,message:'密码不能为空!' },
                            {validator: this.compareToFirstPassword}], initialValue:""})
                        (<Input placeholder={"请再输入一次密码，确保两次输入相同!"} type={this.state.showPass?"text":"password"}
                            addonAfter={this.state.showPass?<EyeInvisibleOutlined onClick={()=>{this.setState({showPass:!this.state.showPass})}}/>:<EyeOutlined onClick={()=>{this.setState({showPass:!this.state.showPass})}}/>}/>)}
                    </Form.Item>
                    <Form.Item label={"电子邮箱"}>
                        {getFieldDecorator('email',{rules:[{type:'email',message: "请输入正确的邮箱格式"}],initialValue:mode?values.email:""})
                        (<Input placeholder={"请输入成员的电子邮箱"}/>)}
                    </Form.Item>
                    <Form.Item label={"手机号码"}>
                        {getFieldDecorator('phone',{rules:[{ required: false,message:'手机号码不能为空!'},
                            {pattern: new RegExp(/(1[3|4|5|6|7|8|9][\d]{9})/,"g"),message:'请输入正确的手机格式'}],initialValue:mode?values.phone:""})
                        (<Input placeholder={"请输入成员的手机号码"}/>)}
                    </Form.Item>
                    {this.renderDepartmentPosition(values,mode)}
                    <Form.Item label={"等级"}>
                        {getFieldDecorator('level',{rules:[{required:false}],initialValue:mode?values.level:1})
                        (<Select>{userLevelOptions}</Select>)}
                    </Form.Item>
                    <Form.Item label={"状态"}>
                        {getFieldDecorator('status',{rules:[{required:false}],initialValue:mode?values.status:1})
                        (<Select>{userStatusOptions}</Select>)}
                    </Form.Item>
                    <Form.Item label={"加入时间"} required={true}>
                        {getFieldDecorator('createTime', {rules: [{ required: true,message:'加入时间不能为空!'}],
                            initialValue:mode?moment(new Date(values.createTime),'YYYY-MM-DD'):moment(new Date(), 'YYYY-MM-DD')})
                        (<DatePicker placeholder={"选择加入的时间"} showTime style={{width:'60%'}}/>)}
                    </Form.Item>
                </Form>
            </Modal>);
        }
    }
)
function mapStateToProps(state) {
    return {...state.staff, ...state.enterprise};
}
function mapDispatchToProps(dispatch) {
    return {onAddStaff:(postData,staffList)=>dispatch(addUser(postData,staffList)),
            onEditStaff:(postData)=>dispatch(updateUser(postData)),
            onDeleteStaff:(account)=>dispatch(deleteUser(account)),
        };
}
class StaffTable extends React.Component{
    constructor(props) {
        super(props);
        this.state = {visible:false, modalMode:0,modalTitle:'添加成员',modalValues:{}};
        this.updateModalValues = this.updateModalValues.bind(this);
    }
    saveFormRef = formRef => {this.formRef = formRef;};
    updateModalValues(values){
        let detail = values.detail;
        this.setState({
            modalValues:{
                account:values.account,
                name:values.name,
                staffID:values.staff_id,
                password:values.password,
                email:detail.email,
                phone:detail.phone,
                position:detail.position,
                position_id1:detail.position_id1?detail.position_id1:"",
                position_id2:detail.position_id2?detail.position_id2:"",
                position_id3:detail.position_id3?detail.position_id3:"",
                level:values.level,
                status:values.status,
                login_time:values.login_time,
                login_token:values.login_token,
                login_token_mobile:values.login_token_mobile,
                createTime:values.create_time
            }
        });
    }
    showDeleteConfirm = (record)=>{
        if(record.account === localStorage.getItem('account')){
            message.error("无法删除自己！");
            return ;
        }
        confirm({
            title: '重要提示',
            content: '您确定要删除该员工?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk:()=> {this.props.onDeleteStaff(record.account);},
            onCancel() {
                console.log('Cancel');
            },
        });
    }
    handleEmptySelect=(account_list)=>{
        this.props.onBatchDelete(account_list);
    }
    handleAddOnClick=()=>{
        this.setState({visible:true, modalMode:0,modalTitle:'添加成员',
            modalValues:{position:"",level:1,status:1}
        });
    }
    handleEditClick=(record)=>{
        if(record.account === localStorage.getItem('account') && USER_LEVEL_ADMIN !== Number(localStorage.getItem('level'))){
            message.error("无法修改自己！");
            return ;
        }
        if (!this.props.get_staff){return ;}
        let staffList = this.props.get_staff_list_result;
        for (let i = 0;i < staffList.length;i++){
            if (staffList[i].account === record.account){
                this.updateModalValues(staffList[i]);
                this.setState({modalMode:1,modalTitle:'编辑成员',visible:true});
                return;
            }
        }
    }
    handleModalCancel=()=>{
        this.setState({visible:false});
        const { form } = this.formRef.props;
        form.resetFields();
    }
    handleStaffSubmit=()=>{
        const { form } = this.formRef.props;
        form.validateFields((err,formValues)=>{
            if (err) {return;}
            let current_time = new Date().getTime();
            let staffList = this.props.get_staff_list_result;
            let detail = {}
            for (let i = 0;i < staffList.length;i++){
                if (staffList[i].account === formValues.account){
                    detail = staffList[i].detail;
                }
            }
            detail.phone = formValues.phone;
            detail.email = formValues.email?formValues.email:formValues.account;
            delete detail.position_id1
            delete detail.position_id2
            delete detail.position_id3
            detail.position_id1 = formValues.position_id1.key;
            if(formValues.position_id2){
                detail.position_id2 = formValues.position_id2.key;   
            }
            if(formValues.position_id3){
                detail.position_id3 = formValues.position_id3.key;
            }
            detail.position = formValues.position;
            console.log(detail)
            let postData = {name:formValues.name,
                            account:formValues.account,
                            enterprise_uuid:localStorage.getItem('enterprise_uuid'),
                            staff_id:formValues.staffID,
                            detail:JSON.stringify(detail),
                            level:formValues.level,
                            status:formValues.status}
            if(this.state.modalMode){
                postData['login_time']=this.state.modalValues.login_time;
                postData['login_token'] =this.state.modalValues.login_token
                postData['login_token_mobile'] =this.state.modalValues.login_token_mobile
                postData['create_time'] = Date.parse(formValues.createTime);
                if(formValues.password.length && formValues.password === formValues.passwordConfig){
                    postData['password'] = formValues.password;
                }
                else{
                    postData['password'] = ""
                }
                this.props.onEditStaff(postData);
            }
            else{
                postData['password'] = formValues.password;
                postData['login_time']=current_time;
                postData['login_token'] =current_time
                postData['login_token_mobile'] =current_time
                postData['create_time'] = Date.parse(formValues.createTime);
                this.props.onAddStaff(postData,this.props.get_staff_list_result);
            }
            let modalValues = {
                name:formValues.name,
                account:formValues.account,
                staff_id:formValues.staffID,
                detail:detail,
                level:formValues.level,
                status:formValues.status,
                login_time:postData['login_time'],
                login_token:postData['login_token'],
                login_token_mobile:postData['login_token_mobile']
            }
            form.resetFields();
            this.updateModalValues(modalValues);
            this.setState({visible:false})
        })
    }
    render() {
        const columns = [
            {title: '序号',dataIndex:'index',key:'index',width:50},
            {title: '账号',dataIndex:'account',key:'account'},
            {title: '名称',dataIndex:'name',key:'name'},
            {title: '部门',dataIndex:'department',key:'department',width:120},
            {title: '职位',dataIndex:'position',key:'position',width:120},
            {title: '电子邮箱',dataIndex:'email',key:'email'},
            {title: '手机号码',dataIndex:'phone',key:'phone'},
            {title: '账号状态',dataIndex:'status',key: 'status',width:100},
            {title: '登录时间',dataIndex:'login_time',key:'login_time'},
            {title: '操作',dataIndex:'actions',key:'actions', render: (text,record) =><span>
                <Button type={"primary"} onClick={()=>{this.handleEditClick(record)}}>编辑</Button>
                <Button type={"danger"} disabled={record.account === localStorage.getItem('account')?true:false} onClick={()=>{this.showDeleteConfirm(record)}}>删除</Button>
            </span>}];
        return (<Row>
                <StaffForm wrappedComponentRef={this.saveFormRef} visible={this.state.visible} 
                    values={this.state.modalValues} mode={this.state.modalMode} title={this.state.modalTitle}
                    onCancel={this.handleModalCancel} onOk={this.handleStaffSubmit}
                    departmentList={this.props.get_department_result}/>
                <ExtendTable handleAddOnClick={this.handleAddOnClick} onBatchDelete={this.handleEmptySelect}
                    columns={columns} bordered dataSource={this.props.dataSource} rowKey={record => record.account}/>
        </Row>);
    }
}
export  default connect(mapStateToProps,mapDispatchToProps)(StaffTable)