import React from 'react';
import {Row,Tabs} from 'antd';
import NoticeTable from "../controls/NoticeTable";
import {getAllNotice} from "../../actions/noticeDBTool";
import {connect} from "react-redux";
const { TabPane } = Tabs;
function mapStateToProps(state) {
    return {...state.notice}
}
function mapDispatchToProps(dispatch) {
    return{ onGetNotice:()=>dispatch(getAllNotice())};
}
class NoticeList extends React.Component {
    constructor(props){
        super(props);
        this.state = {};
    }
    componentDidMount() {
        this.props.onGetNotice();
    }
    renderNotice(){
        let unreadDS = [];
        let readDS = [];
        let allDS = [];
        if(this.props.get_notice_result){
            let result = this.props.get_notice_result;
            result.sort(function (a,b) { return Date.parse(b.create_time) - Date.parse(a.create_time);})
            for (let i = 0;i < result.length;i++){
                let readJson = result[i].detail.read_list;
                allDS.push(result[i]);
                let assignerList = result[i].detail.assigner_account?result[i].detail.assigner_account:[];
                if(0 < assignerList.length && -1 === assignerList.indexOf(localStorage.getItem('account'))){
                    continue;
                }
                if(readJson.length > 0){
                    for(let j = 0;j < readJson.length;j++){
                        if(localStorage.getItem('account') === readJson[j]){
                            readDS.push(result[i])
                            break;
                        }
                        if(readJson.length -1 === j){
                            unreadDS.push(result[i]);
                        }
                    }
                }
                else{ unreadDS.push(result[i]);}
            }
        }
        return(<Tabs defaultActiveKey="1" type='card'>
            <TabPane key="1" tab="未读">
                <NoticeTable dataSource={unreadDS}/>
            </TabPane>
            <TabPane key="2" tab="已读">
                <NoticeTable dataSource={readDS}/>
            </TabPane>
            <TabPane key="3" tab="全部">
                <NoticeTable dataSource={allDS}/>
            </TabPane>
        </Tabs>);
    }
    render(){
        return (<Row>{this.renderNotice()}</Row>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(NoticeList);