import {ActionType} from "../constants/actionTypes";

const shipReducer = (state = {},action)=>{
    switch (action.type) {
        case ActionType.ADD_SHIP_LIST_START:
            return {...state,add_ship:false}
        case ActionType.ADD_SHIP_LIST_SUCCESS:
            return {...state,add_ship:true,add_ship_result:action.payload}
        case ActionType.ADD_SHIP_LIST_FAILED:
            return {...state,add_ship:false,add_ship_errors:action.error?action.payload.errors:null}

        case ActionType.GET_SHIP_LIST_START:
            return {...state,get_ship:false}
        case ActionType.GET_SHIP_LIST_SUCCESS:
            return {...state,get_ship:true,get_ship_result:action.payload}
        case ActionType.GET_SHIP_LIST_FAILED:
            return {...state,get_ship:false,get_ship_errors:action.error?action.payload.errors:null}

        case ActionType.UPDATE_SHIP_LIST_START:
            return {...state,update_ship:false}
        case ActionType.UPDATE_SHIP_LIST_SUCCESS:
            return {...state,update_ship:true,update_ship_result:action.payload}
        case ActionType.UPDATE_SHIP_LIST_FAILED:
            return {...state,update_ship:false,update_ship_errors:action.error?action.payload.errors:null}

        case ActionType.DELETE_SHIP_LIST_START:
            return {...state,delete_ship:false}
        case ActionType.DELETE_SHIP_LIST_SUCCESS:
            return {...state,delete_ship:true,delete_ship_result:action.payload}
        case ActionType.DELETE_SHIP_LIST_FAILED:
            return {...state,delete_ship:false,delete_ship_errors:action.error?action.payload.errors:null}

        default:
            return {...state};
    }
}
export default shipReducer;
