import React from "react";
import {connect} from "react-redux";
import { getSupplier } from "../../actions/supplierDBTool";
import ExtendSelect from './ExtendSelect';

function mapStateToProps(state) {
    return {...state.supplier}
}
function mapDispatchToProps(dispatch) {
    return {onGetAllSupplier:()=>dispatch(getSupplier())}
}
class SupplierChooseSelect extends React.Component{
    constructor(props){
        super(props);
        this.state = {};
    }
    componentDidMount(){
        this.props.onGetAllSupplier();
    }
    render(){
        let supplierList = [];
        if(this.props.get_supplier_result){
            for(let i = 0;i < this.props.get_supplier_result.length;i++){
                let item = this.props.get_supplier_result[i];
                supplierList.push(item.name);
            }
        }
        return (<ExtendSelect style={this.props.style} items={supplierList} title={this.props.title} placeholder={this.props.placeholder} size={this.props.size}
            value={this.props.value} onChange={this.props.onChange} disabled={this.props.disabled}/>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps,null,{forwardRef: true})(SupplierChooseSelect)