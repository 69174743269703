import $ from 'jquery'
import {message} from "antd";
import * as Service from '../config/service.json';
import {ActionType} from "../constants/actionTypes";
import {preHandle,checkAccountStatus} from './commonDBTool';
export function addBusiness(postData) {
    let url = Service.dbServer + Service.apiVersion + "/business/add"
    return dispatch =>{
        dispatch({type:ActionType.ADD_BUSINESS_OPPORTUNITY_START});
        $.ajax(preHandle(url),{
            method:'post',
            contentType:'application/json; charset=utf-8',
            dataType:'json',
            data:JSON.stringify(postData)
        }).then(response=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            message.info("添加成功!");
            dispatch({type:ActionType.ADD_BUSINESS_OPPORTUNITY_SUCCESS,payload:response.result});
            dispatch(getBusinessByUser());
            dispatch(getBusinessByCustom(JSON.parse(postData.detail).custom_uuid));
        }).catch(function (error) {
            message.error("添加失败!");
            dispatch({type:ActionType.ADD_BUSINESS_OPPORTUNITY_FAILED,payload:error});
        })
    }
}
export function getBusinessByUser(pageStart=0,pageSize=50) {
    let url = Service.dbServer + Service.apiVersion + "/business/getByUser"
    return dispatch =>{
        dispatch({type:ActionType.GET_BUSINESS_OPPORTUNITY_START});
        $.ajax(preHandle(url),{
            method:'get',
            dataType:'json',
            data:{pageStart:pageStart,pageSize:pageSize}
        }).then(response=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            let result = response.result
            if(result){
                for(let i = 0;i < result.length;i++){
                    result[i].detail = JSON.parse(result[i].detail);
                }
            }
            dispatch({type:ActionType.GET_BUSINESS_OPPORTUNITY_SUCCESS,payload:result});
        }).catch(function (error) {
            dispatch({type:ActionType.GET_BUSINESS_OPPORTUNITY_FAILED,payload:error});
        })
    }
}
export function getBusinessByCustom(custom_uuid,pageStart=0,pageSize=50) {
    let url = Service.dbServer + Service.apiVersion + "/business/getByCustom"
    return dispatch =>{
        dispatch({type:ActionType.GET_BUSINESS_OPPORTUNITY_BY_CUSTOM_START});
        $.ajax(preHandle(url),{
            method:'get',
            dataType:'json',
            data:{custom_uuid:custom_uuid,pageStart:pageStart,pageSize:pageSize}
        }).then(response=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            let result = response.result
            if(result){
                for(let i = 0;i < result.length;i++){
                    result[i].detail = JSON.parse(result[i].detail);
                }
            }
            dispatch({type:ActionType.GET_BUSINESS_OPPORTUNITY_BY_CUSTOM_SUCCESS,payload:result});
        }).catch(function (error) {
            dispatch({type:ActionType.GET_BUSINESS_OPPORTUNITY_BY_CUSTOM_FAILED,payload:error});
        })
    }
}
export function getBusinessStatusCount(flow_list,start_time,end_time) {
    let url = Service.dbServer + Service.apiVersion + "/business/getStatusCount" 
    return dispatch =>{
        dispatch({type:ActionType.GET_BUSINESS_OPPORTUNITY_STATUS_COUNT_START});
        $.ajax(preHandle(url),{
            method:'get',
            dataType:'json',
            data:({flow_list:JSON.stringify(flow_list),start_time:start_time,end_time:end_time})
        }).then(response=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            dispatch({type:ActionType.GET_BUSINESS_OPPORTUNITY_STATUS_COUNT_SUCCESS,payload:response.result});
        }).catch(function (error) {
            dispatch({type:ActionType.GET_BUSINESS_OPPORTUNITY_STATUS_COUNT_FAILED,payload:error});
        })
    }
}
export function getBusinessStatusAmount(flow_list,start_time,end_time) {
    let url = Service.dbServer + Service.apiVersion + "/business/getStatusAmount" 
    return dispatch =>{
        dispatch({type:ActionType.GET_BUSINESS_OPPORTUNITY_STATUS_AMOUNT_START});
        $.ajax(preHandle(url),{
            method:'GET',
            dataType:'json',
            data:({flow_list:JSON.stringify(flow_list),start_time:start_time,end_time:end_time})
        }).then(response=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            dispatch({type:ActionType.GET_BUSINESS_OPPORTUNITY_STATUS_AMOUNT_SUCCESS,payload:response.result});
        }).catch(function (error) {
            dispatch({type:ActionType.GET_BUSINESS_OPPORTUNITY_STATUS_AMOUNT_FAILED,payload:error});
        })
    }
}
export function updateBusiness(postData) {
    console.log(postData)
    let url = Service.dbServer + Service.apiVersion + "/business/update" 
    return dispatch =>{
        dispatch({type:ActionType.UPDATE_BUSINESS_OPPORTUNITY_START});
        $.ajax(preHandle(url),{
            method:'post',
            dataType:'json',
            contentType:'application/json; charset=utf-8',
            data:JSON.stringify(postData)
        }).then(response=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            message.info("更新成功！")
            dispatch({type:ActionType.UPDATE_BUSINESS_OPPORTUNITY_SUCCESS,payload:response.result});
            dispatch(getBusinessByUser());
            dispatch(getBusinessByCustom(JSON.parse(postData.detail).custom_uuid));
        }).catch(function (error) {
            message.error("更新失败!");
            dispatch({type:ActionType.UPDATE_BUSINESS_OPPORTUNITY_FAILED,payload:error});
        })
    }
}
export function delBusiness(postData) {
    let url = Service.dbServer + Service.apiVersion + "/business/del" 
    return dispatch =>{
        dispatch({type:ActionType.DELETE_BUSINESS_OPPORTUNITY_START});
        $.ajax(preHandle(url),{
            method:'delete',
            dataType:'json',
            data:({uuid:postData.uuid})
        }).then(response=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            message.info("删除成功！");
            dispatch({type:ActionType.DELETE_BUSINESS_OPPORTUNITY_SUCCESS,payload:response.result});
            dispatch(getBusinessByUser());
            dispatch(getBusinessByCustom(postData.detail.custom_uuid))
        }).catch(function (error) {
            message.error("删除失败！");
            dispatch({type:ActionType.DELETE_BUSINESS_OPPORTUNITY_FAILED,payload:error});
        })
    }
}
export function batchDelBusiness(uuid_list){
    let url = Service.dbServer + Service.apiVersion + "/business/batchDel" 
    return dispatch =>{
        $.ajax(preHandle(url),{
            method:'post',
            dataType:'json',
            data:({business_list:JSON.stringify(uuid_list),})
        }).then(response=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            if(200 === response.code){
                message.info("删除成功！");
                dispatch(getBusinessByUser());
            }
        }).catch(function (error) {
            message.error("删除失败！");
        })
    }
}
export function addBusinessFlow(postData){
    let url = Service.dbServer + Service.apiVersion + "/business/addFlow" 
    return dispatch =>{
        dispatch({type:ActionType.ADD_BUSINESS_FLOW_START});
        $.ajax(preHandle(url),{
            method:'post',
            contentType:'application/json; charset=utf-8',
            dataType:'json',
            data:JSON.stringify(postData)
        }).then(response=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            message.info("添加成功!");
            dispatch({type:ActionType.ADD_BUSINESS_FLOW_START,payload:response.result});
            dispatch(getBusinessFlow());
        }).catch(function (error) {
            message.error("添加失败!");
            dispatch({type:ActionType.ADD_BUSINESS_FLOW_FAILED,payload:error});
        })
    }
}
export function getBusinessFlow(callback){
    let url = Service.dbServer + Service.apiVersion + "/business/getFlow" 
    return dispatch =>{
        dispatch({type:ActionType.GET_BUSINESS_FLOW_START});
        $.ajax(preHandle(url),{
            method:'get',
            dataType:'json'
        }).then(response=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            let result = response.result
            if(result){
                for(let i = 0;i < result.length;i++){
                    result[i].detail = JSON.parse(result[i].detail);
                }
            }
            dispatch({type:ActionType.GET_BUSINESS_FLOW_SUCCESS,payload:result});
            if(typeof callback == "function") {callback();}
        }).catch(function(err){
            console.error(err);
            dispatch({type:ActionType.GET_BUSINESS_FLOW_FAILED,payload:err});
        })
    }
}
export function updateBusinessFlow(postData){
    let url = Service.dbServer + Service.apiVersion + "/business/updateFlow" 
    return dispatch=>{
        dispatch({type:ActionType.UPDATE_BUSINESS_FLOW_START});
        $.ajax(preHandle(url),{
            method:'post',
            //dataType:'json',
            contentType:'application/json; charset=utf-8',
            data:JSON.stringify(postData)
        }).then(response =>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            message.info("保存成功！")
            dispatch({type:ActionType.UPDATE_BUSINESS_FLOW_SUCCESS,payload:response.result});
            dispatch(getBusinessFlow());
        }).catch(function(err){
            message.error("保存失败！")
            dispatch({type:ActionType.UPDATE_BUSINESS_FLOW_FAILED,payload:err});
        })
    }
}
export function deleteBusinessFlow(uuid){
    let url = Service.dbServer + Service.apiVersion + "/business/delFlow" 
    return dispatch=>{
        dispatch({type:ActionType.DELETE_BUSINESS_FLOW_START});
        $.ajax(preHandle(url),{
            method:'delete',
            dataType:'json',
            data:({uuid:uuid})
        }).then(response =>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            message.success("删除成功！");
            dispatch({type:ActionType.DELETE_BUSINESS_FLOW_SUCCESS,payload:response.result});
            dispatch(getBusinessFlow())
        }).catch(function(error){
            message.error("删除失败！");
            dispatch({type:ActionType.DELETE_BUSINESS_FLOW_FAILED,payload:error});
        })
    }
}