import React from 'react';
import { Row, Col,Layout} from 'antd';
const {Content} = Layout;
class ReleaseNote extends React.Component{
    render(){
        return (<Content style={{maxHeight:'100%',overflow:'auto'}}>
             <Row>
                <h3 style={{color:'#1369BF'}}>V1.5.2(2022.08.20)</h3>
                <h4>添加文件上传功能;</h4>
                <h4>添加工作日志功能;</h4>
            </Row>
             <Row>
                <h3 style={{color:'#1369BF'}}>V1.5.1(2022.05.20)</h3>
                <h4>优化部门和职位设置;</h4>
                <h4>优化员工管理的相关功能;</h4>
            </Row>
            <Row>
                <h3 style={{color:'#1369BF'}}>V1.5.0(2022.04.20)</h3>
                <h4>优化客户管理相关功能;</h4>
                <h4>优化订单相关功能;</h4>
            </Row>
            <Row>
                <h3 style={{color:'#1369BF'}}>V1.4.0(2022.03.20)</h3>
                <h4>优化界面显示风格;</h4>
            </Row>
            <Row>
                <h3 style={{color:'#1369BF'}}>V1.3.0(2021.11.15)</h3>
                <h4>优化显示布局;</h4>
                <h4>优化后台数据管理;</h4>
            </Row>
            <Row>
                <h3 style={{color:'#1369BF'}}>V1.2.0(2021.10.30)</h3>
                <h4>优化业绩目标设置;</h4>
                <h4>优化商机流程设置;</h4>
            </Row>
            <Row>
                <h3 style={{color:'#1369BF'}}>V1.2.1(2021.10.03)</h3>
                <h4>优化统计相关功能;</h4>
                <h4>优化图标显示风格;</h4>
            </Row>
            <Row>
                <h3 style={{color:'#1369BF'}}>V1.2.0(2021.08.20)</h3>
                <h4>优化业绩目标设置;</h4>
                <h4>优化商机流程设置;</h4>
            </Row>
            <Row>
                <h3 style={{color:'#1369BF'}}>V1.1.0(2021.07.23)</h3>
                <h4>优化软件功能;</h4>
                <h4>优化用户体验;</h4>
            </Row>
            <Row>
                <Col>
                <h3 style={{color:'#1369BF'}}>V1.0.0(2021.06.23)</h3>
                <h4>发布多易CRM V1.0;</h4>
                </Col>
            </Row>
        </Content>)
    }
}
export default ReleaseNote