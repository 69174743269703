import React from 'react';
import {Table,Form,Input} from 'antd';

const EditableRow = ({ form, index, ...props }) => (
    <EditableContext.Provider value={form}>
        <tr {...props} />
    </EditableContext.Provider>
);
const EditableContext = React.createContext();
const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
    state = {editing: false,};

    toggleEdit = () => {
        const editing = !this.state.editing;
        this.setState({ editing }, () => {
            if (editing) {
                this.input.focus();
            }
        });
    };

    save = e => {
        const { record, handleSave } = this.props;
        this.form.validateFields((error, values) => {
          if (error && error[e.currentTarget.id]) {
            return;
          }
          this.toggleEdit();
          handleSave({ ...record, ...values });
        });
    };

  renderCell = form => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;
    return editing ? (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: `${title} is required.`,
            },
          ],
          initialValue: record[dataIndex],
        })(<Input ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} />)}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={this.toggleEdit}
      >
        {children}
      </div>
    );
  };

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}

class SaleGoalTable extends React.Component{
  constructor(props){
    super(props);
    this.state={};
  }

  handleSave = row => {
    const newData = [...this.props.dataSource];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {...item,...row});
    this.props.saveData(row);
  };
  render(){
    const components = {body: {row: EditableFormRow,cell: EditableCell,}};
    const childrenList = [];
    for(let i = 1;i <= 12;i++){
        childrenList.push([{title:'目标',dataIndex:'goal'+i,key:'goal'+i,editable: true,width:120},
                          {title:'已完成',dataIndex:'complete'+i,key:'complete'+i,width:120},
                          {title:'完成度',dataIndex:'percent'+i,key:'percent'+i,width:120}]);
    }
    const column = [{title:'名称',dataIndex:'name',key:'name',width:150,fixed:true},
                    {title:'一月',children:childrenList[0]},
                    {title:'二月',children:childrenList[1]},
                    {title:'三月',children:childrenList[2]},
                    {title:'四月',children:childrenList[3]},
                    {title:'五月',children:childrenList[4]},
                    {title:'六月',children:childrenList[5]},
                    {title:'七月',children:childrenList[6]},
                    {title:'八月',children:childrenList[7]},
                    {title:'九月',children:childrenList[8]},
                    {title:'十月',children:childrenList[9]},
                    {title:'十一月',children:childrenList[10]},
                    {title:'十二月',children:childrenList[11]}];
    const columns = column.map(col => {
        if(col.children){
            let children = col.children;
            for(let i = 0;i < children.length;i++){
                if(!children[i].editable){continue;}
                children[i] =  {
                    ...children[i],
                    onCell: record => ({
                    record,
                    editable: children[i].editable,
                    dataIndex: children[i].dataIndex,
                    title: children[i].title,
                    handleSave:this.handleSave
                    }),
                };
            }
        }
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: record => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title
            }),
        };
    });
    return(<Table components={components} rowClassName={() => 'editable-row'} dataSource={this.props.dataSource} columns={columns} bordered scroll={{x: 'calc(600px + 50%)'}} rowKey={(record)=>record.name}/>);
  }
}
export default SaleGoalTable