import React from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {Row,Col,Input,Form, Modal, DatePicker, Divider, Select, Tag, Button,Radio} from "antd";
import {connect} from "react-redux";
import {addTravel, deleteTravel, updateTravel, batchDeleteTravel} from "../../actions/travelDBTool";
import moment from "moment";
import {exportExcel} from './ExcelUtil';
import {TRAVEL_STATUS_TODO, travelStatusList} from "../../constants/constant";
import ExtendTable from "./ExtendTable";
import StaffChoose from "./StaffChoose";
import {account2Name, name2Account} from "../../utils/accountUtils";
import {StringCompareSort} from "../../utils/common";

const {confirm} = Modal;
const {Option} = Select;
const exportOptions = ['序号','出差事由', '起始时间', '结束时间', '出差地址', '事由','申请进度','审核人','抄送给','申请人','创建时间'];
const TravelForm = Form.create({name:'travel_modal'})(
    class extends React.Component{
    constructor(props) {
        super(props);
        this.state = {};
    }
    render(){
        let { visible, onCancel, onOk, form, values,title,mode } = this.props;
        let { getFieldDecorator } = form;
        let formItemLayout = {labelCol: {span:6}, wrapperCol: {span:16}};
        let currentTime = new Date();
        return(<Modal title={title} visible={visible} maskClosable={false}
                onOk={onOk} onCancel={onCancel} okText={2===mode?"关闭":"确定"} cancelText={"取消"}>
            <Form {...formItemLayout}>
                <Form.Item label={"标题"}>
                    {getFieldDecorator('travelTitle', {rules: [{required: true, message: '出差标题不能为空!'}],initialValue: mode ? values.travelTitle: ''})
                    (<Input placeholder={"例如:出差到XX客户公司"} disabled={1 < mode?true:false}/>)}
                </Form.Item>
                <Form.Item label={"事由"}>
                    {getFieldDecorator('content', {rules: [{required: false}],initialValue: mode?values.content: ''})
                    (<ReactQuill placeholder={"例如:出差具体安排"} readOnly={1 < mode?true:false}/>)}
                </Form.Item>
                <Form.Item label={"出差地址"}>
                    {getFieldDecorator('address', {rules: [{required: true, message: '地址不能为空!'}],initialValue: mode ? values.address: ''})
                    (<Input placeholder={"例如:XXX市XXX公司"} disabled={1 < mode?true:false}/>)}
                </Form.Item>
                <Form.Item label={"出发时间"}>
                    {getFieldDecorator('startTime',{rules: [{ required: true,message:'出发时间不能为空!' }],
                        initialValue:mode? moment(new Date(values.startTime), 'YYYY-MM-DD'):moment(currentTime, 'YYYY-MM-DD')})
                    (<DatePicker placeholder={"选择出发的时间"} showTime disabled={1 < mode?true:false}/>)}
                </Form.Item>
                <Form.Item label={"结束时间"}>
                    {getFieldDecorator('endTime', {rules: [{ required: true,message:'结束时间不能为空!' }],
                        initialValue:mode? moment(new Date(values.endTime), 'YYYY-MM-DD'):moment(currentTime, 'YYYY-MM-DD')})
                    (<DatePicker placeholder={"选择出差结束的时间"} showTime disabled={1 < mode?true:false}/>)}
                </Form.Item>
                <Form.Item label={"申请进度"} hidden={!mode}>
                    {getFieldDecorator('status', {rules: [{ required: true,message:'申请进度不能为空!' }],
                        initialValue:mode?values.status:TRAVEL_STATUS_TODO})
                    (<Select disabled={2 === mode?true:false}>
                        {travelStatusList.map(item=><Option value={item} key={item}>{item}</Option>)}
                    </Select>)}
                </Form.Item>
                <Form.Item label={"审核人"}>
                    {getFieldDecorator('manager', {rules: [{ required: true,message:'审核人不能为空!' }],initialValue:mode?values.manager:""})
                        (<StaffChoose mode={0} disabled={1 < mode?true:false}/>)}
                </Form.Item>
                <Form.Item label={"抄送给"}>
                    {getFieldDecorator('assigner', {initialValue: values.assigner ? values.assigner : ''})
                    (<StaffChoose mode={1} disabled={1 < mode?true:false}/>)}
                </Form.Item>
            </Form>
        </Modal>);
    }
})
function mapStateToProps(state) {
    return {...state.travel}
}
function mapDispatchToProps(dispatch){
    return {onAddTravel:(postData)=>dispatch(addTravel(postData)),
            onEditTravel:(postData)=>dispatch(updateTravel(postData)),
            onDeleteTravel:(uuid)=>dispatch(deleteTravel(uuid)),
            onBatchDelete:(uuid_list)=>dispatch(batchDeleteTravel(uuid_list))
        }
}
class TravelTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  showModal:false,
                        modalMode:0,
                        modalTitle:'新增出差申请',
                        exportColumns:exportOptions,
                        exportType:'xlsx',
                        modalValues:{ travelTitle:'',
                                      content:'',
                                      uuid:'',
                                      startTime:'',
                                      endTime:'',
                                      address:'',
                                      status:'',
                                      manager:'',
                                      assigner:'',
                                      creator:'',
                                      createTime:''}}
        this.showContent = this.showContent.bind(this);
        this.updateModalValues = this.updateModalValues.bind(this);
    }
    componentDidMount(){}
    saveFormRef = formRef => {this.formRef = formRef;}
    updateModalValues(values){
        if(!values){
            this.setState({modalValues:{ travelTitle:'',
            content:'',
            uuid:'',
            startTime:'',
            endTime:'',
            address:'',
            status:'',
            manager:'',
            assigner:'',
            creator:'',
            createTime:''}});
            return;
        }
        let detail = values.detail
        this.setState({modalValues:{
            uuid:values.uuid,
            travelTitle:values.title,
            content:detail.content?detail.content:'',
            startTime:detail.start_time?detail.start_time:"",
            endTime:detail.end_time?detail.end_time:'',
            address:detail.address?detail.address:'',
            status:values.status,
            assigner:account2Name(detail.assigner_account),
            manager:account2Name(detail.manager_account),
            creator:account2Name(values.creator_account),
            createTime:values.create_time
            }});
    }
    showContent(record){
        for (let i = 0;i < this.props.get_travel_result.length;i++){
            let item = this.props.get_travel_result[i];
            if(item.uuid === record.uuid){
                this.updateModalValues(item);
                this.setState({showModal:true,modalMode:2,modalTitle:'查看出差申请'});
                return;
            }
        }
    }
    handleOnEdit=(record)=>{
        for (let i = 0;i < this.props.get_travel_result.length;i++){
            let item = this.props.get_travel_result[i];
            if(item.uuid === record.uuid){
                this.updateModalValues(item);
                if(this.props.editEnable){
                    this.setState({showModal:true,modalMode:1,modalTitle:'更新出差申请'});
                }
                else{
                    this.setState({showModal:true,modalMode:3,modalTitle:'更新出差申请'});
                }
                return;
            }
        }
    }
    handleDelete =(uuid)=>{
        confirm({
            content: '您确定要删除该待办出差申请?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk:()=> {
                this.props.onDeleteTravel(uuid)
            },
            onCancel() {console.log('Cancel');},
        })
    }
    OnChangeColumn=(checkedValues)=>{
        console.log(checkedValues);
        this.setState({exportColumns:checkedValues});
    }
    OnChangeExportFormat=(e)=>{
        this.setState({exportType:e.target.value})
    }
    OnClickExport(dataSource){
        for (let i = 0;i < dataSource.length;i++){
            for (let key in dataSource[i]){
                if (-1 === this.state.exportColumns.indexOf(key)){
                    delete dataSource[i][key];
                    delete dataSource[i].key;
                }
            }
        }
        exportExcel(dataSource,this.state.exportType,'出差列表');
    }
    handleEmptySelect=(uuid_list)=>{
        this.props.onBatchDelete(uuid_list);
    }
    handleAddOnClick=()=>{
        this.updateModalValues();
        this.setState({showModal:true,modalMode:0,modalTitle:'新增出差申请'});
    }
    handleCancel=()=>{
        const { form } = this.formRef.props;
        form.resetFields();
        this.setState({showModal:false})
    }
    handleSubmit=()=>{
        if(2 === this.state.modalMode){
            this.setState({ showModal: false });
            return;
        }
        const { form } = this.formRef.props;
        form.validateFields((err, fromValues) => {
            if(err){return;}
            let manager_account = name2Account(fromValues.manager);
            let assigner_account = name2Account(fromValues.assigner);
            if(0 >= fromValues.manager.length){
                manager_account = "";
            }
            if(0 >= fromValues.assigner.length){
                assigner_account = ""
            }
            let current = new Date();
            let detail = {
                address:fromValues.address,
                content:fromValues.content,
                manager_account:manager_account,
                assigner_account:assigner_account,
                start_time:Date.parse(fromValues.startTime),
                end_time:Date.parse(fromValues.endTime),
            }
            let postData = {
                title:fromValues.travelTitle,
                enterprise_uuid:localStorage.getItem("enterprise_uuid"),
                type:0,
                detail:JSON.stringify(detail),
                creator_account:localStorage.getItem('account'),
                create_time:Date.parse(current)
            };
            if(this.state.modalMode){
                postData['uuid'] = this.state.modalValues.uuid;
                postData['status'] = fromValues.status;
                this.props.onEditTravel(postData);
            }
            else{
                let uuid = Date.parse(new Date())/1000+(Math.round(Math.random() * (9000))+999);
                postData['status'] = TRAVEL_STATUS_TODO;
                postData['uuid'] = uuid;
                this.props.onAddTravel(postData)
            }
            let modalValues = {
                uuid:postData['uuid'],
                title:fromValues.travelTitle,
                type:0,
                status:postData['status'],
                detail:detail,
                creator_account:localStorage.getItem('account'),
                create_time:Date.parse(current)
            }
            this.updateModalValues(modalValues);
            this.setState({ showModal: false });
            form.resetFields();
        });
    }
    renderExportModal=(dataSource,exportDataSource)=>{
        return (<Modal title="导出结果选项" visible={this.state.showExportModal} width={1000}
                        cancelText={"取消"} onCancel={()=>{this.setState({showExportModal:false})}}
                        okText={"导出"} onOk={()=>{this.OnClickExport(exportDataSource)}} >
                <Radio.Group onChange={this.OnChangeExportFormat} defaultValue={'xlsx'}>
                    <Radio value={'xlsx'}>Excel(.xlsx)</Radio>
                    <Radio value={'xls'}>Excel(.xls)</Radio>
                    <Radio value={'csv'}>CSV</Radio>
                    <Radio value={'txt'}>文本(TXT)</Radio>
                </Radio.Group>
        </Modal>);
    }
    renderStatusStyleText(text){
        const statusColor = ["#fa0","#2db7f5","red","#87d068"];
        return (<Tag color={statusColor[travelStatusList.indexOf(text)]}>{text}</Tag>)
    }
    renderSetting() {
        return(<TravelForm wrappedComponentRef={this.saveFormRef} visible={this.state.showModal} 
                values={this.state.modalValues} title={this.state.modalTitle} mode={this.state.modalMode}
                onCancel={this.handleCancel} onOk={this.handleSubmit}/>);
    }
    renderAction(record){
        if(this.props.editEnable){
            return (<Row>
                <Col span={8}><Button size='small' type="link" onClick={()=>this.showContent(record)}>查看</Button></Col>
                <Col span={8}><Button size='small' type="link" onClick={()=>this.handleOnEdit(record)}>编辑</Button></Col>
                <Col span={8}><Button size='small' type="link" style={{color:'red'}} onClick={()=>this.handleDelete(record.uuid)}>删除</Button></Col>
            </Row>)
        }else{
            if(-1 !== record.manager.indexOf(localStorage.getItem('name'))){
                return (<Row>
                    <Col span={8}><Button size='small' type="link" onClick={()=>this.showContent(record)}>查看</Button></Col>
                    <Col span={8}><Button size='small' type="link" onClick={()=>this.handleOnEdit(record)}>编辑</Button></Col>
                    </Row>)
            }
            else{
                return (<Row><Button size='small' type="link" onClick={()=>this.showContent(record)}>查看</Button></Row>)
            }
        }
    }
    renderTable(){
        const travelStatus = [];
        travelStatusList.forEach((item)=>{travelStatus.push({text:item,value:item})});
        let showColumns = [{title:"出差事由",dataIndex:'title',key:'title',isSearch:true},
                {title:"起始时间",dataIndex:'startTime',key:'startTime',width:120,ellipsis:true,sorter: (a, b) => (a.startTime) - (b.startTime),
                    render:(text, record) =><span>{text}</span>},
                {title:"结束时间",dataIndex:'endTime',key:'endTime',width:120,ellipsis:true,isSearch:true,sorter: (a, b) => Date.parse(a.endTime) - Date.parse(b.endTime),
                        render:(text, record) =><span>{text}</span>},
                {title:"出差地址",dataIndex:'address',key:'address',width:120,ellipsis:true,isSearch:true},
                {title:"申请进度",dataIndex: 'status',key:'status',isSearch:true,filters:travelStatus,
                    onFilter: (value, record) => record.status.indexOf(value) === 0,sorter: (a, b) => StringCompareSort(a.status,b.status),
                    render:(text,record)=>this.renderStatusStyleText(text)},
                {title:"审核人",dataIndex:'manager',key:'manager',isSearch:true,sorter:(a,b)=>StringCompareSort(a.manager,b.manager),
                    render:(text, record) =><Row>{text instanceof Array?text.map((tag,index)=>{
                            const tagElem = (<Tag key={tag} color={"geekblue"}>{tag}</Tag>);
                            return (tagElem);
                        }):text?(<Tag key={text} color={"geekblue"}>{text}</Tag>):null}
                    </Row>},
                {title:"抄送给",dataIndex:'assigner',key:'assigner',isSearch:true,sorter:(a,b)=>StringCompareSort(a.creator,b.creator),
                    render:(text, record) =><Row>
                        {text instanceof Array?text.map((tag,index)=>{const tagElem = (<Tag key={tag} color={"geekblue"}>{tag}</Tag>);
                            return (tagElem);}):text?(<Tag key={text} color={"geekblue"}>{text}</Tag>):null}
                    </Row>},
                {title:"申请人",dataIndex:'creator',key:'creator',isSearch:true,sorter:(a,b)=>StringCompareSort(a.creator,b.creator),
                    render:(text, record) =><Row>
                        {text instanceof Array?text.map((tag,index)=>{const tagElem = (<Tag key={tag} color={"geekblue"}>{tag}</Tag>);
                            return (tagElem);}):text?(<Tag key={text} color={"geekblue"}>{text}</Tag>):null}
                    </Row>},
                {title:"创建时间",dataIndex:'createTime',key:'createTime',width:120,ellipsis:true,sorter: (a, b) => Date.parse(a.createTime) - Date.parse(b.createTime),
                    render:(text, record) => <span>{text}</span>},
                {title:'操作',key:'actions',width:150,fixed: 'right', render: (text, record) =>this.renderAction(record)}];
        let dataSource = [];
        let exportDataSource = [];
        for (let i = 0;i < this.props.dataSource.length;i++){
            let record = this.props.dataSource[i];
            let detail = record.detail;
            let manager = account2Name(detail.manager_account)?account2Name(detail.manager_account):"";
            let assigner = account2Name(detail.assigner_account)?account2Name(detail.assigner_account):"";
            let creator = account2Name(record.creator_account);
            dataSource.push({
                index:i+1,
                uuid:record.uuid,
                title:record.title,
                startTime:moment(new Date(detail.start_time)).format('YYYY-MM-DD HH:mm:ss'),
                endTime:moment(new Date(detail.end_time)).format('YYYY-MM-DD HH:mm:ss'),
                address:detail.address,
                content:detail.content,
                status:record.status,
                manager:manager,
                assigner:assigner,
                creator:creator,
                createTime:moment(new Date(record.create_time)).format('YYYY-MM-DD HH:mm:ss')
            });
            exportDataSource.push({ '序号':i+1,
                                    '出差事由':record.title,
                                    '起始时间':moment(new Date(detail.start_time)).format('YYYY-MM-DD HH:mm:ss'),
                                    '结束时间':moment(new Date(detail.end_time)).format('YYYY-MM-DD HH:mm:ss'),
                                    '出差地址':detail.address,
                                    '事由':detail.content,
                                    '申请进度':record.status,
                                    '审核人':manager,
                                    '抄送给':assigner,
                                    '申请人':creator,
                                    '创建时间':moment(new Date(record.create_time)).format('YYYY-MM-DD HH:mm:ss')
                })
        }
        return(<Row>
                    {this.renderExportModal(dataSource,exportDataSource)}
                    <ExtendTable handleAddOnClick={this.handleAddOnClick} onBatchDelete={this.handleEmptySelect} 
                        customColumnsEnable={this.props.customColumnsEnable} tableKey={this.props.tableKey} columns={showColumns} 
                        dataSource={dataSource} rowKey={record=>record.uuid}
                        exportEnable={this.props.exportEnable} exportTitle={"导出出差列表"} exportClick={()=>this.setState({showExportModal:true})}/>
                </Row>);
    }
    render() {
        return (<Row>
                    <Row>{this.renderTable()}</Row>
                    <Divider/>
                    {this.renderSetting()}
                </Row>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(TravelTable);