import React from 'react';

class SaleStatistic extends React.Component {
    constructor(props){
        super(props);
        this.state = {};
    }
    render(){
        return (<div>the user SaleStatistic page</div>);
    }
}
export default SaleStatistic;