import {ActionType} from "../constants/actionTypes";

const workLogReducer = (state = {},action)=>{
    switch (action.type) {
        case ActionType.ADD_WORKLOG_START:
            return {...state,add_workLog:false}
        case ActionType.ADD_WORKLOG_SUCCESS:
            return {...state,add_workLog: true,add_workLog_result:action.payload}
        case ActionType.ADD_WORKLOG_FAILED:
            return {...state,add_workLog: false,add_workLog_errors:action.error?action.payload.errors : null}

        case ActionType.GET_WORKLOG_START:
            return {...state,get_workLog:false}
        case ActionType.GET_WORKLOG_SUCCESS:
            return {...state,get_workLog: true,get_workLog_result:action.payload}
        case ActionType.GET_WORKLOG_FAILED:
            return {...state,get_workLog: false,get_workLog_errors:action.error?action.payload.errors : null}


        case ActionType.UPDATE_WORKLOG_START:
            return {...state,update_workLog:false}
        case ActionType.UPDATE_WORKLOG_SUCCESS:
            return {...state,update_workLog: true,update_workLog_result:action.payload}
        case ActionType.UPDATE_WORKLOG_FAILED:
            return {...state,update_workLog: false,update_workLog_errors:action.error?action.payload.errors : null}

        case ActionType.DELETE_WORKLOG_START:
            return {...state,delete_workLog:false}
        case ActionType.DELETE_WORKLOG_SUCCESS:
            return {...state,delete_workLog: true,delete_workLog_result:action.payload}
        case ActionType.DELETE_WORKLOG_FAILED:
            return {...state,delete_workLog: false,delete_workLog_errors:action.error?action.payload.errors : null}

        default:
            return {...state};
    }
}
export default workLogReducer;