import {ActionType} from "../constants/actionTypes"

const todoWorkReducer = (state={},action)=>{
    switch (action.type) {
        case ActionType.ADD_TODO_WORK_START:
            return {...state,add_todo:false};
        case ActionType.ADD_TODO_WORK_SUCCESS:
            return {...state,add_todo:true,add_todo_result:action.payload};
        case ActionType.ADD_TODO_WORK_FAILED:
            return {...state,add_todo: false,add_todo_errors:action.payload?action.payload:null};

        case ActionType.GET_TODO_WORK_START:
            return {...state,get_todo:false};
        case ActionType.GET_TODO_WORK_FAILED:
            return {...state,get_todo: false,get_todo_errors:action.payload?action.payload:null};
        case ActionType.GET_TODO_WORK_SUCCESS:
            return {...state,get_todo: true,get_todo_result:action.payload};

        case ActionType.UPDATE_TODO_WORK_START:
            return {...state,update_todo:false};
        case ActionType.UPDATE_TODO_WORK_FAILED:
            return {...state,update_todo:false,update_todo_errors:action.payload?action.payload:null};
        case ActionType.UPDATE_TODO_WORK_SUCCESS:
            return {...state,update_todo:true,update_todo_result:action.payload}

        case ActionType.DELETE_TODO_WORK_START:
            return {...state,delete_todo:false}
        case ActionType.DELETE_TODO_WORK_FAILED:
            return {...state,delete_todo:false,delete_todo_errors:action.payload?action.payload:null}
        case ActionType.DELETE_TODO_WORK_SUCCESS:
            return {...state,delete_todo:true,delete_todo_result:action.payload};

        default:
            return {...state}
    }
}
export default todoWorkReducer;