export const ORDER_STATUS_UN_FOLLOW = "待处理";
export const ORDER_STATUS_FOLLOWING = "交易中";
export const ORDER_STATUS_SUCCESS = "已完成";
export const ORDER_STATUS_CLOSED = "已失效";
export const orderStatusList = [ORDER_STATUS_UN_FOLLOW, ORDER_STATUS_FOLLOWING, ORDER_STATUS_SUCCESS, ORDER_STATUS_CLOSED];


export const ORDER_LEVEL_NORMAL = '普通';
export const ORDER_LEVEL_VIP = 'VIP';
export const ORDER_LEVEL_URGENT = '加急';
export const orderLevelList = [ORDER_LEVEL_NORMAL, ORDER_LEVEL_VIP, ORDER_LEVEL_URGENT];


export const PRODUCT_STATUS_NEW = "待上架";
export const PRODUCT_STATUS_ON_SALE = "出售中";
export const PRODUCT_STATUS_UN_SALE = "已下架";
export const productStatusList = [PRODUCT_STATUS_NEW, PRODUCT_STATUS_ON_SALE, PRODUCT_STATUS_UN_SALE];


export const CUSTOM_STATUS_UN_FOLLOW = "未接待";
export const CUSTOM_STATUS_CONTACT = "初步接触";
export const CUSTOM_STATUS_FOLLOW = "联系中";
export const CUSTOM_STATUS_TALKING = "价格谈判";
export const CUSTOM_STATUS_SUCCESS = "已成交";
export const CUSTOM_STATUS_FAILED = "已失败";
export const customStatusList = [CUSTOM_STATUS_UN_FOLLOW, CUSTOM_STATUS_CONTACT,CUSTOM_STATUS_FOLLOW,CUSTOM_STATUS_TALKING, CUSTOM_STATUS_SUCCESS, CUSTOM_STATUS_FAILED];

export const customSourceList = ["注册会员","朋友介绍","手工录入","行业展会","电子商务","网络搜索","自媒体","线下广告"];

export const CUSTOM_TYPE_PERSONAL = "个人客户";
export const CUSTOM_TYPE_ENTERPRISE = "企业客户";
export const customTypeList = [CUSTOM_TYPE_PERSONAL, CUSTOM_TYPE_ENTERPRISE];


export const CUSTOM_LEVEL_NORMAL = "普通客户";
export const CUSTOM_LEVEL_DEVELOPING = "发展客户";
export const CUSTOM_LEVEL_VIP = "VIP客户";
export const customLevelList = [CUSTOM_LEVEL_NORMAL,CUSTOM_LEVEL_DEVELOPING, CUSTOM_LEVEL_VIP];

export const TODO_WORK_STATUS_TODO = '待办/未开始';
export const TODO_WORK_STATUS_PROGRESS = '处理中';
export const TODO_WORK_STATUS_CLOSE = '关闭/完成';
export const todoWorkStatusList = [TODO_WORK_STATUS_TODO, TODO_WORK_STATUS_PROGRESS, TODO_WORK_STATUS_CLOSE];

export const LEAVE_STATUS_TODO = "未处理/未开始";
export const LEAVE_STATUS_PROGRESS = '处理中';
export const LEAVE_STATUS_FAILED = '未批准/拒绝';
export const LEAVE_STATUS_SUCCESS = '已批准/同意'
export const leaveStatusList = [LEAVE_STATUS_TODO,LEAVE_STATUS_PROGRESS,LEAVE_STATUS_SUCCESS,LEAVE_STATUS_FAILED];

export const TRAVEL_STATUS_TODO = "未处理/未开始";
export const TRAVEL_STATUS_PROGRESS = '处理中';
export const TRAVEL_STATUS_FAILED = '未批准/拒绝';
export const TRAVEL_STATUS_SUCCESS = '已批准/同意';
export const travelStatusList = [TRAVEL_STATUS_TODO,TRAVEL_STATUS_PROGRESS,TRAVEL_STATUS_FAILED,TRAVEL_STATUS_SUCCESS];

export const PURCHASE_STATUS_UN_START = '未开始/待处理';
export const PURCHASE_STATUS_DOING = '处理中';
export const PURCHASE_STATUS_SUCCESS = '已完成/已成功';
export const PURCHASE_STATUS_FAILED = '已失效';
export const purchaseStatusList = [PURCHASE_STATUS_UN_START,PURCHASE_STATUS_DOING, PURCHASE_STATUS_SUCCESS,PURCHASE_STATUS_FAILED];

export const PURCHASE_LEVEL_NORMAL = "一般/普通";
export const PURCHASE_LEVEL_IMPORT = "加急/重要";
export const purchaseLevelList = [PURCHASE_LEVEL_NORMAL,PURCHASE_LEVEL_IMPORT]

export const SHIP_STATUS_UN_START = '未开始/待处理';
export const SHIP_STATUS_DOING = '处理中';
export const SHIP_STATUS_SUCCESS = '已完成/已成功';
export const SHIP_STATUS_FAILED = '已失效';
export const shipStatusList =  [SHIP_STATUS_UN_START, SHIP_STATUS_DOING,SHIP_STATUS_SUCCESS,SHIP_STATUS_FAILED];

export const EXPENSE_STATUS_UN_START = '未开始/待处理';
export const EXPENSE_STATUS_DOING = '处理中';
export const EXPENSE_STATUS_SUCCESS = '已完成/已报销';
export const EXPENSE_STATUS_FAILED = '已失效/未报销';
export const expenseStatusList = [EXPENSE_STATUS_UN_START, EXPENSE_STATUS_DOING, EXPENSE_STATUS_SUCCESS, EXPENSE_STATUS_FAILED];

export const invoiceList = [{value:0,text:"否"}, {value:1,text: "是"}];

export const WORK_ORDER_TYPE_COMPLAINT = "投诉工单";
export const WORK_ORDER_TYPE_AFTER_SALE = "售后工单";
export const WORK_ORDER_TYPE_OTHER = "其他类型";
export const workOrderTypeList = [WORK_ORDER_TYPE_COMPLAINT,WORK_ORDER_TYPE_AFTER_SALE,WORK_ORDER_TYPE_OTHER];

export const workOrderStatusList = ["待处理/未处理","处理中","已处理/已关闭","已废弃"];
export const workOrderLevelList = ["低优先级","普通/一般","优先处理"];

export const USER_STATUS_NORMAL = 1;
export const USER_STATUS_DISABLE = 0;
export const userStatusList = [{value:USER_STATUS_NORMAL,text:"正常"},{value:USER_STATUS_DISABLE,text:"禁用"}];

export const USER_LEVEL_MEMBER = 1;
export const USER_LEVEL_LEADER = 10;
export const USER_LEVEL_ADMIN  = 100;
export const userLevelList = [{value:USER_LEVEL_MEMBER,text:"普通成员"},{value:USER_LEVEL_LEADER,text:"领导成员"},{value: USER_LEVEL_ADMIN,text: "超级管理员"}];

export const ENTERPRISE_LEVEL = 1;
export const enterpriseLevelList = [{value:ENTERPRISE_LEVEL,text:"基础版"}];

export const followUpStatusList = ["前期接触","电话联系","客户回访","服务跟进中","客户询价","商务洽谈","已下单","售后服务","服务结束"];
export const businessOpportunitySourceList = ["网上搜索结果","客户主动询价","广告宣传","客户介绍","展会","招标"];
export const businessOpportunityPercentList = ["10","20","30","40","50","60","70","80","90","100","0%"];
export const businessOpportunityStatusList = ["接触客户","需求确定","方案报价","商务谈判","已下单/成交","已失败/丢失"];
export const businessOpportunityTypeList = ["一般商机","重要商机"];
