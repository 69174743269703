import React from 'react';
import {connect} from "react-redux";
import { Row} from 'antd';

function mapStateToProps(state) {
    return {...state.staff,
        ...state.enterprise
    };
}
function mapDispatchToProps(dispatch) {
    return {onGetStaff:()=>dispatch()};
}
class RoleManage extends React.Component{
    constructor(props){
        super(props);
        this.state = {};
    }
    componentDidMount(){}
    render(){
        return (<Row>role</Row>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(RoleManage);