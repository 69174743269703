import React from 'react';
import {Row, Col, Tabs, Anchor, Button, Divider, Card} from 'antd';
import DMSCard from '../controls/DMSCard';
import {ScanOutlined,AlipayCircleOutlined,BankOutlined} from '@ant-design/icons';
const { TabPane } = Tabs;
export default class Payment extends React.Component{
    constructor(props){
        super(props);
        this.state={
            versionCard:1,
            versionText:"基础版",
            userCount:5,
            userPrice:880,
            accountTime:1,
            accountTimeText:"共一年",
            payWayCard:1
        };
    }
    componentDidMount(){}
    renderPayment=()=>{
        return(<Tabs defaultActiveKey="1" style={{height:'70vh',overflowY:'auto',overflowX:'hidden'}}>
            <TabPane tab="升级/续费" key={1}>
                <Row gutter={16}>
                    <Col span={2} style={{textAlign:"right"}}>
                        <span>版本选择：</span>
                    </Col>
                    <Col span={4}>
                        <DMSCard title="基础版" value={1} select={this.state.versionCard}
                                onClick={()=>{this.setState({versionCard:1,versionText:"基础版"})}}>
                            <p>1.适合于功能需求单一，小公司</p>
                            <p>2.基础功能简单好用</p>
                        </DMSCard>
                    </Col>
                    {/*<Col span={6}>
                        <DMSCard title="高级版" index='2' select={this.state.versionCard}
                                onClick={()=>{this.setState({versionCard:2,versionText:"高级版"})}}>
                            <p>1.适合于功能需求多，业务复杂的小公司</p>
                            <p>2.开放部分高级功能</p>
                        </DMSCard>
                    </Col>
                    <Col span={6}>
                        <DMSCard title="旗舰版" index='3' select={this.state.versionCard}
                                onClick={()=>{this.setState({versionCard:3,versionText:"旗舰版"})}}>
                            <p>1.适合于功能需求单多，业务复杂的大公司</p>
                            <p>2.开通高级功能</p>
                        </DMSCard>
                    </Col>*/}
                </Row>
                <Divider/>
                <Row gutter={[2,10]}>
                    <Col span={2} style={{textAlign:"right"}}>
                        <span>支持用户数：</span>
                    </Col>
                    <Col span={3}>
                        <DMSCard title="3人" value={3} select={this.state.userCount} onClick={()=>{this.setState({userCount:3,userPrice:580})}}>
                            <span style={{color:'red'}}>580元/年</span>
                        </DMSCard>
                    </Col>
                    <Col span={3}>
                        <DMSCard title="5人" value={5} select={this.state.userCount} onClick={()=>{this.setState({userCount:5,userPrice:880})}}>
                            <span style={{color:'red'}}>880元/年</span>
                        </DMSCard>
                    </Col>
                    <Col span={3}>
                        <DMSCard title="8人" value={8} select={this.state.userCount} onClick={()=>{this.setState({userCount:8,userPrice:1480})}}>
                            <span style={{color:'red'}}>1480元/年</span>
                        </DMSCard>
                    </Col>
                    <Col span={3}>
                        <DMSCard title="10人" value={10} select={this.state.userCount} onClick={()=>{this.setState({userCount:10,userPrice:1880})}}>
                            <span style={{color:'red'}}>1880元/年</span>
                        </DMSCard>
                    </Col>
                    <Col span={3}>
                        <DMSCard title="13人" value={13} select={this.state.userCount} onClick={()=>{this.setState({userCount:13,userPrice:2380})}}>
                            <span style={{color:'red'}}>2380元/年</span>
                        </DMSCard>
                    </Col>
                    <Col span={3}>
                        <DMSCard title="15人" value={15} select={this.state.userCount} onClick={()=>{this.setState({userCount:15,userPrice:2780})}}>
                            <span style={{color:'red'}}>2780元/年</span>
                        </DMSCard>
                    </Col>
                    <Col span={3}>
                        <DMSCard title="18人" value={18} select={this.state.userCount} onClick={()=>{this.setState({userCount:18,userPrice:3480})}}>
                            <span style={{color:'red'}}>3480元/年</span>
                        </DMSCard>
                    </Col>
                </Row>
                <br/>
                <Row gutter={[2,10]}>
                    <Col span={3} offset={2}>
                        <DMSCard title="20人" value={20} select={this.state.userCount} onClick={()=>{this.setState({userCount:20,userPrice:3880})}}>
                            <span style={{color:'red'}}>3880元/年</span>
                        </DMSCard>
                    </Col>
                    <Col span={3}>
                        <DMSCard title="25人" value={25} select={this.state.userCount} onClick={()=>{this.setState({userCount:25,userPrice:4680})}}>
                            <span style={{color:'red'}}>4680元/年</span>
                        </DMSCard>
                    </Col>
                    <Col span={3}>
                        <DMSCard title="30人" value={30} select={this.state.userCount} onClick={()=>{this.setState({userCount:30,userPrice:5580})}}>
                            <span style={{color:'red'}}>5580元/年</span>
                        </DMSCard>
                    </Col>
                    <Col span={3}>
                        <DMSCard title="35人" value={35} select={this.state.userCount} onClick={()=>{this.setState({userCount:35,userPrice:6200})}}>
                            <span style={{color:'red'}}>6200元/年</span>
                        </DMSCard>
                    </Col>
                    <Col span={3}>
                        <DMSCard title="40人" value={40} select={this.state.userCount} onClick={()=>{this.setState({userCount:40,userPrice:6980})}}>
                            <span style={{color:'red'}}>6980元/年</span>
                        </DMSCard>
                    </Col>
                    <Col span={3}>
                        <DMSCard title="50人" value={50} select={this.state.userCount} onClick={()=>{this.setState({userCount:50,userPrice:8280})}}>
                            <span style={{color:'red'}}>8280元/年</span>
                        </DMSCard>
                    </Col>
                    <Col span={3}>
                        <DMSCard title="100人以上" value={100} select={this.state.userCount} onClick={()=>{this.setState({userCount:100,userPrice:8680})}}>
                            <span style={{color:'red'}}>8680元/年</span>
                        </DMSCard>
                    </Col>
                </Row>
                <Divider/>
                <Row gutter={10}>
                    <Col span={2} style={{textAlign:"right"}}>
                        <span>选择使用期：</span>
                    </Col>
                    <Col span={4}>
                        <DMSCard title="1年" value={1} select={this.state.accountTime}
                            onClick={()=>{this.setState({accountTime:1,accountTimeText:"共1年"})}}>
                            <span style={{color:'red'}}>共1年</span>
                        </DMSCard>
                    </Col>
                    <Col span={4}>
                        <DMSCard title="2年" value={1.8} select={this.state.accountTime}
                            onClick={()=>{this.setState({accountTime:1.8,accountTimeText:"共2年(9折)"})}}>
                            <span style={{color:'red'}}>共2年(9折)</span>
                        </DMSCard>
                    </Col>
                    <Col span={4}>
                        <DMSCard title="3年" value={2.4} select={this.state.accountTime}
                            onClick={()=>{this.setState({accountTime:2.4,accountTimeText:"共3年(8折)"})}}>
                            <span style={{color:'red'}}>共3年(8折)</span>
                        </DMSCard>
                    </Col>
                    <Col span={4}>
                        <DMSCard title="5年" value={3} select={this.state.accountTime}
                            onClick={()=>{this.setState({accountTime:3,accountTimeText:"共5年(5折)"})}}>
                            <span style={{color:'red'}}>共5年(6折)</span>
                        </DMSCard>
                    </Col>
                </Row>
                <Divider/>
                <Row gutter={16}>
                    <Col span={2} style={{textAlign:"right"}}><span>支付方式：</span></Col>
                    <Col span={6}>
                        <Card title={<div style={{fontSize:'18px',textAlign:'center',}}><ScanOutlined/> &nbsp;扫码支付</div>}>
                            <img alt="alipay" style={{height:'100%',width:'100%'}} src={require('../../assets/images/alipayment.jpg')}/>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card index='2' select={this.state.payWayCard} onClick={()=>{this.setState({payWayCard:2})}}
                            title={<div style={{fontSize:'18px',textAlign:'center'}}><BankOutlined/>&nbsp;对公转账</div>}>
                            <Row>
                                <p>开户名：<span style={{fontSize:'15px',color:'#1777FF'}}>上海零多科技有限公司</span></p>
                                <p>账&nbsp;&nbsp;&nbsp;号：<span style={{fontSize:'15px',color:'#1777FF'}}>31050180363700000706</span></p>
                                <p>开户行：<span style={{fontSize:'15px',color:'#1777FF'}}>中国建设银行股份有限公司上海松江新城支行</span></p>
                            </Row>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card index='3' select={this.state.payWayCard} onClick={()=>{this.setState({payWayCard:3})}}
                            title={<div style={{fontSize:'18px',textAlign:'center',}}>公司财务账户</div>}>
                            <Row>
                                <p>开户名:</p>
                                <p>账号:</p>
                                <p>开户行:</p>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <Divider/>
                <Row gutter={16}>
                    <Col span={8} offset={2}>
                        <Card title={<div style={{fontSize:'24px',textAlign:'center',}}>
                            <AlipayCircleOutlined style={{color:'#019FE8'}}/> &nbsp;支付宝在线支付<span style={{color:'red'}}>(暂未开通)</span>
                        </div>}>
                            <span>支付完成后系统自动开通，无需人工干预</span>
                        </Card>
                    </Col>
                </Row>
                <Divider/>
                <Row style={{height:'100px'}}>
                    <Col span={2} style={{textAlign:"right"}}>
                        <span>注意事项：</span>
                    </Col>
                    <Col span={16}>
                        <p style={{color:'RGB(248,162,15)'}}>1、支付宝在线支付成功后系统将自动开通账户，无须人工干预。</p>
                        <p style={{color:'red'}}>2、银行转账或扫码支付成功后须联系客服为您手动开通账户。</p>
                        <p style={{color:'#1E9FFF'}}>3、附件存储空间是有限制的（如上传word、Excel、视频等文件）与您购买的人数对应，比如您购买10人版的，则总上传文档存储空间就是10G。如超过可以单独购买附件存储空间价格600元/10G/年。</p>
                        <p>4、升级后，原账户中的数据都不会受到任何影响。</p>
                        <p>4、如有其它疑问请点击这里<Button type="link" >联系客服(QQ:124136597,微信:ad1133558)</Button>为您解决。</p>
                    </Col>
                </Row>
                <Divider/>
            </TabPane>
        </Tabs>);
    }
    renderOrderConfirm=()=>{
        return(<Anchor>
                    <Row>
                        <Col span={2} style={{height:'80px',textAlign:'center',paddingTop:'30px'}}>
                            <span style={{fontSize:'20px',color:'#FF6c00'}}>订单</span>
                        </Col>
                        <Col span={3}>
                            <Row style={{height:'40px',textAlign:'center'}}><span>选择版本</span></Row>
                            <Row style={{height:'40px',textAlign:'center',paddingTop:'10px',color:'#1E9FFF'}}>{this.state.versionText}</Row>
                        </Col>
                        <Col span={1}>
                            <Row style={{fontSize:'24px',paddingTop:'40px'}}>+</Row>
                        </Col>
                        <Col span={3}>
                            <Row style={{height:'40px',textAlign:'center'}}><span>支持用户数</span></Row>
                            <Row style={{height:'40px',textAlign:'center',paddingTop:'10px',color:'#1E9FFF'}}>{this.state.userCount}&nbsp;人</Row>
                        </Col>
                        <Col span={1}>
                            <Row style={{fontSize:'24px',paddingTop:'40px'}}>+</Row>
                        </Col>
                        <Col span={3}>
                            <Row style={{height:'40px',textAlign:'center'}}><span>使用期</span></Row>
                            <Row style={{height:'40px',textAlign:'center',paddingTop:'10px',color:'#1E9FFF'}}>
                                {this.state.accountTimeText}
                            </Row>
                        </Col>
                        <Col span={1}>
                            <Row style={{fontSize:'24px',paddingTop:'40px'}}>+</Row>
                        </Col>
                        {/*<Col span={3}>
                            <Row style={{height:'40px',textAlign:'center'}}><span>续费时长</span></Row>
                            <Row style={{height:'40px',textAlign:'center',paddingTop:'10px',color:'#1E9FFF'}}>
                                {}
                            </Row>
                        </Col>
                        <Col span={1}>
                            <Row style={{fontSize:'24px',paddingTop:'40px'}}>+</Row>
                        </Col>*/}
                        <Col span={3}>
                            <Row style={{height:'40px',textAlign:'center'}}><span>应付</span></Row>
                            <Row style={{height:'40px',textAlign:'center',paddingTop:'10px',color:'#EE0000'}}>{parseInt(this.state.userPrice*this.state.accountTime)}</Row>
                        </Col>
                        <Col span={1}>
                            <Row style={{fontSize:'24px',paddingTop:'40px'}}>=</Row>
                        </Col>
                        <Col span={2} style={{height:'80px',textAlign:'center',paddingTop:'40px'}}>
                            <Button size='large' type='primary'>确认，支付</Button>
                        </Col>
                    </Row>
                </Anchor>);
    }
    render(){
        return(<Row>
            {this.renderPayment()}
            {this.renderOrderConfirm()}
        </Row>);
    }
}
