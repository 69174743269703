import React from 'react';
import * as Service from '../../config/service.json';
import {deleteObject, uploadFile} from '../../utils/minioutil'
import {Tabs,Row,Col,Form, Input, Button,Modal,Card, Divider,message,Upload} from 'antd';
import {LockOutlined,PlusOutlined} from '@ant-design/icons';
import {connect} from "react-redux";
import {updateUserName, updateUserDetail,resetUserPassword,updateUserEmail,updateUserPhone} from "../../actions/userDBTool";
import {getEnterprise} from "../../actions/enterpriseDBTool";
import {sendEmailVerifyCode, sendPhoneVerifyCode,sendPhoneResetPSDVerifyCode} from "../../actions/commonDBTool";

import {userLevelList} from "../../constants/constant";

const { TabPane } = Tabs;
function mapStateToProps(state) {
    return {...state.auth};
}
function mapDispatchToProps(dispatch) {
    return {onResetPsd:(postData)=>dispatch(resetUserPassword(postData)),
            onUpdateName:(account,newName)=>dispatch(updateUserName(account,newName)),
            onUpdateEmail:(account,verifyCode,newEmail)=>dispatch(updateUserEmail(account,verifyCode,newEmail)),
            onUpdatePhone:(account,verifyCode,newPhone)=>dispatch(updateUserPhone(account,verifyCode,newPhone)),
            onUpdateDetail:(detial)=>dispatch(updateUserDetail(detial)),
            onGetEnterprise:()=>dispatch(getEnterprise())}
}
class ProFile extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            maxTime:60,
            verifyButtonStatus:true,
            verifyButtonText:'获取验证码',
            emailModal:false,
            phoneModal:false,
            newEmail:'',
            newPhone:'',
            imageUrl:Service.resServer +localStorage.getItem("enterprise_uuid") + "/" + localStorage.getItem("account")+"/profile/"+ JSON.parse(localStorage.getItem('staff_detail')).profile_thumb
        };
        this.sendUpdateEmailVerifyCode = this.sendUpdateEmailVerifyCode.bind(this);
        this.sendUpdatePhoneVerifyCode = this.sendUpdatePhoneVerifyCode.bind(this);
        this.sendVerifyCode = this.sendVerifyCode.bind(this);
        this.updateEmailSubmit = this.updateEmailSubmit.bind(this);
        this.updatePhoneSubmit = this.updatePhoneSubmit.bind(this);
        this.beforeUpload = this.beforeUpload.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount(){
        this.props.onGetEnterprise();
    }
    handleOnChange=(key)=>{
        console.log(key);
    }
    handlePsdSubmit=(e)=> {
        e.preventDefault();
        let password = e.target.oldPsd.value;
        let newPassword = e.target.newPsd.value;
        let newPsdConfirm = e.target.newPsdConfirm.value;
        let verifycode = e.target.verifycode.value;
        if(newPassword !== newPsdConfirm){
            message.error("两次新密码输入不一致！");
            return;
        }
        else if(newPassword.length < 6){
            message.error("新密码位数不能少于6位");
            return;
        }
        let postData = {account:localStorage.getItem('account'),
                        old_password:password,
                        new_password:newPassword,
                        verify_code:verifycode
                    }
        this.props.onResetPsd(postData);
    }
    beforeUpload(file){
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('仅支持JPG/PNG 文件!');
            return false
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('仅支持小于2M的图片');
            return false
        }
        try {
            let path = localStorage.getItem("account")+"/profile/";
            if(JSON.parse(localStorage.getItem('staff_detail')).profile_thumb){//remove the old thumb file
                let oldfile = path + JSON.parse(localStorage.getItem('staff_detail')).profile_thumb;
                console.log(oldfile)
                deleteObject(oldfile,(error)=>{
                    console.log(error)
                })
            }
            uploadFile(path,file,()=>{
                let staffDetail = JSON.parse(localStorage.getItem('staff_detail'));
                staffDetail.profile_thumb = file.name
                this.setState({imageUrl:Service.resServer +localStorage.getItem("enterprise_uuid") + "/" + path + file.name})
                this.props.onUpdateDetail(staffDetail)
            })
        }catch (e) {
            console.log(e)
            return false;
        }
        return true
    }
    handleChange(info){
        /*const options = {
            s3: {
              accessKeyId: 'd3c423bc765d4a6c803f8b848c96ccf0',
              secretAccessKey: '7c1f901abb67aef5fe22a67d63d95be7',
              endpoint: "usc1.contabostorage.com/",
              region: '',
              ACL: 'public-read',
              sslEnabled: false
            },
            upload: {
              directory: '/Users/brucehe/Desktop/sdfg/',
              bucket: `res.crm`
            }
          }
           
          const uploading = new Uploader(options)
          uploading.upload()*/
        /*let url = Service.dbServer+"uploadImage";
        console.log(url)
        var formData = new FormData();
        formData.append('image', info.file,info.file.name);
        axios.post(url,formData).then((response)=>{
            if(200 === response.status){
                console.log(response.data)
                let staffDetail = JSON.parse(localStorage.getItem('staff_detail'));
                staffDetail.profile_thumb = response.data
                this.props.onUpdateDetail(staffDetail)
            }
        })*/
    }
    handleModifyName=()=>{
        console.log(this.state.newName);
        this.setState({modifyName:false});
        if(this.state.newName && this.state.newName !== localStorage.getItem('name')){
            this.props.onUpdateName(localStorage.getItem('account'),this.state.newName);
        }
    }
    countDown=()=>{
        if (0 === this.state.maxTime) {
            this.setState({maxTime:60,
                verifyButtonStatus:true,
                verifyButtonText:'获取验证码'});
        } else {
            let maxTime = this.state.maxTime;
            this.setState({maxTime:--maxTime,
                verifyButtonStatus:false,
                verifyButtonText:this.state.maxTime + "秒后重新获取"});
            setTimeout(this.countDown, 1000);
        }
    }
    sendUpdateEmailVerifyCode(){
        if(!this.state.newEmail){
            message.warning("新的邮箱地址不能为空!")
            return;
        }
        sendEmailVerifyCode(this.state.newEmail,"【多易CRM】邮箱验证码","【多易CRM】提醒您，当前账号正在绑定新的邮箱地址，验证码为:");
        this.countDown();
    }
    sendUpdatePhoneVerifyCode(){
        if(!this.state.newPhone){
            message.warning("新的手机号码不能为空!")
            return;
        }
        sendPhoneVerifyCode(this.state.newPhone);
        this.countDown();
    }
    updateEmailSubmit(){
        if(!this.state.newEmail){
            message.warning("新的邮箱不能为空!")
            return;
        }
        else if(!this.state.newEmailVerifyCode){
            message.warning("验证码不能为空!");
            return ;
        }
        this.props.onUpdateEmail(localStorage.getItem('account'),this.state.newEmailVerifyCode,this.state.newEmail);
        this.setState({emailModal:false});
    }
    updatePhoneSubmit(){
        console.log(this.state.newPhoneVerifyCode)
        if(!this.state.newPhone){
            message.warning("手机号不能为空!");
            return ;
        }
        else if(!this.state.newPhoneVerifyCode){
            message.warning("验证码不能为空!");
            return ;
        }
        this.props.onUpdatePhone(localStorage.getItem('account'),this.state.newPhoneVerifyCode,this.state.newPhone);
        this.setState({phoneModal:false});
    }
    sendVerifyCode(account){
        if(!account.length){return}
        if(account.indexOf(".com")){
            sendEmailVerifyCode(account,"【多易CRM】邮箱验证码","【多易CRM】提醒您，您的账号当前尝试修改密码，验证码为:");
        }
        else{
            sendPhoneResetPSDVerifyCode(account)    
        }
    }
    renderEmailModal(){
        return( <Modal title="验证邮箱" visible={this.state.emailModal}
            onOk={this.updateEmailSubmit} onCancel={()=>{this.setState({emailModal:false})}}>
            <Form labelCol={{span:4}} wrapperCol={{span:14}}>
                <Form.Item label={"新邮箱地址"}>
                    <Input onChange={(e)=>{this.setState({newEmail:e.target.value})}} required={true}/>
                </Form.Item>
                <Form.Item label={"验证码"}>
                    <Input onChange={e=>{this.setState({newEmailVerifyCode:e.target.value})}} required={true}/>
                </Form.Item>
                <Form.Item>
                    <Button type={'primary'} disabled={!this.state.verifyButtonStatus} onClick={()=>{this.sendUpdateEmailVerifyCode()}}>
                        {this.state.verifyButtonText}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>);
    }
    renderPhoneModal(){
        return(<Modal title="验证手机号" visible={this.state.phoneModal}
            onOk={this.updatePhoneSubmit} onCancel={()=>{this.setState({phoneModal:false})}}>
            <Form labelCol={{span:4}} wrapperCol={{span:14}}>
                <Form.Item label={"绑定手机号"}>
                    <Input onChange={(e)=>{this.setState({newPhone:e.target.value})}} required={true}/>
                </Form.Item>
                <Form.Item label={"验证码"}>
                    <Input onChange={e=>{this.setState({newPhoneVerifyCode:e.target.value})}} required={true}/>
                </Form.Item>
                <Form.Item>
                    <Button type={'primary'} disabled={!this.state.verifyButtonStatus} onClick={()=>{this.sendUpdatePhoneVerifyCode()}}>
                        获取验证码
                    </Button>
                </Form.Item>
            </Form>
        </Modal>);
    }
    renderProfile(){
        let detailJson = JSON.parse(localStorage.getItem('staff_detail'));
        let departmentList = JSON.parse(localStorage.getItem('department_list'))
        let department = ""
        console.log(departmentList)
        for(let i = 0;i < departmentList.length;i++){
            if(detailJson.position_id1 === departmentList[i].id){
                department = departmentList[i].name
            }
        }
        let userName = localStorage.getItem("name");
        return(<Row>
            {this.renderEmailModal()}
            {this.renderPhoneModal()}
            <Row>
                <Col span={3} style={{textAlign:'right'}}>账    户：</Col>
                <Col span={4} offset={1} style={{textAlign:'left'}}>{localStorage.getItem("account")}</Col>
            </Row>
            <Divider/>
            <Row>
                <Col span={3} style={{textAlign:'right'}}>名    称：</Col>
                <Col span={4} offset={1} style={{textAlign:'left'}}>
                    {this.state.modifyName?
                    <Input onChange={(e)=>{this.setState({newName:e.target.value})}} defaultValue={userName}/>:userName}
                </Col>
                <Col span={2} offset={1}>
                    {this.state.modifyName?
                    <Button onClick={()=>{this.handleModifyName()}}>保存</Button>:<Button type="link" onClick={()=>{this.setState({modifyName:true})}}>[修改名称]</Button>}
                </Col>
            </Row>
            <Divider/>
            <Row>
                <Col span={3} style={{textAlign:'right'}}>所属部门:</Col>
                <Col span={3} offset={1} style={{textAlign:'left'}}>{department}</Col>
            </Row>
            <Divider/>
            <Row>
                <Col span={3} style={{textAlign:'right'}}>所属岗位:</Col>
                <Col span={3} offset={1} style={{textAlign:'left'}}>{detailJson.position}</Col>
            </Row>
            <Divider/>
            <Row>
                <Col span={3} style={{textAlign:'right'}}>邮    箱：</Col>
                <Col span={4} offset={1} style={{textAlign:'left'}}>{detailJson.email}</Col>
                <Col span={2} offset={1}><Button type="link" onClick={()=>{this.setState({emailModal:true});}}>[更换邮箱]</Button></Col>
            </Row>
            <Divider/>
            <Row>
                <Col span={3} style={{textAlign:'right'}}>手机号码:</Col>
                <Col span={4} offset={1} style={{textAlign:'left'}}>{detailJson.phone}</Col>
                <Col span={2} offset={1}><Button type="link" onClick={()=>{this.setState({phoneModal:true})}}>[更换手机]</Button></Col>
            </Row>
        </Row>);
    }
    renderPsdTab() {
        const iconStyle = {color: 'rgba(0,0,0,.25)'};
        const tailLayout = {wrapperCol: {offset: 8, span: 16}};
        let account = localStorage.getItem('account');
        return(<Form onSubmit={e=>this.handlePsdSubmit(e)} style={{textAlign:'left'}}>
                    <Form.Item label={"原密码"} labelCol={{span:4}} wrapperCol={{span:10}}>
                        <Input name={'oldPsd'} prefix={<LockOutlined style={iconStyle}/>} type="password" placeholder="请输入原密码" style={{width:'80%'}}/>
                    </Form.Item>
                    <Form.Item label={"新密码"} labelCol={{span:4}} wrapperCol={{span:10}}>
                        <Input name={'newPsd'} prefix={<LockOutlined style={iconStyle}/>} type="password" placeholder="请输入新密码" style={{width:'80%'}}/>
                    </Form.Item>
                    <Form.Item label={"确认新密码"} labelCol={{span:4}} wrapperCol={{span:10}}>
                        <Input name={'newPsdConfirm'} prefix={<LockOutlined style={iconStyle}/>} type="password" placeholder="请再输入一次密码" style={{width:'80%'}}/>
                    </Form.Item>        
                    <Form.Item label={"验证码"} labelCol={{span:4}} wrapperCol={{span:10}}>
                        <Input name={'verifycode'} required={true} placeholder={"发送到"+account} style={{width:'80%'}}/>
                        <Button type={'primary'} disabled={!this.state.verifyButtonStatus} onClick={()=>{
                        if(account.indexOf('.com')){
                            sendEmailVerifyCode(account,"【多易CRM】邮箱验证码","【多易CRM】提醒您,当前账号正在重置密码，验证码为:");
                        }
                        else{
                            sendPhoneResetPSDVerifyCode(account);
                        }
                        this.countDown();
                    }}>
                        {this.state.verifyButtonText}
                    </Button>
                    </Form.Item>
                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit">修改</Button>
                        <Button htmlType={"button"} onClick={()=>{this.props.form.resetFields()}}>取消</Button>
                    </Form.Item>
                </Form>);
    }
    renderSetting(){
        return(<Row>暂未开通</Row>);
    }
    render(){
        let userLevel = Number(localStorage.getItem("level"));
        if(userLevelList.forEach(item=>{
            if(item.value === userLevel){
                userLevel = item.text;
            }
        }));
        const uploadButton = (<div className="ant-upload-text"><PlusOutlined />点击上传头像</div>);
        const { imageUrl } = this.state;
        let userName = localStorage.getItem("name");
        let detailJson = JSON.parse(localStorage.getItem('staff_detail'));
        return (<Row gutter={[10,0]}>
                    <Col span={6}>
                        <Card title={"个人信息"}>
                        <Row type='flex' align='middle' justify={'center'}>
                            <Upload accept="image/jpeg,image/png" listType="picture" beforeUpload={(file)=>{
                                this.beforeUpload(file);
                                return false /*阻止组件自带上传事件*/
                                }} onChange={this.handleChange}>
                                {imageUrl ? <img src={imageUrl} alt="点击上传头像" style={{ width: '100px',height:'100px' }} /> : uploadButton}
                            </Upload>
                        </Row>
                        <br/>
                        <Row>
                            <Col span={6} style={{textAlign:'left'}}>账    户：</Col>
                            <Col span={17} style={{textAlign:'left'}}>{localStorage.getItem("account")}</Col>
                            <Divider/>
                            <Col span={6} style={{textAlign:'left'}}>名    称：</Col>
                            <Col span={17} style={{textAlign:'left'}}>
                                {this.state.modifyName?
                                <Input onChange={(e)=>{this.setState({newName:e.target.value})}} defaultValue={userName}/>:userName}
                            </Col>
                            <Divider/>
                            <Col span={6} style={{textAlign:'left'}}>邮    箱：</Col>
                            <Col span={17} style={{textAlign:'left'}}>{detailJson.email}</Col>
                            <Divider/>
                            <Col span={8} style={{textAlign:'left'}}>账户类型:</Col>
                            <Col span={15} offset={1} style={{textAlign:'left'}}>{userLevel}</Col>
                            <Divider/>
                        </Row>
                    </Card>
                </Col>
                <Col span={17}>
                    <Card>
                        <Tabs defaultActiveKey="1" onChange={this.handleOnChange}>
                            <TabPane tab="基本资料" key="1">
                                {this.renderProfile()}
                            </TabPane>
                            <TabPane tab="密码设置" key="2">
                                {this.renderPsdTab()}
                            </TabPane>
                            {/*<TabPane tab="系统设置" key="3">
                                {this.renderSetting()}
                            </TabPane>*/}
                        </Tabs>
                    </Card>
                </Col>
            </Row>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(ProFile);