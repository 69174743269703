import React from 'react';
import ReactQuill from 'react-quill';
import {PlusCircleOutlined,MinusCircleOutlined} from '@ant-design/icons';
import 'react-quill/dist/quill.snow.css';
import { Row, Col, Form, Table, Button, Divider, Modal, Select, Upload, message} from 'antd';
import XLSX from 'xlsx';
import {CUSTOM_TYPE_ENTERPRISE, CUSTOM_TYPE_PERSONAL, customLevelList, customStatusList, customTypeList} from "../../../constants/constant";
import {connect} from "react-redux";
import {batchAddCustom} from "../../../actions/customDBTool";
import ExtendSelect from "../../controls/ExtendSelect";
import StaffChoose from "../../controls/StaffChoose";
import { name2Account } from '../../../utils/accountUtils';
import {v1 as uuidv1} from "uuid";

const {Option} = Select;
const {confirm} = Modal;
let importColumns = [];
let importOptions = [];
let customTypeOptions = customTypeList.map(item=><Option key={item} value={item}>{item}</Option>);
const ImportForm = Form.create({name:'import_modal'})(
    class extends React.Component{
    constructor(props) {
        super(props);
        this.state = {showEnterprisePane : false,customType:CUSTOM_TYPE_PERSONAL};
    }
    onCustomType(value){
        if (CUSTOM_TYPE_PERSONAL === value){
            this.setState({showEnterprisePane:false,customType:CUSTOM_TYPE_PERSONAL});
        }
        else{
            this.setState({showEnterprisePane:true,customType:CUSTOM_TYPE_ENTERPRISE});
        }
    }
    remove = k => {
        const { form } = this.props;
        const keys = form.getFieldValue("enterprise_contact_keys");
        // We need at least one passenger
        if (keys.length === 1) {return;}
        console.log(keys);
        console.log(k);
        // can use data-binding to set
        form.setFieldsValue({enterprise_contact_keys: keys.filter(key => key !== k)});
    }
    add = ()=>{
        const { form } = this.props;
        const keys = form.getFieldValue("enterprise_contact_keys");
        if(keys.length >= 4){return}
        const nextKeys = keys.concat(keys.length);
        form.setFieldsValue({enterprise_contact_keys: nextKeys});
    }
    renderDynamicItem(){
        const { form } = this.props;
        let { getFieldDecorator } = form;
        getFieldDecorator("enterprise_contact_keys", { initialValue:[0]});
        const keys = form.getFieldValue("enterprise_contact_keys");
        let formItemLayout = {labelCol: {span:8}, wrapperCol: {span:16}};
        return keys.map((key, index) =>
            <Row key={index}>
                <Col span={10}>
                    <Form.Item {...formItemLayout} label={<span style={{fontsize:'24px'}}>联系人&nbsp;&nbsp;姓名</span>}>
                        {getFieldDecorator(`enterprise_contact_name[${index}]`,
                            {validateTrigger: ["onChange", "onBlur"],
                                rules: [{required: false, message: "请输入新增的联系方式或者删除该行!"}]})
                        (<ExtendSelect items={importOptions} placeholder="选择一列作为联系人名称"/>)}
                    </Form.Item>
                </Col>
                <Col span={14}>
                    <Form.Item {...formItemLayout} label={"电话"}>
                        <Row>
                            <Col span={16}>
                                {getFieldDecorator(`enterprise_contact_phone[${index}]`, {validateTrigger: ["onChange", "onBlur"],
                                    rules: [{required: false, message: "请输入新增的联系方式或者删除该行!"}]})
                                (<ExtendSelect items={importOptions} placeholder="选择一列作为联系人电话"/>)}
                            </Col>
                            <Col span={6} offset={1}>
                                {index === 0?<PlusCircleOutlined style={{color:'blue'}} onClick={() => this.add()}/>:
                                <MinusCircleOutlined style={{color:'red'}} onClick={() => this.remove(key)}/>}
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
            </Row>);
    }
    renderPersonalCustom(){
        const { form } = this.props;
        let { getFieldDecorator } = form;
        return (<div>
            <Row style={{background:'#F0F0F0'}}>客户信息</Row>
            <Row>
                <Col span={12}>
                    <Form.Item label={"名称"} required={true}>
                        {getFieldDecorator('name',{rules:[{ required: true,message:'客户名称不能为空!'}]})
                        (<ExtendSelect items={importOptions} placeholder={"选择表格的列作为客户名称"}/>)}
                    </Form.Item>
                    <Form.Item label={"所在行业"}>
                        {getFieldDecorator('industry',{rules:[{required:false}]})
                        (<ExtendSelect items={importOptions} title={"所在行业"} placeholder={"选择表格的列作为行业"}/>)}
                    </Form.Item>
                    <Form.Item label={"城市"}>
                        {getFieldDecorator('city',{rules:[{required:false}]})
                        (<ExtendSelect items={importOptions} title={"所在城市"} placeholder={"选择表格的列作为城市"}/>)}
                    </Form.Item>
                    <Form.Item label={"联系地址"}>
                        {getFieldDecorator('address', {rules:[{required:false}]})
                        (<ExtendSelect items={importOptions} title={"联系地址"} placeholder={"选择表格的列作为联系地址"}/>)}
                    </Form.Item>
                    <Form.Item label={"手机"}>
                        {getFieldDecorator('phone', {rules:[{required:false}]})
                        (<ExtendSelect type="tel" items={importOptions} title={"手机"} placeholder={"选择表格的列作为手机号码"}/>)}
                    </Form.Item>
                    <Form.Item label={"座机"}>
                        {getFieldDecorator('tel', {rules:[{required:false}]})
                        (<ExtendSelect type="tel" items={importOptions} title={"座机"} placeholder={"选择表格的列作为座机"}/>)}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={"邮箱"}>
                        {getFieldDecorator('email', {rules:[{required:false}]})
                        (<ExtendSelect type="email" items={importOptions} title={"邮箱"} placeholder={"选择表格的列作为email"}/>)}
                    </Form.Item>
                    <Form.Item label={"微信"}>
                        {getFieldDecorator('wechat', {rules:[{required:false}]})
                        (<ExtendSelect items={importOptions} title={"微信"} placeholder={"选择表格的列作为Wechat"}/>)}
                    </Form.Item>
                    <Form.Item label={"QQ"}>
                        {getFieldDecorator('qq', {rules:[{required:false}]})
                        (<ExtendSelect items={importOptions} title={"QQ"} placeholder={"选择表格的列作为QQ"}/>)}
                    </Form.Item>
                    <Form.Item label={"特别备注"}>
                        {getFieldDecorator('note', {rules: [{ required: false}], initialValue:""})(
                            <ReactQuill placeholder={"XXX"} />)}
                    </Form.Item>
                </Col>
            </Row>
        </div>);
    }
    renderEnterpriseCustom(){
        const { form } = this.props;
        let { getFieldDecorator } = form;
        return ( <div>
            <Row style={{background:'#F0F0F0'}}>企业信息</Row>
            <Row>
                <Col span={12}>
                    <Form.Item label={"公司名称"} required={true}>
                        {getFieldDecorator('name',{rules:[{ required: true,message:'公司名称不能为空!'}],initialValue:""})
                        (<ExtendSelect items={importOptions} title={"公司名称"} placeholder={"选择表格的列作为公司名称"}/>)}
                    </Form.Item>
                    <Form.Item label={"所在行业"}>
                        {getFieldDecorator('industry',{rules:[{required:false}]})
                        (<ExtendSelect items={importOptions} title={"所在行业"} placeholder={"选择表格的列作为行业"}/>)}
                    </Form.Item>
                    <Form.Item label={"城市"}>
                        {getFieldDecorator('city',{rules:[{required:false}]})
                        (<ExtendSelect items={importOptions} title={"所在城市"} placeholder={"选择表格的列作为城市"}/>)}
                    </Form.Item>
                    <Form.Item label={"公司地址"}>
                        {getFieldDecorator('address', {rules:[{required:false}]})
                        (<ExtendSelect items={importOptions} title={"联系地址"} placeholder={"选择表格的列作为联系地址"}/>)}
                    </Form.Item>
                    <Form.Item label={"公司座机"}>
                        {getFieldDecorator('tel', {rules:[{required:false}]})
                        (<ExtendSelect type="tel" items={importOptions} title={"座机"} placeholder={"选择表格的列作为座机"}/>)}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={"手机号码"}>
                        {getFieldDecorator('phone', {rules:[{required:false}]})
                        (<ExtendSelect type="phone" items={importOptions} title={"手机号码"} placeholder={"选择表格的列作为手机号码"}/>)}
                    </Form.Item>
                    <Form.Item label={"公司邮箱"}>
                        {getFieldDecorator('email', {rules:[{required:false}]})
                        (<ExtendSelect type="email" items={importOptions} title={"邮箱"} placeholder={"选择表格的列作为email"}/>)}
                    </Form.Item>
                    <Form.Item label={"公司QQ"}>
                        {getFieldDecorator('enterpriseQQ', {rules:[{required:false}]})
                        (<ExtendSelect items={importOptions} placeholder={"选择表格的列作为公司QQ"}/>)}
                    </Form.Item>
                    <Form.Item label={"特别备注"}>
                        {getFieldDecorator('note', {rules: [{ required: false}], initialValue:""})(
                            <ReactQuill placeholder={"XXX"} />)}
                    </Form.Item>
                </Col>
            </Row>
            <Row style={{background:'#F0F0F0'}}>企业联系人</Row>
            {this.renderDynamicItem()}
        </div>)
    }
    render(){
        let { visible, onCancel, onOk, form } = this.props;
        let { getFieldDecorator } = form;
        let formItemLayout = {labelCol: {span:6}, wrapperCol: {span:18}};
        return(<Modal title={"批量导入客户"} visible={visible} onOk={onOk} onCancel={onCancel}
                        okText={"开始导入"} cancelText={"取消"} width={1200}>
            <Form {...formItemLayout}>
                <Row>
                    <Col span={12}>
                        <Form.Item label={"客户类别"}>
                            {getFieldDecorator('type', {rules: [{ required: false}],initialValue:this.state.customType})
                            (<Select style={{width:'90%'}} onChange={(value)=>{this.onCustomType(value)}}>{customTypeOptions}</Select>)}
                        </Form.Item>
                        <Form.Item label={"客户状态"}>
                            {getFieldDecorator('status',{rules:[{required:true,message:'客户状态不能为空'}],
                                initialValue:customStatusList[0]})
                            (<ExtendSelect items={customStatusList} title={"客户状态"}/>)}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={"客户等级"}>
                            {getFieldDecorator('level',{rules:[{required:true,message:'客户等级不能为空'}],
                                initialValue:customLevelList[0]})
                            (<ExtendSelect items={customLevelList} title={"客户等级"} placeholder={"请输入自定义客户等级"}/>)}
                        </Form.Item>
                        <Form.Item label={"负责人"}>
                            {getFieldDecorator('manager', {rules:[{required:false}], initialValue:''})
                            (<StaffChoose mode={0}/>)}
                            {<span style={{color:'red'}}>(不选择任何跟进成员员，即为公海客户)</span>}
                        </Form.Item>
                    </Col>
                </Row>
                {this.state.customType === CUSTOM_TYPE_PERSONAL?this.renderPersonalCustom():this.renderEnterpriseCustom()}
            </Form>
        </Modal>);
    }
}
);
function mapStateToProps(state) {
    return {...state.custom};
}
function mapDispatchToProps(dispatch) {
    return {onBatchAddCustom:(postData)=>dispatch(batchAddCustom(postData)),};
}
class ImportCustom extends React.Component{
    constructor(props){
        super(props);
        this.state = {  showModal:false,
                        importDS:[],
                        importData:[],
                        importColumns:importColumns,
                        selectedRowKeys: []};
    }
    onSelectChange = selectedRowKeys => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    }
    handleAddOnClick=()=>{
        if(0 === this.state.importDS.length){
            return message.error("当前没有可导入的数据，请先上传excel表格！");
        }
        this.setState({showModal:true});
    }
    handleCleanAll=()=>{
        if(0 === this.state.importDS.length){
            return message.error("当前没有可清空的数据!");
        }
        confirm({content: '您确定要清空这些数据?',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk:()=> {
                this.setState({importDS:[]});
            },
            onCancel() {
                console.log('Cancel');
            },
        })
    }
    handleBatchSubmit=()=>{
        let { form } = this.formRef.props;
        form.validateFields((err, fromValues) => {
            if (err) {
                console.log(err);
                return;
            }
            let manager_account = name2Account(fromValues.manager);
            if(0 >= fromValues.manager.length){
                manager_account = "公海客户";
            }
            if(this.state.importData instanceof Array){
                let postDataList = [];
                this.state.importData.forEach(item=>{
                    let name = fromValues.name?fromValues.name:"";
                    let city = fromValues.city?fromValues.city:"";
                    let address = fromValues.address?fromValues.address:"";
                    let industry = fromValues.industry?fromValues.industry:"";
                    let phone = fromValues.phone?fromValues.phone:"";
                    let tel = fromValues.tel?fromValues.tel:"";
                    let email = fromValues.email?fromValues.email:"";
                    let wechat = fromValues.wechat?fromValues.wechat:"";
                    let qq = fromValues.qq?fromValues.qq:"";
                    let enterprise_contact_name = fromValues.enterprise_contact_name;
                    let enterprise_contact_phone = fromValues.enterprise_contact_phone;
                    for(let key in item){
                        if (key === fromValues.name){name = item[key];}
                        if (key === fromValues.city){city = item[key];}
                        if (key === fromValues.address){address = item[key];}
                        if (key === fromValues.industry){industry = item[key];}
                        if (key === fromValues.phone){phone = item[key];}
                        if (key === fromValues.tel){tel = item[key];}
                        if (key === fromValues.email){email = item[key];}
                        if (key === fromValues.qq){qq = item[key];}
                        if(enterprise_contact_name){
                            for(let index = 0;index < fromValues.enterprise_contact_name.length;index++){
                                if (key === fromValues.enterprise_contact_name[index]){
                                    enterprise_contact_name.splice(index,1,item[key]);
                                }
                            }
                        }
                        if(enterprise_contact_phone){
                            for(let index = 0;index < fromValues.enterprise_contact_name.length;index++){
                                if(key === fromValues.enterprise_contact_phone[index]){
                                    enterprise_contact_phone.splice(index,1,item[key]);
                                }
                            }
                        }
                    }
                    let detail = {city:city,
                        address:address,
                        tel:tel,
                        phone:phone,
                        email:email,
                        wechat:wechat,
                        QQ:qq,
                        industry:industry,
                        enterprise_contact_name:enterprise_contact_name,
                        enterprise_contact_phone:enterprise_contact_phone,
                        note:fromValues.note,
                        manager_account:manager_account}
                    let currTime = new Date();
                    let postData = {
                        enterprise_uuid:localStorage.getItem("enterprise_uuid"),
                        uuid:currTime.getFullYear().toString() + uuidv1().toString().replace(/-/g,""),
                        name:name,
                        age:0,
                        sex:1,
                        detail:JSON.stringify(detail),
                        type:fromValues.type,
                        level:fromValues.level,
                        status:fromValues.status,
                        update_time:currTime,
                        creator_account:localStorage.getItem('account'),
                        create_time:currTime
                    };
                    postDataList.push(postData);
                });
                console.log(postDataList);
                this.props.onBatchAddCustom(postDataList);
                this.setState({importDS:[]})
            }
            form.resetFields();
            this.setState({ showModal: false });
        });
    }
    handleCancel=()=>{
        const { form } = this.formRef.props;
        form.resetFields();
        this.setState({showModal:false});
    }
    saveFormRef = formRef =>{this.formRef = formRef;}
    importExcel=(file,fileList)=>{
        console.log(file)
        console.log(fileList)
        // 获取上传的文件对象
        let files = fileList;
        console.log(files[0]);
        importColumns = [];
        // 通过FileReader对象读取文件
        const fileReader = new FileReader();
        fileReader.onload = event => {
            try {
                const { result } = event.target;
                // 以二进制流方式读取得到整份excel表格对象
                const workbook = XLSX.read(result, { type: 'binary' });
                let data = []; //存储获取到的数据
                // 遍历每张工作表进行读取（这里默认只读取第一张表）
                for (const sheet in workbook.Sheets) {
                    if (workbook.Sheets.hasOwnProperty(sheet)) {
                        // 利用 sheet_to_json 方法将 excel 转成 json 数据
                        data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet],{defval:""}));
                        // break; // 如果只取第一张表，就取消注释这行
                    }
                }
                console.log(data)
                if(data.length > 100){
                    return message.error("一次性导入客户数量最大为100条",5)
                }
                for(let key in data[0]){//create the table header
                    importColumns.push({title:key,dataIndex:key});
                }
                //create the options
                importOptions = [];
                importColumns.map((item)=>{return importOptions.push(item.title)});
                for (let i = 0;i < data.length;i++){
                    let item = data[i];
                    item['index'] = i;
                    this.state.importDS.push(item);
                }
                this.setState({importColumns:importColumns,importData:data});
            } catch (e) {
                // 抛出文件类型错误不正确的相关提示
                console.log('文件类型不正确');
                return;
            }
        };
        // 以二进制方式打开文件
        fileReader.readAsBinaryString(files[0]);
        return false; //该行为了禁用upload 控件的post等操作
    }

    renderResult(){
        let maxHeight = document.body.clientHeight > 800?500:300
        return (<Row>
                    <ImportForm wrappedComponentRef={this.saveFormRef} visible={this.state.showModal}
                            onCancel={()=>{this.handleCancel()}} onOk={this.handleBatchSubmit}/>
                    <Row>
                        <Table size="small" scroll={{y:maxHeight,x:1500}} dataSource={this.state.importDS} columns={this.state.importColumns} rowKey={record => record.index}/>
                    </Row>
                    <Divider/>
                    <Row>
                        <Col span={4}>
                            <Upload accept='.xlsx, .xls, .csv' beforeUpload={this.importExcel}>
                                <Button>上传</Button>
                            </Upload>
                        </Col>
                        <Col span={3}>
                            <Button type={'primary'} onClick={this.handleAddOnClick}>批量导入</Button>
                        </Col>
                        <Col span={3}>
                            <Button type={'danger'} onClick={this.handleCleanAll}>清空</Button>
                        </Col>
                    </Row>
                </Row>)
    }
    render(){
        return (<Row>{this.renderResult()}</Row>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(ImportCustom);