import XLSX from 'xlsx';

export const exportExcel=(data,type,fileName = '记录表')=> {
    let wb = XLSX.utils.book_new();
    fileName += ".";
    fileName += type;
    // json_to_sheet 将JS对象数组转换为工作表
    let jsonWs = XLSX.utils.json_to_sheet(data,{defval:true});
    XLSX.utils.book_append_sheet(wb, jsonWs, 'jsonWs');
    XLSX.writeFile(wb, fileName);
}