import React from 'react';
import {Row,Tabs} from 'antd';
import { connect } from 'react-redux';
import SupplierTable from '../controls/SupplierTable';
import {USER_LEVEL_MEMBER} from "../../constants/constant";
import {getSupplier} from "../../actions/supplierDBTool"

const { TabPane } = Tabs;
function mapStateToProps(state){
    return {...state.supplier}
}
function mapDispatchToProps(dispatch){
    return {onGetAllSupplier:()=>dispatch(getSupplier())}
}
class AllSupplier extends React.Component{
    constructor(props){
        super(props)
        this.state = {}
    }
    componentDidMount(){
        this.props.onGetAllSupplier()
    }
    renderAllSupplier(){
        let allSupplier = []
        let mySupplier = []
        const supplierEditable = USER_LEVEL_MEMBER < Number(localStorage.getItem('level'))?true:false
        if(this.props.get_supplier_result){
            let result = this.props.get_supplier_result
            for(let i = 0;i < result.length;i++){
                let detail = result[i].detail;
                if(detail.manager_account && -1 !== detail.manager_account.indexOf(localStorage.getItem('account'))){
                    mySupplier.push(result[i]);
                }
                allSupplier.push(result[i]);
            }
        }
        const isManager = USER_LEVEL_MEMBER < Number(localStorage.getItem('level'))?true:false
        return (<Tabs defaultActiveKey='1' type="card">
            {isManager?
            (<TabPane key='1' tab="全部供应商">
                <SupplierTable dataSource={allSupplier} tableKey={'supplier/supplier'} customColumnsEnable={true} exportEnable={supplierEditable} editEnable={supplierEditable} />
            </TabPane>):null}
            <TabPane key='2' tab="我负责的供应商">
            <SupplierTable dataSource={mySupplier} tableKey={'supplier/supplier'} customColumnsEnable={true} exportEnable={supplierEditable} editEnable={supplierEditable} />
            </TabPane>
        </Tabs>)
    }
    render(){
        return (<Row>{this.renderAllSupplier()}</Row>)
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(AllSupplier)