import $ from 'jquery'
//import axios from "axios";
import {message} from "antd";
import * as Service from '../config/service.json';
import {ActionType} from "../constants/actionTypes";
import {preHandle,checkAccountStatus} from './commonDBTool';
export function addCollection(postData) {
    let url = Service.dbServer + Service.apiVersion + "/collection/add" ;
    return dispatch=>{
        dispatch({type:ActionType.ADD_COLLECTION_LIST_START});
        $.ajax(preHandle(url),{
            method:'POST',
            contentType:'application/json; charset=utf-8',
            data:JSON.stringify(postData)
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            message.info("添加成功!");
            dispatch({type:ActionType.ADD_COLLECTION_LIST_SUCCESS,payload:response.result});
            dispatch(getCollection());
            dispatch(getCollectionByCustom(JSON.parse(postData.detail).custom_uuid))
        }).catch(function (error) {
            message.error("添加失败!");
            dispatch({type:ActionType.ADD_COLLECTION_LIST_FAILED,payload:error});
        })
    }
}
export function getCollection(pageStart=0,pageSize=50) {
    let url = Service.dbServer + Service.apiVersion + "/collection/getAll" ;
    return dispatch=>{
        dispatch({type:ActionType.GET_COLLECTION_LIST_START});
        $.ajax(preHandle(url),{
            method:'GET',
            contentType:'application/json; charset=utf-8',
            dataType:'json',
            data:{pageStart:pageStart,pageSize:pageSize}
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            let result = response.result
            if(result){
                for(let i = 0;i < result.length;i++){
                    result[i].detail = JSON.parse(result[i].detail);
                }
            }
            dispatch({type:ActionType.GET_COLLECTION_LIST_SUCCESS,payload:result});
        }).catch(function (error) {
            dispatch({type:ActionType.GET_COLLECTION_LIST_FAILED,payload:error});
        })
    }
}
export function getCollectionByCustom(custom_uuid,pageStart=0,pageSize=50) {
    let url = Service.dbServer + Service.apiVersion + "/collection/getByCustom" ;
    return dispatch =>{
        dispatch({type:ActionType.GET_COLLECTION_LIST_BY_CUSTOM_START});
        $.ajax(preHandle(url),{
            method:'GET',
            contentType:'application/json; charset=utf-8',
            dataType:'json',
            data:({custom_uuid:custom_uuid,pageStart:pageStart,pageSize:pageSize})
        }).then(response=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            let result = response.result
            if(result){
                for(let i = 0;i < result.length;i++){
                    result[i].detail = JSON.parse(result[i].detail);
                }
            }
            dispatch({type:ActionType.GET_COLLECTION_LIST_BY_CUSTOM_SUCCESS,payload:result});
        }).catch(function (error) {
            dispatch({type:ActionType.GET_COLLECTION_LIST_BY_CUSTOM_FAILED,payload:error});
        })
    }
}
export function updateCollection(postData) {
    let url = Service.dbServer + Service.apiVersion + "/collection/update" ;
    return dispatch=>{
        dispatch({type:ActionType.UPDATE_COLLECTION_LIST_START})
        $.ajax(preHandle(url),{
            method:'POST',
            contentType:'application/json; charset=utf-8',
            data:JSON.stringify(postData)
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            message.info("更新成功!");
            dispatch({type:ActionType.UPDATE_COLLECTION_LIST_SUCCESS,payload:response.result});
            dispatch(getCollection());
            dispatch(getCollectionByCustom(JSON.parse(postData.detail).custom_uuid))
        }).catch(function (error) {
            message.error("更新失败!")
            dispatch({type:ActionType.UPDATE_COLLECTION_LIST_FAILED,payload:error});
        })
    };
}
export function deleteCollection(postData) {
    let url = Service.dbServer + Service.apiVersion + "/collection/del" ;
    return dispatch=>{
        dispatch({type:ActionType.DELETE_COLLECTION_LIST_START});
        $.ajax(preHandle(url),{
            method:'delete',
            //contentType:'application/json; charset=utf-8',
            dataType:'json',
            data:({uuid:postData.uuid})
        }).then(response=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            message.info("删除成功!");
            dispatch({type:ActionType.DELETE_COLLECTION_LIST_SUCCESS,payload:response.result});
            dispatch(getCollection());
            dispatch(getCollectionByCustom(postData.detail.custom_uuid))
        }).catch(function (error) {
            message.error("删除失败!");
            dispatch({type:ActionType.DELETE_COLLECTION_LIST_FAILED,payload:error});
        })
    };
}
export function batchDeleteCollection(uuidList){
    let url = Service.dbServer + Service.apiVersion + "/collection/batchDel" ;
    return dispatch=>{
        $.ajax(preHandle(url),{
            method:'post',
            dataType:'json',
            data:({collection_list:JSON.stringify(uuidList)})
        }).then(response=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            if(200 === response.code){
                message.info("删除成功!");
                dispatch(getCollection());
            }
        }).catch(function (error) {
            message.error("删除失败!");
        })
    }
}