import React from 'react';
import {Tree, Card, Alert, Row} from 'antd';
import {getEnterpriseDepartment} from "../../actions/enterpriseDBTool";
import {connect} from "react-redux";
import {ActionType} from "../../constants/actionTypes";
const { TreeNode } = Tree;

function mapStateToProps(state) {
    return {...state.enterprise,...state.staff};
}
function mapDispatchToProps(dispatch) {
    return {onGetDepartment:()=>dispatch(getEnterpriseDepartment()),
            onUpdateSelectDepartment:(departmentName)=>dispatch({type:ActionType.UPDATE_SELECT_DEPARTMENT,payload:departmentName})//broadcast the select department
        };
}
class DepartmentPositionList extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            departmentVisible:false,
            departmentList:[],
            departmentPositionType:'enterprise',
            departmentPositionName:localStorage.getItem('enterprise_name'),
            departmentModalValues:{mode:0, title: '添加新组织'},
            nodeTreeItem:null,
            treeData:[]
        };
    }
    componentDidMount() {
        this.props.onGetDepartment();
        this.props.onUpdateSelectDepartment(localStorage.getItem('enterprise_name'));
    }
    clearMenu = () => {
        this.setState({nodeTreeItem: null});
    }
    saveDepartmentFormRef = formRef =>{
        this.formRef = formRef;
    }
    handleRightClick = ({event,node}) => {
        event.preventDefault()
        let x = event.currentTarget.offsetLeft + event.currentTarget.clientWidth;
        let y = event.pageY - (event.currentTarget.clientHeight + 12) * this.state.departmentList.length;
        this.setState({ departmentPositionName:node.props.title,
                        departmentPositionType:node.props.type,
                        nodeTreeItem: {
                            pageX: x,
                            pageY: y,
                            id: node.props.eventKey,
                            name: node.props.title
                        }
                    });
        this.props.onUpdateSelectDepartment(node.props.title);
    }
    handleLeftClick = (selectedKeys,info)=>{
        let title = info.node.props.name
        this.setState({departmentPositionName:title,departmentPositionType:info.node.props.type});
        this.clearMenu();
        this.props.onUpdateSelectDepartment(title);
    }
    renderDepartmentList(){
        const treeData = [];
        const departmentTreeData = [];
        let staffList = this.props.get_staff_list_result?this.props.get_staff_list_result:[]
        let departmentList = this.props.get_department_result?this.props.get_department_result:[]
        for (let i = 0;i < departmentList.length;i++){
            let staffCount = 0
            let children = [];
            for(let t = 0;t < staffList.length;t++){
                if(staffList[t].detail.position === departmentList[i].name){
                    staffCount++;
                }
            }
            if(departmentList[i].children){
                for (let j = 0;j < departmentList[i].children.length;j++){
                    let childCount = 0
                    let subchildren = []
                    for(let t = 0;t < staffList.length;t++){
                        if(staffList[t].detail.position === departmentList[i].children[j].name){
                            childCount++;
                        }
                    }
                    for(let k=0;k < departmentList[i].children[j].children.length;k++){
                        let subChildCount = 0
                        for(let t = 0;t < staffList.length;t++){
                            if(staffList[t].detail.position === departmentList[i].children[j].children[k].name){
                                subChildCount++;
                            }
                        }
                        subchildren.push({
                            title:departmentList[i].children[j].children[k].name,
                            key:i+"-"+j+"-"+k,
                            count:subChildCount,
                            type:'position',
                        })
                        childCount += subChildCount;
                    }
                    children.push({
                        title:departmentList[i].children[j].name,
                        children:subchildren,
                        key:i+"-"+j,
                        count:childCount,
                        type:'position',
                    });
                    staffCount += childCount
                }
            }
            departmentTreeData.push({
                title: departmentList[i].name,
                key:i,
                count:staffCount,
                type:'department',
                children:children
            });
        }
        treeData.push({
            title: localStorage.getItem('enterprise_name'),
            key:'enterprise',
            type:'enterprise',
            count:staffList.length,
            children:departmentTreeData
        })
        const loop = data =>
        data.map(item => {
            if (item.children && item.children.length) {
            return (
                <TreeNode key={item.key} title={item.title + "（人数：" + item.count+"）"} name={item.title}>
                {loop(item.children)}
                </TreeNode>
            );
            }
            return <TreeNode key={item.key} title={item.title + "（人数：" + item.count+"）"} name={item.title}/>;
        });
        return (<Card bordered={false} title={<h3>部门组织架构</h3>}>
                    <Alert showIcon type="info" message="左键点击组织可查看组织"/>
                    <Row>
                        <Tree showLine={false} showIcon={true} defaultExpandAll={true} onSelect={this.handleLeftClick}>
                           {loop(treeData)}
                        </Tree>
                    </Row>
                </Card>);
    }
    render() {
        return (<Row>{this.renderDepartmentList()}</Row>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(DepartmentPositionList);