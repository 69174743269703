import $ from 'jquery'
import {message} from "antd";
import * as Service from '../config/service.json';
import {ActionType} from "../constants/actionTypes";
import {preHandle,checkAccountStatus} from './commonDBTool';

export function getAllFollowUp(pageStart=0,pageSize=50) {
    let url = Service.dbServer + Service.apiVersion + "/followup/getAll" ;
    return dispatch => {
        dispatch({type:ActionType.GET_FOLLOW_UP_START});
        $.ajax(preHandle(url),{
            method:'GET',
            dataType:'json',
            data:{pageStart:pageStart,pageSize:pageSize}
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            let result = response.result
            if(result){
                for(let i = 0;i < result.length;i++){
                    result[i].detail = JSON.parse(result[i].detail);
                }
            }
            dispatch({type:ActionType.GET_FOLLOW_UP_SUCCESS,payload:result});
        }).catch(function (error) {
            dispatch({type:ActionType.GET_FOLLOW_UP_FAILED,payload:error});
        })
    }
}
export function getAllFollowUpByCustom(custom_uuid,pageStart=0,pageSize=50) {
    let url = Service.dbServer + Service.apiVersion + "/followup/getByCustom" ;
    return dispatch=>{
        dispatch({type:ActionType.GET_FOLLOW_UP_BY_CUSTOM_START});
        $.ajax(preHandle(url),{
            method:'GET',
            dataType:'json',
            data:({custom_uuid:custom_uuid,pageStart:pageStart,pageSize:pageSize})
        }).then(response=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            let result = response.result
            if(result){
                for(let i = 0;i < result.length;i++){
                    result[i].detail = JSON.parse(result[i].detail);
                }
            }
            dispatch({type:ActionType.GET_FOLLOW_UP_BY_CUSTOM_SUCCESS,payload:result});
        }).catch(function (error) {
            dispatch({type:ActionType.GET_FOLLOW_UP_BY_CUSTOM_FAILED,payload:error});
        })
    }
}
export function addFollowUp(postData) {
    let url = Service.dbServer + Service.apiVersion + "/followup/add" ;
    return dispatch=>{
        dispatch({type:ActionType.ADD_FOLLOW_UP_START});
        $.ajax(preHandle(url),{
            method:'post',
            contentType:'application/json; charset=utf-8',
            dataType:'json',
            data:JSON.stringify(postData)
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            message.success("添加成功!");
            dispatch({type:ActionType.ADD_FOLLOW_UP_SUCCESS,payload:response.result});
            dispatch(getAllFollowUp());
            dispatch(getAllFollowUpByCustom(postData.custom_uuid));
        }).catch(function (error) {
            message.error("添加失败!");
            dispatch({type:ActionType.ADD_FOLLOW_UP_FAILED,payload:error});
        })
    }
}
export function updateFollowUp(postData) {
    let url = Service.dbServer + Service.apiVersion + "/followup/update" ;
    return dispatch=>{
        dispatch({type:ActionType.UPDATE_FOLLOW_UP_START})
        $.ajax(preHandle(url),{
            method:'post',
            contentType:'application/json; charset=utf-8',
            dataType:'json',
            data:JSON.stringify(postData)
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            message.success("更新成功");
            dispatch({type:ActionType.UPDATE_FOLLOW_UP_SUCCESS,payload:response.result});
            dispatch(getAllFollowUp());
            dispatch(getAllFollowUpByCustom(postData.custom_uuid));
        }).catch(function (error) {
            message.error("更新失败!");
            dispatch({type:ActionType.UPDATE_FOLLOW_UP_FAILED,payload:error});
        })
    }
}
export function deleteFollowUp(followUp) {
    console.log(followUp);
    let url = Service.dbServer + Service.apiVersion + "/followup/del" ;
    return dispatch=>{
        dispatch({type:ActionType.DELETE_FOLLOW_UP_START});
        $.ajax(preHandle(url),{
            method:'delete',
            dataType:'json',
            data:({uuid:followUp.uuid})
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            message.success("删除成功!");
            dispatch({type:ActionType.DELETE_FOLLOW_UP_SUCCESS,payload:response.result});
            dispatch(getAllFollowUp());
            dispatch(getAllFollowUpByCustom(followUp.custom_uuid))
        }).catch(function (error) {
            message.error("删除失败");
            dispatch({type:ActionType.DELETE_FOLLOW_UP_FAILED,payload:error});
        })
    }
}
export function batchDeleteFollowUp(uuid_list){
    let url = Service.dbServer + Service.apiVersion + "/followup/batchDel" ;
    return dispatch=>{
        $.ajax(preHandle(url),{
            method:'POST',
            dataType:'json',
            data:({followUp_list:JSON.stringify(uuid_list)})
        }).then((response)=>{
            if(!checkAccountStatus(response.code,response.message)){return;}
            if(200 === response.code){
                message.success("删除成功!");
                dispatch(getAllFollowUp());
            }
        }).catch(function (error) {
            message.error("删除失败");
            dispatch({type:ActionType.DELETE_FOLLOW_UP_FAILED,payload:error});
        })
    }
}