import React from 'react';
import ReactQuill from 'react-quill'; 
import 'react-quill/dist/quill.snow.css';
import {Input, Form, Modal, Select, DatePicker} from 'antd';
import ExtendSelect from "./ExtendSelect";
import moment from "moment";
import {customStatusList, followUpStatusList} from "../../constants/constant";
import {addFollowUp, updateFollowUp} from "../../actions/followupDBTool";
import {connect} from "react-redux";
import CustomChooseSelect from "./CustomChooseSelect";
import StaffChoose from "./StaffChoose";
import FileManage from './FileManage';
import {name2Account,account2Name, customName2Uuid} from "../../utils/accountUtils";

const {Option} = Select;
const FollowUpStatusOptions = followUpStatusList.map(item=><Option key={item} value={item}>{item}</Option>)
const FollowUpForm = Form.create({ name: 'add_order_modal' })(
    class extends React.Component{
        constructor(props) {
            super(props);
            this.state = {};
        }
        render() {
            let { visible, onCancel, onOk, form,mode, values } = this.props;
            let { getFieldDecorator } = form;
            let formItemLayout = {labelCol: {span:6}, wrapperCol: {span:16}};
            let detail = {};
            let title = "添加跟进记录"
            if(2 === mode){title = "查看跟进记录";}
            else if(1 === mode){title = "更新跟进记录";}
            if(values && values.detail){detail = values.detail;}
            return (<Modal title={title} visible={visible} maskClosable={false}
                        onOk={onOk} onCancel={onCancel} okText={2===mode?"关闭":"确定"} cancelText={"取消"}>
                    <Form {...formItemLayout}>
                        <Form.Item label={"客户名称"}>
                            {getFieldDecorator('customName', {rules: [{ required: true, message: '客户名称不能为空!'}],initialValue:values?detail.custom_name:''})
                            (<CustomChooseSelect disabled={2 === mode?true:false}/>)}
                        </Form.Item>
                        <Form.Item label={"联系电话"}>
                            {getFieldDecorator('phone', {rules: [{ required: false, message: '联系方式不能为空!'}],initialValue:values?detail.phone:''})
                            (<Input type="tel" placeholder={"输入客户手机号,多个用'/'隔开"} disabled={2 === mode?true:false}/>)}
                        </Form.Item>
                        <Form.Item label={"跟进时间"} required={true}>
                            {getFieldDecorator('createTime', {rules: [{ required: true,message:'跟进时间不能为空!'}],
                                initialValue:mode?moment(new Date(values.create_time)):moment(new Date(),'YYYY-MM-DD')})
                            (<DatePicker placeholder={"选择跟进的时间"} showTime disabled={2 === mode?true:false}/>)}
                        </Form.Item>
                        <Form.Item label={"跟进内容"}>
                            {getFieldDecorator('content', {rules: [{ required: false}],initialValue:values?detail.content:''})
                            (<ReactQuill placeholder={"请输入跟进内容"} readOnly={2 === mode?true:false}/>)}
                        </Form.Item>
                        <Form.Item label={"跟进阶段"} required={true}>
                            {getFieldDecorator('status',{rules:[{required:true,message:'跟进阶段不能为空!'}],initialValue:values?values.status:followUpStatusList[0]})
                            (<Select disabled={2 === mode?true:false}>{FollowUpStatusOptions}</Select>)}
                        </Form.Item>
                        <div hidden={mode}>
                            <Form.Item label={"更新客户状态"}>
                                {getFieldDecorator('customStatus',{rules:[{required:false,message:'客户状态不能为空！'}],
                                    initialValue:values?detail.custom_status:customStatusList[0]})
                                (<ExtendSelect items={customStatusList} title={"更新客户状态"} disabled={2 === mode?true:false}/>)}
                            </Form.Item>
                        </div>
                        <Form.Item label={"抄送给"} required={false}>
                            {getFieldDecorator('assigner',{rules:[{required:false}],initialValue:values?account2Name(detail.assigner_account):""})
                            (<StaffChoose mode={1} disabled={2 === mode?true:false}/>)}
                        </Form.Item>
                        <Form.Item label={"相关文档"}>
                            {getFieldDecorator('fileUrl', {initialValue:detail.file_url?detail.file_url:[]})
                                (<FileManage filePrefix={"followup/" + values.uuid+"/"} fileList={detail.file_url} disabled={1 < mode?true:false}/>)}
                        </Form.Item>
                    </Form>
                </Modal>);
        }
    })
function mapStateToProps(state) {
    return {...state.custom};
}
function mapDispatchToProps(dispatch) {
    return {onAddFollowUp:(postData)=>dispatch(addFollowUp(postData)),
            onUpdateFollowUp:(postData)=>dispatch(updateFollowUp(postData))};
}
class FollowUpModal extends React.Component{
    constructor(props) {
        super(props);
        this.state = {mode:this.props.mode,visible:false}
        this.onCancel = this.onCancel.bind(this);
        this.onSubmitFollowUp = this.onSubmitFollowUp.bind(this)
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
       if(this.props.visible !== nextProps.visible){
           this.setState({visible:true})
       }
       return true
    }
    saveFormRef=formRef=>{this.formRef = formRef;}
    onCancel(){
        this.setState({visible:false})
        const { form } = this.formRef.props;
        form.resetFields();
    }
    onSubmitFollowUp(){
        const {form} = this.formRef.props;
        if(2 === this.props.mode){
            form.resetFields();
            this.setState({visible:false})
            return;
        }
        form.validateFields((err, fromValues) => {
            if (err) {return;}
            let assigner_account = name2Account(fromValues.assigner);
            console.log(fromValues.fileUrl)
            let detail = {
                custom_name:fromValues.customName,
                phone: fromValues.phone,
                content: fromValues.content,
                file_url:fromValues.fileUrl,
                assigner_account: assigner_account,
            };
            if(this.props.mode){
                detail['custom_status'] = this.props.values.detail.custom_status;
            }
            else{
                detail['custom_status'] = fromValues.customStatus;
            }
            let postData = {
                uuid:this.props.values.uuid,
                enterprise_uuid:localStorage.getItem('enterprise_uuid'),
                title: "",
                type: 1,
                custom_uuid: customName2Uuid(fromValues.customName),
                status: fromValues.status,
                detail: JSON.stringify(detail),
                creator_account:localStorage.getItem('account'),
                create_time: Date.parse(new Date())
            }
            if (this.props.mode) {
                if(null == customName2Uuid(fromValues.customName)){
                    return;
                }
                this.props.onUpdateFollowUp(postData);
            } else {
                this.props.onAddFollowUp(postData);
            }
            this.setState({visible:false})
            form.resetFields();
            this.props.onClose();
        })
    }
    render(){
        return(<FollowUpForm wrappedComponentRef={this.saveFormRef} visible={this.state.visible} mode={this.props.mode}
                onCancel={this.onCancel} onOk={()=>{this.onSubmitFollowUp()}} values={this.props.values}/>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(FollowUpModal);