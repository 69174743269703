import React from "react";
import {Row, Tag, Transfer, Modal, Tabs, Radio, Button} from 'antd';
import {connect} from "react-redux";
import {getAllUser} from "../../actions/userDBTool";
const { TabPane } = Tabs;
let dataSource = [];
function mapStateToProps(state) {
    return {...state.staff}
}
function mapDispatchToProps(dispatch) {
    return {onGetAllStaff:()=>dispatch(getAllUser())}
}
class StaffChoose extends React.Component{
    constructor(props) {
        super(props);
        this.state = {visible:false, targetKeys:[]};
        this.freshStaffChoose = this.freshStaffChoose.bind(this);
        this.handleStaffChoose = this.handleStaffChoose.bind(this);
        this.handleTransferChange = this.handleTransferChange.bind(this);
    }
    componentDidMount(){
        this.freshStaffChoose();
    }
    handleTransferChange(nextTargetKeys, direction, moveKeys){
        this.setState({ targetKeys: nextTargetKeys });
        this.triggerChange(nextTargetKeys);
        console.log('targetKeys: ', nextTargetKeys);
        console.log('direction: ', direction);
        console.log('moveKeys: ', moveKeys);
    }
    handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        this.setState({ selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys] });
        console.log('sourceSelectedKeys: ', sourceSelectedKeys);
        console.log('targetSelectedKeys: ', targetSelectedKeys);
    }
    handleStaffChoose(){
        this.setState({visible:true,targetKeys:[]});
        this.freshStaffChoose();
    }
    triggerChange = (changedValue) => {
        console.log(changedValue);
        const onChange = this.props.onChange;
        if (onChange) {onChange(changedValue);}
    }
    closeSelectStaff(tag){
        let values = this.props.value;
        if(values === tag){
            values = "";
        }
        else{
            values.splice(values.indexOf(tag),1);
        }
        this.triggerChange(values);
        console.log(values);
    }
    freshStaffChoose(){
        this.props.onGetAllStaff();
        if(this.props.get_staff){
            dataSource = [];
            for(let i = 0;i < this.props.get_staff_list_result.length; i++){
                let item = this.props.get_staff_list_result[i];
                dataSource.push({key:item.name,title:item.name})
            }
            if(this.props.value){
                if(this.props.value instanceof Array){
                    this.setState({targetKeys:this.props.value})
                }
                else{
                    this.setState({targetKeys:[this.props.value]});
                }
            }
        }
    }
    renderStaffModel(){
        const { targetKeys, selectedKeys } = this.state;
        return (<Modal title={"选择成员"} visible={this.state.visible} width={1000} okText={"确定"} cancelText={"取消"}
                onOk={()=>{this.setState({visible:false})}} onCancel={()=>{this.setState({visible:false})}}>
            <Tabs defaultActiveKey={"1"}>
                {this.props.mode?(<TabPane key={"1"} tab={"选择成员"}>
                        <Transfer dataSource={dataSource} titles={['成员列表','已选']} targetKeys={targetKeys} 
                                selectedKeys={selectedKeys} onChange={this.handleTransferChange} 
                                onSelectChange={this.handleSelectChange} render={item => item.title}/>
                    </TabPane>):
                    (<TabPane key={"1"} tab={"选择成员"}>
                        <Radio.Group onChange={(e)=>{this.triggerChange(e.target.value)}} value={this.props.value}>
                            {this.props.get_staff?this.props.get_staff_list_result.map((item,index)=>{
                                const element = (<Radio key={item.account} value={item.name}>{item.name}</Radio>);
                                return element;
                            }):null}
                        </Radio.Group>
                </TabPane>)}
            </Tabs>
        </Modal>)
    }
    render(){
        const style = {color:"geekblue"};
        return (<Row>
            {this.renderStaffModel()}
            <Row gutter={[1,0]}>
                {this.props.value instanceof Array?this.props.value.map((tag, index) => {
                    const tagElem = (<Tag key={tag} disabled={this.props.disabled} closable={!this.props.disabled} onClose={()=>this.closeSelectStaff(tag)} style={style}>
                        {tag}
                    </Tag>);
                    return (tagElem);
                }):this.props.value?
                    (<Tag key={this.props.value} disabled={this.props.disabled} closable={!this.props.disabled} onClose={()=>this.closeSelectStaff(this.props.value)} style={style}>
                        {this.props.value}
                    </Tag>):null}
                <Button type="link" disabled={this.props.disabled} onClick={()=>{this.handleStaffChoose()}}>选择成员</Button>
                {this.props.commonEnable?<Button type='link' onClick={()=>{this.triggerChange("");}}>转入公海客户</Button>:null}
            </Row>
        </Row>);
    }
}
export default connect(mapStateToProps,mapDispatchToProps,null,{forwardRef: true})(StaffChoose)